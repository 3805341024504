import { useEffect, useState } from 'react';

export function isSafari11orBelow(): boolean {
    const userAgent = navigator.userAgent;

    const versionMatch = userAgent.match(/Version\/(\d+)\.(\d+)/);
    const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);

    if (versionMatch && isSafari) {
        const majorVersion = parseInt(versionMatch[1], 10);
        if (majorVersion <= 11) {
            return true;
        }
    }

    return false;
}

export const useIsSafari11orBelow = () => {
    const [isSaf11, setIsSaf11] = useState<boolean>(false);
    useEffect(() => {
        setIsSaf11(isSafari11orBelow);
    }, []);
    return isSaf11;
};
