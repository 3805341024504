// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M16.617 3.98879C16.2537 3.70218 15.7413 3.70224 15.378 3.98895L3.88041 13.0647C3.64017 13.2544 3.5 13.5436 3.5 13.8496V27.5C3.5 28.0523 3.94772 28.5 4.5 28.5H27.5C28.0523 28.5 28.5 28.0523 28.5 27.5V13.8486C28.5 13.5424 28.3597 13.2531 28.1194 13.0635L16.617 3.98879ZM5.77042 14.9697C5.77042 14.6636 5.91065 14.3744 6.15097 14.1847L15.378 6.9038C15.7412 6.61718 16.2537 6.61718 16.6169 6.9038L25.844 14.1847C26.0843 14.3744 26.2245 14.6636 26.2245 14.9697V25.26C26.2245 25.8123 25.7768 26.26 25.2245 26.26H6.77042C6.21814 26.26 5.77042 25.8123 5.77042 25.26V14.9697Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
