// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Atom_Textfield_Ds from "./Atom_Textfield_Ds.res.mjs";
import * as Particle_Css_font from "../particle/Particle_Css_font.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";

var inputBaseCss = [
  CssJs.border(CssJs.zero, CssJs.none, CssJs.transparent),
  CssJs.outline(CssJs.zero, CssJs.none, CssJs.transparent),
  CssJs.width(CssJs.pct(100)),
  CssJs.focus(Particle_Border_Css.toCss(Particle_Border_Ds.none)),
  CssJs.active(Particle_Border_Css.toCss(Particle_Border_Ds.none)),
  CssJs.unsafe("-webkit-appearance", "none")
];

function toCss(dsStyle) {
  var match = Atom_Textfield_Ds.view(dsStyle);
  var svgRules = Core__Option.map(match.icon, (function (param) {
          return [
                  CssJs.pointerEvents("none"),
                  CssJs.lineHeight(CssJs.zero),
                  CssJs.right(Particle_Css.getSpacerSize("M")),
                  CssJs.top(CssJs.pct(50)),
                  CssJs.transform({
                        NAME: "translateY",
                        VAL: CssJs.pct(-50)
                      })
                ];
        }));
  var placeholderSizeRules = [CssJs.selector("::placeholder", Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(Particle_Css_font.size), match.textStyle.size))];
  var blockSpace = Particle_Screen_Css.toRules(undefined, undefined, Particle_Spacer_Css.toCss, match.blockSpace);
  return {
          blockSpace: blockSpace,
          border: match.border,
          iconWrapperRules: svgRules,
          placeholderSizeRules: placeholderSizeRules
        };
}

export {
  inputBaseCss ,
  toCss ,
}
/* inputBaseCss Not a pure module */
