// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Logo from "../../atom/Atom_Logo.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../../../type/Type_Common.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_Accordion from "../../molecule/Molecule_Accordion.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Newsletter from "./Organism_Newsletter.res.mjs";
import * as Particle_Spacer_Css from "../../particle/Particle_Spacer_Css.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function makeStyle(icon) {
  if (icon !== undefined) {
    return Atom_Cta_Ds.Footer.makeSocial(Caml_option.valFromOption(icon));
  } else {
    return Atom_Cta_Ds.Footer.link;
  }
}

function Organism_LinksSection$Links(props) {
  var showPrivacyCenter = props.showPrivacyCenter;
  var titleKey = props.titleKey;
  var items = props.items;
  if (items !== undefined && items.length !== 0) {
    return items.map(function (item, i) {
                switch (item.TAG) {
                  case "Link" :
                      var label = item._0;
                      if (label === undefined) {
                        return null;
                      }
                      var target = item._2;
                      if (target !== undefined) {
                        return JsxRuntime.jsx(Atom_Cell.make, {
                                    kind: "ItemList",
                                    children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                          href: Type_String.Href.toString(item._1),
                                          label: Type_String.Footer.Label.toString(label),
                                          rel: item._3,
                                          style: makeStyle(undefined),
                                          target: target
                                        })
                                  }, titleKey + String(i));
                      } else {
                        return null;
                      }
                  case "Button" :
                      var label$1 = item._0;
                      if (label$1 !== undefined) {
                        return JsxRuntime.jsx(Atom_Cell.make, {
                                    kind: "ItemList",
                                    children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                                          label: Type_String.Footer.Label.toString(label$1),
                                          onClick: showPrivacyCenter,
                                          style: makeStyle(undefined)
                                        })
                                  }, titleKey + String(i));
                      } else {
                        return null;
                      }
                  case "Social" :
                      var match = item._0;
                      return JsxRuntime.jsx(Atom_Cell.make, {
                                  kind: "ItemList",
                                  children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                        href: Type_String.Href.toString(match.href),
                                        label: match.label,
                                        rel: Caml_option.some(Type_Common.Rel.make("nofollow")),
                                        style: makeStyle(Caml_option.some(match.icon)),
                                        target: "_blank"
                                      })
                                }, titleKey + String(i));
                  
                }
              });
  } else {
    return null;
  }
}

var Links = {
  makeStyle: makeStyle,
  make: Organism_LinksSection$Links
};

var linkSectionRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.display(CssJs.flexBox)]);

var gridGapValue = Particle_Css.getSpacerSize("L");

var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.gridGap(gridGapValue),
      CssJs.display("grid"),
      CssJs.gridTemplateAreas({
            NAME: "areas",
            VAL: ["logo links ."]
          })
    ], [
      CssJs.gridGap(gridGapValue),
      CssJs.display("flex"),
      CssJs.flexDirection("row")
    ], undefined, []);

var logoRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.display(CssJs.flexBox)], Particle_Spacer_Css.toCss(Particle_Spacer_Ds.makeRight("S")), undefined, [
      CssJs.display(CssJs.none),
      CssJs.gridArea({
            NAME: "ident",
            VAL: "logo"
          })
    ]);

var linksRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.gridArea({
            NAME: "ident",
            VAL: "links"
          }),
      CssJs.gridGap(gridGapValue),
      CssJs.display("grid"),
      CssJs.gridTemplateAreas({
            NAME: "areas",
            VAL: [
              "area_1 . area_2",
              "area_3 . area_4",
              "newsletter newsletter newsletter"
            ]
          })
    ], [
      CssJs.display("flex"),
      CssJs.flexDirection("row")
    ], undefined, []);

var Styles = {
  linkSectionRules: linkSectionRules,
  gridGapValue: gridGapValue,
  wrapperRules: wrapperRules,
  logoRules: logoRules,
  linksRules: linksRules
};

function Organism_LinksSection$Title(props) {
  var href = props.href;
  var title = props.title;
  if (href !== undefined) {
    return JsxRuntime.jsx(Atom_Cta.Link.make, {
                href: Type_String.Href.toString(href),
                label: title,
                style: Atom_Cta_Ds.Footer.titleLink
              });
  } else {
    return JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(title),
                kind: "span",
                style: Atom_Text_Ds.Footer.title
              });
  }
}

var Title = {
  make: Organism_LinksSection$Title
};

function Organism_LinksSection$LinkSection(props) {
  var title = props.title;
  return JsxRuntime.jsxs(Atom_Column.make, {
              area: props.area,
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone())),
              children: [
                title !== undefined ? JsxRuntime.jsx(Organism_LinksSection$Title, {
                        title: Type_String.Footer.Title.toString(title),
                        href: props.href
                      }) : null,
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Organism_LinksSection$Links, {
                            items: props.items,
                            titleKey: props.titleKey,
                            showPrivacyCenter: props.showPrivacyCenter
                          }),
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
                      kind: "List",
                      rules: Caml_option.some(linkSectionRules),
                      width: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.em(10)))
                    })
              ],
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")),
              kind: "ItemList"
            });
}

var LinkSection = {
  make: Organism_LinksSection$LinkSection
};

function Organism_LinksSection(props) {
  var showPrivacyCenter = props.showPrivacyCenter;
  var screenSize = props.screenSize;
  var columnLinks = props.columnLinks;
  var columnLinks$1 = columnLinks !== undefined ? columnLinks.map(function (param, i) {
          var items = param.items;
          var title = Core__Option.getOr(param.title, Type_String.Footer.Title.make(Uuid.v4()));
          var key = Type_String.Footer.Title.toString(title);
          if (typeof screenSize === "object") {
            var variant = screenSize.NAME;
            if (variant === "XS" || variant === "S") {
              return JsxRuntime.jsx(Molecule_Accordion.Footer.make, {
                          area: "area_" + String(i + 1 | 0),
                          title: Type_String.Footer.Title.toString(title),
                          children: JsxRuntime.jsx(Organism_LinksSection$Links, {
                                items: items,
                                titleKey: key,
                                showPrivacyCenter: showPrivacyCenter
                              })
                        }, key);
            }
            
          }
          return JsxRuntime.jsx(Organism_LinksSection$LinkSection, {
                      title: title,
                      titleKey: key,
                      href: param.href,
                      items: items,
                      area: "area_" + String(i + 1 | 0),
                      showPrivacyCenter: showPrivacyCenter
                    }, key);
        }) : null;
  var mainSpacer = Particle_Spacer_Ds.makeVertical("XS");
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Column.make, {
                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, mainSpacer)),
                        children: [
                          JsxRuntime.jsxs(Atom_Cell.make, {
                                blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("XL"))),
                                height: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(200))),
                                children: [
                                  JsxRuntime.jsx(Atom_Logo.make, {
                                        logo: {
                                          NAME: "ArteVerticalLogo",
                                          VAL: "Footer"
                                        }
                                      }),
                                  ""
                                ],
                                rules: Caml_option.some(logoRules)
                              }),
                          JsxRuntime.jsxs(Atom_Column.make, {
                                children: [
                                  columnLinks$1,
                                  JsxRuntime.jsx(Organism_Newsletter.make, {
                                        labels: props.newsletter,
                                        style: "Footer",
                                        source: props.newsletterSource
                                      })
                                ],
                                justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.spaceBetween)),
                                kind: "List",
                                rules: Caml_option.some(linksRules),
                                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                              })
                        ],
                        innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
                        rules: Caml_option.some(wrapperRules)
                      }))
            });
}

var make = Organism_LinksSection;

export {
  Links ,
  Styles ,
  Title ,
  LinkSection ,
  make ,
}
/* linkSectionRules Not a pure module */
