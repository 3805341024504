// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Screen_Css from "./Particle_Screen_Css.res.mjs";
import * as Particle_Animation_Ds from "./Particle_Animation_Ds.res.mjs";

function getTimingFunction(timingFunction) {
  if (typeof timingFunction === "object") {
    return CssJs.cubicBezier(timingFunction._0, timingFunction._1, timingFunction._2, timingFunction._3);
  }
  switch (timingFunction) {
    case "EaseOut" :
        return CssJs.easeOut;
    case "EaseInOut" :
        return CssJs.easeInOut;
    case "CubicBezier" :
        return CssJs.cubicBezier(0.63, 0.24, 0.93, 0.2);
    case "Linear" :
        return CssJs.linear;
    
  }
}

function viewFieldToRules(viewField) {
  var iterationCount = viewField.iterationCount;
  var keyframes = viewField.keyframes;
  var keyframes$1;
  if (typeof keyframes !== "object") {
    switch (keyframes) {
      case "OpacityIn" :
          keyframes$1 = [
            [
              0,
              [CssJs.opacity(0)]
            ],
            [
              100,
              [CssJs.opacity(1)]
            ]
          ];
          break;
      case "OpacityOut" :
          keyframes$1 = [
            [
              0,
              [CssJs.opacity(1)]
            ],
            [
              100,
              [CssJs.opacity(0)]
            ]
          ];
          break;
      case "Pulse" :
          keyframes$1 = [
            [
              0,
              [CssJs.transform({
                      NAME: "scale",
                      VAL: [
                        1,
                        1
                      ]
                    })]
            ],
            [
              25,
              [CssJs.transform({
                      NAME: "scale",
                      VAL: [
                        0.5,
                        0.5
                      ]
                    })]
            ],
            [
              50,
              [CssJs.transform({
                      NAME: "scale",
                      VAL: [
                        1,
                        1
                      ]
                    })]
            ],
            [
              75,
              [CssJs.transform({
                      NAME: "scale",
                      VAL: [
                        1.5,
                        1.5
                      ]
                    })]
            ],
            [
              100,
              [CssJs.transform({
                      NAME: "scale",
                      VAL: [
                        1,
                        1
                      ]
                    })]
            ]
          ];
          break;
      case "RevealText" :
          keyframes$1 = [
            [
              0,
              [CssJs.unsafe("clipPath", "inset(0 100% 0 0)")]
            ],
            [
              100,
              [CssJs.unsafe("clipPath", "inset(0 0 0 0)")]
            ]
          ];
          break;
      case "TranslationLeft" :
          keyframes$1 = [
            [
              0,
              [CssJs.left(CssJs.vw(15))]
            ],
            [
              100,
              [CssJs.left(CssJs.pct(0))]
            ]
          ];
          break;
      case "TranslationRight" :
          keyframes$1 = [
            [
              0,
              [CssJs.transform(CssJs.translate3d(CssJs.vw(-40), CssJs.px(0), CssJs.px(0)))]
            ],
            [
              100,
              [CssJs.transform(CssJs.translate3d(CssJs.px(0), CssJs.px(0), CssJs.px(0)))]
            ]
          ];
          break;
      case "Nothing" :
          keyframes$1 = [];
          break;
      case "DecreaseWidth" :
          keyframes$1 = [
            [
              0,
              [CssJs.width(CssJs.pct(100))]
            ],
            [
              100,
              [CssJs.width(CssJs.pct(0))]
            ]
          ];
          break;
      
    }
  } else if (keyframes.TAG === "Open") {
    keyframes$1 = [
      [
        0,
        [
          CssJs.opacity(0),
          CssJs.maxHeight(CssJs.px(0))
        ]
      ],
      [
        20,
        [
          CssJs.opacity(0),
          CssJs.maxHeight(CssJs.px(keyframes._0 | 0))
        ]
      ],
      [
        100,
        [CssJs.opacity(1)]
      ]
    ];
  } else {
    var match = keyframes._0;
    keyframes$1 = [
      [
        0,
        [CssJs.transform(CssJs.rotate(CssJs.deg(match.start)))]
      ],
      [
        100,
        [CssJs.transform(CssJs.rotate(CssJs.deg(match.end)))]
      ]
    ];
  }
  var iterationCount$1;
  iterationCount$1 = typeof iterationCount !== "object" ? (
      iterationCount === "Unique" ? ({
            NAME: "count",
            VAL: 1
          }) : "infinite"
    ) : ({
        NAME: "count",
        VAL: iterationCount._0
      });
  var fillMode;
  fillMode = viewField.fillMode === "Forwards" ? "forwards" : "none";
  var direction;
  direction = viewField.direction === "Normal" ? "normal" : "alternate";
  var timingFunction = getTimingFunction(viewField.timingFunction);
  return [CssJs.animation(viewField.duration, viewField.delay, direction, timingFunction, fillMode, undefined, iterationCount$1, CssJs.keyframes(keyframes$1))];
}

function toCss(animation) {
  var view = Particle_Animation_Ds.view(animation);
  return Particle_Screen_Css.toRules(undefined, undefined, viewFieldToRules, view);
}

export {
  getTimingFunction ,
  viewFieldToRules ,
  toCss ,
}
/* CssJs Not a pure module */
