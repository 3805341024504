// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M7.431 4.276v3.388h-3.388v3.388h-3.388v16.671h23.915v-3.388h3.388v-3.388h3.388v-16.671h-23.915zM22.365 25.52h-19.513v-12.27h19.513v12.27zM25.754 22.132h-1.191v-11.079h-18.323v-1.191h19.513v12.27zM29.142 18.744h-1.191v-11.079h-18.323v-1.191h19.513v12.27z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
