// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as UseOnBlur from "../../hook/UseOnBlur.res.mjs";
import * as UsePortal from "../../hook/UsePortal.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as ReactDom from "react-dom";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as UseClickOutside from "../../hook/UseClickOutside.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "Modal"
    });

var containerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.position("fixed"),
      CssJs.top("zero"),
      CssJs.overflowY("auto"),
      CssJs.left("zero"),
      zIndexRule
    ]);

var contentBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("L"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "M"));

var contentInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var Organism_Modal_CSS = {
  zIndexRule: zIndexRule,
  containerRules: containerRules,
  contentBlockSpace: contentBlockSpace,
  contentInnerSpace: contentInnerSpace
};

function make(param) {
  var children = param.children;
  var title = param.title;
  var onClose = param.onClose;
  var ref = React.useRef(null);
  var status = UseToggle.toStatus(param.isOpen);
  UseClickOutside.make(ref, onClose);
  UseOnBlur.make(ref, onClose);
  var background = param.background !== undefined ? Particle_Background_Ds.white : Particle_Background_Ds.modalOverlay;
  var modalContent = JsxRuntime.jsx(Atom_Column.make, {
        alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
        background: Caml_option.some(Particle_Background_Ds.modalOverlay),
        children: JsxRuntime.jsx(Atom_Row.make, {
              background: Caml_option.some(background),
              blockSpace: Caml_option.some(contentBlockSpace),
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    children: [
                      title !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(title),
                              kind: "h3",
                              style: Atom_Text_Ds.Modal.title
                            }) : null,
                      children !== undefined ? Caml_option.valFromOption(children) : null
                    ]
                  }),
              identifier: Caml_option.some(Identifiers.Modal.content),
              innerSpace: Caml_option.some(contentInnerSpace),
              ref_: ref
            }),
        height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
        identifier: Caml_option.some(Identifiers.Modal.container),
        justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
        rules: Caml_option.some(containerRules),
        width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
      });
  var content;
  content = status === "On" ? modalContent : null;
  var target = UsePortal.make(Identifiers.toString(param.id));
  if (target !== undefined) {
    return ReactDom.createPortal(content, Caml_option.valFromOption(target));
  } else {
    return null;
  }
}

export {
  Organism_Modal_CSS ,
  make ,
}
/* zIndexRule Not a pure module */
