// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.position(CssJs.fixed),
      CssJs.right(CssJs.px(-2)),
      CssJs.bottom(CssJs.px(20)),
      Particle_zIndex.Particle_zIndex_Css.toCss({
            TAG: "Exact",
            _0: "FeedbackButton"
          })
    ]);

var buttonRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.transformOrigin(CssJs.pct(100), CssJs.pct(100)),
      CssJs.transforms([
            CssJs.rotate(CssJs.deg(-90)),
            CssJs.translateX(CssJs.pct(100))
          ]),
      CssJs.borderRadius(CssJs.pct(50))
    ]);

var display = Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none");

function make(param) {
  var match = Context.I18n.use();
  var match$1 = match.labels.feedback;
  return JsxRuntime.jsx(Atom_Cell.make, {
              display: Caml_option.some(display),
              children: JsxRuntime.jsx(Atom_Cell.make, {
                    children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                          href: param.href,
                          label: match$1.buttonLabel,
                          onClick: param.onClick,
                          style: Atom_Cta_Ds.feedback
                        }),
                    rules: Caml_option.some(buttonRules)
                  }),
              rules: Caml_option.some(wrapperRules)
            });
}

export {
  wrapperRules ,
  buttonRules ,
  display ,
  make ,
}
/* wrapperRules Not a pure module */
