// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.res.mjs";
import * as Particle_Color_Ds from "./Particle_Color_Ds.res.mjs";
import * as Particle_Color_Css from "./Particle_Color_Css.res.mjs";

var prefix = "--ads-";

function getThemeClassName(theme) {
  if (theme === "Dark") {
    return prefix + "dark";
  } else {
    return prefix + "light";
  }
}

function getVarName(t) {
  var varName = t === "Primary" ? "primary" : (
      t === "TextAlternative" ? "text-alternative" : (
          t === "SubHeaderBackground" ? "sub-header-background" : (
              t === "TeaserInfoBorder" ? "teaser-info-border" : (
                  t === "TeaserClassicBackground" ? "teaser-classic-background" : (
                      t === "TeaserInfoBackground" ? "teaser-info-background" : (
                          t === "TeaserHover" ? "teaser-hover" : (
                              t === "Secondary" ? "secondary" : (
                                  t === "AvatarStickerBackground" ? "avatar-sticker-background" : (
                                      t === "EmptyTeaserBackground" ? "empty-teaser-background" : (
                                          t === "TransparentBackground" ? "transparent-background" : (
                                              t === "Background" ? "background" : (
                                                  t === "Placeholder" ? "placeholder" : (
                                                      t === "EmptyTeaserLogo" ? "empty-teaser-logo" : (
                                                          t === "Geoblocking" ? "geoblocking" : (
                                                              t === "SwitchInactiveCircle" ? "switch-inactive-circle" : (
                                                                  t === "SwitchInactiveForeground" ? "switch-inactive-foreground" : (
                                                                      t === "TeaserBorder" ? "teaser-border" : (
                                                                          t === "Text" ? "text" : (
                                                                              t === "Divider" ? "divider" : "switch-inactive-background"
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  return prefix + varName;
}

function stripDegIf0(input) {
  return input.replace("0deg", "0");
}

function generateVariablesFromTheme(theme) {
  return [
            [
              getVarName("AvatarStickerBackground"),
              Particle_Color_Css.toCss(theme.avatarStickerBackground)
            ],
            [
              getVarName("Background"),
              Particle_Color_Css.toCss(theme.secondary)
            ],
            [
              getVarName("Geoblocking"),
              Particle_Color_Css.toCss(Particle_Color_Ds.setAlpha(theme.secondary, 0.8))
            ],
            [
              getVarName("Primary"),
              Particle_Color_Css.toCss(theme.primary)
            ],
            [
              getVarName("Secondary"),
              Particle_Color_Css.toCss(theme.secondary)
            ],
            [
              getVarName("Divider"),
              Particle_Color_Css.toCss(theme.divider)
            ],
            [
              getVarName("Placeholder"),
              Particle_Color_Css.toCss(Particle_Color_Ds.setAlpha(theme.primary, 0.1))
            ],
            [
              getVarName("EmptyTeaserBackground"),
              Particle_Color_Css.toCss(theme.emptyTeaserBackground)
            ],
            [
              getVarName("EmptyTeaserLogo"),
              Particle_Color_Css.toCss(theme.emptyTeaserLogo)
            ],
            [
              getVarName("TeaserHover"),
              Particle_Color_Css.toCss(theme.TeaserHover.background)
            ],
            [
              getVarName("Text"),
              Particle_Color_Css.toCss(theme.primary)
            ],
            [
              getVarName("TransparentBackground"),
              Particle_Color_Css.toCss(Particle_Color_Ds.setAlpha(theme.secondary, 0.1))
            ],
            [
              getVarName("SubHeaderBackground"),
              Particle_Color_Css.toCss(theme.subHeaderBackground)
            ],
            [
              getVarName("SwitchInactiveBackground"),
              Particle_Color_Css.toCss(theme.Switch.Inactive.background)
            ],
            [
              getVarName("SwitchInactiveForeground"),
              Particle_Color_Css.toCss(theme.Switch.Inactive.foreground)
            ],
            [
              getVarName("SwitchInactiveCircle"),
              Particle_Color_Css.toCss(theme.Switch.Inactive.circle)
            ],
            [
              getVarName("TextAlternative"),
              Particle_Color_Css.toCss(Particle_Color_Ds.setAlpha(theme.primary, 0.8))
            ],
            [
              getVarName("TeaserClassicBackground"),
              Particle_Color_Css.toCss(theme.teaserClassicBackground)
            ],
            [
              getVarName("TeaserInfoBackground"),
              Particle_Color_Css.toCss(theme.teaserInfoBackground)
            ],
            [
              getVarName("TeaserBorder"),
              Particle_Color_Css.toCss(theme.teaserBorder)
            ],
            [
              getVarName("TeaserInfoBorder"),
              Particle_Color_Css.toCss(theme.teaserInfoBorder)
            ]
          ].map(function (param) {
              return [
                      param[0],
                      Css_AtomicTypes.Color.toString(param[1]).replace("0deg", "0")
                    ];
            });
}

function getThemeVariables(theme) {
  var Theme = theme === "Dark" ? Particle_Color_Ds.Dark : Particle_Color_Ds.Light;
  return generateVariablesFromTheme(Theme);
}

var Var = {
  prefix: prefix,
  $$default: "Dark",
  getThemeClassName: getThemeClassName,
  getVarName: getVarName,
  stripDegIf0: stripDegIf0,
  generateVariablesFromTheme: generateVariablesFromTheme,
  getThemeVariables: getThemeVariables
};

function color(x) {
  if (typeof x !== "object") {
    if (x === "Divider" || x === "Text" || x === "TeaserBorder" || x === "SwitchInactiveForeground" || x === "SwitchInactiveCircle" || x === "Geoblocking" || x === "EmptyTeaserLogo" || x === "Placeholder" || x === "Background" || x === "TransparentBackground" || x === "EmptyTeaserBackground" || x === "AvatarStickerBackground" || x === "Secondary" || x === "TeaserHover" || x === "TeaserInfoBackground" || x === "TeaserClassicBackground" || x === "TeaserInfoBorder" || x === "SubHeaderBackground" || x === "TextAlternative" || x === "Primary" || x === "SwitchInactiveBackground") {
      return CssJs.$$var(getVarName(x));
    }
    if (x === "CorporateMenu" || x === "CorporateBorder" || x === "CorporateMenuText" || x === "CorporateMenuIcon") {
      if (x === "CorporateMenuText") {
        return CssJs.hex("151515");
      } else if (x === "CorporateBorder") {
        return CssJs.hex("EEEEEE");
      } else if (x === "CorporateMenu") {
        return CssJs.hex("E7E7E9");
      } else {
        return CssJs.hex("727272");
      }
    }
    
  }
  if (typeof x === "object") {
    return CssJs.hsla({
                NAME: "deg",
                VAL: 0
              }, {
                NAME: "percent",
                VAL: 0
              }, {
                NAME: "percent",
                VAL: 61
              }, {
                NAME: "percent",
                VAL: x.VAL
              });
  } else if (x === "Black") {
    return CssJs.hex("000000");
  } else if (x === "Histoire" || x === "Culture" || x === "MyAdvantage" || x === "Voyage" || x === "Serie" || x === "Cinema" || x === "Actualite" || x === "Default" || x === "Concert" || x === "Science") {
    if (x === "Science") {
      return CssJs.hex("DB0A5B");
    } else if (x === "Concert") {
      return CssJs.hex("FF8BC4");
    } else if (x === "Default") {
      return CssJs.hex("FFFFFF");
    } else if (x === "Actualite") {
      return CssJs.hex("1978D4");
    } else if (x === "Cinema") {
      return CssJs.hex("979D9E");
    } else if (x === "Serie") {
      return CssJs.hex("00E1E2");
    } else if (x === "Voyage") {
      return CssJs.hex("26C281");
    } else if (x === "MyAdvantage") {
      return CssJs.hex("FFFFFF");
    } else if (x === "Culture") {
      return CssJs.hex("BF55EC");
    } else if (x === "Histoire") {
      return CssJs.hex("FBBA16");
    } else {
      return CssJs.hex("000000");
    }
  } else if (x === "ArteLight") {
    return CssJs.hex("FC8E73");
  } else if (x === "DarkGrey") {
    return CssJs.hex("262626");
  } else if (x === "White") {
    return CssJs.hex("FFFFFF");
  } else if (x === "AccountHeader") {
    return CssJs.hex("484848");
  } else if (x === "LightGrey") {
    return CssJs.hex("9B9B9B");
  } else if (x === "Facebook") {
    return CssJs.hex("4267B2");
  } else if (x === "DividerStatic") {
    return CssJs.hex("464646");
  } else if (x === "X") {
    return CssJs.hex("000000");
  } else if (x === "Afp") {
    return CssJs.hex("0B76D8");
  } else if (x === "HoverGrey") {
    return CssJs.hex("D0D0D0");
  } else if (x === "Success") {
    return CssJs.hex("7CDB63");
  } else if (x === "Error") {
    return CssJs.hex("FE6D77");
  } else if (x === "Transparent") {
    return CssJs.transparent;
  } else if (x === "LogoPlaceHolder") {
    return CssJs.hex("555555");
  } else if (x === "Arte") {
    return CssJs.hex("FA481C");
  } else if (x === "Outline") {
    return CssJs.hex("4D90FE");
  } else if (x === "Grey") {
    return CssJs.hex("333333");
  } else if (x === "MediumGrey") {
    return CssJs.hex("212121");
  } else if (x === "Header") {
    return CssJs.hex("151515");
  } else if (x === "Whatsapp") {
    return CssJs.hex("25D366");
  } else if (x === "LastChance") {
    return CssJs.hex("9F3166");
  } else if (x === "MostRecent") {
    return CssJs.hex("4C336B");
  } else if (x === "AudioDescription") {
    return CssJs.hex("016B79");
  } else if (x === "Emissions") {
    return CssJs.hex("8D5515");
  } else {
    return CssJs.hex("274A39");
  }
}

function varColorsToCss(colors) {
  return colors.map(function (param) {
              return CssJs.unsafe(param[0], param[1]);
            });
}

var Css = {
  color: color,
  varColorsToCss: varColorsToCss
};

function toString(c) {
  if (typeof c !== "object") {
    return Css_AtomicTypes.Color.toString(c);
  }
  var variant = c.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(c);
  } else {
    return Css_AtomicTypes.Color.toString(c);
  }
}

function overideVarColor(colors) {
  return colors.map(function (param) {
              return CssJs.unsafe(getVarName(param[0]), toString(color(param[1])));
            });
}

export {
  Var ,
  Css ,
  toString ,
  overideVarColor ,
}
/* CssJs Not a pure module */
