// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      d: "M16 31.5c-8.55 0-15.5-6.956-15.5-15.5s6.95-15.5 15.5-15.5 15.5 6.95 15.5 15.5c0 8.544-6.95 15.5-15.5 15.5zM16 2.174c-7.62 0-13.82 6.2-13.82 13.82s6.2 13.82 13.82 13.82 13.82-6.2 13.82-13.82c0-7.614-6.2-13.82-13.82-13.82z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M16 21.543c-3.057 0-5.543-2.486-5.543-5.549s2.486-5.543 5.543-5.543c3.063 0 5.543 2.486 5.543 5.543s-2.48 5.549-5.543 5.549zM16 12.137c-2.127 0-3.863 1.736-3.863 3.863 0 2.133 1.736 3.863 3.863 3.863 2.133 0 3.869-1.73 3.869-3.863s-1.736-3.863-3.869-3.863z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
