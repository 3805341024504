// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Util_Dom from "../../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as UseVisible from "../../../hook/UseVisible.res.mjs";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Atom_Loader from "../../atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_Ds from "./Organism_Slider_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as UseScreenResolution from "../../../hook/UseScreenResolution.res.mjs";
import * as Organism_Slider_Arrows from "./Organism_Slider_Arrows.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var identifier = Identifiers.Slider.item;

var newRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.overflowX(CssJs.visible),
      CssJs.overflowY(CssJs.hidden),
      CssJs.unsafe("MsOverflowStyle", "none"),
      CssJs.unsafe("scrollbarWidth", "none"),
      CssJs.selector("::-webkit-scrollbar", [CssJs.display(CssJs.none)])
    ]);

function getContainerHeight(heightComputation, sliderRef, screenSize) {
  if (typeof heightComputation !== "object") {
    return Core__Option.map(Core__Option.map(Core__Option.map(Util_Dom.getDomRef(sliderRef), (function (prim) {
                          return prim.getBoundingClientRect();
                        })), (function (prim) {
                      return prim.height;
                    })), (function (prim) {
                  return prim | 0;
                }));
  } else {
    return heightComputation._0(screenSize);
  }
}

function Organism_Slider$DefaultLoader(props) {
  var match = props.getSize(props.screenSize);
  var callback = UseVisible.Callback.make(Caml_option.some(UseVisible.Callback.makeCallback("Once", props.loadMore)), undefined, undefined, undefined, undefined);
  var match$1 = UseVisible.make(props.sliderRef, Caml_option.some(callback), undefined);
  var tmp;
  tmp = props.status === "FetchingData" ? JsxRuntime.jsx(Atom_Loader.make, {}) : null;
  return JsxRuntime.jsx(Atom_Cell.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              autoSize: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "AllPlaceNeeded")),
              width: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, match[0])),
              height: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, match[1])),
              children: tmp,
              identifier: Caml_option.some(Identifiers.Slider.loader),
              cbRef: match$1.cbRef
            });
}

var DefaultLoader = {
  make: Organism_Slider$DefaultLoader
};

function make(param) {
  var onDirectionChange = param.onDirectionChange;
  var withLoader = param.withLoader;
  var verticalPadding = param.verticalPadding;
  var sliderRef = param.sliderRef;
  var selectedItem = param.selectedItem;
  var children = param.children;
  var variant = Core__Option.getOr(param.variant, Organism_Slider_Ds.default);
  var loaderComponent = Core__Option.getOr(param.loaderComponent, DefaultLoader);
  var heightComputation = Core__Option.getOr(param.heightComputation, "Auto");
  var match = React.useState(function () {
        return "DidNotScrolledYet";
      });
  var setScrollStatus = match[1];
  var scrollStatus = match[0];
  var match$1 = React.useState(function () {
        return Util_Dom.defaultRect;
      });
  var setSliderSize = match$1[1];
  var sliderSize = match$1[0];
  var screenSize = UseScreenResolution.make(undefined, undefined);
  var match$2 = Organism_Slider_Ds.view(variant);
  var refreshItemPosition = match$2.refreshItemPosition;
  var itemSelectedPosition = match$2.itemSelectedPosition;
  var scrollSnapType = match$2.scrollSnapType;
  React.useEffect((function () {
          var exit = 0;
          if (!(refreshItemPosition === "OnMount" && scrollStatus !== "DidNotScrolledYet")) {
            exit = 1;
          }
          if (exit === 1) {
            var sliderElement = Util_Dom.getDomRef(sliderRef);
            if (sliderElement !== undefined) {
              var sliderElement$1 = Caml_option.valFromOption(sliderElement);
              var currentScroll = sliderElement$1.scrollLeft;
              var maxScroll = sliderElement$1.scrollWidth - sliderElement$1.clientWidth | 0;
              var slideMethod;
              slideMethod = itemSelectedPosition === "Center" ? (
                  selectedItem !== undefined ? (function (__x) {
                        return Organism_Slider_Arrows.slideCenter(sliderSize, selectedItem, __x);
                      }) : (function (param) {
                        return 0;
                      })
                ) : (function (__x) {
                    return Organism_Slider_Arrows.slideNext(sliderSize.left, maxScroll - currentScroll, __x);
                  });
              Core__Option.forEach(Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.flatMap(selectedItem, Util_Dom.getDomRef), (function (prim) {
                                      return prim.getBoundingClientRect();
                                    })), (function (prim) {
                                  return prim.left;
                                })), slideMethod), (function (__x) {
                          Util_Dom.scrollBy(Caml_option.some(sliderElement$1), __x);
                        })), (function () {
                      setScrollStatus(function (param) {
                            return "HasAlreadyScrolled";
                          });
                    }));
            }
            
          }
          
        }), [
        selectedItem,
        sliderRef,
        sliderSize,
        scrollStatus
      ]);
  var updateSliderSize = React.useCallback((function () {
          Core__Option.forEach(Core__Option.map(Util_Dom.getDomRef(sliderRef), Util_Dom.getElementRect), (function (n) {
                  setSliderSize(function (param) {
                        return n;
                      });
                }));
        }), [
        sliderRef,
        setSliderSize,
        children
      ]);
  var onDirectionChange$1 = function (direction) {
    if (onDirectionChange !== undefined) {
      if (direction === "Next") {
        return onDirectionChange("Next");
      } else {
        return onDirectionChange("Previous");
      }
    }
    
  };
  React.useEffect((function () {
          updateSliderSize();
        }), [
        screenSize,
        updateSliderSize
      ]);
  var getContainerHeight$1 = function (__x) {
    return getContainerHeight(heightComputation, sliderRef, __x);
  };
  var rules = React.useMemo((function () {
          if (scrollSnapType !== undefined) {
            return Particle_Screen_Ds.$$Array.concat(newRules, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Array.pure(Particle_Css.scrollSnapType(scrollSnapType))));
          } else {
            return newRules;
          }
        }), [scrollSnapType]);
  var tmp;
  tmp = withLoader !== undefined && withLoader.status !== "Completed" ? JsxRuntime.jsx(loaderComponent.make, {
          loadMore: withLoader.loadMore,
          sliderRef: sliderRef,
          screenSize: screenSize,
          getSize: withLoader.getSize,
          status: withLoader.status
        }) : null;
  return JsxRuntime.jsxs(Atom_Row.make, {
              background: param.background,
              children: [
                JsxRuntime.jsxs(Atom_Row.make, {
                      blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical(verticalPadding))),
                      children: [
                        children,
                        tmp
                      ],
                      innerSpace: param.itemSpace,
                      justifyContent: param.justifyContent,
                      rules: Caml_option.some(rules),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                      wrap: "OnOneLine",
                      ref_: sliderRef
                    }),
                JsxRuntime.jsx(Organism_Slider_Arrows.make, {
                      sliderSize: sliderSize,
                      sliderRef: sliderRef,
                      verticalPadding: verticalPadding,
                      selectElements: param.selectItems,
                      getContainerHeight: getContainerHeight$1,
                      arrowPosition: match$2.arrowPosition,
                      onDirectionChange: onDirectionChange$1
                    })
              ],
              identifier: Caml_option.some(identifier),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

make.displayName = "Slider";

var width = Particle_Screen_Constants.oneHundredPctWidth;

export {
  identifier ,
  newRules ,
  width ,
  getContainerHeight ,
  DefaultLoader ,
  make ,
}
/* newRules Not a pure module */
