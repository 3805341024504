// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../CssJs.res.mjs";
import * as Type_Image from "../type/Type_Image.res.mjs";
import * as Particle_Css_font from "../design-system/particle/Particle_Css_font.res.mjs";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";

function createIdentifierSelector(attributes) {
  return attributes.map(function (identifier) {
                return "[data-testid='" + identifier + "']";
              }).join(", ");
}

function toRules(toCssRule) {
  return function (value) {
    return [toCssRule(value)];
  };
}

function preserveAspectRatioRules(ratio) {
  var match = Type_Image.Ratio.getDimensions(Type_Image.Ratio.make(ratio));
  if (match !== undefined) {
    return [CssJs.before([
                  CssJs.display("block"),
                  CssJs.unsafe("content", ""),
                  CssJs.width(CssJs.px(1)),
                  CssJs.marginLeft(CssJs.px(-1)),
                  CssJs.$$float("left"),
                  CssJs.height(CssJs.px(0)),
                  CssJs.paddingTop(CssJs.pct(match.height / match.width * 100))
                ])];
  } else {
    return [];
  }
}

function gapPolyfill(gap) {
  return [
          CssJs.margin({
                NAME: "pxFloat",
                VAL: gap / -2
              }),
          CssJs.children([CssJs.margin({
                      NAME: "pxFloat",
                      VAL: gap / 2
                    })])
        ];
}

function calcTextLineSize(lineHeight, size) {
  return Particle_Screen_Ds.map(size, (function (fontSize) {
                var lineHeightCoeff = Particle_Css_font.line_height_float(lineHeight);
                var fontSizeValue = Particle_Css_font.size_int(fontSize);
                return CssJs.px(lineHeightCoeff * fontSizeValue | 0);
              }));
}

var fromL = Particle_Screen_Ds.make(undefined, undefined, undefined, "flex", undefined, "none");

var untilL = Particle_Screen_Ds.make(undefined, undefined, undefined, "none", undefined, "flex");

var Display = {
  fromL: fromL,
  untilL: untilL
};

export {
  createIdentifierSelector ,
  toRules ,
  preserveAspectRatioRules ,
  gapPolyfill ,
  calcTextLineSize ,
  Display ,
}
/* fromL Not a pure module */
