// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Type_Spacer from "../../type/Type_Spacer.res.mjs";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.res.mjs";

var Constants = {
  zero: 0,
  none: 0,
  xxxs: 2,
  xxs: 5,
  xs: 10,
  s: 15,
  m: 25,
  l: 30,
  xl: 60
};

function spacerToString(spacer) {
  if (spacer === "M") {
    return "M";
  } else if (spacer === "S") {
    return "S";
  } else if (spacer === "XL") {
    return "XL";
  } else if (spacer === "XS") {
    return "XS";
  } else if (spacer === "XXS") {
    return "XXS";
  } else if (spacer === "None") {
    return "None";
  } else if (spacer === "XXXS") {
    return "XXXS";
  } else if (spacer === "Zero") {
    return "Zero";
  } else {
    return "L";
  }
}

function getVarName(t) {
  var varName = t === "M" ? "spacer-m" : (
      t === "S" ? "spacer-s" : (
          t === "XL" ? "spacer-xl" : (
              t === "XS" ? "spacer-xs" : (
                  t === "XXS" ? "spacer-xxs" : (
                      t === "None" ? "spacer-none" : (
                          t === "XXXS" ? "spacer-xxxs" : (
                              t === "Zero" ? "spacer-0" : "spacer-l"
                            )
                        )
                    )
                )
            )
        )
    );
  return Type_Spacer.Var.prefix + varName;
}

function spacerToVarName(spacer) {
  return CssJs.$$var(getVarName(spacer));
}

function spacerToInt(spacer) {
  if (spacer === "M") {
    return 25;
  } else if (spacer === "S") {
    return 15;
  } else if (spacer === "XL") {
    return 60;
  } else if (spacer === "XS") {
    return 10;
  } else if (spacer === "XXS") {
    return 5;
  } else if (spacer === "None") {
    return 0;
  } else if (spacer === "XXXS") {
    return 2;
  } else if (spacer === "Zero") {
    return 0;
  } else {
    return 30;
  }
}

var generateSpacer = [
  [
    getVarName("Zero"),
    CssJs.px(0)
  ],
  [
    getVarName("None"),
    CssJs.px(0)
  ],
  [
    getVarName("XXXS"),
    CssJs.px(2)
  ],
  [
    getVarName("XXS"),
    CssJs.px(5)
  ],
  [
    getVarName("XS"),
    CssJs.px(10)
  ],
  [
    getVarName("S"),
    CssJs.px(15)
  ],
  [
    getVarName("M"),
    CssJs.px(25)
  ],
  [
    getVarName("L"),
    CssJs.px(30)
  ],
  [
    getVarName("XL"),
    CssJs.px(60)
  ]
];

var varSpacerCss = generateSpacer.map(function (param) {
      var value = Css_AtomicTypes.Length.toString(CssJs.px(param[1].VAL));
      return CssJs.unsafe(param[0], value);
    });

export {
  Constants ,
  spacerToString ,
  getVarName ,
  spacerToVarName ,
  spacerToInt ,
  generateSpacer ,
  varSpacerCss ,
}
/* generateSpacer Not a pure module */
