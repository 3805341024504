// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";

function toCss(param) {
  var borderStyle = param.borderStyle;
  var wrapper = [
      CssJs.focus(Particle_Border_Css.toCss(borderStyle.focus)),
      CssJs.active(Particle_Border_Css.toCss(borderStyle.active)),
      CssJs.cursor(CssJs.pointer),
      CssJs.justifyContent(CssJs.center),
      CssJs.hover([CssJs.opacity(0.7)])
    ].concat(Particle_Border_Css.toCss(borderStyle.inactive));
  var line = [
      CssJs.display(CssJs.block),
      CssJs.transition(250, undefined, Css.ease, "transform"),
      CssJs.margin2(Css.px(2), Css.px(0))
    ].concat(Particle_Border_Css.toCss(borderStyle.line));
  var transform = [
    CssJs.nthChild({
          NAME: "n",
          VAL: 1
        }, [CssJs.transforms([
                CssJs.rotate(CssJs.deg(45)),
                CssJs.translate(CssJs.px(2), CssJs.px(2))
              ])]),
    CssJs.nthChild({
          NAME: "n",
          VAL: 2
        }, [CssJs.transforms([
                CssJs.rotate(CssJs.deg(-45)),
                CssJs.translate(CssJs.px(2), CssJs.px(-2))
              ])])
  ];
  return {
          wrapper: wrapper,
          transform: transform,
          line: line
        };
}

export {
  toCss ,
}
/* Css Not a pure module */
