// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function isIos(userAgent) {
  return Core__Option.getOr(Core__Option.map(userAgent, (function (userAgent) {
                    return new RegExp("(iPad|iPhone|iPod)", "i").test(userAgent);
                  })), false);
}

function isAndroid(userAgent) {
  return Core__Option.getOr(Core__Option.map(userAgent, (function (userAgent) {
                    return new RegExp("android", "i").test(userAgent);
                  })), false);
}

var userAgent = Util_Dom.SafeWebApi.userAgent;

var match = Util_Dom.SafeWebApi.platform;

var match$1 = isIos(userAgent);

var match$2 = isAndroid(userAgent);

var platform;

var exit = 0;

if (match !== undefined) {
  switch (match) {
    case "Linux" :
        platform = "Linux";
        break;
    case "Mac" :
        platform = "Mac";
        break;
    case "Windows" :
        platform = "Windows";
        break;
    default:
      exit = 1;
  }
} else {
  exit = 1;
}

if (exit === 1) {
  platform = match$1 ? (
      match$2 ? "Other" : "IOS"
    ) : (
      match$2 ? "Android" : "Other"
    );
}

export {
  isIos ,
  isAndroid ,
  userAgent ,
  platform ,
}
/* match Not a pure module */
