// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M20.1662 16.443L14.3292 19.1683C13.8186 19.4049 13.4 19.1397 13.4 18.576V13.4426C13.4 12.8794 13.8186 12.6132 14.3292 12.8492L20.1662 15.5746C20.68 15.8132 20.68 16.2032 20.1662 16.443ZM16 6C3.2236 6 3 7.13568 3 16.01C3 24.8843 3.2236 26.02 16 26.02C28.7764 26.02 29 24.8839 29 16.01C29 7.1362 28.7764 6 16 6Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
