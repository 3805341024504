// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M19.3636 20.5108C19.8328 20.0428 19.8328 19.2832 19.3636 18.814L17.0404 16.4908V9.2812C17.0404 8.6176 16.504 8.0812 15.8404 8.0812C15.178 8.0812 14.6404 8.6176 14.6404 9.2812V17.4844L17.6668 20.5108C17.9008 20.7448 18.208 20.8624 18.5152 20.8624C18.8224 20.8624 19.1296 20.7448 19.3636 20.5108Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M5.3608 15.9988C5.3608 21.868 10.1332 26.6404 16 26.6404C21.868 26.6404 26.6416 21.8668 26.6416 15.9988C26.6404 10.132 21.868 5.3584 16 5.3584C10.1332 5.3584 5.3608 10.1332 5.3608 15.9988ZM16 28C9.3832 28 4 22.6168 4 15.9988C4 9.3832 9.3832 4 16 4C22.6168 4 28 9.3832 28 15.9988C28 22.6168 22.6168 28 16 28Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
