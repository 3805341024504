// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";

function makeVariant(t) {
  if (typeof t === "object") {
    return t._0;
  }
  switch (t) {
    case "CarouselArrow" :
    case "Modal" :
        return 10;
    case "OverlayEventZone" :
        return 3;
    case "RedirectionAnchor" :
        return -1;
    case "SliderArrow" :
        return 25;
    case "Header" :
    case "Teaser" :
        return 100;
    case "RedirectionAnchorFocused" :
    case "TopBanner" :
        return 2;
    case "FeedbackButton" :
        return 1000;
    default:
      return 1;
  }
}

function make(t) {
  switch (t.TAG) {
    case "Exact" :
        return makeVariant(t._0);
    case "Above" :
        return makeVariant(t._0) + 1 | 0;
    case "Behind" :
        return makeVariant(t._0) - 1 | 0;
    
  }
}

function toCss(t) {
  return CssJs.zIndex(make(t));
}

var Particle_zIndex_Css = {
  toCss: toCss
};

export {
  makeVariant ,
  make ,
  Particle_zIndex_Css ,
}
/* CssJs Not a pure module */
