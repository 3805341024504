// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Text from "./Atom_Text.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Animation_Ds from "../particle/Particle_Animation_Ds.res.mjs";
import * as Particle_Animation_Css from "../particle/Particle_Animation_Css.res.mjs";

function make(loaderKind) {
  switch (loaderKind) {
    case "Default" :
        return {
                size: 30
              };
    case "Player" :
        return {
                size: 70
              };
    case "AndroidReleases" :
        return {
                size: 50
              };
    case "GuideTv" :
    case "ProfileForm" :
        return {
                size: 60
              };
    
  }
}

function view(param) {
  return {
          size: param.size
        };
}

var spinnerAnimationRules = Particle_Animation_Css.toCss(Particle_Animation_Ds.Loader.main);

var circleInnerAnimationRules = Particle_Animation_Css.toCss(Particle_Animation_Ds.Loader.firstFragment);

var circleInner2AnimationRules = Particle_Animation_Css.toCss(Particle_Animation_Ds.Loader.secondFragment);

function toCss(dsStyle) {
  var match = view(dsStyle);
  var size = match.size;
  var borderSize = size / 7 | 0;
  var spinner = [
      CssJs.width(CssJs.px(size)),
      CssJs.height(CssJs.px(size)),
      CssJs.display(CssJs.inlineBlock)
    ].concat(spinnerAnimationRules);
  var circle = [
    CssJs.width(CssJs.px(size)),
    CssJs.height(CssJs.px(size / 2 | 0)),
    CssJs.overflow(CssJs.hidden)
  ];
  var circle2 = [CssJs.transform(CssJs.rotate(CssJs.deg(180)))];
  var circleInner = [
      CssJs.transform(CssJs.rotate(CssJs.deg(45))),
      CssJs.borderRadius(CssJs.pct(50)),
      CssJs.border(CssJs.px(borderSize), CssJs.solid, Particle_Color.Css.color("Text")),
      CssJs.borderRight(CssJs.px(borderSize), CssJs.solid, Particle_Color.Css.color("Transparent")),
      CssJs.borderBottom(CssJs.px(borderSize), CssJs.solid, Particle_Color.Css.color("Transparent")),
      CssJs.width(CssJs.pct(100)),
      CssJs.height(CssJs.pct(200))
    ].concat(circleInnerAnimationRules);
  return [
          spinner,
          circle,
          circle2,
          circleInner,
          circleInner2AnimationRules
        ];
}

function make$1(param) {
  var label = Core__Option.getOr(param.label, "Loading");
  var variant = Core__Option.getOr(param.variant, "default");
  var dsVariant = variant === "profileForm" ? "ProfileForm" : (
      variant === "androidReleases" ? "AndroidReleases" : (
          variant === "player" ? "Player" : (
              variant === "guideTv" ? "GuideTv" : "Default"
            )
        )
    );
  var variant$1 = make(dsVariant);
  var match = toCss(variant$1);
  var circleInner = match[3];
  var circle = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            className: (function (__x) {
                                  return CssJs.style(__x);
                                })(circleInner)
                          }),
                      className: (function (__x) {
                            return CssJs.style(__x);
                          })(circle)
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            className: CssJs.merge([
                                  (function (__x) {
                                        return CssJs.style(__x);
                                      })(circleInner),
                                  (function (__x) {
                                        return CssJs.style(__x);
                                      })(match[4])
                                ])
                          }),
                      className: CssJs.merge([
                            (function (__x) {
                                  return CssJs.style(__x);
                                })(circle),
                            (function (__x) {
                                  return CssJs.style(__x);
                                })(match[2])
                          ])
                    }),
                JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                      children: label
                    })
              ],
              className: (function (__x) {
                    return CssJs.style(__x);
                  })(match[0])
            });
}

export {
  make$1 as make,
}
/* spinnerAnimationRules Not a pure module */
