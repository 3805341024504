// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Image from "../../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Newsletter from "../footer/Organism_Newsletter.res.mjs";
import * as Organism_FooterLabels from "../footer/Organism_FooterLabels.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.Outside.makeVerticalAndHorizontal("S", "XL"), undefined, undefined, Particle_Spacer_Ds.Outside.makeVerticalAndHorizontal("S", "None"));

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, "spaceBetween", undefined, undefined, "flexStart");

var Container = {
  blockSpace: blockSpace,
  justifyContent: justifyContent
};

var width = Particle_Screen_Ds.make(undefined, undefined, undefined, Css.pct(56), undefined, CssJs.pct(100));

var FormContainer = {
  width: width
};

var display = Particle_Screen_Ds.make(undefined, undefined, undefined, "inlineFlex", undefined, "none");

var width$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, Css.pct(44), undefined, CssJs.pct(100));

var ImageContainer = {
  display: display,
  width: width$1
};

var Organism_NewsletterZone_Css = {
  Container: Container,
  FormContainer: FormContainer,
  ImageContainer: ImageContainer
};

function make(param) {
  var teaser = param.teaser;
  var match = Context.Config.use();
  var match$1 = Context.I18n.use();
  var match$2 = Organism_FooterLabels.getLabels(match$1.locale);
  var newsletter = match$2.newsletter;
  var teaserTitle = Type_String.Teaser.Title.toString(Core__Option.getOr(Type_Teaser.Helper.extractTitle(teaser), Type_String.Teaser.Title.empty));
  var labels_termOfUse = {
    text: newsletter.termOfUse.text,
    link: newsletter.termOfUse.link,
    textLink: newsletter.termOfUse.textLink
  };
  var labels_wrongEmailFormat = newsletter.wrongEmailFormat;
  var labels_button = Type_String.CallToAction.toString(Core__Option.getOr(teaser.callToAction, Type_String.CallToAction.empty));
  var labels_email = newsletter.email;
  var labels_inputTitle = newsletter.inputTitle;
  var labels_buttonAriaLabel = newsletter.buttonAriaLabel;
  var labels = {
    title: teaserTitle,
    termOfUse: labels_termOfUse,
    wrongEmailFormat: labels_wrongEmailFormat,
    button: labels_button,
    email: labels_email,
    inputTitle: labels_inputTitle,
    buttonAriaLabel: labels_buttonAriaLabel
  };
  var background = Particle_Background_Ds.Newsletter.newsletterZone(match.cdnUrl);
  var src = teaser.imageSrc;
  return JsxRuntime.jsxs(Atom_Row.make, {
              area: "newsletter",
              background: Caml_option.some(background),
              blockSpace: Caml_option.some(blockSpace),
              children: [
                JsxRuntime.jsx(Atom_Column.make, {
                      blockSpace: param.blockSpace,
                      children: JsxRuntime.jsx(Organism_Newsletter.make, {
                            labels: labels,
                            style: "Zone",
                            source: "replay_banner"
                          }),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      width: Caml_option.some(width)
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Image.make, {
                            alt: "",
                            src: src,
                            style: "Newsletter"
                          }),
                      display: Caml_option.some(display),
                      width: Caml_option.some(width$1)
                    })
              ],
              identifier: Caml_option.some(Identifiers.Zone_Layout.newsletter),
              justifyContent: Caml_option.some(justifyContent),
              ref_: param.ref_
            });
}

export {
  Organism_NewsletterZone_Css ,
  make ,
}
/* blockSpace Not a pure module */
