// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as SetFocus from "../../../../hook/SetFocus.res.mjs";
import * as Util_Dom from "../../../../util/Util_Dom.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Binding_Date from "../../../../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as $$Date from "@artegeie/date";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_GuideTvCalendar from "../../../organism/slider/guideTv/Organism_Slider_GuideTvCalendar.res.mjs";

function createCurrentAriaLabel(getDayLabel, getDateLabel, items, currentDate, infoSelectedDay) {
  var defaultDaysInterval = (21).toString();
  var currentIndex = Core__Array.findIndexOpt(items, (function (param) {
          return $$Date.isSameDay(param.value, currentDate);
        }));
  var daysBefore = Core__Option.getOr(Core__Option.map(currentIndex, (function (__x) {
              return __x.toString(10);
            })), defaultDaysInterval);
  var daysAfter = Core__Option.mapOr(currentIndex, defaultDaysInterval, (function (currentIndex) {
          return ((items.length - currentIndex | 0) - 1 | 0).toString();
        }));
  return infoSelectedDay(getDayLabel(currentDate) + " " + getDateLabel(currentDate), daysBefore, daysAfter);
}

function makeTitle(todayLabel, tomorrowLabel, yesterdayLabel, date) {
  var match = Binding_Date.dateToAdverbOfTime(date);
  switch (match) {
    case "Today" :
        return Caml_option.some(todayLabel);
    case "Yesterday" :
        return Caml_option.some(yesterdayLabel);
    case "Tomorrow" :
        return Caml_option.some(tomorrowLabel);
    case "Other" :
        return ;
    
  }
}

function Layout_Guide_Tv_Slider(props) {
  var layoutGuideColumnsRef = props.layoutGuideColumnsRef;
  var date = props.date;
  var onClickItem = props.onClickItem;
  var match = Context.I18n.use();
  var match$1 = match.formaters;
  var dateWithFormat = match$1.dateWithFormat;
  var match$2 = match.labels.guide.tv.daySlider;
  var yesterdayLabel = match$2.yesterday;
  var tomorrowLabel = match$2.tomorrow;
  var todayLabel = match$2.today;
  var dayFormat = match$2.dayFormat;
  var match$3 = Context.Config.use();
  var today = match$3.serverTime;
  var start = $$Date.subWeeks(today, 3);
  var end = $$Date.addWeeks(today, 3);
  var dateRange = $$Date.getDaysInRange(start, end);
  var setFocusOnContainer = function () {
    SetFocus.setFocus(Util_Dom.getDomRef(layoutGuideColumnsRef));
  };
  var match$4 = React.useState(function () {
        return date;
      });
  var setCurrentValue = match$4[1];
  var currentValue = match$4[0];
  var makeTitle$1 = function (date) {
    return makeTitle(todayLabel, tomorrowLabel, yesterdayLabel, date);
  };
  var getDayLabel = function (date) {
    return Core__Option.getOr(makeTitle$1(date), dateWithFormat(date, Binding_Date.Format.fullWeekDay));
  };
  var getDateLabel = function (date) {
    return dateWithFormat(date, dayFormat);
  };
  var leftActionLabel = match$2.redirectProgram + " " + dateWithFormat(date, match$2.dateFormat);
  var sliderItems = dateRange.map(function (date) {
        date.setHours(12);
        return {
                dayLabel: getDayLabel(date),
                dateLabel: dateWithFormat(date, dayFormat),
                value: date
              };
      });
  var leftActionOnClick = function (param) {
    setCurrentValue(function (param) {
          return date;
        });
    setFocusOnContainer();
  };
  var leftAction_1 = {
    onClick: leftActionOnClick,
    label: leftActionLabel
  };
  var leftAction = {
    NAME: "Button",
    VAL: leftAction_1
  };
  var currentAriaLabel = createCurrentAriaLabel(getDayLabel, getDateLabel, sliderItems, currentValue, match$1.infoSelectedDay);
  var onClick = function (value) {
    setCurrentValue(function (param) {
          return value;
        });
    onClickItem(value);
    setFocusOnContainer();
  };
  return JsxRuntime.jsx(Organism_Slider_GuideTvCalendar.make, {
              currentAriaLabel: currentAriaLabel,
              currentValue: currentValue,
              items: sliderItems,
              onItemClick: onClick,
              leftAction: leftAction
            });
}

var make = Layout_Guide_Tv_Slider;

export {
  createCurrentAriaLabel ,
  makeTitle ,
  make ,
}
/* react Not a pure module */
