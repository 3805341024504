// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Header from "../../type/Type_Header.res.mjs";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function getPredefinedHeight(t) {
  return Particle_Screen_Ds.map(t.shape, (function (shape) {
                if (typeof shape !== "object") {
                  return ;
                } else {
                  return shape._0;
                }
              }));
}

function getPredefinedWidth(t) {
  return Particle_Screen_Ds.map(t.shape, (function (shape) {
                if (typeof shape !== "object") {
                  return ;
                }
                switch (shape.TAG) {
                  case "RectangleWithStaticHeight" :
                      return ;
                  case "Circle" :
                  case "Square" :
                      return shape._0;
                  
                }
              }));
}

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var spacerVerticalSmaller = Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S");

var particleSpacerVerticalSmaller = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, spacerVerticalSmaller);

var particleSpacerVerticalSmallerWithMedValue = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("M"), undefined, undefined, spacerVerticalSmaller);

function topTeaserButtons_textStyleDefault(asset) {
  return Atom_Text_Ds.makeTopTeaser(asset, {
              NAME: "Cta",
              VAL: "Default"
            });
}

function topTeaserButtons_textStyleDisabled(asset) {
  return Atom_Text_Ds.makeTopTeaser(asset, {
              NAME: "Cta",
              VAL: "Disabled"
            });
}

var topTeaserButtons_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: 40
    });

var topTeaserButtons_spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

var topTeaserButtons_iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var topTeaserButtons = {
  textStyleDefault: topTeaserButtons_textStyleDefault,
  textStyleDisabled: topTeaserButtons_textStyleDisabled,
  shape: topTeaserButtons_shape,
  spacer: topTeaserButtons_spacer,
  iconSize: topTeaserButtons_iconSize
};

var default_spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("None"));

var default_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Rectangle");

var default_hover = "Opacity";

var default_zIndex = {
  TAG: "Exact",
  _0: "Cta"
};

var $$default = {
  textStyle: Atom_Text_Ds.cta,
  background: Particle_Background_Ds.transparent,
  spacer: default_spacer,
  shape: default_shape,
  borderFocus: Particle_Border_Ds.accessibility,
  hover: default_hover,
  kind: "Normal",
  zIndex: default_zIndex
};

function makeStyleWatch(progression, icon, backgroundColor, color) {
  var textStyle;
  if (icon !== undefined) {
    var icon$1 = Caml_option.valFromOption(icon);
    textStyle = color !== undefined ? Atom_Text_Ds.makeCtaWithAssetAndColor({
            TAG: "Icon",
            _0: {
              position: {
                TAG: "Left",
                _0: innerSpace
              },
              icon: icon$1,
              size: topTeaserButtons_iconSize
            }
          }, {
            TAG: "Main",
            _0: color
          }) : Atom_Text_Ds.makeCtaWithAsset({
            TAG: "Icon",
            _0: {
              position: {
                TAG: "Left",
                _0: innerSpace
              },
              icon: icon$1,
              size: topTeaserButtons_iconSize
            }
          });
  } else {
    textStyle = color !== undefined ? Atom_Text_Ds.makeCtaWithColor({
            TAG: "Main",
            _0: color
          }) : Atom_Text_Ds.cta;
  }
  var border = progression !== undefined ? Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.makeProgression(progression))) : undefined;
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.block));
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord.shape = topTeaserButtons_shape;
  newrecord.border = border;
  newrecord.spacer = topTeaserButtons_spacer;
  newrecord.background = backgroundColor;
  newrecord.textStyle = textStyle;
  return newrecord;
}

var shapeArrow = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Circle",
      _0: 40
    });

var shapeControl = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Circle",
      _0: 16
    }, undefined, undefined, {
      TAG: "Circle",
      _0: 12
    });

var newrecord = Caml_obj.obj_dup($$default);

newrecord.hover = {
  TAG: "Background",
  _0: "LightGrey"
};

newrecord.block = {
  justifyContent: "center",
  alignItems: "center",
  wrap: "OnOneLine"
};

newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "M"));

newrecord.background = Particle_Background_Ds.AndroidReleases.ctaTutorial;

newrecord.textStyle = Atom_Text_Ds.AndroidReleases.heroTutorialButton;

var newrecord$1 = Caml_obj.obj_dup($$default);

newrecord$1.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.AndroidRealease.ctaDownload));

newrecord$1.spacer = particleSpacerVerticalSmaller;

newrecord$1.textStyle = Atom_Text_Ds.AndroidReleases.ctaDownload;

var AndroidReleases = {
  tutorial: newrecord,
  download: newrecord$1
};

var newrecord$2 = Caml_obj.obj_dup($$default);

newrecord$2.hover = "Scale";

newrecord$2.block = {
  justifyContent: "center"
};

newrecord$2.shape = shapeArrow;

newrecord$2.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.sliderButton));

newrecord$2.background = Particle_Background_Ds.white;

newrecord$2.textStyle = Atom_Text_Ds.Carousel.makeArrow("Left");

var newrecord$3 = Caml_obj.obj_dup($$default);

newrecord$3.hover = "Scale";

newrecord$3.block = {
  justifyContent: "center"
};

newrecord$3.shape = shapeArrow;

newrecord$3.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.sliderButton));

newrecord$3.background = Particle_Background_Ds.white;

newrecord$3.textStyle = Atom_Text_Ds.Carousel.makeArrow("Right");

var newrecord$4 = Caml_obj.obj_dup($$default);

newrecord$4.shape = shapeControl;

newrecord$4.textStyle = Atom_Text_Ds.Carousel.pause;

var newrecord$5 = Caml_obj.obj_dup($$default);

newrecord$5.shape = shapeControl;

newrecord$5.textStyle = Atom_Text_Ds.Carousel.play;

var newrecord$6 = Caml_obj.obj_dup($$default);

newrecord$6.shape = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Circle",
      _0: 10
    }, undefined, undefined, {
      TAG: "Circle",
      _0: 8
    });

newrecord$6.background = Particle_Background_Ds.white;

newrecord$6.textStyle = Atom_Text_Ds.Carousel.bullet;

function makeBulletActive(category) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.shape = shapeControl;
  newrecord.background = Particle_Background_Ds.activeBullet(category);
  newrecord.textStyle = Atom_Text_Ds.Carousel.bullet;
  return newrecord;
}

var Carousel = {
  previousArrow: newrecord$2,
  nextArrow: newrecord$3,
  controlPause: newrecord$4,
  controlPlay: newrecord$5,
  bulletInactive: newrecord$6,
  makeBulletActive: makeBulletActive
};

var newrecord$7 = Caml_obj.obj_dup($$default);

newrecord$7.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));

newrecord$7.background = Particle_Background_Ds.primary;

newrecord$7.textStyle = Atom_Text_Ds.Corporate.primary;

var newrecord$8 = Caml_obj.obj_dup($$default);

newrecord$8.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));

newrecord$8.background = Particle_Background_Ds.secondary;

newrecord$8.textStyle = Atom_Text_Ds.Corporate.secondary;

var newrecord$9 = Caml_obj.obj_dup(newrecord$8);

newrecord$9.background = Particle_Background_Ds.orange;

newrecord$9.textStyle = Atom_Text_Ds.Corporate.arte;

var newrecord$10 = Caml_obj.obj_dup($$default);

newrecord$10.hover = {
  TAG: "Border",
  _0: Particle_Border_Ds.Textfield.inactive
};

newrecord$10.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.active));

newrecord$10.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));

newrecord$10.background = Particle_Background_Ds.transparent;

newrecord$10.textStyle = Atom_Text_Ds.Corporate.outline;

var newrecord$11 = Caml_obj.obj_dup($$default);

newrecord$11.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$11.textStyle = Atom_Text_Ds.Corporate.link;

var newrecord$12 = Caml_obj.obj_dup($$default);

newrecord$12.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$12.hover = {
  TAG: "Dash",
  _0: "Text",
  _1: "S"
};

newrecord$12.block = {
  justifyContent: "flexStart",
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
  wrap: "OnOneLine"
};

newrecord$12.borderFocus = Particle_Border_Ds.Corporate.accessibility;

newrecord$12.shape = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.m
    }, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.default
    });

newrecord$12.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("S"));

newrecord$12.background = Particle_Background_Ds.Corporate.menu;

newrecord$12.textStyle = Atom_Text_Ds.Corporate.menuItem;

function makeBackgroundByHighlight(highlight) {
  if (highlight) {
    return Particle_Background_Ds.black;
  } else {
    return Particle_Background_Ds.Corporate.menu;
  }
}

function makeToggleButton(status) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = undefined;
  newrecord.border = status === "active" ? Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Corporate.active)) : Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Corporate.inactive));
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));
  newrecord.background = Particle_Background_Ds.toggleCorporateButton;
  newrecord.textStyle = Atom_Text_Ds.Corporate.makeToggleButton(status);
  return newrecord;
}

var newrecord$13 = Caml_obj.obj_dup(newrecord$12);

newrecord$13.hover = {
  TAG: "Dash",
  _0: "White",
  _1: "S"
};

newrecord$13.background = Particle_Background_Ds.black;

newrecord$13.textStyle = Atom_Text_Ds.Corporate.menuItemCurrent;

function makeMenuItemWithChilds(status, highlight) {
  var newrecord$14 = Caml_obj.obj_dup(newrecord$12);
  newrecord$14.background = makeBackgroundByHighlight(highlight);
  newrecord$14.textStyle = Atom_Text_Ds.Corporate.makeMenuItemWithChilds(highlight, status);
  return newrecord$14;
}

function makeMenuItemChild(highlight) {
  var newrecord$14 = Caml_obj.obj_dup(newrecord$12);
  newrecord$14.hover = undefined;
  newrecord$14.block = {
    justifyContent: "flexStart",
    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
    wrap: "OnOneLine"
  };
  newrecord$14.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Rectangle");
  newrecord$14.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Particle_Border_Ds.Corporate.menuItemChild, undefined, undefined, Particle_Border_Ds.none));
  newrecord$14.spacer = particleSpacerVerticalSmaller;
  newrecord$14.background = makeBackgroundByHighlight(highlight);
  newrecord$14.textStyle = Atom_Text_Ds.Corporate.makeMenuItemChild(highlight);
  return newrecord$14;
}

var newrecord$14 = Caml_obj.obj_dup(newrecord$12);

newrecord$14.hover = {
  TAG: "Color",
  _0: "Black"
};

newrecord$14.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XS"));

newrecord$14.textStyle = Atom_Text_Ds.Corporate.searchButton;

var Corporate = {
  primary: newrecord$7,
  secondary: newrecord$8,
  arte: newrecord$9,
  outline: newrecord$10,
  link: newrecord$11,
  menuItemBaseStyle: newrecord$12,
  makeBackgroundByHighlight: makeBackgroundByHighlight,
  makeToggleButton: makeToggleButton,
  menuItemDefault: newrecord$12,
  menuItemCurrent: newrecord$13,
  makeMenuItemWithChilds: makeMenuItemWithChilds,
  makeMenuItemChild: makeMenuItemChild,
  searchButton: newrecord$14
};

var newrecord$15 = Caml_obj.obj_dup($$default);

newrecord$15.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser));

newrecord$15.spacer = particleSpacerVerticalSmaller;

newrecord$15.textStyle = Atom_Text_Ds.Teaser.Hover.toParent(Atom_Text_Ds.Teaser.Hover.cta({
          TAG: "Icon",
          _0: {
            position: {
              TAG: "Left",
              _0: innerSpace
            },
            icon: Atom_Icon_Ds.trash,
            size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
          }
        }, undefined));

var Delete = {
  $$default: newrecord$15
};

function makeDefault(textStyle, status) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));
  newrecord.background = status === "Disabled" ? Particle_Background_Ds.disabledButtonUser : Particle_Background_Ds.white;
  newrecord.textStyle = textStyle;
  return newrecord;
}

var newrecord$16 = Caml_obj.obj_dup($$default);

newrecord$16.hover = undefined;

function makeButton(ctaStatus, playableStatus) {
  return makeDefault(Atom_Text_Ds.EventZone.button(ctaStatus, playableStatus), ctaStatus);
}

function makeActionButton(status) {
  return makeDefault(Atom_Text_Ds.EventZone.actionButton(status), "Enabled");
}

var EventZone = {
  makeDefault: makeDefault,
  image: newrecord$16,
  makeButton: makeButton,
  makeActionButton: makeActionButton
};

function makeFavoriteStyle(textStyle, borderOpt) {
  var border = borderOpt !== undefined ? Caml_option.valFromOption(borderOpt) : Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeRegular));
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.shape = topTeaserButtons_shape;
  newrecord.border = border;
  newrecord.spacer = topTeaserButtons_spacer;
  newrecord.textStyle = textStyle;
  return newrecord;
}

var makeDefaultFavoriteStyle = makeFavoriteStyle(Atom_Text_Ds.Favorite.makeFavorite("Default"), undefined);

var makeFilledFavoriteStyle = makeFavoriteStyle(Atom_Text_Ds.Favorite.makeFavorite("Filled"), undefined);

var makeDefaultAlertStyle = makeFavoriteStyle(Atom_Text_Ds.Favorite.makeAlert("Default"), undefined);

var makeStrikeAlertStyle = makeFavoriteStyle(Atom_Text_Ds.Favorite.makeAlert("Striked"), undefined);

function make(style, variation, availability) {
  if (style === "Add") {
    if (typeof variation !== "object") {
      if (availability === "Available") {
        return makeDefaultFavoriteStyle;
      } else {
        return makeDefaultAlertStyle;
      }
    } else if (availability === "Available") {
      return makeFavoriteStyle(Atom_Text_Ds.Favorite.makeFavoriteTeaserHover("Default"), Caml_option.some(Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser))));
    } else {
      return makeFavoriteStyle(Atom_Text_Ds.Favorite.makeAlertTeaserHover("Default"), Caml_option.some(Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser))));
    }
  } else if (typeof variation !== "object") {
    if (availability === "Available") {
      return makeFilledFavoriteStyle;
    } else {
      return makeStrikeAlertStyle;
    }
  } else if (availability === "Available") {
    return makeFavoriteStyle(Atom_Text_Ds.Favorite.makeFavoriteTeaserHover("Filled"), Caml_option.some(Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser))));
  } else {
    return makeFavoriteStyle(Atom_Text_Ds.Favorite.makeAlertTeaserHover("Striked"), Caml_option.some(Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser))));
  }
}

var Favorite = {
  makeFavoriteStyle: makeFavoriteStyle,
  makeDefaultFavoriteStyle: makeDefaultFavoriteStyle,
  makeFilledFavoriteStyle: makeFilledFavoriteStyle,
  makeDefaultAlertStyle: makeDefaultAlertStyle,
  makeStrikeAlertStyle: makeStrikeAlertStyle,
  make: make
};

var newrecord$17 = Caml_obj.obj_dup($$default);

newrecord$17.hover = {
  TAG: "Border",
  _0: Particle_Border_Ds.Textfield.active
};

newrecord$17.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.inactive));

newrecord$17.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$17.textStyle = Atom_Text_Ds.Footer.newsletter;

var newrecord$18 = Caml_obj.obj_dup($$default);

newrecord$18.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inline));

newrecord$18.shape = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Circle",
      _0: 16
    }, undefined, undefined, {
      TAG: "Circle",
      _0: 12
    });

newrecord$18.textStyle = Atom_Text_Ds.makeTos({
      NAME: "Cta",
      VAL: "Default"
    });

function makePartner(logo) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.textStyle = Atom_Text_Ds.Footer.makePartner(logo);
  return newrecord;
}

var newrecord$19 = Caml_obj.obj_dup($$default);

newrecord$19.textStyle = Atom_Text_Ds.Footer.make({
      NAME: "Cta",
      VAL: "Default"
    });

var newrecord$20 = Caml_obj.obj_dup($$default);

newrecord$20.hover = "Underline";

newrecord$20.textStyle = Atom_Text_Ds.Footer.title;

function makeTitle(status) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = undefined;
  newrecord.textStyle = Atom_Text_Ds.Footer.makeTitle(status);
  return newrecord;
}

function makeSocial(socialMedia) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.textStyle = Atom_Text_Ds.Footer.makeSocial(socialMedia);
  return newrecord;
}

var Footer = {
  newsletter: newrecord$17,
  tos: newrecord$18,
  makePartner: makePartner,
  link: newrecord$19,
  titleLink: newrecord$20,
  makeTitle: makeTitle,
  makeSocial: makeSocial
};

var newrecord$21 = Caml_obj.obj_dup($$default);

newrecord$21.hover = {
  TAG: "Border",
  _0: Particle_Border_Ds.Cta.activeRegular
};

newrecord$21.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.inactiveRegular));

newrecord$21.spacer = particleSpacerVerticalSmallerWithMedValue;

var newrecord$22 = Caml_obj.obj_dup(newrecord$21);

newrecord$22.background = Particle_Background_Ds.selectGenreButtonSelected;

newrecord$22.textStyle = Atom_Text_Ds.SelectGenre.make("Selected");

var newrecord$23 = Caml_obj.obj_dup(newrecord$21);

newrecord$23.textStyle = Atom_Text_Ds.SelectGenre.make("NotSelected");

var GenreSelect = {
  commonStyle: newrecord$21,
  selected: newrecord$22,
  notSelected: newrecord$23
};

var hover = "Opacity";

var border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Header.$$default));

var colorHover = {
  TAG: "Color",
  _0: "HoverGrey"
};

function createSquare(icon) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = hover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine",
    alignSelf: "center"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, {
        TAG: "Square",
        _0: Type_Header.Constants.height.m
      }, undefined, undefined, {
        TAG: "Square",
        _0: Type_Header.Constants.height.default
      });
  newrecord.border = border;
  newrecord.textStyle = Atom_Text_Ds.Header.make({
        NAME: "Cta",
        VAL: "Default"
      }, {
        TAG: "Icon",
        _0: {
          position: "Alone",
          icon: icon,
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
        }
      }, undefined);
  return newrecord;
}

var newrecord$24 = Caml_obj.obj_dup($$default);

newrecord$24.hover = hover;

newrecord$24.block = {
  alignItems: "center",
  wrap: "OnOneLine"
};

newrecord$24.border = border;

newrecord$24.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));

newrecord$24.background = Particle_Background_Ds.white;

newrecord$24.textStyle = Atom_Text_Ds.Header.make({
      NAME: "Cta",
      VAL: "Active"
    }, undefined, undefined);

function inactiveBase(asset) {
  var newrecord$25 = Caml_obj.obj_dup(newrecord$24);
  newrecord$25.background = Particle_Background_Ds.transparent;
  newrecord$25.textStyle = Atom_Text_Ds.Header.make({
        NAME: "Cta",
        VAL: "Default"
      }, asset, undefined);
  return newrecord$25;
}

function getLogoStyle(orientation, logoBerne) {
  var logo;
  logo = orientation === "Horizontal" ? "ArteHorizontalLogo" : ({
        NAME: "ArteVerticalLogo",
        VAL: "Header"
      });
  if (logoBerne) {
    return {
            NAME: "WithWrapper",
            VAL: [
              logo,
              "Berne"
            ]
          };
  } else {
    return logo;
  }
}

function createUser(display) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(display);
  newrecord.hover = colorHover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine",
    alignSelf: "center"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, {
        TAG: "Square",
        _0: Type_Header.Constants.height.m
      }, "Rectangle", undefined, {
        TAG: "Square",
        _0: Type_Header.Constants.height.default
      });
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XXS"), undefined, Particle_Spacer_Ds.makeAll("None"));
  newrecord.textStyle = Atom_Text_Ds.Header.make({
        NAME: "Cta",
        VAL: "Default"
      }, {
        TAG: "Icon",
        _0: {
          position: {
            TAG: "Left",
            _0: Particle_Screen_Ds.make(undefined, undefined, undefined, "XS", undefined, "Zero")
          },
          icon: Atom_Icon_Ds.user,
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, "S", undefined, "XS")
        }
      }, {
        TAG: "Main",
        _0: "HoverGrey"
      });
  return newrecord;
}

function makeInstallMobileApp(display) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(display);
  newrecord.hover = hover;
  newrecord.block = {
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XXS"));
  newrecord.background = Particle_Background_Ds.orange;
  newrecord.textStyle = Atom_Text_Ds.Header.installMobileApp;
  return newrecord;
}

function makeGear(state) {
  var newrecord = Caml_obj.obj_dup(createSquare(Atom_Icon_Ds.settings));
  var tmp;
  tmp = state === "On" ? Particle_Background_Ds.dropdownMenu : Particle_Background_Ds.transparent;
  newrecord.background = tmp;
  return newrecord;
}

var newrecord$25 = Caml_obj.obj_dup($$default);

newrecord$25.hover = hover;

newrecord$25.block = {
  justifyContent: "center",
  alignItems: "center",
  wrap: "OnOneLine",
  alignSelf: "center"
};

newrecord$25.shape = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.m
    }, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.default
    });

newrecord$25.border = border;

newrecord$25.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XS"));

newrecord$25.textStyle = Atom_Text_Ds.Header.make({
      NAME: "Cta",
      VAL: "Default"
    }, {
      TAG: "Icon",
      _0: {
        position: "Alone",
        icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.search, "White"),
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined);

function makeSettingsMenu(display) {
  var newrecord = Caml_obj.obj_dup(createSquare(Atom_Icon_Ds.settings));
  newrecord.display = Caml_option.some(display);
  return newrecord;
}

function makeSettingsMenuLink(display) {
  var newrecord = Caml_obj.obj_dup(inactiveBase(undefined));
  newrecord.display = Caml_option.some(display);
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));
  newrecord.textStyle = Atom_Text_Ds.Header.makeMenuNavigation(undefined, {
        NAME: "Cta",
        VAL: "Default"
      });
  return newrecord;
}

function makeUser(display) {
  return createUser(display);
}

function makeHeaderPanel(variant) {
  var match;
  if (typeof variant !== "object") {
    switch (variant) {
      case "Setting" :
          match = [
            Atom_Icon_Ds.settings,
            colorHover
          ];
          break;
      case "Login" :
          match = [
            Atom_Icon_Ds.user,
            colorHover
          ];
          break;
      case "Logout" :
          match = [
            Atom_Icon_Ds.logout,
            colorHover
          ];
          break;
      case "MyVideo" :
          match = [
            Atom_Icon_Ds.play,
            colorHover
          ];
          break;
      case "Help" :
          match = [
            Atom_Icon_Ds.info,
            colorHover
          ];
          break;
      
    }
  } else {
    match = variant._0 === "Locked" ? [
        Atom_Icon_Ds.starLocked,
        "CursorDefault"
      ] : [
        Atom_Icon_Ds.star,
        colorHover
      ];
  }
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = match[1];
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "S"));
  newrecord.textStyle = Atom_Text_Ds.Header.makeUserPanel({
        TAG: "Icon",
        _0: {
          position: {
            TAG: "Left",
            _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
          },
          icon: match[0],
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
        }
      });
  return newrecord;
}

var newrecord$26 = Caml_obj.obj_dup(createSquare(Atom_Icon_Ds.close));

newrecord$26.hover = undefined;

function makeMyVideo(display) {
  var newrecord = Caml_obj.obj_dup(inactiveBase({
            TAG: "Icon",
            _0: {
              position: {
                TAG: "Left",
                _0: innerSpace
              },
              icon: Atom_Icon_Ds.play,
              size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
            }
          }));
  newrecord.display = Caml_option.some(display);
  newrecord.hover = colorHover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: Type_Header.Constants.height.m
      }, undefined, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: Type_Header.Constants.height.default
      });
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"));
  return newrecord;
}

function makeLanguageSwitcherItem(active) {
  var spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Both.make({
            top: "XXS",
            bottom: "XXS",
            left: "S",
            right: "S"
          }, {
            top: "XXS",
            bottom: "XXS",
            left: "XXS",
            right: "XXS"
          }));
  var block_justifyContent = "center";
  var block_alignItems = "center";
  var block_autoSize = Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable);
  var block_wrap = "OnOneLine";
  var block = {
    justifyContent: block_justifyContent,
    alignItems: block_alignItems,
    autoSize: block_autoSize,
    wrap: block_wrap
  };
  if (typeof active !== "object") {
    var newrecord$27 = Caml_obj.obj_dup(inactiveBase(undefined));
    newrecord$27.hover = colorHover;
    newrecord$27.block = block;
    newrecord$27.spacer = spacer;
    newrecord$27.textStyle = Atom_Text_Ds.Header.makeLanguageSwitcher({
          NAME: "Cta",
          VAL: "Default"
        });
    return newrecord$27;
  }
  var newrecord$28 = Caml_obj.obj_dup(newrecord$24);
  newrecord$28.hover = undefined;
  newrecord$28.block = block;
  newrecord$28.spacer = spacer;
  newrecord$28.textStyle = Atom_Text_Ds.Header.makeLanguageSwitcher({
        NAME: "Cta",
        VAL: "Active"
      });
  return newrecord$28;
}

function makeLanguageSwitcher(state) {
  var newrecord = Caml_obj.obj_dup(inactiveBase({
            TAG: "Icon",
            _0: {
              position: {
                TAG: "Right",
                _0: innerSpace
              },
              icon: Atom_Icon_Ds.Arrow.down,
              size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
            }
          }));
  newrecord.hover = colorHover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: Type_Header.Constants.height.m
      }, undefined, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: Type_Header.Constants.height.default
      });
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"));
  var tmp;
  tmp = state === "On" ? Particle_Background_Ds.dropdownMenu : Particle_Background_Ds.transparent;
  newrecord.background = tmp;
  return newrecord;
}

function makeMenuNavigation(active) {
  switch (active) {
    case "Active" :
        var newrecord$27 = Caml_obj.obj_dup(newrecord$24);
        newrecord$27.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));
        newrecord$27.textStyle = Atom_Text_Ds.Header.makeMenuNavigation(undefined, {
              NAME: "Cta",
              VAL: "Active"
            });
        return newrecord$27;
    case "Inactive" :
        var newrecord$28 = Caml_obj.obj_dup(inactiveBase(undefined));
        newrecord$28.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));
        newrecord$28.textStyle = Atom_Text_Ds.Header.makeMenuNavigation(undefined, {
              NAME: "Cta",
              VAL: "Default"
            });
        return newrecord$28;
    case "External" :
        var newrecord$29 = Caml_obj.obj_dup(inactiveBase(undefined));
        newrecord$29.block = {
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center"
        };
        newrecord$29.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));
        newrecord$29.textStyle = Atom_Text_Ds.Header.makeMenuNavigation({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Right",
                  _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL")
                },
                icon: Atom_Icon_Ds.Arrow.arrow45,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, {
              NAME: "Cta",
              VAL: "Default"
            });
        return newrecord$29;
    
  }
}

var baseNavigation = inactiveBase(undefined);

var newrecord$27 = Caml_obj.obj_dup(inactiveBase(undefined));

newrecord$27.block = {
  justifyContent: "center",
  alignItems: "center",
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
  overflow: "hidden"
};

newrecord$27.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"));

newrecord$27.textStyle = Atom_Text_Ds.Header.redirectionAnchor;

function makeConcert(country) {
  var display;
  display = country === "Europe" ? Particle_Screen_Ds.make(undefined, undefined, undefined, "inlineFlex", undefined, "none") : Particle_Screen_Ds.make(undefined, undefined, "inlineFlex", undefined, undefined, "none");
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(display);
  newrecord.hover = hover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));
  newrecord.textStyle = Atom_Text_Ds.Header.concert;
  return newrecord;
}

function makeLogo(orientation, logoBerne, display) {
  if (orientation === "Horizontal") {
    var newrecord = Caml_obj.obj_dup(inactiveBase({
              TAG: "Logo",
              _0: {
                position: {
                  TAG: "Left",
                  _0: innerSpace
                },
                logo: getLogoStyle(orientation, logoBerne)
              }
            }));
    newrecord.display = Caml_option.some(display);
    newrecord.hover = {
      TAG: "Color",
      _0: "White"
    };
    newrecord.block = {
      alignItems: "center",
      wrap: "OnOneLine"
    };
    newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XS"));
    return newrecord;
  }
  var newrecord$1 = Caml_obj.obj_dup(inactiveBase({
            TAG: "Logo",
            _0: {
              position: "Alone",
              logo: getLogoStyle(orientation, logoBerne)
            }
          }));
  newrecord$1.display = Caml_option.some(display);
  newrecord$1.hover = {
    TAG: "Color",
    _0: "White"
  };
  newrecord$1.block = {
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord$1.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("Zero", "Zero", "Zero", "XXS"), undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XXS"));
  return newrecord$1;
}

function makeConcertLogo(display) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(display);
  newrecord.hover = hover;
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("XXS"));
  newrecord.textStyle = Atom_Text_Ds.Header.concertLogo;
  return newrecord;
}

var newrecord$28 = Caml_obj.obj_dup(inactiveBase(undefined));

newrecord$28.block = {
  justifyContent: "center",
  alignItems: "center",
  wrap: "OnOneLine"
};

newrecord$28.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"));

function makeSocial$1(icon) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = hover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: Type_Header.Constants.height.m
      }, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: 22
      });
  newrecord.border = border;
  newrecord.textStyle = Atom_Text_Ds.Header.make({
        NAME: "Cta",
        VAL: "Default"
      }, {
        TAG: "Icon",
        _0: {
          position: "Alone",
          icon: icon,
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
        }
      }, undefined);
  return newrecord;
}

function makeConcertGenre(status) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = hover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
        TAG: "RectangleWithStaticHeight",
        _0: Type_Header.Constants.height.m
      });
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"));
  var tmp;
  tmp = status === "On" ? Particle_Background_Ds.selectConcertGenre : Particle_Background_Ds.transparent;
  newrecord.background = tmp;
  newrecord.textStyle = Atom_Text_Ds.Header.make({
        NAME: "Cta",
        VAL: "Default"
      }, {
        TAG: "Icon",
        _0: {
          position: {
            TAG: "Right",
            _0: innerSpace
          },
          icon: Atom_Icon_Ds.Arrow.down,
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
        }
      }, undefined);
  return newrecord;
}

var newrecord$29 = Caml_obj.obj_dup($$default);

newrecord$29.hover = undefined;

newrecord$29.textStyle = Atom_Text_Ds.Header.selectGenre;

var newrecord$30 = Caml_obj.obj_dup($$default);

newrecord$30.hover = undefined;

newrecord$30.textStyle = Atom_Text_Ds.Header.concertMenuNavigation;

var newrecord$31 = Caml_obj.obj_dup($$default);

newrecord$31.block = {
  alignSelf: "start"
};

newrecord$31.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeRegular));

newrecord$31.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

newrecord$31.textStyle = Atom_Text_Ds.Header.userPanelRegistration;

var newrecord$32 = Caml_obj.obj_dup($$default);

newrecord$32.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$32.textStyle = Atom_Text_Ds.Header.link;

function makeAvatar(state, icon) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = colorHover;
  newrecord.block = {
    justifyContent: "center",
    alignItems: "center",
    autoSize: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "AllPlaceAvailable")),
    wrap: "OnOneLine",
    alignSelf: "center"
  };
  newrecord.shape = Particle_Screen_Ds.make(undefined, undefined, {
        TAG: "Square",
        _0: Type_Header.Constants.height.m
      }, "Rectangle", undefined, {
        TAG: "Square",
        _0: Type_Header.Constants.height.default
      });
  newrecord.border = border;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XXS"), undefined, Particle_Spacer_Ds.makeAll("None"));
  var tmp;
  tmp = state === "On" ? Particle_Background_Ds.dropdownMenu : Particle_Background_Ds.transparent;
  newrecord.background = tmp;
  newrecord.textStyle = Atom_Text_Ds.Header.make({
        NAME: "Cta",
        VAL: "Default"
      }, icon, {
        TAG: "Main",
        _0: "HoverGrey"
      });
  return newrecord;
}

var Header = {
  hover: hover,
  border: border,
  colorHover: colorHover,
  createSquare: createSquare,
  activeBase: newrecord$24,
  inactiveBase: inactiveBase,
  getLogoStyle: getLogoStyle,
  createUser: createUser,
  makeInstallMobileApp: makeInstallMobileApp,
  makeGear: makeGear,
  search: newrecord$25,
  makeSettingsMenu: makeSettingsMenu,
  makeSettingsMenuLink: makeSettingsMenuLink,
  makeUser: makeUser,
  makeHeaderPanel: makeHeaderPanel,
  close: newrecord$26,
  makeMyVideo: makeMyVideo,
  makeLanguageSwitcherItem: makeLanguageSwitcherItem,
  makeLanguageSwitcher: makeLanguageSwitcher,
  makeMenuNavigation: makeMenuNavigation,
  baseNavigation: baseNavigation,
  redirectionAnchor: newrecord$27,
  makeConcert: makeConcert,
  makeLogo: makeLogo,
  makeConcertLogo: makeConcertLogo,
  oneTimeEvent: newrecord$28,
  makeSocial: makeSocial$1,
  makeConcertGenre: makeConcertGenre,
  genreItem: newrecord$29,
  concertMenuNavigation: newrecord$30,
  panelRegistration: newrecord$31,
  link: newrecord$32,
  makeAvatar: makeAvatar
};

function makeClose(color) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));
  newrecord.textStyle = Atom_Text_Ds.Message.close(color);
  return newrecord;
}

var newrecord$33 = Caml_obj.obj_dup($$default);

newrecord$33.textStyle = Atom_Text_Ds.makeMessage("SubHeaderLink");

var newrecord$34 = Caml_obj.obj_dup($$default);

newrecord$34.textStyle = Atom_Text_Ds.makeMessage({
      NAME: "Cta",
      VAL: "Default"
    });

var Message = {
  makeClose: makeClose,
  linkSubHeader: newrecord$33,
  linkDefault: newrecord$34
};

function makeHeaderNavigationItemList(state) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = undefined;
  newrecord.textStyle = Atom_Text_Ds.User.makeHeaderNavigationItemList(state);
  return newrecord;
}

function makeProfileNavigationItemList(state) {
  var newrecord = Caml_obj.obj_dup($$default);
  var tmp;
  tmp = state === "Current" ? undefined : ({
        TAG: "Background",
        _0: "DarkGrey"
      });
  newrecord.hover = tmp;
  newrecord.block = {
    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
  };
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "L"), undefined, undefined, Particle_Spacer_Ds.makeAll("S"));
  var tmp$1;
  tmp$1 = state === "Current" ? Particle_Background_Ds.userNavigationItemListCurrentUser : Particle_Background_Ds.transparent;
  newrecord.background = tmp$1;
  newrecord.textStyle = Atom_Text_Ds.User.profileNavigationItemList;
  return newrecord;
}

var newrecord$35 = Caml_obj.obj_dup($$default);

newrecord$35.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$35.background = Particle_Background_Ds.white;

newrecord$35.textStyle = Atom_Text_Ds.User.cta;

function makeActionButton$1(status) {
  var newrecord$36 = Caml_obj.obj_dup(newrecord$35);
  newrecord$36.block = {
    justifyContent: "center",
    alignItems: "center",
    wrap: "OnOneLine"
  };
  newrecord$36.textStyle = Atom_Text_Ds.User.actionButton(status);
  return newrecord$36;
}

var newrecord$36 = Caml_obj.obj_dup($$default);

newrecord$36.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$36.background = Particle_Background_Ds.orange;

newrecord$36.textStyle = Atom_Text_Ds.User.action;

function makeOutline(disabled, kind) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = disabled ? "NotAllowed" : "Opacity";
  newrecord.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.inactive));
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));
  newrecord.background = disabled ? Particle_Background_Ds.disabledButtonUser : Particle_Background_Ds.transparent;
  var tmp;
  tmp = kind === "External" ? Atom_Text_Ds.makeSecondaryCtaWithAsset({
          TAG: "Icon",
          _0: {
            position: {
              TAG: "Right",
              _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
            },
            icon: Atom_Icon_Ds.Arrow.arrow45,
            size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
          }
        }) : Atom_Text_Ds.secondaryCta;
  newrecord.textStyle = tmp;
  return newrecord;
}

var newrecord$37 = Caml_obj.obj_dup($$default);

newrecord$37.hover = undefined;

newrecord$37.block = {
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
  wrap: "OnOneLine"
};

newrecord$37.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$37.background = Particle_Background_Ds.dropdownMenu;

newrecord$37.textStyle = Atom_Text_Ds.User.dropdown;

var newrecord$38 = Caml_obj.obj_dup($$default);

newrecord$38.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$38.textStyle = Atom_Text_Ds.User.link;

var newrecord$39 = Caml_obj.obj_dup($$default);

newrecord$39.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$39.textStyle = Atom_Text_Ds.User.footerLink;

var newrecord$40 = Caml_obj.obj_dup($$default);

newrecord$40.hover = "NotAllowed";

newrecord$40.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.inactiveMedium));

newrecord$40.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$40.background = Particle_Background_Ds.disabledButtonUser;

newrecord$40.textStyle = Atom_Text_Ds.User.$$default;

var newrecord$41 = Caml_obj.obj_dup($$default);

newrecord$41.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$41.textStyle = Atom_Text_Ds.User.accountLink;

var newrecord$42 = Caml_obj.obj_dup($$default);

newrecord$42.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$42.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.eventInfoLink));

newrecord$42.textStyle = Atom_Text_Ds.User.eventLink;

var newrecord$43 = Caml_obj.obj_dup($$default);

newrecord$43.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$43.textStyle = Atom_Text_Ds.User.action;

var newrecord$44 = Caml_obj.obj_dup($$default);

newrecord$44.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block"));

newrecord$44.block = {
  wrap: "OnOneLine"
};

newrecord$44.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));

newrecord$44.background = Particle_Background_Ds.white;

newrecord$44.textStyle = Atom_Text_Ds.User.cta;

var newrecord$45 = Caml_obj.obj_dup($$default);

newrecord$45.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$45.block = {
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
  wrap: "OnOneLine",
  alignSelf: "flexStart"
};

newrecord$45.textStyle = Atom_Text_Ds.User.footerAccordionLink;

var newrecord$46 = Caml_obj.obj_dup($$default);

newrecord$46.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("XS"));

newrecord$46.background = Particle_Background_Ds.white;

newrecord$46.textStyle = Atom_Text_Ds.User.cardCta;

var newrecord$47 = Caml_obj.obj_dup($$default);

newrecord$47.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$47.hover = "CursorDefault";

newrecord$47.block = {
  wrap: "OnOneLine",
  alignSelf: "flexStart"
};

newrecord$47.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("M", "XS"));

newrecord$47.textStyle = Atom_Text_Ds.User.tvCellFieldNewDesign;

var newrecord$48 = Caml_obj.obj_dup($$default);

newrecord$48.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"));

newrecord$48.textStyle = Atom_Text_Ds.User.backlink;

var User = {
  makeHeaderNavigationItemList: makeHeaderNavigationItemList,
  makeProfileNavigationItemList: makeProfileNavigationItemList,
  base: newrecord$35,
  makeActionButton: makeActionButton$1,
  primary: newrecord$36,
  makeOutline: makeOutline,
  dropdown: newrecord$37,
  link: newrecord$38,
  footerLink: newrecord$39,
  disabled: newrecord$40,
  accountLink: newrecord$41,
  eventLink: newrecord$42,
  textfieldAction: newrecord$43,
  fullWidth: newrecord$44,
  accordion: newrecord$45,
  card: newrecord$46,
  tvLogin: newrecord$47,
  backlink: newrecord$48
};

var newrecord$49 = Caml_obj.obj_dup($$default);

newrecord$49.block = {
  justifyContent: "center",
  autoSize: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "MaxContent", undefined, undefined, "AllPlaceAvailable")),
  wrap: "OnOneLine"
};

newrecord$49.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$49.background = Particle_Background_Ds.white;

newrecord$49.textStyle = Atom_Text_Ds.NewsletterZone.cta;

var newrecord$50 = Caml_obj.obj_dup($$default);

newrecord$50.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inline));

newrecord$50.shape = shapeControl;

newrecord$50.textStyle = Atom_Text_Ds.makeTos({
      NAME: "Cta",
      VAL: "Default"
    });

var NewsletterZone = {
  cta: newrecord$49,
  tos: newrecord$50
};

var newrecord$51 = Caml_obj.obj_dup($$default);

newrecord$51.hover = undefined;

newrecord$51.textStyle = Atom_Text_Ds.Papa.backlink;

var Papa = {
  backLink: newrecord$51
};

var closeSize = Particle_Screen_Ds.make(undefined, undefined, undefined, Type_Header.Constants.height.m, undefined, Type_Header.Constants.height.default);

var newrecord$52 = Caml_obj.obj_dup($$default);

newrecord$52.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

newrecord$52.background = Particle_Background_Ds.white;

newrecord$52.textStyle = Atom_Text_Ds.Player.cta;

var newrecord$53 = Caml_obj.obj_dup($$default);

newrecord$53.shape = topTeaserButtons_shape;

newrecord$53.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser));

newrecord$53.spacer = topTeaserButtons_spacer;

newrecord$53.textStyle = Atom_Text_Ds.Player.ctaWithAsset(Atom_Icon_Ds.play);

var newrecord$54 = Caml_obj.obj_dup($$default);

newrecord$54.shape = topTeaserButtons_shape;

newrecord$54.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser));

newrecord$54.spacer = topTeaserButtons_spacer;

newrecord$54.textStyle = Atom_Text_Ds.Player.ctaWithAsset(Atom_Icon_Ds.close);

var newrecord$55 = Caml_obj.obj_dup($$default);

newrecord$55.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$55.textStyle = Atom_Text_Ds.Player.link;

var newrecord$56 = Caml_obj.obj_dup($$default);

newrecord$56.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("S"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));

newrecord$56.background = Particle_Background_Ds.white;

newrecord$56.textStyle = Atom_Text_Ds.Player.warningCta;

var newrecord$57 = Caml_obj.obj_dup($$default);

newrecord$57.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.inactive));

newrecord$57.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("S"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));

newrecord$57.textStyle = Atom_Text_Ds.Player.warningSecondaryCta;

var newrecord$58 = Caml_obj.obj_dup($$default);

newrecord$58.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$58.textStyle = Atom_Text_Ds.Player.warningLink;

var newrecord$59 = Caml_obj.obj_dup($$default);

newrecord$59.block = {
  justifyContent: "center",
  alignItems: "center",
  wrap: "OnOneLine",
  alignSelf: "center"
};

newrecord$59.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Square",
      _0: Type_Header.Constants.height.default
    });

newrecord$59.textStyle = Atom_Text_Ds.Header.make({
      NAME: "Cta",
      VAL: "Default"
    }, {
      TAG: "Icon",
      _0: {
        position: "Alone",
        icon: Atom_Icon_Ds.close,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined);

var newrecord$60 = Caml_obj.obj_dup($$default);

newrecord$60.block = {
  justifyContent: "center",
  alignItems: "center",
  wrap: "OnOneLine",
  alignSelf: "center"
};

newrecord$60.shape = Particle_Screen_Ds.map(closeSize, (function (value) {
        return {
                TAG: "Square",
                _0: value
              };
      }));

newrecord$60.textStyle = Atom_Text_Ds.Header.make({
      NAME: "Cta",
      VAL: "Default"
    }, {
      TAG: "Icon",
      _0: {
        position: "Alone",
        icon: Atom_Icon_Ds.close,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "S")
      }
    }, undefined);

var newrecord$61 = Caml_obj.obj_dup($$default);

newrecord$61.hover = undefined;

var Player = {
  closeSize: closeSize,
  ctaBase: newrecord$52,
  ctaWatch: newrecord$53,
  ctaCancel: newrecord$54,
  link: newrecord$55,
  warningCta: newrecord$56,
  warningSecondaryCta: newrecord$57,
  warningLink: newrecord$58,
  closeTooltip: newrecord$59,
  closeOverlay: newrecord$60,
  playOverlayButton: newrecord$61
};

var newrecord$62 = Caml_obj.obj_dup($$default);

newrecord$62.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$62.hover = {
  TAG: "Dash",
  _0: "White",
  _1: "M"
};

newrecord$62.block = {
  justifyContent: "flexStart",
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
  wrap: "OnOneLine"
};

newrecord$62.shape = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.m
    }, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.default
    });

newrecord$62.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("M"));

newrecord$62.background = Particle_Background_Ds.Presse.menu;

newrecord$62.textStyle = Atom_Text_Ds.Presse.menuItem;

function makeBackgroundByHighlight$1(highlight) {
  if (highlight) {
    return Particle_Background_Ds.orange;
  } else {
    return Particle_Background_Ds.Presse.menu;
  }
}

function makePaginationStyle(textStyle) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Css.inlineFlex));
  newrecord.block = {
    alignItems: "center",
    alignSelf: "selfStart"
  };
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "S"));
  newrecord.textStyle = textStyle;
  return newrecord;
}

function makePrimary(disabled, icon) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = disabled ? "NotAllowed" : "Opacity";
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));
  newrecord.background = disabled ? Particle_Background_Ds.Presse.disabledButton : Particle_Background_Ds.Presse.button;
  newrecord.textStyle = Atom_Text_Ds.Presse.label(icon);
  return newrecord;
}

function makeSearchFilter(disabled) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = disabled ? "NotAllowed" : "Opacity";
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("M"));
  newrecord.background = disabled ? Particle_Background_Ds.Presse.disabledButton : Particle_Background_Ds.Presse.button;
  newrecord.textStyle = Atom_Text_Ds.Presse.searchSubmit;
  return newrecord;
}

function makeSecondary(disabled, icon) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = disabled ? "NotAllowed" : "Opacity";
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));
  newrecord.background = disabled ? Particle_Background_Ds.Presse.disabledButton : Particle_Background_Ds.Presse.secondaryButton;
  newrecord.textStyle = Atom_Text_Ds.Presse.label(icon);
  return newrecord;
}

function makeOutline$1(disabled, icon) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = {
    TAG: "Border",
    _0: Particle_Border_Ds.Textfield.active
  };
  newrecord.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.inactive));
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));
  newrecord.background = disabled ? Particle_Background_Ds.Presse.disabledButton : Particle_Background_Ds.transparent;
  newrecord.textStyle = disabled ? Atom_Text_Ds.Presse.label(icon) : Atom_Text_Ds.Presse.secondaryCta(icon);
  return newrecord;
}

function makeClear(disabled) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));
  newrecord.textStyle = Atom_Text_Ds.Presse.clearFilter(disabled);
  return newrecord;
}

var newrecord$63 = Caml_obj.obj_dup($$default);

newrecord$63.hover = undefined;

newrecord$63.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeRight("XL"), undefined, undefined, Particle_Spacer_Ds.makeRight("XS"));

newrecord$63.textStyle = Atom_Text_Ds.Presse.menuBrandTitle;

var newrecord$64 = Caml_obj.obj_dup($$default);

newrecord$64.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$64.hover = {
  TAG: "Dash",
  _0: "White",
  _1: "M"
};

newrecord$64.block = {
  justifyContent: "flexStart",
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
  wrap: "OnOneLine"
};

newrecord$64.shape = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.m
    }, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: Type_Header.Constants.height.default
    });

newrecord$64.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("M"));

newrecord$64.background = Particle_Background_Ds.Presse.menu;

newrecord$64.textStyle = Atom_Text_Ds.Presse.menuItem;

var newrecord$65 = Caml_obj.obj_dup(newrecord$62);

newrecord$65.hover = {
  TAG: "Dash",
  _0: "White",
  _1: "M"
};

newrecord$65.background = Particle_Background_Ds.white;

newrecord$65.textStyle = Atom_Text_Ds.Presse.menuItemCurrent;

function makeMenuItemWithChilds$1(status, highlight) {
  var newrecord$66 = Caml_obj.obj_dup(newrecord$62);
  newrecord$66.background = makeBackgroundByHighlight$1(highlight);
  newrecord$66.textStyle = Atom_Text_Ds.Presse.makeMenuItemWithChilds(status);
  return newrecord$66;
}

function makeMenuItemChild$1(highlight) {
  var newrecord$66 = Caml_obj.obj_dup(newrecord$62);
  newrecord$66.hover = undefined;
  newrecord$66.block = {
    justifyContent: "flexStart",
    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
    wrap: "OnOneLine"
  };
  newrecord$66.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Rectangle");
  newrecord$66.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Particle_Border_Ds.Presse.menuItemChild, undefined, undefined, Particle_Border_Ds.none));
  newrecord$66.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "M"));
  newrecord$66.background = makeBackgroundByHighlight$1(highlight);
  return newrecord$66;
}

var newrecord$66 = Caml_obj.obj_dup(newrecord$62);

newrecord$66.background = Particle_Background_Ds.orange;

var newrecord$67 = Caml_obj.obj_dup($$default);

newrecord$67.hover = "Scale";

newrecord$67.block = {
  justifyContent: "center"
};

newrecord$67.shape = shapeArrow;

newrecord$67.background = Particle_Background_Ds.white;

newrecord$67.textStyle = Atom_Text_Ds.Presse.makeNav("Left");

var newrecord$68 = Caml_obj.obj_dup($$default);

newrecord$68.hover = "Scale";

newrecord$68.block = {
  justifyContent: "center"
};

newrecord$68.shape = shapeArrow;

newrecord$68.background = Particle_Background_Ds.white;

newrecord$68.textStyle = Atom_Text_Ds.Presse.makeNav("Right");

var newrecord$69 = Caml_obj.obj_dup(newrecord$62);

newrecord$69.hover = {
  TAG: "Color",
  _0: "White"
};

newrecord$69.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XS"));

newrecord$69.textStyle = Atom_Text_Ds.Presse.searchButton;

var newrecord$70 = Caml_obj.obj_dup($$default);

newrecord$70.hover = undefined;

newrecord$70.textStyle = Atom_Text_Ds.Papa.backlink;

var newrecord$71 = Caml_obj.obj_dup($$default);

newrecord$71.hover = {
  TAG: "Border",
  _0: undefined
};

newrecord$71.block = {
  autoSize: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "NoResizing", undefined, "AllPlaceNeeded")),
  wrap: "OnOneLine"
};

newrecord$71.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

var newrecord$72 = Caml_obj.obj_dup($$default);

newrecord$72.textStyle = Atom_Text_Ds.Presse.guideDownload;

var newrecord$73 = Caml_obj.obj_dup($$default);

newrecord$73.hover = undefined;

newrecord$73.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XXS"));

newrecord$73.textStyle = Atom_Text_Ds.Presse.weekSliderTooltip;

var newrecord$74 = Caml_obj.obj_dup($$default);

newrecord$74.hover = "CursorDefault";

newrecord$74.block = {
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
  wrap: "OnOneLine"
};

newrecord$74.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVertical("S"), undefined, undefined, Particle_Spacer_Ds.makeVertical("XS"));

newrecord$74.background = Particle_Background_Ds.primary;

var newrecord$75 = Caml_obj.obj_dup($$default);

newrecord$75.hover = {
  TAG: "Border",
  _0: undefined
};

newrecord$75.block = {
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
  wrap: "OnOneLine"
};

newrecord$75.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVertical("S"), undefined, undefined, Particle_Spacer_Ds.makeVertical("XS"));

var newrecord$76 = Caml_obj.obj_dup($$default);

newrecord$76.block = {
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded)
};

newrecord$76.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Presse.downloadLink));

newrecord$76.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("M", "L"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "XS"));

var newrecord$77 = Caml_obj.obj_dup($$default);

newrecord$77.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Css.inlineFlex));

newrecord$77.block = {
  alignItems: "center",
  alignSelf: "selfStart"
};

newrecord$77.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.sliderButton));

newrecord$77.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "S"));

newrecord$77.background = Particle_Background_Ds.primary;

newrecord$77.textStyle = Atom_Text_Ds.Presse.articleButton;

var paginationActive = makePaginationStyle(Atom_Text_Ds.Presse.paginationActive);

var paginationInactive = makePaginationStyle(Atom_Text_Ds.Presse.pagination);

function makePaginationArrow(direction) {
  return makePaginationStyle(Atom_Text_Ds.Presse.paginationArrow(direction));
}

var Presse = {
  menuItemBaseStyle: newrecord$62,
  makeBackgroundByHighlight: makeBackgroundByHighlight$1,
  makePaginationStyle: makePaginationStyle,
  makePrimary: makePrimary,
  makeSearchFilter: makeSearchFilter,
  makeSecondary: makeSecondary,
  makeOutline: makeOutline$1,
  makeClear: makeClear,
  menuBrandTitle: newrecord$63,
  menuItemDefault: newrecord$64,
  menuItemCurrent: newrecord$65,
  makeMenuItemWithChilds: makeMenuItemWithChilds$1,
  makeMenuItemChild: makeMenuItemChild$1,
  menuItemHightlight: newrecord$66,
  navPrev: newrecord$67,
  navNext: newrecord$68,
  searchButton: newrecord$69,
  backLink: newrecord$70,
  weekSlider: newrecord$71,
  weekSliderDownload: newrecord$72,
  weekSliderTooltip: newrecord$73,
  selectDayCurrent: newrecord$74,
  selectDayDefault: newrecord$75,
  downloadLink: newrecord$76,
  articleButton: newrecord$77,
  paginationActive: paginationActive,
  paginationInactive: paginationInactive,
  makePaginationArrow: makePaginationArrow
};

function make$1(socialMedia, kind) {
  var newrecord = Caml_obj.obj_dup($$default);
  var tmp;
  var exit = 0;
  switch (socialMedia) {
    case "Facebook" :
    case "X" :
        exit = 1;
        break;
    case "Whatsapp" :
        tmp = Particle_Screen_Ds.make(undefined, undefined, Css.none, undefined, undefined, Css.inlineFlex);
        break;
    
  }
  if (exit === 1) {
    tmp = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Css.inlineFlex);
  }
  newrecord.display = Caml_option.some(tmp);
  var tmp$1;
  switch (socialMedia) {
    case "Facebook" :
        tmp$1 = "Facebook";
        break;
    case "X" :
        tmp$1 = "X";
        break;
    case "Whatsapp" :
        tmp$1 = "Whatsapp";
        break;
    
  }
  newrecord.hover = {
    TAG: "Background",
    _0: tmp$1
  };
  newrecord.block = {
    justifyContent: "center"
  };
  var tmp$2;
  tmp$2 = kind === "EventZone" ? Particle_Screen_Ds.make(undefined, undefined, undefined, {
          TAG: "Circle",
          _0: 40
        }, undefined, {
          TAG: "Circle",
          _0: 40
        }) : Particle_Screen_Ds.make(undefined, undefined, undefined, {
          TAG: "Circle",
          _0: 80
        }, undefined, {
          TAG: "Circle",
          _0: 40
        });
  newrecord.shape = tmp$2;
  var tmp$3;
  tmp$3 = kind === "EventZone" ? Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.socialNetwork)) : undefined;
  newrecord.border = tmp$3;
  newrecord.textStyle = Atom_Text_Ds.Sharebar.makeSocial(socialMedia, kind);
  return newrecord;
}

var Sharebar = {
  make: make$1
};

var newrecord$78 = Caml_obj.obj_dup($$default);

newrecord$78.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Css.inlineFlex, undefined, undefined, CssJs.none));

newrecord$78.block = {
  alignItems: "center",
  alignSelf: "end_"
};

newrecord$78.textStyle = Atom_Text_Ds.Slider.downloadAll;

function makeSeeMore(theme) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Css.inlineFlex, undefined, undefined, CssJs.none));
  newrecord.block = {
    alignItems: "center",
    alignSelf: "end_"
  };
  newrecord.border = theme === "Info" || theme === "White" ? Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.sliderButton)) : undefined;
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "S"));
  newrecord.background = typeof theme === "object" && theme.NAME === "PlayNext" ? Particle_Background_Ds.white : Particle_Background_Ds.primary;
  newrecord.textStyle = typeof theme === "object" && theme.NAME === "PlayNext" ? Atom_Text_Ds.Slider.seeMorePlayNext : Atom_Text_Ds.Slider.seeMore;
  return newrecord;
}

var newrecord$79 = Caml_obj.obj_dup($$default);

newrecord$79.hover = "Scale";

newrecord$79.block = {
  justifyContent: "center"
};

newrecord$79.shape = shapeArrow;

newrecord$79.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.sliderButton));

newrecord$79.background = Particle_Background_Ds.white;

newrecord$79.textStyle = Atom_Text_Ds.Slider.makeNav("Left");

var newrecord$80 = Caml_obj.obj_dup($$default);

newrecord$80.hover = "Scale";

newrecord$80.block = {
  justifyContent: "center"
};

newrecord$80.shape = shapeArrow;

newrecord$80.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.sliderButton));

newrecord$80.background = Particle_Background_Ds.white;

newrecord$80.textStyle = Atom_Text_Ds.Slider.makeNav("Right");

function makeTitle$1(textStyle) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = undefined;
  newrecord.textStyle = textStyle;
  return newrecord;
}

var Slider = {
  downloadAll: newrecord$78,
  makeSeeMore: makeSeeMore,
  navPrev: newrecord$79,
  navNext: newrecord$80,
  makeTitle: makeTitle$1
};

var newrecord$81 = Caml_obj.obj_dup($$default);

newrecord$81.hover = undefined;

newrecord$81.block = {
  alignItems: "flexStart"
};

function bareTeaser(animation) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = animation;
  return newrecord;
}

function makeTeaserLandscapeGenre(domain) {
  var border = domain === "concert" ? Particle_Border_Ds.Teaser.genreActiveConcert : Particle_Border_Ds.Teaser.genreActiveDefault;
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = {
    TAG: "Border",
    _0: border
  };
  newrecord.block = {
    alignItems: "flexStart"
  };
  newrecord.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Teaser.genreInactive));
  return newrecord;
}

function makeTeaserPlay(progression, icon, parentComponent) {
  if (parentComponent === "TeaserHover") {
    return makeStyleWatch(progression, icon, Particle_Background_Ds.white, "Black");
  } else {
    return makeStyleWatch(progression, icon, Particle_Background_Ds.buttonSolid, undefined);
  }
}

var newrecord$82 = Caml_obj.obj_dup($$default);

newrecord$82.hover = undefined;

newrecord$82.textStyle = Atom_Text_Ds.Teaser.Hover.toParent(Atom_Text_Ds.Teaser.Hover.genreLink);

var newrecord$83 = Caml_obj.obj_dup($$default);

newrecord$83.hover = undefined;

newrecord$83.borderFocus = Particle_Border_Ds.Teaser.infoAccessibility;

var newrecord$84 = Caml_obj.obj_dup($$default);

newrecord$84.shape = topTeaserButtons_shape;

newrecord$84.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser));

newrecord$84.spacer = topTeaserButtons_spacer;

newrecord$84.textStyle = Atom_Text_Ds.Teaser.Hover.toParent(Atom_Text_Ds.Teaser.Hover.cta(undefined, undefined));

var newrecord$85 = Caml_obj.obj_dup($$default);

newrecord$85.shape = topTeaserButtons_shape;

newrecord$85.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser));

newrecord$85.spacer = topTeaserButtons_spacer;

newrecord$85.textStyle = Atom_Text_Ds.Teaser.Hover.toParent(Atom_Text_Ds.Teaser.Hover.cta(undefined, undefined));

var newrecord$86 = Caml_obj.obj_dup($$default);

newrecord$86.shape = topTeaserButtons_shape;

newrecord$86.textStyle = Atom_Text_Ds.makeSecondaryCtaWithAsset({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: innerSpace
        },
        icon: Atom_Icon_Ds.close,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    });

function makeTeaserMobileHover(textStyle) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XS"));
  newrecord.textStyle = Atom_Text_Ds.Teaser.toParent(textStyle);
  return newrecord;
}

var Teaser = {
  teaser: newrecord$81,
  bareTeaser: bareTeaser,
  makeTeaserLandscapeGenre: makeTeaserLandscapeGenre,
  makeTeaserPlay: makeTeaserPlay,
  teaserGenre: newrecord$82,
  teaserInfo: newrecord$83,
  teaserHoverClose: newrecord$84,
  teaserMoreOptions: newrecord$85,
  teaserHoverCloseMobile: newrecord$86,
  makeTeaserMobileHover: makeTeaserMobileHover
};

var newrecord$87 = Caml_obj.obj_dup($$default);

newrecord$87.hover = undefined;

newrecord$87.block = {
  alignItems: "center"
};

var newrecord$88 = Caml_obj.obj_dup($$default);

newrecord$88.hover = undefined;

newrecord$88.block = {
  alignItems: "flexStart"
};

var newrecord$89 = Caml_obj.obj_dup($$default);

newrecord$89.hover = undefined;

newrecord$89.textStyle = Atom_Text_Ds.Teaser.toParent(Atom_Text_Ds.Teaser.makeGuide({
          NAME: "Cta",
          VAL: "Default"
        }));

var TeaserGuide = {
  teaserContainerStandard: newrecord$87,
  teaserContainerBig: newrecord$88,
  genre: newrecord$89
};

var newrecord$90 = Caml_obj.obj_dup($$default);

newrecord$90.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: 40
    });

newrecord$90.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeRegular));

newrecord$90.spacer = particleSpacerVerticalSmaller;

newrecord$90.textStyle = Atom_Text_Ds.Trailer.programEnabled;

var newrecord$91 = Caml_obj.obj_dup($$default);

newrecord$91.hover = "NotAllowed";

newrecord$91.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: 40
    });

newrecord$91.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.inactiveRegular));

newrecord$91.spacer = particleSpacerVerticalSmaller;

newrecord$91.textStyle = Atom_Text_Ds.Trailer.programDisabled;

var newrecord$92 = Caml_obj.obj_dup($$default);

newrecord$92.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.eventZoneTrailer));

newrecord$92.spacer = particleSpacerVerticalSmaller;

newrecord$92.textStyle = Atom_Text_Ds.Trailer.eventZone;

var newrecord$93 = Caml_obj.obj_dup($$default);

newrecord$93.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.teaser));

newrecord$93.spacer = particleSpacerVerticalSmaller;

newrecord$93.textStyle = Atom_Text_Ds.Teaser.Hover.toParent(Atom_Text_Ds.Teaser.Hover.trailerCta);

var newrecord$94 = Caml_obj.obj_dup($$default);

newrecord$94.shape = topTeaserButtons_shape;

newrecord$94.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeRegular));

newrecord$94.spacer = topTeaserButtons_spacer;

newrecord$94.textStyle = topTeaserButtons_textStyleDefault({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
        },
        icon: Atom_Icon_Ds.play,
        size: topTeaserButtons_iconSize
      }
    });

var newrecord$95 = Caml_obj.obj_dup($$default);

newrecord$95.hover = "NotAllowed";

newrecord$95.shape = topTeaserButtons_shape;

newrecord$95.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.inactiveRegular));

newrecord$95.spacer = topTeaserButtons_spacer;

newrecord$95.textStyle = topTeaserButtons_textStyleDisabled({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
        },
        icon: Atom_Icon_Ds.play,
        size: topTeaserButtons_iconSize
      }
    });

var Trailer = {
  enabledProgram: newrecord$90,
  disabledProgram: newrecord$91,
  eventZone: newrecord$92,
  teaser: newrecord$93,
  enabledTopTeaser: newrecord$94,
  disabledTopTeaser: newrecord$95
};

var newrecord$96 = Caml_obj.obj_dup($$default);

newrecord$96.shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "RectangleWithStaticHeight",
      _0: 40
    });

newrecord$96.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "S"), undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "L"));

newrecord$96.background = Particle_Background_Ds.buttonSolid;

newrecord$96.textStyle = Atom_Text_Ds.Setting.ctaActive;

var newrecord$97 = Caml_obj.obj_dup($$default);

newrecord$97.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex));

newrecord$97.hover = undefined;

newrecord$97.textStyle = Atom_Text_Ds.Setting.messageLink;

var newrecord$98 = Caml_obj.obj_dup($$default);

newrecord$98.hover = undefined;

newrecord$98.textStyle = Atom_Text_Ds.Dropdown.current;

var Setting = {
  button: newrecord$96,
  message: newrecord$97,
  dropdown: newrecord$98
};

var newrecord$99 = Caml_obj.obj_dup($$default);

newrecord$99.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeRegular));

newrecord$99.spacer = particleSpacerVerticalSmaller;

newrecord$99.textStyle = Atom_Text_Ds.UserPref.securityCenter;

var UserPref = {
  openSecurityCenter: newrecord$99
};

var newrecord$100 = Caml_obj.obj_dup($$default);

newrecord$100.block = {
  justifyContent: "center",
  wrap: "OnOneLine"
};

newrecord$100.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "L"));

newrecord$100.background = Particle_Background_Ds.black;

newrecord$100.textStyle = Atom_Text_Ds.Cookie.cta;

var newrecord$101 = Caml_obj.obj_dup($$default);

newrecord$101.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.none));

newrecord$101.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));

newrecord$101.textStyle = Atom_Text_Ds.Cookie.linkBanner;

var newrecord$102 = Caml_obj.obj_dup($$default);

newrecord$102.spacer = Particle_Screen_Ds.make(undefined, undefined, spacerVerticalSmaller, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));

newrecord$102.background = Particle_Background_Ds.black;

newrecord$102.textStyle = Atom_Text_Ds.Cookie.cta;

var newrecord$103 = Caml_obj.obj_dup($$default);

newrecord$103.shape = topTeaserButtons_shape;

newrecord$103.textStyle = Atom_Text_Ds.makeSecondaryCtaWithAsset({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: innerSpace
        },
        icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.close, "Black"),
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    });

var Cookie = {
  choice: newrecord$100,
  link: newrecord$101,
  ctaModal: newrecord$102,
  closeModal: newrecord$103
};

var newrecord$104 = Caml_obj.obj_dup($$default);

newrecord$104.hover = undefined;

newrecord$104.borderFocus = Particle_Border_Ds.Avatar.focused;

var newrecord$105 = Caml_obj.obj_dup($$default);

newrecord$105.hover = {
  TAG: "Border",
  _0: undefined
};

newrecord$105.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "M"));

newrecord$105.background = Particle_Background_Ds.Oops.cta404;

newrecord$105.textStyle = Atom_Text_Ds.Oops._404cta;

var newrecord$106 = Caml_obj.obj_dup($$default);

newrecord$106.hover = undefined;

newrecord$106.textStyle = Atom_Text_Ds.Oops._500cta;

var newrecord$107 = Caml_obj.obj_dup($$default);

newrecord$107.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"));

newrecord$107.textStyle = Atom_Text_Ds.makeContentCollection({
      NAME: "Cta",
      VAL: "Default"
    });

var newrecord$108 = Caml_obj.obj_dup($$default);

newrecord$108.hover = {
  TAG: "Color",
  _0: "White"
};

newrecord$108.block = {
  autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
};

var newrecord$109 = Caml_obj.obj_dup($$default);

newrecord$109.hover = undefined;

var newrecord$110 = Caml_obj.obj_dup($$default);

newrecord$110.hover = undefined;

var newrecord$111 = Caml_obj.obj_dup($$default);

newrecord$111.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex"));

newrecord$111.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("S"), undefined, Particle_Spacer_Ds.makeRight("L"));

newrecord$111.textStyle = Atom_Text_Ds.ProgramContent.buyProgramLink;

var newrecord$112 = Caml_obj.obj_dup($$default);

newrecord$112.hover = {
  TAG: "Color",
  _0: "White"
};

var newrecord$113 = Caml_obj.obj_dup($$default);

newrecord$113.hover = "Opacity";

newrecord$113.textStyle = Atom_Text_Ds.ProgramContent.chapter;

function makeColoredBox(backgroundColor) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = {
    TAG: "Border",
    _0: undefined
  };
  newrecord.block = {
    justifyContent: "flexStart",
    alignItems: "center",
    wrap: "OnOneLine",
    alignSelf: "flexStart"
  };
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));
  newrecord.background = backgroundColor;
  return newrecord;
}

var newrecord$114 = Caml_obj.obj_dup($$default);

newrecord$114.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex"));

newrecord$114.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("S"));

newrecord$114.textStyle = Atom_Text_Ds.ProgramContent.makeLink;

function makeDownloadLink(spacer, color) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.block = {
    wrap: "OnOneLine"
  };
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, spacer);
  newrecord.textStyle = Atom_Text_Ds.makeDownload(color, undefined);
  return newrecord;
}

var newrecord$115 = Caml_obj.obj_dup($$default);

newrecord$115.block = {
  wrap: "OnOneLine"
};

newrecord$115.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));

newrecord$115.background = Particle_Background_Ds.white;

newrecord$115.textStyle = Atom_Text_Ds.makeDownloadWithText({
      TAG: "Main",
      _0: "Black"
    }, undefined);

var newrecord$116 = Caml_obj.obj_dup($$default);

newrecord$116.hover = undefined;

newrecord$116.textStyle = Atom_Text_Ds.Dropdown.current;

var newrecord$117 = Caml_obj.obj_dup($$default);

newrecord$117.hover = {
  TAG: "Color",
  _0: "LightGrey"
};

newrecord$117.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

newrecord$117.textStyle = Atom_Text_Ds.Dropdown.listItem;

var newrecord$118 = Caml_obj.obj_dup($$default);

newrecord$118.hover = {
  TAG: "Color",
  _0: "White"
};

newrecord$118.block = {
  wrap: "OnOneLine"
};

newrecord$118.textStyle = Atom_Text_Ds.makeExternal({
      NAME: "Cta",
      VAL: "Default"
    });

var newrecord$119 = Caml_obj.obj_dup($$default);

newrecord$119.hover = "Opacity";

newrecord$119.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.feedback));

newrecord$119.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "M"));

newrecord$119.background = Particle_Background_Ds.primary;

newrecord$119.textStyle = Atom_Text_Ds.User.cta;

var newrecord$120 = Caml_obj.obj_dup($$default);

newrecord$120.hover = "Opacity";

newrecord$120.block = {
  alignItems: "flexStart"
};

function makeGuideTvCalendar(status) {
  var match;
  match = status === "Current" ? [
      Particle_Background_Ds.primary,
      "CursorDefault"
    ] : [
      Particle_Background_Ds.transparent,
      {
        TAG: "Border",
        _0: undefined
      }
    ];
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.hover = match[1];
  newrecord.block = {
    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
  };
  newrecord.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVertical("S"), undefined, undefined, Particle_Spacer_Ds.makeVertical("XS"));
  newrecord.background = match[0];
  return newrecord;
}

var newrecord$121 = Caml_obj.obj_dup($$default);

newrecord$121.display = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex"));

newrecord$121.hover = {
  TAG: "Color",
  _0: "White"
};

newrecord$121.block = {
  alignItems: "center",
  wrap: "OnOneLine"
};

newrecord$121.spacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("Zero", "Zero", "Zero", "XXS"), undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XXS"));

newrecord$121.textStyle = Atom_Text_Ds.Header.make({
      NAME: "Cta",
      VAL: "Default"
    }, {
      TAG: "Logo",
      _0: {
        position: "Alone",
        logo: {
          NAME: "ArteVerticalLogo",
          VAL: "Header"
        }
      }
    }, undefined);

var newrecord$122 = Caml_obj.obj_dup($$default);

newrecord$122.hover = undefined;

newrecord$122.block = {
  alignItems: "flexEnd"
};

newrecord$122.textStyle = Atom_Text_Ds.Presse.listTitle;

function makeNextEpisode(progression) {
  return makeStyleWatch(progression, Caml_option.some(Atom_Icon_Ds.play), Particle_Background_Ds.buttonSolid, undefined);
}

var newrecord$123 = Caml_obj.obj_dup($$default);

newrecord$123.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

newrecord$123.background = Particle_Background_Ds.buttonSolid;

var newrecord$124 = Caml_obj.obj_dup($$default);

newrecord$124.hover = undefined;

var newrecord$125 = Caml_obj.obj_dup($$default);

newrecord$125.hover = "Underline";

newrecord$125.textStyle = Atom_Text_Ds.PlayNextSimple.title;

var newrecord$126 = Caml_obj.obj_dup($$default);

newrecord$126.display = Caml_option.some(Util_Css.Display.fromL);

newrecord$126.border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.default));

newrecord$126.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

var init = Atom_Text_Ds.makeCtaWithAsset({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: innerSpace
        },
        icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.Arrow.back, "White"),
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
      }
    });

newrecord$126.textStyle = {
  asset: init.asset,
  border: init.border,
  color: {
    TAG: "Main",
    _0: "White"
  },
  display: init.display,
  lineHeight: init.lineHeight,
  size: init.size,
  weight: init.weight,
  decoration: init.decoration,
  style: init.style,
  hover: {
    TAG: "Main",
    _0: "LightGrey"
  },
  alignment: init.alignment,
  truncateLine: init.truncateLine,
  whiteSpace: init.whiteSpace
};

var newrecord$127 = Caml_obj.obj_dup($$default);

newrecord$127.hover = "Underline";

newrecord$127.block = {
  wrap: "OnOneLine"
};

newrecord$127.textStyle = Atom_Text_Ds.ProgramExternal.make({
      NAME: "Cta",
      VAL: "Default"
    });

var newrecord$128 = Caml_obj.obj_dup($$default);

newrecord$128.textStyle = Atom_Text_Ds.ProgramContent.valueGenre;

var newrecord$129 = Caml_obj.obj_dup($$default);

newrecord$129.hover = undefined;

newrecord$129.textStyle = Atom_Text_Ds.listItemUnderline;

var newrecord$130 = Caml_obj.obj_dup($$default);

newrecord$130.block = {
  alignSelf: "flexStart"
};

newrecord$130.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"));

newrecord$130.textStyle = Atom_Text_Ds.makeExpanderButton("Top");

var newrecord$131 = Caml_obj.obj_dup($$default);

newrecord$131.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "S"));

newrecord$131.background = Particle_Background_Ds.buttonSolid;

var newrecord$132 = Caml_obj.obj_dup($$default);

newrecord$132.block = {
  alignSelf: "flexStart"
};

newrecord$132.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"));

newrecord$132.textStyle = Atom_Text_Ds.makeExpanderButton("Down");

var newrecord$133 = Caml_obj.obj_dup($$default);

newrecord$133.kind = "Seo";

var newrecord$134 = Caml_obj.obj_dup($$default);

newrecord$134.hover = {
  TAG: "Color",
  _0: "Arte"
};

newrecord$134.textStyle = Atom_Text_Ds.SiteMap.link;

var newrecord$135 = Caml_obj.obj_dup($$default);

newrecord$135.hover = undefined;

var newrecord$136 = Caml_obj.obj_dup($$default);

newrecord$136.spacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

newrecord$136.background = Particle_Background_Ds.tags;

newrecord$136.textStyle = Atom_Text_Ds.Sticker.tag;

var newrecord$137 = Caml_obj.obj_dup($$default);

newrecord$137.hover = undefined;

newrecord$137.textStyle = Atom_Text_Ds.makeTopBanner({
      NAME: "Cta",
      VAL: "Default"
    });

function shapeToParticleShape(shape) {
  if (typeof shape !== "object") {
    return {
            TAG: "Rectangle",
            _0: "Auto"
          };
  }
  switch (shape.TAG) {
    case "RectangleWithStaticHeight" :
        return {
                TAG: "Rectangle",
                _0: {
                  TAG: "Height",
                  _0: shape._0
                }
              };
    case "Circle" :
        return {
                TAG: "Circle",
                _0: shape._0
              };
    case "Square" :
        return {
                TAG: "Square",
                _0: shape._0
              };
    
  }
}

function view(param) {
  return {
          block: param.block,
          textStyle: param.textStyle,
          background: param.background,
          spacer: param.spacer,
          border: param.border,
          borderFocus: param.borderFocus,
          shape: Particle_Screen_Ds.map(param.shape, shapeToParticleShape),
          hover: param.hover,
          display: param.display,
          kind: param.kind,
          zIndex: param.zIndex
        };
}

var topTeaserButtonHeight = 40;

var avatar = newrecord$104;

var backHome404 = newrecord$105;

var backHome500 = newrecord$106;

var backLink = newrecord$107;

var banner = newrecord$108;

var broadcast = newrecord$109;

var breadcrumb = newrecord$110;

var buyProgram = newrecord$111;

var categoryTeaser = newrecord$112;

var chapter = newrecord$113;

var descriptionLink = newrecord$114;

var makeDownloadLinkWithText = newrecord$115;

var dropdown = newrecord$116;

var dropdownItem = newrecord$117;

var externalLink = newrecord$118;

var feedback = newrecord$119;

var teaserUser = newrecord$120;

var menuBrandLogo = newrecord$121;

var listItem = newrecord$122;

var paginatedAction = newrecord$123;

var partner = newrecord$124;

var playNextSimple = newrecord$125;

var $$short = newrecord$126;

var programExternal = newrecord$127;

var programMetaGenre = newrecord$128;

var searchNoResultsAdvice = newrecord$129;

var seeLessExpanderButton = newrecord$130;

var seeMore = newrecord$131;

var seeMoreExpanderButton = newrecord$132;

var seo = newrecord$133;

var siteMap = newrecord$134;

var storeButton = newrecord$135;

var tag = newrecord$136;

var topBanner = newrecord$137;

export {
  getPredefinedHeight ,
  getPredefinedWidth ,
  innerSpace ,
  spacerVerticalSmaller ,
  particleSpacerVerticalSmaller ,
  particleSpacerVerticalSmallerWithMedValue ,
  topTeaserButtonHeight ,
  topTeaserButtons ,
  $$default as default,
  makeStyleWatch ,
  shapeArrow ,
  shapeControl ,
  AndroidReleases ,
  Carousel ,
  Corporate ,
  Delete ,
  EventZone ,
  Favorite ,
  Footer ,
  GenreSelect ,
  Header ,
  Message ,
  User ,
  NewsletterZone ,
  Papa ,
  Player ,
  Presse ,
  Sharebar ,
  Slider ,
  Teaser ,
  TeaserGuide ,
  Trailer ,
  Setting ,
  UserPref ,
  Cookie ,
  avatar ,
  backHome404 ,
  backHome500 ,
  backLink ,
  banner ,
  broadcast ,
  breadcrumb ,
  buyProgram ,
  categoryTeaser ,
  chapter ,
  makeColoredBox ,
  descriptionLink ,
  makeDownloadLink ,
  makeDownloadLinkWithText ,
  dropdown ,
  dropdownItem ,
  externalLink ,
  feedback ,
  teaserUser ,
  makeGuideTvCalendar ,
  menuBrandLogo ,
  listItem ,
  makeNextEpisode ,
  paginatedAction ,
  partner ,
  playNextSimple ,
  $$short ,
  programExternal ,
  programMetaGenre ,
  searchNoResultsAdvice ,
  seeLessExpanderButton ,
  seeMore ,
  seeMoreExpanderButton ,
  seo ,
  siteMap ,
  storeButton ,
  tag ,
  topBanner ,
  shapeToParticleShape ,
  view ,
}
/* innerSpace Not a pure module */
