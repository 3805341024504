// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";

function getBadgeURl(store, locale) {
  return "/footer/badges/" + store + "_" + Util_I18n.localeToString(locale) + ".png";
}

function make(image, cdnUrl) {
  var tmp;
  if (typeof image !== "object") {
    switch (image) {
      case "AfterRightVOD" :
          tmp = "/artevod.png";
          break;
      case "Background500" :
          tmp = "/500/background.jpeg";
          break;
      case "Error500" :
          tmp = "/500/title.png";
          break;
      case "EuropeanFlag" :
          tmp = "/footer/europe.png";
          break;
      case "NewsletterZone" :
          tmp = "/newsletter.jpg";
          break;
      case "QrCode" :
          tmp = "/shorts/ARTE_Shorts_QR-code.jpg";
          break;
      
    }
  } else {
    switch (image.TAG) {
      case "Badge" :
          tmp = getBadgeURl(image._0, image._1);
          break;
      case "BuyProgram" :
          tmp = image._0 === "Available" ? "/program/artevod.png" : "/program/artenovod.png";
          break;
      case "Newsletter" :
          tmp = "/newsletter/newsletter_" + Util_I18n.localeToString(image._0) + ".png";
          break;
      
    }
  }
  return Type_String.CdnUrl.addPath(cdnUrl, tmp);
}

export {
  getBadgeURl ,
  make ,
}
/* No side effect */
