'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context_I18n from "./Context_I18n.res.mjs";
import * as Context_Config from "./Context_Config.res.mjs";

function Make(B) {
  var initialState = B.initialState;
  var initialFullState = {
    status: "NotInitialized",
    state: initialState
  };
  var ctx = React.createContext(initialFullState);
  var use = function () {
    var state = React.useContext(ctx);
    return [
            state.state,
            state.status
          ];
  };
  var use$1 = function () {
    return use()[0];
  };
  var make = function (status, state) {
    return {
            status: status,
            state: state
          };
  };
  var provider = ctx.Provider;
  var Context$Make$Provider = function (props) {
    return React.createElement(provider, {
                value: props.value,
                children: props.children
              });
  };
  return {
          initialState: initialState,
          use: use$1,
          make: make,
          Provider: {
            make: Context$Make$Provider
          }
        };
}

var initialFullState = {
  status: "NotInitialized",
  state: Context_Config.initialState
};

var ctx = React.createContext(initialFullState);

function use() {
  var state = React.useContext(ctx);
  return [
          state.state,
          state.status
        ];
}

function use$1() {
  return use()[0];
}

function make(status, state) {
  return {
          status: status,
          state: state
        };
}

var provider = ctx.Provider;

function Context$Make$Provider(props) {
  return React.createElement(provider, {
              value: props.value,
              children: props.children
            });
}

var Context_Provider = {
  make: Context$Make$Provider
};

var Context = {
  initialState: Context_Config.initialState,
  use: use$1,
  make: make,
  Provider: Context_Provider
};

function getFeatureFlippingStatus(requestedFeature) {
  var state = use$1();
  return Context_Config.Helpers.getFeatureFlippingStatus(requestedFeature, state);
}

var Config_Provider = Context_Provider;

var Config = {
  Context: Context,
  Helpers: undefined,
  getFeatureFlippingStatus: getFeatureFlippingStatus,
  initialState: Context_Config.initialState,
  use: use$1,
  make: make,
  Provider: Config_Provider
};

var initialFullState$1 = {
  status: "NotInitialized",
  state: Context_I18n.initialState
};

var ctx$1 = React.createContext(initialFullState$1);

function use$2() {
  var state = React.useContext(ctx$1);
  return [
          state.state,
          state.status
        ];
}

function use$3() {
  return use$2()[0];
}

function make$1(status, state) {
  return {
          status: status,
          state: state
        };
}

var provider$1 = ctx$1.Provider;

function Context$Make$Provider$1(props) {
  return React.createElement(provider$1, {
              value: props.value,
              children: props.children
            });
}

var I18n_Provider = {
  make: Context$Make$Provider$1
};

var I18n = {
  initialState: Context_I18n.initialState,
  use: use$3,
  make: make$1,
  Provider: I18n_Provider
};

export {
  Make ,
  Config ,
  I18n ,
}
/* ctx Not a pure module */
