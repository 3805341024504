import { createContext, useContext, useState, ReactElement, ReactNode, Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import searchWafRegex from './searchWafRegex';

export enum SearchGenre {
    ALL = 'all',
    DOCUMENTARIES = 'documentaries',
    MOVIES = 'movies',
    TV_SERIES = 'tv_series',
    TV_SHOWS = 'tv_shows',
    CONCERTS = 'concerts',
}

type ContextProps = {
    searchBarStatus: SearchBarStatusType;
    setSearchBarStatus: Dispatch<SetStateAction<SearchBarStatusType>>;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    page: number;
    setSearchGenre: (value: SearchGenre) => void;
    searchGenre: SearchGenre;
    handleOpenSearch: () => void;
    handleCloseSearch: () => void;
};

type SearchProviderProps = {
    children: ReactNode;
};

const contextDefaultValue: ContextProps = {
    searchBarStatus: 'close',
    setSearchBarStatus: () => null,
    searchTerm: '',
    setSearchTerm: () => undefined,
    page: 0,
    setSearchGenre: () => undefined,
    searchGenre: SearchGenre.ALL,
    handleCloseSearch: () => undefined,
    handleOpenSearch: () => undefined,
};

const SearchContext = createContext<ContextProps>(contextDefaultValue);

const ensureSearchGenre = (genre: string | string[] | undefined): SearchGenre => {
    const ensuredGenre = Array.isArray(genre) ? genre[0] : genre;
    if (ensuredGenre === SearchGenre.DOCUMENTARIES) {
        return SearchGenre.DOCUMENTARIES;
    }
    if (ensuredGenre === SearchGenre.MOVIES) {
        return SearchGenre.MOVIES;
    }
    if (ensuredGenre === SearchGenre.TV_SERIES) {
        return SearchGenre.TV_SERIES;
    }
    if (ensuredGenre === SearchGenre.TV_SHOWS) {
        return SearchGenre.TV_SHOWS;
    }
    if (ensuredGenre === SearchGenre.CONCERTS) {
        return SearchGenre.CONCERTS;
    }
    return SearchGenre.ALL;
};

export type SearchBarStatusType = 'open' | 'loading' | 'close';

const ensureForWaf = (searchTerm: string): string | undefined => {
    return searchTerm.match(searchWafRegex)?.join('+');
};

export const SearchProvider = ({ children }: SearchProviderProps): ReactElement => {
    const [searchBarStatus, setSearchBarStatus] = useState<SearchBarStatusType>('close');
    const [fromPathName, setFromPathName] = useState('');
    const [fromQuery, setFromQuery] = useState({});
    const { query, push, pathname, locale } = useRouter();

    const searchTerm = (query.q as string) || '';

    const page = parseInt((query.page as string) || '1', 10);

    const [searchGenre, setSearchGenre] = useState<SearchGenre>(ensureSearchGenre(query.genre));

    const setSearchTerm = debounce((searchTerm: string) => {
        const ensuredSearchTerm = ensureForWaf(searchTerm);
        if (ensuredSearchTerm) {
            push({ pathname: `/${locale}/search/`, query: { q: ensuredSearchTerm, genre: searchGenre } }, undefined, {
                shallow: true,
            });
        } else {
            push({ pathname: `/${locale}/search/` }, undefined, {
                shallow: true,
            });
        }
    }, 700);

    const handleOpenSearch = () => {
        setFromPathName(`/${locale}${pathname}`);
        setFromQuery(query);
        setSearchBarStatus('open');
    };
    const handleCloseSearch = async () => {
        setSearchBarStatus('loading');
        if (fromPathName) {
            await push({ pathname: fromPathName, query: fromQuery });
            setFromQuery({});
            setFromPathName('');
        }
        setSearchBarStatus('close');
    };

    return (
        <SearchContext.Provider
            value={{
                searchBarStatus,
                setSearchBarStatus,
                searchTerm: searchTerm.replace(/\+/g, ' '),
                setSearchTerm,
                page,
                setSearchGenre,
                searchGenre,
                handleOpenSearch,
                handleCloseSearch,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const useSearch = (): ContextProps => {
    return useContext(SearchContext);
};
