// Generated by ReScript, PLEASE EDIT WITH CARE


function fromResult(x) {
  if (x.TAG === "Ok") {
    return {
            tag: "Ok",
            value: x._0
          };
  } else {
    return {
            tag: "Error",
            value: x._0
          };
  }
}

function toResult(x) {
  if (x.tag === "Ok") {
    return {
            TAG: "Ok",
            _0: x.value
          };
  } else {
    return {
            TAG: "Error",
            _0: x.value
          };
  }
}

export {
  fromResult ,
  toResult ,
}
/* No side effect */
