// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as UseOnBlur from "../../../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../../../hook/UseToggle.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as UseClickOutside from "../../../../hook/UseClickOutside.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Molecule_BurgerMenuIcon from "../../../molecule/Molecule_BurgerMenuIcon.res.mjs";
import * as Molecule_BurgerMenuIcon_Ds from "../../../molecule/Molecule_BurgerMenuIcon_Ds.res.mjs";
import * as Organism_ConcertMenuNavigation from "./Organism_ConcertMenuNavigation.res.mjs";

function Organism_ConcertBurgerMenu(props) {
  var match = Context.I18n.use();
  var match$1 = match.labels.navbar;
  var match$2 = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var close = match$2.close;
  var state = match$2.state;
  var ref = React.useRef(null);
  UseClickOutside.make(ref, close);
  UseOnBlur.make(ref, close);
  return JsxRuntime.jsxs(Atom_Cell.make, {
              display: props.display,
              kind: {
                TAG: "Nav",
                _0: match$1.mainNavigation
              },
              children: [
                JsxRuntime.jsx(Molecule_BurgerMenuIcon.make, {
                      state: state,
                      toggle: match$2.toggle,
                      style: Molecule_BurgerMenuIcon_Ds.header
                    }),
                JsxRuntime.jsx(Organism_ConcertMenuNavigation.make, {
                      links: props.links,
                      state: state,
                      socialNetworks: props.socialNetworks,
                      selectGenre: props.selectGenre
                    })
              ],
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.unset)])),
              ref_: ref
            });
}

var make = Organism_ConcertBurgerMenu;

export {
  make ,
}
/* CssJs Not a pure module */
