import { Geoblocking } from '@artegeie/design-system/type';
import { geoblockingOrdered } from '@artegeie/design-system/replay';

export const isTeaserAvailable =
    (userGeoblocking: Geoblocking) =>
    (teaserGeoblocking: Geoblocking): boolean => {
        const priorityTeaser = geoblockingOrdered.findIndex((value: Geoblocking) => teaserGeoblocking === value);
        const priorityUser = geoblockingOrdered.findIndex((value: Geoblocking) => userGeoblocking === value);

        return priorityTeaser >= priorityUser;
    };
