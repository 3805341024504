// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      d: "M22.4694 6.05864L9.07307 11.4167H5.43746C4.09415 11.4167 3 12.5109 3 13.8542V16.8333C3 18.1094 4.00207 18.9999 5.43746 18.9999H6.35828L7.34193 25.8855C7.39935 26.2863 7.74167 26.5831 8.14575 26.5831H11.3957C11.6275 26.5831 11.8474 26.4846 12.0024 26.3123C12.1562 26.1401 12.2298 25.9104 12.2039 25.6797L11.5647 19.9966L22.4683 24.358C22.5668 24.397 22.6687 24.4165 22.7705 24.4165C22.9298 24.4165 23.0901 24.3688 23.2266 24.2768C23.4497 24.1251 23.583 23.8738 23.583 23.604V6.81263C23.583 6.54288 23.4497 6.29155 23.2266 6.13989C23.0023 5.98823 22.7185 5.95789 22.4694 6.05864Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M25.9869 12.8641L28.6952 10.6975C29.0451 10.4169 29.1025 9.90555 28.8219 9.55456C28.5403 9.20573 28.03 9.1494 27.679 9.42781L24.9707 11.5944C24.6208 11.875 24.5634 12.3863 24.844 12.7373C25.0043 12.9367 25.2405 13.0418 25.4788 13.0418C25.6575 13.0418 25.8363 12.9833 25.9869 12.8641Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M25.9869 18.6356C25.638 18.3572 25.1267 18.4135 24.844 18.7623C24.5634 19.1122 24.6208 19.6246 24.9707 19.9052L27.679 22.0719C27.8296 22.191 28.0083 22.2495 28.1871 22.2495C28.4254 22.2495 28.6616 22.1444 28.8219 21.9451C29.1025 21.5952 29.0451 21.0828 28.6952 20.8022L25.9869 18.6356Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M28.1871 14.9377H25.4788C25.0303 14.9377 24.6663 15.3017 24.6663 15.7502C24.6663 16.1987 25.0303 16.5627 25.4788 16.5627H28.1871C28.6356 16.5627 28.9996 16.1987 28.9996 15.7502C28.9996 15.3017 28.6356 14.9377 28.1871 14.9377Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
