import { useConnectedToken } from '@artegeie/design-system/replay';
import { useRouter } from 'next/navigation';
import { isOk } from '@replay/types/Result';
import { fetchJson } from '@replay/fetch';
import { Locale } from '@replay/types/Locale';
import { getBaseURL } from './useRedirect';

export const useYoti = (locale: Locale) => {
    const userToken = useConnectedToken();
    const router = useRouter();

    const fetchYotiUrl = async (cbUrl: string, overrideToken?: string) => {
        const headers = {
            Authorization: userToken || overrideToken || '',
            client: 'web',
            ['Cache-Control']: 'no-cache',
        };
        const searchParams: { cbUrl: string; locale?: string } = locale
            ? {
                  cbUrl,
                  locale: locale,
              }
            : { cbUrl };
        const res = await fetchJson<{ verificationUrl: string }>(
            `/api/rproxy/yoti?${new URLSearchParams(searchParams)}`,
            {
                requestInit: {
                    method: 'GET',
                    headers,
                },
            },
        );

        if (isOk(res)) {
            return res.value.verificationUrl;
        }
        return null;
    };

    const redirectToYoti = async (returnPage?: string, overrideToken?: string) => {
        const redirectUrl = new URL(`${getBaseURL(locale)}/profile/verification-pending/`);
        redirectUrl.searchParams.set('returnPage', returnPage || window.location.href);
        const url = await fetchYotiUrl(redirectUrl.href, overrideToken);
        if (url) {
            router.push(url);
        }
    };

    return redirectToYoti;
};
