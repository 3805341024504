// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M23.4765 4H27.4634L18.7533 13.955L29 27.5016H20.9769L14.693 19.2857L7.50267 27.5016H3.51342L12.8297 16.8536L3 4H11.2268L16.9069 11.5097L23.4765 4ZM22.0773 25.1153H24.2864L10.0264 6.26097H7.65573L22.0773 25.1153Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
