'use client';
import { StateStorage, createJSONStorage } from "zustand/middleware";


const storage: StateStorage = {
    getItem: (name: string): string | null => {
      try {
        if (typeof name !== 'string' || name.trim() === '') {
          throw new Error('Invalid key provided to getItem.');
        }
        return globalThis?.localStorage?.getItem ? globalThis.localStorage.getItem(name) : null;
      } catch (error) {
        console.error('Error in getItem:', error);
        return null;
      }
    },
    setItem: (name: string, value: string): void => {
      try {
        if (typeof name !== 'string' || name.trim() === '') {
          throw new Error('Invalid key provided to setItem.');
        }
        if (typeof value !== 'string') {
          throw new Error('Invalid value provided to setItem. Must be a string.');
        }
        globalThis?.localStorage?.setItem(name, value);
      } catch (error) {
        if ((error as DOMException)?.name === 'QuotaExceededError') {
          // TODO: Take extra counter measures or display a toast to warn the user that some data may not be stored properly ? 
          console.warn('Storage quota exceeded.');
        } else {
          console.error('Error in setItem:', error);
        }
      }
    },
    removeItem: (name: string): void => {
      try {
        if (typeof name !== 'string' || name.trim() === '') {
          throw new Error('Invalid key provided to removeItem.');
        }
        globalThis?.localStorage?.removeItem(name);
      } catch (error) {
        console.error('Error in removeItem:', error);
      }
    },
  };
  

  export const rawStorage = storage;


export const customLocalStorage = createJSONStorage(() => storage)