// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";

function makeList(listType, padding, color) {
  return {
          listStyleType: listType,
          padding: padding,
          markerColor: color
        };
}

function androidReleasesUl(color) {
  return {
          TAG: "Uniform",
          _0: {
            listStyleType: "disc",
            padding: "S",
            markerColor: color
          }
        };
}

function androidReleasesOl(color) {
  return {
          TAG: "Uniform",
          _0: {
            listStyleType: "decimal",
            padding: "S",
            markerColor: color
          }
        };
}

var defaultUl = {
  TAG: "Uniform",
  _0: {
    listStyleType: "disc",
    padding: "S",
    markerColor: "Text"
  }
};

var defaultOl = {
  TAG: "Uniform",
  _0: {
    listStyleType: "decimal",
    padding: "S",
    markerColor: "Text"
  }
};

var papaUl = {
  TAG: "Uniform",
  _0: {
    listStyleType: "disc",
    padding: "S",
    markerColor: "Arte"
  }
};

var head = {
  listStyleType: "disc",
  padding: "S",
  markerColor: "Arte"
};

var tail = [{
    listStyleType: "circle",
    padding: "S",
    markerColor: "Arte"
  }];

var presseUl = {
  TAG: "MultiLevel",
  _0: Util_Array.NonEmpty.make(tail, head)
};

function makeStyle(list) {
  var listStyle = [CssJs.listStyleType(list.listStyleType)];
  var paddingStyle = Particle_Spacer_Css.toCss(Particle_Spacer_Ds.Inside.makeLeft(list.padding));
  var color = [CssJs.selector("li::marker", [CssJs.color(Particle_Color.Css.color(list.markerColor))])];
  return [
            listStyle,
            paddingStyle,
            color
          ].flat();
}

function arrayListToStyle(listArray) {
  var rules = [];
  var head = Util_Array.NonEmpty.head(listArray);
  var headStyle = makeStyle(head);
  Util_Array.NonEmpty.toArray(listArray).slice(1).forEach(function (style, index) {
        var selector = " > li > ul".repeat(index + 1 | 0);
        rules.push(CssJs.selector(selector, makeStyle(style)));
      });
  return rules.concat(headStyle);
}

function toCss(t) {
  if (typeof t !== "object") {
    return [];
  } else if (t.TAG === "Uniform") {
    return makeStyle(t._0);
  } else {
    return arrayListToStyle(t._0);
  }
}

export {
  makeList ,
  androidReleasesUl ,
  androidReleasesOl ,
  defaultUl ,
  defaultOl ,
  papaUl ,
  presseUl ,
  makeStyle ,
  arrayListToStyle ,
  toCss ,
}
/* presseUl Not a pure module */
