// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Atom_Cta from "./Atom_Cta.res.mjs";
import * as SetFocus from "../../hook/SetFocus.res.mjs";
import * as Util_Dom from "../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "./Atom_Cell.res.mjs";
import * as Atom_Icon from "./Atom_Icon.res.mjs";
import * as Atom_Text from "./Atom_Text.res.mjs";
import * as Atom_Column from "./Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Pattern from "../../type/Type_Pattern.res.mjs";
import * as Atom_Text_Css from "./Atom_Text_Css.res.mjs";
import * as Type_Textfield from "../../type/Type_Textfield.res.mjs";
import * as Atom_Textfield_Ds from "./Atom_Textfield_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Atom_Textfield_Css from "./Atom_Textfield_Css.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Css from "../particle/Particle_Background_Css.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var textAreaCss = [CssJs.whiteSpace("preWrap")];

function Atom_Textfield$Internal(props) {
  var __children = props.children;
  var action = props.action;
  var inputRef = props.inputRef;
  var id = props.id;
  var name = props.name;
  var __focusOutline = props.focusOutline;
  var autoSize = props.autoSize;
  var __readOnly = props.readOnly;
  var __required = props.required;
  var onChange = props.onChange;
  var onBlur = props.onBlur;
  var label = props.label;
  var value = props.value;
  var type_ = props.type_;
  var required = __required !== undefined ? __required : false;
  var readOnly = __readOnly !== undefined ? __readOnly : false;
  var focusOutline = __focusOutline !== undefined ? __focusOutline : Particle_Border_Ds.Textfield.active;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var match = React.useState(function () {
        return Core__Option.getOr(value, "");
      });
  var setInternalValue = match[1];
  var match$1 = React.useState(function () {
        return Core__Option.getOr(type_, "text");
      });
  var setInputType = match$1[1];
  var inputType = match$1[0];
  var match$2 = React.useState(function () {
        return "Inactive";
      });
  var setInputState = match$2[1];
  var writableState = readOnly ? "ReadOnly" : "Writable";
  var dsStyle = Atom_Textfield_Ds.make({
        TAG: "User",
        _0: "Writable"
      });
  var match$3 = Atom_Textfield_Ds.view(dsStyle);
  var icon = match$3.icon;
  var match$4 = Atom_Textfield_Css.toCss(dsStyle);
  var iconWrapperRules = match$4.iconWrapperRules;
  var border = match$4.border;
  var textClass = Particle_Screen_Css.toCss2((function (a) {
          return a;
        }), Atom_Text_Css.toCss(Atom_Text_Ds.view(match$3.textStyle)));
  var direction = Core__Option.getOr(match$3.direction, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "column"));
  var inputBaseCss = (function (__x) {
        return CssJs.style(__x);
      })(Particle_Background_Css.toCss(match$3.background).concat(Atom_Textfield_Css.inputBaseCss));
  var textAreaCss$1 = (function (__x) {
        return CssJs.style(__x);
      })(type_ !== undefined ? (
          typeof type_ === "object" ? (
              type_.NAME === "textArea" ? textAreaCss : []
            ) : []
        ) : []);
  var inputBaseClassname = CssJs.merge([
        inputBaseCss,
        textAreaCss$1
      ]);
  var handleFocus = function (param) {
    setInputState(function (param) {
          return "Active";
        });
  };
  var handleChange = function ($$event) {
    var value = $$event.target.value;
    if (onChange !== undefined) {
      onChange(value);
    }
    setInternalValue(value);
  };
  var handleBlur = function ($$event) {
    var value = $$event.target.value;
    if (onBlur !== undefined) {
      onBlur(value);
    }
    setInputState(function (param) {
          return "Inactive";
        });
  };
  var defaultId = React.useId();
  var border$1;
  border$1 = match$2[0] === "Active" && writableState === "Writable" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, focusOutline) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, border);
  var inputRef$1 = inputRef !== undefined ? inputRef : React.useRef(null);
  var pattern = Core__Option.map(props.pattern, Type_Pattern.toString);
  var labelId = Core__Option.getOr(id, defaultId) + "-label";
  var rows = type_ !== undefined && typeof type_ === "object" && type_.NAME === "textArea" ? type_.VAL : undefined;
  var tmp;
  tmp = writableState === "Writable" ? false : true;
  var inputProps_ref = Caml_option.some(inputRef$1);
  var inputProps_aria$invalid = props.ariaInvalid;
  var inputProps_aria$label = props.ariaLabel;
  var inputProps_aria$describedby = props.ariaDescribedby;
  var inputProps_className = Cn.make([
        CssJs.style(match$4.placeholderSizeRules),
        CssJs.style(match$4.blockSpace),
        textClass,
        inputBaseClassname
      ]);
  var inputProps_title = props.title;
  var inputProps_autoComplete = Core__Option.map(props.autoComplete, (function (a) {
          return a;
        }));
  var inputProps_autoFocus = props.autoFocus;
  var inputProps_max = props.max;
  var inputProps_min = props.min;
  var inputProps_placeholder = Core__Option.getOr(props.placeholder, "");
  var inputProps_readOnly = tmp;
  var inputProps_required = required;
  var inputProps_type = Type_Textfield.inputTypeToString(inputType);
  var inputProps_value = match[0];
  var inputProps_onFocus = handleFocus;
  var inputProps_onBlur = handleBlur;
  var inputProps_onChange = handleChange;
  var inputProps = {
    ref: inputProps_ref,
    "aria-invalid": inputProps_aria$invalid,
    "aria-label": inputProps_aria$label,
    "aria-describedby": inputProps_aria$describedby,
    className: inputProps_className,
    id: id,
    title: inputProps_title,
    autoComplete: inputProps_autoComplete,
    autoFocus: inputProps_autoFocus,
    max: inputProps_max,
    min: inputProps_min,
    name: name,
    pattern: pattern,
    placeholder: inputProps_placeholder,
    readOnly: inputProps_readOnly,
    required: inputProps_required,
    rows: rows,
    type: inputProps_type,
    value: inputProps_value,
    onFocus: inputProps_onFocus,
    onBlur: inputProps_onBlur,
    onChange: inputProps_onChange
  };
  var input = React.createElement(type_ !== undefined && typeof type_ === "object" && type_.NAME === "textArea" ? "textarea" : "input", inputProps);
  var action$1;
  var exit = 0;
  if (type_ === "password") {
    if (action !== undefined) {
      if (action.TAG === "Unique") {
        exit = 1;
      } else {
        var style = action.style;
        action$1 = inputType === "password" ? JsxRuntime.jsx(Atom_Cta.Button.make, {
                accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, action.accessibilityLabelOff, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                label: action.labelOff,
                onClick: (function (param) {
                    setInputType(function (param) {
                          return "text";
                        });
                    SetFocus.setFocus(Util_Dom.getDomRef(inputRef$1));
                  }),
                style: style
              }) : (
            inputType === "text" ? JsxRuntime.jsx(Atom_Cta.Button.make, {
                    accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, action.accessibilityLabelOn, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                    label: action.labelOn,
                    onClick: (function (param) {
                        setInputType(function (param) {
                              return "password";
                            });
                        SetFocus.setFocus(Util_Dom.getDomRef(inputRef$1));
                      }),
                    style: style
                  }) : null
          );
      }
    } else {
      action$1 = null;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    action$1 = action !== undefined && action.TAG === "Unique" && writableState !== "Writable" ? JsxRuntime.jsx(Atom_Cta.Button.make, {
            accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, action.accessibilityLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            label: action.label,
            onClick: action.onClick,
            style: action.style
          }) : null;
  }
  var labelKind = id !== undefined ? ({
        TAG: "Label",
        _0: id
      }) : ({
        TAG: "Text",
        _0: "label"
      });
  var match$5 = label !== undefined ? [
      JsxRuntime.jsx(Atom_Text.InputLabel.make, {
            children: Caml_option.some(label),
            id: labelId,
            kind: labelKind,
            style: match$3.labelTextStyle
          }),
      null
    ] : (
      name !== undefined ? [
          null,
          JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(name),
                id: labelId,
                style: Atom_Text_Ds.User.hiddenLabel
              })
        ] : [
          null,
          null
        ]
    );
  return JsxRuntime.jsxs(Atom_Box.make, {
              alignItems: match$3.alignItems,
              autoSize: autoSize,
              children: [
                match$5[0],
                JsxRuntime.jsxs(Atom_Cell.make, {
                      autoSize: autoSize,
                      displayOrder: props.displayOrder,
                      kind: props.kind,
                      border: Caml_option.some(border$1),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                      children: [
                        match$5[1],
                        children,
                        input,
                        action$1,
                        icon !== undefined && iconWrapperRules !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
                                children: JsxRuntime.jsx(Atom_Icon.make, {
                                      icon: icon.icon,
                                      size: icon.size
                                    }),
                                position: Caml_option.some(Particle_Screen_Constants.positionAbsolute),
                                rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, iconWrapperRules))
                              }) : null
                      ],
                      position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                      rules: props.rules
                    })
              ],
              direction: Caml_option.some(direction),
              height: match$3.height,
              width: match$3.width,
              innerSpace: Caml_option.some(match$3.labelInnerspace)
            });
}

function Atom_Textfield$WithError(props) {
  var errorMessage = props.errorMessage;
  var __focusOutline = props.focusOutline;
  var autoSize = props.autoSize;
  var __style = props.style;
  var __readOnly = props.readOnly;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var readOnly = __readOnly !== undefined ? __readOnly : false;
  var style = __style !== undefined ? __style : "Default";
  var focusOutline = __focusOutline !== undefined ? __focusOutline : Particle_Border_Ds.Textfield.active;
  var defaultId = React.useId();
  var id = Core__Option.getOr(props.id, defaultId);
  var name = Core__Option.getOr(props.name, id);
  var innerSpace = Core__Option.map(errorMessage, (function (param) {
          return Particle_Screen_Constants.xsInnerSpace;
        }));
  var errorId = id + "-error";
  var match = errorMessage !== undefined ? [
      errorId,
      "true"
    ] : [
      undefined,
      undefined
    ];
  return JsxRuntime.jsxs(Atom_Column.make, {
              autoSize: autoSize,
              children: [
                JsxRuntime.jsx(Atom_Textfield$Internal, {
                      ariaInvalid: match[1],
                      ariaDescribedby: match[0],
                      ariaLabel: props.ariaLabel,
                      title: props.title,
                      type_: props.type_,
                      value: props.value,
                      label: props.label,
                      placeholder: props.placeholder,
                      onBlur: props.onBlur,
                      onChange: props.onChange,
                      required: required,
                      readOnly: readOnly,
                      displayOrder: props.displayOrder,
                      rules: props.rules,
                      style: style,
                      autoFocus: props.autoFocus,
                      autoSize: autoSize,
                      focusOutline: focusOutline,
                      autoComplete: props.autoComplete,
                      pattern: props.pattern,
                      name: name,
                      id: id,
                      kind: "Span",
                      inputRef: props.inputRef,
                      action: props.action
                    }),
                errorMessage !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                        children: Caml_option.some(errorMessage),
                        id: errorId,
                        style: Atom_Text_Ds.makeFeedback("Error")
                      }) : null
              ],
              innerSpace: innerSpace
            });
}

var WithError = {
  make: Atom_Textfield$WithError
};

function Atom_Textfield(props) {
  var __focusOutline = props.focusOutline;
  var __style = props.style;
  var __readOnly = props.readOnly;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var readOnly = __readOnly !== undefined ? __readOnly : false;
  var style = __style !== undefined ? __style : "Default";
  var focusOutline = __focusOutline !== undefined ? __focusOutline : Particle_Border_Ds.Textfield.active;
  return JsxRuntime.jsx(Atom_Textfield$Internal, {
              ariaDescribedby: props.ariaDescribedby,
              ariaLabel: props.ariaLabel,
              title: props.title,
              type_: props.type_,
              value: props.value,
              label: props.label,
              placeholder: props.placeholder,
              onChange: props.onChange,
              required: required,
              readOnly: readOnly,
              displayOrder: props.displayOrder,
              rules: props.rules,
              style: style,
              autoFocus: props.autoFocus,
              autoSize: props.autoSize,
              focusOutline: focusOutline,
              autoComplete: props.autoComplete,
              pattern: props.pattern,
              name: props.name,
              inputRef: props.inputRef
            });
}

function Atom_Textfield$User$TvCell(props) {
  var __readOnly = props.readOnly;
  var __required = props.required;
  var __autoComplete = props.autoComplete;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "off";
  var required = __required !== undefined ? __required : false;
  var readOnly = __readOnly !== undefined ? __readOnly : true;
  return JsxRuntime.jsx(Atom_Textfield$Internal, {
              ariaLabel: props.ariaLabel,
              title: props.title,
              type_: props.type_,
              value: props.value,
              placeholder: props.placeholder,
              onBlur: props.onBlur,
              onChange: props.onChange,
              required: required,
              readOnly: readOnly,
              style: "TvCodeCell",
              autoComplete: autoComplete,
              pattern: props.pattern,
              inputRef: props.inputRef
            });
}

var TvCell = {
  make: Atom_Textfield$User$TvCell
};

function Atom_Textfield$User$WithError(props) {
  var __required = props.required;
  var __autoComplete = props.autoComplete;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "off";
  var required = __required !== undefined ? __required : false;
  return JsxRuntime.jsx(Atom_Textfield$WithError, {
              ariaLabel: props.ariaLabel,
              title: props.title,
              type_: props.type_,
              value: props.value,
              label: props.label,
              placeholder: props.placeholder,
              onBlur: props.onBlur,
              onChange: props.onChange,
              required: required,
              style: {
                TAG: "User",
                _0: "Writable"
              },
              autoFocus: props.autoFocus,
              autoSize: props.autoSize,
              focusOutline: Particle_Border_Ds.Textfield.userActive,
              autoComplete: autoComplete,
              pattern: props.pattern,
              id: props.id,
              name: props.name,
              errorMessage: props.errorMessage,
              inputRef: props.inputRef,
              action: props.action
            });
}

var WithError$1 = {
  make: Atom_Textfield$User$WithError
};

function Atom_Textfield$User$Readonly(props) {
  var value = props.value;
  var fakeInputStyle = Atom_Textfield_Ds.make({
        TAG: "User",
        _0: "ReadOnly"
      });
  var match = Atom_Textfield_Ds.view(fakeInputStyle);
  var match$1 = Atom_Textfield_Css.toCss(fakeInputStyle);
  var backgroundStyle = Particle_Background_Css.toCss(match.background);
  var textStyle = Atom_Text_Css.toCss(Atom_Text_Ds.view(match.textStyle));
  var fakeInputRules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Atom_Textfield_Css.inputBaseCss.concat(match$1.blockSpace, backgroundStyle, [CssJs.alignSelf("center")])), textStyle);
  return JsxRuntime.jsx(Atom_Textfield$Internal, {
              ariaLabel: props.ariaLabel,
              title: props.title,
              type_: "hidden",
              value: value,
              label: props.label,
              placeholder: props.placeholder,
              readOnly: true,
              style: {
                TAG: "User",
                _0: "ReadOnly"
              },
              autoSize: props.autoSize,
              focusOutline: Particle_Border_Ds.Textfield.userActive,
              autoComplete: "off",
              name: props.name,
              id: props.id,
              inputRef: props.inputRef,
              action: props.action,
              children: Caml_option.some(JsxRuntime.jsx(Atom_Box.make, {
                        children: Caml_option.some(value),
                        kind: "Span",
                        rules: Caml_option.some(fakeInputRules)
                      }))
            });
}

var Readonly = {
  make: Atom_Textfield$User$Readonly
};

function Atom_Textfield$User(props) {
  var __readOnly = props.readOnly;
  var __required = props.required;
  var __autoComplete = props.autoComplete;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "off";
  var required = __required !== undefined ? __required : false;
  var readOnly = __readOnly !== undefined ? __readOnly : false;
  return JsxRuntime.jsx(Atom_Textfield$Internal, {
              ariaLabel: props.ariaLabel,
              title: props.title,
              type_: props.type_,
              value: props.value,
              label: props.label,
              placeholder: props.placeholder,
              onBlur: props.onBlur,
              onChange: props.onChange,
              required: required,
              readOnly: readOnly,
              style: {
                TAG: "User",
                _0: readOnly ? "ReadOnly" : "Writable"
              },
              autoFocus: props.autoFocus,
              focusOutline: Particle_Border_Ds.Textfield.userActive,
              autoComplete: autoComplete,
              pattern: props.pattern,
              name: props.name,
              id: props.id,
              inputRef: props.inputRef,
              action: props.action
            });
}

var User = {
  TvCell: TvCell,
  WithError: WithError$1,
  Readonly: Readonly,
  make: Atom_Textfield$User
};

function Atom_Textfield$Presse(props) {
  var __style = props.style;
  var __readOnly = props.readOnly;
  var __required = props.required;
  var __autoComplete = props.autoComplete;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "off";
  var required = __required !== undefined ? __required : false;
  var readOnly = __readOnly !== undefined ? __readOnly : false;
  var style = __style !== undefined ? __style : "Default";
  var style$1 = {
    TAG: "Presse",
    _0: style
  };
  return JsxRuntime.jsx(Atom_Textfield$Internal, {
              ariaLabel: props.ariaLabel,
              title: props.title,
              type_: props.type_,
              value: props.value,
              label: props.label,
              placeholder: props.placeholder,
              onBlur: props.onBlur,
              onChange: props.onChange,
              required: required,
              readOnly: readOnly,
              style: style$1,
              autoComplete: autoComplete,
              pattern: props.pattern,
              name: props.name,
              id: props.id,
              inputRef: props.inputRef,
              min: props.min,
              max: props.max
            });
}

var Presse = {
  make: Atom_Textfield$Presse
};

var make = Atom_Textfield;

export {
  WithError ,
  make ,
  User ,
  Presse ,
}
/* textAreaCss Not a pure module */
