// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Atom_Grid from "../../../atom/Atom_Grid.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as User_Type from "../../../../user/User_Type.res.mjs";
import * as Atom_Image from "../../../atom/Atom_Image.res.mjs";
import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../../type/Type_Teaser.res.mjs";
import * as Atom_Divider from "../../../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Spacer from "../../../particle/Particle_Spacer.res.mjs";
import * as User_Observable from "../../../../user/User_Observable.res.mjs";
import * as Layout_Constants from "../../Layout_Constants.res.mjs";
import * as UseClickTracking from "../../../../hook/UseClickTracking.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_ExpanderText from "../../../molecule/Molecule_ExpanderText.res.mjs";
import * as Particle_Animation_Ds from "../../../particle/Particle_Animation_Ds.res.mjs";
import * as Organism_WarningMessage from "../../../organism/Organism_WarningMessage.res.mjs";
import * as Template_ProgramContent from "./Template_ProgramContent.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";
import * as Template_ProgramContent_Heading from "./Template_ProgramContent_Heading.res.mjs";
import * as Template_ProgramContent_FirstBroadcast from "./Template_ProgramContent_FirstBroadcast.res.mjs";

var emptyColumns = {
  left: undefined,
  right: undefined
};

function Template_ProgramContent_Description$Credits$Credit(props) {
  var values = props.values;
  var rulesValues = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.alignSelf("flexEnd")]);
  var len = values.length;
  var tmp;
  if (len !== 1) {
    tmp = len !== 0 ? JsxRuntime.jsx(Atom_Column.make, {
            children: values.map(function (value, i) {
                  var value$1 = Type_String.Credit.Value.toString(value);
                  return JsxRuntime.jsx(Atom_Cell.make, {
                              kind: "ItemList",
                              children: JsxRuntime.jsx(Template_ProgramContent.Value.make, {
                                    value: value$1
                                  }, i.toString())
                            }, value$1);
                }),
            kind: "List",
            rules: Caml_option.some(rulesValues)
          }) : null;
  } else {
    var value = values[0];
    tmp = JsxRuntime.jsx(Atom_Column.make, {
          children: JsxRuntime.jsx(Template_ProgramContent.Value.make, {
                value: Type_String.Credit.Value.toString(value)
              }),
          rules: Caml_option.some(rulesValues)
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Template_ProgramContent.Label.make, {
                      label: Type_String.Credit.Value.toString(props.label)
                    }),
                tmp
              ]
            });
}

var Credit = {
  make: Template_ProgramContent_Description$Credits$Credit
};

function splitCredits(credits) {
  return Core__Array.reduce(credits, emptyColumns, (function (accumulator, param) {
                var values = param.values;
                var label = param.label;
                var code = param.code;
                if (code === "SCE" || code === "REA" || code === "PRO" || code === "PRD" || code === "MUS" || code === "MON" || code === "IMA") {
                  var left = Util_Array.append(Core__Option.getOr(accumulator.left, []), JsxRuntime.jsx(Template_ProgramContent_Description$Credits$Credit, {
                            values: values,
                            label: label
                          }, code));
                  return {
                          left: left,
                          right: accumulator.right
                        };
                }
                var right = Util_Array.append(Core__Option.getOr(accumulator.right, []), JsxRuntime.jsx(Template_ProgramContent_Description$Credits$Credit, {
                          values: values,
                          label: label
                        }, code));
                return {
                        left: accumulator.left,
                        right: right
                      };
              }));
}

var containerAlignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "start");

var widthForTwoColumns = Css_Js_Core.Calc.$neg(CssJs.pct(50), CssJs.pxFloat(Particle_Spacer.spacerToInt("S") / 2));

var columnWidthTwoColumns = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, widthForTwoColumns, CssJs.pct(100));

var columnWidthOneColumns = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100));

var columnGapColumn = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var columnRules = Particle_Screen_Ds.make(undefined, [CssJs.gridTemplateColumns([
            CssJs.fr(1),
            CssJs.fr(2)
          ])], undefined, undefined, undefined, [CssJs.gridTemplateColumns([
            CssJs.fr(1),
            CssJs.fr(1)
          ])]);

var Css = {
  containerAlignItems: containerAlignItems,
  marginRightValue: "S",
  widthForTwoColumns: widthForTwoColumns,
  columnWidthTwoColumns: columnWidthTwoColumns,
  columnWidthOneColumns: columnWidthOneColumns,
  columnGapColumn: columnGapColumn,
  columnRules: columnRules
};

var gridBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"));

function Template_ProgramContent_Description$Credits(props) {
  var credits = props.credits;
  if (props.variant === "OneColumn") {
    return JsxRuntime.jsx(Atom_Row.make, {
                alignItems: Caml_option.some(containerAlignItems),
                children: JsxRuntime.jsx(Atom_Grid.make, {
                      blockSpace: Caml_option.some(gridBlockSpace),
                      children: credits.map(function (param) {
                            return JsxRuntime.jsx(Template_ProgramContent_Description$Credits$Credit, {
                                        values: param.values,
                                        label: param.label
                                      }, param.code);
                          }),
                      gapColumn: Caml_option.some(columnGapColumn),
                      rules: Caml_option.some(columnRules),
                      width: Caml_option.some(columnWidthOneColumns)
                    })
              });
  }
  var columns = splitCredits(credits);
  var left = columns.left;
  var leftColumn = left !== undefined ? JsxRuntime.jsx(Atom_Grid.make, {
          blockSpace: Caml_option.some(gridBlockSpace),
          children: left,
          gapColumn: Caml_option.some(columnGapColumn),
          rules: Caml_option.some(columnRules),
          width: Caml_option.some(columnWidthTwoColumns)
        }) : null;
  var right = columns.right;
  var rightColumn = right !== undefined ? JsxRuntime.jsx(Atom_Grid.make, {
          blockSpace: Caml_option.some(gridBlockSpace),
          children: right,
          gapColumn: Caml_option.some(columnGapColumn),
          rules: Caml_option.some(columnRules),
          width: Caml_option.some(columnWidthTwoColumns)
        }) : null;
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(containerAlignItems),
              children: [
                leftColumn,
                rightColumn
              ]
            });
}

var Credits = {
  emptyColumns: emptyColumns,
  Credit: Credit,
  splitCredits: splitCredits,
  Css: Css,
  gridBlockSpace: gridBlockSpace,
  make: Template_ProgramContent_Description$Credits
};

function Template_ProgramContent_Description$AgeRestriction(props) {
  var ageRestriction = props.ageRestriction;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var verificationStatus = Core__Option.map(userInfos, User_Type.Info.getAgeVerificationStatus);
  if (ageRestriction === undefined) {
    return null;
  }
  switch (ageRestriction) {
    case "12" :
        return null;
    case "16" :
        if (verificationStatus !== undefined) {
          switch (verificationStatus) {
            case "Verified16" :
            case "Verified18" :
                return null;
            case "Under16" :
            case "NotVerified" :
                break;
            
          }
        }
        break;
    case "18" :
        if (verificationStatus === "Verified18") {
          return null;
        }
        break;
    
  }
  return JsxRuntime.jsx(Organism_WarningMessage.Program.make, {
              programTitle: props.programTitle,
              ageRestriction: ageRestriction
            });
}

var AgeRestriction = {
  make: Template_ProgramContent_Description$AgeRestriction
};

var creditsBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("L"), undefined, Particle_Spacer_Ds.makeVertical("M"));

var creditsInnerspace = Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "M");

var minHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "zero");

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var expanderBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"));

function Template_ProgramContent_Description$Description(props) {
  var credits = props.credits;
  var program = props.program;
  var clickTracking = UseClickTracking.use();
  var ffImproveComWithUser = Context.Config.getFeatureFlippingStatus("ImproveComWithUser");
  var shortDescription = program.shortDescription;
  var description = program.description;
  var shortDescriptionContent = shortDescription !== undefined ? (
      ffImproveComWithUser === "On" ? JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
              style: Atom_Text_Ds.ProgramContent.descBold,
              dangerouslySetInnerHTML: shortDescription
            }) : JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"))),
              children: JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
                    style: Atom_Text_Ds.ProgramContent.descBold,
                    dangerouslySetInnerHTML: shortDescription
                  })
            })
    ) : null;
  var descriptionContent = JsxRuntime.jsx(Atom_Column.make, {
        children: description !== undefined ? JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
                style: Atom_Text_Ds.ProgramContent.make("Desc"),
                dangerouslySetInnerHTML: description
              }) : null,
        innerSpace: Caml_option.some(innerSpace)
      });
  var tmp;
  tmp = ffImproveComWithUser === "On" ? null : JsxRuntime.jsx(Template_ProgramContent_FirstBroadcast.make, {
          programKind: program.kind,
          value: props.firstBroadcastDate
        });
  var tmp$1;
  tmp$1 = ffImproveComWithUser === "On" ? JsxRuntime.jsxs(Atom_Column.make, {
          children: [
            descriptionContent,
            JsxRuntime.jsx(Atom_Cell.make, {
                  blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("M"))),
                  children: JsxRuntime.jsx(Template_ProgramContent_Description$AgeRestriction, {
                        ageRestriction: props.ageRestriction,
                        programTitle: Core__Option.getOr(Core__Option.map(program.id, (function (id) {
                                    return Type_String.Program.Id.toString(id);
                                  })), "")
                      })
                })
          ]
        }) : descriptionContent;
  var tmp$2;
  tmp$2 = credits !== undefined ? (
      ffImproveComWithUser === "On" ? JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(creditsBlockspace),
              children: [
                JsxRuntime.jsx(Atom_Divider.make, {}),
                JsxRuntime.jsx(Template_ProgramContent_Description$Credits, {
                      credits: credits,
                      variant: "OneColumn"
                    })
              ],
              innerSpace: Caml_option.some(creditsInnerspace)
            }) : JsxRuntime.jsx(Template_ProgramContent_Description$Credits, {
              credits: credits,
              variant: "TwoColumns"
            })
    ) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsx(Atom_Column.make, {
                      children: shortDescriptionContent,
                      maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen)
                    }),
                JsxRuntime.jsxs(Molecule_ExpanderText.make, {
                      children: [
                        tmp$1,
                        tmp$2
                      ],
                      blockSpace: Caml_option.some(expanderBlockSpace),
                      textStyle: Atom_Text_Ds.ProgramContent.descBold,
                      behavior: props.descriptionBehavior,
                      onClick: (function (status) {
                          if (status === "On") {
                            return clickTracking({
                                        eventGroup: "program_actions",
                                        eventName: "READ_LESS"
                                      });
                          } else {
                            return clickTracking({
                                        eventGroup: "program_actions",
                                        eventName: "READ_MORE"
                                      });
                          }
                        }),
                      maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
                      minHeight: Caml_option.some(minHeight)
                    })
              ]
            });
}

var Description = {
  minHeight: minHeight,
  innerSpace: innerSpace,
  expanderBlockSpace: expanderBlockSpace,
  make: Template_ProgramContent_Description$Description
};

function Template_ProgramContent_Description(props) {
  var teaser = props.teaser;
  var ffImproveComWithUser = Context.Config.getFeatureFlippingStatus("ImproveComWithUser");
  var program = teaser.program;
  var title = Type_Teaser.Helper.extractTitle(teaser);
  var subtitle = Type_Teaser.Helper.extractSubtitleString(teaser.subtitle);
  var tmp;
  tmp = ffImproveComWithUser === "On" ? null : JsxRuntime.jsx(Template_ProgramContent_Heading.make, {
          title: title,
          subtitle: subtitle
        });
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                tmp,
                JsxRuntime.jsx(Template_ProgramContent_Description$Description, {
                      program: program,
                      credits: teaser.credits,
                      firstBroadcastDate: program.firstBroadcastDate,
                      descriptionBehavior: props.descriptionBehavior,
                      ageRestriction: teaser.ageRestriction
                    })
              ],
              height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
              ref_: props.ref_
            });
}

function Template_ProgramContent_Description$Presse$RcBanner(props) {
  var program = props.program;
  if (program !== undefined) {
    return JsxRuntime.jsx(Atom_Cell.make, {
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                children: JsxRuntime.jsx(Atom_Image.make, {
                      alt: "",
                      animation: Caml_option.some(Particle_Animation_Ds.TopTeaserPresse.background),
                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                      src: program.imageSrc,
                      style: "PresseRcBanner"
                    }),
                rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, [CssJs.height("inherit_")], undefined, undefined, [CssJs.height({
                                NAME: "px",
                                VAL: 180
                              })]))
              });
  } else {
    return null;
  }
}

var RcBanner = {
  make: Template_ProgramContent_Description$Presse$RcBanner
};

function Template_ProgramContent_Description$Presse$ShortDescription(props) {
  var shortDescription = props.program.shortDescription;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(shortDescription !== undefined ? JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
                          style: Atom_Text_Ds.ProgramContent.value,
                          dangerouslySetInnerHTML: shortDescription
                        }) : null)
            });
}

var ShortDescription = {
  make: Template_ProgramContent_Description$Presse$ShortDescription
};

function Template_ProgramContent_Description$Presse$Description(props) {
  var credits = props.credits;
  var program = props.program;
  var ffImproveComWithUser = Context.Config.getFeatureFlippingStatus("ImproveComWithUser");
  var description = program.description;
  var tmp;
  tmp = ffImproveComWithUser === "On" ? null : JsxRuntime.jsx(Template_ProgramContent_FirstBroadcast.make, {
          programKind: program.kind,
          value: props.firstBroadcastDate
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsxs(Molecule_ExpanderText.make, {
                      children: [
                        description !== undefined ? JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
                                style: Atom_Text_Ds.ProgramContent.make("Desc"),
                                dangerouslySetInnerHTML: description
                              }) : null,
                        credits !== undefined ? JsxRuntime.jsx(Template_ProgramContent_Description$Credits, {
                                credits: credits,
                                variant: "TwoColumns"
                              }) : null
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")),
                      blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"), undefined, Particle_Spacer_Ds.makeTop("M"))),
                      textStyle: Atom_Text_Ds.ProgramContent.descBold,
                      behavior: props.descriptionBehavior,
                      variant: "PresseRc"
                    })
              ]
            });
}

var Description$1 = {
  make: Template_ProgramContent_Description$Presse$Description
};

function Template_ProgramContent_Description$Presse(props) {
  var teaser = props.teaser;
  var headingRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.gridArea({
              NAME: "ident",
              VAL: "title"
            })]);
  var descriptionRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.gridArea({
              NAME: "ident",
              VAL: "description"
            })]);
  var program = teaser.program;
  var title = Type_Teaser.Helper.extractTitle(teaser);
  var subtitle = Type_Teaser.Helper.extractSubtitleString(teaser.subtitle);
  var titleBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("M"));
  var shortDescriptionBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeRight("XL"), undefined, undefined, Particle_Spacer_Ds.makeRight("Zero"));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Row.make, {
                              blockSpace: Caml_option.some(titleBlockSpace),
                              children: JsxRuntime.jsx(Template_ProgramContent_Heading.make, {
                                    title: title,
                                    subtitle: subtitle
                                  })
                            }),
                        JsxRuntime.jsx(Atom_Row.make, {
                              blockSpace: Caml_option.some(shortDescriptionBlockSpace),
                              children: JsxRuntime.jsx(Template_ProgramContent_Description$Presse$ShortDescription, {
                                    program: program
                                  })
                            })
                      ],
                      ref_: props.ref_,
                      rules: Caml_option.some(headingRules)
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Template_ProgramContent_Description$Presse$Description, {
                            program: program,
                            credits: teaser.credits,
                            firstBroadcastDate: program.firstBroadcastDate,
                            descriptionBehavior: props.descriptionBehavior
                          }),
                      rules: Caml_option.some(descriptionRules)
                    })
              ]
            });
}

var Presse = {
  RcBanner: RcBanner,
  ShortDescription: ShortDescription,
  Description: Description$1,
  make: Template_ProgramContent_Description$Presse
};

Template_ProgramContent_Description.displayName = "Template_ProgramContent_Description";

var make = Template_ProgramContent_Description;

export {
  Credits ,
  AgeRestriction ,
  creditsBlockspace ,
  creditsInnerspace ,
  Description ,
  make ,
  Presse ,
}
/* containerAlignItems Not a pure module */
