// Generated by ReScript, PLEASE EDIT WITH CARE


var defaultZonesNumber = 3;

var chunkSize = 3;

export {
  defaultZonesNumber ,
  chunkSize ,
}
/* No side effect */
