// Generated by ReScript, PLEASE EDIT WITH CARE


function toString(x) {
  return x;
}

var Storybook_FakePlayer = {
  content: "fakePlayerCnt"
};

var Storybook_FakePlayer_Kind = {
  clip: "clip",
  program: "program",
  trailer: "tl",
  preview: "preview",
  shorts: "short"
};

var Storybook_Modal = {
  button: "btn",
  modal: "modal",
  modalButtonOne: "modalButtonOne",
  modalButtonTwo: "modalButtonTwo"
};

function component(component$1) {
  return "component-" + component$1;
}

var Storybook_OneToRuleThemAll = {
  component: component,
  content: "cnt"
};

function mount(component) {
  return "btn-" + component;
}

function unmount(component) {
  return "btn-unmount-" + component;
}

var Storybook_OneToRuleThemAll_Button = {
  mount: mount,
  unmount: unmount
};

var Storybook_Pagination = {
  element: "element",
  status: "status",
  button: "btn",
  buttonReset: "btn-reset"
};

var Storybook_Program = {
  trailerButton: "programTrailerButton"
};

var Storybook_Slider = {
  status: "status"
};

var Storybook_Teaser = {
  identifierTitle: "story-title",
  item: "itm"
};

var Storybook_TrapFocus = {
  firstElement: "first",
  lastElement: "last"
};

var Storybook_UseVisible = {
  status: "status",
  firstQuartil: "firstQuartil",
  secondQuartil: "secondQuartil",
  thirdQuartil: "thirdQuartil",
  lastQuartil: "lastQuartil",
  toggleButton: "toggleButton",
  notVisible: "notvisible"
};

var Accordion = {
  item: "acc-itm"
};

var Breadcrumb = {
  item: "bcr-itm",
  title: "bcr-title",
  description: "bcr-description"
};

var Broadcast = {
  item: "bc-itm"
};

var ColoredBox = {
  item: "clb-itm"
};

var Cta = {
  presseDownloadLink: "cta-p-download-link",
  presseFirstBroadcast: "cta-p-first-bro",
  trailerButton: "cta-tl-btn",
  fullProgramButton: "cta-fp-btn"
};

var Download = {
  item: "download-itm"
};

var Dropdown = {
  element: "dd-element",
  container: "dd-ctn"
};

var EventZone = {
  button: "ez-btn"
};

var GuideTV = {
  calendarItem: "gtv-calendar-itm"
};

function language(acronym) {
  return "ls-" + acronym;
}

var LanguageSwitcher = {
  button: "ls-btn",
  language: language
};

var Layout_Guide = {
  column: "lg-column"
};

var Message = {
  item: "msg-itm",
  closeButton: "msg-close-btn"
};

var Modal = {
  container: "md-ctn",
  content: "md-cnt",
  purgeAction: "md-purge-action"
};

var NextEpisode = {
  item: "ne-itm"
};

var Overlay = {
  closeableContainer: "ol-x-ctn",
  closeableContent: "ol-x-cnt"
};

var Player = {
  imageElement: "pl-image-element",
  trailerButton: "pl-tl-btn",
  videoContainer: "pl-vid-ctn"
};

var PaginatedGrid = {
  seeMoreButton: "pg-btn-more",
  actionButton: "pg-btn-action"
};

var Program = {
  descriptionHeading: "pc_desc-Heading"
};

var Slider = {
  item: "sl-itm",
  arrowNext: "sl-arrow-next",
  arrowPrev: "sl-arrow-prev",
  loader: "sl-loader"
};

var Slider_Teaser_MetaInformation = {
  title: "sl-ts-mI-title",
  seeMoreLabel: "sl-ts-mI-seeMoreLabel"
};

var SocialLinks = {
  facebook: "sll-facebook",
  twitter: "sll-twitter",
  instagram: "sll-instagram",
  youtube: "sll-youtube",
  tiktok: "sll-tiktok"
};

var Teaser = {
  item: "ts-tsItem",
  panelHoverWrapper: "ts-tsHvr",
  panelFocus: "ts-tsPanelFocus",
  img: "ts-tsImg",
  hoverPlayButton: "ts-tsHvrPlayButton",
  moreOptionsButton: "ts-tsMoreOptionsButton",
  itemLink: "ts-tsItemLink",
  mobileHoverButton: "ts-tsMobHvrButton",
  title: "ts-tsTitle",
  subtitle: "ts-tsSubtitle",
  hoverTitle: "ts-tsHvrTitle",
  hoverSubtitle: "ts-tsHvrSubtitle",
  hoverCloseButton: "ts-tsHvrCloseButton",
  hoverOverlay: "ts-tsHvrOverlay",
  link: "ts-tsItemLink"
};

var TeaserGuide = {
  item: "tsguide-itm",
  itemDirect: "tsguide-itm-direct",
  itemPrimetime: "tsguide-itm-primetime"
};

var TopTeaser = {
  imageElement: "topts-image-element"
};

var Tooltip = {
  closeableContainer: "tlt-closeable-ctn",
  closeableContent: "tlt-closeable-cnt"
};

var Zone_Layout = {
  triggerNextZoneContainer: "zl-triggerNextZoneCtn",
  zoneHidden: "zl-zoneHidden",
  banner: "zl-banner",
  newsletter: "zl-newsletter",
  contentCollection: "zl-cntCollection",
  eventZoneWithClip: "zl-eZ-withClip",
  eventZoneWithTrailer: "zl-eZ-withTrailer",
  eventZone: "zl-eZ",
  topTeaserWithTrailer: "zl-topTeaser-withTrailer",
  topTeaser: "zl-topTeaser",
  topTeaserWithClip: "zl-topTeaser-withClip",
  slider: "zl-slider",
  topTeaserWithCarousel: "zl-topTeaser-withCarousel",
  paginatedGridWithRelatedZones: "zl-pGr-withRelatedZones",
  paginatedGrid: "zl-pGr",
  ebuBox: "zl-ebubox",
  playNext: "zl-playNext"
};

var Sticker = {
  direct: "sticker-direct",
  livestream: "sticker-livestream"
};

var Footer = {
  newsletter: "newsletter"
};

var zoneSelector = new RegExp("zl-.*");

var topTeaserSelector = new RegExp("zl-topTeaser.*");

function __unsafe_stringToIdentifier(s) {
  return s;
}

export {
  toString ,
  Storybook_FakePlayer ,
  Storybook_FakePlayer_Kind ,
  Storybook_Modal ,
  Storybook_OneToRuleThemAll ,
  Storybook_OneToRuleThemAll_Button ,
  Storybook_Pagination ,
  Storybook_Program ,
  Storybook_Slider ,
  Storybook_Teaser ,
  Storybook_TrapFocus ,
  Storybook_UseVisible ,
  Accordion ,
  Broadcast ,
  Breadcrumb ,
  ColoredBox ,
  Cta ,
  Download ,
  Dropdown ,
  EventZone ,
  GuideTV ,
  LanguageSwitcher ,
  Layout_Guide ,
  Modal ,
  Message ,
  NextEpisode ,
  Overlay ,
  Player ,
  PaginatedGrid ,
  Program ,
  Slider ,
  Slider_Teaser_MetaInformation ,
  SocialLinks ,
  Teaser ,
  TeaserGuide ,
  TopTeaser ,
  Tooltip ,
  Zone_Layout ,
  Sticker ,
  Footer ,
  zoneSelector ,
  topTeaserSelector ,
  __unsafe_stringToIdentifier ,
}
/* zoneSelector Not a pure module */
