// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Color from "../../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("circle", {
                      cx: "8",
                      cy: "8",
                      fill: Particle_Color.toString(Particle_Color.Css.color("Error")),
                      r: "8"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M7.993\n  10.054c.549 0 .993.435.993.973a.984.984 0 01-.993.973.984.984 0\n  01-.993-.973c0-.538.444-.973.993-.973M7.999 4a.79.79 0 01.807.767v3.891a.784.784\n  0 01-.783.787.785.785 0 01-.804-.767v-3.89A.783.783 0 017.999 4",
                      fill: Particle_Color.toString(Particle_Color.Css.color("White"))
                    })
              ]
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
