// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function view(param) {
  return {
          shape: param.shape,
          checkColor: param.checkColor
        };
}

var makeNewsletterZone_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, {
      TAG: "Square",
      _0: 20
    }, undefined, {
      TAG: "Square",
      _0: 15
    });

var makeNewsletterZone_textStyle = Atom_Text_Ds.makeTos("Desc");

var makeNewsletterZone_alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var makeNewsletterZone = {
  shape: makeNewsletterZone_shape,
  checkColor: "White",
  textStyle: makeNewsletterZone_textStyle,
  alignItems: makeNewsletterZone_alignItems
};

var makeFooter_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Square",
      _0: 15
    });

var makeFooter_textStyle = Atom_Text_Ds.makeTos("Desc");

var makeFooter_alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var makeFooter = {
  shape: makeFooter_shape,
  checkColor: "White",
  textStyle: makeFooter_textStyle,
  alignItems: makeFooter_alignItems
};

var user_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Square",
      _0: 16
    });

var user_textStyle = Atom_Text_Ds.User.checkbox;

var user_alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var user = {
  shape: user_shape,
  checkColor: "White",
  textStyle: user_textStyle,
  alignItems: user_alignItems
};

var userLogin_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Square",
      _0: 13
    });

var userLogin_textStyle = Atom_Text_Ds.User.checkbox;

var userLogin = {
  shape: userLogin_shape,
  checkColor: "White",
  textStyle: userLogin_textStyle,
  alignItems: Particle_Screen_Constants.alignItemsCenter
};

var presse_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Square",
      _0: 16
    });

var presse_textStyle = Atom_Text_Ds.Presse.checkbox;

var presse_alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var presse = {
  shape: presse_shape,
  checkColor: "Black",
  textStyle: presse_textStyle,
  alignItems: presse_alignItems
};

var hightlightPresse_shape = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Square",
      _0: 16
    });

var hightlightPresse_textStyle = Atom_Text_Ds.Presse.hightlightFilter;

var hightlightPresse_alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var hightlightPresse = {
  shape: hightlightPresse_shape,
  checkColor: "Black",
  textStyle: hightlightPresse_textStyle,
  alignItems: hightlightPresse_alignItems
};

export {
  view ,
  makeNewsletterZone ,
  makeFooter ,
  user ,
  userLogin ,
  presse ,
  hightlightPresse ,
}
/* makeNewsletterZone Not a pure module */
