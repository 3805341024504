// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Template_ProgramContent$Label(props) {
  return JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(props.label),
              style: Atom_Text_Ds.ProgramContent.label
            });
}

var Label = {
  make: Template_ProgramContent$Label
};

function Template_ProgramContent$Value(props) {
  return JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(props.value),
              style: Atom_Text_Ds.ProgramContent.value
            });
}

function Template_ProgramContent$Value$Link(props) {
  return JsxRuntime.jsx(Atom_Cta.Link.make, {
              href: props.href,
              label: props.value,
              style: Atom_Cta_Ds.programMetaGenre
            });
}

var Link = {
  make: Template_ProgramContent$Value$Link
};

var Value = {
  make: Template_ProgramContent$Value,
  Link: Link
};

export {
  Label ,
  Value ,
}
/* Atom_Cta Not a pure module */
