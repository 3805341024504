// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function Template_Label(props) {
  var zone = props.zone;
  var match = zone.title;
  var tmp;
  tmp = match !== undefined && match.TAG === "Displayed" ? JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(match._0),
          kind: "h3",
          style: Atom_Text_Ds.ProgramExternal.make("Title")
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(props.blockSpace),
              children: [
                tmp,
                JsxRuntime.jsx(Atom_Column.make, {
                      blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XXS"))),
                      children: zone.data.map(function (param) {
                            var title = param.title;
                            if (title === undefined) {
                              return null;
                            }
                            switch (title.TAG) {
                              case "Displayed" :
                                  return JsxRuntime.jsx(Atom_Cell.make, {
                                              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XXS"))),
                                              kind: "ItemList",
                                              children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                    href: Type_String.Href.toString(param.href),
                                                    label: Type_String.Teaser.Title.toString(title._0),
                                                    style: Atom_Cta_Ds.programExternal,
                                                    target: "_blank"
                                                  })
                                            }, Type_String.Teaser.Id.toString(param.id));
                              case "AccessibilityOnly" :
                              case "HoverOnly" :
                                  return null;
                              
                            }
                          }),
                      kind: "List"
                    })
              ],
              ref_: props.ref_,
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

Template_Label.displayName = "Template_Label";

var make = Template_Label;

export {
  make ,
}
/*  Not a pure module */
