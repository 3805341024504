// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../CssJs.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Util_Opt from "../util/Util_Opt.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Util_Float from "../util/Util_Float.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/src/Core__Float.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Belt_SortArray from "@rescript/std/lib/es6/belt_SortArray.js";

var r4_1 = {
  width: 4,
  height: 1
};

var r16_9 = {
  width: 16,
  height: 9
};

var r9_16 = {
  width: 9,
  height: 16
};

var r4_3 = {
  width: 4,
  height: 3
};

var r3_4 = {
  width: 3,
  height: 4
};

var r2_3 = {
  width: 2,
  height: 3
};

var r1_1 = {
  width: 1,
  height: 1
};

function make(ratio) {
  if (ratio === "R9_16") {
    return {
            NAME: "R9_16",
            VAL: r9_16
          };
  } else if (ratio === "Unknow") {
    return "Unknow";
  } else if (ratio === "R1_1") {
    return {
            NAME: "R1_1",
            VAL: r1_1
          };
  } else if (ratio === "R2_3") {
    return {
            NAME: "R2_3",
            VAL: r2_3
          };
  } else if (ratio === "R3_4") {
    return {
            NAME: "R3_4",
            VAL: r4_3
          };
  } else if (ratio === "R4_1") {
    return {
            NAME: "R4_1",
            VAL: r4_1
          };
  } else if (ratio === "R4_3") {
    return {
            NAME: "R4_3",
            VAL: r3_4
          };
  } else {
    return {
            NAME: "R16_9",
            VAL: r16_9
          };
  }
}

function toCss(ratio) {
  var toRule = function (value) {
    return Caml_option.some(CssJs.unsafe("aspectRatio", value));
  };
  if (ratio === "R9_16") {
    return toRule("9/16");
  } else if (ratio === "Unknow") {
    return ;
  } else if (ratio === "R1_1") {
    return toRule("1/1");
  } else if (ratio === "R2_3") {
    return toRule("2/3");
  } else if (ratio === "R3_4") {
    return toRule("3/4");
  } else if (ratio === "R4_1") {
    return toRule("4/1");
  } else if (ratio === "R4_3") {
    return toRule("4/3");
  } else {
    return toRule("16/9");
  }
}

function getDimensions(t) {
  if (typeof t === "object") {
    return Caml_option.some(t.VAL);
  }
  
}

function getCoeffFromRatio(ratio) {
  return Core__Option.map(getDimensions(make(ratio)), (function (param) {
                return param.width / param.height;
              }));
}

function areRatioEquals(r1, r2) {
  var match = getDimensions(r1);
  var match$1 = getDimensions(r2);
  if (match !== undefined && match$1 !== undefined && match.width === match$1.width) {
    return match.height === match$1.height;
  } else {
    return false;
  }
}

function divide(w, h) {
  return Util_Float.keepNdecimal(w / h, 1);
}

function divideDimensions(dimensions) {
  return divide(dimensions.width, dimensions.height);
}

function getFromRatio(ratioValue) {
  if (ratioValue !== undefined) {
    if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r1_1), 1)) {
      return "R1_1";
    } else if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r2_3), 1)) {
      return "R2_3";
    } else if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r4_3), 1)) {
      return "R4_3";
    } else if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r16_9), 1)) {
      return "R16_9";
    } else if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r9_16), 1)) {
      return "R9_16";
    } else if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r4_1), 1)) {
      return "R4_1";
    } else if (ratioValue === Util_Float.keepNdecimal(divideDimensions(r3_4), 1)) {
      return "R3_4";
    } else {
      return "Unknow";
    }
  } else {
    return "Unknow";
  }
}

function computeRatio(w, h) {
  if (h !== 0) {
    return divide(w, h);
  }
  
}

function makeFromDimensions(w, h) {
  return make(getFromRatio(computeRatio(w, h)));
}

var Ratio = {
  areRatioEquals: areRatioEquals,
  makeFromDimensions: makeFromDimensions,
  make: make,
  toCss: toCss,
  getDimensions: getDimensions,
  getCoeffFromRatio: getCoeffFromRatio
};

function t_encode(v) {
  return Spice.stringToJson(v);
}

function t_decode(v) {
  return Spice.stringFromJson(v);
}

function toString(t) {
  return t.replace("W", "").replace("H", "");
}

function toDimensions(t) {
  var splitted = toString(t).split("x");
  var match = splitted.map(Core__Float.fromString);
  var len = match.length;
  if (len >= 3) {
    return ;
  }
  switch (len) {
    case 0 :
    case 1 :
        return ;
    case 2 :
        var width = match[0];
        if (width === undefined) {
          return ;
        }
        var height = match[1];
        if (height !== undefined) {
          return {
                  width: width,
                  height: height
                };
        } else {
          return ;
        }
    
  }
}

function cmpT(t1, t2) {
  var match = toDimensions(t1);
  var match$1 = toDimensions(t2);
  if (match === undefined) {
    if (match$1 !== undefined) {
      return 1;
    } else {
      return 0;
    }
  }
  if (match$1 === undefined) {
    return -1;
  }
  var w2 = match$1.width;
  var w1 = match.width;
  if (w1 > w2) {
    return 1;
  } else if (w1 < w2) {
    return -1;
  } else if (w1 === w2) {
    return 0;
  } else {
    return 0;
  }
}

var arr = [
  "W180xH45",
  "W384xH96",
  "W480xH120",
  "W768xH192",
  "W1024xH256",
  "W1200xH300",
  "W1220xH305",
  "W1400xH350",
  "W1600xH400",
  "W1920xH480"
];

arr.sort(cmpT);

var arr$1 = [
  "W80xH45",
  "W128xH72",
  "W160xH90",
  "W170xH96",
  "W190xH107",
  "W192xH108",
  "W265xH149",
  "W325xH183",
  "W336xH189",
  "W340xH192",
  "W380xH214",
  "W384xH216",
  "W400xH225",
  "W430xH242",
  "W480xH270",
  "W540xH304",
  "W620xH350",
  "W640xH360",
  "W650xH366",
  "W660xH371",
  "W672xH378",
  "W720xH406",
  "W768xH432",
  "W840xH473",
  "W940xH530",
  "W1008xH567",
  "W1121xH632",
  "W1220xH686",
  "W1280xH720",
  "W1400xH788",
  "W1600xH900",
  "W1920xH1080",
  "W3840xH2160"
];

arr$1.sort(cmpT);

var arr$2 = [
  "W45xH45",
  "W125xH125",
  "W190xH190",
  "W200xH200",
  "W240xH240",
  "W400xH400",
  "W480xH480",
  "W720xH720",
  "W768xH768",
  "W940xH940",
  "W1400xH1400",
  "W3000xH3000"
];

arr$2.sort(cmpT);

var arr$3 = [
  "W60xH45",
  "W240xH180",
  "W1000xH750"
];

arr$3.sort(cmpT);

var arr$4 = [
  "W132xH176",
  "W150xH200",
  "W189xH252",
  "W2560xH3413"
];

arr$4.sort(cmpT);

var arr$5 = [
  "W30xH45",
  "W140xH210",
  "W235xH352",
  "W265xH397",
  "W300xH450",
  "W500xH750",
  "W1400xH2100",
  "W2000xH3000"
];

arr$5.sort(cmpT);

function mapT(__x) {
  return __x.map(function (x) {
              return x;
            });
}

function getClosestResolution(width, ratio) {
  var sizes = ratio === "R16_9" ? arr$1.map(function (x) {
          return x;
        }) : (
      ratio === "R1_1" ? arr$2.map(function (x) {
              return x;
            }) : (
          ratio === "R2_3" ? arr$5.map(function (x) {
                  return x;
                }) : (
              ratio === "R3_4" ? arr$4.map(function (x) {
                      return x;
                    }) : (
                  ratio === "R4_1" ? arr.map(function (x) {
                          return x;
                        }) : (
                      ratio === "R4_3" ? arr$3.map(function (x) {
                              return x;
                            }) : []
                    )
                )
            )
        )
    );
  return sizes.filter(function (size) {
                var match = toDimensions(size);
                if (match !== undefined) {
                  return match.width >= width;
                } else {
                  return false;
                }
              })[0];
}

var Size = {
  toString: toString,
  toDimensions: toDimensions,
  cmpT: cmpT,
  r4_1: arr,
  r16_9: arr$1,
  r1_1: arr$2,
  r4_3: arr$3,
  r3_4: arr$4,
  r2_3: arr$5,
  mapT: mapT,
  getClosestResolution: getClosestResolution
};

function make$1(str) {
  if (str.includes("__SIZE__")) {
    return str;
  }
  
}

function makeDefault(size) {
  return "https://api.arte.tv/api/mami/v1/default/" + toString(size);
}

function getUrl(t, width, ratio) {
  if (t === undefined) {
    return "";
  }
  var size = getClosestResolution(width, ratio);
  if (size === undefined) {
    return "";
  }
  var sizeString = toString(size);
  return t.replace(/__SIZE__/g, sizeString);
}

var Mami = {
  t_encode: t_encode,
  t_decode: t_decode,
  Size: Size,
  make: make$1,
  makeDefault: makeDefault,
  getUrl: getUrl
};

function resolution_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "w",
                    false,
                    Spice.intToJson(v.w)
                  ],
                  [
                    "h",
                    false,
                    Spice.intToJson(v.h)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ]
                ]));
}

function resolution_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var w = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "w"), null));
  if (w.TAG === "Ok") {
    var h = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "h"), null));
    if (h.TAG === "Ok") {
      var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
      if (url.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  w: w._0,
                  h: h._0,
                  url: url._0
                }
              };
      }
      var e = url._0;
      return {
              TAG: "Error",
              _0: {
                path: ".url" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = h._0;
    return {
            TAG: "Error",
            _0: {
              path: ".h" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = w._0;
  return {
          TAG: "Error",
          _0: {
            path: ".w" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode$1(v) {
  return Spice.arrayToJson(resolution_encode, v);
}

function t_decode$1(v) {
  return Spice.arrayFromJson(resolution_decode, v);
}

function make$2(url, w, h) {
  return {
          w: w,
          h: h,
          url: url
        };
}

function makeFromResolutions(resolutions) {
  return resolutions;
}

function concatMany(resolutions) {
  return resolutions.flat();
}

function getUrl$1(imageResolution, width, ratio) {
  var ratioT = make(ratio);
  var sortedResolutions = Belt_SortArray.stableSortBy(imageResolution, (function (r1, r2) {
          if (r1.w > r2.w) {
            return 1;
          } else {
            return 0;
          }
        }));
  var resolutions = Belt_Array.keep(sortedResolutions, (function (resolution) {
          var currentRatio = makeFromDimensions(resolution.w, resolution.h);
          return areRatioEquals(currentRatio, ratioT);
        }));
  var getHighestResolution = function () {
    var match = Util_Array.getLastElement(resolutions);
    if (match !== undefined) {
      return match.url;
    } else {
      console.error("No resolution found for ratio " + ratio + " and width " + width.toString());
      return "";
    }
  };
  return Util_Opt.mapWithDefaultLazy(resolutions.find(function (resolution) {
                  return resolution.w >= (width | 0);
                }), getHighestResolution, (function (param) {
                return param.url;
              }));
}

var Resolution = {
  resolution_encode: resolution_encode,
  resolution_decode: resolution_decode,
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  make: make$2,
  makeFromResolutions: makeFromResolutions,
  concatMany: concatMany,
  getUrl: getUrl$1
};

function t_encode$2(v) {
  if (typeof v !== "object") {
    return ["Empty"];
  }
  switch (v.TAG) {
    case "Mami" :
        return [
                "Mami",
                Spice.stringToJson(v._0)
              ];
    case "Url" :
        return [
                "Url",
                Spice.stringToJson(v._0)
              ];
    case "VOD" :
        return [
                "VOD",
                Spice.stringToJson(v._0)
              ];
    case "CDN" :
        return [
                "CDN",
                Spice.stringToJson(v._0)
              ];
    case "Resolution" :
        return [
                "Resolution",
                t_encode$1(v._0)
              ];
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "CDN" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Spice.stringFromJson(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "CDN",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Empty" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Empty"
                  };
          }
      case "Mami" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v$1 = Belt_Array.getExn(v, 1);
          var v0$1 = Spice.stringFromJson(v$1);
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Mami",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "Resolution" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$2 = t_decode$1(Belt_Array.getExn(v, 1));
          if (v0$2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Resolution",
                      _0: v0$2._0
                    }
                  };
          }
          var e$2 = v0$2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
      case "Url" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$3 = Spice.stringFromJson(Belt_Array.getExn(v, 1));
          if (v0$3.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Url",
                      _0: v0$3._0
                    }
                  };
          }
          var e$3 = v0$3._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
      case "VOD" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$4 = Spice.stringFromJson(Belt_Array.getExn(v, 1));
          if (v0$4.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "VOD",
                      _0: v0$4._0
                    }
                  };
          }
          var e$4 = v0$4._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function makeFromUrl(str) {
  return {
          TAG: "Url",
          _0: str
        };
}

function make$3(str) {
  return Core__Option.getOr((function (__x) {
                  return Core__Option.map(__x, (function (a) {
                                return {
                                        TAG: "Mami",
                                        _0: a
                                      };
                              }));
                })(make$1(str)), {
              TAG: "Url",
              _0: str
            });
}

function makeVod(str) {
  return {
          TAG: "VOD",
          _0: str
        };
}

function makeCdn(str) {
  return {
          TAG: "CDN",
          _0: str
        };
}

function makeResolution(resolution) {
  return {
          TAG: "Resolution",
          _0: resolution
        };
}

var extensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "webp",
  "bmp"
];

var urlWithExtensionRegex = new RegExp("\.(" + extensions.join("|") + ")$", "i");

function toString$1(extension, t, width, ratio) {
  var url;
  if (typeof t !== "object") {
    url = "";
  } else {
    switch (t.TAG) {
      case "Mami" :
          url = getUrl(t._0, width, ratio);
          break;
      case "Resolution" :
          url = getUrl$1(t._0, width, ratio);
          break;
      default:
        url = t._0;
    }
  }
  var match = url.match(urlWithExtensionRegex);
  if (!(match == null) || extension === undefined) {
    return url;
  } else {
    return url + "." + extension;
  }
}

var Src = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  makeFromUrl: makeFromUrl,
  make: make$3,
  makeVod: makeVod,
  makeCdn: makeCdn,
  makeResolution: makeResolution,
  empty: "Empty",
  extensions: extensions,
  urlWithExtensionRegex: urlWithExtensionRegex,
  toString: toString$1
};

export {
  Ratio ,
  Mami ,
  Resolution ,
  Src ,
}
/*  Not a pure module */
