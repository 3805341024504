import queryString from 'query-string';

const getPage = (parsedUrl: queryString.ParsedUrl): number | null => {
    const { page } = parsedUrl.query;

    if (typeof page === 'string') {
        const pageNumber = parseInt(page, 10);
        return isNaN(pageNumber) ? null : pageNumber;
    }

    return null;
};

export const buildNextPageUrl = (baseUrl: string, nextPage: string): string | undefined => {
    if (!baseUrl || !nextPage) {
        return undefined;
    }

    const baseUrlQueryObject = queryString.parseUrl(baseUrl);
    const nextPageQueryString = queryString.parseUrl(nextPage);

    const page = getPage(nextPageQueryString);

    if (page) {
        const newQuery = {
            ...baseUrlQueryObject.query,
            page,
        };
        const newQueryString = queryString.stringify(newQuery, { skipNull: true });

        return `${baseUrlQueryObject.url}?${newQueryString}`;
    }

    return undefined;
};

export const buildPageUrl = (baseUrl: string, page: number): string | undefined => {
    if (!baseUrl || !page) {
        return undefined;
    }

    const baseUrlQueryObject = queryString.parseUrl(baseUrl);

    if (page) {
        const newQuery = {
            ...baseUrlQueryObject.query,
            page,
        };
        const newQueryString = queryString.stringify(newQuery, { skipNull: true });

        return `${baseUrlQueryObject.url}?${newQueryString}`;
    }

    return undefined;
};

export const buildChapterUrl = (baseUrl: string, timecode: number): string | undefined => {
    if (!baseUrl || !timecode) {
        return undefined;
    }

    const baseUrlQueryObject = queryString.parseUrl(baseUrl);

    if (timecode) {
        const newQuery = {
            ...baseUrlQueryObject.query,
            t: timecode,
        };
        const newQueryString = queryString.stringify(newQuery, { skipNull: true });

        return `${baseUrlQueryObject.url}?${newQueryString}`;
    }

    return undefined;
};
