// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Util_Css from "../../../../util/Util_Css.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_ConcertBurgerMenu from "./Organism_ConcertBurgerMenu.res.mjs";
import * as Organism_Concert_SelectGenre from "./Organism_Concert_SelectGenre.res.mjs";

function Organism_ConcertNavigation(props) {
  var logoBerne = props.logoBerne;
  var searchState = props.searchState;
  var logoNavigation = props.logoNavigation;
  var labels = props.labels;
  var match = Context.I18n.use();
  var match$1 = match.labels.navbar;
  var concertSelectGenre = labels.concertSelectGenre;
  var displayMobileRules;
  displayMobileRules = searchState === "On" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "none") : Particle_Screen_Ds.make(undefined, undefined, undefined, "inlineFlex", undefined, "none");
  var burgerMenuDisplay;
  burgerMenuDisplay = searchState === "On" ? Particle_Screen_Ds.make(undefined, undefined, "flex", "none", undefined, "none") : Util_Css.Display.untilL;
  var logoDisplay;
  logoDisplay = searchState === "On" ? Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none") : Particle_Screen_Ds.make(undefined, undefined, undefined, "flex", undefined, "flex");
  var tmp;
  tmp = props.country === "Europe" ? JsxRuntime.jsx(Atom_Cta.Link.make, {
          href: logoNavigation.href,
          label: logoNavigation.label,
          screenReadersLabel: logoNavigation.title,
          style: Atom_Cta_Ds.Header.makeLogo("Vertical", logoBerne, Particle_Screen_Ds.make(undefined, undefined, undefined, "inlineFlex", undefined, "none"))
        }) : JsxRuntime.jsx(Atom_Cta.Link.make, {
          href: logoNavigation.href,
          screenReadersLabel: logoNavigation.title,
          style: Atom_Cta_Ds.Header.makeLogo("Vertical", logoBerne, Particle_Screen_Ds.make(undefined, undefined, undefined, "inlineFlex", undefined, "none"))
        });
  return JsxRuntime.jsxs(Atom_Row.make, {
              children: [
                tmp,
                JsxRuntime.jsx(Atom_Cta.Link.make, {
                      href: logoNavigation.href,
                      screenReadersLabel: logoNavigation.title,
                      style: Atom_Cta_Ds.Header.makeLogo("Vertical", logoBerne, Particle_Screen_Ds.make(undefined, undefined, undefined, "none", undefined, "inlineFlex"))
                    }),
                JsxRuntime.jsx(Organism_ConcertBurgerMenu.make, {
                      links: labels.navigation.links,
                      display: Caml_option.some(burgerMenuDisplay),
                      socialNetworks: labels.socialNetworks,
                      selectGenre: concertSelectGenre
                    }),
                JsxRuntime.jsx(Atom_Cta.Link.make, {
                      href: labels.url,
                      screenReadersLabel: labels.title,
                      style: Atom_Cta_Ds.Header.makeConcertLogo(logoDisplay)
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("Zero"))),
                      children: [
                        JsxRuntime.jsx(Organism_Concert_SelectGenre.make, {
                              selectGenre: concertSelectGenre
                            }),
                        Core__Option.mapOr(Caml_option.nullable_to_opt(labels.guide), null, (function (item) {
                                return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                            href: item.href,
                                            label: item.label,
                                            style: Atom_Cta_Ds.Header.baseNavigation
                                          }, item.label);
                              }))
                      ],
                      display: Caml_option.some(displayMobileRules),
                      kind: {
                        TAG: "Nav",
                        _0: match$1.mainNavigation
                      },
                      wrap: "OnOneLine"
                    })
              ],
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.unset)])),
              wrap: "OnOneLine"
            });
}

var make = Organism_ConcertNavigation;

export {
  make ,
}
/* CssJs Not a pure module */
