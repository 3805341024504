// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M16.1 3C11.0742 3 7 7.0742 7 12.1C7 13.8823 7.52 15.5398 8.4053 16.9425L8.391 16.9347L16.1 29L23.8103 16.9347L23.7934 16.9451C24.68 15.5398 25.2 13.8823 25.2 12.1C25.2 7.0742 21.1258 3 16.1 3ZM16.1 5.32698C19.8349 5.32698 22.873 8.36508 22.873 12.1C22.873 13.3714 22.5116 14.6168 21.8252 15.7023L21.1245 16.8164L16.1 24.6775L11.0755 16.8138L10.3735 15.7023C9.68842 14.6168 9.32702 13.3714 9.32702 12.1C9.32702 8.36508 12.3651 5.32698 16.1 5.32698Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
