// Generated by ReScript, PLEASE EDIT WITH CARE


function make(style) {
  return {
          justifyContent: style.justifyContent,
          alignItems: style.alignItems,
          autoSize: style.autoSize,
          wrap: style.wrap,
          alignSelf: style.alignSelf,
          position: style.position,
          overflow: style.overflow
        };
}

function view(t) {
  return {
          justifyContent: t.justifyContent,
          alignItems: t.alignItems,
          autoSize: t.autoSize,
          wrap: t.wrap,
          alignSelf: t.alignSelf,
          position: t.position,
          overflow: t.overflow
        };
}

export {
  make ,
  view ,
}
/* No side effect */
