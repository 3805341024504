// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M14.6729 14.9773H24.3962V12.9493H14.6729V14.9773ZM18.3138 18.2526H23.2003V16.2245H18.3138V18.2526ZM13.4257 18.2526H17.117V16.2245H13.4257V18.2526ZM9.36959 18.2526H12.2297V16.2245H9.36959V18.2526ZM7.75758 14.9773H13.4248V12.9493H7.75758V14.9773ZM26.9235 4.00588V4.01597C26.8454 4.00588 26.7731 4 26.6949 4H3.28632V21.8867H9.78562V28.36L17.4658 21.8867H29.2844V4.00504L26.9235 4.00588Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
