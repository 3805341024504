// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Zone_Layout from "../../zone/Zone_Layout.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var match = Context.I18n.use();
  var formaters = match.formaters;
  var match$1 = Context.Config.use();
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, "Home", param.domain, match$1.serverTime, param.page);
  return JsxRuntime.jsx(Zone_Layout.make, {
              page: page,
              pageKind: "Home",
              numberZone: param.numberZone
            });
}

export {
  make ,
}
/* Context Not a pure module */
