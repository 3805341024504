'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../provider/Context.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function use() {
  var match = Context.Config.use();
  var trackUserClick = match.trackUserClick;
  return function (controlGroup) {
    Core__Option.forEach((trackUserClick == null) ? undefined : Caml_option.some(trackUserClick), (function (tracker) {
            tracker(controlGroup);
          }));
  };
}

export {
  use ,
}
/* Context Not a pure module */
