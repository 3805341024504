// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M7.92308 4H24.0769C25.1385 4.00127 25.9987 4.86152 26 5.92308V25.9231C25.9987 26.9846 25.1385 27.8449 24.0769 27.8462H7.92308C6.86152 27.8449 6.00127 26.9846 6 25.9231V5.92308C6.00127 4.86152 6.86152 4.00127 7.92308 4ZM24.0769 27.0769C24.7142 27.0769 25.2308 26.5603 25.2308 25.9231V5.92308C25.2308 5.28583 24.7142 4.76923 24.0769 4.76923H7.92308C7.28583 4.76923 6.76923 5.28583 6.76923 5.92308V25.9231C6.76923 26.5603 7.28583 27.0769 7.92308 27.0769H24.0769Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M8.6923 5.53831H23.3077C23.9194 5.5166 24.4343 5.99186 24.4615 6.60331V22.9349C24.4343 23.5463 23.9194 24.0216 23.3077 23.9999H8.6923C8.08062 24.0216 7.56572 23.5463 7.53845 22.9349V6.60331C7.56572 5.99186 8.08062 5.5166 8.6923 5.53831ZM23.3077 23.2303C23.4939 23.249 23.6625 23.1193 23.6923 22.9345V6.60299C23.6625 6.41819 23.4939 6.28856 23.3077 6.30722H8.69231C8.50605 6.28856 8.33748 6.41819 8.30769 6.60299V22.9345C8.33748 23.1193 8.50605 23.249 8.69231 23.2303H23.3077Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M14.8461 25.5386C14.8461 24.9014 15.3627 24.3848 16 24.3848C16.6372 24.3848 17.1538 24.9014 17.1538 25.5386C17.1538 26.1759 16.6372 26.6925 16 26.6925C15.3627 26.6925 14.8461 26.1759 14.8461 25.5386ZM15.6154 25.5381C15.6154 25.7505 15.7876 25.9227 16 25.9227C16.2124 25.9227 16.3846 25.7505 16.3846 25.5381C16.3846 25.3257 16.2124 25.1535 16 25.1535C15.7876 25.1535 15.6154 25.3257 15.6154 25.5381Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
