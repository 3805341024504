// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "@rescript/std/lib/es6/js_exn.js";
import * as Util_Dom from "./util/Util_Dom.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "./util/Util_Array.res.mjs";
import * as Dom_storage from "@rescript/std/lib/es6/dom_storage.js";
import * as Util_Result from "./util/Util_Result.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as Belt_MapString from "@rescript/std/lib/es6/belt_MapString.js";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function catchError(f, fallback) {
  try {
    return f();
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID !== Js_exn.$$Error) {
      return fallback();
    }
    var m = obj._1.name;
    if (m !== undefined) {
      console.log("Caught a JS exception! Message: " + m);
      return fallback();
    } else {
      return fallback();
    }
  }
}

var prefix = "ds";

function Make(B, S) {
  var baseKey = prefix + "_" + B.key;
  var version = B.version;
  var key = version !== undefined ? baseKey + "_" + version : baseKey;
  var save = function (value) {
    catchError((function () {
            S.persist(key, B.toString(value));
          }), (function () {
            
          }));
  };
  var remove = function () {
    catchError((function () {
            S.remove(key);
          }), (function () {
            
          }));
  };
  var get = function () {
    return catchError((function () {
                  var result = Core__Result.flatMap(S.get(key), (function (v) {
                          return Util_Result.mapError(B.fromString(v), (function (param) {
                                        return "CantParse";
                                      }));
                        }));
                  if (result.TAG === "Ok") {
                    return result;
                  }
                  switch (result._0) {
                    case "CantParse" :
                        return {
                                TAG: "Ok",
                                _0: B.defaultValue
                              };
                    case "FromDummyStorage" :
                        return {
                                TAG: "Error",
                                _0: "FromDummyStorage"
                              };
                    case "NotFound" :
                    case "StorageError" :
                        return result;
                    
                  }
                }), (function () {
                  return {
                          TAG: "Error",
                          _0: "StorageError"
                        };
                }));
  };
  var deletePreviousVersion = function () {
    catchError((function () {
            S.getAllKeys().filter(function (storageKey) {
                    var match = storageKey.startsWith(baseKey);
                    var match$1 = storageKey === key;
                    if (match && !match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }).forEach(S.remove);
          }), (function () {
            
          }));
  };
  deletePreviousVersion();
  return {
          save: save,
          get: get,
          remove: remove
        };
}

var storage = {
  contents: undefined
};

function persist(key, value) {
  storage.contents = Belt_MapString.set(storage.contents, key, value);
}

function get(key) {
  return Util_Result.fromOption(Belt_MapString.get(storage.contents, key), "NotFound");
}

function remove(key) {
  storage.contents = Belt_MapString.remove(storage.contents, key);
}

function clear() {
  storage.contents = undefined;
}

function getAllKeys() {
  return Belt_MapString.keysToArray(storage.contents);
}

var MapStorage = {
  persist: persist,
  get: get,
  remove: remove,
  clear: clear,
  getAllKeys: getAllKeys
};

function persist$1(param, param$1) {
  
}

function get$1(param) {
  return {
          TAG: "Error",
          _0: "FromDummyStorage"
        };
}

function remove$1(param) {
  
}

function clear$1() {
  
}

function getAllKeys$1() {
  return [];
}

var DummyStorage = {
  persist: persist$1,
  get: get$1,
  remove: remove$1,
  clear: clear$1,
  getAllKeys: getAllKeys$1
};

function persist$2(key, value) {
  Dom_storage.setItem(key, value, localStorage);
}

function remove$2(key) {
  Dom_storage.removeItem(key, localStorage);
}

function get$2(key) {
  return Util_Result.fromOption(Dom_storage.getItem(key, localStorage), "NotFound");
}

function clear$2() {
  localStorage.clear();
}

function getAllKeys$2() {
  var keyLength = localStorage.length;
  return Util_Array.catOption(Belt_Array.makeBy(keyLength, (function (i) {
                    return Dom_storage.key(i, localStorage);
                  })));
}

var Internal_LocalStorage = {
  persist: persist$2,
  get: get$2,
  remove: remove$2,
  clear: clear$2,
  getAllKeys: getAllKeys$2
};

var match = Util_Dom.SafeWebApi.$$window;

var match$1 = Util_Dom.SafeWebApi.localStorage();

var storage$1 = match !== undefined ? (
    match$1 !== undefined ? Internal_LocalStorage : DummyStorage
  ) : MapStorage;

var LocalStorage = storage$1;

export {
  catchError ,
  prefix ,
  Make ,
  MapStorage ,
  DummyStorage ,
  Internal_LocalStorage ,
  LocalStorage ,
}
/* match Not a pure module */
