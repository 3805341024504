// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M15.9994 3C8.82385 3 3 8.82385 3 16.0006C3 23.1762 8.82385 29 15.9994 29C23.1762 29 29 23.1762 29 16.0006C29 8.82385 23.1762 3 15.9994 3ZM15.9995 12.1C16.7165 12.1 17.3 11.5164 17.3 10.7994C17.3 10.0824 16.7165 9.5 15.9995 9.5C15.2836 9.5 14.7 10.0824 14.7 10.7994C14.7 11.5164 15.2836 12.1 15.9995 12.1ZM17.3 22.3667H14.7V13.4H17.3V22.3667Z",
              fill: Particle_Color.toString(Particle_Color.Css.color(color)),
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
