// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Type_Club from "../../../club/Type_Club.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as Atom_Textfield from "../../atom/Atom_Textfield.res.mjs";
import * as Layout_Account from "../../layout/Layout_Account.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as Organism_Dropdown from "../../organism/dropdown/Organism_Dropdown.res.mjs";
import * as User_Api__Sso__Me from "../../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_DatePicker from "../../molecule/Molecule_DatePicker.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var subTitleBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("M", "XS", "None", "None"));

var formBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"));

var buttonBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("M"));

function Page_MyAccount$Profile$MessageComponent(props) {
  var tmp;
  switch (props.fetchStatus) {
    case "Success" :
        tmp = JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(props.successMessage),
              style: Atom_Text_Ds.makeFeedback("Success")
            });
        break;
    case "Error" :
        tmp = JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(props.errorMessage),
              style: Atom_Text_Ds.makeFeedback("Error")
            });
        break;
    case "Idle" :
        tmp = null;
        break;
    
  }
  return JsxRuntime.jsx(Atom_Cell.make, {
              accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "status", undefined),
              children: tmp
            });
}

var MessageComponent = {
  make: Page_MyAccount$Profile$MessageComponent
};

function Page_MyAccount$Profile$Fsk(props) {
  var startAgeVerification = props.startAgeVerification;
  var ageVerificationToken = props.ageVerificationToken;
  var match = Context.I18n.use();
  var match$1 = match.labels.mona.fsk;
  var fskVerificationLabel;
  switch (ageVerificationToken) {
    case "Verified16" :
        fskVerificationLabel = match$1.verified16;
        break;
    case "Verified18" :
        fskVerificationLabel = match$1.verified18;
        break;
    case "Under16" :
    case "NotVerified" :
        fskVerificationLabel = undefined;
        break;
    
  }
  var handleVerify = function (_event) {
    startAgeVerification();
  };
  var tmp;
  tmp = ageVerificationToken === "Verified18" ? null : JsxRuntime.jsx(Atom_Cell.make, {
          blockSpace: Caml_option.some(buttonBlockSpace),
          children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                label: match$1.verifybutton,
                onClick: handleVerify,
                variant: "base"
              })
        });
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(formBlockSpace),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      blockSpace: Caml_option.some(formBlockSpace),
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(match$1.title),
                            kind: "h2",
                            style: Atom_Text_Ds.User.subtitle("MyAccount")
                          })
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(match$1.description),
                            style: Atom_Text_Ds.User.textSmall("MyAccount")
                          })
                    }),
                fskVerificationLabel !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
                        blockSpace: Caml_option.some(subTitleBlockSpace),
                        children: JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(fskVerificationLabel),
                              style: Atom_Text_Ds.User.success
                            })
                      }) : null,
                tmp
              ],
              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
            });
}

var Fsk = {
  make: Page_MyAccount$Profile$Fsk
};

function Page_MyAccount$Profile$EditForm(props) {
  var onPasswordUpdated = props.onPasswordUpdated;
  var onEmailUpdated = props.onEmailUpdated;
  var match = Context.I18n.use();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.common;
  var controller = new AbortController();
  var signal = controller.signal;
  var match$2 = labels.mona.profile;
  var userEditStreetPlaceholderLabel = match$2.streetPlaceholder;
  var userEditPostCodePlaceholderLabel = match$2.postCodePlaceholder;
  var userEditLastNamePlaceholderLabel = match$2.lastNamePlaceholder;
  var userEditGenderPlaceHolder = match$2.genderPlaceHolder;
  var userEditFirstNamePlaceholderLabel = match$2.firstNamePlaceholder;
  var userEmailPlaceholderLabel = match$2.emailPlaceholder;
  var countryLabel = match$2.countryLabel;
  var userEditCityPlaceholderLabel = match$2.cityPlaceholder;
  var match$3 = labels.mona.register;
  var emailActionLabel = match$3.emailActionLabel;
  var match$4 = labels.mona.login;
  var passwordPlaceholder = match$4.passwordPlaceholder;
  var match$5 = React.useState(function () {
        return "Idle";
      });
  var setFormStatus = match$5[1];
  var formStatus = match$5[0];
  var handleSubmit = async function (param) {
    setFormStatus(function (param) {
          return "Idle";
        });
    var response = await User_Api__Sso__Me.put({
          args: User_Api__Sso__Me.Put.stateFormToPutUser(param.state),
          signal: signal,
          locale: locale
        });
    if (response.TAG === "Ok") {
      User_Observable.UserInfo.updateUserInfos(User_Type.Info.userInfoFromUserClub(Type_Club.User.getFromT(response._0)));
      setFormStatus(function (param) {
            return "Success";
          });
    } else {
      setFormStatus(function (param) {
            return "Error";
          });
    }
    return {
            TAG: "Ok",
            _0: undefined
          };
  };
  var form = User_Api__Sso__Me.Put.Form.use(User_Api__Sso__Me.Put.initFormStateFromUser(props.userInfos), User_Api__Sso__Me.Put.schema, handleSubmit, undefined, labels, match.formaters);
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  var match$6 = form.values;
  var onChangeBirthday = function (dateItem) {
    form.handleChange("Birthday", User_Api__Sso__Me.Put.formatStateDate(dateItem));
  };
  var dateItems = User_Api__Sso__Me.Put.makeCalendarItems(31, Type_String.Dropdown.Item.make(match$2.birthdayDayLabel));
  var monthItems = User_Api__Sso__Me.Put.makeCalendarItems(12, Type_String.Dropdown.Item.make(match$2.birthdayMonthLabel));
  var yearItems = User_Api__Sso__Me.Put.makeYearItems(Type_String.Dropdown.Item.make(match$2.birthdayYearLabel));
  var formatBirthdayToDatePicker = function (stringDate) {
    if (stringDate === "") {
      return {
              day: "",
              month: "",
              year: ""
            };
    }
    var date = stringDate.split("/");
    return {
            day: Core__Option.getOr(date[1], "01"),
            month: Core__Option.getOr(date[0], "01"),
            year: Core__Option.getOr(date[2], "2023")
          };
  };
  var tmp;
  var exit = 0;
  switch (formStatus) {
    case "Success" :
    case "Error" :
        exit = 1;
        break;
    case "Idle" :
        tmp = null;
        break;
    
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Atom_Column.make, {
          blockSpace: Caml_option.some(formBlockSpace),
          children: JsxRuntime.jsx(Page_MyAccount$Profile$MessageComponent, {
                fetchStatus: formStatus,
                successMessage: match$2.sucessMessage,
                errorMessage: match$2.errorMessage
              })
        });
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(formBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Textfield.User.Readonly.make, {
                              value: match$6.email,
                              label: userEmailPlaceholderLabel,
                              id: "email",
                              name: userEmailPlaceholderLabel,
                              action: {
                                TAG: "Unique",
                                onClick: (function (param) {
                                    onEmailUpdated();
                                  }),
                                style: Atom_Cta_Ds.User.textfieldAction,
                                label: emailActionLabel,
                                accessibilityLabel: match$3.emailAccessibilityLabel
                              }
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.make, {
                              id: "password",
                              autoComplete: "off",
                              label: passwordPlaceholder,
                              type_: "password",
                              value: "******",
                              readOnly: true,
                              name: passwordPlaceholder,
                              action: {
                                TAG: "Unique",
                                onClick: (function (param) {
                                    onPasswordUpdated();
                                  }),
                                style: Atom_Cta_Ds.User.textfieldAction,
                                label: emailActionLabel,
                                accessibilityLabel: match$3.passwordAccessibilityLabel
                              }
                            }),
                        JsxRuntime.jsx(Organism_Dropdown.make, {
                              kind: {
                                TAG: "Select",
                                _0: {
                                  name: userEditGenderPlaceHolder,
                                  title: userEditGenderPlaceHolder,
                                  autoComplete: "honorificPrefix",
                                  variant: "myProfileNewDesign"
                                }
                              },
                              items: User_Api__Sso__Me.Put.genderItems("", match$2.genderMadamValue, match$2.genderSirValue),
                              label: userEditGenderPlaceHolder,
                              value: JSON.stringify(Type_Club.User.gender_encode(match$6.gender)),
                              onChange: (function (item) {
                                  form.handleChange("Gender", Core__Result.getOr(Type_Club.User.gender_decode(JSON.parse(Type_String.Dropdown.Item.toString(item.value))), "Unknown"));
                                })
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "family-name",
                              value: match$6.lastName,
                              label: userEditLastNamePlaceholderLabel,
                              onBlur: (function (__x) {
                                  form.handleChange("LastName", __x);
                                }),
                              id: "lastName",
                              name: userEditLastNamePlaceholderLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "LastName"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "LastName"
                                  })
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "given-name",
                              value: match$6.firstName,
                              label: userEditFirstNamePlaceholderLabel,
                              onBlur: (function (__x) {
                                  form.handleChange("FirstName", __x);
                                }),
                              id: "firstName",
                              name: userEditFirstNamePlaceholderLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "FirstName"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "FirstName"
                                  })
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(formBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$2.addressTitle),
                              kind: "h2",
                              style: Atom_Text_Ds.User.subtitle("MyAccount")
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$2.addressDescription),
                              style: Atom_Text_Ds.User.textSmall("MyAccount")
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "street-address",
                              value: match$6.street,
                              label: userEditStreetPlaceholderLabel,
                              onBlur: (function (__x) {
                                  form.handleChange("Street", __x);
                                }),
                              id: "street",
                              name: userEditStreetPlaceholderLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "Street"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Street"
                                  })
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "postal-code",
                              value: match$6.postCode,
                              label: userEditPostCodePlaceholderLabel,
                              onBlur: (function (__x) {
                                  form.handleChange("PostCode", __x);
                                }),
                              id: "postCode",
                              name: userEditPostCodePlaceholderLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "PostCode"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "PostCode"
                                  })
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "address-level2",
                              value: match$6.city,
                              label: userEditCityPlaceholderLabel,
                              onBlur: (function (__x) {
                                  form.handleChange("City", __x);
                                }),
                              id: "city",
                              name: userEditCityPlaceholderLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "City"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "City"
                                  })
                            }),
                        JsxRuntime.jsx(Organism_Dropdown.make, {
                              kind: {
                                TAG: "Select",
                                _0: {
                                  name: countryLabel,
                                  title: countryLabel,
                                  autoComplete: "countryName",
                                  variant: "myProfileNewDesign"
                                }
                              },
                              items: props.countriesItems,
                              label: countryLabel,
                              value: match$6.country,
                              onChange: (function (countryItem) {
                                  form.handleChange("Country", Type_String.Dropdown.Item.toString(countryItem.value));
                                })
                            }),
                        JsxRuntime.jsx(Molecule_DatePicker.WithError.make, {
                              onChange: onChangeBirthday,
                              style: Molecule_DatePicker.myProfile,
                              days: dateItems,
                              months: monthItems,
                              years: yearItems,
                              initialValues: formatBirthdayToDatePicker(match$6.birthday),
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "Birthday"
                                  }),
                              ref_: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Birthday"
                                  }),
                              label: match$2.birthdayTitle
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
                    }),
                tmp,
                JsxRuntime.jsx(Atom_Cell.make, {
                      blockSpace: Caml_option.some(buttonBlockSpace),
                      children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                            label: match$1.save,
                            variant: "base",
                            type_: "submit"
                          })
                    }),
                JsxRuntime.jsx(Page_MyAccount$Profile$Fsk, {
                      ageVerificationToken: props.ageVerificationToken,
                      startAgeVerification: props.startAgeVerification
                    })
              ],
              kind: {
                TAG: "Form",
                _0: onSubmit
              }
            });
}

var EditForm = {
  make: Page_MyAccount$Profile$EditForm
};

function Page_MyAccount$Profile(props) {
  var match = Context.I18n.use();
  var userEditProfile = match.labels.mona.profile.title;
  var emptyCountriesItem_value = Type_String.Dropdown.Item.make("-1");
  var emptyCountriesItem_label = Type_String.Dropdown.Item.empty;
  var emptyCountriesItem = {
    value: emptyCountriesItem_value,
    label: emptyCountriesItem_label
  };
  var countriesItems = Util_Array.NonEmpty.make(props.countries, emptyCountriesItem);
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var ageVerificationToken = Core__Option.getOr(Core__Option.map(userInfos, User_Type.Info.getAgeVerificationStatus), "NotVerified");
  var key = userInfos !== undefined ? "loaded" : "loading";
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(userEditProfile),
                      kind: "h1",
                      style: Atom_Text_Ds.User.title("MyAccount")
                    }),
                JsxRuntime.jsx(Page_MyAccount$Profile$EditForm, {
                      userInfos: userInfos,
                      countriesItems: countriesItems,
                      ageVerificationToken: ageVerificationToken,
                      startAgeVerification: props.startAgeVerification,
                      onEmailUpdated: props.onEmailUpdated,
                      onPasswordUpdated: props.onPasswordUpdated
                    }, key)
              ],
              innerSpace: Caml_option.some(Particle_Screen_Constants.xxsInnerSpace)
            });
}

var Profile = {
  subTitleBlockSpace: subTitleBlockSpace,
  formBlockSpace: formBlockSpace,
  buttonBlockSpace: buttonBlockSpace,
  MessageComponent: MessageComponent,
  Fsk: Fsk,
  EditForm: EditForm,
  make: Page_MyAccount$Profile
};

function make(param) {
  var countries = (function (__x) {
        return __x.map(function (param) {
                    return {
                            value: Type_String.Dropdown.Item.make(param[0]),
                            label: Type_String.Dropdown.Item.make(param[1])
                          };
                  });
      })(Object.entries(param.countries));
  return JsxRuntime.jsx(Layout_Account.WithAvatar.make, {
              children: JsxRuntime.jsx(Page_MyAccount$Profile, {
                    startAgeVerification: param.startAgeVerification,
                    onEmailUpdated: param.onEmailUpdated,
                    onPasswordUpdated: param.onPasswordUpdated,
                    countries: countries
                  }),
              cardMobileDisplay: "Displayed"
            });
}

export {
  Profile ,
  make ,
}
/* subTitleBlockSpace Not a pure module */
