// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

function rulesGradient(textStyle) {
  var rulesHeight = Particle_Screen_Ds.map(Util_Css.calcTextLineSize(textStyle.lineHeight, textStyle.size), (function (height) {
          return [CssJs.after([CssJs.height(height)])];
        }));
  var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.after([
              CssJs.position("absolute"),
              CssJs.bottom(CssJs.px(0)),
              CssJs.unsafe("content", ""),
              CssJs.display("block"),
              CssJs.width(CssJs.pct(100)),
              CssJs.height(CssJs.px(100)),
              CssJs.background({
                    NAME: "linearGradient",
                    VAL: [
                      CssJs.deg(0),
                      [
                        [
                          CssJs.pct(0),
                          Particle_Color.Css.color("Background")
                        ],
                        [
                          CssJs.pct(100),
                          Particle_Color.Css.color("TransparentBackground")
                        ]
                      ]
                    ]
                  }),
              CssJs.pointerEvents("none")
            ])]);
  return Particle_Screen_Ds.$$Array.concat(rulesHeight, rules);
}

export {
  rulesGradient ,
}
/* CssJs Not a pure module */
