// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Checkbox from "../../atom/Atom_Checkbox.res.mjs";
import * as Atom_Textfield from "../../atom/Atom_Textfield.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Border_Ds from "../../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Newsletter_Ds from "./Organism_Newsletter_Ds.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as UseSubscribeToNewsletter from "../../../hook/UseSubscribeToNewsletter.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function textId(style) {
  if (style === "Footer") {
    return "newsletter-feedback-footer";
  } else {
    return "newsletter-feedback-zone";
  }
}

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function Organism_Newsletter$Feedback(props) {
  var style = props.style;
  var feedback = props.feedback;
  var match = UseSubscribeToNewsletter.UseSubscribeToNewsletter_Ds.make(feedback);
  var icon = match.icon;
  var match$1 = Organism_Newsletter_Ds.make(style);
  var feedbackBlockSpace = match$1.feedbackBlockSpace;
  var blockSpace;
  blockSpace = typeof feedback !== "object" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone()) : feedbackBlockSpace;
  return JsxRuntime.jsxs(Atom_Row.make, {
              accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "assertive", undefined, undefined, undefined, undefined, undefined),
              blockSpace: Caml_option.some(blockSpace),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
                      width: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(15))),
                      children: icon !== undefined ? JsxRuntime.jsx(Atom_Icon.make, {
                              icon: Caml_option.valFromOption(icon),
                              size: iconSize
                            }) : null,
                      rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.alignSelf("center")]))
                    }),
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match.textFeedback),
                      id: textId(style),
                      kind: "span",
                      style: match.textStyleFeedback
                    })
              ],
              displayOrder: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)),
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.flexWrap(CssJs.nowrap)])),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var Feedback = {
  iconSize: iconSize,
  make: Organism_Newsletter$Feedback
};

var displayOnlyOnMobile = Particle_Screen_Ds.make(undefined, undefined, "none", undefined, undefined, "block");

var footerMaxWidth = Caml_option.some((function (__x) {
          return Particle_Screen_Ds.make(undefined, undefined, undefined, __x, undefined, []);
        })([CssJs.maxWidth(CssJs.px(300))]));

var footerDivider = JsxRuntime.jsx(Atom_Divider.make, {
      style: Atom_Divider.footer,
      display: Caml_option.some(displayOnlyOnMobile),
      kind: "ItemList"
    });

function make(param) {
  var style = param.style;
  var labels = param.labels;
  var source = Core__Option.getOr(param.source, "replay_footer");
  var input = React.useRef(null);
  var match = UseSubscribeToNewsletter.make(source, input);
  var match$1 = Organism_Newsletter_Ds.make(style);
  var match$2 = React.useMemo((function () {
          if (style === "Footer") {
            return [
                    footerDivider,
                    footerMaxWidth,
                    "ItemList"
                  ];
          } else {
            return [
                    null,
                    undefined,
                    undefined
                  ];
          }
        }), [style]);
  var id = "newsletterZoneCheckbox-" + source;
  var checkboxLabel = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          labels.termOfUse.text + " ",
          JsxRuntime.jsx(Atom_Cta.Link.make, {
                href: labels.termOfUse.link,
                label: labels.termOfUse.textLink,
                style: match$1.checkboxCtaStyle,
                target: "_blank"
              })
        ]
      });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                match$2[0],
                JsxRuntime.jsxs(Atom_Column.make, {
                      area: "newsletter",
                      blockSpace: Caml_option.some(match$1.blockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(labels.title),
                              kind: match$1.titleKind,
                              maxWidth: Caml_option.some(match$1.titleMaxWidth),
                              style: match$1.titleStyle
                            }),
                        JsxRuntime.jsx(Atom_Column.make, {
                              children: JsxRuntime.jsxs(Atom_Row.make, {
                                    children: [
                                      JsxRuntime.jsx(Atom_Column.make, {
                                            autoSize: Caml_option.some(match$1.textfieldAutoSize),
                                            blockSpace: Caml_option.some(match$1.textfieldBlockSpace),
                                            children: JsxRuntime.jsx(Atom_Textfield.make, {
                                                  ariaDescribedby: textId(style),
                                                  title: labels.inputTitle,
                                                  type_: "email",
                                                  placeholder: labels.email,
                                                  onChange: match.onChange,
                                                  required: true,
                                                  rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.height({
                                                                  NAME: "percent",
                                                                  VAL: 100
                                                                })])),
                                                  style: match$1.textfieldStyle,
                                                  focusOutline: Particle_Border_Ds.Textfield.newsletterZone,
                                                  autoComplete: "email",
                                                  pattern: "Email",
                                                  name: "newsletterEmail",
                                                  inputRef: input
                                                }),
                                            displayOrder: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 0))
                                          }),
                                      JsxRuntime.jsx(Organism_Newsletter$Feedback, {
                                            feedback: match.state.feedback,
                                            style: style
                                          }),
                                      JsxRuntime.jsx(Atom_Cell.make, {
                                            displayOrder: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 3)),
                                            blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"))),
                                            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                                            children: JsxRuntime.jsx(Atom_Checkbox.make, {
                                                  required: true,
                                                  label: checkboxLabel,
                                                  onChange: match.onCheckboxClick,
                                                  id: id,
                                                  displayOrder: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 3)),
                                                  style: match$1.checkboxStyle
                                                })
                                          }),
                                      JsxRuntime.jsx(Atom_Column.make, {
                                            children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                                                  accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, labels.buttonAriaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                                                  label: labels.button,
                                                  style: match$1.buttonStyle,
                                                  type_: "submit"
                                                }),
                                            displayOrder: Caml_option.some(match$1.displayButtonOrder),
                                            rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.alignSelf("flexStart")])),
                                            width: Caml_option.some(match$1.buttonWidth)
                                          })
                                    ],
                                    innerSpace: Caml_option.some(match$1.textfieldInnerSpace)
                                  }),
                              kind: {
                                TAG: "Form",
                                _0: match.onSubmit
                              }
                            })
                      ],
                      identifier: Caml_option.some(Identifiers.Footer.newsletter),
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")),
                      kind: match$2[2],
                      rules: match$2[1],
                      width: Caml_option.some(match$1.width)
                    })
              ]
            });
}

export {
  textId ,
  Feedback ,
  displayOnlyOnMobile ,
  footerMaxWidth ,
  footerDivider ,
  make ,
}
/* iconSize Not a pure module */
