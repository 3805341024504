// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Atom_Grid from "../../atom/Atom_Grid.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "../../../type/Type_Sticker.res.mjs";
import * as Atom_Image_Ds from "../../atom/Atom_Image_Ds.res.mjs";
import * as Organism_Teaser from "../teaser/Organism_Teaser.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Teaser_Ds from "../teaser/Organism_Teaser_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var leftPartWidth = Particle_Screen_Ds.make(undefined, undefined, 60, undefined, undefined, 55);

var match = Atom_Image_Ds.view(Atom_Image_Ds.make({
          TAG: "TeaserGuide",
          _0: "Big"
        }));

var teaserGuideBigDisplay = match.display;

var gridRules = Particle_Screen_Ds.zipWith((function (display, leftPartWidth) {
        var teaserGuideBigWidth;
        teaserGuideBigWidth = typeof display !== "object" ? CssJs.fr(1) : (
            display.TAG === "Fixed" ? ({
                  NAME: "minmax",
                  VAL: [
                    CssJs.px(display._0.width),
                    CssJs.fr(1)
                  ]
                }) : CssJs.fr(1)
          );
        return [
                CssJs.gridTemplateColumns([
                      CssJs.px(leftPartWidth),
                      teaserGuideBigWidth
                    ]),
                CssJs.alignItems("center")
              ];
      }), teaserGuideBigDisplay, leftPartWidth);

var gapColumn = Particle_Screen_Ds.make(undefined, undefined, "L", "XS", undefined, "XS");

var Organism_Teaser_Guide_Css = {
  leftPartWidthValue: 60,
  leftPartWidth: leftPartWidth,
  teaserGuideBigDisplay: teaserGuideBigDisplay,
  gridRules: gridRules,
  gapColumnValue: "XS",
  gapColumn: gapColumn
};

function make(param) {
  var style = param.style;
  var teaser = param.teaser;
  var match = Context.I18n.use();
  var parentPage = teaser.parentPage;
  var availability = teaser.availability;
  var labels = match.labels;
  var dateWithFormat = match.formaters.dateWithFormat;
  var match$1 = labels.guide;
  var guideHoursMinutes = match$1.hoursMinutes;
  var match$2 = labels.label;
  var borderColor = parentPage !== undefined && parentPage.domain === "concert" ? "Concert" : "Replay";
  var match$3 = Type_Teaser.Kind.view(teaser.kind);
  var teaserKind = typeof match$3 === "object" ? ({
        NAME: "Progress",
        VAL: match$3.VAL
      }) : (
      match$3 === "PrimeTime" ? "PrimeTime" : (
          match$3 === "Direct" ? ({
                NAME: "Direct",
                VAL: borderColor
              }) : (
              match$3 === "Live" ? ({
                    NAME: "Live",
                    VAL: borderColor
                  }) : "Standard"
            )
        )
    );
  var styleDS = Organism_Teaser_Ds.Guide.make(style);
  var match$4 = Organism_Teaser_Ds.withBorder(teaserKind, styleDS);
  var titleStyle = Core__Option.map(match$4.title, Atom_Text_Ds.Teaser.toParent);
  var subtitleStyle = Core__Option.map(match$4.subtitle, Atom_Text_Ds.Teaser.toParent);
  var dateStyle = Atom_Text_Ds.Teaser.toParent(Atom_Text_Ds.Teaser.date);
  var formatDate = function (date, format) {
    return dateWithFormat(new Date(date), format);
  };
  var upcomingDate;
  if (availability !== undefined) {
    var upcomingDate$1 = availability.upcomingDate;
    if (style.TAG === "Concert") {
      var tmp;
      tmp = style.TAG === "Concert" ? JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(formatDate(upcomingDate$1, match$1.monthAbbreviation)),
              style: dateStyle
            }) : null;
      upcomingDate = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(formatDate(upcomingDate$1, match$1.dayAbbreviation)),
                    style: dateStyle
                  }),
              tmp,
              JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(formatDate(upcomingDate$1, guideHoursMinutes)),
                    style: subtitleStyle
                  })
            ]
          });
    } else {
      upcomingDate = JsxRuntime.jsx(Atom_Text.make, {
            children: Caml_option.some(formatDate(upcomingDate$1, guideHoursMinutes)),
            style: titleStyle
          });
    }
  } else {
    upcomingDate = null;
  }
  var match$5 = teaser.stickers;
  var match$6 = Type_String.NotEmpty.make(match$2.live);
  var match$7 = match$5.topLeft;
  var stickers;
  if (match$7 !== undefined && typeof match$7 === "object") {
    var variant = match$7.NAME;
    if (variant === "Soon" || variant === "LastDay" || variant === "AvailableOn") {
      stickers = Type_Sticker.removeTopLeft(teaser.stickers);
    } else if (variant === "Livestream") {
      var exit = 0;
      if (parentPage !== undefined && parentPage.domain === "replay") {
        stickers = Type_Sticker.removeTopLeft(teaser.stickers);
      } else {
        exit = 1;
      }
      if (exit === 1) {
        stickers = match$6 !== undefined ? (function (__x) {
                return Type_Sticker.updateTopLeft(teaser.stickers, __x);
              })({
                NAME: "Livestream",
                VAL: Caml_option.valFromOption(match$6)
              }) : teaser.stickers;
      }
      
    } else {
      stickers = teaser.stickers;
    }
  } else {
    stickers = teaser.stickers;
  }
  var newrecord = Caml_obj.obj_dup(teaser);
  newrecord.stickers = stickers;
  return JsxRuntime.jsxs(Atom_Grid.make, {
              blockSpace: match$4.blockSpace,
              children: [
                JsxRuntime.jsx(Atom_Column.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexEnd),
                      children: upcomingDate,
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
                    }),
                JsxRuntime.jsx(Organism_Teaser.make, {
                      style: styleDS,
                      teaser: newrecord
                    })
              ],
              gapColumn: Caml_option.some(gapColumn),
              identifier: Caml_option.some(typeof teaserKind === "object" ? (
                      teaserKind.NAME === "Direct" ? Identifiers.TeaserGuide.itemDirect : Identifiers.TeaserGuide.item
                    ) : (
                      teaserKind === "PrimeTime" ? Identifiers.TeaserGuide.itemPrimetime : Identifiers.TeaserGuide.item
                    )),
              kind: param.kind,
              rules: Caml_option.some(gridRules)
            });
}

export {
  Organism_Teaser_Guide_Css ,
  make ,
}
/* leftPartWidth Not a pure module */
