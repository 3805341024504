// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as UsePlayer from "../../hook/UsePlayer.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Atom_Icon_Ds from "../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Organism_Overlay from "../organism/Organism_Overlay.res.mjs";
import * as UseTrackingPixel from "../../hook/UseTrackingPixel.res.mjs";
import * as Molecule_Sharebar from "./Molecule_Sharebar.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Animation_Ds from "../particle/Particle_Animation_Ds.res.mjs";
import * as Molecule_TopTeaser_Css from "./Molecule_TopTeaser_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var buttonContainerAlignSelf = Particle_Screen_Ds.make(undefined, undefined, "flexStart", undefined, undefined, "center");

var buttonContainerBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"));

var iconClipContainerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("S"));

var iconClipContainerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Css.shape({
          TAG: "Circle",
          _0: 127
        }));

var direction = Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column");

var illustrationContainerWidth = Particle_Screen_Ds.make(undefined, undefined, Css.pct(50), undefined, undefined, CssJs.pct(100));

var textContentBlockspace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XL"), undefined, undefined, Particle_Spacer_Ds.makeAll("M"));

var textContainerWidth = Particle_Screen_Ds.make(undefined, undefined, Css.pct(50), undefined, undefined, CssJs.pct(100));

var clipContainerIconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "Custom",
      VAL: [
        77,
        77
      ]
    });

var mountOnInit = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, true);

function make(param) {
  var eventShareUrl = param.eventShareUrl;
  var sharebar = param.sharebar;
  var clip = param.clip;
  var buttons = param.buttons;
  var images = param.images;
  var teaserText = param.teaserText;
  var ariaLabelledby = Particle_Accessibility.AriaLabelledby.make(param.id);
  var textPosition = Core__Option.getOr(param.textPosition, "TextOnRightSide");
  var trackPixel = UseTrackingPixel.make(Core__Option.flatMap(param.trackingPixel, Type_String.TrackingPixel.toTypeUrl));
  var player = UsePlayer.make(clip, Caml_option.some(mountOnInit), images, undefined);
  var textDisplayOrder;
  textDisplayOrder = textPosition === "TextOnLeftSide" ? Particle_Screen_Ds.make(undefined, undefined, -1, undefined, undefined, 1) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1);
  var href = Core__Option.map(Type_String.NotEmpty.make(Type_String.Href.toString(param.href)), (function (href) {
          return Type_String.Href.make(Type_String.NotEmpty.toString(href));
        }));
  var sharebar$1 = sharebar !== undefined && sharebar === "With" && eventShareUrl !== undefined ? JsxRuntime.jsx(Molecule_Sharebar.EventZone.make, {
          url: Type_String.Href.toString(eventShareUrl)
        }) : null;
  var clipContainer;
  if (clip !== undefined) {
    var player$1 = UsePlayer.getPlayerElement(player);
    var match = player$1 !== undefined ? [
        Caml_option.valFromOption(player$1),
        Organism_Overlay.Organism_Overlay_Ds.eventZonePlayer
      ] : [
        JsxRuntime.jsx(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              children: JsxRuntime.jsx(Atom_Cell.make, {
                    blockSpace: Caml_option.some(iconClipContainerBlockSpace),
                    background: Caml_option.some(Particle_Background_Ds.playerPlayIcon),
                    children: JsxRuntime.jsx(Atom_Icon.make, {
                          icon: Atom_Icon_Ds.play,
                          size: clipContainerIconSize
                        }),
                    rules: Caml_option.some(iconClipContainerRules)
                  }),
              innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
            }),
        Organism_Overlay.Organism_Overlay_Ds.eventZoneButton
      ];
    clipContainer = Caml_option.some(JsxRuntime.jsx(Organism_Overlay.make, {
              children: match[0],
              isOpen: true,
              zIndex: {
                TAG: "Exact",
                _0: "OverlayEventZone"
              },
              style: match[1]
            }));
  } else {
    clipContainer = undefined;
  }
  var image = function (animation) {
    return JsxRuntime.jsx(Atom_Image.make, {
                alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfCenter),
                alt: "",
                animation: animation,
                src: images,
                style: "EventZone"
              });
  };
  return JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: param.blockSpace,
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              children: JsxRuntime.jsxs(Atom_Box.make, {
                    alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                    background: Caml_option.some(Particle_Background_Ds.eventZone),
                    children: [
                      JsxRuntime.jsx(Atom_Cell.make, {
                            autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
                            width: Caml_option.some(illustrationContainerWidth),
                            children: clipContainer !== undefined ? JsxRuntime.jsxs(Atom_Box.make, {
                                    children: [
                                      image(Caml_option.some(Particle_Animation_Ds.Clip.On.image)),
                                      Caml_option.valFromOption(clipContainer)
                                    ],
                                    position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                                    width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                                    cbRef: (function (ref) {
                                        UsePlayer.getRef(player)(ref);
                                      })
                                  }) : (
                                href !== undefined ? JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                                        accessibility: param.accessibility,
                                        children: image(undefined),
                                        ctaRef: param.ref_,
                                        href: Type_String.Href.toString(href),
                                        onClick: (function (param) {
                                            trackPixel();
                                          }),
                                        style: Atom_Cta_Ds.EventZone.image
                                      }) : JsxRuntime.jsx(Atom_Box.make, {
                                        children: Caml_option.some(image(undefined))
                                      })
                              )
                          }),
                      JsxRuntime.jsx(Atom_Column.make, {
                            autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
                            children: JsxRuntime.jsxs(Atom_Column.make, {
                                  alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                                  blockSpace: Caml_option.some(textContentBlockspace),
                                  children: [
                                    JsxRuntime.jsx(Atom_Text.make, {
                                          children: Caml_option.some(Type_String.Teaser.Title.toString(param.title)),
                                          id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "-title"),
                                          kind: "h2",
                                          style: Atom_Text_Ds.EventZone.make("Title")
                                        }),
                                    JsxRuntime.jsx(Atom_Text.make, {
                                          children: Caml_option.some(Type_String.Teaser.Subtitle.toString(param.subtitle)),
                                          kind: "h3",
                                          style: Atom_Text_Ds.EventZone.make("Subtitle")
                                        }),
                                    teaserText !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                                            children: Caml_option.some(Type_String.Teaser.Description.toString(teaserText)),
                                            kind: "p",
                                            style: param.action !== undefined ? Atom_Text_Ds.EventZone.make("DescNoClamp") : Atom_Text_Ds.EventZone.make("Desc"),
                                            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                                          }) : null,
                                    buttons !== undefined ? JsxRuntime.jsxs(Atom_Row.make, {
                                            alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                            alignSelf: Caml_option.some(buttonContainerAlignSelf),
                                            blockSpace: Caml_option.some(buttonContainerBlockspace),
                                            children: [
                                              Caml_option.valFromOption(buttons),
                                              sharebar$1
                                            ],
                                            gap: Caml_option.some(Molecule_TopTeaser_Css.ButtonsChildrenRow.gap)
                                          }) : null
                                  ],
                                  innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                                }),
                            displayOrder: Caml_option.some(textDisplayOrder),
                            width: Caml_option.some(textContainerWidth)
                          })
                    ],
                    direction: Caml_option.some(direction)
                  }),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              identifier: param.identifier
            });
}

make.displayName = "EventZone";

export {
  buttonContainerAlignSelf ,
  buttonContainerBlockspace ,
  iconClipContainerBlockSpace ,
  iconClipContainerRules ,
  direction ,
  illustrationContainerWidth ,
  textContentBlockspace ,
  textContainerWidth ,
  clipContainerIconSize ,
  mountOnInit ,
  make ,
}
/* buttonContainerAlignSelf Not a pure module */
