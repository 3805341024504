// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1708 8.8344 29 16 29C23.1708 29 29 23.1708 29 16C29 8.8344 23.1656 3 16 3C8.8344 3 3 8.8344 3 16ZM4.4716 16C4.4716 9.6456 9.6456 4.4716 16 4.4716C22.3544 4.4716 27.5284 9.6456 27.5284 16C27.5284 22.3596 22.3544 27.5284 16 27.5284C9.6404 27.5284 4.4716 22.3596 4.4716 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M6.7804 16.13V16.1456C6.7804 17.6484 7.9452 18.8548 9.5728 18.8548C11.2004 18.8548 12.3808 17.6276 12.3808 16.13V16.1144C12.3808 14.6168 11.216 13.4052 9.5884 13.4052C7.9608 13.4052 6.7804 14.6324 6.7804 16.13ZM11.1692 16.13V16.1456C11.1692 17.0504 10.5244 17.794 9.5884 17.794C8.6524 17.794 7.992 17.04 7.992 16.1352V16.1196C7.992 15.2148 8.6368 14.4764 9.5728 14.4764C10.5088 14.4764 11.1692 15.2252 11.1692 16.13Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M13.2908 14.7312H14.4348V15.3032C14.7 14.9652 15.0432 14.6532 15.6256 14.6532C16.1508 14.6532 16.5512 14.8872 16.7644 15.2928C17.118 14.8768 17.5392 14.6532 18.0904 14.6532C18.9432 14.6532 19.4528 15.1628 19.4528 16.1352V18.7612H18.3088V16.5096C18.3088 15.9688 18.0696 15.688 17.638 15.688C17.2064 15.688 16.9464 15.9688 16.9464 16.5096V18.7612H15.8024V16.5096C15.8024 15.9688 15.5632 15.688 15.1316 15.688C14.7 15.688 14.44 15.9688 14.44 16.5096V18.7612H13.296V14.7312H13.2908Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M20.4772 16.5096V13.4988H21.6368V16.4784C21.6368 17.3364 22.0684 17.7784 22.7756 17.7784C23.4828 17.7784 23.9144 17.3468 23.9144 16.5148V13.4936H25.074V16.468C25.074 18.0644 24.1796 18.8444 22.7652 18.8444C21.3456 18.8496 20.4772 18.0592 20.4772 16.5096Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
