// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1659 8.83406 29 16 29C23.171 29 29 23.1659 29 16C29 8.83406 23.1659 3 16 3C8.83406 3 3 8.83406 3 16ZM4.47196 16C4.47196 9.64088 9.64596 4.47191 16 4.47191C22.3591 4.47191 27.5281 9.64088 27.5281 16C27.5281 22.3591 22.3591 27.528 16 27.528C9.64092 27.528 4.47196 22.3591 4.47196 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M7.73194 14.0596L6.46297 14.3716L6.13 13.0615L8.22007 12.4375H9.31207V19.7645H7.7311V14.0604L7.73194 14.0596Z"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M10.831 16.3541C10.831 17.8001 11.164 18.5801 11.694 19.1101L11.6949 19.111C12.2048 19.6201 12.8498 19.891 13.7958 19.896C15.3768 19.896 16.5728 18.8661 16.5728 17.379V17.3581C16.5728 15.84 15.4607 15.039 14.0147 15.039C13.3488 15.039 12.8917 15.247 12.5067 15.507C12.5587 14.8721 12.8808 13.8221 13.9728 13.8221C14.5968 13.8221 14.9818 14.0401 15.4598 14.4041L16.3019 13.1662C15.6569 12.6563 15.012 12.3652 14.024 12.3652C11.85 12.3652 10.831 14.0871 10.831 16.3332V16.3541ZM14.9809 17.4254V17.4464C14.9809 18.0704 14.5238 18.5174 13.7329 18.5174C12.942 18.5174 12.4639 18.0494 12.4639 17.4355V17.4145C12.4639 16.8014 12.921 16.3644 13.7119 16.3644C14.5028 16.3644 14.9809 16.8115 14.9809 17.4254Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M19.0261 15.646H17.7261V16.712H19.0261V15.646ZM19.0261 18.6877H17.7261V19.7637H19.0261V18.6877Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M22.51 17.2794C23.134 17.2794 23.6129 17.0924 23.987 16.7804V16.7812C23.9241 17.4052 23.6121 18.4343 22.51 18.4293C21.8969 18.4293 21.4491 18.2313 20.919 17.8053L20.0769 19.0222C20.6909 19.5313 21.408 19.8853 22.4689 19.8853C24.538 19.8853 25.651 18.2783 25.651 15.9173V15.8964C25.651 14.4605 25.308 13.6704 24.788 13.1504C24.2369 12.5994 23.6649 12.3604 22.687 12.3604C21.044 12.3604 19.91 13.4423 19.91 14.9084V14.9293C19.91 16.4054 20.9291 17.2794 22.51 17.2794ZM24.0181 14.8454V14.8655C24.0181 15.4895 23.566 15.9474 22.7701 15.9474C21.9792 15.9474 21.5011 15.5004 21.5011 14.8554V14.8345C21.5011 14.1903 21.949 13.7324 22.7391 13.7324C23.54 13.7324 24.0181 14.2004 24.0181 14.8454Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
