// Generated by ReScript, PLEASE EDIT WITH CARE


var partners = [
  {
    kind: "Ard",
    label: "ARD",
    href: "http://www.ard.de"
  },
  {
    kind: "Zdf",
    label: "ZDF",
    href: "http://www.zdf.de"
  },
  {
    kind: "Ceskatelevize",
    label: " Česká televize ",
    href: "http://www.ceskatelevize.cz"
  },
  {
    kind: "Ffl",
    label: "Film Fund Luxembourg",
    href: "http://www.filmfund.lu"
  },
  {
    kind: "Lrt",
    label: "Lietuvos nacionalinis radijas ir televizija",
    href: "https://www.lrt.lt"
  },
  {
    kind: "Ltv",
    label: "Latvian Television",
    href: "https://ltv.lsm.lv/lv"
  },
  {
    kind: "Orf",
    label: "ORF",
    href: "http://www.orf.at"
  },
  {
    kind: "Rai",
    label: "Rai",
    href: "http://www.rai.it"
  },
  {
    kind: "Rtbf",
    label: "RTBF",
    href: "http://www.rtbf.be"
  },
  {
    kind: "Rte",
    label: " RTÉ ",
    href: "http://www.rte.ie"
  },
  {
    kind: "Rtve",
    label: "Radiotelevisión Española",
    href: "https://www.rtve.es/"
  },
  {
    kind: "Rts",
    label: "RTS",
    href: "http://www.rts.ch"
  },
  {
    kind: "Yle",
    label: "Yle",
    href: "http://www.yle.fi"
  }
];

var currentYear = new Date().getFullYear();

function getLabels(locale) {
  switch (locale) {
    case "Fr" :
        return {
                legacyLabel: " Tous droits de reproduction et de diffusion réservés © " + (currentYear.toString() + " ARTE G.E.I.E. - ARTE G.E.I.E. 4, quai du chanoine Winterer CS 20035F - 67080 Strasbourg Cedex "),
                european: undefined,
                newsletter: {
                  title: "Newsletter",
                  termOfUse: {
                    text: " Je souhaite recevoir la newsletter d'ARTE. Je peux me désinscrire à tout moment via le lien de désinscription figurant dans la newsletter. ",
                    link: "https://www.arte.tv/sites/corporate/conditions-generales-dutilisation-cgu/",
                    textLink: "Pour en savoir plus, consultez nos CGU."
                  },
                  wrongEmailFormat: "Entrez un format de e-mail valide.",
                  button: "OK",
                  email: "Adresse e-mail",
                  inputTitle: " Abonnez-vous à la newsletter - e-mail (exemple@domain.com) ",
                  buttonAriaLabel: "OK, je souhaite souscrire à la newsletter"
                },
                partners: partners,
                appLink: {
                  title: "arte.tv sur tous vos écrans",
                  description: "Retrouvez nos programmes sur votre ordinateur, smartphone, tablette ou TV connectée",
                  badge: {
                    altPlayStore: "Disponible sur Google Play",
                    altAppStore: "Télécharger dans l'App Store"
                  }
                }
              };
    case "De" :
        return {
                legacyLabel: " Alle Rechte vorbehalten © " + (currentYear.toString() + " ARTE G.E.I.E. - ARTE G.E.I.E. Postfach 1980 D-77679 KEHL "),
                european: undefined,
                newsletter: {
                  title: "Newsletter",
                  termOfUse: {
                    text: " Ich möchte den ARTE Newsletter erhalten. Die Einwilligung kann ich jederzeit über den Abmeldelink im Newsletter widerrufen. ",
                    link: "https://www.arte.tv/sites/corporate/de/allgemeine-nutzungsbedingungen/?lang=de",
                    textLink: "Mehr Infos dazu in unseren Nutzungsbedingungen."
                  },
                  wrongEmailFormat: " Bitte tragen Sie eine gültige E-Mail-Adresse ein. ",
                  button: "OK",
                  email: "E-Mail-Adresse",
                  inputTitle: "Newsletter abonnieren - e-mail (Beispiel@domain.com)",
                  buttonAriaLabel: "OK, ich möchte den Newsletter abonnieren"
                },
                partners: partners,
                appLink: {
                  title: "arte.tv auf allen Geräten",
                  description: "Schauen Sie all Ihre Programme auf Desktop, Smartphone, Tablet oder Smart TV",
                  badge: {
                    altPlayStore: "Jetzt bei Google Play",
                    altAppStore: "Laden im App Store"
                  }
                }
              };
    case "Pl" :
        return {
                legacyLabel: " Wszelkie prawa zastrzeżone - ARTE G.E.I.E. 4, quai du chanoine Winterer CS 20035F - 67080 Strasbourg Cedex - France ",
                european: " Program jest współfinansowany ze środków Unii Europejskiej ",
                newsletter: {
                  title: "Newsletter",
                  termOfUse: {
                    text: " Tak, chcę otrzymywać newsletter ARTE. (Możesz w każdej chwili zrezygnować z subskrypcji.  ",
                    link: "https://www.arte.tv/sites/corporate/pl/ogolne-warunki-uzytkowania/?lang=pl",
                    textLink: " Aby uzyskać więcej informacji, zobacz nasze Warunki.)  "
                  },
                  wrongEmailFormat: " Proszę wprowadzić poprawny adres e-mail. ",
                  button: "OK",
                  email: "Adres e-mail",
                  inputTitle: " Zapisz się do newslettera - e-mail (przykład@domain.com) ",
                  buttonAriaLabel: "OK, chciałbym zapisać się do newslettera"
                },
                partners: partners,
                appLink: {
                  title: "arte.tv na wszystkich Twoich urządzeniach",
                  description: "Oglądaj nasze programy na komputerze, smartfonie, tablecie lub Smart TV.",
                  badge: {
                    altPlayStore: "Pobier z Google Play",
                    altAppStore: "Pobier w App Store"
                  }
                }
              };
    case "It" :
        return {
                legacyLabel: "All rights for reproduction and diffusion reserved - ARTE G.E.I.E. 4, quai du chanoine Winterer CS 20035F - 67080 Strasbourg Cedex - France",
                european: " Progetto co-finanziato dall’Unione europea ",
                newsletter: {
                  title: "Newsletter",
                  termOfUse: {
                    text: " Sì, desidero ricevere la newsletter. Potrò cancellare l'iscrizione in qualsiasi momento tramite il link apposito, che si trova in fondo alla newsletter. ",
                    link: "https://www.arte.tv/sites/corporate/it/condizioni-generali-dutilizzo/?lang=it",
                    textLink: " Maggiori informazioni nelle condizioni generali di utilizzo. "
                  },
                  wrongEmailFormat: "Inserire un indirizzo email valido.",
                  button: "OK",
                  email: "Indirizzo email",
                  inputTitle: "Iscriviti alla newsletter - e-mail (esempio@domain.com)",
                  buttonAriaLabel: "OK, voglio iscrivermi alla newsletter"
                },
                partners: partners,
                appLink: {
                  title: "ARTE.tv su tutti i tuoi dispositivi",
                  description: "Ritrova i tuoi programmi su PC, smartphone, tablet o Smart TV",
                  badge: {
                    altPlayStore: "Disponible su Google Play",
                    altAppStore: "Scarica su App Store"
                  }
                }
              };
    case "En" :
        return {
                legacyLabel: "All rights for reproduction and diffusion reserved - ARTE G.E.I.E. 4, quai du chanoine Winterer CS 20035F - 67080 Strasbourg Cedex - France",
                european: "Co-funded by the European Union",
                newsletter: {
                  title: "Newsletter",
                  termOfUse: {
                    text: "Yes, I would like to receive the newsletter. I can unsubscribe whenever I like via the unsubscribe link in the newsletter.",
                    link: "https://www.arte.tv/sites/corporate/en/general-conditions-of-use/?lang=en",
                    textLink: "For more information please see our terms and conditions."
                  },
                  wrongEmailFormat: "Please enter a valid email address.",
                  button: "OK",
                  email: "Email address",
                  inputTitle: "Subscribe to the newsletter - e-mail (example@domain.com)",
                  buttonAriaLabel: "OK, I would like to subscribe to the newsletter"
                },
                partners: partners,
                appLink: {
                  title: "arte.tv on all your devices",
                  description: "Watch our programmes on your computer, smartphone, tablet or Smart TV",
                  badge: {
                    altPlayStore: "Get it on Google Play",
                    altAppStore: "Download on the App Store"
                  }
                }
              };
    case "Es" :
        return {
                legacyLabel: " Reservados todos los derechos de reproducción y difusión - ARTE G.E.I.E. 4, quai du chanoine Winterer CS 20035F - 67080 Strasbourg Cedex - France ",
                european: " Cofinanciado por la Unión Europea ",
                newsletter: {
                  title: "Newsletter",
                  termOfUse: {
                    text: " Sí, deseo recibir la newsletter de ARTE. Puedo darme de baja en cualquier momento gracias al enlace en la newsletter. ",
                    link: "https://www.arte.tv/sites/corporate/es/condiciones-generales-de-uso-cgu/?lang=es",
                    textLink: " Más información en las Condiciones generales de uso. "
                  },
                  wrongEmailFormat: " Por favor, introduce una dirección de e-mail válida. ",
                  button: "OK",
                  email: " Dirección de e-mail ",
                  inputTitle: "Inscribirse a la newsletter - e-mail (ejemplo@domain.com)",
                  buttonAriaLabel: "OK, me gustaría suscribirme al boletín"
                },
                partners: partners,
                appLink: {
                  title: "arte.tv, en todos tus dispositivos",
                  description: "Encuentra tus programas en tu PC, smartphone, tablet o Smart TV.",
                  badge: {
                    altPlayStore: "Disponible en Google Play",
                    altAppStore: "Consíguelo en el App Store"
                  }
                }
              };
    
  }
}

var defaultPlayStoreUrl = "https://play.google.com/store/apps/details?id=tv.arte.plus7&utm_campaign=install-app-btn";

var defaultAppStoreUrl = "https://apps.apple.com/app/apple-store/id405028510?pt=398281&ct=install-app-btn&mt=8";

export {
  partners ,
  currentYear ,
  defaultPlayStoreUrl ,
  defaultAppStoreUrl ,
  getLabels ,
}
/* currentYear Not a pure module */
