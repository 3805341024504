// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1711 8.83456 29 16 29C23.1711 29 29 23.1711 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.83456 3 16ZM4.47149 16C4.47149 9.64544 9.64549 4.47144 16.0001 4.47144C22.3546 4.47144 27.5286 9.64544 27.5286 16C27.5286 22.3594 22.3546 27.5286 16.0001 27.5286C9.64061 27.5286 4.47149 22.3594 4.47149 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M7.64337 14.2532H6.07849V18.2572H7.64337C8.9068 18.2572 9.77537 17.3837 9.77537 16.2552V16.2446C9.77537 15.116 8.90193 14.2532 7.64337 14.2532ZM6.95678 17.4665V15.0534H7.63766C8.3551 15.0534 8.84422 15.5474 8.84422 16.2599V16.2705C8.84422 16.9774 8.36078 17.4665 7.63766 17.4665H6.95678Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M10.3792 16.2543V16.2649C10.3792 17.4089 11.2688 18.3238 12.5063 18.3238C13.7437 18.3238 14.6432 17.3878 14.6432 16.2486V16.2381C14.6432 15.0998 13.7535 14.1792 12.516 14.1792C11.2786 14.1792 10.3792 15.1152 10.3792 16.2543ZM13.7169 16.2544V16.265C13.7169 16.9564 13.2229 17.513 12.516 17.513C11.8091 17.513 11.3046 16.941 11.3046 16.2544V16.2439C11.3046 15.5573 11.7929 14.9959 12.5054 14.9959C13.2123 14.9959 13.7169 15.5679 13.7169 16.2544Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M15.4231 14.2532H16.3071V17.456H18.3042V18.2572H15.4231V14.2532V14.2532Z"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M18.9689 14.2534H20.8303C21.2877 14.2534 21.6469 14.3785 21.8809 14.6069C22.0629 14.7889 22.1563 15.0123 22.1563 15.2877V15.2983C22.1563 15.7509 21.9174 16.0003 21.6314 16.1612C22.0946 16.3383 22.3806 16.608 22.3806 17.1443V17.1549C22.3806 17.8877 21.7826 18.2517 20.8774 18.2517H18.9689V14.2534ZM21.278 15.4388C21.278 15.1739 21.07 15.0276 20.7011 15.0276H19.8325V15.8751H20.6434C21.0334 15.8751 21.278 15.7499 21.278 15.4542V15.4388ZM19.8277 16.603H20.8466L20.8474 16.6022C21.2992 16.6022 21.5023 16.7688 21.5023 17.0393V17.0499C21.5023 17.3522 21.2683 17.487 20.8783 17.487H19.8277V16.603Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M24.0599 16.6808L22.5211 14.2522H23.5505L24.5076 15.8593L25.4802 14.2522H26.4836L24.9447 16.6653V18.2619H24.0607V16.6808H24.0599Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
