// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3.70828 5V12.3818H5.94348V7.22131H26.8238V23.8811H18.462V26.0834H29V5H3.70828Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3.70795 22.1616V24.2452C4.76002 24.7963 5.16492 25.0247 5.71108 26.0767H7.80045C6.76809 25.0247 5.81292 23.1989 3.70795 22.1616Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("mask", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M3.70306 21.7505H4.11823V22.5718H3.70306V21.7505Z",
                            fill: "white",
                            fillRule: "evenodd"
                          }),
                      id: "mask0_29_9252",
                      height: "2",
                      width: "2",
                      maskUnits: "userSpaceOnUse",
                      x: "3",
                      y: "21"
                    }),
                JsxRuntime.jsx("g", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M3.70714 22.1607C3.70714 22.1607 3.70714 22.1607 3.70796 22.1615V22.1607H3.70714Z",
                            fillRule: "evenodd"
                          }),
                      mask: "url(#mask0_29_9252)"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M14.2516 21.7968C13.579 20.4918 12.7084 19.333 11.6662 18.2817C10.6289 17.2387 9.44541 16.3837 8.13956 15.7012C6.80906 15.0072 5.38247 14.5465 3.90989 14.3042L3.70786 14.2795V16.3385L3.84912 16.3632C5.04821 16.5907 6.20212 17.0005 7.29444 17.5828C8.36213 18.1544 9.33864 18.8829 10.2035 19.7428C11.9692 21.5135 13.1478 23.6193 13.6036 26.0364L13.6291 26.0667H15.6881L15.6528 25.9961C15.4146 24.5145 14.939 23.1331 14.2516 21.7968Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("mask", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M3.70309 18.0881H11.8832V26.1022H3.70309V18.0881Z",
                            fill: "white",
                            fillRule: "evenodd"
                          }),
                      id: "mask1_29_9252",
                      height: "9",
                      width: "9",
                      maskUnits: "userSpaceOnUse",
                      x: "3",
                      y: "18"
                    }),
                JsxRuntime.jsx("g", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M8.67028 21.301H8.6711C7.31514 19.9352 5.62574 18.8421 3.87474 18.1391L3.70309 18.0881V20.1167L3.79919 20.1422C5.1149 20.7541 6.31891 21.5844 7.3406 22.6061C8.36722 23.6384 9.2074 24.7661 9.81434 26.1023L9.84966 26.0875H11.8832L11.8323 26.0317C11.1292 24.2511 10.0361 22.6668 8.67028 21.301Z",
                            fillRule: "evenodd"
                          }),
                      mask: "url(#mask1_29_9252)"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
