// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("circle", {
                      cx: "16",
                      cy: "16",
                      fill: Particle_Color.toString(Particle_Color.Css.color(color)),
                      r: "13"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M20.3875 11.5L14.7 18.0137L11.1792 14.9647L9.82501 16.7664L14.8354 21.0625L22.0125 13.0246L20.3875 11.5Z",
                      fill: "white",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
