// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_json from "@rescript/std/lib/es6/js_json.js";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Type_String from "./Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function time_encode(v) {
  return Spice.stringToJson(v);
}

function time_decode(v) {
  return Spice.stringFromJson(v);
}

function icon_encode(v) {
  if (v === "play") {
    return ["play"];
  } else {
    return ["none"];
  }
}

function icon_decode(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "none" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "none"
                  };
          }
      case "play" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "play"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function label_encode(v) {
  return Type_String.NotEmpty.t_encode(v);
}

function label_decode(v) {
  return Type_String.NotEmpty.t_decode(v);
}

function stickers_encode(v) {
  if (typeof v !== "object") {
    if (v === "ToBeContinued") {
      return ["ToBeContinued"];
    } else {
      return ["PrimeTime"];
    }
  }
  var variant = v.NAME;
  if (variant === "Direct") {
    return [
            "Direct",
            Type_String.NotEmpty.t_encode(v.VAL)
          ];
  }
  if (variant === "Livestream") {
    return [
            "Livestream",
            Type_String.NotEmpty.t_encode(v.VAL)
          ];
  }
  if (variant !== "LabelOverride") {
    if (variant === "Highlight") {
      return [
              "Highlight",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    } else if (variant === "Playable") {
      return [
              "Playable",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    } else if (variant === "Genre") {
      return [
              "Genre",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    } else if (variant === "LastDay") {
      return [
              "LastDay",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    } else if (variant === "Live") {
      return [
              "Live",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    } else if (variant === "Soon") {
      return [
              "Soon",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    } else if (variant === "Time") {
      return [
              "Time",
              Spice.stringToJson(v.VAL)
            ];
    } else {
      return [
              "AvailableOn",
              Type_String.NotEmpty.t_encode(v.VAL)
            ];
    }
  }
  var match = v.VAL;
  return [
          "LabelOverride",
          Type_String.NotEmpty.t_encode(match[0]),
          icon_encode(match[1])
        ];
}

function stickers_decode(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "AvailableOn" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$1 = Belt_Array.getExn(json_arr$1, 1);
          var v0 = Type_String.NotEmpty.t_decode(v$1);
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "AvailableOn",
                      VAL: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Direct" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$2 = Belt_Array.getExn(json_arr$1, 1);
          var v0$1 = Type_String.NotEmpty.t_decode(v$2);
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Direct",
                      VAL: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "Genre" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$3 = Belt_Array.getExn(json_arr$1, 1);
          var v0$2 = Type_String.NotEmpty.t_decode(v$3);
          if (v0$2.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Genre",
                      VAL: v0$2._0
                    }
                  };
          }
          var e$2 = v0$2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
      case "Highlight" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$4 = Belt_Array.getExn(json_arr$1, 1);
          var v0$3 = Type_String.NotEmpty.t_decode(v$4);
          if (v0$3.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Highlight",
                      VAL: v0$3._0
                    }
                  };
          }
          var e$3 = v0$3._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
      case "LabelOverride" :
          if (tagged.length !== 3) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$5 = Belt_Array.getExn(json_arr$1, 1);
          var match$1 = Type_String.NotEmpty.t_decode(v$5);
          var match$2 = icon_decode(Belt_Array.getExn(json_arr$1, 2));
          if (match$1.TAG === "Ok") {
            if (match$2.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        NAME: "LabelOverride",
                        VAL: [
                          match$1._0,
                          match$2._0
                        ]
                      }
                    };
            }
            var e$4 = match$2._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: "[1]" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = match$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
      case "LastDay" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$6 = Belt_Array.getExn(json_arr$1, 1);
          var v0$4 = Type_String.NotEmpty.t_decode(v$6);
          if (v0$4.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "LastDay",
                      VAL: v0$4._0
                    }
                  };
          }
          var e$6 = v0$4._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
      case "Live" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$7 = Belt_Array.getExn(json_arr$1, 1);
          var v0$5 = Type_String.NotEmpty.t_decode(v$7);
          if (v0$5.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Live",
                      VAL: v0$5._0
                    }
                  };
          }
          var e$7 = v0$5._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
      case "Livestream" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$8 = Belt_Array.getExn(json_arr$1, 1);
          var v0$6 = Type_String.NotEmpty.t_decode(v$8);
          if (v0$6.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Livestream",
                      VAL: v0$6._0
                    }
                  };
          }
          var e$8 = v0$6._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
      case "Playable" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$9 = Belt_Array.getExn(json_arr$1, 1);
          var v0$7 = Type_String.NotEmpty.t_decode(v$9);
          if (v0$7.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Playable",
                      VAL: v0$7._0
                    }
                  };
          }
          var e$9 = v0$7._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$9.path,
                    message: e$9.message,
                    value: e$9.value
                  }
                };
      case "PrimeTime" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "PrimeTime"
                  };
          }
      case "Soon" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$10 = Belt_Array.getExn(json_arr$1, 1);
          var v0$8 = Type_String.NotEmpty.t_decode(v$10);
          if (v0$8.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Soon",
                      VAL: v0$8._0
                    }
                  };
          }
          var e$10 = v0$8._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
      case "Time" :
          if (tagged.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v$11 = Belt_Array.getExn(json_arr$1, 1);
          var v0$9 = Spice.stringFromJson(v$11);
          if (v0$9.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      NAME: "Time",
                      VAL: v0$9._0
                    }
                  };
          }
          var e$11 = v0$9._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$11.path,
                    message: e$11.message,
                    value: e$11.value
                  }
                };
      case "ToBeContinued" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ToBeContinued"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function toCode(value) {
  switch (value) {
    case "FULL_VIDEO" :
        return "FULL_VIDEO";
    case "LABEL_OVERRIDE" :
        return "LABEL_OVERRIDE";
    case "LABEL_OVERRIDE_PLAY" :
        return "LABEL_OVERRIDE_PLAY";
    case "LAST_DAY" :
        return "LAST_DAY";
    case "LIVE" :
        return "LIVE";
    case "PLAYABLE" :
        return "PLAYABLE";
    case "PRIME_TIME" :
        return "PRIMETIME";
    case "CONCERT" :
    case "DOC" :
    case "MAGAZINE" :
    case "MOVIE" :
    case "SERIE" :
        return "GENRE";
    case "SOON" :
        return "SOON";
    case "TV_LIVE" :
        return "TV_LIVE";
    default:
      return ;
  }
}

var empty = {
  topLeft: undefined,
  bottomLeft: undefined,
  bottomRight: undefined,
  noPosition: undefined
};

function updateTopLeft(t, value) {
  return {
          topLeft: value,
          bottomLeft: t.bottomLeft,
          bottomRight: t.bottomRight,
          noPosition: t.noPosition
        };
}

function removeTopLeft(t) {
  return {
          topLeft: undefined,
          bottomLeft: t.bottomLeft,
          bottomRight: t.bottomRight,
          noPosition: t.noPosition
        };
}

function addTimeSticker(t, str) {
  return {
          topLeft: t.topLeft,
          bottomLeft: t.bottomLeft,
          bottomRight: {
            NAME: "Time",
            VAL: str
          },
          noPosition: t.noPosition
        };
}

function makeFromCommonStickers(genreStickerBehaviorOpt, stickers) {
  var genreStickerBehavior = genreStickerBehaviorOpt !== undefined ? genreStickerBehaviorOpt : "HideGenreSticker";
  return Core__Array.reduce(stickers, empty, (function (acc, sticker) {
                var match = toCode(sticker.code);
                var match$1 = Type_String.NotEmpty.make(sticker.label);
                if (match === undefined) {
                  return acc;
                }
                if (match === "LAST_DAY") {
                  if (match$1 === undefined) {
                    return acc;
                  }
                  var match$2 = acc.topLeft;
                  if (match$2 !== undefined) {
                    if (typeof match$2 === "object") {
                      var variant = match$2.NAME;
                      if (variant === "Soon" || variant === "Live" || variant === "Direct") {
                        return acc;
                      }
                      
                    } else if (match$2 === "PrimeTime") {
                      return acc;
                    }
                    
                  }
                  return updateTopLeft(acc, {
                              NAME: "LastDay",
                              VAL: Caml_option.valFromOption(match$1)
                            });
                }
                if (match === "TV_LIVE") {
                  if (match$1 !== undefined) {
                    return updateTopLeft(acc, {
                                NAME: "Direct",
                                VAL: Caml_option.valFromOption(match$1)
                              });
                  } else {
                    return acc;
                  }
                }
                if (match === "LABEL_OVERRIDE_PLAY") {
                  if (match$1 !== undefined) {
                    return {
                            topLeft: acc.topLeft,
                            bottomLeft: acc.bottomLeft,
                            bottomRight: acc.bottomRight,
                            noPosition: {
                              NAME: "LabelOverride",
                              VAL: [
                                Caml_option.valFromOption(match$1),
                                "play"
                              ]
                            }
                          };
                  } else {
                    return acc;
                  }
                }
                if (match === "GENRE") {
                  if (genreStickerBehavior !== "DisplayGenreSticker") {
                    return acc;
                  }
                  if (match$1 === undefined) {
                    return acc;
                  }
                  var match$3 = acc.topLeft;
                  if (match$3 !== undefined) {
                    if (typeof match$3 === "object") {
                      var variant$1 = match$3.NAME;
                      if (variant$1 === "Soon" || variant$1 === "Live" || variant$1 === "LastDay" || variant$1 === "Direct") {
                        return acc;
                      }
                      
                    } else if (match$3 === "PrimeTime") {
                      return acc;
                    }
                    
                  }
                  return updateTopLeft(acc, {
                              NAME: "Genre",
                              VAL: Caml_option.valFromOption(match$1)
                            });
                }
                if (match === "FULL_VIDEO" || match === "PLAYABLE") {
                  if (match$1 !== undefined) {
                    return {
                            topLeft: acc.topLeft,
                            bottomLeft: acc.bottomLeft,
                            bottomRight: acc.bottomRight,
                            noPosition: {
                              NAME: "Playable",
                              VAL: Caml_option.valFromOption(match$1)
                            }
                          };
                  } else {
                    return acc;
                  }
                }
                if (match === "LABEL_OVERRIDE") {
                  if (match$1 !== undefined) {
                    return {
                            topLeft: acc.topLeft,
                            bottomLeft: acc.bottomLeft,
                            bottomRight: acc.bottomRight,
                            noPosition: {
                              NAME: "LabelOverride",
                              VAL: [
                                Caml_option.valFromOption(match$1),
                                "none"
                              ]
                            }
                          };
                  } else {
                    return acc;
                  }
                }
                if (match === "LIVE") {
                  if (match$1 === undefined) {
                    return acc;
                  }
                  var match$4 = acc.topLeft;
                  if (match$4 !== undefined && typeof match$4 === "object" && match$4.NAME === "Direct") {
                    return acc;
                  }
                  return updateTopLeft(acc, {
                              NAME: "Live",
                              VAL: Caml_option.valFromOption(match$1)
                            });
                }
                if (match === "SOON") {
                  if (match$1 === undefined) {
                    return acc;
                  }
                  var match$5 = acc.topLeft;
                  if (match$5 !== undefined) {
                    if (typeof match$5 === "object") {
                      var variant$2 = match$5.NAME;
                      if (variant$2 === "Live" || variant$2 === "Direct") {
                        return acc;
                      }
                      
                    } else if (match$5 === "PrimeTime") {
                      return acc;
                    }
                    
                  }
                  return updateTopLeft(acc, {
                              NAME: "Soon",
                              VAL: Caml_option.valFromOption(match$1)
                            });
                }
                var match$6 = acc.topLeft;
                if (match$6 === undefined) {
                  return updateTopLeft(acc, "PrimeTime");
                }
                if (typeof match$6 !== "object") {
                  return updateTopLeft(acc, "PrimeTime");
                }
                var variant$3 = match$6.NAME;
                if (variant$3 === "Live" || variant$3 === "Direct") {
                  return acc;
                } else {
                  return updateTopLeft(acc, "PrimeTime");
                }
              }));
}

function toArray(t) {
  var topLeft = Core__Option.map(t.topLeft, (function (sticker) {
          return sticker;
        }));
  var bottomLeft = Core__Option.map(t.bottomLeft, (function (sticker) {
          return sticker;
        }));
  var bottomRight = Core__Option.map(t.bottomRight, (function (sticker) {
          return sticker;
        }));
  var noPosition = Core__Option.map(t.noPosition, (function (sticker) {
          return sticker;
        }));
  return Util_Array.catOption([
              topLeft,
              bottomLeft,
              bottomRight,
              noPosition
            ]);
}

function getIds(t) {
  var topLeft = Core__Option.map(t.topLeft, (function (param) {
          return "topLeft";
        }));
  var bottomLeft = Core__Option.map(t.bottomLeft, (function (param) {
          return "bottomLeft";
        }));
  var bottomRight = Core__Option.map(t.bottomRight, (function (param) {
          return "bottomRight";
        }));
  return Util_Array.catOption([
              topLeft,
              bottomLeft,
              bottomRight
            ]);
}

function getLabels(t, toBeContinuedLabel) {
  return toArray(t).map(function (sticker) {
              if (typeof sticker !== "object") {
                if (sticker === "ToBeContinued") {
                  return toBeContinuedLabel;
                } else {
                  return ;
                }
              }
              var variant = sticker.NAME;
              if (variant === "LabelOverride") {
                return Type_String.NotEmpty.toString(sticker.VAL[0]);
              } else if (variant === "Time") {
                return sticker.VAL;
              } else {
                return Type_String.NotEmpty.toString(sticker.VAL);
              }
            });
}

function getBottomRight(t) {
  return Core__Option.map(t.bottomRight, (function (sticker) {
                return sticker;
              }));
}

function getTopLeft(t) {
  return Core__Option.map(t.topLeft, (function (sticker) {
                return sticker;
              }));
}

export {
  time_encode ,
  time_decode ,
  icon_encode ,
  icon_decode ,
  label_encode ,
  label_decode ,
  stickers_encode ,
  stickers_decode ,
  toCode ,
  empty ,
  updateTopLeft ,
  removeTopLeft ,
  addTimeSticker ,
  makeFromCommonStickers ,
  toArray ,
  getIds ,
  getLabels ,
  getBottomRight ,
  getTopLeft ,
}
/* No side effect */
