// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as SetFocus from "../../../hook/SetFocus.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_TopTeaser from "../../molecule/Molecule_TopTeaser.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function topTeaser(categoryOpt, param) {
  var category = categoryOpt !== undefined ? categoryOpt : "Default";
  return {
          arrows: undefined,
          bullet: {
            color: category,
            onClick: (function () {
                SetFocus.ById.make(Molecule_TopTeaser.playButtonId);
              })
          },
          mode: {
            TAG: "Automatic",
            _0: {
              delay: 6000
            }
          },
          width: "Full"
        };
}

var papa_arrows = [
  Atom_Cta_Ds.Carousel.previousArrow,
  Atom_Cta_Ds.Carousel.nextArrow
];

var papa = {
  arrows: papa_arrows,
  bullet: undefined,
  mode: "Manual",
  width: "FitContent"
};

var corporate_arrows = [
  Atom_Cta_Ds.Carousel.previousArrow,
  Atom_Cta_Ds.Carousel.nextArrow
];

var corporate = {
  arrows: corporate_arrows,
  bullet: undefined,
  mode: "Manual",
  width: "FitContent"
};

function getArrowWidth(arrow) {
  return Atom_Cta_Ds.getPredefinedWidth(arrow);
}

function getArrowHeight(arrow) {
  return Atom_Cta_Ds.getPredefinedHeight(arrow);
}

function view(t) {
  return t;
}

var Organism_Carousel_Ds = {
  topTeaser: topTeaser,
  papa: papa,
  corporate: corporate,
  getArrowWidth: getArrowWidth,
  getArrowHeight: getArrowHeight,
  view: view
};

function reducer(state, action) {
  if (typeof action === "object") {
    return {
            index: action._0,
            playMode: state.playMode,
            max: state.max,
            lastEvent: "BulletClicked"
          };
  }
  switch (action) {
    case "Play" :
        return {
                index: state.index,
                playMode: {
                  TAG: "Auto",
                  _0: "Playing"
                },
                max: state.max,
                lastEvent: state.lastEvent
              };
    case "Next" :
        return {
                index: (state.index + 1 | 0) === state.max ? 0 : state.index + 1 | 0,
                playMode: state.playMode,
                max: state.max,
                lastEvent: state.lastEvent
              };
    case "Previous" :
        return {
                index: (state.index - 1 | 0) < 0 ? state.max - 1 | 0 : state.index - 1 | 0,
                playMode: state.playMode,
                max: state.max,
                lastEvent: state.lastEvent
              };
    case "AutoPause" :
        return {
                index: state.index,
                playMode: {
                  TAG: "Auto",
                  _0: "Paused"
                },
                max: state.max,
                lastEvent: state.lastEvent
              };
    case "ManualPause" :
        return {
                index: state.index,
                playMode: {
                  TAG: "Manual",
                  _0: "Paused"
                },
                max: state.max,
                lastEvent: state.lastEvent
              };
    case "SetFocused" :
        return {
                index: state.index,
                playMode: state.playMode,
                max: state.max,
                lastEvent: "Idle"
              };
    
  }
}

function Organism_Carousel$Slide(props) {
  var index = props.index;
  var count = props.count;
  var isActive = props.isActive;
  if (isActive === undefined) {
    return null;
  }
  if (!isActive) {
    return null;
  }
  if (index === undefined) {
    return null;
  }
  if (count === undefined) {
    return null;
  }
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, String(index + 1 | 0) + ("/" + String(count)), undefined, undefined, undefined, undefined, undefined, "group", undefined);
  return JsxRuntime.jsx(Atom_Cell.make, {
              accessibility: accessibility,
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              children: props.children
            });
}

var Slide = {
  make: Organism_Carousel$Slide
};

function Organism_Carousel$Bullet(props) {
  var isActive = props.isActive;
  var label = props.label;
  var accessibility = isActive ? Particle_Accessibility.make(undefined, "true", undefined, undefined, undefined, undefined, label, undefined, undefined, undefined, undefined, undefined, undefined, undefined) : Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, label, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var style = isActive ? Atom_Cta_Ds.Carousel.makeBulletActive(props.color) : Atom_Cta_Ds.Carousel.bulletInactive;
  return JsxRuntime.jsx(Atom_Cta.Button.make, {
              accessibility: accessibility,
              onClick: props.onClick,
              style: style
            });
}

var Bullet = {
  make: Organism_Carousel$Bullet
};

var arrowZIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "CarouselArrow"
    });

var baseRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.position("absolute"),
      arrowZIndexRule
    ]);

function getWidth(direction, x) {
  return [direction(CssJs.px(x / -2 | 0))];
}

function getHeight(x) {
  return [CssJs.top((function (__x) {
                    return Css_Js_Core.Calc.$neg(Css.pct(50), __x);
                  })(CssJs.px(x / 2 | 0)))];
}

var bulletZIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "CarouselBullet"
    });

var bulletWrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      bulletZIndexRule,
      CssJs.width(CssJs.pct(100))
    ]);

var blockSpaceBullets = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("Zero", "L", "Zero", "Zero"), undefined, undefined, Particle_Spacer_Ds.makeVertical("M"));

function make(param) {
  var onSlideChange = param.onSlideChange;
  var kind = param.kind;
  var children = param.children;
  var count = React.Children.count(children);
  var id = param.id + "_carousel";
  var match = Context.I18n.use();
  var labels = match.labels;
  var mode = kind.mode;
  var bullet = kind.bullet;
  var match$1 = labels.teasers.carousel;
  var pauseLabel = match$1.pause;
  var goToSlideLabel = match$1.goToSlide;
  var match$2 = labels.teasers;
  var match$3 = React.useReducer(reducer, {
        index: 0,
        playMode: {
          TAG: "Auto",
          _0: "Playing"
        },
        max: count,
        lastEvent: "Init"
      });
  var dispatch = match$3[1];
  var state = match$3[0];
  var match$4 = Core__Option.getOr(Core__Option.map(kind.arrows, (function (param) {
              var next = param[1];
              var prev = param[0];
              var prevWidth = Particle_Screen_Ds.map(Particle_Screen_Ds.map(Atom_Cta_Ds.getPredefinedWidth(prev), (function (__x) {
                          return Core__Option.getOr(__x, 0);
                        })), (function (__x) {
                      return getWidth(CssJs.left, __x);
                    }));
              var prevTop = Particle_Screen_Ds.map(Particle_Screen_Ds.map(Atom_Cta_Ds.getPredefinedHeight(next), (function (__x) {
                          return Core__Option.getOr(__x, 0);
                        })), getHeight);
              var prevRules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(prevWidth, prevTop), baseRules);
              var nextWidth = Particle_Screen_Ds.map(Particle_Screen_Ds.map(Atom_Cta_Ds.getPredefinedWidth(next), (function (__x) {
                          return Core__Option.getOr(__x, 0);
                        })), (function (__x) {
                      return getWidth(CssJs.right, __x);
                    }));
              var nextTop = Particle_Screen_Ds.map(Particle_Screen_Ds.map(Atom_Cta_Ds.getPredefinedHeight(next), (function (__x) {
                          return Core__Option.getOr(__x, 0);
                        })), getHeight);
              var nextRules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(nextTop, nextWidth), baseRules);
              return [
                      JsxRuntime.jsx(Atom_Cell.make, {
                            children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                                  onClick: (function (param) {
                                      dispatch("Previous");
                                    }),
                                  style: prev
                                }),
                            rules: Caml_option.some(prevRules)
                          }),
                      JsxRuntime.jsx(Atom_Cell.make, {
                            children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                                  onClick: (function (param) {
                                      dispatch("Next");
                                    }),
                                  style: next
                                }),
                            rules: Caml_option.some(nextRules)
                          })
                    ];
            })), [
        null,
        null
      ]);
  React.useEffect((function () {
          var match = state.playMode;
          if (typeof mode !== "object") {
            return ;
          }
          if (match.TAG === "Manual") {
            return ;
          }
          if (match._0 !== "Playing") {
            return ;
          }
          var timerID = setTimeout((function () {
                  dispatch("Next");
                  if (onSlideChange !== undefined) {
                    return onSlideChange();
                  }
                  
                }), mode._0.delay);
          return (function () {
                    clearTimeout(timerID);
                  });
        }), [
        state.playMode,
        state.index
      ]);
  React.useEffect((function () {
          var mount = {
            contents: true
          };
          var match = state.lastEvent;
          var match$1 = mount.contents;
          switch (match) {
            case "Idle" :
            case "Init" :
                break;
            case "BulletClicked" :
                if (bullet !== undefined) {
                  var onBulletClick = bullet.onClick;
                  if (onBulletClick !== undefined && match$1) {
                    onBulletClick();
                    dispatch("SetFocused");
                  }
                  
                }
                break;
            
          }
          return (function () {
                    mount.contents = false;
                  });
        }), [
        state.lastEvent,
        bullet
      ]);
  var slides = React.Children.map(children, (function (child, index) {
          return JsxRuntime.jsx(Organism_Carousel$Slide, {
                      children: child,
                      isActive: state.index === index,
                      count: count,
                      index: index
                    }, index.toString());
        }));
  var handleUserPlayPause = function (param) {
    var match = state.playMode;
    if (match.TAG === "Manual") {
      if (match._0 === "Playing") {
        return dispatch("ManualPause");
      } else {
        return dispatch("Play");
      }
    } else if (match._0 === "Playing") {
      return dispatch("ManualPause");
    } else {
      return dispatch("Play");
    }
  };
  var handleAutoPause = function (param) {
    var match = state.playMode;
    if (match.TAG === "Manual") {
      if (match._0 === "Playing") {
        return dispatch("AutoPause");
      } else {
        return ;
      }
    } else if (match._0 === "Playing") {
      return dispatch("AutoPause");
    } else {
      return ;
    }
  };
  var handlePlay = function (param) {
    var match = state.playMode;
    if (match.TAG === "Manual" && match._0 !== "Playing") {
      return ;
    } else {
      return dispatch("Play");
    }
  };
  var match$5 = state.playMode;
  var match$6;
  match$6 = match$5.TAG === "Manual" && match$5._0 !== "Playing" ? [
      match$2.watch,
      Atom_Cta_Ds.Carousel.controlPlay
    ] : [
      pauseLabel,
      Atom_Cta_Ds.Carousel.controlPause
    ];
  var containerWidth;
  containerWidth = kind.width === "Full" ? Particle_Screen_Constants.oneHundredPctWidth : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Css.fitContent);
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, param.label, undefined, undefined, undefined, "carousel", undefined, undefined, undefined);
  var tmp;
  if (count !== 1) {
    var tmp$1;
    if (bullet !== undefined) {
      var color = bullet.color;
      tmp$1 = React.Children.map(children, (function (param, index) {
              return JsxRuntime.jsx(Organism_Carousel$Bullet, {
                          onClick: (function (param) {
                              dispatch({
                                    TAG: "GoTo",
                                    _0: index
                                  });
                            }),
                          label: goToSlideLabel + (" " + String(index + 1 | 0)),
                          isActive: state.index === index,
                          color: color
                        }, id + index.toString());
            }));
    } else {
      tmp$1 = null;
    }
    var tmp$2;
    if (typeof mode !== "object") {
      tmp$2 = null;
    } else {
      var accessibility$1 = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, match$6[0], undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      tmp$2 = JsxRuntime.jsx(Atom_Cta.Button.make, {
            accessibility: accessibility$1,
            onClick: handleUserPlayPause,
            style: match$6[1]
          });
    }
    tmp = JsxRuntime.jsxs(Atom_Row.make, {
          alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
          blockSpace: Caml_option.some(blockSpaceBullets),
          children: [
            tmp$1,
            tmp$2
          ],
          innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
          justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
          rules: Caml_option.some(bulletWrapperRules)
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Column.make, {
                        accessibility: accessibility,
                        blockSpace: param.blockSpace,
                        cbRef: param.carouselRef,
                        children: [
                          JsxRuntime.jsxs(Atom_Cell.make, {
                                autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                                children: [
                                  count !== 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            match$4[1],
                                            match$4[0]
                                          ]
                                        }) : null,
                                  slides
                                ],
                                onFocus: handleAutoPause,
                                onBlur: handlePlay,
                                onMouseEnter: handleAutoPause,
                                onMouseLeave: handlePlay,
                                position: Caml_option.some(Particle_Screen_Constants.positionRelative)
                              }),
                          tmp
                        ],
                        display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")),
                        id: id,
                        identifier: param.identifier,
                        width: Caml_option.some(containerWidth)
                      }))
            });
}

export {
  Organism_Carousel_Ds ,
  reducer ,
  Slide ,
  Bullet ,
  arrowZIndexRule ,
  baseRules ,
  getWidth ,
  getHeight ,
  bulletZIndexRule ,
  bulletWrapperRules ,
  blockSpaceBullets ,
  make ,
}
/* arrowZIndexRule Not a pure module */
