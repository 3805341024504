// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Util_Dom from "../../../util/Util_Dom.res.mjs";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "../../../type/Type_Sticker.res.mjs";
import * as Atom_Image_Ds from "../../atom/Atom_Image_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";

function getLabelFromLabelledElements(element) {
  return element;
}

var screenReadersLabelSeparator = " ";

function getScreenReadersLabel(progression, continueWatchingLabel, playLabel, watchAgainLabel, progressFormater, toBeContinuedLabel, teaser) {
  var stickers = Type_Sticker.getLabels(teaser.stickers, toBeContinuedLabel);
  var match = progression !== undefined ? (
      progression >= 100 ? [
          watchAgainLabel,
          Core__Option.map(progressFormater, (function (formater) {
                  return formater(progression);
                }))
        ] : (
          progression > 0 ? [
              continueWatchingLabel,
              Core__Option.map(progressFormater, (function (formater) {
                      return formater(progression);
                    }))
            ] : [
              playLabel,
              undefined
            ]
        )
    ) : [
      playLabel,
      undefined
    ];
  var subtitle = Core__Option.map(Type_Teaser.Helper.extractSubtitleString(teaser.subtitle), Type_String.Teaser.Subtitle.toString);
  var title = Core__Option.map(Type_Teaser.Helper.extractTitle(teaser), Type_String.Teaser.Title.toString);
  var labels = [
      match[0],
      title,
      subtitle,
      match[1]
    ].concat(stickers);
  var labels$1 = Util_Array.catOption(labels);
  if (labels$1.length !== 0) {
    return labels$1.join(screenReadersLabelSeparator);
  }
  
}

function toPx(value) {
  return CssJs.px(value | 0);
}

function getWidthByImageSize(imageSize) {
  if (typeof imageSize !== "object") {
    return 0;
  } else if (imageSize.TAG === "Fixed") {
    return imageSize._0.width;
  } else {
    return 0;
  }
}

function getHeightByImageSize(imageSize) {
  if (typeof imageSize !== "object") {
    return 0;
  } else if (imageSize.TAG === "Fixed") {
    return imageSize._0.height;
  } else {
    return 0;
  }
}

function getLeftRules(hoverImageSize, width, left) {
  return Particle_Screen_Ds.map(hoverImageSize, (function (imageSize) {
                var hoverWidth = getWidthByImageSize(imageSize);
                var extraSpace = (hoverWidth - width) / 2;
                var leftValue = left - extraSpace;
                return [CssJs.left(CssJs.px(leftValue | 0))];
              }));
}

function getTopRules(hoverImageMinHeight, height, top, scrollY) {
  return Particle_Screen_Ds.map(hoverImageMinHeight, (function (value) {
                var extraSpace = (value - height) / 2;
                var topValue = top + scrollY - extraSpace;
                return [CssJs.top(CssJs.px(topValue | 0))];
              }));
}

function getPanelHoverPositionRules(centerHoverRef, pictureKind, hoverImageMinHeight, hoverMode) {
  var teaserSize = Util_Dom.getElementSize(Util_Dom.getDomRef(centerHoverRef));
  var scrollY = window.scrollY;
  var match = Atom_Image_Ds.view(Atom_Image_Ds.make(pictureKind));
  var leftRules = getLeftRules(match.display, teaserSize.width, teaserSize.left);
  var topRules = getTopRules(hoverImageMinHeight, teaserSize.height, teaserSize.top, scrollY);
  switch (hoverMode.TAG) {
    case "Dialog" :
    case "MouseHover" :
        break;
    case "MobileHover" :
        return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                    CssJs.position("fixed"),
                    CssJs.top(CssJs.pct(50)),
                    CssJs.left(CssJs.pct(50))
                  ]);
    
  }
  return Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position("absolute")]), leftRules), topRules);
}

var Hover = {
  toPx: toPx,
  getWidthByImageSize: getWidthByImageSize,
  getHeightByImageSize: getHeightByImageSize,
  getLeftRules: getLeftRules,
  getTopRules: getTopRules,
  getPanelHoverPositionRules: getPanelHoverPositionRules
};

export {
  getLabelFromLabelledElements ,
  screenReadersLabelSeparator ,
  getScreenReadersLabel ,
  Hover ,
}
/* CssJs Not a pure module */
