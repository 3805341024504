import type { ApiBody, LoginStatus } from '@replay/types/Tracking';

export const validateAuthType = (queryAuthType: LoginStatus | undefined): ApiBody['client']['user']['type'] => {
    if (!queryAuthType) {
        return 'FEATURE_DISABLED';
    }
    switch (queryAuthType) {
        case 'NotReady':
            return 'FEATURE_DISABLED';
        case 'Disconnected':
            return 'FEATURE_DISABLED';
        case 'Anonymous':
            return 'ANONYMOUS';
        case 'Connected':
            return 'AUTHENTICATED';
        default:
            return 'FEATURE_DISABLED';
    }
};

export const validateConsent = (queryConsent: string | undefined): ApiBody['client']['consent']['audience'] => {
    if (!queryConsent) {
        return false;
    }
    switch (queryConsent) {
        case 'on':
            return true;
        case 'off':
            return false;
        default:
            return false;
    }
};

export const validateUtmQueryParam = (queryUtm: string | null): string | undefined => {
    if (!queryUtm || queryUtm === '') {
        return undefined;
    }
    return queryUtm;
};
