// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Js_json from "@rescript/std/lib/es6/js_json.js";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Type_String from "./Type_String.res.mjs";

var _map = {"PRE":"PRE","AUT":"AUT","AR":"A.R","SCE":"SCE","DIA":"DIA","ADA":"ADA","COM":"COM","CA":"C.A","ARR":"ARR","EDI":"EDI","EOP":"EOP","PRO":"PRO","DIS":"DIS","REA":"REA","MEC":"MEC","ACT":"ACT","GRA":"GRA","INT":"INT","CdotO":"C.O","CHO":"CHO","ANI":"ANI","ITW":"ITW","ITX":"ITX","TRA":"TRA","UNK":"UNK","AT":"AT","CD":"CD","DG":"DG","DW":"DW","FA":"FA","PH":"PH","SON":"SON","IMA":"IMA","MON":"MON","MUS":"MUS","MAQ":"MAQ","DEC":"DEC","RED":"RED","PRD":"PRD","CPD":"CPD","SOL":"SOL","INV":"INV","CO":"CO","RTV":"RTV","REP":"REP","LUM":"LUM","DER":"DER","MEN":"MEN","CHA":"CHA","CHS":"CHS","LIB":"LIB","DRA":"DRA","DAN":"DAN","ORC":"ORC","VID":"VID","CCH":"CCH","DPR":"DPR","CDD":"CDD","CHE":"CHE","MA":"MA","RES":"RES","COP":"COP","CPW":"CPW","CM":"CM","DEV":"DEV","DA":"DA","I3D":"I3D","GW":"GW","IG":"IG","JOU":"JOU","DOC":"DOC","CPT":"CPT","DS":"DS","DEB":"DEB","SUP":"SUP","WD":"WD","IW":"IW"};

var _revMap = {"PRE":"PRE","AUT":"AUT","A.R":"AR","SCE":"SCE","DIA":"DIA","ADA":"ADA","COM":"COM","C.A":"CA","ARR":"ARR","EDI":"EDI","EOP":"EOP","PRO":"PRO","DIS":"DIS","REA":"REA","MEC":"MEC","ACT":"ACT","GRA":"GRA","INT":"INT","C.O":"CdotO","CHO":"CHO","ANI":"ANI","ITW":"ITW","ITX":"ITX","TRA":"TRA","UNK":"UNK","AT":"AT","CD":"CD","DG":"DG","DW":"DW","FA":"FA","PH":"PH","SON":"SON","IMA":"IMA","MON":"MON","MUS":"MUS","MAQ":"MAQ","DEC":"DEC","RED":"RED","PRD":"PRD","CPD":"CPD","SOL":"SOL","INV":"INV","CO":"CO","RTV":"RTV","REP":"REP","LUM":"LUM","DER":"DER","MEN":"MEN","CHA":"CHA","CHS":"CHS","LIB":"LIB","DRA":"DRA","DAN":"DAN","ORC":"ORC","VID":"VID","CCH":"CCH","DPR":"DPR","CDD":"CDD","CHE":"CHE","MA":"MA","RES":"RES","COP":"COP","CPW":"CPW","CM":"CM","DEV":"DEV","DA":"DA","I3D":"I3D","GW":"GW","IG":"IG","JOU":"JOU","DOC":"DOC","CPT":"CPT","DS":"DS","DEB":"DEB","SUP":"SUP","WD":"WD","IW":"IW"};

function creditCodeToJs(param) {
  return _map[param];
}

function creditCodeFromJs(param) {
  return _revMap[param];
}

function creditCode_encode(v) {
  if (v === "AR") {
    return ["AR"];
  } else if (v === "AT") {
    return ["AT"];
  } else if (v === "CA") {
    return ["CA"];
  } else if (v === "CD") {
    return ["CD"];
  } else if (v === "CM") {
    return ["CM"];
  } else if (v === "CO") {
    return ["CO"];
  } else if (v === "DA") {
    return ["DA"];
  } else if (v === "DG") {
    return ["DG"];
  } else if (v === "DS") {
    return ["DS"];
  } else if (v === "DW") {
    return ["DW"];
  } else if (v === "FA") {
    return ["FA"];
  } else if (v === "GW") {
    return ["GW"];
  } else if (v === "IG") {
    return ["IG"];
  } else if (v === "IW") {
    return ["IW"];
  } else if (v === "MA") {
    return ["MA"];
  } else if (v === "PH") {
    return ["PH"];
  } else if (v === "WD") {
    return ["WD"];
  } else if (v === "ACT") {
    return ["ACT"];
  } else if (v === "ADA") {
    return ["ADA"];
  } else if (v === "ANI") {
    return ["ANI"];
  } else if (v === "ARR") {
    return ["ARR"];
  } else if (v === "AUT") {
    return ["AUT"];
  } else if (v === "CCH") {
    return ["CCH"];
  } else if (v === "CDD") {
    return ["CDD"];
  } else if (v === "CHA") {
    return ["CHA"];
  } else if (v === "CHE") {
    return ["CHE"];
  } else if (v === "CHO") {
    return ["CHO"];
  } else if (v === "CHS") {
    return ["CHS"];
  } else if (v === "COM") {
    return ["COM"];
  } else if (v === "COP") {
    return ["COP"];
  } else if (v === "CPD") {
    return ["CPD"];
  } else if (v === "CPT") {
    return ["CPT"];
  } else if (v === "CPW") {
    return ["CPW"];
  } else if (v === "DAN") {
    return ["DAN"];
  } else if (v === "DEB") {
    return ["DEB"];
  } else if (v === "DEC") {
    return ["DEC"];
  } else if (v === "DER") {
    return ["DER"];
  } else if (v === "DEV") {
    return ["DEV"];
  } else if (v === "DIA") {
    return ["DIA"];
  } else if (v === "DIS") {
    return ["DIS"];
  } else if (v === "DOC") {
    return ["DOC"];
  } else if (v === "DPR") {
    return ["DPR"];
  } else if (v === "DRA") {
    return ["DRA"];
  } else if (v === "EDI") {
    return ["EDI"];
  } else if (v === "EOP") {
    return ["EOP"];
  } else if (v === "GRA") {
    return ["GRA"];
  } else if (v === "I3D") {
    return ["I3D"];
  } else if (v === "IMA") {
    return ["IMA"];
  } else if (v === "INT") {
    return ["INT"];
  } else if (v === "INV") {
    return ["INV"];
  } else if (v === "ITW") {
    return ["ITW"];
  } else if (v === "ITX") {
    return ["ITX"];
  } else if (v === "JOU") {
    return ["JOU"];
  } else if (v === "LIB") {
    return ["LIB"];
  } else if (v === "LUM") {
    return ["LUM"];
  } else if (v === "MAQ") {
    return ["MAQ"];
  } else if (v === "MEC") {
    return ["MEC"];
  } else if (v === "MEN") {
    return ["MEN"];
  } else if (v === "MON") {
    return ["MON"];
  } else if (v === "MUS") {
    return ["MUS"];
  } else if (v === "ORC") {
    return ["ORC"];
  } else if (v === "PRD") {
    return ["PRD"];
  } else if (v === "PRE") {
    return ["PRE"];
  } else if (v === "PRO") {
    return ["PRO"];
  } else if (v === "REA") {
    return ["REA"];
  } else if (v === "RED") {
    return ["RED"];
  } else if (v === "REP") {
    return ["REP"];
  } else if (v === "RES") {
    return ["RES"];
  } else if (v === "RTV") {
    return ["RTV"];
  } else if (v === "SCE") {
    return ["SCE"];
  } else if (v === "SOL") {
    return ["SOL"];
  } else if (v === "SON") {
    return ["SON"];
  } else if (v === "SUP") {
    return ["SUP"];
  } else if (v === "TRA") {
    return ["TRA"];
  } else if (v === "UNK") {
    return ["UNK"];
  } else if (v === "VID") {
    return ["VID"];
  } else {
    return ["CdotO"];
  }
}

function creditCode_decode(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "ACT" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ACT"
                  };
          }
      case "ADA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ADA"
                  };
          }
      case "ANI" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ANI"
                  };
          }
      case "AR" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "AR"
                  };
          }
      case "ARR" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ARR"
                  };
          }
      case "AT" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "AT"
                  };
          }
      case "AUT" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "AUT"
                  };
          }
      case "CA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CA"
                  };
          }
      case "CCH" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CCH"
                  };
          }
      case "CD" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CD"
                  };
          }
      case "CDD" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CDD"
                  };
          }
      case "CHA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CHA"
                  };
          }
      case "CHE" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CHE"
                  };
          }
      case "CHO" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CHO"
                  };
          }
      case "CHS" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CHS"
                  };
          }
      case "CM" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CM"
                  };
          }
      case "CO" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CO"
                  };
          }
      case "COM" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "COM"
                  };
          }
      case "COP" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "COP"
                  };
          }
      case "CPD" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CPD"
                  };
          }
      case "CPT" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CPT"
                  };
          }
      case "CPW" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CPW"
                  };
          }
      case "CdotO" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "CdotO"
                  };
          }
      case "DA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DA"
                  };
          }
      case "DAN" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DAN"
                  };
          }
      case "DEB" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DEB"
                  };
          }
      case "DEC" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DEC"
                  };
          }
      case "DER" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DER"
                  };
          }
      case "DEV" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DEV"
                  };
          }
      case "DG" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DG"
                  };
          }
      case "DIA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DIA"
                  };
          }
      case "DIS" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DIS"
                  };
          }
      case "DOC" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DOC"
                  };
          }
      case "DPR" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DPR"
                  };
          }
      case "DRA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DRA"
                  };
          }
      case "DS" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DS"
                  };
          }
      case "DW" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "DW"
                  };
          }
      case "EDI" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EDI"
                  };
          }
      case "EOP" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EOP"
                  };
          }
      case "FA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "FA"
                  };
          }
      case "GRA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "GRA"
                  };
          }
      case "GW" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "GW"
                  };
          }
      case "I3D" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "I3D"
                  };
          }
      case "IG" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "IG"
                  };
          }
      case "IMA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "IMA"
                  };
          }
      case "INT" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "INT"
                  };
          }
      case "INV" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "INV"
                  };
          }
      case "ITW" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ITW"
                  };
          }
      case "ITX" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ITX"
                  };
          }
      case "IW" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "IW"
                  };
          }
      case "JOU" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "JOU"
                  };
          }
      case "LIB" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "LIB"
                  };
          }
      case "LUM" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "LUM"
                  };
          }
      case "MA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MA"
                  };
          }
      case "MAQ" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MAQ"
                  };
          }
      case "MEC" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MEC"
                  };
          }
      case "MEN" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MEN"
                  };
          }
      case "MON" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MON"
                  };
          }
      case "MUS" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MUS"
                  };
          }
      case "ORC" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "ORC"
                  };
          }
      case "PH" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "PH"
                  };
          }
      case "PRD" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "PRD"
                  };
          }
      case "PRE" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "PRE"
                  };
          }
      case "PRO" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "PRO"
                  };
          }
      case "REA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "REA"
                  };
          }
      case "RED" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "RED"
                  };
          }
      case "REP" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "REP"
                  };
          }
      case "RES" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "RES"
                  };
          }
      case "RTV" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "RTV"
                  };
          }
      case "SCE" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "SCE"
                  };
          }
      case "SOL" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "SOL"
                  };
          }
      case "SON" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "SON"
                  };
          }
      case "SUP" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "SUP"
                  };
          }
      case "TRA" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "TRA"
                  };
          }
      case "UNK" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "UNK"
                  };
          }
      case "VID" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "VID"
                  };
          }
      case "WD" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "WD"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function credit_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    creditCode_encode(v.code)
                  ],
                  [
                    "label",
                    false,
                    Type_String.Credit.Label.t_encode(v.label)
                  ],
                  [
                    "values",
                    false,
                    (function (extra) {
                          return Spice.arrayToJson(Type_String.Credit.Value.t_encode, extra);
                        })(v.values)
                  ]
                ]));
}

function credit_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = creditCode_decode(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var label = Type_String.Credit.Label.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      var values = (function (extra) {
            return Spice.arrayFromJson(Type_String.Credit.Value.t_decode, extra);
          })(Belt_Option.getWithDefault(Js_dict.get(v, "values"), null));
      if (values.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  code: code._0,
                  label: label._0,
                  values: values._0
                }
              };
      }
      var e = values._0;
      return {
              TAG: "Error",
              _0: {
                path: ".values" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function credits_encode(v) {
  return Spice.arrayToJson(credit_encode, v);
}

function credits_decode(v) {
  return Spice.arrayFromJson(credit_decode, v);
}

export {
  creditCodeToJs ,
  creditCodeFromJs ,
  creditCode_encode ,
  creditCode_decode ,
  credit_encode ,
  credit_decode ,
  credits_encode ,
  credits_decode ,
}
/* No side effect */
