import { Header, HeaderConcert } from '@artegeie/design-system/replay';
import { isFrLocale } from '@replay/i18n/types/locale';
import { type AlternativeLanguage } from '@replay/types/AlternativeLanguage';
import { useRouter } from 'next/router';
import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { env } from '@/env';
import { SearchGenre, useSearch } from './SearchContext';
import { useIsSafari11orBelow } from './useIsSafari11orBelow';
import { useAuthStore } from './useRedirect';

type Header_Props = Parameters<typeof Header>[0];

const sidactionOneTimeEvent = {
    imageSrc: 'https://static-cdn.arte.tv/static/styleguide/latest/img/icons/sidaaction.svg',
    redirectLink: {
        id: 'sidaction',
        label: 'Sidaction',
        href: 'https://www.sidaction.org/',
        kind: 'external',
    },
} as const;

const resolveDomainWithUrl = (url: string, parentUrl: string | undefined): 'concert' | 'replay' => {
    if (url.includes('arte-concert') || parentUrl?.split('/')[2] === 'arte-concert') {
        return 'concert';
    }
    return 'replay';
};

const getOneTimeEvent = (ffSidaction: boolean, locale: string | undefined) => {
    if (ffSidaction && isFrLocale(locale)) {
        return sidactionOneTimeEvent;
    }
    return undefined;
};
type DisplayedSubHeaders = NonNullable<React.ComponentProps<typeof Header>['subHeadersToDisplay']>;

export type subheader = DisplayedSubHeaders[number];
const isSubheader = (subheaderKey: string): subheaderKey is subheader => {
    const subheaders: DisplayedSubHeaders = ['Fsk', 'Welcome'];
    return subheaders.includes(subheaderKey as subheader);
};

export const Navbar = ({
    alternativeLanguages,
    parentUrl,
}: {
    alternativeLanguages: AlternativeLanguage[];
    parentUrl?: string;
}): ReactElement => {
    const { t: translate } = useTranslation();
    const { locale, pathname } = useRouter();

    const { subheaders, removeSubheader, addSubheader } = useAuthStore();
    const isSafari11orBelow = useIsSafari11orBelow();
    // Add a warning subheader for Safari 11 and below
    // if message was dismissed by the user, show it again
    if (isSafari11orBelow && !subheaders.includes('Saf11Warning')) {
        addSubheader('Saf11Warning');
    }
    const { searchBarStatus, handleOpenSearch, handleCloseSearch, setSearchTerm, searchTerm, setSearchGenre } =
        useSearch();
    const oneTimeEvent = getOneTimeEvent(env.NEXT_PUBLIC_FEATURE_FLAGS_SIDACTION, locale);
    const headerMode = resolveDomainWithUrl(pathname, parentUrl);
    const onOpenSearch = () => {
        if (headerMode === 'concert') {
            setSearchGenre(SearchGenre.CONCERTS);
        }
        handleOpenSearch();
    };
    const enabledSubHeaders = env.NEXT_PUBLIC_FEATURE_FLAGS_DISPLAYED_SUBHEADERS?.filter(isSubheader);

    const search: Header_Props['search'] = {
        initialQuery: searchTerm,
        debounce: 300,
        onChange: setSearchTerm,
        onOpen: onOpenSearch,
        placeholder: translate ? translate('search.placeholder') : '',
        onClose: handleCloseSearch,
        isOpen: searchBarStatus === 'open',
        searchBarStatus: 'forced',
    };

    switch (headerMode) {
        case 'concert':
            return (
                <>
                    <HeaderConcert
                        requiredConsentSubHeaders={enabledSubHeaders as DisplayedSubHeaders}
                        subHeadersToDisplay={subheaders}
                        languages={alternativeLanguages}
                        oneTimeEvent={oneTimeEvent}
                        search={search}
                        onCloseSubHeader={(id) => {
                            removeSubheader(id);
                        }}
                    />
                </>
            );
        case 'replay':
        default:
            return (
                <Header
                    requiredConsentSubHeaders={enabledSubHeaders as DisplayedSubHeaders}
                    subHeadersToDisplay={subheaders}
                    languages={alternativeLanguages}
                    oneTimeEvent={oneTimeEvent}
                    search={search}
                    onCloseSubHeader={(id) => {
                        removeSubheader(id);
                    }}
                />
            );
    }
};
