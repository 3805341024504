'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Atom_Cta from "../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../design-system/atom/Atom_Cell.res.mjs";
import * as Type_Image from "../type/Type_Image.res.mjs";
import * as UseVisible from "./UseVisible.res.mjs";
import * as Atom_Cta_Ds from "../design-system/atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../selector/Identifiers.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseClickTracking from "./UseClickTracking.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as UseScreenResolution from "./UseScreenResolution.res.mjs";
import * as LegacyStyleOverwrite from "../design-system/LegacyStyleOverwrite.res.mjs";
import * as Particle_Animation_Ds from "../design-system/particle/Particle_Animation_Ds.res.mjs";
import * as Particle_Screen_Constants from "../design-system/particle/Particle_Screen_Constants.res.mjs";

function isClip(video) {
  if (video.TAG === "Clip") {
    return true;
  } else {
    return false;
  }
}

function addLegacyRules(player) {
  return JsxRuntime.jsx(Atom_Cell.make, {
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
              children: player,
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, LegacyStyleOverwrite.Player.rules))
            });
}

function renderVideoFactory(createVideoElement, onPlayerReady, previewImage, playRef, pauseRef, isUserPaused, video) {
  var tmp;
  if (video !== undefined) {
    if (video.TAG === "Clip") {
      var clip = video._0;
      tmp = video._1 === "Standard" ? [
          "clip",
          Type_String.Program.Video.makeFromClip(clip)
        ] : [
          "preview",
          Type_String.Program.Video.makeFromClip(clip)
        ];
    } else {
      tmp = [
        "trailer",
        Type_String.Program.Video.makeFromTrailer(video._0)
      ];
    }
  } else {
    tmp = undefined;
  }
  return Core__Option.getOr(Core__Option.map(tmp, (function (param) {
                    var videoUrl = param[1];
                    var videoKind = param[0];
                    return function () {
                      return Caml_option.some(addLegacyRules(createVideoElement({
                                          playSetter: playRef,
                                          pauseSetter: pauseRef,
                                          isUserPaused: isUserPaused,
                                          videoKind: videoKind,
                                          videoUrl: videoUrl,
                                          onPlayerReady: onPlayerReady,
                                          previewImage: previewImage
                                        })));
                    };
                  })), (function () {
                
              }));
}

function getPlayerElement(t) {
  var state = t.state;
  if (state !== undefined) {
    return state.videoElement;
  }
  
}

function getBackgroundAnimation(t) {
  var state = t.state;
  if (state === undefined) {
    return ;
  }
  var match = state.videoElement;
  if (match !== undefined) {
    return Caml_option.some(Particle_Animation_Ds.Trailer.On.background);
  } else {
    return Caml_option.some(Particle_Animation_Ds.Trailer.initial);
  }
}

function make(param) {
  var pageKind = param.pageKind;
  var match = Context.I18n.use();
  var clickTracking = UseClickTracking.use();
  var match$1 = match.labels.program;
  var t = param.t.state;
  if (t === undefined) {
    return null;
  }
  var match$2 = t.status;
  var tmp;
  tmp = match$2 === "On" ? true : false;
  var match$3 = t.videoElement;
  return JsxRuntime.jsx(Atom_Cta.Button.make, {
              disabled: tmp,
              identifier: Caml_option.some(Identifiers.Player.trailerButton),
              label: match$1.trailer_button,
              onClick: (function (param) {
                  if (typeof pageKind === "object" && pageKind.TAG === "Rc") {
                    clickTracking({
                          eventGroup: "collection_actions",
                          eventName: "WATCH_TRAILER"
                        });
                  }
                  t.mountPlayer();
                }),
              screenReadersLabel: param.screenReadersLabel,
              style: match$3 !== undefined ? Atom_Cta_Ds.Trailer.disabledTopTeaser : Atom_Cta_Ds.Trailer.enabledTopTeaser
            });
}

var Button = {
  make: make
};

var Trailer = {
  getBackgroundAnimation: getBackgroundAnimation,
  Button: Button
};

function getRef(t) {
  return t.ref;
}

var mountOnInitDefault = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, false);

function getPreviewImage(src) {
  return Type_Image.Src.toString(undefined, src, 1600, "R16_9");
}

function make$1(video, mountOnInitOpt, images, param) {
  var mountOnInit = mountOnInitOpt !== undefined ? Caml_option.valFromOption(mountOnInitOpt) : mountOnInitDefault;
  var match = Context.Config.use();
  var isUserPaused = React.useRef(false);
  var playRef = React.useRef(function () {
        
      });
  var pauseRef = React.useRef(function () {
        
      });
  var previewImage = getPreviewImage(images);
  var whenFullVisible = function () {
    if (isUserPaused.current) {
      return ;
    } else {
      return playRef.current();
    }
  };
  var leaving = function () {
    pauseRef.current();
  };
  var callback = UseVisible.Callback.make(undefined, Caml_option.some(UseVisible.Callback.makeCallback("Always", leaving)), Caml_option.some(UseVisible.Callback.makeCallback("Always", whenFullVisible)), undefined, undefined);
  var match$1 = UseVisible.make(undefined, Caml_option.some(callback), undefined);
  var visibilityStatus = match$1.status;
  var useVisibleRef = match$1.cbRef;
  var useVisibleRef$1 = function (a) {
    useVisibleRef(a);
  };
  var screenSize = UseScreenResolution.make(undefined, undefined);
  var visibilityStatusRef = React.useRef(visibilityStatus);
  React.useEffect((function () {
          visibilityStatusRef.current = visibilityStatus;
        }), [visibilityStatus]);
  var onPlayerReady = function () {
    var match = visibilityStatusRef.current;
    if (typeof match !== "object" && match === "FullVisible") {
      return playRef.current();
    } else {
      return pauseRef.current();
    }
  };
  var match$2 = React.useState(function () {
        return "Off";
      });
  var setStatus = match$2[1];
  var componentStatus = match$2[0];
  var isInit = React.useRef(false);
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          var match = isInit.current;
          var exit = 0;
          if (!(typeof screenSize === "object" && screenSize.NAME === "XS" && screenSize.VAL === 0)) {
            exit = 1;
          }
          if (exit === 1 && !match) {
            isInit.current = true;
            var mountOnInit$1 = Core__Option.getOr(Particle_Screen_Ds.getValueByScreenSize(mountOnInit, screenSize), false);
            var match$1 = isMount.contents;
            if (mountOnInit$1) {
              if (match$1) {
                setStatus(function (param) {
                      return "On";
                    });
              }
              
            } else if (match$1) {
              setStatus(function (param) {
                    return "Off";
                  });
            }
            
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [screenSize]);
  return {
          ref: useVisibleRef$1,
          state: Core__Option.map(match.createVideoElement, (function (createVideoElement) {
                  var tmp;
                  tmp = componentStatus === "On" ? renderVideoFactory(createVideoElement, onPlayerReady, previewImage, (function (value) {
                              playRef.current = (function () {
                                  value();
                                });
                            }), (function (value) {
                              pauseRef.current = (function () {
                                  value();
                                });
                            }), isUserPaused, video)() : undefined;
                  return {
                          videoElement: tmp,
                          status: componentStatus,
                          mountPlayer: (function () {
                              setStatus(function (param) {
                                    return "On";
                                  });
                            })
                        };
                }))
        };
}

export {
  isClip ,
  getPlayerElement ,
  Trailer ,
  getRef ,
  make$1 as make,
}
/* mountOnInitDefault Not a pure module */
