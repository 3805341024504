// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Row from "./Atom_Row.res.mjs";
import * as Atom_Cell from "./Atom_Cell.res.mjs";
import * as Atom_Text from "./Atom_Text.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Checkbox_Ds from "./Atom_Checkbox_Ds.res.mjs";
import * as Atom_Checkbox_Css from "./Atom_Checkbox_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Molecule_DangerousHtml from "../molecule/Molecule_DangerousHtml.res.mjs";
import * as Molecule_DangerousHtml_Ds from "../molecule/Molecule_DangerousHtml_Ds.res.mjs";

function Atom_Checkbox$Internal(props) {
  var __checked = props.checked;
  var style = props.style;
  var id = props.id;
  var onChange = props.onChange;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var checked = __checked !== undefined ? __checked : false;
  var match = UseToggle.make(UseToggle.toStatus(checked), (function () {
          onChange(true);
        }), (function () {
          onChange(false);
        }), undefined, undefined, undefined);
  var toggleRaw = match.toggleRaw;
  var inputClass = (function (__x) {
        return CssJs.style(__x);
      })(Atom_Checkbox_Css.toCss(style));
  var containerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.cursor("pointer")]);
  var handleOnChange = function (param) {
    toggleRaw();
  };
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(style.alignItems),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      children: JsxRuntime.jsx("input", {
                            ref: props.ref_,
                            className: inputClass,
                            id: id,
                            checked: match.stateBoolean,
                            name: props.name,
                            required: required,
                            type: "checkbox",
                            onBlur: props.onBlur,
                            onChange: handleOnChange
                          })
                    }),
                props.children
              ],
              displayOrder: props.displayOrder,
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
              kind: {
                TAG: "Label",
                _0: id
              },
              rules: Caml_option.some(containerRules),
              wrap: "OnOneLine"
            });
}

var Internal = {
  make: Atom_Checkbox$Internal
};

function make(param) {
  var style = param.style;
  var required = Core__Option.getOr(param.required, false);
  return JsxRuntime.jsx(Atom_Checkbox$Internal, {
              required: required,
              onChange: param.onChange,
              id: param.id,
              displayOrder: param.displayOrder,
              style: style,
              checked: param.checked,
              children: JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(param.label),
                    kind: "span",
                    style: style.textStyle
                  })
            });
}

function Atom_Checkbox$User(props) {
  var __checked = props.checked;
  var innerHtml = props.innerHtml;
  var label = props.label;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var checked = __checked !== undefined ? __checked : false;
  return JsxRuntime.jsx(Atom_Checkbox$Internal, {
              required: required,
              onChange: props.onClick,
              id: props.id,
              style: Atom_Checkbox_Ds.userLogin,
              checked: checked,
              ref_: props.ref_,
              children: JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(label !== undefined ? Caml_option.valFromOption(label) : (
                            innerHtml !== undefined ? JsxRuntime.jsx(Molecule_DangerousHtml.make, {
                                    dangerousHtml: innerHtml,
                                    variant: Molecule_DangerousHtml_Ds.userCheckbox
                                  }) : null
                          )),
                    kind: "span",
                    style: Atom_Checkbox_Ds.userLogin.textStyle
                  })
            });
}

var User = {
  make: Atom_Checkbox$User
};

function Atom_Checkbox$Presse(props) {
  var __checked = props.checked;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var checked = __checked !== undefined ? __checked : false;
  return JsxRuntime.jsx(Atom_Checkbox$Internal, {
              required: required,
              onChange: props.onClick,
              id: props.id,
              style: Atom_Checkbox_Ds.presse,
              checked: checked,
              onBlur: props.onBlur,
              name: props.name,
              children: JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(props.label),
                    kind: "span",
                    style: Atom_Checkbox_Ds.presse.textStyle
                  })
            });
}

var Presse = {
  make: Atom_Checkbox$Presse
};

export {
  Internal ,
  make ,
  User ,
  Presse ,
}
/* CssJs Not a pure module */
