'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../../../design-system/atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../design-system/particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

var wrapperBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XL", "L"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "Zero"));

var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.minHeight(CssJs.vh(60))]);

function make(param) {
  return JsxRuntime.jsx(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(wrapperBlockSpace),
              children: JsxRuntime.jsx(Atom_Loader.make, {
                    variant: "profileForm"
                  }),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
              kind: "Main",
              rules: Caml_option.some(wrapperRules)
            });
}

export {
  wrapperBlockSpace ,
  wrapperRules ,
  make ,
}
/* wrapperBlockSpace Not a pure module */
