// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseTrackingPixel from "../../hook/UseTrackingPixel.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function make(param) {
  var handleBannerClick = UseTrackingPixel.make(Core__Option.flatMap(param.trackingPixel, Type_String.TrackingPixel.toTypeUrl));
  return JsxRuntime.jsx(Atom_Row.make, {
              blockSpace: param.blockSpace,
              children: JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                    children: JsxRuntime.jsx(Atom_Image.make, {
                          alt: Type_String.Teaser.Title.toString(param.title),
                          src: param.images,
                          style: "Banner"
                        }),
                    href: Type_String.Href.toString(param.url),
                    onClick: handleBannerClick,
                    style: Atom_Cta_Ds.banner
                  }),
              identifier: Caml_option.some(Identifiers.Zone_Layout.banner),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              ref_: param.ref_
            });
}

make.displayName = "Banner";

export {
  make ,
}
/*  Not a pure module */
