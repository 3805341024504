// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Layout_Guide_Concert from "../layout/guide/concert/Layout_Guide_Concert.res.mjs";

function make(param) {
  var match = Context.I18n.use();
  var formaters = match.formaters;
  var match$1 = Context.Config.use();
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, {
        TAG: "Guide",
        _0: "Concert"
      }, "concert", match$1.serverTime, param.page);
  return JsxRuntime.jsx(Layout_Guide_Concert.make, {
              page: page,
              pageKind: {
                TAG: "Guide",
                _0: "Concert"
              }
            });
}

export {
  make ,
}
/* Context Not a pure module */
