// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M27.7812 6H4.21875C3.54565 6 3 6.54565 3 7.21875V20.2187C3 20.8918 3.54565 21.4375 4.21875 21.4375H14.375V23.0625H13.5625C12.4412 23.0638 11.5326 23.9725 11.5312 25.0937C11.5312 25.3181 11.7131 25.5 11.9375 25.5H20.0625C20.2869 25.5 20.4687 25.3181 20.4687 25.0937C20.4674 23.9725 19.5588 23.0638 18.4375 23.0625H17.625V21.4375H20.4687C20.6931 21.4375 20.875 21.2556 20.875 21.0312C20.875 20.8069 20.6931 20.625 20.4687 20.625H4.21875C3.99438 20.625 3.8125 20.4431 3.8125 20.2187V7.21875C3.8125 6.99438 3.99438 6.8125 4.21875 6.8125H27.7812C28.0056 6.8125 28.1875 6.99438 28.1875 7.21875V10.4687C28.1875 10.6931 28.3694 10.875 28.5937 10.875C28.8181 10.875 29 10.6931 29 10.4687V7.21875C29 6.54565 28.4543 6 27.7812 6ZM19.5868 24.6879H12.4132C12.5861 24.2014 13.0462 23.8761 13.5625 23.8754H18.4375C18.9539 23.8761 19.414 24.2014 19.5868 24.6879ZM16.8125 23.0629V21.4379H15.1875V23.0629H16.8125Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M22.9062 25.5005H27.7812C28.4543 25.5005 29 24.9548 29 24.2817V12.9067C29 12.2336 28.4543 11.688 27.7812 11.688H22.9062C22.2332 11.688 21.6875 12.2336 21.6875 12.9067V24.2817C21.6875 24.9548 22.2332 25.5005 22.9062 25.5005ZM22.5 12.9067C22.5 12.6823 22.6819 12.5004 22.9063 12.5004H27.7813C28.0056 12.5004 28.1875 12.6823 28.1875 12.9067V24.2817C28.1875 24.506 28.0056 24.6879 27.7813 24.6879H22.9063C22.6819 24.6879 22.5 24.506 22.5 24.2817V12.9067Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M23.3124 20.2187C23.3124 21.3406 24.2219 22.25 25.3437 22.25C26.465 22.2487 27.3736 21.34 27.3749 20.2187C27.3749 19.0969 26.4655 18.1875 25.3437 18.1875C24.2219 18.1875 23.3124 19.0969 23.3124 20.2187ZM24.125 20.2192C24.125 19.5461 24.6706 19.0004 25.3437 19.0004C26.0168 19.0004 26.5625 19.5461 26.5625 20.2192C26.5625 20.8923 26.0168 21.4379 25.3437 21.4379C24.6706 21.4379 24.125 20.8923 24.125 20.2192Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "23.7187",
                      cy: "13.7188",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "25.3438",
                      cy: "13.7188",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "26.9688",
                      cy: "13.7188",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "23.7187",
                      cy: "15.3433",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "25.3438",
                      cy: "15.3433",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "26.9688",
                      cy: "15.3433",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "23.7187",
                      cy: "16.9692",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "25.3438",
                      cy: "16.9692",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "26.9688",
                      cy: "16.9692",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "23.7187",
                      cy: "23.4688",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "25.3438",
                      cy: "23.4688",
                      r: "0.40625"
                    }),
                JsxRuntime.jsx("circle", {
                      cx: "26.9688",
                      cy: "23.4688",
                      r: "0.40625"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
