// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "./Atom_Cta.res.mjs";
import * as Atom_Cta_Ds from "./Atom_Cta_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

function Atom_InstallMoblileApp(props) {
  var __searchState = props.searchState;
  var searchState = __searchState !== undefined ? __searchState : "Off";
  var match = Context.I18n.use();
  var match$1 = match.labels.header;
  var tmp;
  tmp = searchState === "On" ? "none" : "flex";
  var display = Particle_Screen_Ds.make(undefined, undefined, undefined, "none", undefined, tmp);
  return JsxRuntime.jsx(Atom_Cta.Link.make, {
              href: "https://arte.app.link/header-dl",
              label: match$1.installApp,
              style: Atom_Cta_Ds.Header.makeInstallMobileApp(display)
            });
}

var make = Atom_InstallMoblileApp;

export {
  make ,
}
/* Context Not a pure module */
