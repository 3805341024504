// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

var makeDefault_blockSpace = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("L")));

var makeDefault_height = Particle_Screen_Ds.make(undefined, undefined, CssJs.px(395), CssJs.px(484), undefined, "auto");

var makeDefault = {
  blockSpace: makeDefault_blockSpace,
  height: makeDefault_height,
  contentMinHeight: undefined
};

var makeCarousel_height = Particle_Screen_Ds.make(undefined, undefined, CssJs.px(365), CssJs.px(418), undefined, "auto");

var makeCarousel_contentMinHeight = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "zero", undefined, undefined, {
          NAME: "px",
          VAL: 163
        }));

var makeCarousel = {
  blockSpace: undefined,
  height: makeCarousel_height,
  contentMinHeight: makeCarousel_contentMinHeight
};

function view(param) {
  return {
          blockSpace: param.blockSpace,
          height: param.height,
          contentMinHeight: param.contentMinHeight
        };
}

export {
  makeDefault ,
  makeCarousel ,
  view ,
}
/* makeDefault Not a pure module */
