// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as UseGetFocusableElement from "../../../hook/UseGetFocusableElement.res.mjs";
import * as Molecule_RedirectionAnchor from "../../molecule/Molecule_RedirectionAnchor.res.mjs";

function Organism_HeaderRedirectionAnchor(props) {
  var labels = props.labels;
  var match = UseGetFocusableElement.make();
  var htmlElement = match[0];
  var handleClick = function (param) {
    Core__Option.forEach(htmlElement, (function (prim) {
            prim.focus();
          }));
  };
  var left;
  if (htmlElement !== undefined) {
    var tmp;
    tmp = match[1] === "Main" ? labels.redirectMainLabel : labels.redirectPlayerLabel;
    left = {
      NAME: "Button",
      VAL: {
        onClick: handleClick,
        label: tmp
      }
    };
  } else {
    left = undefined;
  }
  var right_1 = {
    href: props.searchNavigation.href,
    label: labels.redirectSearchLabel,
    target: "_blank"
  };
  var right = {
    NAME: "Link",
    VAL: right_1
  };
  return JsxRuntime.jsx(Molecule_RedirectionAnchor.make, {
              left: left,
              right: right
            });
}

Organism_HeaderRedirectionAnchor.displayName = "HeaderRedirectionAnchor";

var make = Organism_HeaderRedirectionAnchor;

export {
  make ,
}
/*  Not a pure module */
