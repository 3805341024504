// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function Molecule_RedirectionAnchor$Button(props) {
  return JsxRuntime.jsx(Atom_Cta.Button.make, {
              label: props.label,
              onClick: props.onClick,
              style: Atom_Cta_Ds.Header.redirectionAnchor
            });
}

var Button = {
  make: Molecule_RedirectionAnchor$Button
};

function Molecule_RedirectionAnchor$Link(props) {
  return JsxRuntime.jsx(Atom_Cta.Link.make, {
              href: props.href,
              label: props.label,
              style: Atom_Cta_Ds.Header.redirectionAnchor,
              target: props.target
            });
}

var Link = {
  make: Molecule_RedirectionAnchor$Link
};

function renderAction(action) {
  if (action === undefined) {
    return null;
  }
  if (action.NAME === "Link") {
    var match = action.VAL;
    return JsxRuntime.jsx(Molecule_RedirectionAnchor$Link, {
                href: match.href,
                label: match.label,
                target: match.target
              });
  }
  var match$1 = action.VAL;
  return JsxRuntime.jsx(Molecule_RedirectionAnchor$Button, {
              onClick: match$1.onClick,
              label: match$1.label
            });
}

function make(param) {
  var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
        Particle_zIndex.Particle_zIndex_Css.toCss({
              TAG: "Exact",
              _0: "RedirectionAnchor"
            }),
        CssJs.focusWithin([Particle_zIndex.Particle_zIndex_Css.toCss({
                    TAG: "Exact",
                    _0: "RedirectionAnchorFocused"
                  })])
      ]);
  var left = renderAction(param.left);
  var right = renderAction(param.right);
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "stretch")),
              background: Caml_option.some(Particle_Background_Ds.header),
              children: [
                left,
                right
              ],
              height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
              justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "spaceAround")),
              position: Caml_option.some(Particle_Screen_Constants.positionAbsolute),
              rules: Caml_option.some(rules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              wrap: "OnOneLine"
            });
}

make.displayName = "RedirectionAnchor";

export {
  Button ,
  Link ,
  renderAction ,
  make ,
}
/*  Not a pure module */
