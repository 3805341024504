// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";

function nullableToJson(encoder, $$null) {
  if ($$null == null) {
    return null;
  } else {
    return encoder($$null);
  }
}

function nullableFromJson(decoder, json) {
  var match = Core__JSON.Decode.$$null(json);
  if (match !== undefined) {
    return {
            TAG: "Ok",
            _0: null
          };
  } else {
    return Core__Result.map(decoder(json), (function (prim) {
                  return prim;
                }));
  }
}

function t_encode(encoder_a) {
  return function (v) {
    return nullableToJson(encoder_a, v);
  };
}

function t_decode(decoder_a) {
  return function (v) {
    return nullableFromJson(decoder_a, v);
  };
}

export {
  nullableToJson ,
  nullableFromJson ,
  t_encode ,
  t_decode ,
}
/* No side effect */
