// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "./Particle_Color.res.mjs";
import * as Particle_Screen_Css from "./Particle_Screen_Css.res.mjs";
import * as Particle_Background_Ds from "./Particle_Background_Ds.res.mjs";

function makeImageRules(color, opacity, image) {
  if (image !== undefined) {
    var position = image.position;
    var tmp;
    tmp = image.size === "Cover" ? CssJs.backgroundSize("cover") : CssJs.backgroundSize("auto");
    var baseRules = [
      CssJs.backgroundColor(Particle_Color.Css.color(color)),
      CssJs.backgroundImage({
            NAME: "url",
            VAL: Type_String.CdnUrl.toString(image.cdnUrl)
          }),
      CssJs.backgroundPosition({
            NAME: "hv",
            VAL: [
              CssJs.px(position.x),
              CssJs.px(position.y)
            ]
          }),
      tmp,
      CssJs.backgroundRepeat(image.repeat)
    ];
    var optionalRules = Util_Array.catOption([
          Core__Option.map(opacity, CssJs.opacity),
          Core__Option.map(Core__Option.map(image.width, CssJs.px), CssJs.width),
          Core__Option.map(Core__Option.map(image.height, CssJs.px), CssJs.height),
          Core__Option.map(Core__Option.map(Core__Option.map(image.hoverOpacity, CssJs.opacity), (function (__x) {
                      return Util_Array.pure(__x);
                    })), CssJs.hover)
        ]);
    return baseRules.concat(optionalRules);
  }
  var baseRules$1 = Caml_option.some(CssJs.backgroundColor(Particle_Color.Css.color(color)));
  var optionalRules$1 = Core__Option.map(opacity, CssJs.opacity);
  return Util_Array.catOption([
              baseRules$1,
              optionalRules$1
            ]);
}

function toCss(dsStyle) {
  var match = Particle_Background_Ds.view(dsStyle);
  var image = match.image;
  var opacity = match.opacity;
  var color = match.color;
  if (image !== undefined) {
    return Particle_Screen_Css.toRules(undefined, undefined, (function (extra) {
                  return makeImageRules(color, opacity, extra);
                }), Caml_option.valFromOption(image));
  }
  var baseRules = Caml_option.some(CssJs.backgroundColor(Particle_Color.Css.color(color)));
  var optionalRules = Core__Option.map(opacity, CssJs.opacity);
  return Util_Array.catOption([
              baseRules,
              optionalRules
            ]);
}

export {
  makeImageRules ,
  toCss ,
}
/* CssJs Not a pure module */
