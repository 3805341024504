// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      d: "M3 11.1997H5.6728V19.9565H3V11.1997Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M7.2744 11.1941L16.3588 4.56934V26.9605L7.2744 20.3357V11.1941Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M29 20.3878L25.2768 16.3162L29 12.2498L27.57 10.6846L23.8468 14.751L20.1236 10.6846L18.6884 12.2498L22.4064 16.3162L18.6884 20.3878L20.1236 21.953L23.8468 17.8866L27.57 21.953L29 20.3878Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
