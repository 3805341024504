// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M31.085 3.91c0.499 0 0.915 0.41 0.915 0.915v22.349c0 0.499-0.41 0.915-0.915 0.915h-30.182c-0.499 0-0.902-0.41-0.902-0.915v-22.349c0-0.499 0.397-0.915 0.902-0.915h0.947c9.587 0 19.174 0 28.768 0h0.467zM15.994 16.493l6.848-5.92 5.594-4.851h-0.474c-7.821 0-15.661 0-23.494 0h-0.909l5.594 4.851 6.842 5.92zM24.019 11.942l-7.424 6.432c-0.333 0.294-0.851 0.307-1.197 0.013l-13.587-11.77v18.771l6.15-5.338 3.45-2.97c0.371-0.333 0.954-0.294 1.274 0.090 0.333 0.371 0.282 0.954-0.090 1.274l-9.043 7.821h24.883l-9.043-7.827c-0.371-0.32-0.41-0.902-0.090-1.274 0.333-0.384 0.902-0.422 1.274-0.090l3.45 2.97 6.163 5.338v-18.771l-6.17 5.331z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
