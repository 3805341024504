// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function pushTeaserIntoColumn(teaser, column, teasersByWeek) {
  var otherWeek = teasersByWeek.otherWeek;
  var secondWeek = teasersByWeek.secondWeek;
  var firstWeek = teasersByWeek.firstWeek;
  switch (column) {
    case "FirstWeek" :
        var firstList = Core__Option.mapOr(firstWeek.teaserList, Util_Array.NonEmpty.make(undefined, teaser), (function (list) {
                return Util_Array.NonEmpty.add(list, teaser);
              }));
        return {
                firstWeek: {
                  date: firstWeek.date,
                  teaserList: Caml_option.some(firstList)
                },
                secondWeek: teasersByWeek.secondWeek,
                otherWeek: teasersByWeek.otherWeek
              };
    case "SecondWeek" :
        var secondList = Core__Option.mapOr(secondWeek.teaserList, Util_Array.NonEmpty.make(undefined, teaser), (function (list) {
                return Util_Array.NonEmpty.add(list, teaser);
              }));
        return {
                firstWeek: teasersByWeek.firstWeek,
                secondWeek: {
                  date: secondWeek.date,
                  teaserList: Caml_option.some(secondList)
                },
                otherWeek: teasersByWeek.otherWeek
              };
    case "OtherWeek" :
        var otherList = Core__Option.mapOr(otherWeek.teaserList, Util_Array.NonEmpty.make(undefined, teaser), (function (list) {
                return Util_Array.NonEmpty.add(list, teaser);
              }));
        return {
                firstWeek: teasersByWeek.firstWeek,
                secondWeek: teasersByWeek.secondWeek,
                otherWeek: {
                  date: otherWeek.date,
                  teaserList: Caml_option.some(otherList)
                }
              };
    
  }
}

var weekStartsOn = 1;

export {
  weekStartsOn ,
  pushTeaserIntoColumn ,
}
/* No side effect */
