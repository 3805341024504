// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_Spacer from "../particle/Particle_Spacer.res.mjs";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";
import * as Particle_Device_Css from "../particle/Particle_Device_Css.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";
import * as Particle_Animation_Css from "../particle/Particle_Animation_Css.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Background_Css from "../particle/Particle_Background_Css.res.mjs";

function getBorderRules(toRules, rules) {
  return Particle_Screen_Css.toRules(undefined, undefined, toRules, rules);
}

function getSpacerRules(toRules, rules) {
  return Particle_Screen_Css.toRules(undefined, undefined, toRules, rules);
}

function getDisplayRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(Particle_Css.font_display), extra);
}

function getDirectionRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.flexDirection), extra);
}

function getWidthRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.width), extra);
}

function getMaxWidthRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.maxWidth), extra);
}

function getMaxHeightRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.maxHeight), extra);
}

function getMinHeightRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.minHeight), extra);
}

function getAlignItemsRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.alignItems), extra);
}

function getAlignSelfRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.alignSelf), extra);
}

function getDisplayOrderRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.order), extra);
}

function getHeightRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.height), extra);
}

function getJustifyContentRules(extra) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(CssJs.justifyContent), extra);
}

function getFlexGapRules(value) {
  return Util_Css.gapPolyfill(Particle_Spacer.spacerToInt(value));
}

function getGridGapRules(value) {
  return Util_Css.toRules(CssJs.gridGap)(Particle_Css.getSpacerSize(value));
}

var defaultDisplay = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.flexBox);

function getAutoSizeRule(autoSize) {
  switch (autoSize) {
    case "AllPlaceAvailable" :
        return CssJs.flex3(1, 1, Css.px(0));
    case "AllPlaceNeeded" :
        return CssJs.flex3(1, 0, "auto");
    case "MaxContent" :
        return CssJs.flex("initial");
    case "NoResizing" :
        return CssJs.flex3(0, 0, "auto");
    
  }
}

function getAutoSizeRules(autoSize) {
  return Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(getAutoSizeRule), autoSize);
}

function pToPositionCss(p) {
  if (typeof p !== "object") {
    if (p === "Relative") {
      return [CssJs.position("relative")];
    } else {
      return [CssJs.position("static")];
    }
  } else if (p.TAG === "Fixed") {
    return [CssJs.position("fixed")];
  } else {
    return [CssJs.position("absolute")];
  }
}

function boxCoordToCss(boxCoord) {
  var top = Core__Option.map(boxCoord.top, CssJs.top);
  var right = Core__Option.map(boxCoord.right, CssJs.right);
  var bottom = Core__Option.map(boxCoord.bottom, CssJs.bottom);
  var left = Core__Option.map(boxCoord.left, CssJs.left);
  return Util_Array.catOption([
              top,
              right,
              bottom,
              left
            ]);
}

function positionToBoxCoordCss(position) {
  if (typeof position !== "object") {
    if (position === "Relative") {
      return [];
    } else {
      return [];
    }
  } else if (position.TAG === "Fixed") {
    return Core__Option.mapOr(position._0, [], boxCoordToCss);
  } else {
    return Core__Option.mapOr(position._0, [], boxCoordToCss);
  }
}

function getPositionRules(position) {
  return Particle_Screen_Css.toRules(undefined, undefined, (function (array) {
                return array;
              }), Particle_Screen_Ds.map(position, (function (p) {
                    return pToPositionCss(p);
                  })));
}

function getBoxCoordonateRules(position) {
  return Particle_Screen_Css.toRules(undefined, undefined, (function (array) {
                return array;
              }), Particle_Screen_Ds.map(position, (function (p) {
                    return positionToBoxCoordCss(p);
                  })));
}

function getBaseRules(alignItems, alignSelf, animation, area, autoSize, background, border, deviceRules, direction, displayOpt, displayOrder, height, justifyContent, kindRules, maxHeight, maxWidth, minHeight, spacer, width, wrap) {
  var display = displayOpt !== undefined ? Caml_option.valFromOption(displayOpt) : defaultDisplay;
  var widthRules = Core__Option.map(width, getWidthRules);
  var maxWidthRules = Core__Option.map(maxWidth, getMaxWidthRules);
  var heightRules = Core__Option.map(height, getHeightRules);
  var maxHeightRules = Core__Option.map(maxHeight, getMaxHeightRules);
  var minHeightRules = Core__Option.map(minHeight, getMinHeightRules);
  var alignItemsRules = Core__Option.map(alignItems, getAlignItemsRules);
  var alignSelfRules = Core__Option.map(alignSelf, getAlignSelfRules);
  var justifyContentRules = Core__Option.map(justifyContent, getJustifyContentRules);
  var directionRules = Core__Option.map(direction, getDirectionRules);
  var displayRules = getDisplayRules(display);
  var deviceRules$1 = Core__Option.map(deviceRules, Particle_Device_Css.toRules);
  var spacerRules = Core__Option.map(spacer, (function (extra) {
          return getSpacerRules(Particle_Spacer_Css.toCss, extra);
        }));
  var displayOrderRules = Core__Option.map(displayOrder, getDisplayOrderRules);
  var areaRules = Core__Option.map(area, (function (area) {
          return [CssJs.gridArea({
                        NAME: "ident",
                        VAL: area
                      })];
        }));
  var borderRules = Core__Option.map(border, (function (extra) {
          return getBorderRules(Particle_Border_Css.toCss, extra);
        }));
  var animationRules = Core__Option.map(animation, Particle_Animation_Css.toCss);
  var autoSizeRules = Core__Option.map(autoSize, getAutoSizeRules);
  var tmp;
  tmp = wrap !== undefined ? (
      wrap === "MultiLine" ? [CssJs.flexWrap("wrap")] : [CssJs.flexWrap("nowrap")]
    ) : [CssJs.flexWrap("nowrap")];
  var wrapRules = tmp;
  var backgroundRules = Particle_Background_Css.toCss(Core__Option.getOr(background, Particle_Background_Ds.transparent));
  return Core__Array.reduce([
              widthRules,
              maxWidthRules,
              heightRules,
              maxHeightRules,
              minHeightRules,
              alignItemsRules,
              alignSelfRules,
              justifyContentRules,
              directionRules,
              wrapRules,
              autoSizeRules,
              displayOrderRules,
              displayRules,
              deviceRules$1,
              borderRules,
              animationRules,
              spacerRules,
              backgroundRules,
              areaRules,
              kindRules
            ], [], (function (acc, rules) {
                if (rules !== undefined && rules.length !== 0) {
                  acc.push(rules);
                  return acc;
                } else {
                  return acc;
                }
              }));
}

var hrefSchema = S$RescriptSchema.string;

export {
  hrefSchema ,
  getBorderRules ,
  getSpacerRules ,
  getDisplayRules ,
  getDirectionRules ,
  getWidthRules ,
  getMaxWidthRules ,
  getMaxHeightRules ,
  getMinHeightRules ,
  getAlignItemsRules ,
  getAlignSelfRules ,
  getDisplayOrderRules ,
  getHeightRules ,
  getJustifyContentRules ,
  getFlexGapRules ,
  getGridGapRules ,
  defaultDisplay ,
  getAutoSizeRule ,
  getAutoSizeRules ,
  pToPositionCss ,
  boxCoordToCss ,
  positionToBoxCoordCss ,
  getPositionRules ,
  getBoxCoordonateRules ,
  getBaseRules ,
}
/* defaultDisplay Not a pure module */
