// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3.79999 9.46583H28.4583V7H3.79999V9.46583Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3.79999 16.9817H28.4583V14.5159H3.79999V16.9817Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3.79999 24.5H28.4583V22.0342H3.79999V24.5Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
