import { settingsVideoQuality } from '@artegeie/design-system/replay';
import { useLocalStorage } from './useLocalStorage';

function assertNeverWithDefault<T>(_: never, defaultValue: T): T {
    return defaultValue;
}

const isVideoQuality = (value: settingsVideoQuality): value is settingsVideoQuality => {
    switch (value) {
        case 'auto':
        case 'savedata':
            return true;
        default:
            return assertNeverWithDefault(value, false);
    }
};

type VideoQualityChange = {
    videoQuality: settingsVideoQuality;
    onVideoQualityChange: (videoQualityRaw: settingsVideoQuality) => void;
};

const localStorageKey = 'videoQuality';
const videoQualityInitialState: settingsVideoQuality = 'auto';
const stringToVideoQuality = (value: string): settingsVideoQuality => {
    if (isVideoQuality(value as settingsVideoQuality)) {
        return value as settingsVideoQuality;
    }
    return videoQualityInitialState;
};

export const useVideoQualityChange = (): VideoQualityChange => {
    const [storedVideoQuality, setVideoQualityInStorage] = useLocalStorage<settingsVideoQuality | string>(
        localStorageKey,
        videoQualityInitialState,
    );

    const onVideoQualityChange = (videoQuality: settingsVideoQuality) => {
        setVideoQualityInStorage(videoQuality);
    };

    return {
        videoQuality: stringToVideoQuality(storedVideoQuality),
        onVideoQualityChange,
    };
};

export const useGetVideoQualityValue = () => {
    const [videoQuality] = useLocalStorage(localStorageKey, videoQualityInitialState);

    return videoQuality;
};
