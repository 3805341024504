// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M16.0032 3H15.9967C8.82888 3 3 8.8305 3 16C3 18.8437 3.9165 21.4795 5.47487 23.6196L3.85475 28.4491L8.85162 26.8517C10.9072 28.2135 13.3594 29 16.0032 29C23.1711 29 29 23.1679 29 16C29 8.83212 23.1711 3 16.0032 3ZM23.5676 21.358C23.254 22.2436 22.0092 22.9781 21.0164 23.1926C20.3371 23.3373 19.4499 23.4526 16.4631 22.2144C12.6427 20.6316 10.1825 16.7495 9.99074 16.4976C9.80711 16.2458 8.44699 14.442 8.44699 12.5765C8.44699 10.711 9.39436 9.80264 9.77624 9.41264C10.0899 9.09251 10.6082 8.94626 11.1055 8.94626C11.2664 8.94626 11.411 8.95439 11.541 8.96089C11.9229 8.97714 12.1146 8.99989 12.3665 9.60276C12.6801 10.3584 13.4439 12.2239 13.5349 12.4156C13.6275 12.6074 13.7201 12.8674 13.5901 13.1193C13.4682 13.3793 13.361 13.4946 13.1692 13.7156C12.9775 13.9366 12.7955 14.1056 12.6037 14.3429C12.4282 14.5493 12.23 14.7703 12.451 15.1521C12.672 15.5259 13.4357 16.7723 14.5602 17.7733C16.0114 19.0651 17.1879 19.4779 17.6087 19.6534C17.9224 19.7834 18.2961 19.7525 18.5252 19.5088C18.8161 19.1951 19.1752 18.6751 19.5409 18.1633C19.8009 17.796 20.1291 17.7505 20.4736 17.8805C20.8246 18.0024 22.682 18.9205 23.0639 19.1106C23.4457 19.3024 23.6976 19.3934 23.7902 19.5543C23.8812 19.7151 23.8812 20.4708 23.5676 21.358Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
