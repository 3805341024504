// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1654 8.83456 29 16 29C23.1711 29 29 23.1654 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.82887 3 16ZM4.47144 16.0001C4.47144 9.64554 9.64544 4.47154 16 4.47154C22.3546 4.47154 27.5286 9.64066 27.5286 16.0001C27.5286 22.3547 22.3546 27.5287 16 27.5287C9.64057 27.5287 4.47144 22.3595 4.47144 16.0001Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M16 6.48389V25.5159C21.252 25.5159 25.516 21.2568 25.516 15.9999C25.516 10.743 21.252 6.48389 16 6.48389Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
