'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function make() {
  var match = React.useState(function () {
        return "Unready";
      });
  var setStatus = match[1];
  React.useEffect((function () {
          setStatus(function (param) {
                return "Ready";
              });
        }), []);
  return match[0];
}

export {
  make ,
}
/* react Not a pure module */
