import { type FooterProps } from '@replay/types/Footer';

const getFooterFallback = async (locale: string): Promise<FooterProps> => {
    switch (locale) {
        case 'fr':
            return (await import('./fr.json')) as unknown as FooterProps;
        case 'de':
            return (await import('./de.json')) as unknown as FooterProps;
        case 'it':
            return (await import('./it.json')) as unknown as FooterProps;
        case 'es':
            return (await import('./es.json')) as unknown as FooterProps;
        case 'pl':
            return (await import('./pl.json')) as unknown as FooterProps;
        default:
            return (await import('./fr.json')) as unknown as FooterProps;
    }
};

export default getFooterFallback;
