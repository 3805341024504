'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";

function setFocus(element) {
  if (element !== undefined) {
    Core__Option.map(Webapi__Dom__Element.asHtmlElement(Caml_option.valFromOption(element)), (function (prim) {
            prim.focus();
          }));
    return ;
  }
  
}

function make(elementId) {
  var $$document = Util_Dom.SafeWebApi.$$document;
  if ($$document === undefined) {
    return ;
  }
  var element = Caml_option.valFromOption($$document).getElementById(elementId);
  setFocus((element == null) ? undefined : Caml_option.some(element));
}

var ById = {
  make: make
};

function make$1(elementRef) {
  var element = Util_Dom.getDomRef(elementRef);
  setFocus(element);
}

var ByRef = {
  make: make$1
};

export {
  setFocus ,
  ById ,
  ByRef ,
}
/* Util_Dom Not a pure module */
