// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M16.2877 29C13.5297 29 10.8569 28.1166 8.61321 26.438C5.87813 24.3906 4.10768 21.4075 3.62864 18.0356C3.14924 14.6638 4.01807 11.3087 6.0752 8.58743C8.13198 5.86655 11.1312 4.10521 14.5198 3.62836C17.9091 3.15041 21.281 4.0156 24.0157 6.06217C26.7504 8.10911 28.5209 11.0926 28.9999 14.4645L25.5505 14.9497C25.2018 12.4952 23.9126 10.3225 21.9214 8.83223C19.9302 7.34265 17.4756 6.71389 15.0076 7.05996C12.5406 7.4075 10.3574 8.68943 8.8591 10.6708C7.36151 12.6525 6.7291 15.0951 7.07809 17.5497C7.79866 22.6177 12.5297 26.156 17.621 25.4402C18.6461 25.2955 19.6286 24.9881 20.5418 24.5269L22.1199 27.6168C20.8642 28.2517 19.5146 28.6743 18.1091 28.8718C17.5 28.9577 16.892 29 16.2877 29Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
