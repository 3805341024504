'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function reducer(state, action) {
  if (typeof action === "object") {
    return action._0;
  }
  switch (action) {
    case "SetOn" :
        return {
                TAG: "Changed",
                _0: "On"
              };
    case "SetOff" :
        return {
                TAG: "Changed",
                _0: "Off"
              };
    case "Toggle" :
        if (state.TAG === "Pristine") {
          if (state._0 === "On") {
            return {
                    TAG: "Changed",
                    _0: "Off"
                  };
          } else {
            return {
                    TAG: "Changed",
                    _0: "On"
                  };
          }
        } else if (state._0 === "On") {
          return {
                  TAG: "Changed",
                  _0: "Off"
                };
        } else {
          return {
                  TAG: "Changed",
                  _0: "On"
                };
        }
    
  }
}

function toStatus($$boolean) {
  if ($$boolean) {
    return "On";
  } else {
    return "Off";
  }
}

function make(statusOpt, onOnOpt, onOffOpt, asyncOnOnOpt, asyncOnOffOpt, param) {
  var status = statusOpt !== undefined ? statusOpt : "Off";
  var onOn = onOnOpt !== undefined ? onOnOpt : (function () {
        
      });
  var onOff = onOffOpt !== undefined ? onOffOpt : (function () {
        
      });
  var asyncOnOn = asyncOnOnOpt !== undefined ? asyncOnOnOpt : (function () {
        return Promise.resolve(undefined);
      });
  var asyncOnOff = asyncOnOffOpt !== undefined ? asyncOnOffOpt : (function () {
        return Promise.resolve(undefined);
      });
  var match = React.useReducer(reducer, {
        TAG: "Pristine",
        _0: status
      });
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch({
                TAG: "Set",
                _0: {
                  TAG: "Pristine",
                  _0: status
                }
              });
        }), [status]);
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          var runAsync = async function () {
            if (state.TAG === "Pristine") {
              return ;
            }
            if (state._0 === "On") {
              onOn();
              return Core__Option.forEach(await asyncOnOn(), (function (status) {
                            dispatch({
                                  TAG: "Set",
                                  _0: {
                                    TAG: "Pristine",
                                    _0: status
                                  }
                                });
                          }));
            }
            onOff();
            return Core__Option.forEach(await asyncOnOff(), (function (status) {
                          dispatch({
                                TAG: "Set",
                                _0: {
                                  TAG: "Pristine",
                                  _0: status
                                }
                              });
                        }));
          };
          if (isMount.contents) {
            runAsync();
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [state]);
  var toggleRaw = function (param) {
    dispatch("Toggle");
  };
  var toggle = function (param) {
    dispatch("Toggle");
  };
  var close = function (param) {
    dispatch("SetOff");
  };
  var stateBoolean;
  stateBoolean = state.TAG === "Pristine" ? (
      state._0 === "On" ? true : false
    ) : (
      state._0 === "On" ? true : false
    );
  return {
          toggleRaw: toggleRaw,
          toggle: toggle,
          state: state._0,
          stateBoolean: stateBoolean,
          close: close,
          setOn: (function () {
              dispatch("SetOn");
            }),
          setInitialStatus: (function (status) {
              dispatch({
                    TAG: "Set",
                    _0: {
                      TAG: "Pristine",
                      _0: status
                    }
                  });
            })
        };
}

export {
  reducer ,
  toStatus ,
  make ,
}
/* react Not a pure module */
