// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = (function (baseUrl) {
    return {
            Concert: {
                title: 'ARTE Concert',
                url: baseUrl + '/it/arte-concert/',
                navigation: {
                    links: [
                        {
                            label: 'Ricerca per genere',
                            href: baseUrl + '/it/arte-concert/piu-recenti/',
                            id: 'concert-genres',
                            kind: 'other',
                        },
                    ],
                },
                socialNetworks:{
                    facebook: null,
                    twitter: null,
                    instagram: null,
                    youtube: null,
                    tiktok: null,
                },
                        concertSelectGenre:{
                    title:{label:"Generi musicali", href:baseUrl +"/it/arte-concert/piu-recenti/"},
                    items:[
                        {
                            label:"Pop & Rock",
                            href: baseUrl+"/it/p/pop-rock/" 
                        },
                        {
                            label:"Elettronica",
                            href:baseUrl +"/it/p/elettronica/"
                        },
                        {
                            label:"Metal",
                            href:baseUrl +"/it/p/metal/"
                        },
                        {
                            label:"Hip-hop",
                            href:baseUrl +"/it/p/hip-hop/"
                        },
                        {
                            label:"Jazz",
                            href:baseUrl +"/it/p/jazz/"
                        },
                        {
                            label:"Classica",
                            href:  baseUrl+"/it/p/musica-classica/" 
                        },
                        {
                            label:"Opera",
                            href:baseUrl +"/it/p/opera/"
                        },
                        {
                            label:"Barocco",
                            href:  baseUrl+"/it/p/musica-barocca/" 
                        },
                        {
                            label:"Arti di scena",
                            href:  baseUrl+"/it/p/arti-dello-spettacolo/" 
                        },
                        {
                            label:"World Music",
                            href: baseUrl+"/it/p/musiche-del-mondo/" 
                        },
                       
                    ]
               
            },
               guide:null,
            },
            DesktopNavigation: {
                links: [
                                          {
                        label: 'Cerca',
                        href: baseUrl + '/it/search/',
                        id: 'search',
                    },
                    {
                        label: 'Chi siamo',
                        href: 'https://www.arte.tv/sites/it/corporate/cosa-facciamo/?lang=it',
                        id: 'about',
                    },
                ],
            },
            MenuNavigation: {
                mobileLinks: [
                    {
                        label: 'Inizio',
                        href: baseUrl + '/it/',
                    },
                     {
                        label: 'Prossimamente',
                        href: baseUrl + '/it/p/prossimamente/',
                        id: 'upcoming',
                    },                      
                    {
                        label: 'Cerca',
                        href: baseUrl + '/it/search/',
                        id: 'search',
                    },
                    {
                        label: 'Chi siamo',
                        href: baseUrl + '/sites/it/corporate/cosa-facciamo/?lang=it',
                        id: 'about',
                    },
                ],
                categories: [
                    {
                        label: 'Politica e società',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/politica-e-societa/',
                        code: 'ACT',
                    },
                    {
                        label: 'Cinema',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/cinema/',
                        code: 'CIN',
                    },
                    {
                        label: 'Serie',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/serie-e-fiction/',
                        code: 'SER',
                    },
                    {
                        label: 'Cultura',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/cultura/',
                        code: 'CPO',
                    },
                    {
                        label: 'ARTE Concert',
                        kind: 'internal',
                        href: baseUrl + '/it/arte-concert/',
                        code: 'ARS',
                    },
                    {
                        label: 'Scienze',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/scienze/',
                        code: 'SCI',
                    },
                    {
                        label: 'Viaggi e scoperte',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/viaggi-e-scoperte/',
                        code: 'DEC',
                    },
                    {
                        label: 'Storia',
                        kind: 'internal',
                        href: baseUrl + '/it/videos/storia/',
                        code: 'HIS',
                    },
                ],
                otherLinks: [
                    {
                        label: 'Programmi',
                        href: baseUrl + '/it/videos/programmi/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Videogiochi',
                        href: baseUrl + '/digitalproductions/it/categories/videogiochi/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Tutti i video',
                        href: baseUrl + '/it/videos/piu-visti/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Produzioni digitali',
                        href: baseUrl + '/digitalproductions/it/',
                        code: null,
                        kind: 'external',
                    },
                ],
            },
            LogoNavigation: {
                label: 'in italiano',
                title: 'Arte in italiano',
                href: baseUrl + '/it/',
                links: [
                    {
                        label: 'ARTE Concert',
                        id: 'arte_concert',
                        href: baseUrl + '/it/arte-concert/',
                    },  
                     {
                        label: 'Prossimamente',
                        href: baseUrl + '/it/p/prossimamente/',
                        id: 'upcoming',
                    },
                    {
                        label: 'Chi siamo',
                        href: baseUrl + '/sites/it/corporate/cosa-facciamo/?lang=it',
                        id: 'about',
                    },
                ],
            },
    
    };
});

export {
  $$default as default,
}
/* No side effect */
