export type Ok<T> = { tag: 'Ok'; value: T };
export type Error<T> = { tag: 'Error'; value: T };
export type Result<Value, Err> = Ok<Value> | Error<Err>;

/**
 * returns true if the value is a Result otherwise returns false
 *
 * @example
 * ```typescript
 * const a: Result<string, string> = { tag: 'Ok', value: 'a' }
 * const b: Result<string, string> = { tag: 'Error', value: 'b' }
 * const c: unknown = 'c'
 *
 * isResult(a) // true
 * isResult(b) // true
 * isResult(c) // false
 * ```
 */
const isResult = <Value, Error>(result: unknown): result is Result<Value, Error> => {
    return (
        typeof result !== 'undefined' &&
        result !== null &&
        typeof result === 'object' &&
        'tag' in result &&
        ((result as { tag: string }).tag === 'Ok' || (result as { tag: string }).tag === 'Error')
    );
};

/**
 * Returns true if the result is Ok.
 *
 * @example
 * ```typescript
 * const a: Result<string, string> = { tag: 'Ok', value: 'a' }
 * const b: Result<string, string> = { tag: 'Error', value: 'b' }
 *
 * isOk(a) // true
 * isOk(b) // false
 * ```
 */
export const isOk = <Value, Err>(result: Result<Value, Err>): result is Ok<Value> => {
    return result.tag === 'Ok';
};

/**
 * Returns true if the result is Error.
 *
 * @example
 * ```typescript
 * const a: Result<string, string> = { tag: 'Ok', value: 'a' }
 * const b: Result<string, string> = { tag: 'Error', value: 'b' }
 *
 * isError(a) // false
 * isError(b) // true
 * ```
 */
export const isError = <Value, Err>(result: Result<Value, Err>): result is Error<Err> => {
    if (!isResult(result)) return true;
    if (result.tag === 'Error') return true;
    return false;
};

export type NestedResult<T, Err> = Ok<T> | Error<Err> | Result<T, Err>;

/**
 * Returns the result if it is not a nested result, otherwise, returns the result of the nested result.
 *
 * @example
 * ```typescript
 * const a: Result<string, string> = { tag: 'Ok', value: 'a' }
 * const b: Result<string, string> = { tag: 'Error', value: 'b' }
 * const c: Result<string, string> = { tag: 'Ok', value: { tag: 'Ok', value: 'c' } }
 * const d: Result<string, string> = { tag: 'Ok', value: { tag: 'Error', value: 'd' } }
 * const e: Result<string, string> = { tag: 'Error', value: { tag: 'Ok', value: 'e' } }
 * const f: Result<string, string> = { tag: 'Error', value: { tag: 'Error', value: 'f' } }
 *
 * flattenResult(a) // { tag: 'Ok', value: 'a' }
 * flattenResult(b) // { tag: 'Error', value: 'b' }
 * flattenResult(c) // { tag: 'Ok', value: 'c' }
 * flattenResult(d) // { tag: 'Error', value: 'd' }
 * flattenResult(e) // { tag: 'Ok', value: 'e' }
 * flattenResult(f) // { tag: 'Error', value: 'f' }
 * ```
 */
export const flattenResult = <T, Err>(result: NestedResult<T, Err>): Result<T, Err> => {
    if (isOk(result) && isResult(result.value)) {
        return flattenResult(result.value as NestedResult<T, Err>);
    } else {
        if (isError(result) && isResult(result.value)) {
            return flattenResult(result.value as NestedResult<T, Err>);
        } else {
            return result;
        }
    }
};

/**
 * Returns the result of okFn if result is Ok(value), otherwise, returns the result of errorFn.
 *
 * @example
 * ```typescript
 * const a: Result<string, string> = { tag: 'Ok', value: 'a' }
 * const b: Result<string, string> = { tag: 'Error', value: 'b' }
 *
 * matchResult(a, (v) => v, (e) => e) // 'a'
 * matchResult(b, (v) => v, (e) => e) // 'b'
 * ```
 */
export const matchResult = <Value, Err, Return>(
    result: Result<Value, Err>,
    okFn: (v: Value) => Return,
    errorFn: (err: Err) => Return,
): Return => {
    if (isOk(result)) {
        return okFn(result.value);
    }
    return errorFn(result.value);
};

export const ok = <T>(value: T): Ok<T> => ({ tag: 'Ok', value });
export const err = <T>(value: T): Error<T> => ({ tag: 'Error', value });
