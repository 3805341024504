// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16Zm19.952-.65a16.846 16.846 0 0 0-.848-4.671c.41-.043.822-.09 1.233-.141l2.818-.352a11.633 11.633 0 0 1 1.527 5.164h-4.73Zm-1.301 0a15.541 15.541 0 0 0-.88-4.548c-3.086.25-6.186.257-9.272.021a15.539 15.539 0 0 0-.872 4.527H21.65Zm1.524-6.102c-.515.064-1.03.122-1.547.172a16.742 16.742 0 0 0-3.155-4.858 11.704 11.704 0 0 1 6.884 4.413l-2.181.273Zm-11.165.309c2.746.19 5.503.183 8.248-.02a15.48 15.48 0 0 0-3.137-4.504 1.39 1.39 0 0 0-1.965 0 15.478 15.478 0 0 0-3.146 4.524Zm1.858-5.063a16.743 16.743 0 0 0-3.23 4.952 57.825 57.825 0 0 1-1.813-.198l-2.182-.273a11.699 11.699 0 0 1 7.225-4.481ZM4.318 15.35a11.633 11.633 0 0 1 1.527-5.164l2.818.352c.5.063 1 .118 1.502.168a16.849 16.849 0 0 0-.84 4.644H4.319Zm6.309 1.3H21.65a15.541 15.541 0 0 1-.88 4.548 59.157 59.157 0 0 0-9.272-.021 15.537 15.537 0 0 1-.872-4.527Zm-.463 4.644a16.85 16.85 0 0 1-.838-4.644H4.318a11.633 11.633 0 0 0 1.527 5.164l2.818-.352c.5-.063 1-.119 1.502-.168Zm-3.521 1.73 2.182-.272a58.25 58.25 0 0 1 1.814-.198 16.744 16.744 0 0 0 3.229 4.952 11.698 11.698 0 0 1-7.225-4.481Zm5.367-.58a57.825 57.825 0 0 1 8.248.018 15.48 15.48 0 0 1-3.137 4.505 1.39 1.39 0 0 1-1.965 0 15.476 15.476 0 0 1-3.146-4.524Zm9.618.136a16.743 16.743 0 0 1-3.155 4.858 11.703 11.703 0 0 0 6.884-4.413l-2.181-.273a58.005 58.005 0 0 0-1.548-.172Zm4.527-.766a11.634 11.634 0 0 0 1.527-5.164h-4.73a16.846 16.846 0 0 1-.848 4.671c.41.043.822.09 1.233.141l2.818.352Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
