// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M5.60443 20.5356H3V26.3956V29H5.60443H11.4644V26.3956H5.60443V20.5356ZM29.1504 20.5356H26.546V26.3956H20.686V29H26.546H29.1504V26.3956V20.5356ZM11.4644 3H5.60443H3V5.60443V11.4644H5.60443V5.60443H11.4644V3ZM29.1504 3H26.546H20.686V5.60443H26.546V11.4644H29.1504V5.60443V3Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
