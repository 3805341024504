import { useEffect } from 'react';
import { eventStore } from './eventStore';
import { userStore } from './userStore';
import { toApiEvent } from './event';
import { send as makeSend } from './sendEvent';

type Config = {
    env: string;
    serverSideTrackingUrl: string;
    allowedEvents: string[];
    baseUrl: string;
    serverName: string;
    dryMode?: boolean;
};

/**
 * Here we subscribe to all events of the eventStore (not only pageview)
 */
/* istanbul ignore next */
export const subscribe = ({ env, serverSideTrackingUrl, allowedEvents, baseUrl, serverName, dryMode }: Config) => {
    const send = makeSend({
        baseUrl: baseUrl,
        serverSideTrackingUrl: serverSideTrackingUrl,
        serverName: serverName,
        dryMode,
    });
    return eventStore(env).subscribe(({ queue: eventQueue, forceSend }, { queue: previousEventQueue }) => {
        if (eventQueue.length === 0) {
            return;
        }
        const data = userStore.getState().getReadyData();
        if (!data) {
            // Skip silently because user data is not ready (can be because user didnt define his GDPR consents)
            return;
        }
        const [event, ...restEvents] = eventQueue;
        if (!event) {
            return;
        }
        if (event.behavior === 'DELAYED') {
            if (dryMode) {
                console.info('====SST EVENT DELAYED====', event);
            }
            eventStore(env).setState(
                {
                    queue: [
                        {
                            ...event,
                            behavior: 'IMMEDIATE',
                        },
                        ...restEvents,
                    ],
                    forceSend: false,
                },
                false,
                {
                    type: 'DELAYED_EVENT',
                    newEvent: event,
                },
            );
            return;
        }
        if (eventQueue.length !== previousEventQueue.length || forceSend) {
            // See tests/pageview.spec.tsx, 'should send event when data and event is ready'
            /* istanbul ignore next */
            // We should only send events allowed by feature flipping
            if (!allowedEvents.includes(event.type)) {
                eventStore(env).setState({ queue: restEvents, forceSend: false }, false, {
                    type: 'FEATURE_FLIPPING_SKIP_HIT',
                    newEvent: event,
                });
                if (dryMode) {
                    console.log(`TRACKING: ${event.type} event skipped because of feature flipping rules`);
                }
                return;
            }

            send(data, toApiEvent(serverSideTrackingUrl, event));
            eventStore(env).setState({ queue: restEvents, forceSend: false }, false, {
                type: 'SEND_HIT',
                newEvent: event,
            });
        }
    });
};

export const subscribeWithSelector = (env: string) =>
    userStore.subscribe(
        (state) => state.status,
        (state, prevState) => {
            if (state === 'ready' && state !== prevState) {
                eventStore(env).setState({ forceSend: true });
            }
        },
    );

export const useSendPageView = (config: Config) => {
    // See tests/pageview.spec.tsx, 'should call unsubscribe and unsubscribeWithSelector on cleanup'
    /* istanbul ignore next */
    useEffect(() => {
        const unsubscribe = subscribe(config);
        const unsubscribeWithSelector = subscribeWithSelector(config.env);
        return () => {
            unsubscribe();
            unsubscribeWithSelector();
        };
    }, []);
};
