// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Animation_Ds from "../../particle/Particle_Animation_Ds.res.mjs";
import * as Particle_Animation_Css from "../../particle/Particle_Animation_Css.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function animatedRules(hoverBehavior) {
  var tmp;
  var exit = 0;
  switch (hoverBehavior) {
    case "Disabled" :
        tmp = CssJs.empty([]);
        break;
    case "Normal" :
    case "Forced" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = CssJs.hover([CssJs.children(Particle_Animation_Css.toCss(Particle_Animation_Ds.TeaserGenre.titleHover))]);
  }
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
              CssJs.height(CssJs.pct(100)),
              tmp
            ]);
}

function Organism_Teaser_Animated_Title(props) {
  var style = props.style;
  var title = props.title;
  if (title !== undefined) {
    if (style !== undefined) {
      return JsxRuntime.jsx(Atom_Row.make, {
                  alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                  children: JsxRuntime.jsx(Atom_Text.make, {
                        children: Caml_option.some(title),
                        kind: "h3",
                        style: style
                      }),
                  height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                  justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                  rules: Caml_option.some(animatedRules(props.hoverBehavior)),
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                });
    } else {
      return JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                  children: title
                });
    }
  } else {
    return null;
  }
}

var make = Organism_Teaser_Animated_Title;

export {
  animatedRules ,
  make ,
}
/* CssJs Not a pure module */
