// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Spacer_Ds from "./Particle_Spacer_Ds.res.mjs";

var mobile = Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "M");

var desktop = Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "XL");

var firstMobile = Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "M");

var firstDesktop = Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "XL");

var mobile$1 = Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "Zero");

var firstMobile$1 = Particle_Spacer_Ds.make("Zero", "S", "Zero", "Zero");

var firstDesktop$1 = Particle_Spacer_Ds.make("Zero", "L", "XL", "XL");

var $$Event = {
  mobile: mobile$1,
  firstMobile: firstMobile$1,
  firstDesktop: firstDesktop$1
};

var withBackgroundMobile = Particle_Spacer_Ds.Both.make({
      top: "S",
      bottom: "S",
      left: "M",
      right: "M"
    }, {
      top: "S",
      bottom: "S",
      left: "M",
      right: "M"
    });

var withBackgroundDesktop = Particle_Spacer_Ds.Both.make({
      top: "L",
      bottom: "L",
      left: "XL",
      right: "XL"
    }, {
      top: "L",
      bottom: "L",
      left: "Zero",
      right: "Zero"
    });

var afterTopTeaserMobile = Particle_Spacer_Ds.make("Zero", "S", "M", "M");

var afterTopTeaserDesktop = Particle_Spacer_Ds.make("Zero", "L", "XL", "XL");

var Slider = {
  withBackgroundMobile: withBackgroundMobile,
  withBackgroundDesktop: withBackgroundDesktop,
  afterTopTeaserMobile: afterTopTeaserMobile,
  afterTopTeaserDesktop: afterTopTeaserDesktop
};

var Zone_defaultSpacerHorizontalValues = {
  default: "M",
  m: "XL"
};

var Zone = {
  defaultSpacerHorizontalValues: Zone_defaultSpacerHorizontalValues,
  mobile: mobile,
  desktop: desktop,
  firstMobile: firstMobile,
  firstDesktop: firstDesktop,
  $$Event: $$Event,
  Slider: Slider
};

var mobile$2 = Particle_Spacer_Ds.makeHorizontal("M");

var desktop$1 = Particle_Spacer_Ds.makeHorizontal("XL");

var PlayNext = {
  mobile: mobile$2,
  desktop: desktop$1
};

var mobile$3 = Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "M");

var desktop$2 = Particle_Spacer_Ds.make("XL", "L", "XL", "XL");

var ProgramContent = {
  mobile: mobile$3,
  desktop: desktop$2
};

var mobile$4 = Particle_Spacer_Ds.makeTop("M");

var desktop$3 = Particle_Spacer_Ds.makeTop("XL");

var Label = {
  mobile: mobile$4,
  desktop: desktop$3
};

var ProgramPage = {
  PlayNext: PlayNext,
  ProgramContent: ProgramContent,
  Label: Label
};

var dropdown = Particle_Spacer_Ds.makeTop("S");

var grid = Particle_Spacer_Ds.makeTop("L");

var ListPage = {
  dropdown: dropdown,
  grid: grid
};

var allZero = Particle_Spacer_Ds.makeAll("Zero");

export {
  Zone ,
  ProgramPage ,
  ListPage ,
  allZero ,
}
/* mobile Not a pure module */
