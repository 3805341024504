'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Webapi__Dom__Document from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.res.mjs";

function handleBlur(currentTarget, e, callback) {
  requestAnimationFrame(function (param) {
        var isEscapedPressed = Util_Dom.$$KeyboardEvent.isKeyPressed(e, "Escape");
        var activeElement = Core__Option.flatMap(Core__Option.flatMap(Util_Dom.SafeWebApi.$$document, Webapi__Dom__Document.asHtmlDocument), (function (prim) {
                return Caml_option.nullable_to_opt(prim.activeElement);
              }));
        var isActive = activeElement !== undefined ? currentTarget.contains(Caml_option.valFromOption(activeElement)) : false;
        if (!isActive || isEscapedPressed) {
          return callback();
        }
        
      });
}

function make(ref, onBlur) {
  var handleKeyDown = function (e) {
    Core__Option.forEach(Util_Dom.getDomRef(ref), (function (refValue) {
            handleBlur(refValue, e, onBlur);
          }));
  };
  React.useEffect((function () {
          document.addEventListener("keydown", handleKeyDown);
          return (function () {
                    document.removeEventListener("keydown", handleKeyDown);
                  });
        }), []);
}

export {
  handleBlur ,
  make ,
}
/* react Not a pure module */
