// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Header from "../../type/Type_Header.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";

var header_blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "S"));

var header_height = Type_Header.Constants.heightStyle;

var header_borderStyle = {
  active: Particle_Border_Ds.Header.$$default,
  inactive: Particle_Border_Ds.Header.$$default,
  focus: Particle_Border_Ds.accessibility,
  line: Particle_Border_Ds.burgerMenuLine
};

var header_width = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "px",
      VAL: 55
    });

var header = {
  background: Particle_Background_Ds.dropdownMenu,
  blockSpace: header_blockSpace,
  height: header_height,
  borderStyle: header_borderStyle,
  width: header_width
};

var corporate_background = Particle_Background_Ds.Corporate.menu;

var corporate_blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "S"));

var corporate_height = Type_Header.Constants.heightStyle;

var corporate_borderStyle = {
  active: Particle_Border_Ds.Corporate.burgerMenu,
  inactive: Particle_Border_Ds.Cta.inactiveMedium,
  focus: Particle_Border_Ds.Corporate.accessibility,
  line: Particle_Border_Ds.Corporate.burgerMenuLine
};

var corporate_width = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "px",
      VAL: 60
    });

var corporate = {
  background: corporate_background,
  blockSpace: corporate_blockSpace,
  height: corporate_height,
  borderStyle: corporate_borderStyle,
  width: corporate_width
};

var presse_background = Particle_Background_Ds.Presse.menu;

var presse_blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "XXS"));

var presse_height = Type_Header.Constants.heightStyle;

var presse_borderStyle = {
  active: Particle_Border_Ds.Presse.burgerMenu,
  inactive: Particle_Border_Ds.Cta.inactiveMedium,
  focus: Particle_Border_Ds.accessibility,
  line: Particle_Border_Ds.Presse.burgerMenuLine
};

var presse_width = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "px",
      VAL: 40
    });

var presse = {
  background: presse_background,
  blockSpace: presse_blockSpace,
  height: presse_height,
  borderStyle: presse_borderStyle,
  width: presse_width
};

function view(param) {
  return {
          background: param.background,
          blockSpace: param.blockSpace,
          height: param.height,
          borderStyle: param.borderStyle,
          width: param.width
        };
}

export {
  header ,
  corporate ,
  presse ,
  view ,
}
/* header Not a pure module */
