// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1711 8.83456 29 16 29C23.1711 29 29 23.1711 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.83456 3 16ZM4.47144 16C4.47144 9.64544 9.64544 4.47144 16 4.47144C22.3546 4.47144 27.5286 9.64544 27.5286 16C27.5286 22.3594 22.3546 27.5286 16 27.5286C9.64056 27.5286 4.47144 22.3594 4.47144 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M8.46488 16.026V16.0471C8.46488 18.1166 10.0777 19.7911 12.3234 19.7911C14.5692 19.7911 16.2023 18.0954 16.2023 16.026V16.0049C16.2023 13.9354 14.5895 12.2609 12.3438 12.2609C10.098 12.2609 8.46488 13.9566 8.46488 16.026ZM14.5286 16.026V16.0471C14.5286 17.2951 13.6348 18.314 12.3446 18.314C11.0543 18.314 10.1394 17.274 10.1394 16.026V16.0049C10.1394 14.7569 11.0332 13.738 12.3234 13.738C13.6137 13.738 14.5286 14.778 14.5286 16.026Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M16.5354 12.386H18.3034L20.2063 17.5129L22.1092 12.386H23.8357L20.8929 19.718H19.4783L16.5354 12.386Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
