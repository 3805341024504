// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseGuideZone from "../../../../hook/UseGuideZone.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_Guide_Loader from "../Molecule_Guide_Loader.res.mjs";
import * as Layout_Guide_Tv_Slider from "./Layout_Guide_Tv_Slider.res.mjs";
import * as Layout_Guide_Tv_Columns from "./Layout_Guide_Tv_Columns.res.mjs";

function Layout_Guide_Tv(props) {
  var convertDateToTimezone = props.convertDateToTimezone;
  var loading = props.loading;
  var pageKind = props.pageKind;
  var match = Context.I18n.use();
  var zone = UseGuideZone.make(props.page);
  var layoutGuideColumnsRef = React.useRef(null);
  var teasers = Core__Option.getOr(Core__Option.map(zone, (function (param) {
              return param.data;
            })), []);
  var guideTvColumns = React.useMemo((function () {
          if (loading === "Ready") {
            return JsxRuntime.jsx(Layout_Guide_Tv_Columns.make, {
                        teasers: teasers,
                        layoutGuideColumnsRef: layoutGuideColumnsRef,
                        pageKind: pageKind,
                        convertDateToTimezone: convertDateToTimezone
                      });
          } else {
            return JsxRuntime.jsx(Molecule_Guide_Loader.make, {});
          }
        }), [
        teasers,
        match.locale,
        loading
      ]);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Layout_Guide_Tv_Slider.make, {
                      onClickItem: props.onClickItem,
                      date: props.initialDate,
                      layoutGuideColumnsRef: layoutGuideColumnsRef
                    }),
                guideTvColumns
              ],
              kind: "Main"
            });
}

var make = Layout_Guide_Tv;

export {
  make ,
}
/* react Not a pure module */
