import { Geoblocking } from '@artegeie/design-system/type';

const GEOBLOCKING: readonly Geoblocking[] = ['ALL', 'DE_FR', 'EUR_DE_FR', 'SAT'] as const;
const GEOBLOCKING_DEFAULTVALUE: Geoblocking = 'DE_FR';

const isGeoblocking = (geoblocking: string | null): geoblocking is Geoblocking =>
    GEOBLOCKING.includes(geoblocking as Geoblocking);

export const makeGeoblockingFromString = (geoblocking: string | null): Geoblocking => {
    if (!isGeoblocking(geoblocking)) {
        return GEOBLOCKING_DEFAULTVALUE;
    }
    return geoblocking;
};
