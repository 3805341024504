// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as UseRefs from "../../hook/UseRefs.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as SetFocus from "../../hook/SetFocus.res.mjs";
import * as Util_Dom from "../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Grid from "../atom/Atom_Grid.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Core__Int from "@rescript/core/src/Core__Int.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as UseNextPage from "../../hook/UseNextPage.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UsePagination from "../../hook/UsePagination.res.mjs";
import * as Molecule_Modal from "./Molecule_Modal.res.mjs";
import * as Organism_Teaser from "../organism/teaser/Organism_Teaser.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as Organism_Dropdown from "../organism/dropdown/Organism_Dropdown.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Teaser_Ds from "../organism/teaser/Organism_Teaser_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Teaser_Css from "../organism/teaser/Organism_Teaser_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as User_Api__Sso__Favorites from "../../user/User_Api/User_Api__Sso__Favorites.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as User_Api__Sso__LastVieweds from "../../user/User_Api/User_Api__Sso__LastVieweds.res.mjs";

var gridGap = Particle_Screen_Ds.make(undefined, "M", undefined, undefined, undefined, "XS");

function makeBy(lastPage, maxPage, step, getPageUrl, pageUrl) {
  return Core__Array.reduce((function (__x) {
                  return Belt_Array.rangeBy(0, __x, step);
                })(Math.min(maxPage, lastPage)), [], (function (acc, page) {
                var match = getPageUrl(pageUrl, page);
                if (page !== 0 && match !== undefined) {
                  acc.push(JsxRuntime.jsx(Atom_Cta.Seo.make, {
                            href: match,
                            label: ""
                          }, String(page)));
                  return acc;
                } else {
                  return acc;
                }
              }));
}

function makeFirstNinePageLinks(lastPage, pageUrl, getPageUrl) {
  return makeBy(lastPage, 9, 1, getPageUrl, pageUrl);
}

function makeTensPageLink(lastPage, pageUrl, getPageUrl) {
  return makeBy(lastPage, 99, 10, getPageUrl, pageUrl);
}

function makeHundredsPageLink(lastPage, pageUrl, getPageUrl) {
  return makeBy(lastPage, Core__Int.Constants.maxValue, 100, getPageUrl, pageUrl);
}

var SEO = {
  makeBy: makeBy,
  makeFirstNinePageLinks: makeFirstNinePageLinks,
  makeTensPageLink: makeTensPageLink,
  makeHundredsPageLink: makeHundredsPageLink
};

function itemFromZone(zone) {
  var match = zone.title;
  return {
          value: Type_String.Dropdown.Item.make(zone.id),
          label: match !== undefined ? Type_String.Dropdown.Item.make(match._0) : Type_String.Dropdown.Item.empty
        };
}

function Molecule_PaginatedGrid$Dropdown(props) {
  var onChange = props.onChange;
  var zones = props.zones;
  var match = Context.I18n.use();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.videos;
  var items = Util_Array.NonEmpty.makeFromArray(zones.map(itemFromZone));
  if (items !== undefined) {
    return JsxRuntime.jsx(Organism_Dropdown.make, {
                kind: {
                  TAG: "Select",
                  _0: {
                    name: "sort_by_concertgenre",
                    title: match$1.showVideosSortingOptions,
                    autoComplete: "off",
                    variant: "concertGenre"
                  }
                },
                items: Caml_option.valFromOption(items),
                label: match$2.sort_by_label,
                onChange: (function (item) {
                    var findedZone = zones.find(function (zone) {
                          return zone.id === Type_String.Dropdown.Item.toString(item.value);
                        });
                    if (findedZone !== undefined) {
                      return onChange(findedZone, item.label);
                    }
                    
                  })
              });
  } else {
    return null;
  }
}

var Dropdown = {
  itemFromZone: itemFromZone,
  make: Molecule_PaginatedGrid$Dropdown
};

function createUsePageConfig(async, teasers) {
  return UsePagination.makeConfig(async, 20, {
              TAG: "NumberElement",
              _0: teasers.length
            }, undefined);
}

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeNone(), undefined, undefined, Particle_Spacer_Ds.makeTop("XS"));

function Molecule_PaginatedGrid$ActionButton(props) {
  var link = props.link;
  var controller = new AbortController();
  var signal = controller.signal;
  var match = Context.Config.use();
  var match$1 = Context.I18n.use();
  var locale = match$1.locale;
  var match$2 = match$1.labels.mona.myVideos;
  var onPurgeCallback = match.api.onPurgeCallback;
  var match$3 = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var toggleModal = match$3.toggle;
  var match$4 = link.page;
  var title = link.title;
  if (title === undefined) {
    return null;
  }
  if (match$4 === undefined) {
    return null;
  }
  if (typeof match$4 !== "object") {
    return null;
  }
  var action = match$4._0;
  var tmp;
  tmp = action === "PurgeHistory" ? match$2.purgeHistoryModalTitle : match$2.purgeFavoriteModalTitle;
  var tmp$1;
  tmp$1 = action === "PurgeHistory" ? match$2.purgeHistoryModalText : match$2.purgeFavoriteModalText;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      blockSpace: Caml_option.some(blockSpace),
                      children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                            identifier: Caml_option.some(Identifiers.PaginatedGrid.actionButton),
                            label: title,
                            onClick: toggleModal,
                            style: Atom_Cta_Ds.paginatedAction
                          })
                    }),
                JsxRuntime.jsxs(Molecule_Modal.make, {
                      onClose: match$3.close,
                      isOpen: match$3.stateBoolean,
                      title: tmp,
                      id: Identifiers.Modal.purgeAction,
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(tmp$1)
                            }),
                        JsxRuntime.jsxs(Atom_Row.make, {
                              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"))),
                              children: [
                                JsxRuntime.jsx(Atom_Cta.Button.make, {
                                      label: match$2.purgeModalConfirm,
                                      onClick: (function (param) {
                                          if (action === "PurgeHistory") {
                                            User_Observable.Progression.purgeProgression();
                                            User_Api__Sso__LastVieweds.purge({
                                                    signal: signal,
                                                    locale: locale
                                                  }).then(function (param) {
                                                  onPurgeCallback("History");
                                                });
                                            return ;
                                          }
                                          User_Observable.Favorite.purgeFavorites();
                                          User_Api__Sso__Favorites.purge({
                                                  signal: signal,
                                                  locale: locale
                                                }).then(function (param) {
                                                onPurgeCallback("Favorites");
                                              });
                                        }),
                                      style: Atom_Cta_Ds.User.base
                                    }),
                                JsxRuntime.jsx(Atom_Cta.Button.make, {
                                      label: match$2.purgeModalCancel,
                                      onClick: toggleModal,
                                      style: Atom_Cta_Ds.User.makeOutline(false, "Internal")
                                    })
                              ],
                              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S"))
                            })
                      ]
                    })
              ]
            });
}

var ActionButton = {
  blockSpace: blockSpace,
  make: Molecule_PaginatedGrid$ActionButton
};

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS");

var titleInnerspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var numberColumn = Particle_Screen_Ds.make(undefined, undefined, 3, 4, 5, 2);

var gridRules = Particle_Screen_Ds.map(numberColumn, (function (columnCount) {
        return [CssJs.gridTemplateColumns([{
                        NAME: "repeat",
                        VAL: [
                          {
                            NAME: "num",
                            VAL: columnCount
                          },
                          CssJs.fr(1)
                        ]
                      }])];
      }));

function make(param) {
  var url = param.url;
  var relatedZones = param.relatedZones;
  var zone = param.zone;
  var title = param.title;
  var match = Context.Config.use();
  var totalPagesCount = zone.totalPagesCount;
  var lastPage = zone.lastPage;
  var nextPage = zone.nextPage;
  var teasers = zone.data;
  var api = match.api;
  var getPageUrl = api.getPageUrl;
  var getNextPageUrl = api.getNextPageUrl;
  var initialNextPage = nextPage !== undefined && !(nextPage.TAG === "Club" || url === undefined) ? getNextPageUrl(url, nextPage._0) : undefined;
  var initialLastPage = lastPage !== undefined && url !== undefined ? getNextPageUrl(url, lastPage) : undefined;
  var match$1 = UseRefs.use();
  var setRef = match$1[1];
  var getRef = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setChunkIdToFocus = match$2[1];
  var chunkIdToFocus = match$2[0];
  var match$3 = UseNextPage.make(zone);
  var reinitUseNextPage = match$3.reset;
  var previousChunksCount = React.useRef(0);
  var match$4 = UsePagination.use(createUsePageConfig(match$3.value, teasers), teasers);
  var resetPagination = match$4.reset;
  var chunks = match$4.data;
  var status = match$4.status;
  var loadMore = match$4.loadMore;
  React.useEffect((function () {
          var exit = 0;
          switch (status) {
            case "Completed" :
            case "Idle" :
                if (chunks.length !== 1) {
                  exit = 1;
                }
                break;
            default:
              
          }
          if (exit === 1) {
            var newChunkId = Core__Option.map(Belt_Array.get(chunks, previousChunksCount.current), (function (param) {
                    return param.id;
                  }));
            setChunkIdToFocus(function (param) {
                  return newChunkId;
                });
          }
          previousChunksCount.current = chunks.length;
        }), [
        status,
        chunks
      ]);
  var ctaAccessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, param.seeMoreAriaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var handleClick = function (param) {
    loadMore();
  };
  var teasers$1 = React.useMemo((function () {
          return chunks.map(function (teaser) {
                      var ref = setRef(Type_String.Teaser.Id.toString(teaser.id));
                      var match = zone.theme;
                      return JsxRuntime.jsx(Organism_Teaser.make, {
                                  style: match === "User" ? Organism_Teaser_Ds.user("PaginatedTeaser") : Organism_Teaser_Ds.classic("PaginatedTeaser", zone.genreStickerBehavior),
                                  teaser: teaser,
                                  teaserRef: ref
                                }, Type_String.Teaser.Id.toString(teaser.id));
                    });
        }), [
        chunks,
        zone.itemTitle,
        "PaginatedTeaser",
        setRef
      ]);
  React.useEffect((function () {
          SetFocus.setFocus(Core__Option.flatMap(Core__Option.flatMap(Core__Option.map(chunkIdToFocus, Type_String.Teaser.Id.toString), getRef), Util_Dom.getFirstElementFocusable));
        }), [chunkIdToFocus]);
  var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
        Particle_zIndex.Particle_zIndex_Css.toCss({
              TAG: "Exact",
              _0: "PaginatedGrid"
            }),
        Organism_Teaser_Css.Selectors.teaserItemsSelector([CssJs.width(CssJs.pct(100))])
      ]);
  var seeMoreButton;
  seeMoreButton = status === "Completed" ? null : JsxRuntime.jsx(Atom_Cell.make, {
          alignSelf: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
          children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                accessibility: ctaAccessibility,
                identifier: Caml_option.some(Identifiers.PaginatedGrid.seeMoreButton),
                label: param.seeMoreLabel,
                onClick: handleClick,
                style: Atom_Cta_Ds.seeMore
              })
        });
  var match$5 = React.useState(function () {
        return title;
      });
  var setTitle = match$5[1];
  var title$1 = match$5[0];
  var handleSelectChange = function (zone, zoneLabel) {
    var newAsync = reinitUseNextPage(zone);
    var newConfig = createUsePageConfig(newAsync, zone.data);
    setTitle(function (param) {
          return Type_String.Dropdown.Item.toString(zoneLabel);
        });
    resetPagination(newConfig, zone.data);
  };
  var match$6 = zone.link;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: param.blockSpace,
              children: [
                title$1 !== undefined ? (
                    match$6 !== undefined ? JsxRuntime.jsxs(Atom_Row.make, {
                            alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsBaseline),
                            children: [
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(title$1),
                                    kind: "h2",
                                    style: Atom_Text_Ds.paginatedGrid
                                  }),
                              JsxRuntime.jsx(Molecule_PaginatedGrid$ActionButton, {
                                    link: match$6
                                  })
                            ],
                            innerSpace: Caml_option.some(titleInnerspace),
                            justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
                          }) : JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(title$1),
                            kind: "h2",
                            style: Atom_Text_Ds.paginatedGrid
                          })
                  ) : null,
                relatedZones !== undefined ? JsxRuntime.jsx(Molecule_PaginatedGrid$Dropdown, {
                        zones: [zone].concat(relatedZones),
                        onChange: handleSelectChange
                      }) : null,
                JsxRuntime.jsx(Atom_Grid.make, {
                      children: teasers$1,
                      gap: Caml_option.some(gridGap),
                      rules: Caml_option.some(gridRules)
                    }),
                seeMoreButton,
                totalPagesCount !== undefined && url !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          makeFirstNinePageLinks(totalPagesCount, url, getPageUrl),
                          makeTensPageLink(totalPagesCount, url, getPageUrl),
                          makeHundredsPageLink(totalPagesCount, url, getPageUrl)
                        ]
                      }) : null,
                initialNextPage !== undefined ? JsxRuntime.jsx(Atom_Cta.Seo.make, {
                        href: initialNextPage,
                        label: "Next"
                      }) : null,
                initialLastPage !== undefined ? JsxRuntime.jsx(Atom_Cta.Seo.make, {
                        href: initialLastPage,
                        label: "Last"
                      }) : null
              ],
              id: param.id,
              identifier: param.identifier,
              innerSpace: Caml_option.some(innerSpace),
              ref_: param.ref_,
              rules: Caml_option.some(wrapperRules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var elementsPerChunk = 20;

export {
  gridGap ,
  elementsPerChunk ,
  SEO ,
  Dropdown ,
  createUsePageConfig ,
  ActionButton ,
  innerSpace ,
  titleInnerspace ,
  numberColumn ,
  gridRules ,
  make ,
}
/* gridGap Not a pure module */
