// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as Core__String from "@rescript/core/src/Core__String.res.mjs";
import * as Belt_MutableMap from "@rescript/std/lib/es6/belt_MutableMap.js";

function cmp(a, b) {
  return Core__String.compare(a, b) | 0;
}

var StringComparable = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function make(comparable) {
  var refs = React.useMemo((function () {
          return Belt_MutableMap.make(comparable);
        }), []);
  var getRef = React.useCallback((function (key) {
          return Belt_MutableMap.get(refs, key);
        }), [refs]);
  var setRef = React.useCallback((function (key) {
          var currentRef = getRef(key);
          var ref = currentRef !== undefined ? currentRef : React.createRef();
          Belt_MutableMap.set(refs, key, ref);
          return ref;
        }), [
        refs,
        getRef
      ]);
  return [
          getRef,
          setRef
        ];
}

function use() {
  return make(StringComparable);
}

export {
  StringComparable ,
  make ,
  use ,
}
/* StringComparable Not a pure module */
