// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User_Api_Config from "./User_Api_Config.res.mjs";

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

var endpoint = {
  NAME: "Static",
  VAL: "logout"
};

var options = {
  tokenKind: "User",
  userRestriction: "Connected"
};

function makeBody(param) {
  
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var PostConfig = {
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  method: "Post",
  options: options,
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Post = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode,
      errorResponse_decode: errorResponse_decode,
      domain: "Sso",
      mapError: mapError,
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var logout = Post.call;

export {
  PostConfig ,
  Post ,
  logout ,
}
/* Post Not a pure module */
