// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as SetFocus from "../../hook/SetFocus.res.mjs";
import * as Util_Dom from "../../util/Util_Dom.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as UseScreenResolution from "../../hook/UseScreenResolution.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Molecule_ExpanderText_Css from "./Molecule_ExpanderText_Css.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var readMoreDisplayOrder = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2);

var childWrapperAccessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1);

var defaultMinHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "px",
      VAL: 150
    });

function make(param) {
  var onClick = param.onClick;
  var textStyle = param.textStyle;
  var blockSpace = param.blockSpace;
  var innerSpace = param.innerSpace;
  var children = param.children;
  var behavior = Core__Option.getOr(param.behavior, "Auto");
  var variant = Core__Option.getOr(param.variant, "Standard");
  var id = React.useId();
  var ref = React.useRef(null);
  var match = Context.I18n.use();
  var labels = match.labels;
  var match$1 = labels.common;
  var readMoreLabel = match$1.read_more;
  var readLessLabel = match$1.read_less;
  var match$2 = labels.accessibility;
  var screenSize = UseScreenResolution.make(undefined, undefined);
  var match$3 = React.useState(function () {
        return "NotNeeded";
      });
  var setReadButtonsConvenience = match$3[1];
  var readButtonsConvenience = match$3[0];
  React.useEffect((function () {
          var element = Util_Dom.getDomRef(ref);
          if (element !== undefined) {
            var element$1 = Caml_option.valFromOption(element);
            var height = element$1.clientHeight;
            var scrollHeight = element$1.scrollHeight;
            if (height < scrollHeight) {
              setReadButtonsConvenience(function (param) {
                    return "Needed";
                  });
            }
            
          }
          
        }), []);
  var minHeight = Core__Option.getOr(param.minHeight, defaultMinHeight);
  var match$4;
  match$4 = variant === "Standard" ? [
      Particle_Screen_Ds.$$Array.concatMany([
            Molecule_ExpanderText_Css.rulesGradient(textStyle),
            Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.flexGrow(1)])
          ]),
      Caml_option.some(minHeight)
    ] : [
      Particle_Screen_Ds.$$Array.concatMany([
            Molecule_ExpanderText_Css.rulesGradient(textStyle),
            Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.flexGrow(1)])
          ]),
      undefined
    ];
  var match$5 = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var state = match$5.state;
  var toggleRaw = match$5.toggleRaw;
  React.useEffect((function () {
          if (state === "On") {
            SetFocus.ByRef.make(ref);
          }
          
        }), [state]);
  var match$6;
  match$6 = state === "On" || behavior !== "Auto" ? [
      readLessLabel,
      Atom_Cta_Ds.seeLessExpanderButton,
      undefined,
      undefined
    ] : [
      readMoreLabel,
      Atom_Cta_Ds.seeMoreExpanderButton,
      Caml_option.some(match$4[0]),
      match$4[1]
    ];
  var rules = match$6[2];
  var styleCta = match$6[1];
  var handleOnClick = function (param) {
    toggleRaw();
    if (onClick !== undefined) {
      return onClick(state);
    }
    
  };
  var childrenRules;
  var exit = 0;
  if (state === "On" || behavior !== "Auto") {
    exit = 1;
  } else {
    childrenRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
          CssJs.height("zero"),
          CssJs.flexGrow(1),
          CssJs.overflow("hidden")
        ]);
  }
  if (exit === 1) {
    childrenRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.flexGrow(1)]);
  }
  if (children === undefined) {
    return null;
  }
  var children$1 = Caml_option.valFromOption(children);
  if (children$1 === null) {
    return null;
  }
  if (variant === "PresseRc") {
    var behavior$1;
    if (typeof screenSize === "object") {
      var variant$1 = screenSize.NAME;
      behavior$1 = variant$1 === "XS" || variant$1 === "S" || variant$1 === "M" ? "Auto" : "AlwaysExpanded";
    } else {
      behavior$1 = "AlwaysExpanded";
    }
    var tmp;
    var exit$1 = 0;
    if (state === "On" || behavior$1 !== "Auto") {
      exit$1 = 1;
    } else {
      tmp = null;
    }
    if (exit$1 === 1) {
      var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1);
      tmp = JsxRuntime.jsx(Atom_Column.make, {
            accessibility: accessibility,
            children: children$1,
            innerSpace: innerSpace,
            ref_: ref
          });
    }
    var tmp$1;
    tmp$1 = behavior$1 === "Auto" ? JsxRuntime.jsx(Atom_Cta.Button.make, {
            accessibility: Particle_Accessibility.make(id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            label: match$6[0],
            onClick: handleOnClick,
            style: styleCta
          }) : null;
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Atom_Column.make, {
                        blockSpace: blockSpace,
                        children: tmp,
                        id: id,
                        innerSpace: innerSpace,
                        minHeight: match$6[3],
                        position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                        rules: rules
                      }),
                  tmp$1
                ]
              });
  }
  var tmp$2;
  var exit$2 = 0;
  if (behavior === "Auto") {
    if (state === "On" || readButtonsConvenience !== "Needed") {
      exit$2 = 1;
    } else {
      tmp$2 = JsxRuntime.jsx(Atom_Cta.Button.make, {
            accessibility: Particle_Accessibility.make(id, undefined, undefined, undefined, undefined, undefined, match$2.readMore, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            displayOrder: Caml_option.some(readMoreDisplayOrder),
            label: readMoreLabel,
            onClick: handleOnClick,
            style: styleCta
          });
    }
  } else {
    tmp$2 = null;
  }
  if (exit$2 === 1) {
    tmp$2 = null;
  }
  var tmp$3;
  var exit$3 = 0;
  if (behavior === "Auto") {
    if (state === "On" && readButtonsConvenience === "Needed") {
      tmp$3 = JsxRuntime.jsx(Atom_Cta.Button.make, {
            accessibility: Particle_Accessibility.make(id, undefined, undefined, undefined, undefined, undefined, match$2.readLess, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            label: readLessLabel,
            onClick: handleOnClick,
            style: styleCta
          });
    } else {
      exit$3 = 1;
    }
  } else {
    tmp$3 = null;
  }
  if (exit$3 === 1) {
    tmp$3 = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Column.make, {
                        blockSpace: blockSpace,
                        children: [
                          tmp$2,
                          JsxRuntime.jsx(Atom_Column.make, {
                                accessibility: childWrapperAccessibility,
                                children: children$1,
                                innerSpace: innerSpace,
                                ref_: ref,
                                rules: Caml_option.some(childrenRules)
                              }),
                          tmp$3
                        ],
                        id: id,
                        innerSpace: innerSpace,
                        maxWidth: param.maxWidth,
                        position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                        rules: rules
                      }))
            });
}

export {
  readMoreDisplayOrder ,
  childWrapperAccessibility ,
  defaultMinHeight ,
  make ,
}
/* readMoreDisplayOrder Not a pure module */
