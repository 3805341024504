export const isValidAge = (ageValue: string) => {
    switch (ageValue) {
        case '18':
        case '16':
        case '0':
            return true;
        default:
            return false;
    }
};
