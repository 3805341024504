import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { type I18nProvider_Props } from '../types/translations';

export const getConfig = (locale: string, phrases: I18nProvider_Props['labels']) => ({
    lng: locale,
    resources: {
        [locale]: {
            translation: phrases,
        },
    },
    debug: false,
    interpolation: { escapeValue: false },
});
export function translate(locale: string, phrases: I18nProvider_Props['labels']) {
    const instance = i18next.use(initReactI18next).createInstance();

    instance.init(getConfig(locale, phrases));

    return { i18n: instance, translate: instance.t };
}
