// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

var maxWidthMainContent = CssJs.rem(100);

var maxWidthMainContentScreen = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.rem(100));

var margin = CssJs.margin("auto");

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("S"));

export {
  maxWidthMainContent ,
  maxWidthMainContentScreen ,
  margin ,
  blockSpace ,
}
/* maxWidthMainContent Not a pure module */
