// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Js_string from "@rescript/std/lib/es6/js_string.js";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../type/Type_Avatar.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Type_Teaser from "../type/Type_Teaser.res.mjs";
import * as Binding_Date from "../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as $$Date from "@artegeie/date";
import * as Type_Progression from "../type/Type_Progression.res.mjs";

function t_encode(v) {
  return Spice.optionToJson(Spice.stringToJson, v);
}

function t_decode(v) {
  return Spice.optionFromJson(Spice.stringFromJson, v);
}

function make(str) {
  if (str === "") {
    return ;
  } else {
    return str;
  }
}

function get(t) {
  return t;
}

function isDisconnected(t) {
  return t === undefined;
}

function createBearerToken(t) {
  return Core__Option.map(t, (function (extra) {
                return "Bearer ".concat(extra);
              }));
}

function areEquals(t1, t2) {
  if (t1 !== undefined && t2 !== undefined) {
    return Caml_obj.equal(Caml_option.valFromOption(t1), Caml_option.valFromOption(t2));
  } else {
    return false;
  }
}

function toString(t) {
  if (t !== undefined) {
    return t;
  } else {
    return "Disconnected";
  }
}

function fromToken(token) {
  if (token !== undefined) {
    if (Js_string.includes("anonymous_", token)) {
      return "Anonymous";
    } else {
      return "Connected";
    }
  } else {
    return "Disconnected";
  }
}

var Status = {
  fromToken: fromToken
};

function t_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.id)
                  ],
                  [
                    "acceptCom",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.boolToJson, extra);
                        })(v.acceptCom)
                  ],
                  [
                    "email",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.email)
                  ],
                  [
                    "gender",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Type_Club.User.gender_encode, extra);
                        })(v.gender)
                  ],
                  [
                    "firstName",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.firstName)
                  ],
                  [
                    "lastName",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.lastName)
                  ],
                  [
                    "street",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.street)
                  ],
                  [
                    "city",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.city)
                  ],
                  [
                    "zipCode",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.zipCode)
                  ],
                  [
                    "country",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.country)
                  ],
                  [
                    "birthdate",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.birthdate)
                  ],
                  [
                    "over16",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.boolToJson, extra);
                        })(v.over16)
                  ],
                  [
                    "over18",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.boolToJson, extra);
                        })(v.over18)
                  ],
                  [
                    "ageVerificationToken",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.ageVerificationToken)
                  ],
                  [
                    "isSubscribedToNewsletter",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.boolToJson, extra);
                        })(v.isSubscribedToNewsletter)
                  ],
                  [
                    "avatar",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Type_Avatar.t_encode, extra);
                        })(v.avatar)
                  ],
                  [
                    "eventsParticipation",
                    true,
                    (function (extra) {
                          return Spice.optionToJson((function (extra) {
                                        return Spice.arrayToJson(Spice.stringToJson, extra);
                                      }), extra);
                        })(v.eventsParticipation)
                  ]
                ]));
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = (function (extra) {
        return Spice.optionFromJson(Spice.stringFromJson, extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var acceptCom = (function (extra) {
          return Spice.optionFromJson(Spice.boolFromJson, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "acceptCom"), null));
    if (acceptCom.TAG === "Ok") {
      var email = (function (extra) {
            return Spice.optionFromJson(Spice.stringFromJson, extra);
          })(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var gender = (function (extra) {
              return Spice.optionFromJson(Type_Club.User.gender_decode, extra);
            })(Belt_Option.getWithDefault(Js_dict.get(v, "gender"), null));
        if (gender.TAG === "Ok") {
          var firstName = (function (extra) {
                return Spice.optionFromJson(Spice.stringFromJson, extra);
              })(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
          if (firstName.TAG === "Ok") {
            var lastName = (function (extra) {
                  return Spice.optionFromJson(Spice.stringFromJson, extra);
                })(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
            if (lastName.TAG === "Ok") {
              var street = (function (extra) {
                    return Spice.optionFromJson(Spice.stringFromJson, extra);
                  })(Belt_Option.getWithDefault(Js_dict.get(v, "street"), null));
              if (street.TAG === "Ok") {
                var city = (function (extra) {
                      return Spice.optionFromJson(Spice.stringFromJson, extra);
                    })(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
                if (city.TAG === "Ok") {
                  var zipCode = (function (extra) {
                        return Spice.optionFromJson(Spice.stringFromJson, extra);
                      })(Belt_Option.getWithDefault(Js_dict.get(v, "zipCode"), null));
                  if (zipCode.TAG === "Ok") {
                    var country = (function (extra) {
                          return Spice.optionFromJson(Spice.stringFromJson, extra);
                        })(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
                    if (country.TAG === "Ok") {
                      var birthdate = (function (extra) {
                            return Spice.optionFromJson(Spice.stringFromJson, extra);
                          })(Belt_Option.getWithDefault(Js_dict.get(v, "birthdate"), null));
                      if (birthdate.TAG === "Ok") {
                        var over16 = (function (extra) {
                              return Spice.optionFromJson(Spice.boolFromJson, extra);
                            })(Belt_Option.getWithDefault(Js_dict.get(v, "over16"), null));
                        if (over16.TAG === "Ok") {
                          var over18 = (function (extra) {
                                return Spice.optionFromJson(Spice.boolFromJson, extra);
                              })(Belt_Option.getWithDefault(Js_dict.get(v, "over18"), null));
                          if (over18.TAG === "Ok") {
                            var ageVerificationToken = (function (extra) {
                                  return Spice.optionFromJson(Spice.stringFromJson, extra);
                                })(Belt_Option.getWithDefault(Js_dict.get(v, "ageVerificationToken"), null));
                            if (ageVerificationToken.TAG === "Ok") {
                              var isSubscribedToNewsletter = (function (extra) {
                                    return Spice.optionFromJson(Spice.boolFromJson, extra);
                                  })(Belt_Option.getWithDefault(Js_dict.get(v, "isSubscribedToNewsletter"), null));
                              if (isSubscribedToNewsletter.TAG === "Ok") {
                                var avatar = (function (extra) {
                                      return Spice.optionFromJson(Type_Avatar.t_decode, extra);
                                    })(Belt_Option.getWithDefault(Js_dict.get(v, "avatar"), null));
                                if (avatar.TAG === "Ok") {
                                  var eventsParticipation = (function (extra) {
                                        return Spice.optionFromJson((function (extra) {
                                                      return Spice.arrayFromJson(Spice.stringFromJson, extra);
                                                    }), extra);
                                      })(Belt_Option.getWithDefault(Js_dict.get(v, "eventsParticipation"), null));
                                  if (eventsParticipation.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              id: id._0,
                                              acceptCom: acceptCom._0,
                                              email: email._0,
                                              gender: gender._0,
                                              firstName: firstName._0,
                                              lastName: lastName._0,
                                              street: street._0,
                                              city: city._0,
                                              zipCode: zipCode._0,
                                              country: country._0,
                                              birthdate: birthdate._0,
                                              over16: over16._0,
                                              over18: over18._0,
                                              ageVerificationToken: ageVerificationToken._0,
                                              isSubscribedToNewsletter: isSubscribedToNewsletter._0,
                                              avatar: avatar._0,
                                              eventsParticipation: eventsParticipation._0
                                            }
                                          };
                                  }
                                  var e = eventsParticipation._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".eventsParticipation" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = avatar._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".avatar" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = isSubscribedToNewsletter._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".isSubscribedToNewsletter" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = ageVerificationToken._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".ageVerificationToken" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = over18._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".over18" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = over16._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".over16" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = birthdate._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".birthdate" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = country._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".country" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = zipCode._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".zipCode" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = city._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".city" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = street._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".street" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = lastName._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".lastName" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = firstName._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".firstName" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = gender._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".gender" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = acceptCom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".acceptCom" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

function userInfoFromUserClub(userClub) {
  return {
          id: Caml_option.nullable_to_opt(userClub.uid),
          acceptCom: Caml_option.nullable_to_opt(userClub.acceptCom),
          email: Caml_option.nullable_to_opt(userClub.email),
          gender: Caml_option.nullable_to_opt(userClub.gender),
          firstName: Caml_option.nullable_to_opt(userClub.firstName),
          lastName: Caml_option.nullable_to_opt(userClub.lastName),
          street: Caml_option.nullable_to_opt(userClub.street),
          city: Caml_option.nullable_to_opt(userClub.city),
          zipCode: Caml_option.nullable_to_opt(userClub.zipCode),
          country: Caml_option.nullable_to_opt(userClub.country),
          birthdate: Caml_option.nullable_to_opt(userClub.birthdate),
          over16: Caml_option.nullable_to_opt(userClub.over16),
          over18: Caml_option.nullable_to_opt(userClub.over18),
          ageVerificationToken: Caml_option.nullable_to_opt(userClub.ageVerificationToken),
          isSubscribedToNewsletter: Caml_option.nullable_to_opt(userClub.nlarte),
          avatar: Core__Option.getOr(Core__Option.map(Caml_option.nullable_to_opt(userClub.avatar), (function (avatar) {
                      return Type_Avatar.toDs(avatar);
                    })), Type_Avatar.defaultAvatar),
          eventsParticipation: Core__Option.flatMap(Caml_option.nullable_to_opt(userClub.custom), (function (customData) {
                  return Caml_option.nullable_to_opt(customData.eventsParticipation);
                }))
        };
}

function acceptCom(userInfo) {
  return userInfo.acceptCom;
}

function id(userInfo) {
  return userInfo.id;
}

function email(userInfo) {
  return userInfo.email;
}

function gender(userInfo) {
  return userInfo.gender;
}

function firstName(userInfo) {
  return userInfo.firstName;
}

function lastName(userInfo) {
  return userInfo.lastName;
}

function street(userInfo) {
  return userInfo.street;
}

function city(userInfo) {
  return userInfo.city;
}

function zipCode(userInfo) {
  return userInfo.zipCode;
}

function country(userInfo) {
  return userInfo.country;
}

function birthdate(userInfo) {
  return userInfo.birthdate;
}

function over16(userInfo) {
  return userInfo.over16;
}

function over18(userInfo) {
  return userInfo.over18;
}

function ageVerificationToken(userInfo) {
  return userInfo.ageVerificationToken;
}

function isSubscribedToNewsletter(userInfo) {
  return userInfo.isSubscribedToNewsletter;
}

function avatar(userInfo) {
  return userInfo.avatar;
}

function eventsParticipation(userInfo) {
  return userInfo.eventsParticipation;
}

function getAgeVerificationStatus(userInfo) {
  var match = userInfo.over16;
  var match$1 = userInfo.over18;
  var match$2 = userInfo.ageVerificationToken;
  if (match !== undefined) {
    if (match) {
      if (match$1 !== undefined) {
        if (match$1) {
          if (match$2 !== undefined) {
            return "Verified18";
          } else {
            return "NotVerified";
          }
        } else if (match$2 !== undefined) {
          return "Verified16";
        } else {
          return "NotVerified";
        }
      } else {
        return "NotVerified";
      }
    } else if (match$1 !== undefined && !(match$1 || match$2 === undefined)) {
      return "Under16";
    } else {
      return "NotVerified";
    }
  } else {
    return "NotVerified";
  }
}

function setAcceptCom(userInfo, acceptCom) {
  var newrecord = Caml_obj.obj_dup(userInfo);
  newrecord.acceptCom = acceptCom;
  return newrecord;
}

function setIsSubscribedToNewsletter(userInfo, isSubscribedToNewsletter) {
  var newrecord = Caml_obj.obj_dup(userInfo);
  newrecord.isSubscribedToNewsletter = isSubscribedToNewsletter;
  return newrecord;
}

function setAvatar(userInfo, avatar) {
  var newrecord = Caml_obj.obj_dup(userInfo);
  newrecord.avatar = avatar;
  return newrecord;
}

function addEventParticipation(userInfo, eventId) {
  var newEventParticipation = Core__Option.getOr(userInfo.eventsParticipation, []);
  newEventParticipation.push(eventId);
  var newrecord = Caml_obj.obj_dup(userInfo);
  newrecord.eventsParticipation = newEventParticipation;
  return newrecord;
}

function t_encode$2(v) {
  return Spice.unitToJson(v);
}

function t_decode$2(v) {
  return Spice.unitFromJson(v);
}

function t_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "progression",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Type_Progression.userProgression_encode, extra);
                        })(v.progression)
                  ],
                  [
                    "favorite",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Type_Teaser.Favorite.status_encode, extra);
                        })(v.favorite)
                  ]
                ]));
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var progression = (function (extra) {
        return Spice.optionFromJson(Type_Progression.userProgression_decode, extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "progression"), null));
  if (progression.TAG === "Ok") {
    var favorite = (function (extra) {
          return Spice.optionFromJson(Type_Teaser.Favorite.status_decode, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "favorite"), null));
    if (favorite.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                progression: progression._0,
                favorite: favorite._0
              }
            };
    }
    var e = favorite._0;
    return {
            TAG: "Error",
            _0: {
              path: ".favorite" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = progression._0;
  return {
          TAG: "Error",
          _0: {
            path: ".progression" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var DataMap = Type_String.$$Map.MakeMutable({
      Str: Type_String.Teaser.DsId,
      t_encode: t_encode$3,
      t_decode: t_decode$3
    });

var MagazineMap = Type_String.$$Map.MakeMutable({
      Str: Type_String.Teaser.DsId,
      t_encode: t_encode$2,
      t_decode: t_decode$2
    });

function fields_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "datas",
                    false,
                    DataMap.t_encode(v.datas)
                  ],
                  [
                    "magazines",
                    false,
                    MagazineMap.t_encode(v.magazines)
                  ],
                  [
                    "userInfo",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(t_encode$1, extra);
                        })(v.userInfo)
                  ]
                ]));
}

function fields_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var v$1 = Belt_Option.getWithDefault(Js_dict.get(v, "datas"), null);
  var datas = DataMap.t_decode(v$1);
  if (datas.TAG === "Ok") {
    var v$2 = Belt_Option.getWithDefault(Js_dict.get(v, "magazines"), null);
    var magazines = MagazineMap.t_decode(v$2);
    if (magazines.TAG === "Ok") {
      var userInfo = (function (extra) {
            return Spice.optionFromJson(t_decode$1, extra);
          })(Belt_Option.getWithDefault(Js_dict.get(v, "userInfo"), null));
      if (userInfo.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  datas: datas._0,
                  magazines: magazines._0,
                  userInfo: userInfo._0
                }
              };
      }
      var e = userInfo._0;
      return {
              TAG: "Error",
              _0: {
                path: ".userInfo" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = magazines._0;
    return {
            TAG: "Error",
            _0: {
              path: ".magazines" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = datas._0;
  return {
          TAG: "Error",
          _0: {
            path: ".datas" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function apiAccess_encode(v) {
  if (typeof v !== "object") {
    return ["Allowed"];
  } else {
    return [
            "Disallowed",
            Binding_Date.t_encode(v._0)
          ];
  }
}

function apiAccess_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Allowed" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Allowed"
                  };
          }
      case "Disallowed" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Binding_Date.t_decode(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Disallowed",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function user_encode(encoder_a) {
  return function (v) {
    return Js_dict.fromArray(Spice.filterOptional([
                    [
                      "fields",
                      false,
                      encoder_a(v.fields)
                    ],
                    [
                      "token",
                      false,
                      t_encode(v.token)
                    ],
                    [
                      "lastFetched",
                      false,
                      Binding_Date.t_encode(v.lastFetched)
                    ],
                    [
                      "apiAccess",
                      false,
                      apiAccess_encode(v.apiAccess)
                    ]
                  ]));
  };
}

function user_decode(decoder_a) {
  return function (v) {
    if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
      return Spice.error(undefined, "Not an object", v);
    }
    if (!(typeof v === "object" && !Array.isArray(v))) {
      return Spice.error(undefined, "Not an object", v);
    }
    var fields = decoder_a(Belt_Option.getWithDefault(Js_dict.get(v, "fields"), null));
    if (fields.TAG === "Ok") {
      var token = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
      if (token.TAG === "Ok") {
        var lastFetched = Binding_Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "lastFetched"), null));
        if (lastFetched.TAG === "Ok") {
          var apiAccess = apiAccess_decode(Belt_Option.getWithDefault(Js_dict.get(v, "apiAccess"), null));
          if (apiAccess.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      fields: fields._0,
                      token: token._0,
                      lastFetched: lastFetched._0,
                      apiAccess: apiAccess._0
                    }
                  };
          }
          var e = apiAccess._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".apiAccess" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = lastFetched._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastFetched" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = token._0;
      return {
              TAG: "Error",
              _0: {
                path: ".token" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = fields._0;
    return {
            TAG: "Error",
            _0: {
              path: ".fields" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  };
}

function t_encode$4(v) {
  if (typeof v !== "object") {
    return ["Disconnected"];
  } else if (v.TAG === "Connected") {
    return [
            "Connected",
            user_encode(fields_encode)(v._0)
          ];
  } else {
    return [
            "Anonymous",
            user_encode(fields_encode)(v._0)
          ];
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Anonymous" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = user_decode(fields_decode)(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Anonymous",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Connected" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = user_decode(fields_decode)(Belt_Array.getExn(v, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Connected",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "Disconnected" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Disconnected"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function keepMostRecentProgression(local, remote) {
  if (local === undefined) {
    if (remote !== undefined) {
      return remote;
    } else {
      return ;
    }
  }
  if (remote === undefined) {
    return local;
  }
  var progressionLocal = local.progression;
  if (progressionLocal === undefined) {
    if (remote.progression !== undefined) {
      return remote;
    } else {
      return local;
    }
  }
  var progressionRemote = remote.progression;
  if (progressionRemote === undefined) {
    return local;
  }
  var localUpdatedAt = Type_Progression.getUpdatedAt(progressionLocal.persistence);
  var remoteUpdatedAt = Type_Progression.getUpdatedAt(progressionRemote.persistence);
  var match = Binding_Date.compareDates(localUpdatedAt, remoteUpdatedAt);
  switch (match) {
    case "Same" :
    case "After" :
        return remote;
    case "Before" :
        return local;
    
  }
}

function merge(local, remote) {
  var mergeFn = function (param, local, remote) {
    return keepMostRecentProgression(local, remote);
  };
  if (typeof local === "object") {
    if (local.TAG === "Connected") {
      var match = local._0;
      var teasersLocal = match.fields.datas;
      if (typeof remote === "object") {
        if (remote.TAG !== "Connected") {
          return remote;
        }
        var userFields = remote._0;
        var userData = userFields.fields;
        if (!areEquals(match.token, userFields.token)) {
          return remote;
        }
        DataMap.merge(teasersLocal, userData.datas, mergeFn);
        var newrecord = Caml_obj.obj_dup(userData);
        return {
                TAG: "Connected",
                _0: {
                  fields: (newrecord.datas = teasersLocal, newrecord),
                  token: userFields.token,
                  lastFetched: userFields.lastFetched,
                  apiAccess: userFields.apiAccess
                }
              };
      }
      
    } else {
      var match$1 = local._0;
      var teasersLocal$1 = match$1.fields.datas;
      if (typeof remote === "object") {
        if (remote.TAG === "Connected") {
          return remote;
        }
        var userFields$1 = remote._0;
        var userData$1 = userFields$1.fields;
        if (!areEquals(match$1.token, userFields$1.token)) {
          return remote;
        }
        DataMap.merge(teasersLocal$1, userData$1.datas, mergeFn);
        var newrecord$1 = Caml_obj.obj_dup(userData$1);
        return {
                TAG: "Anonymous",
                _0: {
                  fields: (newrecord$1.datas = teasersLocal$1, newrecord$1),
                  token: userFields$1.token,
                  lastFetched: userFields$1.lastFetched,
                  apiAccess: userFields$1.apiAccess
                }
              };
      }
      
    }
  }
  if (typeof remote !== "object") {
    return "Disconnected";
  } else {
    return remote;
  }
}

function updateTeasers(teaser, dataMap) {
  var existingTeaser = Curry._2(DataMap.get, dataMap, teaser.dsId);
  var progression = Core__Option.flatMap(existingTeaser, (function (teaser) {
          return teaser.progression;
        }));
  var favStatus = teaser.favoriteStatus;
  var tmp;
  tmp = typeof favStatus !== "object" || favStatus.TAG !== "Available" ? "NotFavorite" : favStatus._0;
  var data_progression = progression !== undefined ? progression : ({
        timeProgression: teaser.progression,
        persistence: {
          TAG: "Remote",
          _0: new Date()
        }
      });
  var data_favorite = tmp;
  var data = {
    progression: data_progression,
    favorite: data_favorite
  };
  var newValue = Core__Option.getOr(keepMostRecentProgression(Curry._2(DataMap.get, dataMap, teaser.dsId), data), data);
  Curry._3(DataMap.set, dataMap, teaser.dsId, newValue);
}

function addFavorite(teaser, t) {
  if (typeof t !== "object") {
    return t;
  }
  t.TAG === "Connected";
  updateTeasers(teaser, t._0.fields.datas);
  return t;
}

function removeFavorite(dsId, t) {
  if (typeof t !== "object") {
    return t;
  }
  t.TAG === "Connected";
  var datas = t._0.fields.datas;
  var match = Curry._2(DataMap.get, datas, dsId);
  if (match !== undefined) {
    var match$1 = match.favorite;
    if (match$1 === "Favorite") {
      var newTeaser_progression = match.progression;
      var newTeaser_favorite = "NotFavorite";
      var newTeaser = {
        progression: newTeaser_progression,
        favorite: newTeaser_favorite
      };
      Curry._3(DataMap.set, datas, dsId, newTeaser);
    }
    
  }
  return t;
}

function getFavorites(t) {
  if (typeof t !== "object") {
    return ;
  }
  t.TAG === "Connected";
  return Curry._3(DataMap.reduce, t._0.fields.datas, [], (function (acc, key, teaser) {
                var match = teaser.favorite;
                if (match === "Favorite") {
                  acc.push(key);
                }
                return acc;
              }));
}

function getProgression(teasers, dsId) {
  var match = Curry._2(DataMap.get, teasers, dsId);
  if (match !== undefined) {
    return match.progression;
  }
  
}

function getProgressionById(t, id) {
  if (typeof t !== "object") {
    return ;
  }
  t.TAG === "Connected";
  return getProgression(t._0.fields.datas, id);
}

function purge(t, action) {
  if (typeof t !== "object") {
    return t;
  }
  t.TAG === "Connected";
  var datas = t._0.fields.datas;
  Core__Option.getOr(getFavorites(t), []).map(function (teaserId) {
        var teaser = Core__Option.getOr(Curry._2(DataMap.get, datas, teaserId), {
              progression: {
                timeProgression: Type_Progression.noProgression,
                persistence: {
                  TAG: "Local",
                  _0: new Date()
                }
              },
              favorite: "NotFavorite"
            });
        var tmp;
        tmp = action === "History" ? ({
              progression: {
                timeProgression: Type_Progression.noProgression,
                persistence: {
                  TAG: "Local",
                  _0: new Date()
                }
              },
              favorite: teaser.favorite
            }) : ({
              progression: teaser.progression,
              favorite: "NotFavorite"
            });
        Curry._3(DataMap.set, datas, teaserId, tmp);
      });
  return t;
}

function getTimeProgressionById(dsId, t) {
  if (t === undefined) {
    return ;
  }
  if (typeof t !== "object") {
    return ;
  }
  t.TAG === "Connected";
  return Core__Option.flatMap(getProgression(t._0.fields.datas, dsId), (function (progression) {
                return Type_Progression.getTime(progression.timeProgression);
              }));
}

function setTimeProgression(dsId, timeProgression, t) {
  if (t === undefined) {
    return t;
  }
  if (typeof t !== "object") {
    return t;
  }
  t.TAG === "Connected";
  var datas = t._0.fields.datas;
  Curry._2(DataMap.get, datas, dsId);
  ((function (__x) {
          Curry._3(DataMap.set, datas, dsId, __x);
        })({
          progression: {
            timeProgression: timeProgression,
            persistence: {
              TAG: "Local",
              _0: new Date()
            }
          }
        }));
  return t;
}

function make$1(userStatus, magazines, favoritesOpt, lastviewedsOpt, lastFetched, apiAccessOpt, token) {
  var favorites = favoritesOpt !== undefined ? favoritesOpt : [];
  var lastvieweds = lastviewedsOpt !== undefined ? lastviewedsOpt : [];
  var apiAccess = apiAccessOpt !== undefined ? apiAccessOpt : "Allowed";
  var datas = DataMap.make();
  favorites.forEach(function (dsId) {
        Curry._3(DataMap.set, datas, dsId, {
              favorite: "Favorite"
            });
      });
  lastvieweds.forEach(function (lastViewed) {
        var programId = lastViewed.programId;
        var teaser = Curry._2(DataMap.get, datas, programId);
        Curry._3(DataMap.set, datas, programId, {
              progression: Type_Progression.toUserProgression(lastViewed),
              favorite: Core__Option.getOr(Core__Option.flatMap(teaser, (function (teaser) {
                          return teaser.favorite;
                        })), "NotFavorite")
            });
      });
  var magazinesMap = MagazineMap.make();
  Core__Option.forEach(magazines, (function (magazines) {
          magazines.forEach(function (magazineId) {
                Curry._3(MagazineMap.set, magazinesMap, magazineId, undefined);
              });
        }));
  var apiAccess$1;
  if (typeof apiAccess !== "object") {
    apiAccess$1 = "Allowed";
  } else {
    var disallowedTtl = apiAccess._0;
    apiAccess$1 = $$Date.isAfter(new Date(), disallowedTtl) ? "Allowed" : ({
          TAG: "Disallowed",
          _0: disallowedTtl
        });
  }
  switch (userStatus) {
    case "Anonymous" :
        return {
                TAG: "Anonymous",
                _0: {
                  fields: {
                    datas: datas,
                    magazines: magazinesMap
                  },
                  token: token,
                  lastFetched: lastFetched,
                  apiAccess: apiAccess$1
                }
              };
    case "Connected" :
        return {
                TAG: "Connected",
                _0: {
                  fields: {
                    datas: datas,
                    magazines: magazinesMap
                  },
                  token: token,
                  lastFetched: lastFetched,
                  apiAccess: apiAccess$1
                }
              };
    case "Disconnected" :
        return "Disconnected";
    
  }
}

var disconnected = make$1("Disconnected", undefined, undefined, undefined, $$Date.subWeeks(new Date(), 10), undefined, undefined);

var emptyAnonymousUser = make$1("Anonymous", undefined, undefined, undefined, $$Date.subWeeks(new Date(), 10), undefined, undefined);

function getUserStatus(t) {
  if (typeof t !== "object") {
    return "Disconnected";
  } else if (t.TAG === "Connected") {
    return "Connected";
  } else {
    return "Anonymous";
  }
}

function getAnonymousFeatureStatus(user) {
  if (typeof user !== "object") {
    return "Disabled";
  } else {
    return "Enabled";
  }
}

function newUserFromAnonymousStatus(user, newAnonymousStatus) {
  if (typeof user !== "object") {
    if (newAnonymousStatus === "Enabled") {
      return emptyAnonymousUser;
    } else {
      return disconnected;
    }
  } else if (user.TAG === "Connected" || newAnonymousStatus === "Enabled") {
    return user;
  } else {
    return disconnected;
  }
}

function updateToken(t, token) {
  if (typeof t !== "object") {
    return make$1("Disconnected", undefined, undefined, undefined, $$Date.subWeeks(new Date(), 10), undefined, token);
  } else if (t.TAG === "Connected") {
    if (Caml_obj.notequal(t._0.token, token)) {
      return make$1("Connected", undefined, undefined, undefined, $$Date.subWeeks(new Date(), 10), undefined, token);
    } else {
      return t;
    }
  } else if (Caml_obj.notequal(t._0.token, token)) {
    return make$1("Anonymous", undefined, undefined, undefined, $$Date.subWeeks(new Date(), 10), undefined, token);
  } else {
    return t;
  }
}

function getVideoById(id, t) {
  if (typeof t !== "object") {
    return "NotFavorite";
  }
  t.TAG === "Connected";
  return Core__Option.mapOr(Curry._2(DataMap.get, t._0.fields.datas, id), "NotFavorite", (function (teaser) {
                var favorite = teaser.favorite;
                if (favorite !== undefined) {
                  return favorite;
                } else {
                  return "NotFavorite";
                }
              }));
}

function getApiAccess(t) {
  if (typeof t !== "object") {
    return "Allowed";
  } else {
    return t._0.apiAccess;
  }
}

function changeApiRestriction(t, apiAccess) {
  if (typeof t !== "object") {
    return t;
  }
  if (t.TAG === "Connected") {
    var userData = t._0;
    return {
            TAG: "Connected",
            _0: {
              fields: userData.fields,
              token: userData.token,
              lastFetched: userData.lastFetched,
              apiAccess: apiAccess
            }
          };
  }
  var userData$1 = t._0;
  return {
          TAG: "Anonymous",
          _0: {
            fields: userData$1.fields,
            token: userData$1.token,
            lastFetched: userData$1.lastFetched,
            apiAccess: apiAccess
          }
        };
}

function getToken(t) {
  if (typeof t !== "object") {
    return ;
  } else {
    return Caml_option.some(t._0.token);
  }
}

function getConnectedToken(t) {
  if (typeof t !== "object" || t.TAG !== "Connected") {
    return ;
  } else {
    return Caml_option.some(t._0.token);
  }
}

function getAnonymousToken(t) {
  getApiAccess(t);
  if (typeof t !== "object" || t.TAG === "Connected") {
    return ;
  } else {
    return Caml_option.some(t._0.token);
  }
}

function getLastFetched(t) {
  if (typeof t !== "object") {
    return ;
  } else {
    return Caml_option.some(t._0.lastFetched);
  }
}

function getLocalProgressions(t) {
  if (typeof t !== "object") {
    return [];
  }
  t.TAG === "Connected";
  return Curry._3(DataMap.reduce, t._0.fields.datas, [], (function (acc, key, teaser) {
                var progression = teaser.progression;
                if (progression !== undefined && progression.persistence.TAG !== "Remote") {
                  acc.push((function (__x) {
                            return Type_Progression.toClubProgression(key, __x);
                          })(progression));
                }
                return acc;
              }));
}

function setAllProgressionRemote(t) {
  if (typeof t !== "object") {
    return t;
  }
  t.TAG === "Connected";
  var datas = t._0.fields.datas;
  Curry._3(DataMap.reduce, datas, [], (function (acc, key, teaser) {
          var match = teaser.progression;
          if (match !== undefined && match.persistence.TAG !== "Remote") {
            Curry._3(DataMap.set, datas, key, {
                  progression: {
                    timeProgression: match.timeProgression,
                    persistence: {
                      TAG: "Remote",
                      _0: new Date()
                    }
                  },
                  favorite: Core__Option.getOr(teaser.favorite, "NotFavorite")
                });
          }
          return acc;
        }));
  return t;
}

function getProgressionAndFavorite(param) {
  var teaser = param[1];
  var progression = Core__Option.getOr(teaser.progression, {
        timeProgression: Type_Progression.noProgression,
        persistence: {
          TAG: "Local",
          _0: new Date()
        }
      });
  var favoriteStatus = Core__Option.getOr(teaser.favorite, "NotFavorite");
  return {
          dsId: param[0],
          progression: progression,
          favoriteStatus: favoriteStatus
        };
}

function getAllProgressionsAndFavoriteStatus(t) {
  if (typeof t !== "object") {
    return ;
  }
  t.TAG === "Connected";
  return Curry._1(DataMap.toArray, t._0.fields.datas).map(getProgressionAndFavorite);
}

function addUserDetailFromClub(user, userInfo) {
  if (typeof user !== "object") {
    return user;
  }
  if (user.TAG !== "Connected") {
    return user;
  }
  var userData = user._0;
  var newrecord = Caml_obj.obj_dup(userData.fields);
  return {
          TAG: "Connected",
          _0: {
            fields: (newrecord.userInfo = userInfoFromUserClub(userInfo), newrecord),
            token: userData.token,
            lastFetched: userData.lastFetched,
            apiAccess: userData.apiAccess
          }
        };
}

function getUserInfo(t) {
  if (typeof t !== "object" || t.TAG !== "Connected") {
    return ;
  } else {
    return t._0.fields.userInfo;
  }
}

function updateUserInfo(t, userInfo) {
  if (typeof t !== "object") {
    return t;
  }
  if (t.TAG !== "Connected") {
    return t;
  }
  var userData = t._0;
  var newrecord = Caml_obj.obj_dup(userData.fields);
  return {
          TAG: "Connected",
          _0: {
            fields: (newrecord.userInfo = userInfo, newrecord),
            token: userData.token,
            lastFetched: userData.lastFetched,
            apiAccess: userData.apiAccess
          }
        };
}

var Token = {
  t_encode: t_encode,
  t_decode: t_decode,
  get: get,
  make: make,
  isDisconnected: isDisconnected,
  createBearerToken: createBearerToken,
  toString: toString
};

var Info = {
  userInfoFromUserClub: userInfoFromUserClub,
  acceptCom: acceptCom,
  id: id,
  email: email,
  gender: gender,
  firstName: firstName,
  lastName: lastName,
  street: street,
  city: city,
  zipCode: zipCode,
  country: country,
  birthdate: birthdate,
  over16: over16,
  over18: over18,
  getAgeVerificationStatus: getAgeVerificationStatus,
  eventsParticipation: eventsParticipation,
  ageVerificationToken: ageVerificationToken,
  isSubscribedToNewsletter: isSubscribedToNewsletter,
  avatar: avatar,
  setAcceptCom: setAcceptCom,
  setIsSubscribedToNewsletter: setIsSubscribedToNewsletter,
  setAvatar: setAvatar,
  addEventParticipation: addEventParticipation
};

export {
  Token ,
  Status ,
  Info ,
  t_encode$4 as t_encode,
  t_decode$4 as t_decode,
  getFavorites ,
  getProgressionById ,
  getTimeProgressionById ,
  setTimeProgression ,
  purge ,
  getVideoById ,
  addFavorite ,
  removeFavorite ,
  getUserStatus ,
  emptyAnonymousUser ,
  make$1 as make,
  merge ,
  disconnected ,
  getToken ,
  getAnonymousToken ,
  getLastFetched ,
  getLocalProgressions ,
  setAllProgressionRemote ,
  getAllProgressionsAndFavoriteStatus ,
  getAnonymousFeatureStatus ,
  newUserFromAnonymousStatus ,
  updateToken ,
  getConnectedToken ,
  addUserDetailFromClub ,
  getUserInfo ,
  updateUserInfo ,
  getApiAccess ,
  changeApiRestriction ,
}
/* DataMap Not a pure module */
