// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as MyNullable from "../MyNullable.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "./Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Progression from "./Type_Progression.res.mjs";

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lastviewed",
                    false,
                    MyNullable.t_encode(Type_Club.lastviewed_encode)(v.lastviewed)
                  ],
                  [
                    "season",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.season)
                  ],
                  [
                    "episode",
                    false,
                    Spice.intToJson(v.episode)
                  ],
                  [
                    "totalEpisodes",
                    false,
                    Spice.intToJson(v.totalEpisodes)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lastviewed = MyNullable.t_decode(Type_Club.lastviewed_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "lastviewed"), null));
  if (lastviewed.TAG === "Ok") {
    var season = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "season"), null));
    if (season.TAG === "Ok") {
      var episode = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "episode"), null));
      if (episode.TAG === "Ok") {
        var totalEpisodes = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "totalEpisodes"), null));
        if (totalEpisodes.TAG === "Ok") {
          var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
          if (url.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      lastviewed: lastviewed._0,
                      season: season._0,
                      episode: episode._0,
                      totalEpisodes: totalEpisodes._0,
                      url: url._0
                    }
                  };
          }
          var e = url._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".url" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = totalEpisodes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".totalEpisodes" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = episode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".episode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = season._0;
    return {
            TAG: "Error",
            _0: {
              path: ".season" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = lastviewed._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lastviewed" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function data_encode(v) {
  return Spice.arrayToJson(t_encode, v);
}

function data_decode(v) {
  return Spice.arrayFromJson(t_decode, v);
}

function response_encode(v) {
  return Type_Club.$$Response.t_encode(data_encode)(v);
}

function response_decode(v) {
  return Type_Club.$$Response.t_decode(data_decode)(v);
}

function get(response) {
  return Type_Club.$$Response.getData(response)[0];
}

var ApiResponse = {
  t_encode: t_encode,
  t_decode: t_decode,
  data_encode: data_encode,
  data_decode: data_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  get: get
};

function t_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "progression",
                    false,
                    Type_Progression.t_encode(v.progression)
                  ],
                  [
                    "season",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.intToJson, extra);
                        })(v.season)
                  ],
                  [
                    "episode",
                    false,
                    Spice.intToJson(v.episode)
                  ],
                  [
                    "totalEpisodes",
                    false,
                    Spice.intToJson(v.totalEpisodes)
                  ],
                  [
                    "href",
                    false,
                    Type_String.Href.t_encode(v.href)
                  ]
                ]));
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var progression = Type_Progression.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "progression"), null));
  if (progression.TAG === "Ok") {
    var season = (function (extra) {
          return Spice.optionFromJson(Spice.intFromJson, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "season"), null));
    if (season.TAG === "Ok") {
      var episode = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "episode"), null));
      if (episode.TAG === "Ok") {
        var totalEpisodes = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "totalEpisodes"), null));
        if (totalEpisodes.TAG === "Ok") {
          var href = Type_String.Href.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "href"), null));
          if (href.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      progression: progression._0,
                      season: season._0,
                      episode: episode._0,
                      totalEpisodes: totalEpisodes._0,
                      href: href._0
                    }
                  };
          }
          var e = href._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".href" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = totalEpisodes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".totalEpisodes" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = episode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".episode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = season._0;
    return {
            TAG: "Error",
            _0: {
              path: ".season" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = progression._0;
  return {
          TAG: "Error",
          _0: {
            path: ".progression" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function make(param) {
  return {
          progression: Core__Option.getOr(Core__Option.flatMap(Caml_option.nullable_to_opt(param.lastviewed), (function (param) {
                      var timecode = param.timecode;
                      return Core__Option.map(Caml_option.nullable_to_opt(param.progress), (function (extra) {
                                    return Type_Progression.makeFromPct((timecode == null) ? undefined : Caml_option.some(timecode), extra);
                                  }));
                    })), Type_Progression.noProgression),
          season: Caml_option.nullable_to_opt(param.season),
          episode: param.episode,
          totalEpisodes: param.totalEpisodes,
          href: Type_String.Href.make(param.url)
        };
}

export {
  ApiResponse ,
  t_encode$1 as t_encode,
  t_decode$1 as t_decode,
  make ,
}
/* Type_Club Not a pure module */
