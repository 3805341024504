// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

var baseUrlDefault = "http://url.com";

function toString(t) {
  var tString = t.href;
  if (tString.includes(baseUrlDefault)) {
    return tString.replace(baseUrlDefault, "");
  } else {
    return tString;
  }
}

var targetPlusChar = /\+/g;

function toEncodedString(t) {
  return toString(t).replace(targetPlusChar, "%20");
}

function make(string) {
  var trimedStr = decodeURI(string.trim());
  if (trimedStr.length > 0) {
    return Caml_option.some(new URL(trimedStr, baseUrlDefault));
  }
  
}

function t_encode(t) {
  return toString(t);
}

function t_decode(json) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(Core__JSON.Decode.string(json), (function (string) {
                        return make(string);
                      })), (function (t) {
                    return {
                            TAG: "Ok",
                            _0: t
                          };
                  })), {
              TAG: "Error",
              _0: {
                path: "",
                message: "Can't decode url",
                value: json
              }
            });
}

var Url = {
  toString: toString,
  toEncodedString: toEncodedString,
  make: make,
  t_encode: t_encode,
  t_decode: t_decode
};

var TrackingPixel = {
  toString: toString,
  toEncodedString: toEncodedString,
  make: make,
  t_encode: t_encode,
  t_decode: t_decode
};

export {
  Url ,
  TrackingPixel ,
}
/* No side effect */
