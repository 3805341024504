// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("mask", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M3 20.5303H8.678V26.2076H3V20.5303Z",
                            fillRule: "evenodd"
                          }),
                      id: "mask0_29_8798",
                      height: "7",
                      width: "6",
                      maskUnits: "userSpaceOnUse",
                      x: "3",
                      y: "20"
                    }),
                JsxRuntime.jsx("g", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M8.67798 23.3694C8.67798 24.9376 7.40714 26.2076 5.8389 26.2076C4.27066 26.2076 2.99982 24.9376 2.99982 23.3694C2.99982 21.8003 4.27148 20.5303 5.8389 20.5303C7.40633 20.5303 8.67798 21.8003 8.67798 23.3694Z",
                            fill: "#FA481C",
                            fillRule: "evenodd"
                          }),
                      mask: "url(#mask0_29_8798)"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M27.2846 17.2792H26.3591V8.64167H12.0792V7.71536H27.2855V17.28L27.2846 17.2792ZM24.643 17.2795V19.9269H23.7127V11.2837H9.43682V10.3582H24.6479L24.643 17.2795ZM12.3905 21.6369V13.9257L19.2712 17.7781L12.3905 21.6369ZM10.3624 6V8.64163H7.72073V11.2833H5.07911V18.7555C5.32856 18.7149 5.57802 18.6881 5.83803 18.6881C8.42278 18.6881 10.5135 20.7837 10.5135 23.3636C10.5135 23.6764 10.4826 23.9779 10.4249 24.2736H23.7168V21.632H26.3584V18.9904H29V6.00081H10.3615L10.3624 6Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
