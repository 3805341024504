// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Meta from "./Type_Meta.res.mjs";
import * as Zone_Type from "../zone/Zone_Type.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Presse_Page from "../presse/Presse_Page.res.mjs";
import * as Type_String from "./Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Category from "./Type_Category.res.mjs";

var Description_t_encode = Type_String.$$String.t_encode;

var Description_t_decode = Type_String.$$String.t_decode;

var Description_toString = Type_String.$$String.toString;

var Description_empty = Type_String.$$String.empty;

var Description_make = Type_String.$$String.make;

var Description_makeFromNullable = Type_String.$$String.makeFromNullable;

var Description_makeFromNullableOrEmpty = Type_String.$$String.makeFromNullableOrEmpty;

var Description = {
  t_encode: Description_t_encode,
  t_decode: Description_t_decode,
  toString: Description_toString,
  empty: Description_empty,
  make: Description_make,
  makeFromNullable: Description_makeFromNullable,
  makeFromNullableOrEmpty: Description_makeFromNullableOrEmpty
};

var Title_t_encode = Type_String.$$String.t_encode;

var Title_t_decode = Type_String.$$String.t_decode;

var Title_toString = Type_String.$$String.toString;

var Title_empty = Type_String.$$String.empty;

var Title_make = Type_String.$$String.make;

var Title_makeFromNullable = Type_String.$$String.makeFromNullable;

var Title_makeFromNullableOrEmpty = Type_String.$$String.makeFromNullableOrEmpty;

var Title = {
  t_encode: Title_t_encode,
  t_decode: Title_t_decode,
  toString: Title_toString,
  empty: Title_empty,
  make: Title_make,
  makeFromNullable: Title_makeFromNullable,
  makeFromNullableOrEmpty: Title_makeFromNullableOrEmpty
};

function emacParentToParent(parent) {
  if (!(parent == null)) {
    return {
            label: parent.label,
            url: parent.url,
            page: Caml_option.nullable_to_opt(parent.page),
            parent: emacParentToParent(parent.parent)
          };
  }
  
}

function fromEmac(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, betweenDatesFormattedFormater, seasonCountFormaterOpt, pageKind, domain, timeReference, page) {
  var seasonCountFormater = seasonCountFormaterOpt !== undefined ? seasonCountFormaterOpt : (function (param) {
        return "";
      });
  var parent = emacParentToParent(page.parent);
  var category = Type_Category.encode(page.code);
  var description = Core__Option.getOr(Caml_option.nullable_to_opt(page.metadata.description), "");
  return {
          page: category,
          description: description,
          url: page.url,
          parent: parent,
          title: Core__Option.getOr(Caml_option.nullable_to_opt(page.metadata.title), ""),
          zones: Zone_Type.transformZones(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, category, parent, description, page.url, pageKind, Type_Meta.makePageMetas(betweenDatesFormattedFormater, seasonCountFormater, page.metadata), Core__Option.getOr(domain, "replay"), page.zones)
        };
}

function makeSliderDownload(pictures) {
  return {
          title: pictures.title,
          id: pictures.id,
          items: Presse_Page.filesToDownloadElements(pictures.files),
          href: pictures.zip
        };
}

function makeSliderVideo(videos, toDsTeaser) {
  return {
          title: videos.title,
          id: videos.id,
          items: Belt_Array.map(videos.teasers, (function (teaser) {
                  return toDsTeaser(teaser);
                }))
        };
}

function makeFromPresse(labels, pressePage, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, page) {
  var teaser = Presse_Page.toDsTeaser(pressePage.zones.program, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, labels.common.firstBroadcast, page);
  var toDsTeaser = function (none) {
    return Presse_Page.toDsTeaser(none, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, "", page);
  };
  var programContent = {
    NAME: "ProgramContent",
    VAL: [
      teaser,
      undefined,
      "Auto"
    ]
  };
  var programMetas = Type_Meta.makeProgramMetas("Presse", undefined, nextBroadcastDateFormater, "Program", labels, teaser);
  return {
          download: Core__Option.map(pressePage.zones.pictures, makeSliderDownload),
          videos: Core__Option.map(Caml_option.nullable_to_opt(pressePage.zones.videos), (function (__x) {
                  return makeSliderVideo(__x, toDsTeaser);
                })),
          programContent: programContent,
          meta: programMetas,
          ctas: Presse_Page.programToCtas(pressePage.zones.program),
          backlink: Caml_option.nullable_to_opt(pressePage.zones.backlink)
        };
}

var Presse = {
  makeSliderDownload: makeSliderDownload,
  makeSliderVideo: makeSliderVideo,
  makeFromPresse: makeFromPresse
};

export {
  Description ,
  Title ,
  emacParentToParent ,
  fromEmac ,
  Presse ,
}
/* Type_Meta Not a pure module */
