import Url from 'url-parse';
import mediaSessionHandler from './mediaSessionHandler';

import type { ArteVpProps, ExternalInterface, QualityLabel } from '@artegeie/arte-vp';
import { type Page } from '@replay/types/Page';
import { localStorageKey } from '../../shared/useAutoPlayChange';

type handleExternalInterfaceParams = {
    page: Page | null | undefined;
    onPrevNext: (url: string) => void;
    setPositionRef: React.MutableRefObject<(url: string, position: number) => void>;
    onTrailerButtonClickHandler: () => void;
};
export const handleExternalInterface = ({
    page,
    onPrevNext,
    setPositionRef,
    onTrailerButtonClickHandler,
}: handleExternalInterfaceParams): ArteVpProps['getExternalInterface'] => {
    const handlePlayerEvent = (externalInterface: ExternalInterface) => {
        externalInterface.on('arte_vp.prevNextItem', (event) => {
            onPrevNext(new Url(event.url).pathname);
        });

        externalInterface.on('firstFrame', () => {
            mediaSessionHandler(page, onPrevNext);
        });

        externalInterface.on('arte_vp.skipAction', onTrailerButtonClickHandler);

        setPositionRef.current = (_, position: number) => {
            if (typeof window !== 'undefined') {
                window.scrollTo(0, 0);
            }
            externalInterface.setPosition(position);
        };
    };

    return handlePlayerEvent;
};

const isAutoplayActivated = (): boolean => {
    if (!localStorage) {
        return true;
    }
    const value = localStorage.getItem(localStorageKey);
    if (!value) {
        return true;
    }
    const { clip } = JSON.parse(value);
    return clip;
};

/**
 * this function will set the play and pause function to be used outside
 * The pauseSetter and playSetter are references, to used it in a react component you can use `React.useRef` like in the
 * exemple
 *
 * Exemple:
 *
 * ```typescript
 * const TestPlayPause = () => {
 *   const play = useRef<() => void>(() => undefined);
 *   const pause = useRef<() => void>(() => undefined);
 *   const handlePlayerEvent = controlledPlayer({
 *       pauseSetter: (a) => {
 *           pause.current = a;
 *       },
 *       playSetter: (a) => {
 *           play.current = a;
 *       },
 *   });
 *   const playerProps: Props = {
 *       forcedMute: true,
 *       previewData: {
 *           title: '',
 *           subtitle: '',
 *           image: '',
 *           stickers: [],
 *       },
 *       autoPlayNext: false,
 *       config: 'https://api.arte.tv/api/player/v2/config/fr/SMWF0192',
 *       fromDomain: 'other' as FromPage,
 *       locale: 'fr' as Locale,
 *       videoKind: 'clip',
 *       getExternalInterface: handlePlayerEvent,
 *       translate: (key: string) => key,
 *       tcStartFrom: null,
 *   };
 *   return (
 *       <div>
 *           <Player {...playerProps} />
 *           <div>
 *               <button onClick={() => play.current()}>play</button>
 *               <button onClick={() => pause.current()}>pause</button>
 *           </div>
 *       </div>
 *   );
 * };
 * ````
 *
 */

export const controlledPlayer = ({
    pauseSetter,
    playSetter,
    onPlayerReady,
    isUserPaused,
}: {
    pauseSetter: (fn: () => void) => void;
    playSetter: (fn: () => void) => void;
    onPlayerReady: () => void;
    isUserPaused: React.MutableRefObject<null | boolean>;
}) => {
    const handlePlayerEvent = (externalInterface: ExternalInterface) => {
        externalInterface.on('pause', (event) => {
            // FIXME: player typescript definition is not correct
            const interfaceEvent = event as unknown as {
                source: 'user' | 'programmatic';
            };
            if (interfaceEvent.source === 'user') {
                console.log('this is a user pause');
                isUserPaused.current = true;
            }
        });
        externalInterface.on('play', () => {
            isUserPaused.current = false;
        });
        playSetter(() => {
            if (!isAutoplayActivated()) {
                return;
            }
            const currentState = externalInterface.getState();
            if ((!isUserPaused.current && currentState === 'paused') || currentState === 'complete') {
                externalInterface.togglePlayPause();
            }
        });
        pauseSetter(() => {
            if (!isAutoplayActivated()) {
                return;
            }
            const currentState = externalInterface.getState();
            if (currentState === 'playing') {
                externalInterface.togglePlayPause();
            }
        });
        externalInterface.on('readyToPlay', () => {
            onPlayerReady();
        });
    };

    return handlePlayerEvent;
};

export const setPlayerStartQuality = async (
    playerExternalInterface: ExternalInterface,
    videoQualityPreference: QualityLabel,
) => {
    playerExternalInterface.setOptimalQuality(videoQualityPreference);
};
