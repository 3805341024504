// Generated by ReScript, PLEASE EDIT WITH CARE


function makeFromHsla(h, s, l, a) {
  return {
          h: h,
          s: s,
          l: l,
          a: a
        };
}

function setAlpha(t, a) {
  return makeFromHsla(t.h, t.s, t.l, a);
}

var secondary = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var primary = {
  h: 0,
  s: 0,
  l: 8,
  a: 1
};

var divider = {
  h: 0,
  s: 0,
  l: 81,
  a: 1
};

var emptyTeaserBackground = {
  h: 0,
  s: 0,
  l: 98,
  a: 1
};

var emptyTeaserLogo = {
  h: 0,
  s: 0,
  l: 78,
  a: 1
};

var teaserClassicBackground = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var teaserInfoBackground = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var teaserBorder = {
  h: 0,
  s: 0,
  l: 20,
  a: 0.2
};

var teaserInfoBorder = {
  h: 0,
  s: 0,
  l: 20,
  a: 0.2
};

var avatarStickerBackground = {
  h: 0,
  s: 0,
  l: 100,
  a: 0.2
};

var background = {
  h: 0,
  s: 0,
  l: 20,
  a: 1
};

var TeaserHover = {
  background: background
};

var subHeaderBackground = {
  h: 0,
  s: 0,
  l: 20,
  a: 1
};

var background$1 = {
  h: 0,
  s: 0,
  l: 61,
  a: 1
};

var foreground = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var circle = {
  h: 0,
  s: 0,
  l: 20,
  a: 1
};

var Inactive = {
  background: background$1,
  foreground: foreground,
  circle: circle
};

var Switch = {
  Inactive: Inactive
};

var Light = {
  primary: primary,
  secondary: secondary,
  emptyTeaserBackground: emptyTeaserBackground,
  emptyTeaserLogo: emptyTeaserLogo,
  divider: divider,
  subHeaderBackground: subHeaderBackground,
  teaserClassicBackground: teaserClassicBackground,
  teaserInfoBackground: teaserInfoBackground,
  teaserBorder: teaserBorder,
  teaserInfoBorder: teaserInfoBorder,
  avatarStickerBackground: avatarStickerBackground,
  TeaserHover: TeaserHover,
  Switch: Switch
};

var secondary$1 = {
  h: 0,
  s: 0,
  l: 8,
  a: 1
};

var primary$1 = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var emptyTeaserBackground$1 = {
  h: 0,
  s: 0,
  l: 13,
  a: 1
};

var emptyTeaserLogo$1 = {
  h: 0,
  s: 0,
  l: 33,
  a: 1
};

var divider$1 = {
  h: 0,
  s: 0,
  l: 27,
  a: 1
};

var subHeaderBackground$1 = {
  h: 0,
  s: 100,
  l: 100,
  a: 1
};

var teaserClassicBackground$1 = {
  h: 0,
  s: 0,
  l: 20,
  a: 1
};

var teaserInfoBackground$1 = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var teaserBorder$1 = {
  h: 0,
  s: 0,
  l: 0,
  a: 0
};

var teaserInfoBorder$1 = {
  h: 0,
  s: 0,
  l: 20,
  a: 0.2
};

var avatarStickerBackground$1 = {
  h: 0,
  s: 0,
  l: 100,
  a: 0.2
};

var background$2 = {
  h: 0,
  s: 0,
  l: 0,
  a: 1
};

var TeaserHover$1 = {
  background: background$2
};

var background$3 = {
  h: 0,
  s: 0,
  l: 71,
  a: 1
};

var foreground$1 = {
  h: 0,
  s: 0,
  l: 13,
  a: 1
};

var circle$1 = {
  h: 0,
  s: 0,
  l: 100,
  a: 1
};

var Inactive$1 = {
  background: background$3,
  foreground: foreground$1,
  circle: circle$1
};

var Switch$1 = {
  Inactive: Inactive$1
};

var Dark = {
  primary: primary$1,
  secondary: secondary$1,
  emptyTeaserBackground: emptyTeaserBackground$1,
  emptyTeaserLogo: emptyTeaserLogo$1,
  divider: divider$1,
  subHeaderBackground: subHeaderBackground$1,
  teaserClassicBackground: teaserClassicBackground$1,
  teaserInfoBackground: teaserInfoBackground$1,
  teaserBorder: teaserBorder$1,
  teaserInfoBorder: teaserInfoBorder$1,
  avatarStickerBackground: avatarStickerBackground$1,
  TeaserHover: TeaserHover$1,
  Switch: Switch$1
};

function view(param) {
  return {
          h: param.h,
          s: param.s,
          l: param.l,
          a: param.a
        };
}

export {
  makeFromHsla ,
  setAlpha ,
  Light ,
  Dark ,
  view ,
}
/* No side effect */
