// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

var endpoint = {
  NAME: "Static",
  VAL: "code"
};

var options = {
  tokenKind: "User",
  userRestriction: "Connected"
};

var GetConfig = {
  response_decode: response_decode,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  decodeApiResponse: response_decode,
  options: options
};

var Get = User_Api_Config.MakeRequest({
      errorResponse_decode: errorResponse_decode,
      mapError: mapError,
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: response_decode,
      options: options
    });

var get = Get.call;

export {
  GetConfig ,
  Get ,
  get ,
}
/* Get Not a pure module */
