// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../../selector/Identifiers.res.mjs";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseClientReady from "../../../../hook/UseClientReady.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Accessibility from "../../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

var width = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(20), CssJs.pct(14.285), undefined, CssJs.pct(100));

function Organism_Slider_GuideTvCalendar_Item(props) {
  var value = props.value;
  var status = props.status;
  var onClick = props.onClick;
  var ariaLabel = props.ariaLabel;
  var clientStatus = UseClientReady.make();
  var ctaAccessibility = ariaLabel !== undefined ? Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, ariaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined) : undefined;
  var containerAccessiblity;
  containerAccessiblity = status === "Current" ? Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1) : undefined;
  var kind;
  kind = status === "Current" ? ({
        TAG: "Text",
        _0: "h2"
      }) : ({
        TAG: "Text",
        _0: "div"
      });
  var handleOnClick = function (param) {
    Core__Option.map(onClick, (function (onClick) {
            return onClick(value);
          }));
  };
  var scrollSnapRule;
  scrollSnapRule = clientStatus === "Ready" || status !== "Current" ? undefined : Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.unsafe("scroll-snap-align", "center")]));
  return JsxRuntime.jsx(Atom_Cell.make, {
              accessibility: containerAccessiblity,
              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
              kind: kind,
              width: Caml_option.some(width),
              children: JsxRuntime.jsxs(Atom_Cta.Button.Block.make, {
                    accessibility: ctaAccessibility,
                    children: [
                      JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(props.dayLabel),
                            style: Atom_Text_Ds.GuideTvCalendar.makeDay(status)
                          }),
                      JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(props.dateLabel),
                            style: Atom_Text_Ds.GuideTvCalendar.makeDate(status)
                          })
                    ],
                    ctaRef: props.guideTvCalendarRef,
                    direction: Caml_option.some(Particle_Screen_Constants.flexDirectionColumn),
                    onClick: handleOnClick,
                    style: Atom_Cta_Ds.makeGuideTvCalendar(status)
                  }),
              identifier: Caml_option.some(Identifiers.GuideTV.calendarItem),
              rules: scrollSnapRule
            });
}

var make = Organism_Slider_GuideTvCalendar_Item;

export {
  width ,
  make ,
}
/* width Not a pure module */
