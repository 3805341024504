// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as $$Date from "@artegeie/date";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function useGetEventStatus(eventsParticipation, eventId, participationEndDate) {
  var match = React.useState(function () {
        return "NotParticipated";
      });
  var setParticipationStatus = match[1];
  React.useEffect((function () {
          var hasParticipated = Core__Option.isSome(Core__Option.flatMap(eventsParticipation, (function (eventsParticipation) {
                      return eventsParticipation.find(function (id) {
                                  return eventId === id;
                                });
                    })));
          var isParticipationEnded = Core__Option.getOr(Core__Option.map(participationEndDate, (function (endDate) {
                      return $$Date.isAfter(new Date(), new Date(endDate));
                    })), true);
          var exit = 0;
          if (isParticipationEnded) {
            if (hasParticipated) {
              exit = 1;
            } else {
              setParticipationStatus(function (param) {
                    return "Ended";
                  });
            }
          } else if (hasParticipated) {
            exit = 1;
          } else {
            setParticipationStatus(function (param) {
                  return "NotParticipated";
                });
          }
          if (exit === 1) {
            setParticipationStatus(function (param) {
                  return "Participated";
                });
          }
          
        }), [
        eventsParticipation,
        eventId,
        participationEndDate
      ]);
  return [
          match[0],
          setParticipationStatus
        ];
}

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

function endpoint_1(args) {
  return "events/" + Util_I18n.localeToString(args.locale) + "/join/" + args.eventId;
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  tokenKind: "User",
  userRestriction: "Connected"
};

function makeBody(param) {
  
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: "Participated"
        };
}

var JoinConfig = {
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  method: "Put",
  endpoint: endpoint,
  options: options,
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Join = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode,
      errorResponse_decode: errorResponse_decode,
      domain: "Sso",
      mapError: mapError,
      method: "Put",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var join = Join.call;

export {
  useGetEventStatus ,
  JoinConfig ,
  Join ,
  join ,
}
/* Join Not a pure module */
