'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Util_Opt from "../util/Util_Opt.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Provider from "../user/User_Provider.res.mjs";
import * as UseClientReady from "./UseClientReady.res.mjs";

function make() {
  var status = User_Provider.Hook.useStatus();
  var clientStatus = UseClientReady.make();
  var match = React.useState(function () {
        return [
                undefined,
                "Main"
              ];
      });
  var setHtmlElement = match[1];
  var match$1 = match[0];
  React.useEffect((function () {
          var playerElement = Core__Option.flatMap(Util_Dom.SafeWebApi.$$document, (function (__x) {
                  return Caml_option.nullable_to_opt(__x.getElementById("video_player"));
                }));
          var firstDataAnchorElement = Core__Option.flatMap(Util_Dom.SafeWebApi.$$document, (function (__x) {
                  return Caml_option.nullable_to_opt(__x.querySelector("[data-anchor]"));
                }));
          var firstFocusableElement = Core__Option.flatMap(Core__Option.flatMap(Util_Dom.SafeWebApi.$$document, (function ($$document) {
                      return Caml_option.nullable_to_opt($$document.querySelector("main"));
                    })), Util_Dom.getFirstElementFocusableWithElement);
          var htmlElement = Util_Opt.alt(playerElement, Util_Opt.alt(firstDataAnchorElement, firstFocusableElement));
          setHtmlElement(function (param) {
                return [
                        Core__Option.map(htmlElement, (function (prim) {
                                return prim;
                              })),
                        htmlElement === playerElement ? "Player" : "Main"
                      ];
              });
        }), [
        status,
        clientStatus
      ]);
  return [
          match$1[0],
          match$1[1]
        ];
}

export {
  make ,
}
/* react Not a pure module */
