import Url from 'url-parse';
import { type Page } from '@replay/types/Page';
import { type Teaser } from '@replay/types/Teaser';
import { getCode, getData, type Zone } from '@replay/types/Zone';

const findZone = (page: Page, zoneTitle: string): Zone | undefined => {
    const predicate = (zone: Zone) => getCode(zone).includes(zoneTitle);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (page.zones as any[]).find(predicate);
};

export const findProgram = (page: Page, type: 'next' | 'previous') => {
    let programToFind = null;

    const playNextZone = findZone(page, 'program_playNext');
    if (!playNextZone) {
        return null;
    }

    const codeId = getCode(playNextZone);
    const data = getData(playNextZone);

    const programIndex = data.findIndex((p) => p.programId === codeId);
    const programToFindIndex = type === 'next' ? programIndex + 1 : programIndex - 1;
    programToFind = programIndex !== -1 && data[programToFindIndex] ? data[programToFindIndex] : null;

    if (type === 'next' && !programToFind) {
        const program_recommendationsZone = findZone(page, 'program_recommendations');
        const dataRecomandations = program_recommendationsZone && getData(program_recommendationsZone);
        programToFind = dataRecomandations && dataRecomandations[0];
    }

    return programToFind;
};

const getImageURL = (teaserData: Teaser) => {
    if (teaserData) {
        return teaserData.mainImage?.url.replace('__SIZE__', '1920x1080');
    }
    return '';
};

const getProgramInfos = (teaserData: Teaser) => {
    const title: string = teaserData.title ? teaserData.title : '';
    const subtitle: string = teaserData.subtitle ? teaserData.subtitle : '';
    const imageUrl: string = getImageURL(teaserData);

    return { title, subtitle, imageUrl };
};

const mediaSessionHandler = (page: Page | null | undefined, setProgramUrl: (value: string) => void) => {
    if (!page) {
        return null;
    }

    const teaserZone = findZone(page, 'program_content');
    if (!teaserZone) {
        return null;
    }
    const teaserData = getData(teaserZone)[0];
    if (!teaserData) {
        return null;
    }

    if ('mediaSession' in navigator) {
        const { title, subtitle, imageUrl } = getProgramInfos(teaserData);

        navigator.mediaSession.metadata = new MediaMetadata({
            title: title,
            album: subtitle,
            artwork: [
                {
                    src: imageUrl,
                    sizes: '1920x1080',
                    type: 'image/png',
                },
            ],
        });

        const nextProgram = findProgram(page, 'next');
        const previousProgram = findProgram(page, 'previous');

        if (nextProgram) {
            navigator.mediaSession.setActionHandler('nexttrack', function () {
                setProgramUrl(new Url(nextProgram.url).pathname);
            });
        }

        if (previousProgram) {
            navigator.mediaSession.setActionHandler('previoustrack', function () {
                setProgramUrl(new Url(previousProgram.url).pathname);
            });
        }
    }
};

export default mediaSessionHandler;
