// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Logger from "../util/Util_Logger.res.mjs";
import * as VideoTypes from "@artegeie/page-constants/dist/videoTypes";

function displayError(scope, value) {
  Util_Logger.error2(scope + (": " + value), "This constant is not defined");
}

var Layout = {};

var Item_Template = {};

var _map = {"ebu-box":"ebu-box","event-textOnLeftSide":"event-textOnLeftSide","event-textOnRightSide":"event-textOnRightSide","horizontal-landscape":"horizontal-landscape","horizontal-landscapeBig":"horizontal-landscapeBig","horizontal-landscapeBigWithSubtitle":"horizontal-landscapeBigWithSubtitle","horizontal-landscape-genre":"horizontal-landscape-genre","horizontal-portrait":"horizontal-portrait","horizontal-poster":"horizontal-poster","horizontal-square":"horizontal-square","horizontalFirstHighlighted-square":"horizontalFirstHighlighted-square","horizontalSelectedHighlighted-landscape":"horizontalSelectedHighlighted-landscape","single-banner":"single-banner","single-collectionContent":"single-collectionContent","single-newsletter":"single-newsletter","single-pageHeader":"single-pageHeader","single-partner":"single-partner","single-programContent":"single-programContent","slider-banner":"slider-banner","slider-landscape":"slider-landscape","slider-square":"slider-square","tableview-guide":"tableview-guide","tableview-playnext":"tableview-playnext","vertical-label":"vertical-label","vertical-landscape":"vertical-landscape","verticalFirstHighlighted-landscape":"verticalFirstHighlighted-landscape"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function toItemTemplate(t) {
  if (t === "single-programContent") {
    return "ProgramContent";
  } else if (t === "single-collectionContent") {
    return "ContentCollection";
  } else if (t === "single-banner" || t === "slider-banner") {
    return "Banner";
  } else if (t === "horizontal-poster") {
    return "Poster";
  } else if (t === "horizontal-square" || t === "horizontalFirstHighlighted-square" || t === "slider-square") {
    return "Square";
  } else if (t === "vertical-landscape" || t === "tableview-guide" || t === "horizontalSelectedHighlighted-landscape" || t === "slider-landscape" || t === "horizontal-landscape" || t === "verticalFirstHighlighted-landscape") {
    return "Landscape";
  } else if (t === "horizontal-landscapeBig") {
    return "LandscapeBig";
  } else if (t === "horizontal-portrait") {
    return "Portrait";
  } else if (t === "single-partner") {
    return "Partner";
  } else if (t === "vertical-label") {
    return "Label";
  } else if (t === "single-pageHeader") {
    return "PageHeader";
  } else if (t === "ebu-box") {
    return "EbuBox";
  } else if (t === "event-textOnRightSide") {
    return "TextOnRightSide";
  } else if (t === "tableview-playnext") {
    return "PlayNext";
  } else if (t === "single-newsletter") {
    return "Newsletter";
  } else if (t === "horizontal-landscape-genre") {
    return "LandscapeGenre";
  } else if (t === "horizontal-landscapeBigWithSubtitle") {
    return "LandscapeBigWithSubtitle";
  } else {
    return "TextOnLeftSide";
  }
}

var Template = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  toItemTemplate: toItemTemplate
};

var scope = "Zone.DataKeys";

function decode(x) {
  if (x === "program_recommendations") {
    return "ProgramRecommendations";
  } else if (x === "program_categoryMostViewed") {
    return "ProgramMostViewedCategory";
  } else {
    displayError(scope, x);
    return "NotRecognized";
  }
}

var DataKeys = {
  scope: scope,
  decode: decode
};

var Zone = {
  Layout: Layout,
  Item_Template: Item_Template,
  Template: Template,
  DataKeys: DataKeys
};

var scope$1 = "VideoTypes";

function t_encode(v) {
  switch (v) {
    case "MostViewed" :
        return ["MostViewed"];
    case "LastChance" :
        return ["LastChance"];
    case "MostRecent" :
        return ["MostRecent"];
    case "AudioDescription" :
        return ["AudioDescription"];
    case "Magazines" :
        return ["Magazines"];
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "AudioDescription" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "AudioDescription"
                  };
          }
      case "LastChance" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "LastChance"
                  };
          }
      case "Magazines" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Magazines"
                  };
          }
      case "MostRecent" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MostRecent"
                  };
          }
      case "MostViewed" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MostViewed"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function encode(x) {
  switch (x) {
    case "MostViewed" :
        return VideoTypes.MOST_VIEWED;
    case "LastChance" :
        return VideoTypes.LAST_CHANCE;
    case "MostRecent" :
        return VideoTypes.MOST_RECENT;
    case "AudioDescription" :
        return VideoTypes.AUDIO_DESCRIPTION;
    case "Magazines" :
        return VideoTypes.MAGAZINES;
    
  }
}

function decode$1(x) {
  if (x === VideoTypes.MOST_VIEWED) {
    return "MostViewed";
  } else if (x === VideoTypes.LAST_CHANCE) {
    return "LastChance";
  } else if (x === VideoTypes.MOST_RECENT) {
    return "MostRecent";
  } else if (x === VideoTypes.AUDIO_DESCRIPTION) {
    return "AudioDescription";
  } else if (x === VideoTypes.MAGAZINES) {
    return "Magazines";
  } else {
    displayError(scope$1, x);
    return ;
  }
}

var VideoTypes$1 = {
  scope: scope$1,
  t_encode: t_encode,
  t_decode: t_decode,
  encode: encode,
  decode: decode$1
};

export {
  displayError ,
  Zone ,
  VideoTypes$1 as VideoTypes,
}
/* Util_Logger Not a pure module */
