// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../type/Type_Common.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_SocialMedia from "../type/Type_SocialMedia.res.mjs";

var _map = {"external":"external","internal":"internal","cookie":"cookie"};

function kindToJs(param) {
  return param;
}

function kindFromJs(param) {
  return _map[param];
}

function toDsFooterTarget(kind) {
  if (kind === "external") {
    return "_blank";
  } else if (kind === "internal") {
    return "_self";
  } else {
    return ;
  }
}

function toDsFooterItems(items) {
  return items.map(function (item) {
              var kind = item.kind;
              var label = item.label;
              var href = Type_String.Href.make(item.href);
              var icon = Type_SocialMedia.makeIconFromLabel(label, "Footer");
              var label$1 = Type_String.Footer.Label.make(label);
              var rel = Type_Common.Rel.make(item.rel);
              if (kind === "cookie") {
                return {
                        TAG: "Button",
                        _0: label$1,
                        _1: "Cookies"
                      };
              } else if (icon !== undefined) {
                return {
                        TAG: "Social",
                        _0: {
                          icon: Caml_option.valFromOption(icon),
                          label: item.label,
                          href: href
                        }
                      };
              } else {
                return {
                        TAG: "Link",
                        _0: label$1,
                        _1: href,
                        _2: toDsFooterTarget(kind),
                        _3: Caml_option.some(rel)
                      };
              }
            });
}

function toDsFooter(data) {
  return data.map(function (param) {
              return {
                      title: Type_String.Footer.Title.makeFromNullable(param.label),
                      href: Type_String.Href.makeFromNullable(param.href),
                      items: Core__Option.map(Caml_option.nullable_to_opt(param.links), (function (items) {
                              return toDsFooterItems(items);
                            }))
                    };
            });
}

export {
  kindToJs ,
  kindFromJs ,
  toDsFooterTarget ,
  toDsFooterItems ,
  toDsFooter ,
}
/* Type_Common Not a pure module */
