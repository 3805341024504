// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Box_Type from "../atom/Atom_Box_Type.res.mjs";
import * as Particle_Block_Ds from "./Particle_Block_Ds.res.mjs";
import * as Particle_Screen_Css from "./Particle_Screen_Css.res.mjs";

function wrapRule(wrap) {
  return CssJs.flexWrap("nowrap");
}

function getAutoSizeRules(autoSize) {
  var getAutoSizeRule = function (autoSizeValue) {
    var tmp;
    switch (autoSizeValue) {
      case "AllPlaceAvailable" :
          tmp = [CssJs.width(CssJs.pct(100))];
          break;
      case "MaxContent" :
          tmp = [CssJs.unsafe("width", "max-content")];
          break;
      case "AllPlaceNeeded" :
      case "NoResizing" :
          tmp = [];
          break;
      
    }
    return tmp.concat([Atom_Box_Type.getAutoSizeRule(autoSizeValue)]);
  };
  return Particle_Screen_Css.toRules(undefined, undefined, getAutoSizeRule, autoSize);
}

function toCss(t) {
  var match = Particle_Block_Ds.view(t);
  return Util_Array.catOption([
                Core__Option.map(match.wrap, wrapRule),
                Core__Option.map(match.justifyContent, CssJs.justifyContent),
                Core__Option.map(match.alignItems, CssJs.alignItems),
                Core__Option.map(match.alignSelf, CssJs.alignSelf),
                Core__Option.map(match.position, CssJs.position),
                Core__Option.map(match.overflow, CssJs.overflow)
              ]).concat(Core__Option.getOr(Core__Option.map(match.autoSize, getAutoSizeRules), []));
}

var justifyContentRule = CssJs.justifyContent;

var alignItemsRule = CssJs.alignItems;

var alignSelfRule = CssJs.alignSelf;

var positionRule = CssJs.position;

var overflowRule = CssJs.overflow;

export {
  wrapRule ,
  getAutoSizeRules ,
  justifyContentRule ,
  alignItemsRule ,
  alignSelfRule ,
  positionRule ,
  overflowRule ,
  toCss ,
}
/* CssJs Not a pure module */
