// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";

function make(page) {
  return React.useMemo((function () {
                return Util_Array.getFirstElement(Core__Array.filterMap(page.zones, (function (zone) {
                                  switch (zone.TAG) {
                                    case "Main" :
                                    case "Program" :
                                        return ;
                                    case "Guide" :
                                        return zone._0.VAL;
                                    
                                  }
                                })));
              }), [page.zones]);
}

export {
  make ,
}
/* react Not a pure module */
