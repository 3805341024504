'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Atom_Cell from "../design-system/atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../design-system/atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../design-system/atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Accessibility from "../design-system/particle/Particle_Accessibility.res.mjs";

var accessiblityPolite = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "polite", undefined, undefined, undefined, undefined, undefined);

var accessiblityAssertive = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "assertive", undefined, undefined, undefined, undefined, undefined);

function UseUserFetch$Component(props) {
  var state = props.state;
  var match = Context.I18n.use();
  var match$1 = match.labels.common;
  var match$2;
  match$2 = typeof state !== "object" ? (
      state === "Loading" ? [
          "none",
          "block"
        ] : [
          "none",
          "none"
        ]
    ) : (
      state.TAG === "Success" ? [
          "none",
          "block"
        ] : [
          "block",
          "none"
        ]
    );
  var tmp;
  tmp = typeof state !== "object" || state.TAG !== "Error" ? null : JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(state._0),
          style: Atom_Text_Ds.makeFeedback("Error")
        });
  var tmp$1;
  tmp$1 = typeof state !== "object" ? (
      state === "Loading" ? JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(match$1.loading),
              style: Atom_Text_Ds.User.info
            }) : null
    ) : (
      state.TAG === "Success" ? JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(state._0),
              style: Atom_Text_Ds.makeFeedback("Success")
            }) : null
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      accessibility: accessiblityAssertive,
                      display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, match$2[0])),
                      children: tmp
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      accessibility: accessiblityPolite,
                      display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, match$2[1])),
                      children: tmp$1
                    })
              ]
            });
}

function reducer(param, actions) {
  if (typeof actions !== "object") {
    if (actions === "Fetching") {
      return "Loading";
    } else {
      return "Idle";
    }
  } else if (actions.TAG === "SetSuccess") {
    return {
            TAG: "Success",
            _0: actions._0
          };
  } else {
    return {
            TAG: "Error",
            _0: actions._0
          };
  }
}

function make() {
  var match = React.useReducer(reducer, "Idle");
  var dispatch = match[1];
  var state = match[0];
  return {
          component: JsxRuntime.jsx(UseUserFetch$Component, {
                state: state
              }),
          setError: (function (response) {
              dispatch({
                    TAG: "SetError",
                    _0: response
                  });
            }),
          setIdle: (function () {
              dispatch("SetIdle");
            }),
          setLoading: (function () {
              dispatch("Fetching");
            }),
          setSuccess: (function (response) {
              dispatch({
                    TAG: "SetSuccess",
                    _0: response
                  });
            }),
          state: state
        };
}

export {
  make ,
}
/* accessiblityPolite Not a pure module */
