// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function make(fn, timeOut) {
  var id = {
    contents: null
  };
  return function (a) {
    Core__Option.mapOr(Caml_option.nullable_to_opt(id.contents), undefined, (function (prim) {
            clearTimeout(prim);
          }));
    id.contents = setTimeout((function () {
            fn(a);
          }), timeOut);
  };
}

export {
  make ,
}
/* No side effect */
