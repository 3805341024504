// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User_Type from "./User_Type.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as OidcClient from "@replay/oidc-client";

async function getToken() {
  var user = await OidcClient.getUser();
  if (!(user == null)) {
    return Caml_option.some(User_Type.Token.make(user.access_token));
  }
  
}

async function storeLegacyToken(token) {
  await OidcClient.storeLegacyToken(token);
}

async function removeLegacyToken() {
  return await OidcClient.removeStoredToken();
}

async function removeAnonymousToken() {
  return await OidcClient.removeStoredToken();
}

async function logout() {
  return await OidcClient.logout();
}

async function getTokenType() {
  return await OidcClient.getTokenType();
}

export {
  getToken ,
  storeLegacyToken ,
  removeLegacyToken ,
  removeAnonymousToken ,
  logout ,
  getTokenType ,
}
/* User_Type Not a pure module */
