// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.res.mjs";

var prefix = "--ads-";

var Var = {
  prefix: prefix
};

var Type = {
  Var: Var
};

var FontSize = {
  xxs: 10,
  xs: 14,
  s: 17,
  m: 20,
  m2: 22,
  l: 25,
  l2: 30,
  xl: 40,
  xxl: 55
};

var LineHeight = {
  xxs: 1,
  xs: 1.1,
  s: 1.2,
  m: 1.4,
  l: 1.6
};

var Constants = {
  FontSize: FontSize,
  LineHeight: LineHeight
};

function pxToRem(px) {
  var remValue = px / 16;
  return CssJs.rem(remValue);
}

var baseSize = CssJs.fontSize(CssJs.px(16));

function family(x) {
  return CssJs.fontFamilies([
              {
                NAME: "custom",
                VAL: "barna"
              },
              {
                NAME: "custom",
                VAL: "barna-fallback"
              },
              "sansSerif"
            ]);
}

function weight(x) {
  if (x === "Light") {
    return CssJs.fontWeight("light");
  } else if (x === "SemiBold") {
    return CssJs.fontWeight("semiBold");
  } else if (x === "Bold") {
    return CssJs.fontWeight("bold");
  } else if (x === "Medium") {
    return CssJs.fontWeight("medium");
  } else {
    return CssJs.fontWeight("normal");
  }
}

function size_int(x) {
  if (x === "L") {
    return 25;
  } else if (x === "M") {
    return 20;
  } else if (x === "S") {
    return 17;
  } else if (x === "L2") {
    return 30;
  } else if (x === "M2") {
    return 22;
  } else if (x === "XL") {
    return 40;
  } else if (x === "XS") {
    return 14;
  } else if (x === "XXL") {
    return 55;
  } else if (x === "XXS") {
    return 10;
  } else {
    return 17;
  }
}

function size_rem(x) {
  return pxToRem(size_int(x));
}

function line_height_float(x) {
  if (x === "L") {
    return 1.6;
  } else if (x === "M") {
    return 1.4;
  } else if (x === "S") {
    return 1.2;
  } else if (x === "XS") {
    return 1.1;
  } else if (x === "XXS") {
    return 1;
  } else {
    return 1.4;
  }
}

function line_height_abs(x) {
  return {
          NAME: "abs",
          VAL: line_height_float(x)
        };
}

function sizeVarName(t) {
  var varName = t === "L" ? "font-l" : (
      t === "M" ? "font-m" : (
          t === "S" ? "font-s" : (
              t === "L2" ? "font-l2" : (
                  t === "M2" ? "font-m2" : (
                      t === "XL" ? "font-xl" : (
                          t === "XS" ? "font-xs" : (
                              t === "XXL" ? "font-xxl" : (
                                  t === "XXS" ? "font-xxs" : "font-s"
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  return prefix + varName;
}

function getSizeCssVar(size) {
  return CssJs.$$var(sizeVarName(size));
}

function size(size$1) {
  return CssJs.fontSize(getSizeCssVar(size$1));
}

function lineHeightVarName(t) {
  var varName = t === "L" ? "line_height-l" : (
      t === "M" ? "line_height-m" : (
          t === "S" ? "line_height-s" : (
              t === "XS" ? "line_height-xs" : (
                  t === "XXS" ? "line_height-xxs" : "line_height-m"
                )
            )
        )
    );
  return prefix + varName;
}

function getlineHeightCssVar(size) {
  return CssJs.$$var(lineHeightVarName(size));
}

function line_height(x) {
  return CssJs.lineHeight(getlineHeightCssVar(x));
}

var allSizes = [
  [
    sizeVarName("XXS"),
    pxToRem(size_int("XXS"))
  ],
  [
    sizeVarName("XS"),
    pxToRem(size_int("XS"))
  ],
  [
    sizeVarName("S"),
    pxToRem(size_int("S"))
  ],
  [
    sizeVarName("M"),
    pxToRem(size_int("M"))
  ],
  [
    sizeVarName("M2"),
    pxToRem(size_int("M2"))
  ],
  [
    sizeVarName("L"),
    pxToRem(size_int("L"))
  ],
  [
    sizeVarName("L2"),
    pxToRem(size_int("L2"))
  ],
  [
    sizeVarName("XL"),
    pxToRem(size_int("XL"))
  ],
  [
    sizeVarName("XXL"),
    pxToRem(size_int("XXL"))
  ]
];

var varSizesCss = allSizes.map(function (param) {
      return CssJs.unsafe(param[0], Css_AtomicTypes.Length.toString(param[1]));
    });

var allLineHeights = [
  [
    lineHeightVarName("XXS"),
    line_height_abs("XXS")
  ],
  [
    lineHeightVarName("XS"),
    line_height_abs("XS")
  ],
  [
    lineHeightVarName("S"),
    line_height_abs("S")
  ],
  [
    lineHeightVarName("M"),
    line_height_abs("M")
  ],
  [
    lineHeightVarName("L"),
    line_height_abs("L")
  ]
];

var varLineHeightsCss = allLineHeights.map(function (param) {
      return CssJs.unsafe(param[0], Css_AtomicTypes.LineHeight.toString(param[1]));
    });

var basePx = 16;

export {
  Type ,
  Constants ,
  basePx ,
  pxToRem ,
  baseSize ,
  family ,
  weight ,
  size_int ,
  size_rem ,
  line_height_float ,
  line_height_abs ,
  sizeVarName ,
  getSizeCssVar ,
  size ,
  lineHeightVarName ,
  getlineHeightCssVar ,
  line_height ,
  allSizes ,
  varSizesCss ,
  allLineHeights ,
  varLineHeightsCss ,
}
/* baseSize Not a pure module */
