// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M16 2.99988C8.82413 2.99988 3 8.82401 3 15.9999C3 23.177 8.82413 29.0011 16 29.0011C23.1759 29.0011 29 23.177 29 15.9999C29 8.82401 23.1759 2.99988 16 2.99988ZM16 4.67371C22.2451 4.67371 27.3262 9.75476 27.3262 15.9999C27.3262 22.2463 22.2451 27.3273 16 27.3273C9.75487 27.3273 4.67381 22.2463 4.67381 15.9999C4.67381 9.75476 9.75487 4.67371 16 4.67371Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M11.0546 16.5831C11.3983 16.2343 11.9131 16.2343 12.2453 16.5831L15.0692 19.4843V11.0341C15.0692 10.5459 15.4598 10.1503 15.9379 10.1503C16.4159 10.1503 16.8065 10.5459 16.8065 11.0341V19.4894L19.6925 16.5881C19.8587 16.4169 20.0983 16.3282 20.3215 16.3282C20.5447 16.3282 20.7691 16.4169 20.9454 16.5881C21.2839 16.9305 21.289 17.4922 20.9504 17.8359L16.6137 22.2398C16.2764 22.5885 15.7248 22.5885 15.3913 22.2398L11.0597 17.8308C10.711 17.4872 10.711 16.9305 11.0546 16.5831Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
