import path from 'ramda/src/path';

import { type ATTag, type AtEventParams } from '@replay/types/definitions/atInternet';

export const sendAtCampaignEvent = (event: string, data: AtEventParams) => {
    const Tag: ATTag | undefined = path(['ATInternet', 'Tracker', 'Tag'], window);
    if (Tag) {
        const tag = new Tag();
        tag.privacy.setVisitorOptin();
        tag?.events?.send(event, data);
        tag.dispatch();
    }
};
