// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M16.2908 1L10.9152 6.41393L12.2289 7.73678L15.3617 4.58216V18.1558H17.2199V4.58216L20.3528 7.73678L21.6665 6.41393L16.2908 1ZM20.219 9.29022V11.3628H23.5805V25.9226H9.07257V11.3628H12.2363V9.29022H7V28H25.5816V9.29022H20.219Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
