// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../selector/Identifiers.res.mjs";
import * as Type_String from "./Type_String.res.mjs";
import * as Atom_Icon_Ds from "../design-system/atom/Atom_Icon_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function makeIconFromLabel(label, $$location) {
  var Icon = $$location === "Footer" ? Atom_Icon_Ds.SocialMedia.Footer : ({
        youtube: Atom_Icon_Ds.SocialMedia.youtube,
        instagram: Atom_Icon_Ds.SocialMedia.instagram,
        x: Atom_Icon_Ds.SocialMedia.x,
        facebook: Atom_Icon_Ds.SocialMedia.facebook,
        tiktok: Atom_Icon_Ds.SocialMedia.tiktok,
        twitch: Atom_Icon_Ds.SocialMedia.twitch
      });
  switch (label) {
    case "Facebook" :
        return Caml_option.some(Icon.facebook);
    case "Instagram" :
        return Caml_option.some(Icon.instagram);
    case "Tiktok" :
        return Caml_option.some(Icon.tiktok);
    case "Twitch" :
        return Caml_option.some(Icon.twitch);
    case "Twitter" :
        return Caml_option.some(Icon.x);
    case "Youtube" :
        return Caml_option.some(Icon.youtube);
    default:
      return ;
  }
}

function fromHeader(socialNetworks) {
  var facebook = Core__Option.map(Caml_option.nullable_to_opt(socialNetworks.facebook), (function (fb) {
          return {
                  icon: Atom_Icon_Ds.SocialMedia.facebook,
                  label: "Facebook",
                  href: Type_String.Href.make(fb),
                  identifier: Caml_option.some(Identifiers.SocialLinks.facebook)
                };
        }));
  var twitter = Core__Option.map(Caml_option.nullable_to_opt(socialNetworks.twitter), (function (tw) {
          return {
                  icon: Atom_Icon_Ds.SocialMedia.x,
                  label: "Twitter",
                  href: Type_String.Href.make(tw),
                  identifier: Caml_option.some(Identifiers.SocialLinks.twitter)
                };
        }));
  var instagram = Core__Option.map(Caml_option.nullable_to_opt(socialNetworks.instagram), (function (ig) {
          return {
                  icon: Atom_Icon_Ds.SocialMedia.instagram,
                  label: "Instagram",
                  href: Type_String.Href.make(ig),
                  identifier: Caml_option.some(Identifiers.SocialLinks.instagram)
                };
        }));
  var youtube = Core__Option.map(Caml_option.nullable_to_opt(socialNetworks.youtube), (function (yt) {
          return {
                  icon: Atom_Icon_Ds.SocialMedia.youtube,
                  label: "Youtube",
                  href: Type_String.Href.make(yt),
                  identifier: Caml_option.some(Identifiers.SocialLinks.youtube)
                };
        }));
  var tiktok = Core__Option.map(Caml_option.nullable_to_opt(socialNetworks.tiktok), (function (tt) {
          return {
                  icon: Atom_Icon_Ds.SocialMedia.tiktok,
                  label: "Tiktok",
                  href: Type_String.Href.make(tt),
                  identifier: Caml_option.some(Identifiers.SocialLinks.tiktok)
                };
        }));
  return Util_Array.catOption([
              facebook,
              twitter,
              instagram,
              tiktok,
              youtube
            ]);
}

export {
  makeIconFromLabel ,
  fromHeader ,
}
/* Identifiers Not a pure module */
