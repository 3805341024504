// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Teaser_Css from "./Organism_Teaser_Css.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("M", "L"));

var display = Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none");

var moreOptionPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: {
          NAME: "px",
          VAL: 0
        }
      }
    });

function Organism_Teaser_Focus(props) {
  var screenReadersLabel = props.screenReadersLabel;
  var label = props.label;
  var screenReadersLabel$1 = screenReadersLabel !== undefined ? label + " " + screenReadersLabel : label;
  if (props.style === "Default") {
    return null;
  } else {
    return JsxRuntime.jsx(Atom_Column.make, {
                background: Caml_option.some(props.background),
                children: JsxRuntime.jsx(Atom_Box.make, {
                      children: Caml_option.some(JsxRuntime.jsx(Atom_Cta.Button.make, {
                                ctaRef: props.buttonRef,
                                identifier: Caml_option.some(Identifiers.Teaser.moreOptionsButton),
                                label: label,
                                onClick: props.onClick,
                                screenReadersLabel: screenReadersLabel$1,
                                style: Atom_Cta_Ds.Teaser.teaserMoreOptions
                              })),
                      spacer: Caml_option.some(blockSpace)
                    }),
                display: Caml_option.some(display),
                identifier: Caml_option.some(Identifiers.Teaser.panelFocus),
                position: Caml_option.some(moreOptionPosition),
                rules: Caml_option.some(Organism_Teaser_Css.panelFocusRules),
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
              });
  }
}

var make = Organism_Teaser_Focus;

export {
  blockSpace ,
  display ,
  moreOptionPosition ,
  make ,
}
/* blockSpace Not a pure module */
