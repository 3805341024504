// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = (function (baseUrl) {
    return {
    
            Concert: {
                title: 'ARTE Concert',
                url: baseUrl + '/de/arte-concert/',
                navigation: {
                    links: [
                        {
                            label: 'Unser Angebot',
                            href: baseUrl + '/de/arte-concert/neueste-videos/ ',
                            id: 'concert-genres',
                            kind: 'other',
                        },
                        {
                            label: 'Demnächst online',
                            href: baseUrl + '/de/arte-concert/demnachst/',
                            id: 'tv_guide',
                            kind: 'other',
                        },
                    ],
                },
                 socialNetworks:{
                    facebook: 'https://www.facebook.com/ARTEConcertDE',
                    twitter: null,
                    instagram: " https://www.instagram.com/arteconcertde/",
                    youtube: "https://www.youtube.com/c/arteconcert",
                    tiktok: null,
                },
                 concertSelectGenre:{
                    title:{label:"Unser Angebot", href:baseUrl +"/de/arte-concert/neueste-videos/"},
                    items:[
                        {
                            label:"Pop & Rock",
                            href: baseUrl +"/de/p/pop-rock/" 
                        },
                        {
                            label:"Electro",
                            href:  baseUrl +"/de/p/electronic/" 
                        },
                        {
                            label:"Metal",
                            href:baseUrl +"/de/p/metal/"
                        },
                        {
                            label:"Hip-Hop",
                            href:baseUrl +"/de/p/hip-hop/"
                        },
                        {
                            label:"Jazz",
                            href:baseUrl +"/de/p/jazz/"
                        },
                        {
                            label:"Klassik",
                            href: baseUrl +"/de/p/klassik/" 
                        },
                        {
                            label:"Oper",
                            href: baseUrl +"/de/p/oper/"
                        },
                        {
                            label:"Barock",
                            href: baseUrl +"/de/p/barockmusik/" 
                        },
                        {
                            label:"Performance",
                            href: baseUrl+"/de/p/buehnen-performance/" 
                        },
                        {
                            label:"World",
                            href:baseUrl +"/de/p/world/"
                        },
                       
                    ]
               
            },
            guide:{  label: 'Demnächst online',
                            href: baseUrl + '/de/arte-concert/demnachst/',}
            },
            LogoNavigation: {
                label: '',
                title: 'Arte, home',
                href: baseUrl + '/de/',
            },
            DesktopNavigation: {
                links: [
                    {
                        label: 'TV-Programm',
                        href: baseUrl + '/de/guide/',
                        id: 'tv_guide',
                    },
                    {
                        label: 'Live',
                        href: baseUrl + '/de/live/',
                        id: 'live',
                    },
                    {
                        label: 'Bald online',
                        href: baseUrl + '/de/p/demnaechst/',
                        id: 'upcoming',
                    },
                    {
                        label: 'ARTE Concert',
                        href: baseUrl + '/de/arte-concert/',
                        id: 'arte_concert',
                    },
                ],
            },
            MenuNavigation: {
                mobileLinks: [
                    {
                        label: 'Home',
                        href: baseUrl + '/de/',
                    },
                    {
                        label: 'TV-Programm',
                        href: baseUrl + '/de/guide/',
                    },
                    {
                        label: 'Live',
                        href: baseUrl + '/de/live/',
                    },
                    {
                        label: 'Bald online',
                        href: baseUrl + '/de/p/demnaechst/',
                        id: 'upcoming',
                    },
                ],
                categories: [
                    {
                        label: 'Dokus und Reportagen',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/dokumentationen-und-reportagen/',
                        code: 'DOR',
                    },
                    {
                        label: 'Kino',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/kino/',
                        code: 'CIN',
                    },
                    {
                        label: 'Fernsehfilme und Serien',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/fernsehfilme-und-serien/',
                        code: 'SER',
                    },
                    {
                        label: 'Aktuelles und Gesellschaft',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/aktuelles-und-gesellschaft/',
                        code: 'ACT',
                    },
                    {
                        label: 'Kultur und Pop',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/kultur-und-pop/',
                        code: 'CPO',
                    },
                    {
                        label: 'ARTE Concert',
                        kind: 'internal',
                        href: baseUrl + '/de/arte-concert/',
                        code: 'ARS',
                    },
                    {
                        label: 'Wissenschaft',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/wissenschaft/',
                        code: 'SCI',
                    },
                    {
                        label: 'Entdeckung der Welt',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/entdeckung-der-welt/',
                        code: 'DEC',
                    },
                    {
                        label: 'Geschichte',
                        kind: 'internal',
                        href: baseUrl + '/de/videos/geschichte/',
                        code: 'HIS',
                    },
                ],
                otherLinks: [
                    {
                        label: 'Alle Sendungen',
                        href: baseUrl + '/de/videos/sendungen/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Alle Videos',
                        href: baseUrl + '/de/videos/meistgesehen/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Barrierefreie Programme',
                        href: baseUrl + '/de/p/barrierefreie-programme/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Gewinnspiele',
                        href: baseUrl + '/de/profile/events/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Virtuellen Assistenten',
                        href: 'https://chatbot.arte.tv/de/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Videospiele',
                        href: 'https://www.arte.tv/digitalproductions/de/categories/videospiele/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'ARTE Magazin',
                        href: 'https://www.arte-magazin.de/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Digitale Produktionen',
                        href: 'https://www.arte.tv/digitalproductions/de/',
                        deeplink: null,
                        code: null,
                        kind: 'external',
                    },
                ],
            },
    };
});

export {
  $$default as default,
}
/* No side effect */
