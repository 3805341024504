// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Category from "../../type/Type_Category.res.mjs";
import * as Layout_Constants from "../layout/Layout_Constants.res.mjs";
import * as Organism_Dropdown from "../organism/dropdown/Organism_Dropdown.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Molecule_PaginatedGrid from "../molecule/Molecule_PaginatedGrid.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var blockSpaceDropdown = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Constants.ListPage.dropdown);

var blockSpaceGrid = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Constants.ListPage.grid);

var blockSpaceContainer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.desktop, undefined, undefined, Particle_Spacer_Constants.Zone.mobile);

function make(param) {
  var match = Context.I18n.use();
  var labels = match.labels;
  var formaters = match.formaters;
  var match$1 = labels.accessibility;
  var match$2 = labels.common;
  var match$3 = labels.program;
  var match$4 = labels.accessibility;
  var match$5 = labels.videos;
  var match$6 = Context.Config.use();
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, "Genre", param.domain, match$6.serverTime, param.page);
  var zone = Util_Array.getFirstElement(page.zones);
  var pageVideoType = Core__Option.getOr(Core__Option.flatMap(page.page, Type_Category.getVideoType), "MostViewed");
  var dropdownItems = Util_Array.NonEmpty.makeFromArray(param.dropdownItems);
  var dropdown = dropdownItems !== undefined ? JsxRuntime.jsx(Atom_Row.make, {
          blockSpace: Caml_option.some(blockSpaceDropdown),
          children: JsxRuntime.jsx(Organism_Dropdown.make, {
                kind: {
                  TAG: "List",
                  _0: {
                    title: match$4.showVideosSortingOptions,
                    titleItems: match$4.sortVideoBy,
                    buttonKind: "h1",
                    variant: "list"
                  }
                },
                items: Caml_option.valFromOption(dropdownItems),
                label: match$5.sort_by_label,
                value: param.dropdownValue
              })
        }) : null;
  var match$7;
  match$7 = pageVideoType === "AudioDescription" ? [
      match$3.audiodescription,
      "h1",
      null
    ] : [
      match$2.all_videos,
      "h2",
      dropdown
    ];
  var tmp;
  if (zone !== undefined) {
    switch (zone.TAG) {
      case "Main" :
          var match$8 = zone._0;
          var match$9 = match$8.template;
          tmp = typeof match$9 === "object" && match$9.NAME === "PaginatedGrid" ? JsxRuntime.jsx(Molecule_PaginatedGrid.make, {
                  id: match$8.id,
                  seeMoreLabel: match$2.see_more,
                  zone: match$9.VAL.zone,
                  blockSpace: Caml_option.some(blockSpaceGrid),
                  url: page.url,
                  seeMoreAriaLabel: match$1.seeMore
                }) : null;
          break;
      case "Program" :
      case "Guide" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(blockSpaceContainer),
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match$7[0]),
                      kind: match$7[1],
                      style: Atom_Text_Ds.pageList
                    }),
                match$7[2],
                tmp
              ],
              maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.margin("auto")])),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

export {
  blockSpaceDropdown ,
  blockSpaceGrid ,
  blockSpaceContainer ,
  make ,
}
/* blockSpaceDropdown Not a pure module */
