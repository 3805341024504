// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as UseOnBlur from "../../../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../../../hook/UseToggle.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Header from "../../../../type/Type_Header.res.mjs";
import * as UseClickOutside from "../../../../hook/UseClickOutside.res.mjs";
import * as UseClickTracking from "../../../../hook/UseClickTracking.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Molecule_BurgerMenuIcon from "../../../molecule/Molecule_BurgerMenuIcon.res.mjs";
import * as Organism_MenuNavigation from "./Organism_MenuNavigation.res.mjs";
import * as Molecule_BurgerMenuIcon_Ds from "../../../molecule/Molecule_BurgerMenuIcon_Ds.res.mjs";

function Organism_BurgerMenu(props) {
  var match = Context.I18n.use();
  var ref = React.useRef(null);
  var clickTracking = UseClickTracking.use();
  var match$1 = UseToggle.make(undefined, (function () {
          clickTracking({
                eventGroup: "header",
                eventName: "burger_menu"
              });
        }), undefined, undefined, undefined, undefined);
  var close = match$1.close;
  var state = match$1.state;
  UseClickOutside.make(ref, close);
  UseOnBlur.make(ref, close);
  var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.unset)]);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Molecule_BurgerMenuIcon.make, {
                      state: state,
                      toggle: match$1.toggle,
                      style: Molecule_BurgerMenuIcon_Ds.header
                    }),
                JsxRuntime.jsx(Organism_MenuNavigation.make, {
                      menuNavigation: props.menuNavigation,
                      state: state,
                      category: props.category,
                      country: props.country
                    })
              ],
              display: props.display,
              height: Caml_option.some(Type_Header.Constants.heightStyle),
              justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              kind: {
                TAG: "Nav",
                _0: match.labels.navbar.mainNavigation
              },
              ref_: ref,
              rules: Caml_option.some(rules)
            });
}

var make = Organism_BurgerMenu;

export {
  make ,
}
/* CssJs Not a pure module */
