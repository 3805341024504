// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Type_Club from "../../club/Type_Club.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

function endpoint_1(args) {
  return "favorites/" + Util_I18n.localeToString(args.language) + "?page=" + args.page.toString();
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

var GetConfig = {
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  decodeApiResponse: Type_Club.teasersResponse_decode
};

var Get = User_Api_Config.MakeRequest({
      errorResponse_decode: errorResponse_decode,
      mapError: mapError,
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: Type_Club.teasersResponse_decode,
      options: options
    });

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "programId",
                    false,
                    Type_String.Teaser.DsId.t_encode(v.programId)
                  ],
                  [
                    "language",
                    false,
                    Util_I18n.locale_encode(v.language)
                  ]
                ]));
}

function errorResponse_encode$1(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$1(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$1 = User_Api_Config.$$Error.mapError;

var endpoint$1 = {
  NAME: "Static",
  VAL: "favorites"
};

function makeBody(args) {
  return args_encode(args);
}

var options$1 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

function decodeApiResponse(resp) {
  return Core__Result.map(Type_Club.Favorite.favorites_decode(resp), (function (response) {
                return Type_Club.Favorite.getFirstTeaser(response);
              }));
}

var PutConfig = {
  args_encode: args_encode,
  errorResponse_encode: errorResponse_encode$1,
  errorResponse_decode: errorResponse_decode$1,
  mapError: mapError$1,
  domain: "Sso",
  endpoint: endpoint$1,
  method: "Put",
  makeBody: makeBody,
  options: options$1,
  decodeApiResponse: decodeApiResponse
};

var Put = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$1,
      errorResponse_decode: errorResponse_decode$1,
      domain: "Sso",
      mapError: mapError$1,
      method: "Put",
      endpoint: endpoint$1,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options$1
    });

function errorResponse_encode$2(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$2(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$2 = User_Api_Config.$$Error.mapError;

function endpoint_1$1(args) {
  return "favorites/" + Type_String.Teaser.DsId.toString(args.programId);
}

var endpoint$2 = {
  NAME: "Dynamic",
  VAL: endpoint_1$1
};

var options$2 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

function makeBody$1(param) {
  
}

function decodeApiResponse$1(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var DeleteConfig = {
  errorResponse_encode: errorResponse_encode$2,
  errorResponse_decode: errorResponse_decode$2,
  mapError: mapError$2,
  domain: "Sso",
  endpoint: endpoint$2,
  method: "Delete",
  options: options$2,
  makeBody: makeBody$1,
  decodeApiResponse: decodeApiResponse$1
};

var Delete = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$2,
      errorResponse_decode: errorResponse_decode$2,
      domain: "Sso",
      mapError: mapError$2,
      method: "Delete",
      endpoint: endpoint$2,
      makeBody: makeBody$1,
      decodeApiResponse: decodeApiResponse$1,
      options: options$2
    });

function errorResponse_encode$3(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$3(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$3 = User_Api_Config.$$Error.mapError;

var endpoint$3 = {
  NAME: "Static",
  VAL: "favorites/purge"
};

var options$3 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

function makeBody$2(param) {
  
}

function decodeApiResponse$2(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var PurgeConfig = {
  errorResponse_encode: errorResponse_encode$3,
  errorResponse_decode: errorResponse_decode$3,
  mapError: mapError$3,
  domain: "Sso",
  endpoint: endpoint$3,
  method: "Patch",
  options: options$3,
  makeBody: makeBody$2,
  decodeApiResponse: decodeApiResponse$2
};

var Purge = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$3,
      errorResponse_decode: errorResponse_decode$3,
      domain: "Sso",
      mapError: mapError$3,
      method: "Patch",
      endpoint: endpoint$3,
      makeBody: makeBody$2,
      decodeApiResponse: decodeApiResponse$2,
      options: options$3
    });

var get = Get.call;

var put = Put.call;

var $$delete = Delete.call;

var purge = Purge.call;

export {
  GetConfig ,
  Get ,
  get ,
  PutConfig ,
  Put ,
  put ,
  DeleteConfig ,
  Delete ,
  $$delete ,
  PurgeConfig ,
  Purge ,
  purge ,
}
/* Get Not a pure module */
