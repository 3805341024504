// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M15.9938 3C9.10272 3 3.46644 8.35756 3.01924 15.1321H6.20216V16.4966H3C3.26156 23.4459 8.9774 29 15.9938 29C23.0101 29 29 23.1796 29 16C29 8.82036 23.177 3 15.9938 3ZM15.2834 20.68H10.1854V19.1663H11.9778V12.9528L10.411 13.5903L9.95968 12.3423L12.1369 11.32H13.6241V19.1663H15.2834V20.68ZM18.8725 20.68C16.6209 20.68 15.7046 18.965 15.7046 17.4466C15.7046 15.4046 17.8777 12.7469 19.095 11.32H21.0851C21.0851 11.32 19.8282 12.7599 18.755 14.2788C18.755 14.2788 18.899 14.2528 19.03 14.2528C20.9676 14.2528 22.0408 15.8107 22.0408 17.4472C22.0408 18.6255 21.3856 20.68 18.8725 20.68ZM20.3389 17.4466C20.3389 18.3759 19.9593 19.2791 18.8725 19.2791C17.7857 19.2791 17.4061 18.4149 17.4061 17.4466C17.4061 16.4784 17.9167 15.7712 17.9822 15.6792C17.9822 15.6792 18.1523 15.6266 18.3618 15.6266C19.6447 15.6266 20.3389 16.4649 20.3389 17.4466Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
