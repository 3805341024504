// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Spacer from "../particle/Particle_Spacer.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";

function getPosition(alignPosition, spacer) {
  var iteratee = function (alignPosition) {
    var calculatedDistance_1 = [
      "add",
      {
        NAME: "percent",
        VAL: 100
      },
      {
        NAME: "px",
        VAL: Particle_Spacer.spacerToInt(spacer)
      }
    ];
    var calculatedDistance = {
      NAME: "calc",
      VAL: calculatedDistance_1
    };
    if (alignPosition === "BottomLeft") {
      return {
              TAG: "Absolute",
              _0: {
                top: calculatedDistance,
                bottom: "auto",
                right: calculatedDistance,
                left: "auto"
              }
            };
    } else if (alignPosition === "FullWindow") {
      return {
              TAG: "Fixed",
              _0: {
                top: "zero",
                bottom: "zero",
                right: "zero",
                left: "zero"
              }
            };
    } else if (alignPosition === "BottomRight") {
      return {
              TAG: "Absolute",
              _0: {
                top: calculatedDistance,
                bottom: "auto",
                right: "auto",
                left: calculatedDistance
              }
            };
    } else if (alignPosition === "TopLeft") {
      return {
              TAG: "Absolute",
              _0: {
                top: "auto",
                bottom: calculatedDistance,
                right: calculatedDistance,
                left: "auto"
              }
            };
    } else {
      return {
              TAG: "Absolute",
              _0: {
                top: "auto",
                bottom: calculatedDistance,
                right: "auto",
                left: calculatedDistance
              }
            };
    }
  };
  return Particle_Screen_Ds.map(alignPosition, iteratee);
}

function makeStyle(style) {
  switch (style.TAG) {
    case "Player" :
        var position = getPosition(style._0, style._1);
        return {
                position: position,
                decoration: "WithoutArrow",
                zIndex: style._2,
                background: Particle_Background_Ds.tooltip,
                borderRadius: "Square",
                visibility: "visible"
              };
    case "PresseDownload" :
        return {
                position: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                      TAG: "Fixed",
                      _0: {
                        top: CssJs.px(style._0),
                        left: CssJs.px(style._1)
                      }
                    }),
                decoration: {
                  TAG: "WithArrow",
                  _0: "Top"
                },
                zIndex: {
                  TAG: "Above",
                  _0: "SliderArrow"
                },
                background: Particle_Background_Ds.Presse.tooltip,
                borderRadius: "Rounded",
                visibility: style._2
              };
    case "Program" :
        return {
                position: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                      TAG: "Fixed",
                      _0: {
                        top: CssJs.px(style._0),
                        left: CssJs.px(style._1)
                      }
                    }),
                decoration: {
                  TAG: "WithArrow",
                  _0: "Bottom"
                },
                zIndex: {
                  TAG: "Above",
                  _0: "SliderArrow"
                },
                background: Particle_Background_Ds.Presse.tooltip,
                borderRadius: "Rounded",
                visibility: style._2
              };
    
  }
}

function makeArrowPosition(arrowPosition, arrowSize) {
  var sidePosition_1 = [
    "sub",
    {
      NAME: "percent",
      VAL: 50
    },
    {
      NAME: "px",
      VAL: arrowSize / 2 | 0
    }
  ];
  var sidePosition = {
    NAME: "calc",
    VAL: sidePosition_1
  };
  var bottomPosition_1 = [
    "sub",
    {
      NAME: "percent",
      VAL: 100
    },
    {
      NAME: "px",
      VAL: arrowSize / 2 | 0
    }
  ];
  var bottomPosition = {
    NAME: "calc",
    VAL: bottomPosition_1
  };
  switch (arrowPosition) {
    case "Left" :
        return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                    TAG: "Absolute",
                    _0: {
                      top: sidePosition,
                      left: {
                        NAME: "px",
                        VAL: -(arrowSize / 2 | 0) | 0
                      }
                    }
                  });
    case "Right" :
        return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                    TAG: "Absolute",
                    _0: {
                      top: sidePosition,
                      right: {
                        NAME: "px",
                        VAL: -(arrowSize / 2 | 0) | 0
                      }
                    }
                  });
    case "Top" :
        return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                    TAG: "Absolute",
                    _0: {
                      top: {
                        NAME: "px",
                        VAL: -(arrowSize / 2 | 0) | 0
                      },
                      bottom: {
                        NAME: "percent",
                        VAL: 100
                      },
                      left: sidePosition
                    }
                  });
    case "Bottom" :
        return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                    TAG: "Absolute",
                    _0: {
                      top: bottomPosition,
                      bottom: {
                        NAME: "px",
                        VAL: 0
                      },
                      left: sidePosition
                    }
                  });
    
  }
}

export {
  getPosition ,
  makeStyle ,
  makeArrowPosition ,
}
/* CssJs Not a pure module */
