// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Particle_Spacer from "../../particle/Particle_Spacer.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Teaser_Ds from "../../organism/teaser/Organism_Teaser_Ds.res.mjs";
import * as Particle_Border_Ds from "../../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Teaser_Guide from "../../organism/guide/Organism_Teaser_Guide.res.mjs";

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, "flexStart", undefined, "center");

var border = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.guide);

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.Both.make({
          top: "S",
          bottom: "S",
          left: "Zero",
          right: "Zero"
        }, {
          top: "Zero",
          bottom: "L",
          left: "Zero",
          right: "Zero"
        }), undefined, undefined, Particle_Spacer_Ds.Both.make({
          top: "XS",
          bottom: "XS",
          left: "Zero",
          right: "Zero"
        }, {
          top: "Zero",
          bottom: "L",
          left: "Zero",
          right: "Zero"
        }));

var marginLeftRules = Particle_Screen_Ds.make(undefined, undefined, undefined, [CssJs.marginLeft(CssJs.px((Particle_Spacer.spacerToInt(Organism_Teaser_Guide.Organism_Teaser_Guide_Css.gapColumnValue) + Particle_Spacer.spacerToInt(Organism_Teaser_Ds.Guide.horizontalPaddingValue) | 0) + Organism_Teaser_Guide.Organism_Teaser_Guide_Css.leftPartWidthValue | 0))], undefined, []);

function Layout_Guide_Title(props) {
  var __kind = props.kind;
  var kind = __kind !== undefined ? __kind : "h2";
  return JsxRuntime.jsx(Atom_Cell.make, {
              justifyContent: Caml_option.some(justifyContent),
              blockSpace: Caml_option.some(blockSpace),
              border: Caml_option.some(border),
              children: JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(props.label),
                    kind: kind,
                    style: Atom_Text_Ds.Guide.title
                  }),
              rules: Caml_option.some(marginLeftRules),
              ref_: props.titleRef
            });
}

var make = Layout_Guide_Title;

export {
  justifyContent ,
  border ,
  blockSpace ,
  marginLeftRules ,
  make ,
}
/* justifyContent Not a pure module */
