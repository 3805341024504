// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as MyNullable from "../../MyNullable.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "isEmailUpdate",
                    false,
                    Spice.boolToJson(v.isEmailUpdate)
                  ],
                  [
                    "verificationToken",
                    false,
                    Spice.stringToJson(v.verificationToken)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var access_token = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "access_token"), null));
  if (access_token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              access_token: access_token._0
            }
          };
  }
  var e = access_token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".access_token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

var endpoint = {
  NAME: "Static",
  VAL: "verify-email"
};

function makeBody(args) {
  return args_encode(args);
}

var options = {
  tokenKind: "Sso"
};

var PostConfig = {
  args_encode: args_encode,
  response_decode: response_decode,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  method: "Post",
  makeBody: makeBody,
  decodeApiResponse: response_decode,
  options: options
};

var Post = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode,
      errorResponse_decode: errorResponse_decode,
      domain: "Sso",
      mapError: mapError,
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: response_decode,
      options: options
    });

var verify = Post.call;

export {
  PostConfig ,
  Post ,
  verify ,
}
/* Post Not a pure module */
