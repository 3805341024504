// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Type_Club from "../../club/Type_Club.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

function endpoint_1(args) {
  return "lastvieweds/" + Util_I18n.localeToString(args.language) + "?page=" + args.page.toString();
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

var GetConfig = {
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  decodeApiResponse: Type_Club.teasersResponse_decode
};

var Get = User_Api_Config.MakeRequest({
      errorResponse_decode: errorResponse_decode,
      mapError: mapError,
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: Type_Club.teasersResponse_decode,
      options: options
    });

function errorResponse_encode$1(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$1(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$1 = User_Api_Config.$$Error.mapError;

function endpoint_1$1(args) {
  return "lastvieweds/" + args.language + "/" + args.programId;
}

var endpoint$1 = {
  NAME: "Dynamic",
  VAL: endpoint_1$1
};

var options$1 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

var decodeApiResponse = Type_Club.VideoInProgress.videosInProgress_decode;

var GetByIdConfig = {
  errorResponse_encode: errorResponse_encode$1,
  errorResponse_decode: errorResponse_decode$1,
  mapError: mapError$1,
  domain: "Sso",
  endpoint: endpoint$1,
  options: options$1,
  decodeApiResponse: decodeApiResponse
};

var GetById = User_Api_Config.MakeRequest({
      errorResponse_decode: errorResponse_decode$1,
      mapError: mapError$1,
      domain: "Sso",
      endpoint: endpoint$1,
      decodeApiResponse: decodeApiResponse,
      options: options$1
    });

function errorResponse_encode$2(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$2(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$2 = User_Api_Config.$$Error.mapError;

var endpoint$2 = {
  NAME: "Static",
  VAL: "lastvieweds_batch"
};

var options$2 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

function requestBody_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "programId",
                    false,
                    Spice.stringToJson(v.programId)
                  ],
                  [
                    "timecode",
                    false,
                    Spice.stringToJson(v.timecode)
                  ]
                ]));
}

function batchRequestBody_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "language",
                    false,
                    Spice.stringToJson(v.language)
                  ],
                  [
                    "lastvieweds",
                    false,
                    (function (extra) {
                          return Spice.arrayToJson(requestBody_encode, extra);
                        })(v.lastvieweds)
                  ]
                ]));
}

function makeBody(args) {
  return batchRequestBody_encode({
              language: Util_I18n.localeToString(args.language),
              lastvieweds: Core__Array.filterMap(args.programs, (function (param) {
                      var timecode = param.timecode;
                      if (timecode === 0) {
                        return ;
                      } else {
                        return {
                                programId: param.programId,
                                timecode: param.progress === 100 ? "complete" : timecode.toString()
                              };
                      }
                    }))
            });
}

function decodeApiResponse$1(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var SetBatchConfig = {
  errorResponse_encode: errorResponse_encode$2,
  errorResponse_decode: errorResponse_decode$2,
  mapError: mapError$2,
  domain: "Sso",
  endpoint: endpoint$2,
  method: "Post",
  options: options$2,
  requestBody_encode: requestBody_encode,
  batchRequestBody_encode: batchRequestBody_encode,
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse$1
};

var SetBatch = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$2,
      errorResponse_decode: errorResponse_decode$2,
      domain: "Sso",
      mapError: mapError$2,
      method: "Post",
      endpoint: endpoint$2,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse$1,
      options: options$2
    });

function errorResponse_encode$3(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$3(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$3 = User_Api_Config.$$Error.mapError;

var endpoint$3 = {
  NAME: "Static",
  VAL: "lastvieweds/purge"
};

var options$3 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

function makeBody$1(param) {
  
}

function decodeApiResponse$2(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var PurgeConfig = {
  errorResponse_encode: errorResponse_encode$3,
  errorResponse_decode: errorResponse_decode$3,
  mapError: mapError$3,
  domain: "Sso",
  endpoint: endpoint$3,
  method: "Patch",
  options: options$3,
  makeBody: makeBody$1,
  decodeApiResponse: decodeApiResponse$2
};

var Purge = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$3,
      errorResponse_decode: errorResponse_decode$3,
      domain: "Sso",
      mapError: mapError$3,
      method: "Patch",
      endpoint: endpoint$3,
      makeBody: makeBody$1,
      decodeApiResponse: decodeApiResponse$2,
      options: options$3
    });

function errorResponse_encode$4(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$4(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$4 = User_Api_Config.$$Error.mapError;

function endpoint_1$2(param) {
  return "lastvieweds/" + Type_String.Teaser.DsId.toString(param.programId);
}

var endpoint$4 = {
  NAME: "Dynamic",
  VAL: endpoint_1$2
};

var options$4 = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

function makeBody$2(param) {
  
}

function decodeApiResponse$3(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var DeleteConfig = {
  errorResponse_encode: errorResponse_encode$4,
  errorResponse_decode: errorResponse_decode$4,
  mapError: mapError$4,
  domain: "Sso",
  endpoint: endpoint$4,
  method: "Delete",
  options: options$4,
  makeBody: makeBody$2,
  decodeApiResponse: decodeApiResponse$3
};

var Delete = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$4,
      errorResponse_decode: errorResponse_decode$4,
      domain: "Sso",
      mapError: mapError$4,
      method: "Delete",
      endpoint: endpoint$4,
      makeBody: makeBody$2,
      decodeApiResponse: decodeApiResponse$3,
      options: options$4
    });

var get = Get.call;

var getById = GetById.call;

var setBatch = SetBatch.call;

var purge = Purge.call;

var $$delete = Delete.call;

export {
  GetConfig ,
  Get ,
  get ,
  GetByIdConfig ,
  GetById ,
  getById ,
  SetBatchConfig ,
  SetBatch ,
  setBatch ,
  PurgeConfig ,
  Purge ,
  purge ,
  DeleteConfig ,
  Delete ,
  $$delete ,
}
/* Get Not a pure module */
