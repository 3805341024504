// Generated by ReScript, PLEASE EDIT WITH CARE


var match = process.env.NODE_ENV;

var currentEnv = match === "development" ? "Development" : "Production";

function runOnlyInDev(fn) {
  if (currentEnv === "Production") {
    return ;
  } else {
    return fn();
  }
}

var latestCdnUrl = "https://static-cdn.arte.tv/static/design-system";

export {
  latestCdnUrl ,
  currentEnv ,
  runOnlyInDev ,
}
/* match Not a pure module */
