import { type ReleaseInfos } from '@replay/types/App';
import appInfos from '../../app_info.json';

let releaseInfos: ReleaseInfos = {
    release: 'unknown',
    commit_id: 'unknown',
};
const getReleaseInfos = (): ReleaseInfos => {
    releaseInfos = {
        release: appInfos.version || 'unknown',
        commit_id: appInfos.commit_id || 'unknown',
    };
    return releaseInfos;
};

export default getReleaseInfos;
