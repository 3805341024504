// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Atom_Row from "./Atom_Row.res.mjs";
import * as Atom_Icon from "./Atom_Icon.res.mjs";
import * as Atom_Text from "./Atom_Text.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "./Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "./Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Presse_Icon from "../../entry/Presse/Presse_Icon.res.mjs";
import * as Atom_Cta_Css from "./Atom_Cta_Css.res.mjs";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Accessibility_Constant from "../particle/Particle_Accessibility_Constant.res.mjs";

function Atom_Cta$Internal(props) {
  var textAccessibility = props.textAccessibility;
  var style = props.style;
  var screenReadersLabel = props.screenReadersLabel;
  var __labelKind = props.labelKind;
  var __direction = props.direction;
  var children = props.children;
  var direction = __direction !== undefined ? Caml_option.valFromOption(__direction) : Particle_Screen_Constants.flexDirectionRow;
  var labelKind = __labelKind !== undefined ? __labelKind : "span";
  var match = Atom_Cta_Ds.view(style);
  var textStyle = match.textStyle;
  var styleRules = Atom_Cta_Css.toCss(style);
  var match$1 = Atom_Text_Ds.view(textStyle);
  var textColorRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Array.catOption([Core__Option.map(match$1.color, (function (color) {
                    return CssJs.color(Particle_Color.Css.color(color));
                  }))]));
  var rules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concatOrDefault(styleRules, props.rules), textColorRules);
  var match$2 = screenReadersLabel !== undefined ? (
      textAccessibility !== undefined ? [
          textAccessibility,
          JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                children: screenReadersLabel
              })
        ] : [
          Particle_Accessibility_Constant.ariaHidden,
          JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                children: screenReadersLabel
              })
        ]
    ) : (
      textAccessibility !== undefined ? [
          textAccessibility,
          null
        ] : [
          undefined,
          null
        ]
    );
  var child;
  if (children !== undefined) {
    child = Caml_option.valFromOption(children);
  } else {
    var justifyContent = Core__Option.map(Core__Option.flatMap(match.block, (function (param) {
                return param.justifyContent;
              })), (function (justifyContent) {
            return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, justifyContent);
          }));
    child = JsxRuntime.jsx(Atom_Text.make, {
          accessibility: match$2[0],
          children: Caml_option.some(Core__Option.mapOr(props.label, null, (function (prim) {
                      return prim;
                    }))),
          id: props.labelId,
          justifyContent: justifyContent,
          kind: labelKind,
          style: textStyle,
          width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
        });
  }
  return JsxRuntime.jsxs(Atom_Box.make, {
              accessibility: props.accessibility,
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              background: Caml_option.some(match.background),
              border: match.border,
              children: [
                match$2[1],
                child
              ],
              dataAnchor: props.dataAnchor,
              direction: Caml_option.some(direction),
              display: props.display,
              displayOrder: props.displayOrder,
              id: props.id,
              identifier: props.identifier,
              kind: props.kind,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              position: props.position,
              rules: Caml_option.some(rules),
              spacer: Caml_option.some(match.spacer),
              innerSpace: props.innerSpace,
              ref_: props.ctaRef
            });
}

Atom_Cta$Internal.displayName = "Cta_Internal";

function getBoxKind(disabled, type_, onClick, title) {
  var handleClick = function (ev) {
    if (onClick !== undefined) {
      return onClick(ev);
    }
    
  };
  if (disabled !== undefined && disabled) {
    return {
            TAG: "ButtonDisabled",
            _0: Core__Option.getOr(type_, "button")
          };
  }
  return {
          TAG: "Button",
          _0: Core__Option.getOr(type_, "button"),
          _1: handleClick,
          _2: title
        };
}

function Atom_Cta$Button$Block(props) {
  var boxKind = getBoxKind(props.disabled, props.type_, props.onClick, props.title);
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: props.accessibility,
              children: Caml_option.some(props.children),
              ctaRef: props.ctaRef,
              dataAnchor: props.dataAnchor,
              direction: props.direction,
              display: props.display,
              displayOrder: props.displayOrder,
              id: props.id,
              identifier: props.identifier,
              innerSpace: props.innerSpace,
              kind: boxKind,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              screenReadersLabel: props.screenReadersLabel,
              style: props.style
            });
}

var Block = {
  make: Atom_Cta$Button$Block
};

function Atom_Cta$Button(props) {
  var boxKind = getBoxKind(props.disabled, props.type_, props.onClick, props.title);
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: props.accessibility,
              ctaRef: props.ctaRef,
              dataAnchor: props.dataAnchor,
              display: props.display,
              displayOrder: props.displayOrder,
              id: props.id,
              identifier: props.identifier,
              kind: boxKind,
              label: props.label,
              labelKind: props.labelKind,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              position: props.position,
              rules: props.rules,
              screenReadersLabel: props.screenReadersLabel,
              style: props.style,
              textAccessibility: props.textAccessibility
            });
}

Atom_Cta$Button.displayName = "Button";

function Atom_Cta$Link$Block(props) {
  var __target = props.target;
  var target = __target !== undefined ? __target : "_self";
  var boxLink = {
    TAG: "Link",
    _0: {
      href: props.href,
      target: target,
      onClick: props.onClick
    }
  };
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: props.accessibility,
              children: Caml_option.some(props.children),
              ctaRef: props.ctaRef,
              dataAnchor: props.dataAnchor,
              direction: props.direction,
              identifier: props.identifier,
              innerSpace: props.innerSpace,
              kind: boxLink,
              position: props.position,
              screenReadersLabel: props.screenReadersLabel,
              style: props.style
            });
}

var Block$1 = {
  make: Atom_Cta$Link$Block
};

function Atom_Cta$Link$Responsive(props) {
  var __target = props.target;
  var style = props.style;
  var screenReadersLabel = props.screenReadersLabel;
  var target = __target !== undefined ? __target : "_self";
  var boxLink = {
    TAG: "Link",
    _0: {
      href: props.href,
      target: target,
      title: props.title,
      onClick: props.onClick
    }
  };
  var match = Atom_Cta_Ds.view(style);
  var rules = Atom_Cta_Css.toCss(style);
  var match$1 = screenReadersLabel !== undefined ? [
      Particle_Accessibility_Constant.ariaHidden,
      JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
            children: screenReadersLabel
          })
    ] : [
      undefined,
      null
    ];
  var children = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          match$1[1],
          JsxRuntime.jsx(Atom_Text.Responsive.make, {
                accessibility: match$1[0],
                style: match.textStyle,
                id: props.labelId,
                labels: props.labels
              })
        ]
      });
  return JsxRuntime.jsx(Atom_Box.make, {
              accessibility: props.accessibility,
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              background: Caml_option.some(match.background),
              border: match.border,
              children: Caml_option.some(children),
              dataAnchor: props.dataAnchor,
              direction: Caml_option.some(Particle_Screen_Constants.flexDirectionRow),
              displayOrder: props.displayOrder,
              id: props.id,
              identifier: props.identifier,
              kind: boxLink,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              position: props.position,
              rules: Caml_option.some(rules),
              spacer: Caml_option.some(match.spacer),
              ref_: props.ctaRef
            });
}

var Responsive = {
  make: Atom_Cta$Link$Responsive
};

function Atom_Cta$Link(props) {
  var __target = props.target;
  var target = __target !== undefined ? __target : "_self";
  var boxLink = {
    TAG: "Link",
    _0: {
      href: props.href,
      target: target,
      title: props.title,
      onClick: props.onClick,
      rel: props.rel
    }
  };
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: props.accessibility,
              ctaRef: props.ctaRef,
              dataAnchor: props.dataAnchor,
              displayOrder: props.displayOrder,
              id: props.id,
              identifier: props.identifier,
              kind: boxLink,
              label: props.label,
              labelId: props.labelId,
              labelKind: props.labelKind,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              position: props.position,
              screenReadersLabel: props.screenReadersLabel,
              style: props.style
            });
}

var Link = {
  Block: Block$1,
  Responsive: Responsive,
  make: Atom_Cta$Link
};

function Atom_Cta$DownloadLink(props) {
  var __download = props.download;
  var download = __download !== undefined ? __download : "Default";
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: props.accessibility,
              kind: {
                TAG: "DownloadLink",
                _0: {
                  href: props.href,
                  download: download
                }
              },
              label: props.label,
              style: props.style
            });
}

function Atom_Cta$Seo(props) {
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: Particle_Accessibility.notFocusable,
              kind: {
                TAG: "Link",
                _0: {
                  href: props.href,
                  target: "_self"
                }
              },
              label: props.label,
              position: Caml_option.some(Particle_Screen_Constants.positionAbsolute),
              style: Atom_Cta_Ds.seo
            });
}

var Seo = {
  make: Atom_Cta$Seo
};

Atom_Cta$Link$Block.displayName = "Link.Block";

Atom_Cta$Link.displayName = "Link";

function make(param) {
  var variant = param.variant;
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, param.ariaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var tmp;
  if (variant !== undefined) {
    switch (variant) {
      case "Base" :
          tmp = Atom_Cta_Ds.Player.ctaBase;
          break;
      case "Watch" :
          tmp = Atom_Cta_Ds.Player.ctaWatch;
          break;
      case "Cancel" :
          tmp = Atom_Cta_Ds.Player.ctaCancel;
          break;
      
    }
  } else {
    tmp = Atom_Cta_Ds.Player.ctaBase;
  }
  return JsxRuntime.jsx(Atom_Cta$Button, {
              accessibility: accessibility,
              label: param.label,
              onClick: param.onClick,
              style: tmp
            });
}

var Player = {
  make: make
};

function Atom_Cta$User$Button(props) {
  var __variant = props.variant;
  var disabled = props.disabled;
  var variant = __variant !== undefined ? __variant : "base";
  var style = variant === "fullWidth" ? Atom_Cta_Ds.User.fullWidth : (
      variant === "link" ? Atom_Cta_Ds.User.link : (
          variant === "outline" ? Atom_Cta_Ds.User.makeOutline(Core__Option.getOr(disabled, false), "Internal") : (
              variant === "accordion" ? Atom_Cta_Ds.User.accordion : (
                  variant === "dropdown" ? Atom_Cta_Ds.User.dropdown : (
                      variant === "tvLogin" ? Atom_Cta_Ds.User.tvLogin : (
                          disabled !== undefined && disabled ? Atom_Cta_Ds.User.disabled : Atom_Cta_Ds.User.base
                        )
                    )
                )
            )
        )
    );
  return JsxRuntime.jsx(Atom_Cta$Button, {
              disabled: disabled,
              label: props.label,
              onClick: props.onClick,
              style: style,
              type_: props.type_
            });
}

var Button = {
  make: Atom_Cta$User$Button
};

function Atom_Cta$User$Link(props) {
  var __variant = props.variant;
  var __target = props.target;
  var target = __target !== undefined ? __target : "_self";
  var variant = __variant !== undefined ? __variant : "link";
  var style = variant === "button" ? Atom_Cta_Ds.User.base : (
      variant === "outline" ? Atom_Cta_Ds.User.makeOutline(false, target === "_blank" ? "External" : "Internal") : (
          variant === "eventLink" ? Atom_Cta_Ds.User.eventLink : (
              variant === "footer" ? Atom_Cta_Ds.User.footerLink : (
                  variant === "buttonPrimary" ? Atom_Cta_Ds.User.primary : Atom_Cta_Ds.User.link
                )
            )
        )
    );
  return JsxRuntime.jsx(Atom_Cta$Link, {
              href: props.href,
              label: props.label,
              onClick: props.onClick,
              style: style,
              target: target
            });
}

var Link$1 = {
  make: Atom_Cta$User$Link
};

var User = {
  Button: Button,
  Link: Link$1
};

function Atom_Cta$Corporate(props) {
  var __variant = props.variant;
  var variant = __variant !== undefined ? __variant : "active";
  var dsStyle = Atom_Cta_Ds.Corporate.makeToggleButton(variant);
  return JsxRuntime.jsx(Atom_Cta$Button, {
              label: props.label,
              onClick: props.onClick,
              style: dsStyle
            });
}

var Corporate = {
  make: Atom_Cta$Corporate
};

function Atom_Cta$AndroidReleases(props) {
  var variant = props.variant;
  var href = props.href;
  var label = props.label;
  if (typeof variant !== "object") {
    return JsxRuntime.jsx(Atom_Cta$Link, {
                href: href,
                label: label,
                style: Atom_Cta_Ds.AndroidReleases.tutorial
              });
  }
  var download = variant.VAL;
  var dsStyle = Atom_Cta_Ds.AndroidReleases.download;
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  if (download !== undefined) {
    return JsxRuntime.jsx(Atom_Cta$DownloadLink, {
                href: href,
                label: label,
                download: download,
                style: dsStyle,
                accessibility: accessibility
              });
  } else {
    return JsxRuntime.jsx(Atom_Cta$DownloadLink, {
                href: href,
                label: label,
                style: dsStyle,
                accessibility: accessibility
              });
  }
}

var AndroidReleases = {
  make: Atom_Cta$AndroidReleases
};

function Atom_Cta$Presse_Button$Internal(props) {
  return JsxRuntime.jsx(Atom_Cta$Button, {
              disabled: props.disabled,
              label: props.label,
              onClick: props.onClick,
              style: props.style,
              type_: props.type_
            });
}

function make$1(param) {
  var icon = param.icon;
  var disabled = Core__Option.getOr(param.disabled, false);
  var variant = Core__Option.getOr(param.variant, "Primary");
  var icon$1 = icon !== undefined ? Caml_option.some(Presse_Icon.toIcon(icon)) : undefined;
  var dsStyle;
  if (typeof variant !== "object") {
    switch (variant) {
      case "Primary" :
          dsStyle = Atom_Cta_Ds.Presse.makePrimary(disabled, icon$1);
          break;
      case "Secondary" :
          dsStyle = Atom_Cta_Ds.Presse.makeSecondary(disabled, icon$1);
          break;
      case "Outline" :
          dsStyle = Atom_Cta_Ds.Presse.makeOutline(disabled, icon$1);
          break;
      case "Clear" :
          dsStyle = Atom_Cta_Ds.Presse.makeClear(disabled);
          break;
      case "Search" :
          dsStyle = Atom_Cta_Ds.Presse.makeSearchFilter(disabled);
          break;
      
    }
  } else {
    dsStyle = variant.TAG === "Pagination" ? (
        variant._0 === "Active" ? Atom_Cta_Ds.Presse.paginationActive : Atom_Cta_Ds.Presse.paginationInactive
      ) : Atom_Cta_Ds.Presse.makePaginationArrow(variant._0);
  }
  return JsxRuntime.jsx(Atom_Cta$Presse_Button$Internal, {
              disabled: disabled,
              label: param.label,
              onClick: param.onClick,
              style: dsStyle,
              type_: param.type_
            });
}

function make$2(param) {
  var icon = param.icon;
  var target = Core__Option.getOr(param.target, "_self");
  var variant = Core__Option.getOr(param.variant, "Primary");
  var disabled = Core__Option.getOr(param.disabled, false);
  var icon$1 = icon !== undefined ? Caml_option.some(Presse_Icon.toIcon(icon)) : undefined;
  var style;
  switch (variant) {
    case "Secondary" :
        style = Atom_Cta_Ds.Presse.makeSecondary(disabled, icon$1);
        break;
    case "Outline" :
        style = Atom_Cta_Ds.Presse.makeOutline(disabled, icon$1);
        break;
    case "Primary" :
    case "Default" :
        style = Atom_Cta_Ds.User.eventLink;
        break;
    
  }
  return JsxRuntime.jsx(Atom_Cta$Link, {
              href: param.href,
              label: param.label,
              onClick: param.onClick,
              style: style,
              target: target
            });
}

var Presse_Link = {
  make: make$2
};

var ctaRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.maxWidth({
            NAME: "px",
            VAL: 451
          })], undefined, undefined, [CssJs.maxWidth({
            NAME: "px",
            VAL: 280
          })]);

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var size = Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S");

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS");

var icon = Atom_Icon_Ds.updateColor(Atom_Icon_Ds.download, "Text");

function Atom_Cta$Presse_DownloadLink(props) {
  var ariaLabel = props.ariaLabel;
  var style = Atom_Cta_Ds.Presse.downloadLink;
  var accessibility = ariaLabel !== undefined ? Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, ariaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined) : undefined;
  return JsxRuntime.jsx(Atom_Cta$Internal, {
              accessibility: accessibility,
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Row.make, {
                        alignItems: Caml_option.some(alignItems),
                        children: [
                          JsxRuntime.jsx(Atom_Icon.make, {
                                icon: icon,
                                size: size
                              }),
                          JsxRuntime.jsxs(Atom_Column.make, {
                                children: [
                                  JsxRuntime.jsx(Atom_Text.make, {
                                        children: Caml_option.some(props.label),
                                        style: Atom_Text_Ds.Presse.downloadLabel
                                      }),
                                  JsxRuntime.jsx(Atom_Text.make, {
                                        children: Caml_option.some(props.fileInfo),
                                        style: Atom_Text_Ds.Presse.downloadFileName
                                      })
                                ],
                                innerSpace: Caml_option.some(innerSpace)
                              })
                        ],
                        gap: Caml_option.some(gap),
                        wrap: "OnOneLine"
                      })),
              kind: {
                TAG: "Link",
                _0: {
                  href: props.href,
                  target: "_blank"
                }
              },
              rules: Caml_option.some(ctaRules),
              style: style
            });
}

function Atom_Cta$Action$Toggle(props) {
  var __variant = props.variant;
  var action = props.action;
  var onClick = props.onClick;
  var variant = __variant !== undefined ? __variant : "EventZone";
  var match = Context.I18n.use();
  var match$1 = Context.I18n.use();
  var locale = match$1.locale;
  var user = User_Observable.UserInfo.All.Hook.use();
  var onToggle = async function (status) {
    if (onClick !== undefined) {
      onClick();
    }
    var result = await action.sendAction(status, locale);
    if (result.TAG === "Ok") {
      return ;
    } else {
      return status;
    }
  };
  var match$2 = UseToggle.make(action.initialStatus, undefined, undefined, (function () {
          return onToggle("Off");
        }), (function () {
          return onToggle("On");
        }), undefined);
  var setInitialStatus = match$2.setInitialStatus;
  var state = match$2.state;
  React.useEffect((function () {
          setInitialStatus(action.getStatus(user));
        }), [user]);
  var label;
  label = state === "On" ? match.labels.mona.advantage.subscribedLabel : props.label;
  var tmp;
  tmp = variant === "Auth" ? Atom_Cta_Ds.User.makeActionButton(state) : Atom_Cta_Ds.EventZone.makeActionButton(state);
  return JsxRuntime.jsx(Atom_Cta$Button, {
              accessibility: props.accessibility,
              identifier: props.identifier,
              label: label,
              onClick: match$2.toggle,
              style: tmp
            });
}

var Toggle = {
  make: Atom_Cta$Action$Toggle
};

var Action = {
  Toggle: Toggle
};

var Button$1 = {
  Block: Block,
  make: Atom_Cta$Button
};

var Presse_Button = {
  make: make$1
};

var Presse_DownloadLink = {
  make: Atom_Cta$Presse_DownloadLink
};

export {
  Button$1 as Button,
  Link ,
  Seo ,
  Player ,
  User ,
  Corporate ,
  AndroidReleases ,
  Presse_Button ,
  Presse_DownloadLink ,
  Presse_Link ,
  Action ,
}
/*  Not a pure module */
