// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Util_Opt from "../../util/Util_Opt.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function screenSizeToString(screenSize) {
  var variant = screenSize.NAME;
  if (variant === "M") {
    return "M";
  } else if (variant === "S") {
    return "S";
  } else if (variant === "XL") {
    return "XL";
  } else if (variant === "XS") {
    return "XS";
  } else {
    return "L";
  }
}

function map(t, fn) {
  return {
          xs: Core__Option.map(t.xs, fn),
          s: Core__Option.map(t.s, fn),
          m: Core__Option.map(t.m, fn),
          l: Core__Option.map(t.l, fn),
          xl: Core__Option.map(t.xl, fn),
          default: fn(t.default)
        };
}

function extractValue(x) {
  return x.VAL;
}

function extractDefaultValue(t) {
  return t.default;
}

function getScreenSizes(t) {
  return Util_Array.catOption([
              Core__Option.map(t.xs, (function (v) {
                      return {
                              NAME: "XS",
                              VAL: v
                            };
                    })),
              Core__Option.map(t.s, (function (v) {
                      return {
                              NAME: "S",
                              VAL: v
                            };
                    })),
              Core__Option.map(t.m, (function (v) {
                      return {
                              NAME: "M",
                              VAL: v
                            };
                    })),
              Core__Option.map(t.l, (function (v) {
                      return {
                              NAME: "L",
                              VAL: v
                            };
                    })),
              Core__Option.map(t.xl, (function (v) {
                      return {
                              NAME: "XL",
                              VAL: v
                            };
                    }))
            ]);
}

function view(t) {
  var $$default = t.default;
  var screenSizes = getScreenSizes(t);
  if (Caml_obj.equal(screenSizes, [])) {
    return {
            TAG: "AllSize",
            _0: $$default
          };
  } else {
    return {
            TAG: "Custom",
            _0: $$default,
            _1: screenSizes
          };
  }
}

function getValueByScreenSize(t, _screenSize) {
  while(true) {
    var screenSize = _screenSize;
    var a = view(t);
    if (a.TAG === "AllSize") {
      return Caml_option.some(a._0);
    }
    var variant = screenSize.NAME;
    if (variant === "M") {
      if (Core__Option.isSome(t.m)) {
        return t.m;
      }
      _screenSize = {
        NAME: "S",
        VAL: screenSize.VAL
      };
      continue ;
    }
    if (variant === "S") {
      if (Core__Option.isSome(t.s)) {
        return t.s;
      }
      _screenSize = {
        NAME: "XS",
        VAL: screenSize.VAL
      };
      continue ;
    }
    if (variant === "XL") {
      if (Core__Option.isSome(t.l)) {
        return t.xl;
      }
      _screenSize = {
        NAME: "L",
        VAL: screenSize.VAL
      };
      continue ;
    }
    if (variant === "XS") {
      if (Core__Option.isSome(t.xs)) {
        return t.s;
      } else {
        return Caml_option.some(a._0);
      }
    }
    if (Core__Option.isSome(t.l)) {
      return t.l;
    }
    _screenSize = {
      NAME: "M",
      VAL: screenSize.VAL
    };
    continue ;
  };
}

function zipWith(iteratee, t1, t2) {
  var sh = function (extra, extra$1, extra$2, extra$3) {
    if (extra$2 !== undefined) {
      var x = Caml_option.valFromOption(extra$2);
      if (extra$3 !== undefined) {
        var y = Caml_option.valFromOption(extra$3);
        var z = iteratee(x, y);
        return {
                minT1Value: x,
                minT2Value: y,
                value: Caml_option.some(z)
              };
      }
      var z$1 = iteratee(x, extra$1);
      return {
              minT1Value: x,
              minT2Value: extra$1,
              value: Caml_option.some(z$1)
            };
    }
    if (extra$3 === undefined) {
      return {
              minT1Value: extra,
              minT2Value: extra$1,
              value: undefined
            };
    }
    var y$1 = Caml_option.valFromOption(extra$3);
    var z$2 = iteratee(extra, y$1);
    return {
            minT1Value: extra,
            minT2Value: y$1,
            value: Caml_option.some(z$2)
          };
  };
  var match = sh(t1.default, t2.default, t1.xs, t2.xs);
  var match$1 = sh(match.minT1Value, match.minT2Value, t1.s, t2.s);
  var match$2 = sh(match$1.minT1Value, match$1.minT2Value, t1.m, t2.m);
  var match$3 = sh(match$2.minT1Value, match$2.minT2Value, t1.l, t2.l);
  var match$4 = sh(match$3.minT1Value, match$3.minT2Value, t1.xl, t2.xl);
  return {
          xs: match.value,
          s: match$1.value,
          m: match$2.value,
          l: match$3.value,
          xl: match$4.value,
          default: iteratee(t1.default, t2.default)
        };
}

function getEnsuredValue(v, minValue) {
  if (v === undefined) {
    return [
            undefined,
            minValue
          ];
  }
  var x = Caml_option.valFromOption(v);
  if (Caml_obj.notequal(x, minValue)) {
    return [
            Caml_option.some(x),
            x
          ];
  } else {
    return [
            undefined,
            minValue
          ];
  }
}

function make(xs, s, m, l, xl, $$default) {
  var match = getEnsuredValue(xs, $$default);
  var match$1 = getEnsuredValue(s, match[1]);
  var match$2 = getEnsuredValue(m, match$1[1]);
  var match$3 = getEnsuredValue(l, match$2[1]);
  var match$4 = getEnsuredValue(xl, match$3[1]);
  return {
          xs: match[0],
          s: match$1[0],
          m: match$2[0],
          l: match$3[0],
          xl: match$4[0],
          default: $$default
        };
}

function makeForScreenSize($$default, screenSize) {
  var variant = screenSize.NAME;
  if (variant === "M") {
    return make(undefined, undefined, Caml_option.some(screenSize.VAL), undefined, undefined, $$default);
  } else if (variant === "S") {
    return make(undefined, Caml_option.some(screenSize.VAL), undefined, undefined, undefined, $$default);
  } else if (variant === "XL") {
    return make(undefined, undefined, undefined, undefined, Caml_option.some(screenSize.VAL), $$default);
  } else if (variant === "XS") {
    return make(Caml_option.some(screenSize.VAL), undefined, undefined, undefined, undefined, $$default);
  } else {
    return make(undefined, undefined, undefined, Caml_option.some(screenSize.VAL), undefined, $$default);
  }
}

function concatBy(iteratee, t1, t2) {
  return zipWith(iteratee, t1, t2);
}

function concat(arr1, arr2) {
  var concatValues = function (value1, value2) {
    if (value1 !== undefined) {
      if (value2 !== undefined) {
        return value1.concat(value2);
      } else {
        return value1;
      }
    } else if (value2 !== undefined) {
      return value2;
    } else {
      return ;
    }
  };
  return {
          xs: concatValues(arr1.xs, arr2.xs),
          s: concatValues(arr1.s, arr2.s),
          m: concatValues(arr1.m, arr2.m),
          l: concatValues(arr1.l, arr2.l),
          xl: concatValues(arr1.xl, arr2.xl),
          default: arr1.default.concat(arr2.default)
        };
}

function concatMany(arr) {
  var _arr = arr;
  var _acc = make(undefined, undefined, undefined, undefined, undefined, []);
  while(true) {
    var acc = _acc;
    var arr$1 = _arr;
    var el1 = Util_Array.getFirstElement(arr$1);
    var tail = Util_Array.tail(arr$1);
    if (el1 === undefined) {
      return acc;
    }
    if (tail === undefined) {
      return concat(acc, el1);
    }
    if (tail.length === 0) {
      return concat(acc, el1);
    }
    _acc = concat(acc, el1);
    _arr = tail;
    continue ;
  };
}

function concatOrDefault($$default, l) {
  return Core__Option.getOr(Core__Option.map(l, (function (l) {
                    return concat(l, $$default);
                  })), $$default);
}

var $$Array = {
  concat: concat,
  concatMany: concatMany,
  concatOrDefault: concatOrDefault
};

function catOption($$default, t) {
  return {
          xs: Util_Opt.flatten(t.xs),
          s: Util_Opt.flatten(t.s),
          m: Util_Opt.flatten(t.m),
          l: Util_Opt.flatten(t.l),
          xl: Util_Opt.flatten(t.xl),
          default: Core__Option.getOr(t.default, $$default)
        };
}

function all(t, predicate) {
  var value = view(t);
  if (value.TAG === "AllSize") {
    return predicate(value._0);
  } else if (predicate(value._0)) {
    return value._1.every(function (screen) {
                return predicate(screen.VAL);
              });
  } else {
    return false;
  }
}

function reduce(t, initialValue, fn) {
  var value = view(t);
  if (value.TAG === "AllSize") {
    return fn(initialValue, value._0);
  }
  var initialValue$1 = fn(initialValue, value._0);
  return Core__Array.reduce(value._1, initialValue$1, (function (acc, screen) {
                return fn(acc, screen.VAL);
              }));
}

export {
  screenSizeToString ,
  extractValue ,
  extractDefaultValue ,
  getValueByScreenSize ,
  map ,
  zipWith ,
  concatBy ,
  catOption ,
  all ,
  reduce ,
  $$Array ,
  make ,
  view ,
  makeForScreenSize ,
}
/* No side effect */
