// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Feedback_Form from "../organism/Organism_Feedback_Form.res.mjs";

function Page_Feedback(props) {
  return JsxRuntime.jsx(Organism_Feedback_Form.make, {
              onSubmit: props.onSubmit,
              onClose: props.onClose
            });
}

function Page_Feedback$Success(props) {
  return JsxRuntime.jsx(Organism_Feedback_Form.Success.make, {
              onClose: props.onClose
            });
}

var Success = {
  make: Page_Feedback$Success
};

var make = Page_Feedback;

export {
  make ,
  Success ,
}
/* react/jsx-runtime Not a pure module */
