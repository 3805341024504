// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Particle_Screen_Ds from "./Particle_Screen_Ds.res.mjs";

var default_weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Regular");

var $$default = {
  color: "Text",
  width: 100,
  weight: default_weight,
  position: "Border"
};

var newrecord = Caml_obj.obj_dup($$default);

newrecord.color = "White";

var AndroidRealease = {
  ctaDownload: newrecord
};

var newrecord$1 = Caml_obj.obj_dup($$default);

newrecord$1.color = "Black";

var newrecord$2 = Caml_obj.obj_dup($$default);

newrecord$2.color = "White";

var newrecord$3 = Caml_obj.obj_dup($$default);

newrecord$3.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$3.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$3.color = "Background";

var newrecord$4 = Caml_obj.obj_dup($$default);

newrecord$4.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$4.color = "Background";

var newrecord$5 = Caml_obj.obj_dup($$default);

newrecord$5.position = "Outside";

newrecord$5.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$5.color = "Outline";

var newrecord$6 = Caml_obj.obj_dup($$default);

newrecord$6.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$6.color = "LightGrey";

var newrecord$7 = Caml_obj.obj_dup($$default);

newrecord$7.position = "BorderLeft";

newrecord$7.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$7.color = "LightGrey";

var Corporate = {
  active: newrecord$1,
  inactive: newrecord$2,
  burgerMenuLine: newrecord$3,
  burgerMenu: newrecord$4,
  accessibility: newrecord$5,
  menuItemChild: newrecord$6,
  menuDivider: newrecord$7
};

var newrecord$8 = Caml_obj.obj_dup($$default);

newrecord$8.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$8.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$8.color = "White";

var newrecord$9 = Caml_obj.obj_dup($$default);

newrecord$9.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$9.color = "White";

var newrecord$10 = Caml_obj.obj_dup($$default);

newrecord$10.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$10.color = "White";

var newrecord$11 = Caml_obj.obj_dup($$default);

newrecord$11.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$11.color = "Grey";

var newrecord$12 = Caml_obj.obj_dup($$default);

newrecord$12.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

var Presse = {
  burgerMenuLine: newrecord$8,
  burgerMenu: newrecord$9,
  menuItemChild: newrecord$10,
  textField: $$default,
  guide: newrecord$11,
  downloadLink: newrecord$12
};

var newrecord$13 = Caml_obj.obj_dup($$default);

newrecord$13.color = "LightGrey";

var newrecord$14 = Caml_obj.obj_dup($$default);

newrecord$14.color = "LightGrey";

var newrecord$15 = Caml_obj.obj_dup($$default);

newrecord$15.color = "White";

var newrecord$16 = Caml_obj.obj_dup($$default);

newrecord$16.color = "LightGrey";

var newrecord$17 = Caml_obj.obj_dup($$default);

newrecord$17.position = "Inset";

newrecord$17.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$17.color = "White";

var newrecord$18 = Caml_obj.obj_dup($$default);

newrecord$18.position = "Inset";

newrecord$18.color = "LightGrey";

var Textfield = {
  active: $$default,
  inactive: newrecord$13,
  tvCodeCell: newrecord$14,
  newsletterZone: newrecord$15,
  searchPage: newrecord$16,
  userActive: newrecord$17,
  userInactive: newrecord$18
};

var newrecord$19 = Caml_obj.obj_dup($$default);

newrecord$19.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

var newrecord$20 = Caml_obj.obj_dup($$default);

newrecord$20.color = "White";

var newrecord$21 = Caml_obj.obj_dup($$default);

newrecord$21.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$21.color = "Transparent";

var newrecord$22 = Caml_obj.obj_dup($$default);

newrecord$22.color = "White";

var Cta = {
  activeMedium: newrecord$19,
  activeRegular: $$default,
  socialNetwork: newrecord$20,
  inactiveRegular: $$default,
  inactiveMedium: newrecord$21,
  teaser: newrecord$22
};

var newrecord$23 = Caml_obj.obj_dup($$default);

newrecord$23.color = "TeaserBorder";

var newrecord$24 = Caml_obj.obj_dup($$default);

newrecord$24.color = "TeaserInfoBorder";

var newrecord$25 = Caml_obj.obj_dup($$default);

newrecord$25.position = "Outside";

newrecord$25.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$25.color = "Outline";

var newrecord$26 = Caml_obj.obj_dup($$default);

newrecord$26.position = "Around";

newrecord$26.weight = Particle_Screen_Ds.make(undefined, "SemiBold", undefined, undefined, undefined, "Medium");

newrecord$26.color = "Concert";

var newrecord$27 = Caml_obj.obj_dup($$default);

newrecord$27.position = "Around";

newrecord$27.weight = Particle_Screen_Ds.make(undefined, "SemiBold", undefined, undefined, undefined, "Medium");

newrecord$27.color = "Arte";

var newrecord$28 = Caml_obj.obj_dup($$default);

newrecord$28.position = "Around";

newrecord$28.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold");

newrecord$28.color = "Concert";

var newrecord$29 = Caml_obj.obj_dup($$default);

newrecord$29.position = "Around";

newrecord$29.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold");

newrecord$29.color = "Primary";

var newrecord$30 = Caml_obj.obj_dup($$default);

newrecord$30.position = "Around";

newrecord$30.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "None");

newrecord$30.color = "Transparent";

var Teaser = {
  classic: newrecord$23,
  info: newrecord$24,
  infoAccessibility: newrecord$25,
  directConcert: newrecord$26,
  directDefault: newrecord$27,
  genreActiveConcert: newrecord$28,
  genreActiveDefault: newrecord$29,
  genreInactive: newrecord$30
};

var newrecord$31 = Caml_obj.obj_dup($$default);

newrecord$31.color = "DarkGrey";

var newrecord$32 = Caml_obj.obj_dup(newrecord$31);

newrecord$32.position = "BorderLeft";

newrecord$32.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$32.color = "White";

var newrecord$33 = Caml_obj.obj_dup(newrecord$31);

newrecord$33.position = "BorderLeft";

newrecord$33.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$33.color = "LightGrey";

var newrecord$34 = Caml_obj.obj_dup(newrecord$31);

newrecord$34.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$34.color = "Grey";

var newrecord$35 = Caml_obj.obj_dup(newrecord$31);

newrecord$35.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$35.color = "DividerStatic";

var newrecord$36 = Caml_obj.obj_dup(newrecord$31);

newrecord$36.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$36.color = "DividerStatic";

var newrecord$37 = Caml_obj.obj_dup(newrecord$31);

newrecord$37.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$37.color = "White";

var newrecord$38 = Caml_obj.obj_dup(newrecord$31);

newrecord$38.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$38.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Regular");

newrecord$38.color = "Grey";

var Divider = {
  $$default: newrecord$31,
  contentCollectionMeta: newrecord$32,
  userNavigation: newrecord$33,
  menuNavigation: newrecord$34,
  footer: newrecord$35,
  user: newrecord$36,
  player: newrecord$37,
  cookieModal: newrecord$38
};

var newrecord$39 = Caml_obj.obj_dup($$default);

newrecord$39.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$39.color = "Divider";

var newrecord$40 = Caml_obj.obj_dup($$default);

newrecord$40.position = {
  TAG: "BorderBottom",
  _0: undefined
};

var newrecord$41 = Caml_obj.obj_dup($$default);

newrecord$41.color = "Primary";

var newrecord$42 = Caml_obj.obj_dup($$default);

newrecord$42.position = "Outside";

newrecord$42.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$42.color = "Outline";

var Settings = {
  menu: newrecord$39,
  menuLastItem: newrecord$40,
  dropdown: newrecord$41,
  dropdownItemAccessibility: newrecord$42
};

var newrecord$43 = Caml_obj.obj_dup($$default);

newrecord$43.position = "BorderLeft";

newrecord$43.color = "Grey";

var newrecord$44 = Caml_obj.obj_dup($$default);

newrecord$44.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$44.color = "LightGrey";

var newrecord$45 = Caml_obj.obj_dup($$default);

newrecord$45.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$45.color = "Transparent";

var Header = {
  socialLink: newrecord$43,
  userPanel: newrecord$44,
  $$default: newrecord$45
};

var newrecord$46 = Caml_obj.obj_dup($$default);

newrecord$46.radius = {
  TAG: "All",
  _0: {
    NAME: "percent",
    VAL: 50
  }
};

newrecord$46.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$46.color = "White";

var newrecord$47 = Caml_obj.obj_dup($$default);

newrecord$47.radius = {
  TAG: "All",
  _0: {
    NAME: "percent",
    VAL: 50
  }
};

newrecord$47.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold");

newrecord$47.color = "White";

var newrecord$48 = Caml_obj.obj_dup($$default);

newrecord$48.radius = {
  TAG: "All",
  _0: {
    NAME: "percent",
    VAL: 50
  }
};

newrecord$48.position = "Outside";

newrecord$48.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold");

newrecord$48.color = "Outline";

var Avatar = {
  medium: newrecord$46,
  semiBold: newrecord$47,
  focused: newrecord$48
};

var newrecord$49 = Caml_obj.obj_dup($$default);

newrecord$49.radius = {
  TAG: "Split",
  _0: {
    NAME: "percent",
    VAL: 5
  },
  _1: {
    NAME: "percent",
    VAL: 5
  },
  _2: {
    NAME: "percent",
    VAL: 0
  },
  _3: {
    NAME: "percent",
    VAL: 0
  }
};

newrecord$49.color = "DarkGrey";

var newrecord$50 = Caml_obj.obj_dup($$default);

newrecord$50.radius = {
  TAG: "All",
  _0: {
    NAME: "px",
    VAL: 16
  }
};

newrecord$50.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$50.color = "DividerStatic";

var newrecord$51 = Caml_obj.obj_dup($$default);

newrecord$51.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$51.color = "Grey";

var newrecord$52 = Caml_obj.obj_dup($$default);

newrecord$52.color = "White";

var newrecord$53 = Caml_obj.obj_dup($$default);

newrecord$53.color = "LightGrey";

var newrecord$54 = Caml_obj.obj_dup($$default);

newrecord$54.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "None");

newrecord$54.width = 0;

newrecord$54.color = "Transparent";

var newrecord$55 = Caml_obj.obj_dup($$default);

newrecord$55.color = "LightGrey";

var newrecord$56 = Caml_obj.obj_dup($$default);

newrecord$56.position = {
  TAG: "BorderBottom",
  _0: undefined
};

var newrecord$57 = Caml_obj.obj_dup($$default);

newrecord$57.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$57.color = "Transparent";

function makeProgression(pct) {
  return {
          color: "Arte",
          width: pct,
          weight: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold"),
          position: {
            TAG: "BorderBottom",
            _0: "DividerStatic"
          }
        };
}

var newrecord$58 = Caml_obj.obj_dup($$default);

newrecord$58.color = "Error";

var newrecord$59 = Caml_obj.obj_dup($$default);

newrecord$59.position = "Outside";

newrecord$59.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$59.color = "Outline";

var newrecord$60 = Caml_obj.obj_dup($$default);

newrecord$60.position = "Inset";

newrecord$60.color = "Black";

var newrecord$61 = Caml_obj.obj_dup($$default);

newrecord$61.position = {
  TAG: "BorderBottom",
  _0: "Arte"
};

newrecord$61.color = "Arte";

var newrecord$62 = Caml_obj.obj_dup($$default);

newrecord$62.position = {
  TAG: "BorderBottom",
  _0: undefined
};

newrecord$62.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

newrecord$62.color = "White";

var newrecord$63 = Caml_obj.obj_dup($$default);

newrecord$63.weight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold");

newrecord$63.color = "White";

var feedback = newrecord$49;

var tvLogin = newrecord$50;

var guide = newrecord$51;

var eventZoneTrailer = newrecord$52;

var sharebar = newrecord$53;

var none = newrecord$54;

var checkboxActive = $$default;

var checkboxInactive = newrecord$55;

var underlineActive = newrecord$56;

var underlineInactive = newrecord$57;

var error = newrecord$58;

var accessibility = newrecord$59;

var sliderButton = newrecord$60;

var eventInfoLink = newrecord$61;

var burgerMenuLine = newrecord$62;

var qrCode = newrecord$63;

export {
  $$default as default,
  AndroidRealease ,
  Corporate ,
  Presse ,
  Textfield ,
  Cta ,
  Teaser ,
  Divider ,
  Settings ,
  Header ,
  Avatar ,
  feedback ,
  tvLogin ,
  guide ,
  eventZoneTrailer ,
  sharebar ,
  none ,
  checkboxActive ,
  checkboxInactive ,
  underlineActive ,
  underlineInactive ,
  makeProgression ,
  error ,
  accessibility ,
  sliderButton ,
  eventInfoLink ,
  burgerMenuLine ,
  qrCode ,
}
/* default Not a pure module */
