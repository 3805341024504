// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M27.3874 9.51005C25.8651 9.51005 24.4605 9.01772 23.3325 8.18718C22.0389 7.23509 21.1094 5.83848 20.7812 4.22877C20.6999 3.83105 20.6561 3.42061 20.652 3H16.3032V14.6001L16.298 20.954C16.298 22.6527 15.1648 24.0931 13.594 24.5996C13.1382 24.7466 12.6458 24.8163 12.1332 24.7888C11.4788 24.7537 10.8656 24.561 10.3326 24.2497C9.19837 23.5875 8.42938 22.3862 8.40854 21.012C8.37572 18.8642 10.1544 17.1131 12.353 17.1131C12.787 17.1131 13.2038 17.1822 13.594 17.3079V14.1373V12.9975C13.1824 12.938 12.7636 12.907 12.34 12.907C9.9335 12.907 7.68279 13.8835 6.07395 15.6427C4.85794 16.9722 4.12855 18.6684 4.01601 20.4469C3.86857 22.7834 4.74436 25.0045 6.44282 26.6432C6.69237 26.8837 6.95444 27.107 7.22848 27.313C8.68467 28.407 10.4649 29 12.34 29C12.7636 29 13.1824 28.9695 13.594 28.91C15.3456 28.6567 16.9618 27.874 18.2372 26.6432C19.8043 25.1311 20.6702 23.1237 20.6796 20.987L20.6572 11.4987C21.4048 12.0617 22.2223 12.5276 23.0996 12.8892C24.4641 13.4512 25.9109 13.736 27.3999 13.7355V10.6529V9.50903C27.401 9.51005 27.3885 9.51005 27.3874 9.51005Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
