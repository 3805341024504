// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

function makeContainerRules(style, display) {
  var baseRules = [
    display,
    CssJs.visibility(style.visibility)
  ];
  var borderRadiusRules;
  borderRadiusRules = style.borderRadius === "Rounded" ? [CssJs.borderRadius({
            NAME: "px",
            VAL: 5
          })] : [];
  var zIndexRule = Core__Option.getOr(Core__Option.map(style.zIndex, (function (zIndex) {
              return Util_Array.pure(Particle_zIndex.Particle_zIndex_Css.toCss(zIndex));
            })), []);
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, zIndexRule.concat(baseRules, borderRadiusRules));
}

var arrowRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.unsafe("content", ""),
      CssJs.width({
            NAME: "px",
            VAL: 20
          }),
      CssJs.height({
            NAME: "px",
            VAL: 20
          }),
      CssJs.transform(CssJs.rotate(CssJs.deg(45)))
    ]);

var arrowSize = 20;

export {
  makeContainerRules ,
  arrowSize ,
  arrowRules ,
}
/* arrowRules Not a pure module */
