// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M20.3763 18.1107C20.3763 18.756 19.834 19.2984 18.9432 19.2984C18.0524 19.2984 17.5101 18.756 17.5101 18.1107C17.5101 17.4654 17.8585 16.923 18.8527 16.5616C19.795 16.82 20.3763 17.4784 20.3763 18.1107ZM19.0077 15.077C19.666 14.7541 20.0664 14.1732 20.0664 13.6699C20.0664 13.334 19.7825 12.6756 18.9562 12.6756C18.1299 12.6756 17.82 13.334 17.82 13.6699C17.82 14.2377 18.2204 14.7026 19.0077 15.077ZM28.9995 16C28.9995 23.1796 23.1765 29 15.9932 29C8.80996 29 3.26156 23.4459 3 16.4966H6.20216V15.1321H3.01924C3.46644 8.35756 9.10272 3 15.9938 3C22.8848 3 29 8.82036 29 16H28.9995ZM15.3204 19.1663H13.6605V11.32H12.1738L9.9966 12.3423L10.448 13.5903L12.0147 12.9528V19.1663H10.2223V20.68H15.3204V19.1663ZM22.0029 18.0977C22.0029 16.884 21.0991 16.1612 20.2084 15.7738C20.7637 15.5029 21.719 14.8186 21.719 13.6309C21.719 12.5077 20.8542 11.32 18.9432 11.32C17.0322 11.32 16.1674 12.5077 16.1674 13.6309C16.1674 14.6766 16.7613 15.4769 17.665 15.9033C17.1617 16.0712 15.8835 16.794 15.8835 18.2272C15.8835 19.7508 17.0582 20.68 18.9432 20.68C20.8282 20.68 22.0029 19.6213 22.0029 18.0982V18.0977Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
