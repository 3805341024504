// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Template_ProgramContent_Heading(props) {
  var subtitle = props.subtitle;
  var title = props.title;
  if (title !== undefined) {
    if (subtitle !== undefined) {
      return JsxRuntime.jsx(JsxRuntime.Fragment, {
                  children: Caml_option.some(JsxRuntime.jsxs(Atom_Text.make, {
                            children: [
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(Type_String.Teaser.Title.toString(title)),
                                    kind: "span",
                                    style: Atom_Text_Ds.ProgramContent.make("Title")
                                  }),
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(Type_String.Teaser.Subtitle.toString(subtitle)),
                                    kind: "span",
                                    style: Atom_Text_Ds.ProgramContent.make("Subtitle")
                                  })
                            ],
                            identifier: Caml_option.some(Identifiers.Program.descriptionHeading),
                            kind: "h1"
                          }))
                });
    } else {
      return JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(Type_String.Teaser.Title.toString(title)),
                  identifier: Caml_option.some(Identifiers.Program.descriptionHeading),
                  kind: "h1",
                  style: Atom_Text_Ds.ProgramContent.make("Title")
                });
    }
  } else {
    return null;
  }
}

var make = Template_ProgramContent_Heading;

export {
  make ,
}
/* Atom_Text Not a pure module */
