// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";

function accessibility(state) {
  if (state === "On") {
    return Particle_Accessibility.make(undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return Particle_Accessibility.make(undefined, undefined, undefined, undefined, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

export {
  accessibility ,
}
/* No side effect */
