import { Component, ReactElement, ErrorInfo } from 'react';

type Props = {
    handleError: (error: Error, data: ErrorInfo) => void;
    renderError: (props: Props) => ReactElement;
    children: ReactElement;
};

type Stats = {
    hasError: boolean;
};

export class ErrorBoundary extends Component<Props, Stats> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): { hasError: boolean } {
        // Minified React errors #418, #425 and #423 are hydration errors
        if (
            error.message.includes('Minified React error #418;') ||
            error.message.includes('Minified React error #423;') ||
            error.message.includes('Minified React error #425;')
        ) {
            console.warn('Hydration error');
            return { hasError: false };
        }

        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.props.handleError(error, errorInfo);
    }

    render(): ReactElement {
        const { renderError } = this.props;

        if (this.state.hasError) {
            return renderError(this.props);
        }

        return this.props.children;
    }
}
