// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M15.9938 3C9.10272 3 3.46644 8.35756 3.01924 15.1321H6.20216V16.4966H3C3.26156 23.4459 8.9774 29 15.9938 29C23.0101 29 29 23.1796 29 16C29 8.82036 23.177 3 15.9938 3ZM15.5403 20.68H10.4422V19.1663H12.2347V12.9528L10.6679 13.5898L10.2166 12.3418L12.3938 11.3195H13.881V19.1658H15.5403V20.6795V20.68ZM21.784 20.68H16.2205V19.3706C16.2205 18.0748 17.2938 17.145 18.3546 16.2028C19.2578 15.4041 20.0435 14.6579 20.0435 13.7942C20.0435 13.336 19.795 12.7209 18.8262 12.7209C17.923 12.7209 17.1768 13.205 17.1768 13.205L16.5746 11.8956C16.9542 11.6731 17.6614 11.32 18.8657 11.32C20.9343 11.32 21.772 12.2888 21.772 13.7942C21.772 15.1555 20.5282 16.3468 19.4809 17.2371C18.7087 17.8918 18.0145 18.5069 18.0145 19.1481V19.1876H21.7845V20.68H21.784Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
