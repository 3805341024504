// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Organism_Dropdown from "../organism/dropdown/Organism_Dropdown.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var default_innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var $$default = {
  styleSelect: "select",
  innerSpace: default_innerSpace
};

var user_innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var user = {
  styleSelect: "user",
  innerSpace: user_innerSpace
};

var myProfile_innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var myProfile = {
  styleSelect: "myProfile",
  innerSpace: myProfile_innerSpace
};

var Molecule_DatePicker_Ds = {
  $$default: $$default,
  user: user,
  myProfile: myProfile
};

function reducer(state, action) {
  switch (action.TAG) {
    case "SetDay" :
        return {
                day: action._0,
                month: state.month,
                year: state.year
              };
    case "SetMonth" :
        return {
                day: state.day,
                month: action._0,
                year: state.year
              };
    case "SetYear" :
        return {
                day: state.day,
                month: state.month,
                year: action._0
              };
    
  }
}

function Molecule_DatePicker$Internal(props) {
  var initialValues = props.initialValues;
  var __style = props.style;
  var onChange = props.onChange;
  var style = __style !== undefined ? __style : $$default;
  var initialState = initialValues !== undefined ? ({
        day: initialValues.day,
        month: initialValues.month,
        year: initialValues.year
      }) : ({
        day: "",
        month: "",
        year: ""
      });
  var match = React.useReducer(reducer, initialState);
  var state = match[0];
  var dispatch = match[1];
  var match$1 = Context.I18n.use();
  var match$2 = match$1.labels.mona.profile;
  var yearLabel = match$2.birthdayYearLabel;
  var monthLabel = match$2.birthdayMonthLabel;
  var dayLabel = match$2.birthdayDayLabel;
  var handleDay = function (param) {
    dispatch({
          TAG: "SetDay",
          _0: Type_String.Dropdown.Item.toString(param.value)
        });
  };
  var handleMonth = function (param) {
    dispatch({
          TAG: "SetMonth",
          _0: Type_String.Dropdown.Item.toString(param.value)
        });
  };
  var handleYear = function (param) {
    dispatch({
          TAG: "SetYear",
          _0: Type_String.Dropdown.Item.toString(param.value)
        });
  };
  React.useEffect((function () {
          onChange(state);
        }), [state]);
  var styleSelect = style.styleSelect;
  return JsxRuntime.jsxs(Atom_Box.make, {
              children: [
                JsxRuntime.jsx(Organism_Dropdown.make, {
                      kind: {
                        TAG: "Select",
                        _0: {
                          name: dayLabel,
                          title: dayLabel,
                          autoComplete: "birthdayDay",
                          variant: styleSelect
                        }
                      },
                      items: props.days,
                      value: state.day,
                      onChange: handleDay,
                      ref_: props.ref_
                    }),
                JsxRuntime.jsx(Organism_Dropdown.make, {
                      kind: {
                        TAG: "Select",
                        _0: {
                          name: monthLabel,
                          title: monthLabel,
                          autoComplete: "birthdayMonth",
                          variant: styleSelect
                        }
                      },
                      items: props.months,
                      value: state.month,
                      onChange: handleMonth
                    }),
                JsxRuntime.jsx(Organism_Dropdown.make, {
                      kind: {
                        TAG: "Select",
                        _0: {
                          name: yearLabel,
                          title: yearLabel,
                          autoComplete: "birthdayYear",
                          variant: styleSelect
                        }
                      },
                      items: props.years,
                      value: state.year,
                      onChange: handleYear
                    })
              ],
              direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column")),
              display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              wrap: "OnOneLine",
              innerSpace: Caml_option.some(style.innerSpace)
            });
}

var Internal = {
  make: Molecule_DatePicker$Internal
};

function make(param) {
  var match = Util_Array.NonEmpty.makeFromArray(param.days);
  var match$1 = Util_Array.NonEmpty.makeFromArray(param.months);
  var match$2 = Util_Array.NonEmpty.makeFromArray(param.years);
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
    return JsxRuntime.jsx(Molecule_DatePicker$Internal, {
                onChange: param.onChange,
                style: param.style,
                days: Caml_option.valFromOption(match),
                months: Caml_option.valFromOption(match$1),
                years: Caml_option.valFromOption(match$2),
                initialValues: param.initialValues
              });
  } else {
    return null;
  }
}

function Molecule_DatePicker$User(props) {
  var match = Util_Array.NonEmpty.makeFromArray(props.days);
  var match$1 = Util_Array.NonEmpty.makeFromArray(props.months);
  var match$2 = Util_Array.NonEmpty.makeFromArray(props.years);
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
    return JsxRuntime.jsx(Molecule_DatePicker$Internal, {
                onChange: props.onChange,
                style: user,
                days: Caml_option.valFromOption(match),
                months: Caml_option.valFromOption(match$1),
                years: Caml_option.valFromOption(match$2),
                initialValues: props.initialValues
              });
  } else {
    return null;
  }
}

var User = {
  make: Molecule_DatePicker$User
};

var labelInnerspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

function Molecule_DatePicker$WithError(props) {
  var label = props.label;
  var errorMessage = props.errorMessage;
  var match = label !== undefined ? [
      JsxRuntime.jsx(Atom_Text.InputLabel.make, {
            children: Caml_option.some(label),
            kind: {
              TAG: "Text",
              _0: "legend"
            },
            style: Atom_Text_Ds.User.label
          }),
      "Fieldset"
    ] : [
      null,
      undefined
    ];
  var match$1 = Util_Array.NonEmpty.makeFromArray(props.days);
  var match$2 = Util_Array.NonEmpty.makeFromArray(props.months);
  var match$3 = Util_Array.NonEmpty.makeFromArray(props.years);
  if (match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
    return JsxRuntime.jsxs(Atom_Column.make, {
                children: [
                  match[0],
                  JsxRuntime.jsx(Molecule_DatePicker$Internal, {
                        onChange: props.onChange,
                        style: props.style,
                        days: Caml_option.valFromOption(match$1),
                        months: Caml_option.valFromOption(match$2),
                        years: Caml_option.valFromOption(match$3),
                        initialValues: props.initialValues,
                        ref_: props.ref_
                      }),
                  JsxRuntime.jsx(Atom_Column.make, {
                        accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "assertive", undefined, undefined, undefined, undefined, undefined),
                        children: errorMessage !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                                children: Caml_option.some(errorMessage),
                                style: Atom_Text_Ds.makeFeedback("Error")
                              }) : null,
                        innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
                        kind: "Span"
                      })
                ],
                innerSpace: Caml_option.some(labelInnerspace),
                kind: match[1]
              });
  } else {
    return null;
  }
}

var WithError = {
  make: Molecule_DatePicker$WithError
};

export {
  Molecule_DatePicker_Ds ,
  $$default as default,
  user ,
  myProfile ,
  reducer ,
  Internal ,
  make ,
  User ,
  labelInnerspace ,
  WithError ,
}
/* default Not a pure module */
