// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as $$Date from "@artegeie/date";

function makeDateWithoutTime(refDate) {
  var date = refDate.getDate();
  var year = refDate.getFullYear();
  var month = refDate.getMonth();
  return new Date(year, month, date);
}

function makeDateFromTimeInterval(date, timeInterval) {
  var end = timeInterval.end;
  var start = timeInterval.start;
  var startDate = makeDateWithoutTime(date);
  startDate.setHours(start.hour | 0, start.minute | 0);
  var endDate = makeDateWithoutTime(date);
  endDate.setHours(end.hour | 0, end.minute | 0);
  return {
          start: startDate,
          end: endDate
        };
}

function sortTeaserByUpcomingDate(teasers) {
  teasers.sort(function (a, b) {
        var availabilityA = a.availability;
        var availabilityB = b.availability;
        var dateA = Core__Option.map(availabilityA, (function (av) {
                return new Date(av.upcomingDate);
              }));
        var dateB = Core__Option.map(availabilityB, (function (av) {
                return new Date(av.upcomingDate);
              }));
        if (dateA === undefined) {
          if (dateB !== undefined) {
            return 1;
          } else {
            return 0;
          }
        }
        if (dateB === undefined) {
          return -1;
        }
        var match = Caml_option.valFromOption(dateA).getTime();
        var match$1 = Caml_option.valFromOption(dateB).getTime();
        if (match > match$1) {
          return 1;
        } else if (match < match$1) {
          return -1;
        } else {
          return 0;
        }
      });
  return teasers;
}

function compareDayPeriod(upcomingDate, dayPeriod) {
  var morningInterval = makeDateFromTimeInterval(upcomingDate, dayPeriod.morning);
  var dayTimeInterval = makeDateFromTimeInterval(upcomingDate, dayPeriod.dayTime);
  var eveningInterval = makeDateFromTimeInterval(upcomingDate, dayPeriod.evening);
  if ($$Date.isWithinInterval(upcomingDate, morningInterval)) {
    return "Morning";
  } else if ($$Date.isWithinInterval(upcomingDate, dayTimeInterval)) {
    return "DayTime";
  } else {
    $$Date.isWithinInterval(upcomingDate, eveningInterval);
    return "Evening";
  }
}

var frDayPeriod = {
  morning: {
    start: {
      hour: 5,
      minute: 0
    },
    end: {
      hour: 12,
      minute: 59
    }
  },
  dayTime: {
    start: {
      hour: 13,
      minute: 0
    },
    end: {
      hour: 20,
      minute: 49
    }
  },
  evening: {
    start: {
      hour: 20,
      minute: 50
    },
    end: {
      hour: 23,
      minute: 59
    }
  }
};

var deDayPeriod = {
  morning: {
    start: {
      hour: 5,
      minute: 0
    },
    end: {
      hour: 12,
      minute: 59
    }
  },
  dayTime: {
    start: {
      hour: 13,
      minute: 0
    },
    end: {
      hour: 20,
      minute: 14
    }
  },
  evening: {
    start: {
      hour: 20,
      minute: 15
    },
    end: {
      hour: 23,
      minute: 59
    }
  }
};

export {
  frDayPeriod ,
  deDayPeriod ,
  makeDateWithoutTime ,
  makeDateFromTimeInterval ,
  sortTeaserByUpcomingDate ,
  compareDayPeriod ,
}
/* @artegeie/date Not a pure module */
