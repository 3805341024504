import { type VideoKind } from '@artegeie/design-system/type';
import { useEffect, useReducer } from 'react';
import {
    AutoPlayStatus,
    type FromPage,
    getAutoplayFromDomain,
    getAutoplayFromVideoKind,
    getHigherAutoplay,
} from '@replay/types/Player';
import { useLocalStorage } from './useLocalStorage';

export type AutoPlayState = { next: boolean; clip: boolean; program: boolean };
export enum AutoPlayAction {
    NEXT = 'NEXT',
    CLIP = 'CLIP',
    PROGRAM = 'PROGRAM',
    INIT = 'INIT',
}
type AutoPlayChange = {
    autoPlay: AutoPlayState;
    onAutoPlayChange: (param: AutoPlayAction) => () => void;
};

export const localStorageKey = 'autoplay';
export const autoPlayInitialState: AutoPlayState = { next: true, clip: true, program: true };

export const useAutoPlayChange = (): AutoPlayChange => {
    const [autoPlay, setAutoplay] = useLocalStorage(localStorageKey, autoPlayInitialState);
    // this condition is only use to convert 'old' autoplay local storage value to new type (boolean->object)
    //needs to be removed with ffSettingsMenu
    let initialAutoPlay = autoPlay;
    if (typeof autoPlay === 'boolean') {
        initialAutoPlay = { ...autoPlayInitialState, next: autoPlay };
        setAutoplay(initialAutoPlay);
    }

    const autoPlayReducer = (
        state: AutoPlayState,
        action: { type: AutoPlayAction; payload: AutoPlayState | undefined },
    ) => {
        let newState: AutoPlayState;
        switch (action.type) {
            case AutoPlayAction.NEXT:
                newState = { ...state, next: !state.next };
                setAutoplay(newState);
                break;
            case AutoPlayAction.CLIP:
                newState = { ...state, clip: !state.clip };
                setAutoplay(newState);
                break;
            case AutoPlayAction.PROGRAM:
                newState = {
                    ...state,
                    program: !state.program,
                };
                setAutoplay(newState);
                break;
            case AutoPlayAction.INIT:
                newState = action.payload as AutoPlayState;
                break;
            default:
                newState = state;
                break;
        }
        return newState;
    };
    const [state, dispatch] = useReducer(autoPlayReducer, initialAutoPlay);
    const onAutoPlayChange = (param: AutoPlayAction) => () => dispatch({ type: param, payload: undefined });

    useEffect(() => {
        dispatch({ type: AutoPlayAction.INIT, payload: initialAutoPlay });
    }, [initialAutoPlay]);

    return {
        autoPlay: state,
        onAutoPlayChange: onAutoPlayChange,
    };
};

export const useGetAutoplayValues = (videoKind: VideoKind, domain: FromPage) => {
    const [autoPlay] = useLocalStorage(localStorageKey, autoPlayInitialState);
    const videoKindAutoplay = getAutoplayFromVideoKind(videoKind);
    const domainAutoplay = getAutoplayFromDomain(domain);
    const autoplayFromConfig = getHigherAutoplay(videoKindAutoplay, domainAutoplay);
    if ((videoKind === 'clip' || videoKind === 'preview') && !autoPlay.clip) {
        return AutoPlayStatus.DEACTIVATED;
    }
    if (!autoPlay.program && domain === 'other') {
        return AutoPlayStatus.DEACTIVATED;
    }
    return autoplayFromConfig;
};
