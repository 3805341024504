// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as SetFocus from "../../../hook/SetFocus.res.mjs";
import * as Util_Dom from "../../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as UsePortal from "../../../hook/UsePortal.res.mjs";
import * as ReactDom from "react-dom";
import * as Atom_Image from "../../atom/Atom_Image.res.mjs";
import * as UseVisible from "../../../hook/UseVisible.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "../../../type/Type_Sticker.res.mjs";
import * as Atom_Image_Ds from "../../atom/Atom_Image_Ds.res.mjs";
import * as UseScrollLock from "../../../hook/UseScrollLock.res.mjs";
import * as Core__Nullable from "@rescript/core/src/Core__Nullable.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as Type_Progression from "../../../type/Type_Progression.res.mjs";
import * as UseTrackingPixel from "../../../hook/UseTrackingPixel.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Teaser_Ds from "./Organism_Teaser_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Teaser_Css from "./Organism_Teaser_Css.res.mjs";
import * as UseOneToRuleThemAll from "../../../hook/UseOneToRuleThemAll.res.mjs";
import * as UseScreenResolution from "../../../hook/UseScreenResolution.res.mjs";
import * as Organism_Teaser_Util from "./Organism_Teaser_Util.res.mjs";
import * as Organism_Teaser_Focus from "./Organism_Teaser_Focus.res.mjs";
import * as Organism_Teaser_Hover from "./Organism_Teaser_Hover.res.mjs";
import * as Organism_Teaser_Legend from "./Organism_Teaser_Legend.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Organism_Teaser_Stickers from "./Organism_Teaser_Stickers.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_Teaser_Geoblocking from "./Organism_Teaser_Geoblocking.res.mjs";

var $$event = UseOneToRuleThemAll.$$Event.make();

function make(param) {
  var teaser = param.teaser;
  var title = teaser.title;
  var subtitle = teaser.subtitle;
  var stickers = teaser.stickers;
  var kind = teaser.kind;
  var imageSrc = teaser.imageSrc;
  var id = teaser.id;
  var href = teaser.href;
  var parentPage = teaser.parentPage;
  var onTeaserClick = param.onTeaserClick;
  var teaserRef = param.teaserRef;
  var onFocus = param.onFocus;
  var screenSize = UseScreenResolution.make(undefined, undefined);
  var teaserMode;
  if (typeof screenSize === "object") {
    var variant = screenSize.NAME;
    teaserMode = variant === "XS" || variant === "S" ? "Mobile" : "Desktop";
  } else {
    teaserMode = "Desktop";
  }
  var status = Core__Option.getOr(param.status, "Standard");
  var style = Core__Option.getOr(param.style, Organism_Teaser_Ds.classic("StandardTeaser", undefined));
  var template = Core__Option.getOr(param.template, "Landscape");
  var match = Context.I18n.use();
  var labels = match.labels;
  var match$1 = labels.teasers;
  var moreOptionsLabel = match$1.moreOptions;
  var match$2 = labels.program;
  var toBeContinuedLabel = match$2.to_be_continued;
  var match$3 = React.useState(function () {
        return "NotEscaped";
      });
  var setHoverEscaped = match$3[1];
  var hoverEscaped = match$3[0];
  var progression = User_Observable.Progression.Hook.use(teaser.dsId);
  var progressionPct = Type_Progression.getPct(progression.timeProgression);
  var match$4 = parentPage !== undefined && parentPage.domain === "concert" ? [
      "Concert",
      "Concert"
    ] : [
      "Replay",
      "Arte"
    ];
  var color = match$4[1];
  var borderColor = match$4[0];
  var teaserKind = React.useMemo((function () {
          var match = Type_Teaser.Kind.view(kind);
          if (typeof match === "object") {
            if (progressionPct !== undefined) {
              return {
                      NAME: "Progress",
                      VAL: progressionPct
                    };
            } else {
              return {
                      NAME: "Progress",
                      VAL: match.VAL
                    };
            }
          } else if (match === "PrimeTime") {
            return "PrimeTime";
          } else if (match === "Direct") {
            return {
                    NAME: "Direct",
                    VAL: borderColor
                  };
          } else if (match === "Live") {
            return {
                    NAME: "Live",
                    VAL: borderColor
                  };
          } else if (progressionPct !== undefined) {
            return {
                    NAME: "Progress",
                    VAL: progressionPct
                  };
          } else {
            return "Standard";
          }
        }), [
        kind,
        progressionPct
      ]);
  var focusMoreOptionsButtonRef = React.useRef(null);
  var hoverRef = React.useRef(null);
  var pictureRef = React.useRef(null);
  var ensuredTeaserRef = React.useRef(null);
  var match$5 = Context.Config.use();
  var isTeaserAvailable = match$5.api.isTeaserAvailable;
  var match$6 = Core__Option.map(teaser.geoblocking, (function (geoblocking) {
          return isTeaserAvailable(geoblocking.code);
        }));
  var status$1 = match$6 !== undefined && !match$6 ? "Geoblocked" : status;
  var match$7 = Organism_Teaser_Ds.withBorder(teaserKind, style);
  var mobileBorder = match$7.mobileBorder;
  var mobileHoverButtonStyle = match$7.mobileHoverButton;
  var match$8 = match$7.legend;
  var pictureKind = match$7.image;
  var hover = match$7.hover;
  var direction = match$7.direction;
  var containerCtaStyle = match$7.containerCtaStyle;
  var background = match$7.background;
  var initialWidth = React.useMemo((function () {
          var image = Atom_Image_Ds.view(Atom_Image_Ds.make(pictureKind));
          return Particle_Screen_Ds.map(image.display, (function (x) {
                        if (typeof x !== "object") {
                          return CssJs.px(0);
                        }
                        switch (x.TAG) {
                          case "Fluid" :
                              return CssJs.px(0);
                          case "Fixed" :
                              return CssJs.px(x._0.width);
                          case "FullPlace" :
                              return CssJs.pct(100);
                          
                        }
                      }));
        }), [pictureKind]);
  var ariaLabelledby = Particle_Accessibility.AriaLabelledby.make(Type_String.Teaser.Key.toString(teaser.key));
  ((function (__x) {
          return Particle_Accessibility.AriaLabelledby.registerNewIds(ariaLabelledby, __x);
        })(Type_Sticker.getIds(stickers)));
  var match$9 = hover !== undefined ? (
      status$1 === "Geoblocked" ? [
          undefined,
          true
        ] : [
          Organism_Teaser_Ds.Hover.overrideByTeaser(hover, teaser, pictureKind),
          true
        ]
    ) : [
      undefined,
      undefined
    ];
  var ariaHidden = match$9[1];
  var hover$1 = match$9[0];
  var addIdWhenSomeValue = function (value) {
    return Core__Option.map(value, (function (value) {
                  return Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, Organism_Teaser_Util.getLabelFromLabelledElements(value));
                }));
  };
  var titleId = title !== undefined && hover$1 !== undefined ? addIdWhenSomeValue("title") : undefined;
  var subtitleId = Core__Option.flatMap(hover$1, (function (param) {
          return addIdWhenSomeValue(Core__Option.map(subtitle, (function (param) {
                            return "subtitle";
                          })));
        }));
  var alt;
  if (title !== undefined) {
    switch (title.TAG) {
      case "AccessibilityOnly" :
          alt = Type_String.Teaser.Title.toString(title._0);
          break;
      case "Displayed" :
      case "HoverOnly" :
          alt = "";
          break;
      
    }
  } else {
    alt = "";
  }
  var pictureRules = status$1 === "Disabled" ? Organism_Teaser_Css.imgDisableRules(style.background) : Organism_Teaser_Css.imgWrapperRules;
  var match$10 = teaser.hoverBehavior;
  var border;
  var exit = 0;
  switch (match$10) {
    case "Disabled" :
        border = undefined;
        break;
    case "Normal" :
    case "Forced" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    border = status$1 === "Disabled" ? undefined : Core__Option.map(match$7.border, (function (border) {
              return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, border);
            }));
  }
  var titleStyle = Core__Option.map(match$7.title, Atom_Text_Ds.Teaser.toParent);
  var subtitleStyle = Core__Option.map(match$7.subtitle, Atom_Text_Ds.Teaser.toParent);
  var trackPixel = UseTrackingPixel.make(Core__Option.flatMap(teaser.trackingPixel, Type_String.TrackingPixel.toTypeUrl));
  var handleTeaserClick = function (param) {
    trackPixel();
    if (onTeaserClick !== undefined) {
      return onTeaserClick();
    }
    
  };
  var containerInitialWidth = Particle_Screen_Ds.map(initialWidth, (function (initialWidth) {
          if (direction === "row") {
            return "initial";
          } else {
            return initialWidth;
          }
        }));
  var match$11 = UseVisible.make(undefined, undefined, undefined);
  var cbRef = match$11.cbRef;
  var setPictureRef = React.useCallback((function (node) {
          pictureRef.current = node;
          if (direction === "row") {
            return cbRef(node);
          }
          
        }), [
        cbRef,
        direction
      ]);
  var setTeaserRef = React.useCallback((function (node) {
          ensuredTeaserRef.current = node;
          if (direction === "row") {
            
          } else {
            cbRef(node);
          }
          if (teaserRef !== undefined) {
            teaserRef.current = node;
            return ;
          }
          
        }), [
        cbRef,
        direction
      ]);
  var centerHoverRef = direction === "row" ? pictureRef : ensuredTeaserRef;
  var pictureAutoSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "NoResizing");
  var hoverPictureKind = Organism_Teaser_Ds.getHoverPictureKind(pictureKind);
  var match$12 = UseOneToRuleThemAll.make(undefined, $$event);
  var hideHover = match$12.setOff;
  var displayHover = match$12.setOn;
  var hoverStatus = match$12.status;
  var mouseHoverSetOff = function () {
    setHoverEscaped(function (param) {
          return "Escaped";
        });
    hideHover();
  };
  var target = UsePortal.make(Organism_Teaser_Hover.portalHoverId);
  var modeDefault = {
    TAG: "MouseHover",
    _0: mouseHoverSetOff
  };
  var match$13 = React.useState(function () {
        return modeDefault;
      });
  var setMode = match$13[1];
  var mode = match$13[0];
  var match$14 = UseScrollLock.make();
  var disableScrollLock = match$14.disableScrollLock;
  var enableScrollLock = match$14.enableScrollLock;
  var modalCloseButtonOnClick = React.useCallback((function (param) {
          setMode(function (param) {
                return modeDefault;
              });
          hideHover();
          disableScrollLock();
          SetFocus.ByRef.make(focusMoreOptionsButtonRef);
        }), [
        focusMoreOptionsButtonRef,
        hideHover
      ]);
  var closeOnBlur = React.useCallback((function () {
          setMode(function (param) {
                return modeDefault;
              });
          hideHover();
          SetFocus.ByRef.make(focusMoreOptionsButtonRef);
        }), [
        focusMoreOptionsButtonRef,
        hideHover
      ]);
  var onClickMoreOptions = React.useCallback((function (param) {
          setMode(function (param) {
                return {
                        TAG: "Dialog",
                        _0: modalCloseButtonOnClick,
                        _1: closeOnBlur
                      };
              });
          displayHover();
          setTimeout((function () {
                  SetFocus.setFocus(Util_Dom.getFirstElementFocusable(hoverRef));
                }), 100);
        }), [
        hoverRef,
        displayHover
      ]);
  var onClickMobileHover = React.useCallback((function (param) {
          setMode(function (param) {
                return {
                        TAG: "MobileHover",
                        _0: modalCloseButtonOnClick,
                        _1: closeOnBlur
                      };
              });
          setTimeout((function () {
                  SetFocus.setFocus(Util_Dom.getFirstElementFocusable(hoverRef));
                }), 100);
          displayHover();
          enableScrollLock();
        }), [
        hoverRef,
        displayHover
      ]);
  var screenReadersLabel = Organism_Teaser_Util.getScreenReadersLabel(progressionPct, match$1.continueWatching, match$1.watch, match$1.watchAgain, match.formaters.progress, toBeContinuedLabel, teaser);
  var displayStatus;
  var exit$1 = 0;
  var tmp = match$11.status;
  if (typeof tmp !== "object" && tmp === "FullVisible") {
    displayStatus = "Hoverable";
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    switch (mode.TAG) {
      case "Dialog" :
      case "MouseHover" :
          displayStatus = "Unhoverable";
          break;
      case "MobileHover" :
          displayStatus = "Hoverable";
          break;
      
    }
  }
  var makeImage = React.useCallback((function (pictureKind) {
          return JsxRuntime.jsx(Atom_Image.make, {
                      ariaHidden: ariaHidden,
                      alt: alt,
                      identifier: Caml_option.some(Identifiers.Teaser.img),
                      src: imageSrc,
                      style: pictureKind
                    });
        }), [pictureKind]);
  var imageHover = makeImage(hoverPictureKind);
  var image = makeImage(pictureKind);
  var stickersComponent = JsxRuntime.jsx(Organism_Teaser_Stickers.make, {
        stickers: stickers,
        stickersDefinition: match$7.sticker,
        status: status$1,
        color: color,
        id: Type_String.Teaser.Id.toString(id)
      });
  var hoverAccessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1);
  var match$15 = teaser.hoverBehavior;
  var hoverPortalContent;
  switch (match$15) {
    case "Normal" :
        if (hover$1 !== undefined && hoverStatus === "On" && displayStatus === "Hoverable" && hoverEscaped !== "Escaped") {
          var stickersHoverComponent = JsxRuntime.jsx(Organism_Teaser_Stickers.make, {
                stickers: stickers,
                stickersDefinition: hover$1.sticker,
                status: status$1,
                color: color,
                id: Type_String.Teaser.Id.toString(id)
              });
          hoverPortalContent = JsxRuntime.jsx(Organism_Teaser_Hover.make, {
                centerHoverRef: centerHoverRef,
                children: JsxRuntime.jsxs(Atom_Cell.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                              accessibility: hoverAccessibility,
                              children: imageHover,
                              href: Type_String.Href.toString(href),
                              onClick: handleTeaserClick,
                              style: containerCtaStyle
                            }),
                        stickersHoverComponent
                      ],
                      position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                      rules: Caml_option.some(pictureRules)
                    }),
                hoverRef: hoverRef,
                mode: mode,
                pictureKind: hoverPictureKind,
                progression: progressionPct,
                style: hover$1,
                subtitleId: subtitleId,
                teaser: teaser,
                titleId: titleId,
                handleTeaserClick: handleTeaserClick,
                parentContext: style.context
              });
        } else {
          hoverPortalContent = null;
        }
        break;
    case "Disabled" :
        hoverPortalContent = null;
        break;
    case "Forced" :
        hoverPortalContent = null;
        break;
    
  }
  var hoverPortal = React.useMemo((function () {
          if (target !== undefined && hoverEscaped !== "Escaped") {
            return ReactDom.createPortal(hoverPortalContent, Caml_option.valFromOption(target));
          } else {
            return null;
          }
        }), [
        target,
        hoverPortalContent
      ]);
  var timer = {
    contents: null
  };
  var timeoutOnMouseEnter = function () {
    timer.contents = setTimeout(displayHover, 425);
  };
  var clearTimeOnMouseLeave = function () {
    Core__Nullable.map(timer.contents, (function (timer) {
            clearTimeout(timer);
          }));
    setHoverEscaped(function (param) {
          return "NotEscaped";
        });
    hideHover();
    timer.contents = null;
  };
  var match$16 = hover$1 !== undefined ? (
      teaserMode === "Desktop" ? ({
            handleOnMouseEnter: (function (param) {
                timeoutOnMouseEnter();
              }),
            handleOnMouseLeave: (function (param) {
                clearTimeOnMouseLeave();
              }),
            onClickMoreOptions: onClickMoreOptions,
            onClickMobileHover: onClickMobileHover
          }) : ({
            handleOnMouseEnter: undefined,
            handleOnMouseLeave: undefined,
            onClickMoreOptions: undefined,
            onClickMobileHover: onClickMobileHover
          })
    ) : ({
        handleOnMouseEnter: undefined,
        handleOnMouseLeave: undefined,
        onClickMoreOptions: undefined,
        onClickMobileHover: undefined
      });
  var handleOnFocus;
  var exit$2 = 0;
  if (hover$1 !== undefined && onFocus !== undefined) {
    handleOnFocus = (function ($$event) {
        onFocus($$event);
      });
  } else {
    exit$2 = 1;
  }
  if (exit$2 === 1) {
    handleOnFocus = (function (param) {
        
      });
  }
  var match$17 = teaser.hoverBehavior;
  var focus;
  var exit$3 = 0;
  switch (match$17) {
    case "Disabled" :
        focus = null;
        break;
    case "Normal" :
    case "Forced" :
        exit$3 = 1;
        break;
    
  }
  if (exit$3 === 1) {
    if (hover$1 !== undefined) {
      var screenReadersLabel$1 = Organism_Teaser_Util.getScreenReadersLabel(undefined, undefined, undefined, undefined, undefined, toBeContinuedLabel, teaser);
      focus = JsxRuntime.jsx(Organism_Teaser_Focus.make, {
            background: hover$1.background,
            buttonRef: focusMoreOptionsButtonRef,
            label: moreOptionsLabel,
            onClick: match$16.onClickMoreOptions,
            screenReadersLabel: screenReadersLabel$1,
            style: match$7.focus
          });
    } else {
      focus = null;
    }
  }
  var match$18 = teaser.hoverBehavior;
  var match$19;
  switch (match$18) {
    case "Disabled" :
        match$19 = [
          undefined,
          undefined,
          undefined
        ];
        break;
    case "Normal" :
    case "Forced" :
        match$19 = [
          mobileHoverButtonStyle,
          mobileBorder,
          background
        ];
        break;
    
  }
  var mobileHoverButtonStyle$1 = match$19[0];
  var mobileHoverButton = status$1 === "Disabled" || mobileHoverButtonStyle$1 === undefined ? null : JsxRuntime.jsx(Atom_Cell.make, {
          children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                identifier: Caml_option.some(Identifiers.Teaser.mobileHoverButton),
                label: moreOptionsLabel,
                onClick: match$16.onClickMobileHover,
                style: Atom_Cta_Ds.Teaser.makeTeaserMobileHover(Caml_option.valFromOption(mobileHoverButtonStyle$1))
              }),
          rules: Caml_option.some(Organism_Teaser_Css.mobileHoverButtonWrapperRules)
        });
  var geoblocking = status$1 === "Geoblocked" ? JsxRuntime.jsx(Organism_Teaser_Geoblocking.make, {}) : null;
  var picture = JsxRuntime.jsxs(Atom_Cell.make, {
        autoSize: Caml_option.some(pictureAutoSize),
        border: border,
        children: [
          image,
          stickersComponent,
          geoblocking
        ],
        position: Caml_option.some(Particle_Screen_Constants.positionRelative),
        rules: Caml_option.some(pictureRules),
        cbRef: setPictureRef
      });
  var match$20 = status$1 === "Disabled" ? [
      Organism_Teaser_Css.wrapperDisabledRules,
      Particle_Accessibility.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    ] : (
      template === "LandscapeGenre" ? [
          Organism_Teaser_Css.wrapperGenreRules(teaser.hoverBehavior),
          undefined
        ] : [
          Organism_Teaser_Css.wrapperRules(Caml_option.some(match$19[2]), Caml_option.some(match$19[1]), undefined),
          undefined
        ]
    );
  return JsxRuntime.jsxs(Atom_Column.make, {
              cbRef: setTeaserRef,
              children: [
                JsxRuntime.jsxs(Atom_Cta.Link.Block.make, {
                      accessibility: match$20[1],
                      children: [
                        picture,
                        JsxRuntime.jsx(Organism_Teaser_Legend.make, {
                              justifyContent: match$8.justifyContent,
                              subtitle: subtitle,
                              subtitleStyle: subtitleStyle,
                              containerBlockspace: match$8.blockspace,
                              title: title,
                              titleStyle: titleStyle,
                              teaserTemplate: template,
                              hoverBehavior: teaser.hoverBehavior
                            })
                      ],
                      direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, direction)),
                      href: Type_String.Href.toString(href),
                      identifier: Caml_option.some(Identifiers.Teaser.link),
                      innerSpace: match$7.innerSpace,
                      onClick: handleTeaserClick,
                      screenReadersLabel: screenReadersLabel,
                      style: containerCtaStyle
                    }),
                mobileHoverButton,
                focus,
                hoverPortal
              ],
              identifier: Caml_option.some(Identifiers.Teaser.item),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween),
              onFocus: handleOnFocus,
              onMouseEnter: match$16.handleOnMouseEnter,
              onMouseLeave: match$16.handleOnMouseLeave,
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              rules: Caml_option.some(match$20[0]),
              width: Caml_option.some(containerInitialWidth)
            });
}

export {
  $$event ,
  make ,
}
/* event Not a pure module */
