import fr from '@artegeie/date/dist/locale/fr';
import de from '@artegeie/date/dist/locale/de';
import es from '@artegeie/date/dist/locale/es';
import pl from '@artegeie/date/dist/locale/pl';
import en from '@artegeie/date/dist/locale/en';
import it from '@artegeie/date/dist/locale/it';
import { type DateLocale } from '@artegeie/date';
import { type Locale } from '../types/locale';

const locales = { fr, de, es, pl, en, it };

export const getDateLocale = (locale: Locale): DateLocale => {
    return locales[locale] || en;
};
