// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1711 8.83456 29 16 29C23.1711 29 29 23.1711 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.83456 3 16ZM4.47144 16C4.47144 9.64544 9.64544 4.47144 16 4.47144C22.3546 4.47144 27.5286 9.64544 27.5286 16C27.5286 22.3594 22.3546 27.5286 16 27.5286C9.64056 27.5286 4.47144 22.3594 4.47144 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M12.1423 12.308H9.28713V19.6302H12.1423C14.4408 19.6302 16.0317 18.028 16.0317 15.9691V15.948C16.0317 13.8891 14.4408 12.308 12.1423 12.308ZM10.8991 18.1791V13.7689H12.1423C13.4585 13.7689 14.3474 14.6789 14.3474 15.974V15.9951C14.3474 17.2902 13.4577 18.1791 12.1423 18.1791H10.8991Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M17.4666 12.308H22.9891V13.7429H19.068V15.2297H22.5211V16.6646H19.068V18.2035H23.0411V19.6384H17.4666V12.3064V12.308Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
