'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SetFocus from "./SetFocus.res.mjs";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Webapi__Dom__Document from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.res.mjs";

function getFirstAndLastElement(containerRef) {
  var first = Util_Dom.getFirstElementFocusable(containerRef);
  var last = Util_Dom.getLastElementFocusable(containerRef);
  return [
          first,
          last
        ];
}

function executeCallback($$event, callback) {
  $$event.preventDefault();
  return callback();
}

function setFocusOnElement(element) {
  SetFocus.setFocus(element);
}

function handleKeyDown(firstElement, lastElement, forwardExitCallback, backwardExitCallback, $$event) {
  var activeElement = Core__Option.flatMap(Core__Option.flatMap(Util_Dom.SafeWebApi.$$document, Webapi__Dom__Document.asHtmlDocument), (function (prim) {
          return Caml_option.nullable_to_opt(prim.activeElement);
        }));
  var isFirstElement = activeElement === firstElement;
  var isLastElement = activeElement === lastElement;
  var position = isFirstElement ? (
      isLastElement ? "Alone" : (
          isFirstElement ? "First" : "Middle"
        )
    ) : (
      isLastElement ? "Last" : (
          isFirstElement ? "First" : "Middle"
        )
    );
  var key = Util_Dom.$$KeyboardEvent.getKey($$event);
  if (key === undefined) {
    return ;
  }
  if (key === "ShiftTab") {
    switch (position) {
      case "Middle" :
      case "Last" :
          return ;
      case "First" :
      case "Alone" :
          return executeCallback($$event, backwardExitCallback);
      
    }
  } else {
    if (key !== "Tab") {
      return ;
    }
    switch (position) {
      case "First" :
      case "Middle" :
          return ;
      case "Last" :
      case "Alone" :
          return executeCallback($$event, forwardExitCallback);
      
    }
  }
}

function make(containerRef) {
  var match = React.useState(function () {
        return [
                undefined,
                undefined
              ];
      });
  var setFirstAndLastElementsFocus = match[1];
  var match$1 = match[0];
  var lastElement = match$1[1];
  var firstElement = match$1[0];
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          if (isMount.contents) {
            var newFirstAndLastElementsFocus = getFirstAndLastElement(containerRef);
            setFirstAndLastElementsFocus(function (param) {
                  return newFirstAndLastElementsFocus;
                });
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [containerRef.current]);
  var ensuredForwardExitCallback = function () {
    SetFocus.setFocus(firstElement);
  };
  var ensuredBackwardExitCallback = function () {
    SetFocus.setFocus(lastElement);
  };
  var handleKeyDown$1 = React.useCallback((function (extra) {
          return handleKeyDown(firstElement, lastElement, ensuredForwardExitCallback, ensuredBackwardExitCallback, extra);
        }), [
        firstElement,
        lastElement,
        ensuredForwardExitCallback,
        ensuredBackwardExitCallback
      ]);
  React.useEffect((function () {
          document.addEventListener("keydown", handleKeyDown$1);
          return (function () {
                    document.removeEventListener("keydown", handleKeyDown$1);
                  });
        }), [handleKeyDown$1]);
}

export {
  getFirstAndLastElement ,
  executeCallback ,
  setFocusOnElement ,
  handleKeyDown ,
  make ,
}
/* react Not a pure module */
