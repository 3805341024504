import type { Zone as DsZone } from '@artegeie/design-system/type';
import { type Teaser } from './Teaser';

export const getData = (zone: Zone): Teaser[] => {
    return zone.content.data;
};

export const getCode = (zone: Zone): string => {
    return zone.code || '';
};

export const getNextPage = (pagination: Zone['content']['pagination']): string | null => {
    return (pagination && pagination.links?.next) || null;
};

export type Zone = DsZone;
