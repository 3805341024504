// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "Teaser"
    });

var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [zIndexRule]);

function Organism_Teaser_Geoblocking(props) {
  return JsxRuntime.jsx(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              background: Caml_option.some(Particle_Background_Ds.geoblocking),
              children: JsxRuntime.jsx(Atom_Text.make, {
                    style: Atom_Text_Ds.Teaser.toParent(Atom_Text_Ds.Teaser.geoblocking)
                  }),
              height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
              position: Caml_option.some(Particle_Screen_Constants.positionAbsolute),
              rules: Caml_option.some(rules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var make = Organism_Teaser_Geoblocking;

export {
  zIndexRule ,
  rules ,
  make ,
}
/* zIndexRule Not a pure module */
