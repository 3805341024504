// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Particle_Css from "./Particle_Css.res.mjs";
import * as Particle_Color from "./Particle_Color.res.mjs";
import * as Particle_zIndex from "./Particle_zIndex.res.mjs";
import * as Particle_Screen_Css from "./Particle_Screen_Css.res.mjs";

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Above",
      _0: "Cta"
    });

var commonRules = [
  CssJs.unsafe("content", ""),
  CssJs.position(CssJs.absolute),
  CssJs.display(CssJs.block),
  CssJs.right(CssJs.px(0)),
  CssJs.left(CssJs.px(0)),
  CssJs.top(CssJs.px(0)),
  CssJs.bottom(CssJs.px(0)),
  zIndexRule
];

function createBorder(border, color, weight, radius) {
  var borderRules = Util_Array.pure(border(Particle_Css.border_width(weight), CssJs.solid, Particle_Color.Css.color(color)));
  if (radius !== undefined) {
    if (radius.TAG === "All") {
      return borderRules.concat([CssJs.borderRadius(radius._0)]);
    } else {
      return borderRules.concat([CssJs.borderRadius4(radius._0, radius._1, radius._2, radius._3)]);
    }
  } else {
    return borderRules;
  }
}

function toCss(dsStyle) {
  var backgroundColor = dsStyle.position;
  var weight = dsStyle.weight;
  var color = dsStyle.color;
  if (typeof backgroundColor !== "object") {
    switch (backgroundColor) {
      case "Around" :
          var radius = dsStyle.radius;
          var borders = Particle_Screen_Css.toRules(undefined, undefined, (function (__x) {
                  return createBorder(CssJs.border, color, __x, radius);
                }), weight);
          var rules = commonRules.concat(borders);
          return [CssJs.before(rules)];
      case "Border" :
          var radius$1 = dsStyle.radius;
          return Particle_Screen_Css.toRules(undefined, undefined, (function (__x) {
                        return createBorder(CssJs.border, color, __x, radius$1);
                      }), weight);
      case "BorderLeft" :
          var radius$2 = dsStyle.radius;
          return Particle_Screen_Css.toRules(undefined, undefined, (function (__x) {
                        return createBorder(CssJs.borderLeft, color, __x, radius$2);
                      }), weight);
      case "Outside" :
          var radius$3 = dsStyle.radius;
          return Particle_Screen_Css.toRules(undefined, undefined, (function (__x) {
                          return createBorder(CssJs.outline, color, __x, radius$3);
                        }), weight).concat([CssJs.not_(":focus-visible", [CssJs.outlineColor(CssJs.transparent)])]);
      case "Inset" :
          return Particle_Screen_Css.toRules(undefined, undefined, (function (extra) {
                        return Util_Array.pure(CssJs.boxShadow(Css_Js_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(0), Particle_Css.border_width(extra), true, Particle_Color.Css.color(color))));
                      }), weight);
      
    }
  } else {
    var radius$4 = dsStyle.radius;
    var backgroundColor$1 = backgroundColor._0;
    var makeBorders = function (color) {
      return Particle_Screen_Css.toRules(undefined, undefined, (function (__x) {
                    return createBorder(CssJs.borderBottom, color, __x, radius$4);
                  }), weight);
    };
    if (backgroundColor$1 !== undefined) {
      return [
              CssJs.before(commonRules.concat(makeBorders(backgroundColor$1)).concat([
                        CssJs.width(CssJs.pct(100)),
                        CssJs.pointerEvents("none")
                      ])),
              CssJs.after(commonRules.concat(makeBorders(color)).concat([
                        CssJs.width(CssJs.pct(dsStyle.width)),
                        CssJs.pointerEvents("none")
                      ]))
            ];
    } else {
      return makeBorders(color);
    }
  }
}

export {
  zIndexRule ,
  commonRules ,
  createBorder ,
  toCss ,
}
/* zIndexRule Not a pure module */
