// Generated by ReScript, PLEASE EDIT WITH CARE


function isEmpty(string) {
  return string === "";
}

function isNotEmpty(string) {
  return string !== "";
}

function isString(value) {
  return typeof value === "string";
}

export {
  isEmpty ,
  isNotEmpty ,
  isString ,
}
/* No side effect */
