import { useTokenStatus } from '@artegeie/design-system/replay';
import { type FrontendContext, type TrackingGroupControl, type Geoblocking } from '@artegeie/design-system/type';
import { paths } from '@replay/api-docs-generator/types/sst';
import { type ReleaseInfos } from '@replay/types/App';
import { type ServerSideTracking } from '@replay/types/Stats';

export type ConsentStatus = 'unknown' | 'all-on' | 'all-off' | 'mixed' | 'unset';
export enum ConsentCategory {
    ON = 'on',
    OFF = 'off',
}

/** Get datas from query body
 *  type: "NAVIGATION" | "PLAYBACK" | "INPUT",
 *  action: "PAGE_VIEWED" | "CONTROL_CLICKED" | "VIDEO_PLAYED" | "VIDEO_PAUSED" | "VIDEO_STOPPED" | "PROGRAM_ADDED_TO_FAVORITES",
 *  auth_type: from ADS tokenStatus: "NotReady" | "Disconnected" | "Anonymous" | "Connected",
 * deeplink: string,
 * emacData: from emac page, used for NAVIGATION and INPUT,
 *  **/

export type UtmData = {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
};

export type Data = {
    trackingId?: string;
    trackingIdExp?: string;
    loginStatus: LoginStatus;
    audienceMeasurementConsent?: ConsentCategory;
    technicalMeasurementConsent?: ConsentCategory;
    releaseInfos?: ReleaseInfos;
    locale: 'fr' | 'de' | 'en' | 'es' | 'pl' | 'it';
    abv?: string;
    userId?: undefined | string;
    ageVerification?: undefined | string;
    utm?: UtmData;
    geoblocking?: Geoblocking;
    pageStats?: ServerSideTracking | undefined;
    trackingUrl: string;
};
export type RequiredData = Required<Data>;

export type EventData = {
    type: ApiBody['type'];
    action: ApiBody['action'];
    time: string;
    referrer: string;
    frontendContext?: ApiBody['frontendContext'];
    emacApiContext: Record<string, unknown>;
    playerApiContext?: Record<string, unknown>;
    clickTrackingUrl?: string;
};

export type LoginStatus = ReturnType<typeof useTokenStatus>;
export type ApiBody =
    paths['/api/server-side-tracking/v1/tracking']['post']['requestBody']['content']['application/json'];

const ageVerificationTypeGroupControls = {
    verify_age_button: 'age_verification',
    age_verification_succeded: 'age_verification',
    age_verification_failed: 'age_verification',
} as const;

export type AgeVerificationGroupControlTypes = Uppercase<keyof typeof ageVerificationTypeGroupControls>;
export type TeaserClickedGroupControlType = 'TEASER_CLICKED';
export type VideoGroupControlType = 'VIDEO_PLAYED' | 'VIDEO_PAUSED' | 'VIDEO_STOPPED';
export type PageViewedGroupControlType = 'PAGE_VIEWED';
export type UserClickGroupControlType = TrackingGroupControl;

type EventName =
    | AgeVerificationGroupControlTypes
    | UserClickGroupControlType
    | VideoGroupControlType
    | PageViewedGroupControlType
    | TeaserClickedGroupControlType
    | FrontendContext['eventName'];
type Context<TAction extends ApiType, TEventName extends EventName, TEventGroup extends string | undefined> = {
    action: TAction;
    eventName: Lowercase<TEventName>;
    eventGroup: TEventGroup;
};

type EventContext =
    | AgeVerificationFrontendContext
    | UserFrontContext
    | VideoFrontContext
    | PageViewedFrontContext
    | TeaserClickedFrontContext;
type EventBehavior = 'DELAYED' | 'IMMEDIATE';
type EventType<T extends EventContext, Payload = undefined> = {
    type: Uppercase<T['eventName']>;
    action: T['action'];
    time: string;
    referrer?: string;
    emacApiContext: ServerSideTracking;
    group: T extends AgeVerificationFrontendContext | UserFrontContext
        ? { eventGroup: T['eventGroup']; eventName: T['eventName'] }
        : undefined;

    payload: Payload;
    behavior: EventBehavior;
};
export type TeaserClicked = EventType<TeaserClickedFrontContext, { clickTrackingUrl: string }>;
export type Video = EventType<
    VideoFrontContext,
    {
        playerContext: {
            timecode: number;
            previousTimecode: number;
            audioTrackLanguage?: string;
            audioTrackType?: 'STANDARD' | 'AUDIO_DESCRIPTION';
            subtitlesTrackLanguage?: string;
            subtitlesTrackType?: 'FORCED' | 'FULL' | 'STM';
            streamUrl: string;
            soundMuted?: boolean;
            playbackMode: 'DEVICE' | 'CHROMECAST' | 'AIRPLAY' | 'PIP' | 'SHAREPLAY';
        };
        playerApiContext: Record<string, unknown>;
    }
>;
export type AgeVerification = EventType<AgeVerificationFrontendContext>;
export type UserClicked = EventType<UserFrontContext>;
export type PageViewed = EventType<PageViewedFrontContext>;

export type UserFrontContext = Context<'ACTION', FrontendContext['eventName'], FrontendContext['eventGroup']>;
export type AgeVerificationFrontendContext = Context<'ACTION', AgeVerificationGroupControlTypes, 'age_verification'>;
export type VideoFrontContext = Context<'PLAYBACK', VideoGroupControlType, undefined>;
export type PageViewedFrontContext = Context<'NAVIGATION', PageViewedGroupControlType, undefined>;
export type TeaserClickedFrontContext = Context<'ACTION', TeaserClickedGroupControlType, undefined>;

export type Event = TeaserClicked | Video | AgeVerification | PageViewed | UserClicked;

export type ApiType =
    paths['/api/server-side-tracking/v1/tracking']['post']['requestBody']['content']['application/json']['type'];
export type ApiActions =
    paths['/api/server-side-tracking/v1/tracking']['post']['requestBody']['content']['application/json']['action'];
