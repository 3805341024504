import { UseBoundStore, create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import { type TrackingEvent } from '@artegeie/arte-vp';
import { type ServerSideTracking } from '@replay/types/Stats';
import { pageViewedEvent, ageVerificationEvent, teaserClickedEvent, playerEvent, userClickedEvent } from './event';
import type {
    AgeVerificationFrontendContext,
    AgeVerificationGroupControlTypes,
    Event,
    UserFrontContext,
} from '@replay/types/Tracking';
import { customLocalStorage } from './customStorage';

type TrackingEventData = {
    queue: Event[];
    forceSend: boolean;
};

const QUEUE_LIMIT = 20;
const trackingInitialStore: TrackingEventData = {
    forceSend: false,
    queue: [],
};

interface TrackingEvents extends TrackingEventData {
    sendPageview: (stats: ServerSideTracking | undefined) => void;
    sendTeaserClick: (stats: ServerSideTracking | undefined, clickTrackingUrl: string) => void;
    setForceSend: (forceSend: boolean) => void;
    sendPlayerEvent: (
        stats: ServerSideTracking | undefined,
        { action, frontendContextPlayer, apiContextPlayer }: TrackingEvent,
    ) => void;
    sendAgeVerificationEvent: (stats: ServerSideTracking, action: AgeVerificationGroupControlTypes) => void;
    sendUserClickedEvent: (stats: ServerSideTracking | undefined, action: UserFrontContext) => void;
}
/**
 * @param eventQueue current event queue
 * @param newEvent event to add to the queue
 * @returns the new queue containing {newEvent} with a maximum length of 20
 */
const addToEventsQueue = (eventQueue: Event[], newEvent: Event): Event[] => {
    const newEventQueue = [...eventQueue, newEvent];
    return newEventQueue.slice(-QUEUE_LIMIT);
};

const stores: Record<string, UseBoundStore<ReturnType<typeof eventStore2>>> = {};

const eventsStoreName = 't_queue_replay';
export const eventStore2 = (env: string) => {
    return create<TrackingEvents>()(
        persist(
            subscribeWithSelector(
                devtools(
                    (set) => ({
                        ...trackingInitialStore,
                        setForceSend: (forceSend: boolean) => {
                            set({ forceSend });
                        },
                        sendPageview: (stats) => {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: addToEventsQueue(state.queue, pageViewedEvent(stats)),
                                        };
                                    },
                                    false,
                                    'sendPageview',
                                );
                            }
                        },
                        sendAgeVerificationEvent: (stats, action) => {
                            const context: AgeVerificationFrontendContext = {
                                action: 'ACTION',
                                eventName: action as Lowercase<AgeVerificationGroupControlTypes>,
                                eventGroup: 'age_verification',
                            };
                            set(
                                (state) => {
                                    return {
                                        queue: addToEventsQueue(state.queue, ageVerificationEvent(stats, context)),
                                    };
                                },
                                false,
                                `sendAgeVerificationEvent/${action}`,
                            );
                        },
                        sendTeaserClick: (stats, clickTrackingUrl) => {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: addToEventsQueue(
                                                state.queue,
                                                teaserClickedEvent(stats, clickTrackingUrl),
                                            ),
                                        };
                                    },
                                    false,
                                    'sendTeaserClick',
                                );
                            }
                        },
                        sendPlayerEvent: (stats, { action, frontendContextPlayer, apiContextPlayer }) => {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: addToEventsQueue(
                                                state.queue,
                                                playerEvent(stats, action, {
                                                    playerContext: frontendContextPlayer,
                                                    playerApiContext: apiContextPlayer,
                                                }),
                                            ),
                                        };
                                    },
                                    false,
                                    'sendPlayerEvent',
                                );
                            }
                        },
                        sendUserClickedEvent: (stats, action) => {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: addToEventsQueue(state.queue, userClickedEvent(stats, action)),
                                        };
                                    },
                                    false,
                                    'sendUserClickedEvent',
                                );
                            }
                        },
                    }),
                    { name: eventsStoreName, enabled: global.window && env === 'development' },
                ),
            ),
            { name: eventsStoreName, storage: customLocalStorage },
        ),
    );
};

export const eventStore = (env: string) => {
    if (!stores[env]) {
        stores[env] = eventStore2(env);
    }
    return stores[env];
};
