// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M11.5728 4.17813C11.0195 3.88795 10.5332 3.62618 10.5332 3.04519C10.5332 2.42131 10.0272 1.91528 9.40242 1.91528C8.77854 1.91528 8.27251 2.42131 8.27251 3.04519C8.27251 3.63287 7.77439 3.89394 7.21301 4.18817C7.04517 4.27613 6.87169 4.36706 6.70417 4.47057C6.70251 4.47167 6.70156 4.47277 6.70041 4.4741L6.6981 4.47663C4.83948 5.45143 3.57007 7.3967 3.57007 9.64178V12.1809L5.30306 10.4479V9.6418C5.30306 8.11418 6.14615 6.72259 7.50308 6.0112L7.57066 5.97568L7.63392 5.93495L7.66338 5.91589C7.76909 5.85324 7.87835 5.79626 7.98411 5.7411L8.01777 5.72353L8.02321 5.72067C8.38578 5.52985 8.96444 5.2253 9.40242 4.708C9.67603 5.03053 10.0037 5.27035 10.2995 5.45147L11.5728 4.17813ZM9.61706 15.066H14.2712C13.7747 14.5557 13.5018 13.8841 13.5018 13.1702V11.1813L15.2317 9.45138C15.2337 9.5146 15.2348 9.57807 15.2348 9.64178V13.1701C15.2348 13.4327 15.3396 13.6848 15.5242 13.8694L17.005 15.3502V16.799H7.8841L9.61706 15.066ZM11.2567 17.3908C11.2567 18.415 10.4266 19.2451 9.40242 19.2451C8.37823 19.2451 7.54813 18.415 7.54813 17.3908H11.2567Z",
                      fill: Particle_Color.toString(Particle_Color.Css.color(color)),
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("rect", {
                      height: "26.3167",
                      width: "1.75444",
                      fill: Particle_Color.toString(Particle_Color.Css.color(color)),
                      transform: "rotate(45 18.7593 0.160889)",
                      x: "18.7593",
                      y: "0.160889"
                    })
              ]
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
