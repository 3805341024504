// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M26.8911 6.50607L26.7414 6.33131C25.4486 4.85211 23.7164 4.04073 21.8758 4.04073C20.0291 4.04073 18.303 4.8571 17.015 6.33131L15.928 7.56211L14.8482 6.32632C13.5554 4.84711 11.828 4.03448 9.98135 4.03448C8.14078 4.03448 6.41339 4.85211 5.12057 6.32632L4.96598 6.50108C3.69874 7.94908 3 9.86893 3 11.8999C3 13.9146 3.68292 15.8132 4.92946 17.255L15.1732 28.6567C15.3705 28.8739 15.6443 29 15.9341 29C16.2177 29 16.4977 28.8739 16.6936 28.6567L26.8911 17.2974C28.1584 15.8494 28.8571 13.9308 28.8571 11.8999C28.8571 9.86893 28.1584 7.94908 26.8911 6.50607Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
