// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Util_Css from "../../../util/Util_Css.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as LegacyStyleOverwrite from "../../LegacyStyleOverwrite.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var position = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: {
          NAME: "px",
          VAL: 0
        },
        right: {
          NAME: "px",
          VAL: 0
        },
        left: {
          NAME: "px",
          VAL: 0
        }
      }
    });

function Template_Player$PlayerContainer(props) {
  return JsxRuntime.jsx(Atom_Box.make, {
              children: Caml_option.some(props.children),
              height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
              position: Caml_option.some(position),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var PlayerContainer = {
  position: position,
  make: Template_Player$PlayerContainer
};

var maxHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Css.vh(72));

function Template_Player(props) {
  var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, LegacyStyleOverwrite.Player.rules.concat(Util_Css.preserveAspectRatioRules("R16_9")));
  return JsxRuntime.jsx(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
              background: Caml_option.some(Particle_Background_Ds.playerPlaceholder),
              children: JsxRuntime.jsx(Template_Player$PlayerContainer, {
                    children: props.player
                  }),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
              maxHeight: Caml_option.some(maxHeight),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              rules: Caml_option.some(rules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var make = Template_Player;

export {
  PlayerContainer ,
  maxHeight ,
  make ,
}
/* position Not a pure module */
