'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../user/User.res.mjs";
import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as User_Type from "../user/User_Type.res.mjs";
import * as Util_Logger from "../util/Util_Logger.res.mjs";
import * as Core__Promise from "@rescript/core/src/Core__Promise.res.mjs";
import * as User_Provider from "../user/User_Provider.res.mjs";
import * as User_Api__Sso__ContinueWatching from "../user/User_Api/User_Api__Sso__ContinueWatching.res.mjs";

function make(id) {
  var controller = new AbortController();
  var signal = controller.signal;
  var match = Context.I18n.use();
  var locale = match.locale;
  var match$1 = React.useState(function () {
        return "Idle";
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var userStatus = User_Provider.Hook.useStatus();
  React.useEffect((function () {
          var user = User.get();
          var match = User_Type.getUserStatus(user);
          switch (userStatus) {
            case "Initialized" :
                var exit = 0;
                switch (match) {
                  case "Anonymous" :
                  case "Connected" :
                      exit = 1;
                      break;
                  case "Disconnected" :
                      break;
                  
                }
                if (exit === 1 && id !== undefined && typeof status !== "object" && status === "Idle") {
                  setStatus(function (param) {
                        return "Loading";
                      });
                  Core__Promise.$$catch(User_Api__Sso__ContinueWatching.get({
                              args: {
                                locale: locale,
                                programId: id
                              },
                              signal: signal,
                              locale: locale
                            }).then(function (nextEpisode) {
                            if (nextEpisode.TAG === "Ok") {
                              var episode = nextEpisode._0;
                              var exit = 0;
                              if (episode !== undefined && episode.totalEpisodes === 0) {
                                setStatus(function (param) {
                                      return {
                                              TAG: "Completed",
                                              _0: undefined
                                            };
                                    });
                              } else {
                                exit = 1;
                              }
                              if (exit === 1) {
                                setStatus(function (param) {
                                      return {
                                              TAG: "Completed",
                                              _0: episode
                                            };
                                    });
                              }
                              
                            } else {
                              setStatus(function (param) {
                                    return {
                                            TAG: "Completed",
                                            _0: undefined
                                          };
                                  });
                            }
                            return Promise.resolve();
                          }), (function (error) {
                          Util_Logger.error2("Can't get nextEpisode in UseNextEpisode", error);
                          setStatus(function (param) {
                                return {
                                        TAG: "Error",
                                        _0: error
                                      };
                              });
                          return Promise.resolve();
                        }));
                }
                break;
            case "NotInitialized" :
            case "ForceReInit" :
                break;
            
          }
        }), [
        userStatus,
        id,
        status,
        locale
      ]);
  return status;
}

export {
  make ,
}
/* User Not a pure module */
