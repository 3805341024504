// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as User_Type from "../../user/User_Type.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../../type/Type_Avatar.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Molecule_Avatar from "./Molecule_Avatar.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var titleInnerspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXXS");

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));

function make(param) {
  var teaserTitle = param.teaserTitle;
  var match = Context.I18n.use();
  var match$1 = match.labels.forMe;
  var stickerTitle = match$1.title;
  var sameAsTitle = match$1.sameAs;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var userAvatar = Core__Option.getOr(Core__Option.flatMap(userInfos, User_Type.Info.avatar), Type_Avatar.defaultAvatar);
  var title = React.useMemo((function () {
          if (teaserTitle !== undefined) {
            return JsxRuntime.jsxs(Atom_Row.make, {
                        children: [
                          JsxRuntime.jsx(Atom_Text.make, {
                                children: Caml_option.some(sameAsTitle),
                                style: Atom_Text_Ds.ForMe.stickerTitle
                              }),
                          JsxRuntime.jsx(Atom_Text.make, {
                                children: Caml_option.some(teaserTitle),
                                style: Atom_Text_Ds.ForMe.stickerSameAs
                              })
                        ],
                        innerSpace: Caml_option.some(titleInnerspace)
                      });
          } else {
            return JsxRuntime.jsx(Atom_Text.make, {
                        children: Caml_option.some(stickerTitle),
                        style: Atom_Text_Ds.ForMe.stickerTitle
                      });
          }
        }), [teaserTitle]);
  return JsxRuntime.jsx(Atom_Cell.make, {
              children: JsxRuntime.jsxs(Atom_Row.make, {
                    alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                    background: Caml_option.some(Particle_Background_Ds.avatarSticker),
                    blockSpace: Caml_option.some(blockSpace),
                    children: [
                      JsxRuntime.jsx(Molecule_Avatar.make, {
                            avatar: userAvatar,
                            style: "Sticker"
                          }),
                      title
                    ],
                    innerSpace: Caml_option.some(innerSpace)
                  })
            });
}

export {
  titleInnerspace ,
  innerSpace ,
  blockSpace ,
  make ,
}
/* titleInnerspace Not a pure module */
