// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("rect", {
                      height: "24",
                      width: "2.13333",
                      x: "6",
                      y: "4.00757"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M25.1922 26.9896C25.5477 27.2029 26 26.9469 26 26.5323L26 4.73123C26 4.31665 25.5477 4.06059 25.1922 4.27393L7.02867 15.1745C6.68348 15.3816 6.68348 15.8819 7.02867 16.0891L25.1922 26.9896Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
