// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Logo_Ds from "./Atom_Logo_Ds.res.mjs";
import * as Atom_Logo_Css from "./Atom_Logo_Css.res.mjs";
import * as Atom_Logo_Path from "./Atom_Logo_Path.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function Atom_Logo(props) {
  var logo = props.logo;
  var dsLogo = Atom_Logo_Ds.makeLogo(logo);
  var match = Atom_Logo_Ds.view(dsLogo);
  var match$1 = Atom_Logo_Css.toCss(dsLogo);
  var wrapperRule = match$1.wrapper;
  var svgClassName = (function (__x) {
        return CssJs.merge(__x);
      })(match$1.svg.map(function (rule) {
            var __x = [rule];
            return CssJs.style(__x);
          }));
  var exec = function (_logo) {
    while(true) {
      var logo = _logo;
      if (typeof logo !== "object") {
        return Atom_Logo_Path.getPath(logo);
      }
      if (logo.NAME !== "WithWrapper") {
        return Atom_Logo_Path.getPath(logo);
      }
      _logo = logo.VAL[0];
      continue ;
    };
  };
  var svg = JsxRuntime.jsx("svg", {
        children: exec(logo),
        "aria-hidden": true,
        className: svgClassName,
        focusable: "false",
        viewBox: match.viewBox,
        xmlns: "http://www.w3.org/2000/svg"
      });
  var position = typeof logo === "object" && logo.NAME === "WithWrapper" ? Caml_option.some(Particle_Screen_Constants.positionRelative) : undefined;
  if (wrapperRule !== undefined) {
    return JsxRuntime.jsx(Atom_Box.make, {
                children: Caml_option.some(svg),
                position: position,
                rules: Caml_option.some(Caml_option.valFromOption(wrapperRule))
              });
  } else {
    return svg;
  }
}

var make = Atom_Logo;

export {
  make ,
}
/* CssJs Not a pure module */
