// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M27.3529 16C27.3529 13.5989 26.5989 11.3746 25.3223 9.5377L22.4922 12.5251C22.305 11.7269 21.7408 10.2449 20.121 9.2101L22.4896 6.6985C20.6488 5.4102 18.4128 4.6471 16 4.6471C9.7392 4.6471 4.6471 9.7405 4.6471 16C4.6471 18.5467 5.5012 20.8919 6.9221 22.7886L11.671 17.8486L15.1875 19.9923L9.8536 25.5316C11.6268 26.6782 13.7341 27.3529 16 27.3529C22.2608 27.3529 27.3516 22.2595 27.3529 16ZM29 16C29 23.1695 23.1682 29 16 29C8.8318 29 3 23.1695 3 16C3 8.8305 8.8318 3 16 3C23.1682 3 29 8.8305 29 16ZM14.7767 24.6814C15.1069 24.6814 15.4215 24.6242 15.7205 24.5111C16.8242 24.0873 17.4196 22.9953 17.7251 22.1542C18.2191 20.7866 18.5545 20.4993 19.1096 20.0248C19.4554 19.7284 19.887 19.3592 20.4356 18.665C21.9397 16.754 21.6888 14.9236 21.6823 14.9184C21.6823 11.6593 19.0316 9.0086 15.7725 9.0086C13.6041 9.0086 10.7675 9.7587 9.5611 13.3324C9.4753 13.5885 9.6118 13.8641 9.8679 13.9512C10.124 14.037 10.3996 13.8992 10.4867 13.6457C11.3057 11.216 13.0854 9.9849 15.7738 9.9849C18.4947 9.9849 20.7086 12.1988 20.7138 14.9886C20.7216 15.0497 20.9023 16.4979 19.6712 18.0618C19.1811 18.6819 18.8067 19.0017 18.4765 19.2851C17.8707 19.8038 17.3923 20.2133 16.8086 21.8214C16.5733 22.4727 16.1326 23.3086 15.3734 23.5985C14.8183 23.8104 14.1371 23.7038 13.3506 23.2813C13.1101 23.1526 12.8163 23.241 12.6902 23.4789C12.5628 23.7168 12.6512 24.0119 12.8878 24.1393C13.5586 24.4994 14.1904 24.6814 14.7767 24.6814ZM18.1515 17.3715C18.067 17.3715 17.9799 17.3494 17.9019 17.3026C17.6705 17.1648 17.5964 16.8645 17.7329 16.6331C18.5051 15.3409 18.0839 13.66 16.7917 12.8878C15.4995 12.1143 13.8199 12.5381 13.0477 13.8303C12.9099 14.0591 12.6109 14.1345 12.3795 13.9967C12.1494 13.8589 12.074 13.5599 12.2118 13.3285C13.2609 11.5774 15.5411 11.0041 17.2935 12.0519C19.0446 13.1023 19.6179 15.3825 18.5714 17.1349C18.4791 17.287 18.3166 17.3715 18.1515 17.3715Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
