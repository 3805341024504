'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function createRootElement(id) {
  var $$document = Util_Dom.SafeWebApi.$$document;
  if ($$document === undefined) {
    return ;
  }
  var rootContainer = Caml_option.valFromOption($$document).createElement("div");
  rootContainer.setAttribute("id", id);
  return Caml_option.some(rootContainer);
}

function addRootElement(rootElement) {
  var match = Util_Dom.SafeWebApi.$$document;
  if (rootElement === undefined) {
    return ;
  }
  if (match === undefined) {
    return ;
  }
  var body = Caml_option.valFromOption(match).querySelector("body");
  if (!(body == null)) {
    body.appendChild(Caml_option.valFromOption(rootElement));
    return ;
  }
  
}

function make(id) {
  var rootElementRef = React.useRef(undefined);
  React.useEffect((function () {
          var existingTarget = Core__Option.flatMap(Util_Dom.SafeWebApi.$$document, (function ($$document) {
                  return Caml_option.nullable_to_opt($$document.querySelector("#" + id));
                }));
          var target;
          if (existingTarget !== undefined) {
            target = Caml_option.some(Caml_option.valFromOption(existingTarget));
          } else {
            var rootElement = createRootElement(id);
            addRootElement(rootElement);
            target = rootElement;
          }
          rootElementRef.current = target;
        }), [
        id,
        rootElementRef
      ]);
  var current = rootElementRef.current;
  if (current !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(current));
  }
  
}

export {
  createRootElement ,
  addRootElement ,
  make ,
}
/* react Not a pure module */
