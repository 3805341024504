// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Grid from "../atom/Atom_Grid.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as User_Type from "../../user/User_Type.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../../type/Type_Avatar.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Molecule_Avatar from "../molecule/Molecule_Avatar.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as User_Api__Sso__Me from "../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var rules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.gridTemplateColumns([{
              NAME: "repeat",
              VAL: [
                "autoFill",
                {
                  NAME: "minmax",
                  VAL: [
                    {
                      NAME: "px",
                      VAL: 195
                    },
                    "auto"
                  ]
                }
              ]
            }])], undefined, undefined, [
      CssJs.display("grid"),
      CssJs.gridTemplateColumns([{
              NAME: "repeat",
              VAL: [
                {
                  NAME: "num",
                  VAL: 2
                },
                "auto"
              ]
            }])
    ]);

var gridBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeTop("M"), undefined, undefined, Particle_Spacer_Ds.makeHorizontal("L"));

var titleBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("L"), undefined, Particle_Spacer_Constants.allZero);

var griGap = Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S");

function handleAvatarClick(avatar, userAvatar, locale) {
  var controller = new AbortController();
  var signal = controller.signal;
  var isAlreadySelected = avatar.id === userAvatar.id ? "Selected" : "NotSelected";
  if (isAlreadySelected === "Selected") {
    return ;
  }
  User_Api__Sso__Me.Avatar.update({
          args: {
            avatarId: avatar.id
          },
          signal: signal,
          locale: locale
        }).then(function (param) {
        User_Observable.UserInfo.updateAvatar(avatar);
      });
}

function Organism_Avatar_List(props) {
  var match = Context.I18n.use();
  var locale = match.locale;
  var match$1 = match.labels.mona.profile;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var userAvatar = Core__Option.getOr(Core__Option.flatMap(userInfos, User_Type.Info.avatar), Type_Avatar.defaultAvatar);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      blockSpace: Caml_option.some(titleBlockSpace),
                      children: Caml_option.some(match$1.avatarListTitle),
                      kind: "h1",
                      style: Atom_Text_Ds.User.title("Auth")
                    }),
                JsxRuntime.jsx(Atom_Grid.make, {
                      blockSpace: Caml_option.some(gridBlockSpace),
                      children: props.avatarList.map(function (avatar) {
                            var selectedStatus = avatar.id === userAvatar.id ? "Selected" : "NotSelected";
                            return JsxRuntime.jsx(Molecule_Avatar.make, {
                                        avatar: avatar,
                                        style: {
                                          TAG: "List",
                                          _0: selectedStatus
                                        },
                                        selected: selectedStatus,
                                        onClick: (function (param) {
                                            handleAvatarClick(avatar, userAvatar, locale);
                                          })
                                      }, avatar.id);
                          }),
                      gap: Caml_option.some(griGap),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      rules: Caml_option.some(rules)
                    })
              ],
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var make = Organism_Avatar_List;

export {
  rules ,
  gridBlockSpace ,
  titleBlockSpace ,
  griGap ,
  handleAvatarClick ,
  make ,
}
/* rules Not a pure module */
