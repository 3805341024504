// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1711 8.83456 29 16 29C23.1711 29 29 23.1711 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.83456 3 16ZM4.47149 16C4.47149 9.64544 9.64549 4.47144 16.0001 4.47144C22.3546 4.47144 27.5286 9.64544 27.5286 16C27.5286 22.3594 22.3546 27.5286 16.0001 27.5286C9.64061 27.5286 4.47149 22.3594 4.47149 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M8.8548 12.4226H10.4562V15.3037H13.4097V12.4226H15.0111V19.7026H13.4097V16.78H10.4562V19.7026H8.8548V12.4226V12.4226Z"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M19.5977 12.4226H16.7589V19.7026H19.5977C21.8857 19.7026 23.4669 18.1117 23.4669 16.0626V16.0415C23.4669 13.9932 21.8857 12.4226 19.5977 12.4226ZM18.3611 18.2572V13.868H19.5985C20.9091 13.868 21.7931 14.7732 21.7931 16.0626V16.0837C21.7931 17.3732 20.9091 18.2572 19.5985 18.2572H18.3611Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
