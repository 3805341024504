// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "./Atom_Cta.res.mjs";
import * as Atom_Cta_Ds from "./Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Progression from "../../type/Type_Progression.res.mjs";
import * as UseClickTracking from "../../hook/UseClickTracking.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var identifier = Identifiers.NextEpisode.item;

function Atom_NextEpisodeButton(props) {
  var __availability = props.availability;
  var nextEpisode = props.nextEpisode;
  var availability = __availability !== undefined ? __availability : "NotAvailable";
  var match = Context.I18n.use();
  var season = nextEpisode.season;
  var progress = Core__Option.getOr(Type_Progression.getPct(nextEpisode.progression), 0);
  var style = Atom_Cta_Ds.makeNextEpisode(progress);
  var clickTracking = UseClickTracking.use();
  if (availability !== "Available") {
    return null;
  }
  if (season === undefined) {
    return null;
  }
  var label = match.formaters.nextEpisode({
        progress: progress,
        season: season,
        episode: nextEpisode.episode,
        totalEpisodes: nextEpisode.totalEpisodes
      });
  return JsxRuntime.jsx(Atom_Cta.Link.make, {
              href: Type_String.Href.toString(nextEpisode.href),
              identifier: Caml_option.some(identifier),
              label: label,
              onClick: (function (param) {
                  clickTracking({
                        eventGroup: "collection_actions",
                        eventName: "WATCH_CONTENT"
                      });
                }),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              style: style
            });
}

var make = Atom_NextEpisodeButton;

export {
  identifier ,
  make ,
}
/* Context Not a pure module */
