// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Atom_Row from "./Atom_Row.res.mjs";
import * as Atom_Cell from "./Atom_Cell.res.mjs";
import * as Atom_Icon from "./Atom_Icon.res.mjs";
import * as Atom_Text from "./Atom_Text.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Switch_Css from "./Atom_Switch_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.cursor("pointer")]);

function Atom_Switch$Internal$Label(props) {
  var label = props.label;
  if (label !== undefined) {
    return JsxRuntime.jsx(Atom_Cell.make, {
                kind: "Span",
                children: JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(label),
                      kind: "span",
                      style: props.textStyle
                    }),
                rules: Caml_option.some(rules)
              });
  } else {
    return null;
  }
}

var Label = {
  rules: rules,
  make: Atom_Switch$Internal$Label
};

var positionForeground = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: CssJs.pct(50)
      }
    });

function Atom_Switch$Internal(props) {
  var justifyContent = props.justifyContent;
  var style = props.style;
  var initialState = props.initialState;
  var onChange = props.onChange;
  var id = props.id;
  var labelSecondary = props.labelSecondary;
  var label = props.label;
  var match = Core__Option.mapOr(props.accessibility, Particle_Accessibility.defaultView, Particle_Accessibility.view);
  var labelTextStyle = style.labelTextStyle;
  var handleOnOff = function () {
    onChange(false);
  };
  var handleOnOn = function () {
    onChange(true);
  };
  var initialState$1 = React.useMemo((function () {
          return initialState;
        }), [initialState]);
  var match$1 = UseToggle.make(initialState$1, handleOnOn, handleOnOff, undefined, undefined, undefined);
  var toggleRaw = match$1.toggleRaw;
  var innerSpace = label !== undefined || labelSecondary !== undefined ? Caml_option.some(Particle_Screen_Constants.xsInnerSpace) : undefined;
  var displayLabel = JsxRuntime.jsx(Atom_Switch$Internal$Label, {
        label: label,
        textStyle: labelTextStyle
      });
  var displayLabelOpt = JsxRuntime.jsx(Atom_Switch$Internal$Label, {
        label: labelSecondary,
        textStyle: labelTextStyle
      });
  var match$2 = Atom_Switch_Css.toCss(style, match$1.state);
  var handleOnChange = React.useCallback((function (param) {
          toggleRaw();
        }), []);
  var input = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          JsxRuntime.jsx("input", {
                "aria-describedby": match.ariaDescribedby,
                "aria-labelledby": match.ariaLabelledby,
                id: id,
                role: "switch",
                checked: match$1.stateBoolean,
                type: "checkbox",
                onChange: handleOnChange
              }),
          JsxRuntime.jsxs(Atom_Box.make, {
                children: [
                  JsxRuntime.jsx(Atom_Box.make, {
                        kind: "Span",
                        rules: Caml_option.some(match$2.background)
                      }),
                  JsxRuntime.jsx(Atom_Box.make, {
                        children: Caml_option.some(JsxRuntime.jsx(Atom_Icon.make, {
                                  icon: match$2.icon,
                                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")
                                })),
                        kind: "Span",
                        position: Caml_option.some(positionForeground),
                        rules: Caml_option.some(match$2.foreground)
                      })
                ],
                kind: "Span"
              })
        ]
      });
  var display = justifyContent !== undefined ? undefined : Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex"));
  var match$3 = style.labelPosition;
  var tmp;
  switch (match$3) {
    case "Before" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                displayLabel,
                input
              ]
            });
        break;
    case "After" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                input,
                displayLabel
              ]
            });
        break;
    case "Both" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                displayLabel,
                input,
                displayLabelOpt
              ]
            });
        break;
    
  }
  return JsxRuntime.jsx(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              children: tmp,
              display: display,
              innerSpace: innerSpace,
              justifyContent: justifyContent,
              kind: {
                TAG: "Label",
                _0: id
              },
              rules: Caml_option.some(match$2.parent),
              wrap: "OnOneLine"
            });
}

var Internal = {
  Label: Label,
  positionForeground: positionForeground,
  make: Atom_Switch$Internal
};

function Atom_Switch(props) {
  return JsxRuntime.jsx(Atom_Switch$Internal, {
              label: props.label,
              id: props.id,
              onChange: props.onChange,
              initialState: props.initialState,
              style: props.style,
              accessibility: props.accessibility,
              justifyContent: props.justifyContent
            });
}

function Atom_Switch$Both(props) {
  return JsxRuntime.jsx(Atom_Switch$Internal, {
              label: props.label,
              labelSecondary: props.labelSecondary,
              id: props.id,
              onChange: props.onChange,
              initialState: props.initialState,
              style: props.style,
              accessibility: props.accessibility,
              justifyContent: props.justifyContent
            });
}

var Both = {
  make: Atom_Switch$Both
};

var make = Atom_Switch;

export {
  Internal ,
  make ,
  Both ,
}
/* rules Not a pure module */
