// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Atom_Checkbox_Ds from "../../atom/Atom_Checkbox_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function make(style) {
  if (style === "Footer") {
    return {
            blockSpace: Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("Zero"), undefined, undefined, Particle_Spacer_Ds.make("S", "None", "S", "None")),
            buttonStyle: Atom_Cta_Ds.Footer.newsletter,
            buttonWidth: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "auto"),
            displayButtonOrder: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 0),
            checkboxCtaStyle: Atom_Cta_Ds.Footer.tos,
            checkboxStyle: Atom_Checkbox_Ds.makeFooter,
            feedbackBlockSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS")),
            textfieldAutoSize: Particle_Screen_Constants.autoSizeAllPlaceAvailable,
            textfieldBlockSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone()),
            textfieldInnerSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"),
            textfieldStyle: "FooterNewsletter",
            titleKind: "h2",
            titleMaxWidth: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100)),
            titleStyle: Atom_Text_Ds.Footer.title,
            width: Particle_Screen_Ds.make(undefined, undefined, undefined, Css.pct(56), undefined, CssJs.pct(100))
          };
  } else {
    return {
            blockSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone()),
            buttonStyle: Atom_Cta_Ds.NewsletterZone.cta,
            buttonWidth: Particle_Screen_Ds.make(undefined, undefined, "auto", undefined, undefined, Css.pct(100)),
            displayButtonOrder: Particle_Screen_Ds.make(undefined, undefined, 0, undefined, undefined, 2),
            checkboxCtaStyle: Atom_Cta_Ds.NewsletterZone.tos,
            checkboxStyle: Atom_Checkbox_Ds.makeNewsletterZone,
            feedbackBlockSpace: Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("XXS", "Zero", "Zero", "Zero"), undefined, undefined, Particle_Spacer_Ds.makeBottom("XS")),
            textfieldAutoSize: Particle_Screen_Constants.autoSizeAllPlaceNeeded,
            textfieldBlockSpace: Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("None", "XXS", "None", "S"), undefined, undefined, Particle_Spacer_Ds.make("None", "S", "None", "None")),
            textfieldInnerSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "None"),
            textfieldStyle: "NewsletterZone",
            titleKind: "h2",
            titleMaxWidth: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, Css.pct(70), CssJs.pct(100)),
            titleStyle: Atom_Text_Ds.NewsletterZone.title,
            width: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100))
          };
  }
}

export {
  make ,
}
/* Css Not a pure module */
