'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Belt_MutableMap from "@rescript/std/lib/es6/belt_MutableMap.js";

function handleClickOutside(domElement, $$event, fn) {
  var targetElement = $$event.target;
  var targetElement$1 = $$event.composed ? Core__Option.mapOr(Util_Array.getFirstElement($$event.composedPath()), targetElement, (function (prim) {
            return prim;
          })) : targetElement;
  if (domElement.contains(targetElement$1)) {
    return ;
  } else {
    return fn.current();
  }
}

function cmp(a, b) {
  if (a !== b) {
    return 1;
  } else if (a === b) {
    return 0;
  } else {
    return -1;
  }
}

var RefCmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var refs = Belt_MutableMap.make(RefCmp);

function handleMouseDown(e) {
  Belt_MutableMap.forEach(refs, (function (ref, onClickOutside) {
          Core__Option.forEach(Util_Dom.getDomRef(ref), (function (refValue) {
                  handleClickOutside(refValue, e, onClickOutside);
                }));
        }));
}

function make(ref, onClickOutside) {
  var onClickOutsideRef = React.useRef(function () {
        
      });
  React.useEffect((function () {
          onClickOutsideRef.current = onClickOutside;
        }), [onClickOutside]);
  React.useEffect((function () {
          if (Belt_MutableMap.isEmpty(refs)) {
            document.addEventListener("mousedown", handleMouseDown);
          }
          Belt_MutableMap.set(refs, ref, onClickOutsideRef);
          return (function () {
                    Belt_MutableMap.remove(refs, ref);
                    if (Belt_MutableMap.isEmpty(refs)) {
                      document.removeEventListener("mousedown", handleMouseDown);
                      return ;
                    }
                    
                  });
        }), [
        ref,
        onClickOutsideRef
      ]);
}

export {
  handleClickOutside ,
  RefCmp ,
  refs ,
  handleMouseDown ,
  make ,
}
/* RefCmp Not a pure module */
