'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as ReactIntersectionObserver from "react-intersection-observer";

function useInView(root, thresholdOpt, onChange, param) {
  var threshold = thresholdOpt !== undefined ? thresholdOpt : [
      0,
      0.25,
      0.50,
      0.75,
      0.99
    ];
  var match = React.useState(function () {
        
      });
  var setRootElt = match[1];
  var isMount = React.useRef(true);
  React.useEffect((function () {
          var rootElt = Core__Option.flatMap(root, Util_Dom.getDomRef);
          if (isMount.current === true) {
            setRootElt(function (param) {
                  return rootElt;
                });
          }
          return (function () {
                    isMount.current = false;
                  });
        }), [root]);
  return ReactIntersectionObserver.useInView({
              root: match[0],
              threshold: threshold,
              onChange: onChange
            });
}

function makeCallback(time, callback) {
  if (time === "Once") {
    return {
            TAG: "Once",
            _0: callback,
            _1: {
              contents: false
            }
          };
  } else {
    return {
            TAG: "Always",
            _0: callback
          };
  }
}

function make(startingToBeVisible, leaving, whenFullVisible, whenNotVisibleAnymore, param) {
  return {
          startingToBeVisible: startingToBeVisible,
          leaving: leaving,
          fullVisible: whenFullVisible,
          notVisibleAnymore: whenNotVisibleAnymore
        };
}

function triggerCallback(callback, status) {
  if (callback === undefined) {
    return ;
  }
  var cb;
  var called;
  if (typeof status !== "object") {
    if (status === "FullVisible") {
      var match = callback.fullVisible;
      if (match === undefined) {
        return ;
      }
      if (match.TAG !== "Once") {
        return match._0();
      }
      var called$1 = match._1;
      var match$1 = called$1.contents;
      if (match$1) {
        return ;
      }
      cb = match._0;
      called = called$1;
    } else {
      var match$2 = callback.notVisibleAnymore;
      if (match$2 === undefined) {
        return ;
      }
      if (match$2.TAG !== "Once") {
        return match$2._0();
      }
      var called$2 = match$2._1;
      var match$3 = called$2.contents;
      if (match$3) {
        return ;
      }
      cb = match$2._0;
      called = called$2;
    }
  } else if (status.TAG === "Entering") {
    var match$4 = callback.startingToBeVisible;
    if (match$4 === undefined) {
      return ;
    }
    if (match$4.TAG !== "Once") {
      return match$4._0();
    }
    var called$3 = match$4._1;
    var match$5 = called$3.contents;
    if (match$5) {
      return ;
    }
    cb = match$4._0;
    called = called$3;
  } else {
    var match$6 = callback.leaving;
    if (match$6 === undefined) {
      return ;
    }
    if (match$6.TAG !== "Once") {
      return match$6._0();
    }
    var called$4 = match$6._1;
    var match$7 = called$4.contents;
    if (match$7) {
      return ;
    }
    cb = match$6._0;
    called = called$4;
  }
  cb();
  called.contents = true;
}

var Callback = {
  makeCallback: makeCallback,
  make: make,
  triggerCallback: triggerCallback
};

function getNewStatus(isVisible, previousStatus, newRatio) {
  if (!isVisible) {
    return "NotVisible";
  }
  if (typeof previousStatus !== "object") {
    if (previousStatus === "FullVisible") {
      if (newRatio < 0.99) {
        return {
                TAG: "Leaving",
                _0: newRatio
              };
      } else {
        return "FullVisible";
      }
    } else if (newRatio > 0.99) {
      return "FullVisible";
    } else {
      return {
              TAG: "Entering",
              _0: newRatio
            };
    }
  }
  previousStatus.TAG === "Entering";
  if (newRatio > 0.99) {
    return "FullVisible";
  }
  if (typeof previousStatus === "object") {
    if (previousStatus.TAG === "Entering") {
      if (previousStatus._0 > newRatio) {
        return {
                TAG: "Leaving",
                _0: newRatio
              };
      } else {
        return {
                TAG: "Entering",
                _0: newRatio
              };
      }
    } else if (previousStatus._0 < newRatio) {
      return {
              TAG: "Entering",
              _0: newRatio
            };
    } else {
      return {
              TAG: "Leaving",
              _0: newRatio
            };
    }
  }
  
}

function make$1(root, callback, param) {
  var match = React.useState(function () {
        return "NotVisible";
      });
  var setCurrentStatus = match[1];
  var currentStatus = match[0];
  var callbackRef = React.useRef(callback);
  callbackRef.current = callback;
  var isMount = React.useRef(false);
  React.useEffect((function () {
          isMount.current = true;
          return (function () {
                    isMount.current = false;
                  });
        }), []);
  React.useEffect((function () {
          var match = isMount.current;
          var match$1 = callbackRef.current;
          if (match && match$1 !== undefined) {
            triggerCallback(match$1, currentStatus);
          }
          
        }), [
        currentStatus,
        callbackRef
      ]);
  var onChange = function (isVisible, entry) {
    var newRatio = entry.intersectionRatio;
    setCurrentStatus(function (previousStatus) {
          return getNewStatus(isVisible, previousStatus, newRatio);
        });
  };
  var match$1 = useInView(root, undefined, onChange, undefined);
  return {
          cbRef: match$1.ref,
          status: currentStatus
        };
}

export {
  Callback ,
  getNewStatus ,
  make$1 as make,
}
/* react Not a pure module */
