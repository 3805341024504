import { ReactElement } from 'react';
import Head from 'next/head';

import pkg from '../../../package.json';

const versionPlayer = pkg.dependencies['@artegeie/arte-vp'];

export const PlayerStyle = (): ReactElement => {
    return (
        <Head>
            <link
                key="player-css-preload"
                rel="preload"
                href={`//static-cdn.arte.tv/static/artevp/${versionPlayer}/css/arte.css`}
                as="style"
            />
            <link
                key="player-css"
                rel="stylesheet"
                type="text/css"
                href={`//static-cdn.arte.tv/static/artevp/${versionPlayer}/css/arte.css`}
            />
        </Head>
    );
};
