export type ssoEnv = 'prod' | 'preprod' | 'dev';

export const getBaseUrl = (env: ssoEnv) => {
    switch (env) {
        case 'prod':
            return 'https://auth.arte.tv';
        case 'preprod':
            return 'https://auth-preprod.arte.tv';
        case 'dev':
            return 'https://auth-dev.arte.tv';
        default:
            return 'https://auth.arte.tv';
    }
};

export const setCookie = async (env: ssoEnv, token: string) => {
    const response = await fetch(
        `${getBaseUrl(env)}/ssologin/setCustomToken?shouldValidateAnonymous=true&token=` + token,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        },
    );
    return response;
};

export const deleteCookie = async (env: ssoEnv) => {
    const response = await fetch(`${getBaseUrl(env)}/ssologin/logout`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    return response;
};
