// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M31.2143 18.8571C31.2143 22.3052 30.0397 25.4789 28.0688 28H4.6455C2.6746 25.4789 1.5 22.3052 1.5 18.8571C1.5 10.6518 8.15177 4 16.3571 4C24.5625 4 31.2143 10.6518 31.2143 18.8571ZM22.2997 11.543L23.916 13.1592L19.7239 17.3513C19.9465 17.8058 20.0714 18.3169 20.0714 18.8571C20.0714 20.7507 18.5364 22.2857 16.6428 22.2857C14.7493 22.2857 13.2143 20.7507 13.2143 18.8571C13.2143 16.9636 14.7493 15.4286 16.6428 15.4286C17.1612 15.4286 17.6527 15.5436 18.0932 15.7495L22.2997 11.543Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
