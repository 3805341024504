// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Image_Ds from "../../atom/Atom_Image_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Slider_Css from "./Organism_Slider_Css.res.mjs";

function getWidthAndHeight(image, screenSize) {
  var image$1 = Atom_Image_Ds.view(Atom_Image_Ds.make(image));
  var match = Particle_Screen_Ds.getValueByScreenSize(image$1.display, screenSize);
  if (match === undefined) {
    return ;
  }
  if (typeof match !== "object") {
    return ;
  }
  if (match.TAG !== "Fixed") {
    return ;
  }
  var match$1 = match._0;
  return {
          width: match$1.width,
          height: match$1.height
        };
}

var default_arrowPosition = Particle_Screen_Ds.make(undefined, undefined, -Organism_Slider_Css.halfButtonNavSize | 0, undefined, undefined, -Organism_Slider_Css.mobilePosition | 0);

var $$default = {
  arrowPosition: default_arrowPosition,
  scrollSnapType: undefined,
  itemSelectedPosition: "Left",
  refreshItemPosition: "OnMount"
};

var guide_arrowPosition = Particle_Screen_Ds.make(undefined, undefined, 10, undefined, undefined, 1);

var guide_scrollSnapType = [
  "X",
  "Mandatory"
];

var guide = {
  arrowPosition: guide_arrowPosition,
  scrollSnapType: guide_scrollSnapType,
  itemSelectedPosition: "Center",
  refreshItemPosition: "OnSelectedChange"
};

function view(param) {
  return {
          arrowPosition: param.arrowPosition,
          scrollSnapType: param.scrollSnapType,
          itemSelectedPosition: param.itemSelectedPosition,
          refreshItemPosition: param.refreshItemPosition
        };
}

export {
  getWidthAndHeight ,
  $$default as default,
  guide ,
  view ,
}
/* default Not a pure module */
