// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Molecule_DangerousHtml_Ds from "./Molecule_DangerousHtml_Ds.res.mjs";
import * as Molecule_DangerousHtml_Css from "./Molecule_DangerousHtml_Css.res.mjs";

function make(param) {
  var rules = Molecule_DangerousHtml_Css.toCss(param.variant);
  return JsxRuntime.jsx(Atom_Box.make, {
              display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.block)),
              id: param.id,
              kind: {
                TAG: "DangerouslySetInnerHTML",
                _0: param.dangerousHtml
              },
              rules: Caml_option.some(rules)
            });
}

var Internal = {
  make: make
};

function make$1(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsx(make, {
              variant: Molecule_DangerousHtml_Ds.androidReleases(color),
              id: param.id,
              dangerousHtml: param.dangerousHtml
            });
}

var AndroidReleases = {
  make: make$1
};

function make$2(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsx(make, {
              variant: Molecule_DangerousHtml_Ds.papa(color),
              id: param.id,
              dangerousHtml: param.dangerousHtml
            });
}

var Papa = {
  make: make$2
};

function make$3(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsx(make, {
              variant: Molecule_DangerousHtml_Ds.presse(color),
              id: param.id,
              dangerousHtml: param.dangerousHtml
            });
}

var Presse = {
  make: make$3
};

function make$4(param) {
  var variant = Core__Option.getOr(param.variant, Molecule_DangerousHtml_Ds.default);
  return JsxRuntime.jsx(make, {
              variant: variant,
              id: param.id,
              dangerousHtml: param.dangerousHtml
            });
}

export {
  Internal ,
  AndroidReleases ,
  Papa ,
  Presse ,
  make$4 as make,
}
/* CssJs Not a pure module */
