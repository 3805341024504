// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Accessibility from "./Particle_Accessibility.res.mjs";

var ariaHidden = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

export {
  ariaHidden ,
}
/* ariaHidden Not a pure module */
