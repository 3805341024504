// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Color from "../../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("rect", {
                      height: "33",
                      width: "21",
                      fill: Particle_Color.toString(Particle_Color.Css.color("Background")),
                      rx: "4.5",
                      stroke: Particle_Color.toString(Particle_Color.Css.color("Text")),
                      strokeWidth: "3",
                      x: "15.166",
                      y: "9.5"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M24.099 25.1218L22.3418 26.8035C21.7305 27.3885 20.7372 27.3885 20.1249 26.8035C19.5126 26.2184 19.5136 25.2678 20.1249 24.6818L24.5577 20.4395C25.1701 19.8535 26.1623 19.8535 26.7747 20.4395L31.2075 24.6818C31.8188 25.2669 31.8188 26.2175 31.2075 26.8035C30.5962 27.3895 29.6029 27.3885 28.9916 26.8035L27.2334 25.1218V32.0002C27.2334 32.8283 26.5315 33.5 25.6662 33.5C24.8009 33.5 24.099 32.8283 24.099 32.0002V25.1218Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M0.938728 14.2556C2.64758 14.6962 3.97096 16.034 4.41149 17.7289C4.53583 18.1838 4.93551 18.5 5.4169 18.5C5.89829 18.5 6.29797 18.1838 6.42232 17.7289C6.86285 16.0198 8.20044 14.6962 9.89508 14.2556C10.3498 14.1312 10.666 13.7315 10.666 13.25C10.666 12.7685 10.3498 12.3688 9.89508 12.2444C8.18623 11.8038 6.85042 10.4802 6.40811 8.77107C6.29797 8.31624 5.88408 8 5.41513 8C4.94617 8 4.53406 8.31624 4.40971 8.77107C3.96918 10.4802 2.63159 11.8038 0.936951 12.2444C0.482206 12.3688 0.166016 12.7685 0.166016 13.25C0.166016 13.7315 0.482206 14.1312 0.936951 14.2556H0.938728Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M41.4387 38.2556C43.1476 38.6962 44.471 40.034 44.9115 41.7289C45.0358 42.1838 45.4355 42.5 45.9169 42.5C46.3983 42.5 46.798 42.1838 46.9223 41.7289C47.3629 40.0198 48.7004 38.6962 50.3951 38.2556C50.8498 38.1312 51.166 37.7315 51.166 37.25C51.166 36.7685 50.8498 36.3688 50.3951 36.2444C48.6862 35.8038 47.3504 34.4802 46.9081 32.7711C46.798 32.3162 46.3841 32 45.9151 32C45.4462 32 45.0341 32.3162 44.9097 32.7711C44.4692 34.4802 43.1316 35.8038 41.437 36.2444C40.9822 36.3688 40.666 36.7685 40.666 37.25C40.666 37.7315 40.9822 38.1312 41.437 38.2556H41.4387Z"
                    })
              ]
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
