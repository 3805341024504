// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Core__Int from "@rescript/core/src/Core__Int.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Pattern from "./Type_Pattern.res.mjs";
import * as Type_SchemaI18n from "./Type_SchemaI18n.res.mjs";

function Make(Lenses) {
  var getFieldName = function (field) {
    return field._0;
  };
  var schema = function (validations) {
    return validations.flat();
  };
  var mergeValidators = function (validations) {
    return Core__Array.reduce(validations, [], (function (rules, param) {
                  var rule = param[0];
                  if (rule !== undefined) {
                    rules.push(param[1](Caml_option.valFromOption(rule)));
                  }
                  return rules;
                }));
  };
  var confirmation = function (error, field1, field2) {
    return [{
              TAG: "Confirmation",
              field1: field1,
              field2: field2,
              error: error
            }];
  };
  var email = function (field) {
    return [{
              TAG: "Email",
              field: field,
              error: undefined
            }];
  };
  var date = function (field) {
    return [{
              TAG: "Date",
              field: field,
              error: undefined
            }];
  };
  var nonEmpty = function (field) {
    return [{
              TAG: "StringNonEmpty",
              field: field,
              error: undefined
            }];
  };
  var eitherNonEmpty = function (field1, field2) {
    return [{
              TAG: "EitherStringNonEmpty",
              field1: field1,
              field2: field2,
              error: undefined
            }];
  };
  var string = function (min, minError, max, maxError, field) {
    return mergeValidators([
                [
                  min,
                  (function (min) {
                      return {
                              TAG: "StringMin",
                              field: field,
                              min: min,
                              error: minError
                            };
                    })
                ],
                [
                  max,
                  (function (max) {
                      return {
                              TAG: "StringMax",
                              field: field,
                              max: max,
                              error: maxError
                            };
                    })
                ]
              ]);
  };
  var checked = function (field) {
    return [{
              TAG: "Checked",
              field: field,
              error: undefined
            }];
  };
  var password = function (field) {
    return [{
              TAG: "Password",
              field: field,
              error: undefined
            }];
  };
  var Validation = {
    schema: schema,
    mergeValidators: mergeValidators,
    confirmation: confirmation,
    email: email,
    date: date,
    nonEmpty: nonEmpty,
    eitherNonEmpty: eitherNonEmpty,
    string: string,
    checked: checked,
    password: password
  };
  var email$1 = Type_Pattern.make("Email");
  var passwordUpercaseChar = Type_Pattern.make("UppercaseChar");
  var passwordLowercaseChar = Type_Pattern.make("LowercaseChar");
  var passwordNumber = Type_Pattern.make("Number");
  var passwordSpecialChar = Type_Pattern.make("SpecialChar");
  var RegExps = {
    email: email$1,
    passwordUpercaseChar: passwordUpercaseChar,
    passwordLowercaseChar: passwordLowercaseChar,
    passwordNumber: passwordNumber,
    passwordSpecialChar: passwordSpecialChar
  };
  var validateField = function (validator, values, i18n) {
    switch (validator.TAG) {
      case "Confirmation" :
          var field2 = validator.field2;
          var value1 = Curry._2(Lenses.get, values, validator.field1);
          var value2 = Curry._2(Lenses.get, values, field2);
          return [
                  {
                    TAG: "Field",
                    _0: field2
                  },
                  value1 === value2 ? "Valid" : ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.confirmation(value1, value2))
                      })
                ];
      case "Email" :
          var field = validator.field;
          var value = Curry._2(Lenses.get, values, field);
          return [
                  {
                    TAG: "Field",
                    _0: field
                  },
                  email$1.test(value) ? "Valid" : ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.email(value))
                      })
                ];
      case "StringNonEmpty" :
          var field$1 = validator.field;
          var value$1 = Curry._2(Lenses.get, values, field$1);
          return [
                  {
                    TAG: "Field",
                    _0: field$1
                  },
                  value$1 === "" ? ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.stringNonEmpty(value$1))
                      }) : "Valid"
                ];
      case "EitherStringNonEmpty" :
          var field2$1 = validator.field2;
          var value1$1 = Curry._2(Lenses.get, values, validator.field1);
          var value2$1 = Curry._2(Lenses.get, values, field2$1);
          return [
                  {
                    TAG: "Field",
                    _0: field2$1
                  },
                  value1$1 === "" && value2$1 === "" ? ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.eitherStringNonEmpty(value1$1, value2$1))
                      }) : "Valid"
                ];
      case "StringMin" :
          var min = validator.min;
          var field$2 = validator.field;
          var value$2 = Curry._2(Lenses.get, values, field$2);
          return [
                  {
                    TAG: "Field",
                    _0: field$2
                  },
                  value$2.length >= min ? "Valid" : ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.stringMin(value$2, min))
                      })
                ];
      case "StringMax" :
          var max = validator.max;
          var field$3 = validator.field;
          var value$3 = Curry._2(Lenses.get, values, field$3);
          return [
                  {
                    TAG: "Field",
                    _0: field$3
                  },
                  value$3.length <= max ? "Valid" : ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.stringMax(value$3, max))
                      })
                ];
      case "Date" :
          var error = validator.error;
          var field$4 = validator.field;
          var value$4 = Curry._2(Lenses.get, values, field$4);
          var dateArray = value$4.split("/");
          return [
                  {
                    TAG: "Field",
                    _0: field$4
                  },
                  value$4 === "" ? "Valid" : (
                      dateArray.length === 3 ? Core__Array.reduce(dateArray, "Valid", (function (acc, datePart) {
                                if (typeof acc !== "object") {
                                  if (Core__Option.getOr(Core__Int.fromString(datePart, undefined), 0) > 0) {
                                    return "Valid";
                                  } else {
                                    return {
                                            TAG: "Error",
                                            _0: Core__Option.getOr(error, i18n.date)
                                          };
                                  }
                                } else {
                                  return acc;
                                }
                              })) : ({
                            TAG: "Error",
                            _0: Core__Option.getOr(error, i18n.date)
                          })
                    )
                ];
      case "Checked" :
          var field$5 = validator.field;
          var value$5 = Curry._2(Lenses.get, values, field$5);
          return [
                  {
                    TAG: "Field",
                    _0: field$5
                  },
                  value$5 ? "Valid" : ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.required)
                      })
                ];
      case "Password" :
          var field$6 = validator.field;
          var value$6 = Curry._2(Lenses.get, values, field$6);
          var validCount = Core__Array.reduce([
                passwordUpercaseChar,
                passwordLowercaseChar,
                passwordNumber,
                passwordSpecialChar
              ], 0, (function (acc, check) {
                  if (check.test(value$6)) {
                    return acc + 1 | 0;
                  } else {
                    return acc;
                  }
                }));
          return [
                  {
                    TAG: "Field",
                    _0: field$6
                  },
                  validCount >= 3 ? "Valid" : ({
                        TAG: "Error",
                        _0: Core__Option.getOr(validator.error, i18n.password)
                      })
                ];
      
    }
  };
  var getFieldValidators = function (validators, fieldName) {
    return validators.filter(function (validator) {
                switch (validator.TAG) {
                  case "Confirmation" :
                      return Caml_obj.equal({
                                  TAG: "Field",
                                  _0: validator.field2
                                }, fieldName);
                  case "EitherStringNonEmpty" :
                      if (Caml_obj.equal({
                              TAG: "Field",
                              _0: validator.field1
                            }, fieldName)) {
                        return true;
                      } else {
                        return Caml_obj.equal({
                                    TAG: "Field",
                                    _0: validator.field2
                                  }, fieldName);
                      }
                  default:
                    return Caml_obj.equal({
                                TAG: "Field",
                                _0: validator.field
                              }, fieldName);
                }
              });
  };
  var validateOne = function (field, values, i18nOpt, schema) {
    var i18n = i18nOpt !== undefined ? i18nOpt : Type_SchemaI18n.default;
    return getFieldValidators(schema, field).map(function (validator) {
                  return validateField(validator, values, i18n);
                }).find(function (validation) {
                var tmp = validation[1];
                if (typeof tmp !== "object") {
                  return false;
                } else {
                  return true;
                }
              });
  };
  var validate = function (i18n, values, schema) {
    var validationList = schema.map(function (validator) {
          return validateField(validator, values, i18n);
        });
    var errors = Core__Array.filterMap(validationList, (function (param) {
            var fieldState = param[1];
            if (typeof fieldState !== "object") {
              return ;
            } else {
              return [
                      param[0],
                      fieldState._0
                    ];
            }
          }));
    return Core__Option.getOr(Core__Option.map(Util_Array.NonEmpty.makeFromArray(errors), (function (errors) {
                      return {
                              TAG: "Errors",
                              _0: errors
                            };
                    })), "Valid");
  };
  return {
          getFieldName: getFieldName,
          Validation: Validation,
          RegExps: RegExps,
          validateField: validateField,
          getFieldValidators: getFieldValidators,
          validateOne: validateOne,
          validate: validate
        };
}

export {
  Make ,
}
/* No side effect */
