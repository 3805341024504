// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Binding_Constant from "../binding/Binding_Constant.res.mjs";

var _map = {"ACT":"ACT","CIN":"CIN","SER":"SER","CPO":"CPO","ARS":"ARS","SCI":"SCI","DEC":"DEC","HIS":"HIS","ARTE_CONCERT":"ARTE_CONCERT","ARTE_CONCERT_CLASSIC":"ARTE_CONCERT_CLASSIC","ARTE_CONCERT_MODERN":"ARTE_CONCERT_MODERN","MY_ADVANTAGE":"MY_ADVANTAGE"};

function emacPageToJs(param) {
  return param;
}

function emacPageFromJs(param) {
  return _map[param];
}

function encode(str) {
  var category = Core__Option.map(emacPageFromJs(str), (function (c) {
          return {
                  TAG: "Category",
                  _0: c === "ARTE_CONCERT" || c === "ARTE_CONCERT_CLASSIC" || c === "ARS" || c === "ARTE_CONCERT_MODERN" ? "Concert" : (
                      c === "ACT" ? "Actualite" : (
                          c === "CIN" ? "Cinema" : (
                              c === "CPO" ? "Culture" : (
                                  c === "DEC" ? "Voyage" : (
                                      c === "HIS" ? "Histoire" : (
                                          c === "SCI" ? "Science" : (
                                              c === "SER" ? "Serie" : "MyAdvantage"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                };
        }));
  if (category !== undefined) {
    return category;
  } else {
    return Core__Option.map(Binding_Constant.VideoTypes.decode(str), (function (videoType) {
                  return {
                          TAG: "VideoType",
                          _0: videoType
                        };
                }));
  }
}

function getCategory(t) {
  if (t.TAG === "VideoType") {
    return ;
  } else {
    return t._0;
  }
}

function getVideoType(t) {
  if (t.TAG === "VideoType") {
    return t._0;
  }
  
}

export {
  emacPageToJs ,
  emacPageFromJs ,
  encode ,
  getCategory ,
  getVideoType ,
}
/* Binding_Constant Not a pure module */
