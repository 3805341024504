// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("rect", {
                      height: "24",
                      width: "2.13333",
                      transform: "rotate(-180 26 27.7891)",
                      x: "26",
                      y: "27.7891"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M6.80778 4.80704C6.4523 4.5937 6 4.84976 6 5.26434L6 27.0654C6 27.48 6.4523 27.736 6.80778 27.5227L24.9713 16.6222C25.3165 16.415 25.3165 15.9147 24.9713 15.7076L6.80778 4.80704Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
