// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Spacer from "./Particle_Spacer.res.mjs";
import * as Particle_Spacer_Ds from "./Particle_Spacer_Ds.res.mjs";

function makeInside(param) {
  var toRule = function (fn, spacerSizeOpt) {
    return Core__Option.map(spacerSizeOpt, (function (spacerSize) {
                  return fn(Particle_Spacer.spacerToVarName(spacerSize));
                }));
  };
  return Util_Array.catOption([
              toRule(CssJs.paddingTop, param.top),
              toRule(CssJs.paddingBottom, param.bottom),
              toRule(CssJs.paddingLeft, param.left),
              toRule(CssJs.paddingRight, param.right)
            ]);
}

function makeOutside(param) {
  var toRule = function (fn, spacerSizeOpt) {
    return Core__Option.map(spacerSizeOpt, (function (spacerSize) {
                  return fn(Particle_Spacer.spacerToVarName(spacerSize));
                }));
  };
  return Util_Array.catOption([
              toRule(CssJs.marginTop, param.top),
              toRule(CssJs.marginBottom, param.bottom),
              toRule(CssJs.marginLeft, param.left),
              toRule(CssJs.marginRight, param.right)
            ]);
}

function toCss(spacerStyle) {
  var viewValues = Particle_Spacer_Ds.view(spacerStyle);
  switch (viewValues.TAG) {
    case "Inside" :
        return makeInside(viewValues._0);
    case "Outside" :
        return makeOutside(viewValues._0);
    case "Both" :
        var match = viewValues._0;
        return makeInside(match.inside).concat(makeOutside(match.outside));
    
  }
}

export {
  makeInside ,
  makeOutside ,
  toCss ,
}
/* CssJs Not a pure module */
