// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.res.mjs";
import * as MyNullable from "../MyNullable.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Emac_Teaser from "./Emac_Teaser.res.mjs";
import * as Util_Result from "../util/Util_Result.res.mjs";

function contentId_decode(contentId) {
  var match = Core__JSON.Decode.string(contentId);
  if (match === undefined) {
    return Spice.error(undefined, "Not a valid contentId", contentId);
  }
  switch (match) {
    case "displayed-connected" :
        return {
                TAG: "Ok",
                _0: "displayed-connected"
              };
    case "sso-favorites" :
        return {
                TAG: "Ok",
                _0: "sso-favorites"
              };
    case "sso-history" :
        return {
                TAG: "Ok",
                _0: "sso-history"
              };
    case "sso-personalzone" :
        return {
                TAG: "Ok",
                _0: "sso-personalzone"
              };
    default:
      return Spice.error(undefined, "Not a valid contentId", contentId);
  }
}

function contentIdFromJs(contentId) {
  return Util_Result.toOption(contentId_decode(contentId));
}

function paginationLinks_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "first",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.first)
                  ],
                  [
                    "last",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.last)
                  ],
                  [
                    "next",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.next)
                  ]
                ]));
}

function paginationLinks_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var first = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "first"), null));
  if (first.TAG === "Ok") {
    var last = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "last"), null));
    if (last.TAG === "Ok") {
      var next = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "next"), null));
      if (next.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  first: first._0,
                  last: last._0,
                  next: next._0
                }
              };
      }
      var e = next._0;
      return {
              TAG: "Error",
              _0: {
                path: ".next" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = last._0;
    return {
            TAG: "Error",
            _0: {
              path: ".last" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = first._0;
  return {
          TAG: "Error",
          _0: {
            path: ".first" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function pagination_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "links",
                    false,
                    MyNullable.t_encode(paginationLinks_encode)(v.links)
                  ],
                  [
                    "page",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.page)
                  ],
                  [
                    "pages",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.pages)
                  ],
                  [
                    "totalCount",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.totalCount)
                  ]
                ]));
}

function pagination_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var links = MyNullable.t_decode(paginationLinks_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "links"), null));
  if (links.TAG === "Ok") {
    var page = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
    if (page.TAG === "Ok") {
      var pages = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "pages"), null));
      if (pages.TAG === "Ok") {
        var totalCount = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "totalCount"), null));
        if (totalCount.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    links: links._0,
                    page: page._0,
                    pages: pages._0,
                    totalCount: totalCount._0
                  }
                };
        }
        var e = totalCount._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".totalCount" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = pages._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pages" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = page._0;
    return {
            TAG: "Error",
            _0: {
              path: ".page" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = links._0;
  return {
          TAG: "Error",
          _0: {
            path: ".links" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function nextPage_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "data",
                    false,
                    MyNullable.t_encode(function (extra) {
                            return Spice.arrayToJson(Emac_Teaser.t_encode, extra);
                          })(v.data)
                  ],
                  [
                    "pagination",
                    false,
                    MyNullable.t_encode(pagination_encode)(v.pagination)
                  ]
                ]));
}

function nextPage_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var data = MyNullable.t_decode(function (extra) {
          return Spice.arrayFromJson(Emac_Teaser.t_decode, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "data"), null));
  if (data.TAG === "Ok") {
    var pagination = MyNullable.t_decode(pagination_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
    if (pagination.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                data: data._0,
                pagination: pagination._0
              }
            };
    }
    var e = pagination._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagination" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = data._0;
  return {
          TAG: "Error",
          _0: {
            path: ".data" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  contentId_decode ,
  contentIdFromJs ,
  paginationLinks_encode ,
  paginationLinks_decode ,
  pagination_encode ,
  pagination_decode ,
  nextPage_encode ,
  nextPage_decode ,
}
/* Emac_Teaser Not a pure module */
