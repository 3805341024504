// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M21.5233 16.0113H17.8489V29H12.4018V16.0113H9.8125V11.4195H12.4018V8.44981C12.4018 6.32348 13.4211 3 17.8983 3L21.9341 3.01581V7.472H19.0046C18.5338 7.472 17.85 7.70824 17.85 8.72224V11.4206H22L21.5233 16.0113Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
