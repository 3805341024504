// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M16.2647 5.60205L7.25174 11.6272V19.936L16.2653 25.9627V5.60205H16.2647ZM3 19.59H5.65253V11.6308H3V19.59ZM18.5867 21.7126V20.1207C20.9002 20.1207 22.7817 18.2572 22.7817 15.9671C22.7817 13.6764 20.9002 11.8135 18.5867 11.8135V10.2216C21.7826 10.2216 24.3735 12.7935 24.3735 15.9671C24.3735 19.1402 21.7826 21.7126 18.5867 21.7126Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("mask", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M18.5784 5.61084H29V26.3047H18.5784V5.61084Z",
                            fill: "white",
                            fillRule: "evenodd"
                          }),
                      id: "mask0_32_11161",
                      height: "22",
                      width: "11",
                      maskUnits: "userSpaceOnUse",
                      x: "18",
                      y: "5"
                    }),
                JsxRuntime.jsx("g", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M18.5784 5.61084V7.20268C23.4473 7.20268 27.4083 11.1303 27.4083 15.9578C27.4083 20.7853 23.4473 24.7129 18.5784 24.7129V26.3047C24.334 26.3047 29.0002 21.6725 29.0002 15.9578C29.0002 10.2436 24.334 5.61084 18.5784 5.61084Z",
                            fillRule: "evenodd"
                          }),
                      mask: "url(#mask0_32_11161)"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
