// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M20.8242 7.69531H24.1934C25.308 7.69531 26.2148 8.60215 26.2148 9.7168C26.2148 10.612 25.6297 11.3727 24.822 11.6377L23.6202 26.146C23.5336 27.1858 22.6488 28 21.6057 28H10.6092C9.56612 28 8.68125 27.1858 8.59469 26.1463L7.3928 11.6377C6.58511 11.3727 6 10.612 6 9.7168C6 8.60215 6.90684 7.69531 8.02148 7.69531H11.3906V7.02148C11.3906 5.90684 12.2975 5 13.4121 5H18.8027C19.9174 5 20.8242 5.90684 20.8242 7.02148V7.69531ZM13.4121 6.34771C13.0405 6.34771 12.7382 6.64999 12.7382 7.02153V7.69536H19.4765V7.02153C19.4765 6.64999 19.1743 6.34771 18.8027 6.34771H13.4121ZM22.2772 26.0347C22.2483 26.3812 21.9534 26.6526 21.6057 26.6526H10.6092C10.2615 26.6526 9.96657 26.3812 9.93777 26.0351L8.75345 11.7385H23.4614L22.2772 26.0347ZM8.02149 10.3904H24.1934C24.5649 10.3904 24.8672 10.0881 24.8672 9.71657C24.8672 9.34502 24.5649 9.04274 24.1934 9.04274H8.02149C7.64994 9.04274 7.34766 9.34502 7.34766 9.71657C7.34766 10.0881 7.64994 10.3904 8.02149 10.3904Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M13.4108 24.5892L12.737 13.7181C12.7139 13.3466 12.3924 13.0641 12.0228 13.0873C11.6513 13.1103 11.3689 13.4301 11.3919 13.8015L12.0657 24.6726C12.0878 25.0299 12.3845 25.3048 12.7376 25.3048C13.1278 25.3048 13.4347 24.9761 13.4108 24.5892Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M16.1074 13.0859C15.7353 13.0859 15.4336 13.3876 15.4336 13.7598V24.6309C15.4336 25.003 15.7353 25.3047 16.1074 25.3047C16.4796 25.3047 16.7812 25.003 16.7812 24.6309V13.7598C16.7812 13.3876 16.4796 13.0859 16.1074 13.0859Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M20.192 13.0873C19.8214 13.0642 19.5008 13.3466 19.4778 13.7181L18.804 24.5892C18.781 24.9606 19.0634 25.2804 19.4349 25.3034C19.8065 25.3264 20.1261 25.0438 20.1491 24.6726L20.8229 13.8015C20.8459 13.43 20.5635 13.1103 20.192 13.0873Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
