import { FullEnv, fullEnvTypes, getEnvFromKey, client, server } from '../generated/generated_env';
declare global {
    interface Window {
        __ENV: NodeJS.ProcessEnv;
    }
}

export const updateVars = <T extends keyof FullEnv>(key: T, value: FullEnv[T]) => {
    if (process.env.NODE_ENV !== 'test') {
        throw new Error('updateVars should only be used in tests');
    }
    if (!fullEnvTypes[key]) {
        throw new Error(`updateVars: key ${key} is not a valid env key`);
    }
    if (typeof key !== 'string') throw new Error('updateVars: key must be a string');

    const tmp = getEnvFromKey(key, process.env[key]);
    if (fullEnvTypes[key] === 'string' && typeof value === 'string' && typeof tmp === 'string') {
        process.env[key as string] = value;
        return () => {
            process.env[key as string] = tmp;
        };
    }

    process.env[key as string] = JSON.stringify(value);
    return () => {
        process.env[key as string] = JSON.stringify(tmp);
    };
};

const getVarForTest = <T extends keyof FullEnv>(key: T): FullEnv[T] => {
    if (process.env.NODE_ENV !== 'test') {
        throw new Error('getVarForTest should only be used in tests');
    }
    return getEnvFromKey(key, process.env[key]);
};

const isServer = typeof global.window === 'undefined';

export const env = new Proxy(
    { ...client, ...server, NODE_ENV: process.env.NODE_ENV },
    {
        get(target, prop) {
            if (typeof prop !== 'string') {
                return undefined;
            }
            if (!(prop in target)) {
                return undefined;
            }

            if (process.env.NODE_ENV === 'test') {
                return getVarForTest(prop as keyof typeof target);
            }

            // Throw a descriptive error if a server-side env var is accessed on the client
            // Otherwise it would just be returning `undefined` and be annoying to debug
            if (!isServer && prop !== 'NODE_ENV' && !prop.startsWith('NEXT_PUBLIC_')) {
                throw new Error(
                    process.env.NODE_ENV === 'production'
                        ? '❌ Attempted to access a server-side environment variable on the client'
                        : `❌ Attempted to access server-side environment variable '${prop}' on the client`,
                );
            }

            if (!isServer && prop.startsWith('NEXT_PUBLIC_')) {
                return window.__ENV[prop];
            }

            return target[prop as keyof typeof target];
        },
    },
);
