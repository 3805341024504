// Generated by ReScript, PLEASE EDIT WITH CARE


function toString(t) {
  switch (t) {
    case "Email" :
        return "[a-z0-9.%+-_]+@[a-z0-9.%+\\-]+\\.[a-zA-Z]+$";
    case "UppercaseChar" :
        return "(.*[A-Z])";
    case "LowercaseChar" :
        return "(.*[a-z])";
    case "Number" :
        return "(.*\\d.*)";
    case "SpecialChar" :
        return "(.*\\W)";
    case "ArteBaseUrl" :
        return "https?:\/\/(?:www\.)?arte\.tv";
    
  }
}

function make(t) {
  return new RegExp(toString(t));
}

function makeWithFlags(t, flags) {
  return new RegExp(toString(t), flags);
}

export {
  toString ,
  make ,
  makeWithFlags ,
}
/* No side effect */
