import { fetchJson } from '@replay/fetch';
import { type Locale } from '@replay/i18n/types/locale';
import { type FooterProps } from '@replay/types/Footer';
import { isError } from '@replay/types/Result';
import { env } from '@/env';
import getFooterFallback from '../../shared/footer-fallbacks';

let fetchFooterData: (locale: Locale) => Promise<FooterProps> = async (locale) => {
    const data = await getFooterFallback(locale);
    return data;
};

if (typeof window === 'undefined') {
    fetchFooterData = async (locale) => {
        const baseUrl = `http://127.0.0.1:${env.PORT}`;
        const res = await fetchJson<FooterProps>(`${baseUrl}/api/rproxy/footer/${locale}/`);
        if (isError(res)) {
            return { main: [] };
        }
        return res.value;
    };
}

export { fetchFooterData };
