// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M3.5 21.0347V7H28.5V21.0347H23.9986L22.9234 19.0229H26.5603V8.97097H5.47097V19.0229H9.07665L8.00144 21.0347H3.5ZM16 24.7388H7.20354L16 13.725L24.7964 24.7388H16Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
