'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as React from "react";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Belt_MapString from "@rescript/std/lib/es6/belt_MapString.js";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as UseScreenResolution from "./UseScreenResolution.res.mjs";

function make() {
  var listeners = {
    contents: undefined
  };
  var subscribe = function (callback) {
    var id = Uuid.v4();
    listeners.contents = Belt_MapString.set(listeners.contents, id, [
          "Off",
          callback
        ]);
    return id;
  };
  var unSubscribe = function (listenerId) {
    listeners.contents = Belt_MapString.remove(listeners.contents, listenerId);
  };
  var onOn = function (id) {
    listeners.contents = Belt_MapString.mapWithKey(listeners.contents, (function (key, param) {
            var callback = param[1];
            var t = param[0];
            var match = key === id;
            if (t === "On") {
              if (match) {
                return [
                        t,
                        callback
                      ];
              } else {
                callback("Off");
                return [
                        "Off",
                        callback
                      ];
              }
            } else if (match) {
              callback("On");
              return [
                      "On",
                      callback
                    ];
            } else {
              return [
                      t,
                      callback
                    ];
            }
          }));
  };
  var onOff = function (id) {
    var value = Belt_MapString.get(listeners.contents, id);
    if (value === undefined) {
      return ;
    }
    if (value[0] !== "On") {
      return ;
    }
    var callback = value[1];
    callback("Off");
    listeners.contents = Belt_MapString.set(listeners.contents, id, [
          "Off",
          callback
        ]);
  };
  return {
          subscribe: subscribe,
          unSubscribe: unSubscribe,
          onOn: onOn,
          onOff: onOff
        };
}

var $$Event = {
  make: make
};

var mountOnInitDefault = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, false);

function make$1(mountOnInitOpt, $$event) {
  var mountOnInit = mountOnInitOpt !== undefined ? Caml_option.valFromOption(mountOnInitOpt) : mountOnInitDefault;
  var listenerIdInternal = {
    contents: undefined
  };
  var isInit = React.useRef(false);
  var match = React.useState(function () {
        return "Off";
      });
  var setStatus = match[1];
  var status = match[0];
  var callback = function (status) {
    setStatus(function (param) {
          return status;
        });
  };
  var screenSize = UseScreenResolution.make(undefined, undefined);
  var setOn = React.useCallback((function () {
          var listenerId = listenerIdInternal.contents;
          if (listenerId !== undefined) {
            return $$event.onOn(Caml_option.valFromOption(listenerId));
          }
          
        }), []);
  var setOff = React.useCallback((function () {
          var listenerId = listenerIdInternal.contents;
          if (listenerId !== undefined) {
            return $$event.onOff(Caml_option.valFromOption(listenerId));
          }
          
        }), []);
  React.useEffect((function () {
          var listenerId = $$event.subscribe(callback);
          listenerIdInternal.contents = Caml_option.some(listenerId);
          return (function () {
                    $$event.unSubscribe(listenerId);
                    listenerIdInternal.contents = undefined;
                  });
        }), []);
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          var match = isInit.current;
          var exit = 0;
          if (!(typeof screenSize === "object" && screenSize.NAME === "XS" && screenSize.VAL === 0)) {
            exit = 1;
          }
          if (exit === 1 && !match) {
            isInit.current = true;
            var mountOnInit$1 = Core__Option.getOr(Particle_Screen_Ds.getValueByScreenSize(mountOnInit, screenSize), false);
            if (mountOnInit$1) {
              setOn();
            } else {
              setOff();
            }
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [screenSize]);
  return React.useMemo((function () {
                return {
                        status: status,
                        setOn: setOn,
                        setOff: setOff
                      };
              }), [
              status,
              setOn,
              setOff
            ]);
}

export {
  $$Event ,
  mountOnInitDefault ,
  make$1 as make,
}
/* mountOnInitDefault Not a pure module */
