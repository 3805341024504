// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var height = Particle_Screen_Ds.make(undefined, undefined, "auto", undefined, undefined, {
      NAME: "vh",
      VAL: 100
    });

function width(width$1) {
  if (width$1 === "Video") {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, {
                NAME: "percent",
                VAL: 100.0
              }, "auto");
  } else {
    return Particle_Screen_Constants.oneHundredPctWidth;
  }
}

var displayVideo = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block");

function blockspace(blockspace$1) {
  if (blockspace$1 === "Content") {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Inside.makeHorizontal("L"));
  } else {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Inside.makeTop("M"));
  }
}

var video = Particle_Screen_Ds.make(undefined, [CssJs.minHeight({
            NAME: "vh",
            VAL: 60
          })], undefined, undefined, undefined, []);

var wrapper = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.overflow("hidden")]);

var Rules = {
  video: video,
  wrapper: wrapper
};

var Error404 = {
  height: height,
  width: width,
  displayVideo: displayVideo,
  blockspace: blockspace,
  Rules: Rules
};

export {
  Error404 ,
}
/* height Not a pure module */
