// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Text from "./Atom_Text.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";

function make(t) {
  return "🌿";
}

var Emoji_Ds = {
  make: make
};

function Atom_Emoji(props) {
  var ariaLabel = props.ariaLabel;
  var ariaHidden = Core__Option.isSome(ariaLabel);
  return JsxRuntime.jsx(Atom_Text.make, {
              accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, ariaHidden, ariaLabel, undefined, undefined, undefined, undefined, undefined, "img", undefined),
              blockSpace: props.blockSpace,
              children: "🌿",
              kind: "span",
              style: props.textStyle
            });
}

var make$1 = Atom_Emoji;

export {
  Emoji_Ds ,
  make$1 as make,
}
/* Atom_Text Not a pure module */
