import { v4 } from 'uuid';
import { flattenResult, type Error as ResultError, type NestedResult, type Result, isOk } from '@replay/types/Result';
import {
    type FetchError,
    fromStatusCode,
    type HttpError,
    type HttpErrors,
    type StatusCode,
    type TimeoutError,
    type ReplayError,
} from '@replay/types/Type_Error';
import { type Paths } from '@replay/types/Utils';

export const createHttpError = (statusCode: StatusCode): GlobalError['Fetch'] => ({
    type: 'Fetch',
    err: {
        type: 'Http',
        codeDefinition: fromStatusCode(statusCode),
        statusCode: statusCode,
        'x-correlation-id': v4(),
        stack: new global.Error().stack || null,
        url: '',
    },
});
export const notFoundError = (): GlobalError['Fetch'] => createHttpError(404);

type RetryOnObject = {
    [P in FetchError['type']]?: P extends 'Http' ? { [C in HttpErrors]?: boolean } : boolean;
};

type RetryOnString = Paths<RetryOnObject> | 'All';

const getPathError = (error: GlobalError['Fetch']['err']) => {
    const paths: RetryOnString[] = [error.type];

    if (error.type === 'Http') {
        const path: RetryOnString = `${error.type}.${error.codeDefinition}`;
        paths.push(path);
    }
    return paths;
};

type EnsuredRetryOn = {
    include: RetryOnString[];
    exclude: RetryOnString[];
};

const shouldRetry = (error: GlobalError['Fetch']['err'], numberRetry: number, retryConfig: EnsuredRetryConfig) => {
    if (numberRetry >= retryConfig.number) {
        return false;
    }

    const pathError = getPathError(error);

    // The reducer function takes an accumulator object and a value and returns
    // an updated accumulator object.
    const reducer =
        (scope: 'exclude' | 'include') => (acc: { depth: number; value: boolean }, v: RetryOnString, i: number) => {
            // If the value is included in the given array, the depth property is set
            // to the index of the value (i) and the value property is set to true.
            // Otherwise, the accumulator object is returned unchanged.
            if (retryConfig.retryOn[scope].includes(v)) {
                return { depth: i, value: true };
            }
            return acc;
        };

    // The isErrorExcluded and isErrorIncluded variables are created by calling
    // the reducer function with the pathError array and the logFor.exclude and
    // logFor.include arrays, respectively.
    const isErrorExcluded = pathError.reduce(reducer('exclude'), {
        depth: -1,
        value: retryConfig.retryOn.exclude.includes('All'),
    });

    const isErrorIncluded = pathError.reduce(reducer('include'), {
        depth: -1,
        value: retryConfig.retryOn.include.includes('All'),
    });

    if (isErrorExcluded.value && isErrorIncluded.value) {
        return isErrorIncluded.depth >= isErrorExcluded.depth;
    }

    return isErrorIncluded.value;
};
type RetryOn = {
    include?: RetryOnString[];
    exclude?: RetryOnString[];
};

type RetryConfig = {
    max: number;
    retryAfter: number;
    retryOn?: RetryOn;
};

type EnsuredRetryConfig = {
    number: number;
    retryAfter: number;
    retryOn: EnsuredRetryOn;
};

export type Options = {
    requestInit?: EnsuredRequestInit;
    retryConfig?: RetryConfig;
    timeout?: number;
    /**
     * With this function you can modify the text before trying parse it with JSON.parse
     */
    preParseTranformer?: (text: string) => string;
    /**
     * You can opt-out adding the x-correlation-id headers, this should not be set to false except for queries
     * who doesn't accept this header (for example the newsletter)
     */
    withoutXCorrelationHeader?: boolean;
};

type EnsuredOptions = {
    requestInit: EnsuredRequestInit;
    retryConfig: EnsuredRetryConfig;
    timeout: number;
    preParseTranformer: (text: string) => string;
};

const isError = (error: unknown): error is Error => {
    return error instanceof Error;
};
const parseJson = <T>(
    url: string,
    rawContent: string,
    xCorrelationId?: string,
): NestedResult<T, GlobalError['Fetch']['err']> => {
    try {
        return { tag: 'Ok', value: JSON.parse(rawContent) as T };
    } catch (err) {
        // because we only use JSON.parse, the error is always a SyntaxError
        const error = err as Error;
        return {
            tag: 'Error',
            value: {
                url,
                value: rawContent,
                stack: error.stack || null,
                'x-correlation-id': xCorrelationId,
                type: 'Json',
            },
        };
    }
};

interface DoFetchErrorI extends Error {
    url: string;
    statusCode: number;
}

class DoFetchError extends Error implements DoFetchErrorI {
    url: string;
    statusCode: number;
    constructor({ url, statusCode }: { url: string; statusCode: number }) {
        super();
        this.statusCode = statusCode;
        this.url = url;
    }
}

type AbortedBy = 'TIMEOUT' | 'PARENT' | null;

const doFetch = async <T>(
    url: string,
    options: EnsuredOptions,
    currentRetry: number,
): Promise<Result<T, GlobalError['Fetch']['err']>> => {
    const doRetry = async (
        lastResult: ResultError<GlobalError['Fetch']['err']>,
    ): Promise<Result<T, GlobalError['Fetch']['err']>> => {
        const nextRetryNumber = currentRetry + 1;
        if (shouldRetry(lastResult.value, nextRetryNumber, options.retryConfig)) {
            return await new Promise(async (resolve) => {
                setTimeout(async () => {
                    const result = await doFetch<T>(url, options, nextRetryNumber);
                    resolve(result);
                }, options.retryConfig.retryAfter);
            });
        }
        return lastResult;
    };
    const abortController: AbortController = new AbortController();
    let abortedBy: AbortedBy = null;

    const timeoutId = setTimeout(() => {
        abortedBy = 'TIMEOUT';
        abortController.abort();
    }, options.timeout);

    if (options.requestInit.signal) {
        options.requestInit?.signal.addEventListener('abort', () => {
            abortedBy = 'PARENT';
            abortController?.abort();
        });
    }

    const requestInit = { ...options.requestInit, signal: abortController.signal };

    try {
        const response = await fetch(url, requestInit);
        clearTimeout(timeoutId);
        if (response.bodyUsed || !response.ok) {
            throw new DoFetchError({ url, statusCode: response.status });
        }
        const rawContent = await response.text();
        const json = options.preParseTranformer(rawContent);
        const jsonResult = parseJson<T>(url, json, options.requestInit.headers?.['x-correlation-id']);
        const result = flattenResult(jsonResult);
        return result;
    } catch (error) {
        clearTimeout(timeoutId);
        if (error instanceof DoFetchError) {
            const err: HttpError = {
                'x-correlation-id': options.requestInit.headers?.['x-correlation-id'],
                type: 'Http',
                url: error.url,
                statusCode: error.statusCode,
                codeDefinition: fromStatusCode(error.statusCode),
                stack: error.stack || null,
            };
            return await doRetry({ tag: 'Error', value: err });
        }
        if (isError(error)) {
            if (error.name === 'AbortError' && abortedBy === 'TIMEOUT') {
                const result: ResultError<TimeoutError> = {
                    tag: 'Error',
                    value: {
                        'x-correlation-id': requestInit.headers?.['x-correlation-id'],
                        type: 'Timeout',
                        numberRetry: currentRetry,
                        url,
                        stack: error.stack || null,
                    },
                };
                return await doRetry(result);
            } else if (error.name === 'AbortError' && abortedBy === 'PARENT') {
                // if the parent abort the request, we don't retry
                return {
                    tag: 'Error',
                    value: {
                        type: 'AbortedByConsumer',
                        url,
                        stack: error.stack || null,
                        'x-correlation-id': requestInit.headers?.['x-correlation-id'],
                    },
                };
            }

            return doRetry({
                tag: 'Error',
                value: {
                    type: 'Unknown',
                    message: error.message,
                    url,
                    stack: error.stack || null,
                    'x-correlation-id': requestInit.headers?.['x-correlation-id'],
                },
            });
        }
        const newError = new global.Error();
        return doRetry({
            tag: 'Error',
            value: {
                type: 'Unknown',
                message: 'Unknown error',
                url,
                stack: newError.stack || null,
                'x-correlation-id': requestInit.headers?.['x-correlation-id'],
            },
        });
    }
};

type EnsuredHeaders = { [key: string]: string } & {
    [K in 'x-correlation-id' | 'accept']?: string;
};

interface EnsuredRequestInit extends Omit<RequestInit, 'headers'> {
    headers?: EnsuredHeaders;
}

const makeHeaders = (requestInit?: EnsuredRequestInit, withoutXCorrelationHeader?: boolean): EnsuredRequestInit => {
    const ensuredRequestInit: EnsuredRequestInit = requestInit || {};
    const headers = ensuredRequestInit.headers || {};

    const headersWithAccept: EnsuredHeaders = {
        ...headers,
        accept: 'application/json',
    };

    if (!withoutXCorrelationHeader) {
        headersWithAccept['x-correlation-id'] =
            'x-correlation-id' in headers && typeof headers['x-correlation-id'] === 'string'
                ? headers['x-correlation-id']
                : v4();
    }

    return {
        ...ensuredRequestInit,
        headers: headersWithAccept,
    };
};

const defaultRetryOn: EnsuredRetryOn = {
    include: ['All'],
    exclude: ['Http.NotFound', 'Http.TooManyRequests', 'AbortedByConsumer'],
};

const ensureRetryConfig = (retryConfig?: RetryConfig): EnsuredRetryConfig => {
    return {
        number: retryConfig?.max === undefined ? 3 : retryConfig.max,
        retryAfter: retryConfig?.retryAfter ?? 0,
        retryOn: retryConfig?.retryOn
            ? {
                  include: retryConfig.retryOn.include || defaultRetryOn.include,
                  exclude: retryConfig.retryOn.exclude || defaultRetryOn.exclude,
              }
            : defaultRetryOn,
    };
};

export const fetchJson = async <T>(url: string, options?: Options): Promise<Result<T, ReplayError>> => {
    const optionWithJsonAcceptHeader = makeHeaders(options?.requestInit, options?.withoutXCorrelationHeader);
    const ensuredOptions: EnsuredOptions = {
        timeout: options?.timeout || 10000,
        requestInit: optionWithJsonAcceptHeader,
        retryConfig: ensureRetryConfig(options?.retryConfig),
        preParseTranformer: options?.preParseTranformer || ((text) => text),
    };
    const result = await doFetch<T>(url, ensuredOptions, 0);

    if (isOk(result)) {
        return result;
    }

    const error: ReplayError = { type: 'Fetch', err: result.value };

    return { tag: 'Error', value: error };
};
