// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Atom_Checkbox_Ds from "./Atom_Checkbox_Ds.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";

function toCss(t) {
  var match = Atom_Checkbox_Ds.view(t);
  var shapeRules = Particle_Screen_Css.toRules(undefined, undefined, Particle_Css.shape, match.shape);
  var color = match.checkColor === "White" ? "ffffff" : "000000";
  var commonRules = [
      CssJs.cursor("pointer"),
      CssJs.alignSelf("center"),
      CssJs.margin(CssJs.px(0)),
      CssJs.unsafe("appearance", "none"),
      CssJs.checked([
            CssJs.unsafe("backgroundPosition", "center center"),
            CssJs.backgroundImage(CssJs.url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%208%208%22%20enable-background%3D%22new%200%200%208%208%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpath%20fill%3D%22%23" + color + "%22%20d%3D%22M6.4%2C1L5.7%2C1.7L2.9%2C4.5L2.1%2C3.7L1.4%2C3L0%2C4.4l0.7%2C0.7l1.5%2C1.5l0.7%2C0.7l0.7-0.7l3.5-3.5l0.7-0.7L6.4%2C1L6.4%2C1z%22%20%2F%3E%0A%3C%2Fsvg%3E")),
            CssJs.backgroundRepeat(CssJs.noRepeat),
            CssJs.backgroundSize(CssJs.size(CssJs.pct(90), CssJs.pct(90))),
            CssJs.backgroundColor(CssJs.transparent)
          ]),
      CssJs.active(Particle_Border_Css.toCss(Particle_Border_Ds.checkboxActive)),
      CssJs.focus(Particle_Border_Css.toCss(Particle_Border_Ds.checkboxActive)),
      CssJs.boxShadow(CssJs.none),
      CssJs.unsafe("-webkit-appearance", "none")
    ].concat(Particle_Border_Css.toCss(Particle_Border_Ds.checkboxInactive));
  return commonRules.concat(shapeRules);
}

export {
  toCss ,
}
/* CssJs Not a pure module */
