// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Club from "../../club/Type_Club.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

function endpoint_1(args) {
  return "personalzone/" + Util_I18n.localeToString(args.language) + "?page=" + args.page.toString();
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  tokenKind: "User",
  userRestriction: "Anonymous"
};

var GetConfig = {
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  decodeApiResponse: Type_Club.teasersResponse_decode
};

var Get = User_Api_Config.MakeRequest({
      errorResponse_decode: errorResponse_decode,
      mapError: mapError,
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: Type_Club.teasersResponse_decode,
      options: options
    });

var get = Get.call;

export {
  GetConfig ,
  Get ,
  get ,
}
/* Get Not a pure module */
