import { jwtDecode } from 'jwt-decode';
import { Log, Logger, OidcClient, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import type { Locale } from '@replay/types/Locale';
import { deleteCookie, setCookie, type ssoEnv } from './sso-cookie';
import { migrateAnonymous, migrateLegacyToken } from './sso-migrate';
import { pollMe } from './sso-check-user';

let userManager: UserManager | undefined;
let oidcClient: OidcClient | undefined;
let env: ssoEnv = 'prod';

let shouldMigrateLegacyToken = false;

type settings = {
    authority: string;
    baseUrl: string;
    callbackUrl: string;
    clientId: string;
    automaticSilentRenew?: boolean;
};
const getSettings: ({
    authority,
    baseUrl,
    automaticSilentRenew,
    callbackUrl,
    clientId,
}: settings) => UserManagerSettings = ({ authority, baseUrl, automaticSilentRenew, callbackUrl, clientId }) => ({
    authority,
    client_id: clientId,
    redirect_uri: `${callbackUrl}`,
    silent_redirect_uri: `${callbackUrl}?mode=silent`,
    post_logout_redirect_uri: `${baseUrl}`,
    response_type: 'code',
    scope: 'openid',
    redirectMethod: 'replace',
    redirectTarget: 'self',
    automaticSilentRenew,
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
});

type init = settings & {
    logLevel?: Log;
    env: 'prod' | 'preprod' | 'dev';
    migrateLegacyToken?: boolean;
};

/* istanbul ignore next  */
const init = ({
    authority,
    baseUrl,
    automaticSilentRenew,
    callbackUrl,
    clientId,
    logLevel,
    env: clientEnv,
    migrateLegacyToken,
}: init) => {
    if (userManager) return;
    userManager = new UserManager(getSettings({ authority, baseUrl, automaticSilentRenew, callbackUrl, clientId }));
    /* istanbul ignore if  */
    if (logLevel) {
        Log.setLogger(console);
        Log.setLevel(logLevel);
    }
    env = clientEnv;
    userManager.events.addUserLoaded(async (user) => {
        // tested, but istambul doesn't see it
        /* istanbul ignore next */
        await setCookie(env, user.access_token);
    });
    userManager.events.addUserUnloaded(async () => {
        // tested, but istambul doesn't see it
        /* istanbul ignore next */
        await deleteCookie(env);
    });

    if (oidcClient) return;

    if (migrateLegacyToken) {
        shouldMigrateLegacyToken = migrateLegacyToken;
    }

    oidcClient = new OidcClient({
        authority,
        client_id: clientId,
        redirect_uri: callbackUrl,
        response_type: 'code',
        scope: 'openid',
        stateStore: new WebStorageStateStore({ store: window.localStorage }),
    });
};

/* istanbul ignore next */
const getUserManager = async () => {
    if (userManager) return userManager;
    return new Promise<UserManager>((resolve) => {
        const abortTimeout = setTimeout(() => {
            Logger.error('UserManager is not initialized, please call init() first');
        }, 5000);
        const pollingInterval = setInterval(async () => {
            if (userManager) {
                clearInterval(pollingInterval);
                clearTimeout(abortTimeout);
                resolve(userManager);
            }
        }, 100);
    });
};

/* istanbul ignore next */
const getOidcClient = async () => {
    if (oidcClient) return oidcClient;
    return new Promise<OidcClient>((resolve) => {
        const abortTimeout = setTimeout(() => {
            Logger.error('OidcClient is not initialized, please call init() first');
        }, 5000);
        const pollingInterval = setInterval(async () => {
            if (oidcClient) {
                clearInterval(pollingInterval);
                clearTimeout(abortTimeout);
                resolve(oidcClient);
            }
        }, 100);
    });
};

const logout = async () => {
    const userManager = await getUserManager();
    await deleteCookie(env);
    await userManager.clearStaleState();
    await userManager.signoutRedirect();
};

type authParams = {
    extraQueryParams?: Record<string, string>;
    encodedTrackingData?: string;
    returnPage?: string;
    locale?: Locale;
};

const getBaseAuthUrl = (authority: string) => ({
    login: `${authority}/protocol/openid-connect/auth`,
    register: `${authority}/protocol/openid-connect/registrations`,
});

const getAuthUrl = async (params?: authParams) => {
    const oidcClient = await getOidcClient();
    const redirectUri = new URL(oidcClient.settings.redirect_uri);
    if (params?.extraQueryParams) {
        for (const key in params.extraQueryParams) {
            redirectUri.searchParams.set(key, params?.extraQueryParams[key]);
        }
    }
    redirectUri.searchParams.set('returnPage', params?.returnPage || window.location.href);
    await oidcClient.clearStaleState();
    const state = await oidcClient.createSigninRequest({
        redirect_uri: redirectUri.toString(),
        response_type: oidcClient.settings.response_type,
        scope: 'openid',
        ui_locales: params?.locale || 'fr',
        extraQueryParams: {
            tracking: params?.encodedTrackingData || '',
        },
    });
    return {
        login: state.url,
        register: state.url.replace('/openid-connect/auth', '/openid-connect/registrations'),
    };
};

const signin = async (params?: authParams) => {
    const loginUrl = (await getAuthUrl(params)).login;
    window.location.assign(loginUrl);
};

const register = async (params?: authParams) => {
    const loginUrl = (await getAuthUrl(params)).register;
    window.location.assign(loginUrl.replace('/openid-connect/auth', '/openid-connect/registrations'));
};

/* istanbul ignore next */
const userCallback = async (callbackUrl: string) => {
    const userManager = await getUserManager();
    const currentUser = await userManager.getUser();
    const tokenType = tokenTypeFromUser(userManager, currentUser);
    const oidcClient = await getOidcClient();
    oidcClient.clearStaleState();
    const signinResponse = await oidcClient.processSigninResponse(callbackUrl);
    const loginCount = signinResponse?.profile?.login_count as string | undefined;
    if (loginCount && loginCount === '1') {
        // /!\ the account is created on keycloak but might not be created on the backend
        // we need to poll an endpoint to check if the account is created
        const userResponse = await pollMe(env, signinResponse.access_token);
        if (userResponse.status !== 200) {
            throw new Error("User account wasn't created");
        }
    }
    if (loginCount && loginCount === '1' && tokenType === 'anonymous') {
        try {
            await migrateAnonymous(env, {
                anonymousToken: currentUser?.access_token || '',
                accessToken: signinResponse?.access_token,
            });
        } catch (error) {
            Logger.error('Failed to migrate anonymous token:', error);
        }
    }
    userManager.storeUser(new User(signinResponse));
};

/* istanbul ignore next */
const silentCallback = async (callbackUrl: string) => {
    const userManager = await getUserManager();
    await userManager.signinSilentCallback(callbackUrl);
    await userManager.clearStaleState();
};

/* istanbul ignore next */
const handleCallback = async (callbackUrl: string, isSilent: boolean) => {
    const userManager = await getUserManager();
    if (isSilent) {
        await silentCallback(callbackUrl);
    } else {
        await userCallback(callbackUrl);
    }
    return userManager.getUser();
};

const getUser = async ({ forceSilent }: { forceSilent?: boolean } = {}) => {
    const IFRAME_RENEWAL_TIMEOUT_SECONDS = 5;
    const userManager = await getUserManager();
    const storedUser = await userManager.getUser();
    if ((!storedUser && userManager.settings.automaticSilentRenew) || forceSilent) {
        try {
            const silentUser = await userManager.signinSilent({
                silentRequestTimeoutInSeconds: IFRAME_RENEWAL_TIMEOUT_SECONDS,
            });
            return silentUser;
        } catch (error) {
            /* istanbul ignore next */
            console.error('Signin silent error:', error);
        }
    }
    /** LEGACY TOKEN MIGRATION **/
    /* istanbul ignore next */
    if (storedUser && tokenTypeFromUser(userManager, storedUser) === 'legacy' && shouldMigrateLegacyToken) {
        const migratedToken = await migrateLegacyToken(env, storedUser.access_token);
        if (migratedToken) {
            const decoded = jwtDecode(migratedToken.access_token);
            const migratedUser = new User({
                ...migratedToken,
                profile: {
                    ...decoded,
                    sub: decoded.sub ?? '',
                    iss: decoded.iss ?? '',
                    aud: decoded.aud ?? '',
                    exp: decoded.exp ?? 0,
                    iat: decoded.iat ?? 0,
                },
                token_type: 'Bearer',
                expires_at: decoded.exp ?? 0,
                scope: 'openid',
            });
            await userManager.storeUser(migratedUser);
            userManager.startSilentRenew();
            return migratedUser;
        }
    }
    // When NEXT_PUBLIC_FEATURE_FLAGS_OIDC_AUTH is off, automaticSilentRenew is not enabled
    // Migrated legacy token will need silent renew, so we start it here
    /* istanbul ignore next */
    if (storedUser && tokenTypeFromUser(userManager, storedUser) === 'oidc') {
        if (!userManager.settings.automaticSilentRenew) {
            console.log('starting silent renew');
            userManager.startSilentRenew();
        }
    }

    return storedUser;
};

const storeLegacyToken = async (token: string) => {
    const userManager = await getUserManager();
    try {
        const decoded = jwtDecode(token);
        const legacyUser = new User({
            access_token: token,
            profile: {
                ...decoded,
                sub: decoded.sub ?? '',
                iss: decoded.iss ?? '',
                aud: decoded.aud ?? '',
                exp: decoded.exp ?? 0,
                iat: decoded.iat ?? 0,
            },
            token_type: 'Bearer',
        });
        await userManager.storeUser(legacyUser);
        userManager.stopSilentRenew();
        await setCookie(env, token);
        return await userManager.getUser();
    } catch (error) {
        Logger.error('Failed to store legacy token:', error);
        return undefined;
    }
};

const removeStoredToken = async () => {
    const userManager = await getUserManager();
    await deleteCookie(env);
    await userManager.removeUser();
};

const tokenTypeFromUser = (userManager: UserManager, user: User | null) => {
    const uid = user?.profile?.uid as string | undefined;
    const iss = user?.profile?.iss as string | undefined;
    if (!user) return 'legacy';
    if (uid && uid.includes('anonymous')) {
        return 'anonymous';
    }
    if (iss && iss.includes(userManager.settings.authority)) {
        return 'oidc';
    }
    return 'legacy';
};

const getTokenType = async () => {
    const userManager = await getUserManager();
    const user = await userManager.getUser();
    return tokenTypeFromUser(userManager, user);
};

const saveCookie = async (token: string) => setCookie(env, token);

export {
    logout,
    userManager,
    handleCallback,
    signin,
    register,
    getBaseAuthUrl,
    getAuthUrl,
    getUser,
    storeLegacyToken,
    getTokenType,
    removeStoredToken,
    init,
    getSettings,
    getUserManager,
    getOidcClient,
    saveCookie,
};
