// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M6.5 0.100098C7.03413 0.100098 7.46678 0.53274 7.46678 1.06613C7.46678 1.56872 7.89245 1.79192 8.37237 2.04356C8.51568 2.1187 8.66384 2.19638 8.80693 2.28479C8.80882 2.28574 8.8095 2.28668 8.81033 2.28782C8.8108 2.28846 8.81131 2.28917 8.81212 2.28998C10.4012 3.12341 11.4865 4.78656 11.4865 6.70604V9.72268C11.4865 9.94715 11.5761 10.1627 11.7339 10.3205L13 11.5866V12.8253H0V11.5866L1.26607 10.3205C1.42461 10.1627 1.51351 9.94715 1.51351 9.72268V6.70604C1.51351 4.78656 2.59881 3.12341 4.18788 2.28998L4.18986 2.28782C4.19084 2.28668 4.19165 2.28574 4.19307 2.28479C4.3363 2.1963 4.48462 2.11856 4.62812 2.04335C5.10808 1.7918 5.53396 1.56858 5.53396 1.06613C5.53396 0.53274 5.96661 0.100098 6.5 0.100098ZM6.5 2.48779C6.12554 2.93007 5.6308 3.19045 5.32082 3.35359L5.31617 3.35604L5.28739 3.37106C5.19697 3.41822 5.10355 3.46694 5.01317 3.5205L4.98798 3.5368L4.9339 3.57162L4.87612 3.602C3.71598 4.21021 2.99516 5.39998 2.99516 6.70605V9.72269C2.99516 10.3331 2.7618 10.9073 2.33805 11.3436H10.6627C10.2382 10.9073 10.0048 10.3331 10.0048 9.72269V6.70605C10.0048 5.39998 9.28403 4.21021 8.12389 3.602L8.06685 3.57162L8.01203 3.5368L7.98684 3.5205C7.89391 3.46488 7.79974 3.41609 7.70887 3.36901L7.68384 3.35604L7.68063 3.35435C7.37074 3.19126 6.87578 2.93075 6.5 2.48779Z",
                      fill: Particle_Color.toString(Particle_Color.Css.color(color)),
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M6.5 14.9166C7.37566 14.9166 8.08537 14.2069 8.08537 13.3312H4.91463C4.91463 14.2069 5.62434 14.9166 6.5 14.9166Z",
                      fill: Particle_Color.toString(Particle_Color.Css.color(color))
                    })
              ]
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
