// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "@rescript/std/lib/es6/js_exn.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Webapi__Fetch from "rescript-webapi/src/Webapi/Webapi__Fetch.res.mjs";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function errorFromStatusCode(statusCode) {
  if (statusCode >= 430) {
    if (statusCode !== 500 && statusCode !== 503) {
      return {
              TAG: "FailedWithStatus",
              _0: statusCode
            };
    } else {
      return "ServerError";
    }
  }
  if (statusCode >= 405) {
    if (statusCode >= 429) {
      return "TooManyRequest";
    } else {
      return {
              TAG: "FailedWithStatus",
              _0: statusCode
            };
    }
  }
  if (statusCode < 400) {
    return {
            TAG: "FailedWithStatus",
            _0: statusCode
          };
  }
  switch (statusCode) {
    case 400 :
        return "BadRequest";
    case 401 :
        return "InvalidAuthent";
    case 402 :
        return {
                TAG: "FailedWithStatus",
                _0: statusCode
              };
    case 403 :
        return "Unauthorized";
    case 404 :
        return "NotFound";
    
  }
}

function exnToError(exn) {
  var tmp;
  if (exn.RE_EXN_ID === Js_exn.$$Error) {
    var name = exn._1.name;
    tmp = name === "AbortError" ? ({
          kind: "RequestAborted"
        }) : ({
          kind: "Unknown"
        });
  } else {
    tmp = {
      kind: "Unknown"
    };
  }
  return {
          TAG: "Error",
          _0: tmp
        };
}

var $$Error = {
  errorFromStatusCode: errorFromStatusCode,
  exnToError: exnToError
};

function $$fetch$1(param) {
  var body = Core__Option.map(Core__Option.map(param.body, (function (__x) {
              return JSON.stringify(__x);
            })), (function (prim) {
          return prim;
        }));
  return fetch(param.url, Webapi__Fetch.RequestInit.make(param.method, Caml_option.some(param.headers), body, undefined, undefined, undefined, param.credentials, undefined, undefined, undefined, undefined, Caml_option.some(param.signal), undefined));
}

var defaultCallbacks = {};

var defaultFetchOptions = {};

function parseResponse(parseResponse$1, parseError) {
  return async function (response) {
    var text = await response.text();
    var json;
    try {
      json = {
        TAG: "Ok",
        _0: JSON.parse(text)
      };
    }
    catch (exn){
      json = {
        TAG: "Error",
        _0: text
      };
    }
    var status = response.status;
    if (status >= 200 && status < 300) {
      if (json.TAG !== "Ok") {
        return {
                TAG: "Error",
                _0: {
                  kind: {
                    TAG: "NotValidJson",
                    _0: json._0
                  }
                }
              };
      }
      var value = parseResponse$1(json._0);
      if (value.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: value._0
              };
      } else {
        return {
                TAG: "Error",
                _0: {
                  kind: {
                    TAG: "CantParseResponse",
                    _0: value._0
                  }
                }
              };
      }
    }
    var response$1;
    if (json.TAG === "Ok") {
      var value$1 = parseError(json._0);
      response$1 = value$1.TAG === "Ok" ? Caml_option.some(value$1._0) : undefined;
    } else {
      response$1 = undefined;
    }
    return {
            TAG: "Error",
            _0: {
              kind: errorFromStatusCode(status),
              response: response$1
            }
          };
  };
}

function make(config) {
  var getUrl = config.getUrl;
  var callbacks = Core__Option.getOr(config.options.callbacks, defaultCallbacks);
  var fetchOptions = Core__Option.getOr(config.options.fetchOptions, defaultFetchOptions);
  var decodeResponse = parseResponse(config.options.responseDecoder, config.options.errorDecoder);
  var $$fetch$2 = Core__Option.getOr(config.options.overrideFetch, $$fetch$1);
  return async function (param) {
    var overrideBearerToken = param.overrideBearerToken;
    var args = param.args;
    var guard = Core__Option.getOr(fetchOptions.guard, (async function (param, param$1) {
            return "Allowed";
          }));
    var match = await guard(overrideBearerToken, Caml_option.some(args));
    switch (match) {
      case "Allowed" :
          var url = getUrl(args);
          var getToken = fetchOptions.getToken;
          var token = getToken !== undefined ? await getToken() : undefined;
          var bearerToken = Core__Option.map(Core__Option.mapOr(overrideBearerToken, token, (function (a) {
                      return a;
                    })), (function (a) {
                  if (a.startsWith("Bearer")) {
                    return a;
                  } else {
                    return "Bearer " + a;
                  }
                }));
          var body = config.method;
          var match$1;
          if (typeof body !== "object") {
            match$1 = [
              "Get",
              undefined
            ];
          } else {
            switch (body.TAG) {
              case "Post" :
                  match$1 = [
                    "Post",
                    body._0(args)
                  ];
                  break;
              case "Put" :
                  match$1 = [
                    "Put",
                    body._0(args)
                  ];
                  break;
              case "Delete" :
                  match$1 = [
                    "Delete",
                    body._0(args)
                  ];
                  break;
              case "Patch" :
                  match$1 = [
                    "Patch",
                    body._0(args)
                  ];
                  break;
              
            }
          }
          var headers = config.headersToArray(config.getHeaders(param.locale, undefined, args));
          if (bearerToken !== undefined) {
            headers.push([
                  "Authorization",
                  bearerToken
                ]);
          }
          var response;
          try {
            var fetchResponse = await $$fetch$2({
                  url: url,
                  headers: headers,
                  body: match$1[1],
                  signal: param.signal,
                  method: match$1[0],
                  credentials: fetchOptions.withCredentials
                });
            response = await decodeResponse(fetchResponse);
          }
          catch (raw_exn){
            var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
            response = exnToError(exn);
          }
          if (response.TAG === "Ok") {
            var fn = callbacks.onSuccess;
            if (fn !== undefined) {
              fn(response._0);
            }
            return response;
          }
          var fn$1 = callbacks.onError;
          if (fn$1 !== undefined) {
            fn$1(response._0);
          }
          return response;
      case "Refused" :
          var fn$2 = callbacks.onRequestSkipped;
          if (fn$2 !== undefined) {
            fn$2();
          }
          return {
                  TAG: "Error",
                  _0: {
                    kind: {
                      TAG: "Guard",
                      _0: "Refused"
                    }
                  }
                };
      case "NotEnoughRights" :
          return {
                  TAG: "Error",
                  _0: {
                    kind: {
                      TAG: "Guard",
                      _0: "NotEnoughRights"
                    }
                  }
                };
      
    }
  };
}

export {
  $$Error ,
  $$fetch$1 as $$fetch,
  defaultCallbacks ,
  defaultFetchOptions ,
  parseResponse ,
  make ,
}
/* No side effect */
