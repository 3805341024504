// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../../../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../../../../type/Type_Common.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

function Template_ProgramContent_AudioVersions(props) {
  var audioVersions = props.audioVersions;
  if (audioVersions.length !== 0) {
    return JsxRuntime.jsx(Atom_Column.make, {
                children: JsxRuntime.jsx(Atom_Row.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      children: audioVersions.map(function (param, index) {
                            var label = param.label;
                            return JsxRuntime.jsxs(Atom_Cell.make, {
                                        alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                        blockSpace: Caml_option.some(Particle_Screen_Constants.xxsVerticalBlockSpace),
                                        kind: "ItemList",
                                        children: [
                                          JsxRuntime.jsx(Atom_Icon.make, {
                                                icon: Type_Common.audioVersionCodeToIcon(param.code, "Text"),
                                                size: iconSize,
                                                title: label
                                              }),
                                          JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                                                children: label
                                              })
                                        ]
                                      }, label + String(index));
                          }),
                      kind: "List"
                    })
              });
  } else {
    return null;
  }
}

var make = Template_ProgramContent_AudioVersions;

export {
  iconSize ,
  make ,
}
/* iconSize Not a pure module */
