// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M17.3702 12.3093H22.9446V13.7731H18.9809V15.3318H22.474V16.7956H18.9809V19.6296H17.3702V12.3093ZM8.8994 12.3093H10.6778L12.5914 17.4651L14.505 12.3093H16.2405L13.2817 19.6816H11.8595L8.8994 12.3093ZM4.4742 15.9987C4.4742 22.357 9.6443 27.5271 16 27.5271C22.357 27.5271 27.5284 22.3557 27.5284 15.9987C27.5271 9.643 22.357 4.4716 16 4.4716C9.6443 4.4716 4.4742 9.6443 4.4742 15.9987ZM16 29C8.8318 29 3 23.1682 3 15.9987C3 8.8318 8.8318 3 16 3C23.1682 3 29 8.8318 29 15.9987C29 23.1682 23.1682 29 16 29Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
