'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Belt_MutableSetString from "@rescript/std/lib/es6/belt_MutableSetString.js";

function make(id) {
  return {
          id: id,
          input: Belt_MutableSetString.make()
        };
}

function makeWithInputArray(id, input) {
  return {
          id: id,
          input: Belt_MutableSetString.fromArray(input)
        };
}

function toString(t) {
  return Belt_MutableSetString.toArray(t.input).join(" ");
}

function newId(t, key) {
  var newId$1 = t.id + key;
  Belt_MutableSetString.add(t.input, newId$1);
  return newId$1;
}

function registerNewIds(t, values) {
  return values.map(function (__x) {
              return newId(t, __x);
            });
}

var AriaLabelledby = {
  make: make,
  makeWithInputArray: makeWithInputArray,
  toString: toString,
  newId: newId,
  registerNewIds: registerNewIds
};

function make$1(id) {
  return {
          id: id,
          input: Belt_MutableSetString.make()
        };
}

function toString$1(t) {
  return Belt_MutableSetString.toArray(t.input).join(" ");
}

function newId$1(t, key) {
  var newId$2 = t.id + key;
  Belt_MutableSetString.add(t.input, newId$2);
  return newId$2;
}

var AriaDescribedby = {
  make: make$1,
  toString: toString$1,
  newId: newId$1
};

function makeDialog(ariaLabelledby) {
  return {
          ariaLabelledby: ariaLabelledby,
          ariaModal: true,
          role: "dialog"
        };
}

var $$default = {};

function view(param) {
  var ariaLabelledby = param.ariaLabelledby;
  var ariaDescribedby = param.ariaDescribedby;
  return {
          ariaControls: param.ariaControls,
          ariaCurrent: param.ariaCurrent,
          ariaDescribedby: ariaDescribedby !== undefined ? toString$1(Caml_option.valFromOption(ariaDescribedby)) : undefined,
          ariaDisabled: param.ariaDisabled,
          ariaExpanded: param.ariaExpanded,
          ariaHasPopup: param.ariaHasPopup,
          ariaHidden: param.ariaHidden,
          ariaPressed: param.ariaPressed,
          ariaLabel: param.ariaLabel,
          ariaLabelledby: ariaLabelledby !== undefined ? toString(Caml_option.valFromOption(ariaLabelledby)) : undefined,
          ariaLive: param.ariaLive,
          ariaModal: param.ariaModal,
          ariaRoledescription: param.ariaRoledescription,
          lang: param.lang,
          role: param.role,
          tabIndex: param.tabIndex
        };
}

function set(ariaControls, ariaCurrent, ariaDescribedby, ariaDisabled, ariaExpanded, ariaHasPopup, ariaHidden, ariaLabel, ariaLabelledby, ariaLive, ariaModal, ariaPressed, ariaRoledescription, lang, role, tabindex, t) {
  return {
          ariaControls: ariaControls !== undefined ? ariaControls : t.ariaControls,
          ariaCurrent: ariaCurrent !== undefined && ariaCurrent !== "false" ? ariaCurrent : t.ariaCurrent,
          ariaDescribedby: ariaDescribedby !== undefined ? Caml_option.some(Caml_option.valFromOption(ariaDescribedby)) : t.ariaDescribedby,
          ariaDisabled: ariaDisabled !== undefined ? ariaDisabled : t.ariaDisabled,
          ariaExpanded: ariaExpanded !== undefined ? ariaExpanded : t.ariaExpanded,
          ariaHasPopup: ariaHasPopup !== undefined ? ariaHasPopup : t.ariaHasPopup,
          ariaHidden: ariaHidden !== undefined ? ariaHidden : t.ariaHidden,
          ariaLabel: ariaLabel !== undefined ? ariaLabel : t.ariaLabel,
          ariaLabelledby: ariaLabelledby !== undefined ? Caml_option.some(Caml_option.valFromOption(ariaLabelledby)) : t.ariaLabelledby,
          ariaLive: ariaLive !== undefined ? ariaLive : t.ariaLive,
          ariaModal: ariaModal !== undefined ? ariaModal : t.ariaModal,
          ariaPressed: ariaPressed !== undefined ? ariaPressed : t.ariaPressed,
          ariaRoledescription: ariaRoledescription !== undefined ? ariaRoledescription : t.ariaRoledescription,
          lang: lang !== undefined ? lang : t.lang,
          role: role !== undefined ? role : t.role,
          tabIndex: tabindex !== undefined ? tabindex : (
              role === "main" ? -1 : t.tabIndex
            )
        };
}

function make$2(ariaControls, ariaCurrent, ariaDescribedby, ariaDisabled, ariaExpanded, ariaHidden, ariaLabel, ariaLabelledby, ariaLive, ariaPressed, ariaRoledescription, lang, role, tabindex) {
  return set(ariaControls, ariaCurrent, ariaDescribedby, ariaDisabled, ariaExpanded, undefined, ariaHidden, ariaLabel, ariaLabelledby, ariaLive, undefined, ariaPressed, ariaRoledescription, lang, role, tabindex, $$default);
}

var notFocusable = {
  ariaHidden: true,
  tabIndex: -1
};

var defaultView = {};

export {
  AriaLabelledby ,
  AriaDescribedby ,
  notFocusable ,
  makeDialog ,
  $$default as default,
  defaultView ,
  view ,
  set ,
  make$2 as make,
}
/* No side effect */
