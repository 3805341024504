// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Type_Image from "../../type/Type_Image.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Atom_Image_Ds from "./Atom_Image_Ds.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_Gradient_Ds from "../particle/Particle_Gradient_Ds.res.mjs";
import * as Belt_MutableMapString from "@rescript/std/lib/es6/belt_MutableMapString.js";
import * as Particle_Gradient_Css from "../particle/Particle_Gradient_Css.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Background_Css from "../particle/Particle_Background_Css.res.mjs";

function getPresentationRules(presentation) {
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Core__Option.getOr(Core__Option.map(Particle_Css.imagePresentation(presentation), (function (rule) {
                        return [rule];
                      })), []));
}

function makeMaskRules(mask) {
  if (mask !== undefined) {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.unsafe("clipPath", "circle(" + (50).toString() + "%)")]);
  } else {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  }
}

function imageWidthRules(x) {
  if (typeof x !== "object") {
    return [CssJs.width(CssJs.auto)];
  }
  switch (x.TAG) {
    case "Fixed" :
        var width = x._0.width;
        return [
                CssJs.width(CssJs.px(width)),
                CssJs.maxWidth(CssJs.px(width))
              ];
    case "Fluid" :
    case "FullPlace" :
        break;
    
  }
  return [
          CssJs.width(CssJs.pct(100)),
          CssJs.maxWidth(CssJs.px(x._0.maxWidth))
        ];
}

function imageMinWidthRules(minWidth) {
  if (minWidth !== undefined) {
    return [CssJs.minWidth(CssJs.px(minWidth))];
  } else {
    return [];
  }
}

function imageHeightRules(x) {
  if (typeof x !== "object") {
    return [CssJs.minHeight(CssJs.auto)];
  }
  switch (x.TAG) {
    case "Fluid" :
        var minHeight = x._0.minHeight;
        if (minHeight !== undefined) {
          return [CssJs.minHeight(CssJs.px(minHeight))];
        } else {
          return [CssJs.minHeight(CssJs.auto)];
        }
    case "Fixed" :
        return [
                CssJs.height(CssJs.px(x._0.height)),
                CssJs.maxHeight(CssJs.auto)
              ];
    case "FullPlace" :
        return [CssJs.height(CssJs.pct(100))];
    
  }
}

function getSizeRules(display, minWidth) {
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                Particle_Screen_Css.toRules(undefined, undefined, imageHeightRules, display),
                Particle_Screen_Css.toRules(undefined, undefined, imageWidthRules, display),
                Particle_Screen_Css.toRules(undefined, undefined, imageMinWidthRules, minWidth)
              ].flat());
}

function makeBackgroundRules(background) {
  var background$1 = background !== undefined ? Caml_option.some(Caml_option.valFromOption(background)) : Caml_option.some(Particle_Background_Ds.imagePlaceholder);
  return Core__Option.mapOr(background$1, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []), (function (background) {
                return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Background_Css.toCss(background));
              }));
}

function getColorRules(color) {
  if (color !== undefined) {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.color(Particle_Color.Css.color(color))]);
  } else {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  }
}

function makeImageRules(display, presentation, background, color, minWidth, mask) {
  var colorRules = getColorRules(color);
  var presentationRules = getPresentationRules(presentation);
  var sizeRules = getSizeRules(display, minWidth);
  var backgroundRules = makeBackgroundRules(background);
  var maskRules = makeMaskRules(mask);
  var ratio = Particle_Screen_Ds.map(display, (function (d) {
          var tmp;
          tmp = typeof d !== "object" ? [] : [Type_Image.Ratio.toCss(d._0.ratio)];
          return Util_Array.catOption(tmp);
        }));
  return Particle_Screen_Ds.$$Array.concatMany([
              presentationRules,
              sizeRules,
              backgroundRules,
              colorRules,
              maskRules,
              ratio
            ]);
}

var cssRulesMap = Belt_MutableMapString.make();

function toCss(imageKind) {
  var imageKey = Atom_Image_Ds.getMapKey(imageKind);
  var rules = Core__Option.flatMap(imageKey, (function (__x) {
          return Belt_MutableMapString.get(cssRulesMap, __x);
        }));
  if (rules !== undefined) {
    return rules;
  }
  var match = Atom_Image_Ds.view(imageKind);
  var gradient = match.gradient;
  var color = match.color;
  var background = match.background;
  var imageRules;
  if (gradient !== undefined) {
    var gradientRules = Particle_Gradient_Css.toCss(Particle_Gradient_Ds.view(gradient));
    imageRules = {
      image: makeImageRules(match.display, match.presentation, background, color, match.minWidth, match.mask),
      gradient: Caml_option.some(gradientRules)
    };
  } else {
    imageRules = {
      image: makeImageRules(match.display, match.presentation, background, color, match.minWidth, match.mask),
      gradient: undefined
    };
  }
  Core__Option.forEach(imageKey, (function (__x) {
          Belt_MutableMapString.set(cssRulesMap, __x, imageRules);
        }));
  return imageRules;
}

export {
  getPresentationRules ,
  makeMaskRules ,
  imageWidthRules ,
  imageMinWidthRules ,
  imageHeightRules ,
  getSizeRules ,
  makeBackgroundRules ,
  getColorRules ,
  makeImageRules ,
  cssRulesMap ,
  toCss ,
}
/* cssRulesMap Not a pure module */
