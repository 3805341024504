import pickBy from 'ramda/src/pickBy';

type Nullish = null | undefined;

function isNullish(value: unknown): value is Nullish {
    return value === null || value === undefined;
}

export const stripNullValues: (object: Record<string, unknown>) => Record<string, unknown> = pickBy(
    (value) => !isNullish(value),
);
