// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";

function fromOption(opt, defaultError) {
  if (opt !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(opt)
          };
  } else {
    return {
            TAG: "Error",
            _0: defaultError
          };
  }
}

function toOption(r) {
  if (r.TAG === "Ok") {
    return Caml_option.some(r._0);
  }
  
}

function mapError(ra, f) {
  if (ra.TAG === "Ok") {
    return ra;
  } else {
    return {
            TAG: "Error",
            _0: f(ra._0)
          };
  }
}

export {
  fromOption ,
  toOption ,
  mapError ,
}
/* No side effect */
