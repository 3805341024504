// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as React from "react";
import * as UseRefs from "../../../../hook/UseRefs.res.mjs";
import * as Atom_Box from "../../../atom/Atom_Box.res.mjs";
import * as Util_Dom from "../../../../util/Util_Dom.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as UseNextPage from "../../../../hook/UseNextPage.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UsePagination from "../../../../hook/UsePagination.res.mjs";
import * as Organism_Slider from "../Organism_Slider.res.mjs";
import * as Organism_Teaser from "../../teaser/Organism_Teaser.res.mjs";
import * as Particle_zIndex from "../../../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_Ds from "../Organism_Slider_Ds.res.mjs";
import * as Organism_Teaser_Ds from "../../teaser/Organism_Teaser_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_Image_Selector from "../../../atom/Atom_Image_Selector.res.mjs";
import * as Organism_Teaser_Css from "../../teaser/Organism_Teaser_Css.res.mjs";
import * as UseScreenResolution from "../../../../hook/UseScreenResolution.res.mjs";
import * as Particle_Background_Ds from "../../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_Slider_Teaser_Constant from "./Organism_Slider_Teaser_Constant.res.mjs";
import * as Organism_Slider_Teaser_MetaInformation from "./Organism_Slider_Teaser_MetaInformation.res.mjs";

function selectItems(__x) {
  return __x.querySelectorAll(Organism_Teaser_Css.Selectors.teaserItemIdentifier);
}

var itemSpace = Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS");

function handleTeaserFocus(screenSize, sliderRef, $$event) {
  var currentTarget = $$event.currentTarget;
  var sliderElement = Util_Dom.getDomRef(sliderRef);
  var sliderSize = Util_Dom.getElementSize(sliderElement);
  var sliderHorizontalPadding = (Particle_Screen_Ds.extractValue(screenSize) - sliderSize.width) / 2;
  var rect = currentTarget.getBoundingClientRect();
  var match = rect.left < sliderHorizontalPadding;
  var match$1 = rect.right > sliderSize.width + sliderHorizontalPadding;
  if (match) {
    if (match$1) {
      return ;
    } else {
      return Util_Dom.scrollBy(sliderElement, rect.left - sliderHorizontalPadding);
    }
  } else if (match$1) {
    return Util_Dom.scrollBy(sliderElement, rect.right - sliderSize.width - sliderHorizontalPadding);
  } else {
    return ;
  }
}

function updateImageHeight(image, screenSize) {
  return Core__Option.map(Organism_Slider_Ds.getWidthAndHeight(image, screenSize), (function (param) {
                return param.height;
              }));
}

function getSize(image, screenSize) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(image, (function (__x) {
                        return Organism_Slider_Ds.getWidthAndHeight(__x, screenSize);
                      })), (function (param) {
                    return [
                            CssJs.px(param.width),
                            CssJs.px(param.height)
                          ];
                  })), [
              CssJs.px(265),
              CssJs.pct(100)
            ]);
}

var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Array.pure(Particle_zIndex.Particle_zIndex_Css.toCss({
              TAG: "Exact",
              _0: "SliderTeaser"
            })));

function make(param) {
  var zone = param.zone;
  var theme = zone.theme;
  var itemTemplate = zone.itemTemplate;
  var itemTitle = zone.itemTitle;
  var title = zone.title;
  var key = zone.id;
  var firstTeaserToDisplay = param.firstTeaserToDisplay;
  var method = Core__Option.getOr(param.method, "Static");
  var sliderRef = React.useRef(null);
  var pictureKind = Atom_Image_Selector.itemTemplateToImageStyle(itemTemplate);
  var metaInformationTitleId = key + "_title";
  var match = UseRefs.use();
  var setRef = match[1];
  var getRef = match[0];
  var screenSize = UseScreenResolution.make(undefined, undefined);
  var handleTeaserFocus$1 = React.useCallback((function (__x) {
          handleTeaserFocus(screenSize, sliderRef, __x);
        }), [
        screenSize,
        sliderRef
      ]);
  var match$1 = UseNextPage.make(zone);
  var async = match$1.value;
  var initialBehavior = React.useMemo((function () {
          if (firstTeaserToDisplay !== undefined) {
            return {
                    TAG: "WithPredicate",
                    _0: (function (arr) {
                        var index = Util_Array.findIndex(arr, (function (param) {
                                return param.id === firstTeaserToDisplay;
                              }));
                        return Core__Option.mapOr(index, false, (function (index) {
                                      return (index + 6 | 0) < arr.length;
                                    }));
                      })
                  };
          } else {
            return {
                    TAG: "NumberElement",
                    _0: Organism_Slider_Teaser_Constant.defaultNumberElement
                  };
          }
        }), [firstTeaserToDisplay]);
  var config = React.useMemo((function () {
          return UsePagination.makeConfig(async, 6, initialBehavior, undefined);
        }), [async]);
  var match$2 = UsePagination.use(config, zone.data);
  var data = match$2.data;
  var status = match$2.status;
  var verticalPadding = React.useMemo((function () {
          if (pictureKind !== undefined && typeof pictureKind !== "object" && pictureKind === "PortraitTeaser" && !(title !== undefined && title.TAG === "Displayed")) {
            return "M";
          } else {
            return "S";
          }
        }), [
        pictureKind,
        title
      ]);
  var heightComputation = React.useMemo((function () {
          if (pictureKind !== undefined) {
            return {
                    TAG: "Custom",
                    _0: (function (__x) {
                        return updateImageHeight(pictureKind, __x);
                      })
                  };
          } else {
            return "Auto";
          }
        }), [pictureKind]);
  var domain = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(Belt_Array.get(data, 0), (function (param) {
                  return param.parentPage;
                })), (function (param) {
              return param.domain;
            })), "replay");
  var style = React.useMemo((function () {
          switch (itemTemplate) {
            case "Landscape" :
                if (itemTitle === "NeverDisplayed") {
                  return Organism_Teaser_Ds.category;
                }
                break;
            case "LandscapeGenre" :
                return Organism_Teaser_Ds.genre(domain);
            default:
              
          }
          if (pictureKind !== undefined) {
            return typeof theme === "object" ? Organism_Teaser_Ds.playNext(pictureKind) : (
                      theme === "Afp" ? Organism_Teaser_Ds.afp(pictureKind) : (
                          theme === "Search" ? Organism_Teaser_Ds.search(pictureKind) : (
                              theme === "Info" ? Organism_Teaser_Ds.info(pictureKind) : (
                                  theme === "Classic" ? Organism_Teaser_Ds.classic(pictureKind, zone.genreStickerBehavior) : (
                                      theme === "User" ? Organism_Teaser_Ds.user(pictureKind) : Organism_Teaser_Ds.white(pictureKind)
                                    )
                                )
                            )
                        )
                    );
          }
          
        }), [
        itemTemplate,
        itemTitle,
        pictureKind,
        theme,
        zone.genreStickerBehavior
      ]);
  var match$3 = React.useMemo((function () {
          var index = Util_Array.findIndex(data, (function (param) {
                  return param.id === firstTeaserToDisplay;
                }));
          return Core__Option.getOr(Core__Option.map(index, (function (i) {
                            return [
                                    Core__Option.map(data[i], (function (param) {
                                            return param.id;
                                          })),
                                    Core__Option.map(data[i + 1 | 0], (function (param) {
                                            return param.id;
                                          }))
                                  ];
                          })), [
                      undefined,
                      undefined
                    ]);
        }), [
        data,
        firstTeaserToDisplay
      ]);
  var teaserNextId = match$3[1];
  var disabledTeaserId = match$3[0];
  var teaserElements = React.useMemo((function () {
          return data.map(function (teaser) {
                      var teaserRef = setRef(Type_String.Teaser.Id.toString(teaser.id));
                      if (style === undefined) {
                        return JsxRuntime.jsx(Atom_Box.make, {
                                    ref_: teaserRef
                                  });
                      }
                      var match;
                      var exit = 0;
                      if (teaserNextId !== undefined && teaserNextId === teaser.id) {
                        match = [
                          "PlayedNext",
                          style
                        ];
                      } else {
                        exit = 1;
                      }
                      if (exit === 1) {
                        match = disabledTeaserId !== undefined && disabledTeaserId === teaser.id ? [
                            "Disabled",
                            Organism_Teaser_Ds.setDisable(style)
                          ] : [
                            "Standard",
                            style
                          ];
                      }
                      return JsxRuntime.jsx(Organism_Teaser.make, {
                                  onFocus: handleTeaserFocus$1,
                                  status: match[0],
                                  style: match[1],
                                  teaser: teaser,
                                  template: itemTemplate,
                                  teaserRef: teaserRef
                                }, Type_String.Teaser.Key.toString(teaser.key));
                    });
        }), [
        data,
        disabledTeaserId,
        teaserNextId
      ]);
  var refFirstSelectedTeaser;
  var exit = 0;
  switch (status) {
    case "Initialized" :
    case "Completed" :
        exit = 1;
        break;
    default:
      refFirstSelectedTeaser = React.createRef();
  }
  if (exit === 1) {
    refFirstSelectedTeaser = Core__Option.flatMap(firstTeaserToDisplay, (function (id) {
            return getRef(Type_String.Teaser.Id.toString(id));
          }));
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              background: Caml_option.some(Particle_Background_Ds.slider(theme)),
              blockSpace: Caml_option.some(param.blockSpace),
              children: [
                JsxRuntime.jsx(Organism_Slider_Teaser_MetaInformation.make, {
                      id: metaInformationTitleId,
                      theme: theme,
                      description: zone.description,
                      title: title,
                      link: zone.link,
                      onPlayNextChange: param.onPlayNextChange,
                      method: method,
                      isPlayNextActivated: param.isPlayNextActivated,
                      blockSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Inside.makeTop("S")),
                      recommendation: param.recommendation
                    }),
                JsxRuntime.jsx(Organism_Slider.make, {
                      children: teaserElements,
                      selectedItem: refFirstSelectedTeaser,
                      heightComputation: heightComputation,
                      itemSpace: Caml_option.some(itemSpace),
                      selectItems: selectItems,
                      sliderRef: sliderRef,
                      verticalPadding: verticalPadding,
                      withLoader: {
                        status: status,
                        loadMore: match$2.loadMore,
                        getSize: (function (__x) {
                            return getSize(pictureKind, __x);
                          })
                      }
                    }, zone.id)
              ],
              id: zone.slug,
              identifier: param.identifier,
              ref_: param.parentRef,
              rules: Caml_option.some(rules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            }, key);
}

make.displayName = "Organism_Slider_Teaser";

export {
  selectItems ,
  itemSpace ,
  handleTeaserFocus ,
  updateImageHeight ,
  getSize ,
  rules ,
  make ,
}
/* itemSpace Not a pure module */
