// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Provider_I18n from "../../provider/Provider_I18n.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Replay_I18n_Provider(props) {
  return JsxRuntime.jsx(Provider_I18n.make, {
              locale: props.locale,
              labels: props.labels,
              formaters: props.formaters,
              children: props.children
            });
}

var make = Replay_I18n_Provider;

export {
  make ,
}
/* Provider_I18n Not a pure module */
