// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as UseOnBlur from "../../../hook/UseOnBlur.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_Common from "../../../type/Type_Common.res.mjs";
import * as Type_Header from "../../../type/Type_Header.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseTrapFocus from "../../../hook/UseTrapFocus.res.mjs";
import * as Atom_Image_Ds from "../../atom/Atom_Image_Ds.res.mjs";
import * as Atom_Image_Css from "../../atom/Atom_Image_Css.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_FavAlertButton from "../../atom/Atom_FavAlertButton.res.mjs";
import * as Organism_Teaser_Css from "./Organism_Teaser_Css.res.mjs";
import * as Particle_Screen_Css from "../../particle/Particle_Screen_Css.res.mjs";
import * as Organism_Teaser_Util from "./Organism_Teaser_Util.res.mjs";
import * as Organism_Teaser_Title from "./Organism_Teaser_Title.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Organism_Teaser_Subtitle from "./Organism_Teaser_Subtitle.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_Teaser_Hover_Play from "./Organism_Teaser_Hover_Play.res.mjs";
import * as User_Api__Sso__LastVieweds from "../../../user/User_Api/User_Api__Sso__LastVieweds.res.mjs";

var modalCloseButtonDisplayOrder = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1);

var textDisplayOrder = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2);

var modalCloseButtonBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("S", "Zero", "M", "Zero"));

var modalCloseButtonRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.position("absolute"),
      CssJs.top("zero"),
      CssJs.right("zero"),
      CssJs.transform({
            NAME: "translateY",
            VAL: {
              NAME: "percent",
              VAL: -100
            }
          })
    ]);

var contentBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"));

var contentInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var genreAndAudioVersionsInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var genreAndAudioVersionsBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("XS"));

var innerSpaceRowButtons = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var buttonFavoriteKey = "teaser-hover-fav-button";

var buttonPlayKey = "teaser-hover-play-button";

var buttonTrailerKey = "teaser-hover-trailer-button";

var buttonDeleteKey = "teaser-hover-delete-button";

var displayHover = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex");

var iconSize = Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS");

var display = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex");

var AudioVersions = {
  iconSize: iconSize,
  display: display
};

var documentAccessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "document", undefined);

var hoverZIndex = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "Teaser"
    });

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Behind",
      _0: "Teaser"
    });

function makeRules(mode) {
  var tmp;
  switch (mode.TAG) {
    case "MouseHover" :
        tmp = CssJs.opacity(0);
        break;
    case "Dialog" :
    case "MobileHover" :
        tmp = CssJs.opacity(0.8);
        break;
    
  }
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
              CssJs.position("fixed"),
              CssJs.top("zero"),
              CssJs.bottom("zero"),
              CssJs.left("zero"),
              CssJs.right("zero"),
              CssJs.pointerEvents("none"),
              zIndexRule,
              tmp
            ]);
}

function Organism_Teaser_Hover$Overlay(props) {
  return JsxRuntime.jsx(Atom_Box.make, {
              background: Caml_option.some(Particle_Background_Ds.teaserHoverNewOverlay),
              identifier: Caml_option.some(Identifiers.Teaser.hoverOverlay),
              rules: Caml_option.some(makeRules(props.mode))
            });
}

var Overlay = {
  zIndexRule: zIndexRule,
  makeRules: makeRules,
  make: Organism_Teaser_Hover$Overlay
};

function Organism_Teaser_Hover(props) {
  var teaser = props.teaser;
  var title = teaser.title;
  var subtitle = teaser.subtitle;
  var match = teaser.program;
  var programId = match.id;
  var key = teaser.key;
  var description = teaser.description;
  var audioVersions = teaser.audioVersions;
  var titleId = props.titleId;
  var subtitleId = props.subtitleId;
  var style = props.style;
  var pictureKind = props.pictureKind;
  var mode = props.mode;
  var hoverRef = props.hoverRef;
  var centerHoverRef = props.centerHoverRef;
  var match$1 = Context.Config.use();
  var match$2 = Context.I18n.use();
  var locale = match$2.locale;
  var labels = match$2.labels;
  var match$3 = labels.program;
  var match$4 = labels.accessibility;
  var favoriteAvailability = style.favorite;
  var descriptionStyle = style.description;
  var close = match$4.close;
  var titleStyle = Core__Option.map(style.title, Atom_Text_Ds.Teaser.Hover.toParent);
  var subtitleStyle = Core__Option.map(style.subtitle, Atom_Text_Ds.Teaser.Hover.toParent);
  var title$1;
  if (title !== undefined) {
    switch (title.TAG) {
      case "AccessibilityOnly" :
          var title$2 = title._0;
          if (subtitle !== undefined && subtitle.TAG === "Displayed") {
            var match$5 = subtitle.style;
            title$1 = match$5 === "TitleStyle" ? undefined : title$2;
          } else {
            title$1 = title$2;
          }
          break;
      case "Displayed" :
      case "HoverOnly" :
          title$1 = title._0;
          break;
      
    }
  } else {
    title$1 = undefined;
  }
  var makeDialogAriaLabelledby = function (id) {
    return Particle_Accessibility.AriaLabelledby.makeWithInputArray(Type_String.Teaser.Key.toString(key), [id]);
  };
  var dialogAriaLabelledBy;
  if (title$1 !== undefined) {
    dialogAriaLabelledBy = Core__Option.map(titleId, makeDialogAriaLabelledby);
  } else if (subtitle !== undefined && subtitle.TAG === "Displayed") {
    var match$6 = subtitle.style;
    dialogAriaLabelledBy = match$6 !== undefined && match$6 !== "TitleStyle" ? Core__Option.map(subtitleId, makeDialogAriaLabelledby) : undefined;
  } else {
    dialogAriaLabelledBy = undefined;
  }
  var match$7;
  switch (mode.TAG) {
    case "Dialog" :
        match$7 = [
          JsxRuntime.jsx(Atom_Row.make, {
                blockSpace: Caml_option.some(modalCloseButtonBlockSpace),
                children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                      identifier: Caml_option.some(Identifiers.Teaser.hoverCloseButton),
                      label: close,
                      onClick: mode._0,
                      style: Atom_Cta_Ds.Teaser.teaserHoverClose
                    }),
                displayOrder: Caml_option.some(modalCloseButtonDisplayOrder)
              }),
          mode._1,
          {
            TAG: "Dialog",
            _0: dialogAriaLabelledBy
          },
          JsxRuntime.jsx(Organism_Teaser_Hover$Overlay, {
                mode: mode
              })
        ];
        break;
    case "MouseHover" :
        match$7 = [
          null,
          mode._0,
          undefined,
          null
        ];
        break;
    case "MobileHover" :
        match$7 = [
          JsxRuntime.jsx(Atom_Row.make, {
                blockSpace: Caml_option.some(modalCloseButtonBlockSpace),
                children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                      identifier: Caml_option.some(Identifiers.Teaser.hoverCloseButton),
                      label: close,
                      onClick: mode._0,
                      style: Atom_Cta_Ds.Teaser.teaserHoverCloseMobile
                    }),
                displayOrder: Caml_option.some(modalCloseButtonDisplayOrder),
                rules: Caml_option.some(modalCloseButtonRules)
              }),
          mode._1,
          {
            TAG: "Dialog",
            _0: dialogAriaLabelledBy
          },
          JsxRuntime.jsx(Organism_Teaser_Hover$Overlay, {
                mode: mode
              })
        ];
        break;
    
  }
  var showTextPart = titleStyle !== undefined || subtitleStyle !== undefined ? true : descriptionStyle !== undefined;
  var panelHoverWrapperMinHeight = showTextPart ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Organism_Teaser_Css.getHoverWrapperMinHeight(pictureKind)) : (function (param) {
          return Particle_Screen_Ds.map(param.display, Organism_Teaser_Util.Hover.getHeightByImageSize);
        })(Atom_Image_Ds.view(Atom_Image_Ds.make(pictureKind)));
  var match$8 = React.useState(function () {
        return Particle_Screen_Constants.emptyRules;
      });
  var setPanelHoverPositionRules = match$8[1];
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          if (isMount.contents === true) {
            var rules = Particle_Screen_Ds.$$Array.concat(Organism_Teaser_Util.Hover.getPanelHoverPositionRules(centerHoverRef, pictureKind, panelHoverWrapperMinHeight, mode), Organism_Teaser_Css.getPanelHoverWrapperActiveRules(Organism_Teaser_Css.transitionRules, mode));
            setPanelHoverPositionRules(function (param) {
                  return rules;
                });
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [centerHoverRef]);
  UseOnBlur.make(hoverRef, match$7[1]);
  var match$9 = Atom_FavAlertButton.availabilityFromTeaserFavorite(teaser.favoriteStatus);
  var favoriteAvailability$1 = favoriteAvailability === "Upcoming" ? (
      match$9 === "Upcoming" ? "Upcoming" : "NotAvailable"
    ) : (
      favoriteAvailability === "Available" ? (
          match$9 === "Upcoming" ? "Upcoming" : (
              match$9 === "Available" ? "Available" : "NotAvailable"
            )
        ) : "NotAvailable"
    );
  var description$1 = descriptionStyle !== undefined && description !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(description),
          kind: "p",
          style: Atom_Text_Ds.Teaser.Hover.toParent(Caml_option.valFromOption(descriptionStyle))
        }) : null;
  var audioVersions$1 = audioVersions !== undefined && audioVersions.length !== 0 ? Caml_option.some(JsxRuntime.jsx(Atom_Row.make, {
              children: audioVersions.map(function (param, index) {
                    var label = param.label;
                    return JsxRuntime.jsxs(Atom_Cell.make, {
                                alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                kind: "ItemList",
                                children: [
                                  JsxRuntime.jsx(Atom_Icon.make, {
                                        icon: Type_Common.audioVersionCodeToIcon(param.code, "LightGrey"),
                                        size: iconSize,
                                        title: label
                                      }),
                                  JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                                        children: label
                                      })
                                ]
                              }, label + String(index));
                  }),
              display: Caml_option.some(display),
              kind: "List"
            })) : undefined;
  var content;
  var exit = 0;
  var title$3;
  var subtitle$1;
  var subtitle$2;
  if (title$1 !== undefined) {
    if (subtitle !== undefined) {
      if (subtitle.TAG === "Displayed") {
        var match$10 = subtitle.style;
        var subtitle$3 = subtitle.label;
        if (match$10 === "TitleStyle") {
          subtitle$2 = subtitle$3;
          exit = 2;
        } else {
          title$3 = title$1;
          subtitle$1 = subtitle$3;
          exit = 1;
        }
      } else {
        title$3 = title$1;
        subtitle$1 = subtitle._0;
        exit = 1;
      }
    } else {
      content = JsxRuntime.jsx(Atom_Column.make, {
            children: JsxRuntime.jsx(Organism_Teaser_Title.make, {
                  id: titleId,
                  title: title$1,
                  style: titleStyle,
                  identifier: Caml_option.some(Identifiers.Teaser.hoverTitle)
                })
          });
    }
  } else if (subtitle !== undefined) {
    if (subtitle.TAG === "Displayed") {
      subtitle$2 = subtitle.label;
      exit = 2;
    } else {
      subtitle$2 = subtitle._0;
      exit = 2;
    }
  } else {
    content = null;
  }
  switch (exit) {
    case 1 :
        content = JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Organism_Teaser_Title.make, {
                      id: titleId,
                      title: title$3,
                      style: titleStyle,
                      identifier: Caml_option.some(Identifiers.Teaser.hoverTitle)
                    }),
                JsxRuntime.jsx(Organism_Teaser_Subtitle.make, {
                      id: subtitleId,
                      identifier: Caml_option.some(Identifiers.Teaser.hoverSubtitle),
                      subtitle: subtitle$1,
                      style: subtitleStyle,
                      kind: "p"
                    })
              ]
            });
        break;
    case 2 :
        content = JsxRuntime.jsx(Atom_Column.make, {
              children: JsxRuntime.jsx(Organism_Teaser_Subtitle.make, {
                    id: subtitleId,
                    identifier: Caml_option.some(Identifiers.Teaser.hoverSubtitle),
                    subtitle: subtitle$2,
                    style: titleStyle,
                    kind: "h3"
                  })
            });
        break;
    
  }
  var trailerButton;
  if (match.trailer !== undefined && programId !== undefined) {
    var trailerUrl = match$1.api.getTrailerUrl(programId);
    trailerButton = JsxRuntime.jsx(Atom_Cta.Link.make, {
          href: Type_String.Href.toString(trailerUrl),
          label: match$3.trailer_button,
          style: Atom_Cta_Ds.Trailer.teaser
        }, buttonTrailerKey);
  } else {
    trailerButton = null;
  }
  var deleteTeaser = function (param) {
    var controller = new AbortController();
    var signal = controller.signal;
    User_Api__Sso__LastVieweds.$$delete({
            args: {
              programId: teaser.dsId
            },
            signal: signal,
            locale: locale
          }).then(function (param) {
          User_Observable.Progression.$$delete(undefined, undefined, teaser.dsId);
          switch (mode.TAG) {
            case "MouseHover" :
                return Promise.resolve(mode._0());
            case "Dialog" :
            case "MobileHover" :
                return Promise.resolve(mode._1());
            
          }
        });
  };
  var deleteFromHistoryButton = props.progression !== undefined ? JsxRuntime.jsx(Atom_Cta.Button.make, {
          label: match$3.history_delete,
          onClick: deleteTeaser,
          style: Atom_Cta_Ds.Delete.$$default
        }, buttonDeleteKey) : null;
  var genreLink = Core__Option.map(match.genre, (function (param) {
          var url = param.url;
          var label = param.label;
          if (url !== undefined) {
            return JsxRuntime.jsx(Atom_Cta.Link.make, {
                        href: Type_String.Program.Genre.Url.toString(url),
                        label: Type_String.Program.Genre.Label.toString(label),
                        style: Atom_Cta_Ds.Teaser.teaserGenre
                      });
          }
          var style = Atom_Cta_Ds.Teaser.teaserGenre.textStyle;
          return JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(Type_String.Program.Genre.Label.toString(label)),
                      style: style
                    });
        }));
  var rulesWidth = (function (param) {
        return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Screen_Css.toRules(undefined, undefined, Atom_Image_Css.imageWidthRules, param.display));
      })(Atom_Image_Ds.view(Atom_Image_Ds.make(pictureKind)));
  var rules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Organism_Teaser_Css.getPanelHoverWrapperRules(Caml_option.some(panelHoverWrapperMinHeight), pictureKind), Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [hoverZIndex])), rulesWidth), match$8[0]), Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.maxWidth(CssJs.vw(95))])), Particle_Screen_Ds.make(undefined, undefined, [CssJs.maxHeight({
                  NAME: "calc",
                  VAL: [
                    "sub",
                    {
                      NAME: "vh",
                      VAL: 100
                    },
                    {
                      NAME: "px",
                      VAL: Type_Header.Constants.height.m
                    }
                  ]
                })], undefined, undefined, [
            Particle_zIndex.Particle_zIndex_Css.toCss({
                  TAG: "Exact",
                  _0: "Header"
                }),
            CssJs.maxHeight({
                  NAME: "calc",
                  VAL: [
                    "sub",
                    {
                      NAME: "vh",
                      VAL: 100
                    },
                    {
                      NAME: "px",
                      VAL: Type_Header.Constants.height.default
                    }
                  ]
                })
          ]));
  UseTrapFocus.make(hoverRef);
  var tmp;
  if (showTextPart) {
    var tmp$1;
    var exit$1 = 0;
    if (audioVersions$1 !== undefined || genreLink !== undefined) {
      exit$1 = 1;
    } else {
      tmp$1 = null;
    }
    if (exit$1 === 1) {
      tmp$1 = JsxRuntime.jsxs(Atom_Row.make, {
            blockSpace: Caml_option.some(genreAndAudioVersionsBlockSpace),
            children: [
              Core__Option.getOr(genreLink, null),
              Core__Option.getOr(audioVersions$1, null)
            ],
            innerSpace: Caml_option.some(genreAndAudioVersionsInnerSpace),
            wrap: "OnOneLine"
          });
    }
    tmp = JsxRuntime.jsxs(Atom_Column.make, {
          blockSpace: Caml_option.some(contentBlockSpace),
          children: [
            content,
            description$1,
            tmp$1,
            JsxRuntime.jsxs(Atom_Row.make, {
                  children: [
                    JsxRuntime.jsx(Organism_Teaser_Hover_Play.make, {
                          identifier: Caml_option.some(Identifiers.Teaser.hoverPlayButton),
                          onClick: props.handleTeaserClick,
                          teaser: teaser,
                          parentComponent: "TeaserHover"
                        }, buttonPlayKey),
                    JsxRuntime.jsx(Atom_FavAlertButton.make, {
                          teaser: teaser,
                          styleVariation: {
                            TAG: "TeaserHover",
                            _0: props.parentContext
                          },
                          availability: favoriteAvailability$1
                        }, buttonFavoriteKey)
                  ],
                  innerSpace: Caml_option.some(innerSpaceRowButtons),
                  rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.rowGap(CssJs.px(10))]))
                }),
            JsxRuntime.jsxs(Atom_Row.make, {
                  children: [
                    trailerButton,
                    deleteFromHistoryButton
                  ],
                  innerSpace: Caml_option.some(innerSpaceRowButtons),
                  rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.rowGap(CssJs.px(10))]))
                })
          ],
          displayOrder: Caml_option.some(textDisplayOrder),
          innerSpace: Caml_option.some(contentInnerSpace),
          width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                match$7[3],
                JsxRuntime.jsx(Atom_Column.make, {
                      background: Caml_option.some(style.background),
                      children: JsxRuntime.jsxs(Atom_Column.make, {
                            accessibility: documentAccessibility,
                            alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                            children: [
                              match$7[0],
                              props.children,
                              tmp
                            ]
                          }),
                      display: Caml_option.some(displayHover),
                      identifier: Caml_option.some(Identifiers.Teaser.panelHoverWrapper),
                      kind: match$7[2],
                      ref_: hoverRef,
                      rules: Caml_option.some(rules)
                    })
              ]
            });
}

var modalCloseButtonDisplayOrderValue = 1;

var portalHoverId = "teaser-hover-portal";

var make = Organism_Teaser_Hover;

export {
  modalCloseButtonDisplayOrderValue ,
  modalCloseButtonDisplayOrder ,
  textDisplayOrder ,
  modalCloseButtonBlockSpace ,
  modalCloseButtonRules ,
  contentBlockSpace ,
  contentInnerSpace ,
  genreAndAudioVersionsInnerSpace ,
  genreAndAudioVersionsBlockSpace ,
  innerSpaceRowButtons ,
  portalHoverId ,
  buttonFavoriteKey ,
  buttonPlayKey ,
  buttonTrailerKey ,
  buttonDeleteKey ,
  displayHover ,
  AudioVersions ,
  documentAccessibility ,
  hoverZIndex ,
  Overlay ,
  make ,
}
/* modalCloseButtonDisplayOrder Not a pure module */
