'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Debounce from "../util/Util_Debounce.res.mjs";
import * as Particle_Screen_Css from "../design-system/particle/Particle_Screen_Css.res.mjs";

function make(defaultScreensizeOpt, onScreenSizeChange) {
  var defaultScreensize = defaultScreensizeOpt !== undefined ? defaultScreensizeOpt : ({
        NAME: "XS",
        VAL: 0
      });
  var match = React.useState(function () {
        return defaultScreensize;
      });
  var setScreenSize = match[1];
  var screenSize = match[0];
  React.useEffect((function () {
          var $$window$1 = window;
          var handleResize = function (param) {
            setScreenSize(function (param) {
                  return Particle_Screen_Css.getResolutionFromScreensize(window);
                });
          };
          handleResize();
          $$window$1.addEventListener("resize", Util_Debounce.make(handleResize, 50));
          return (function () {
                    $$window$1.removeEventListener("resize", handleResize);
                  });
        }), []);
  React.useEffect((function () {
          if (onScreenSizeChange !== undefined) {
            onScreenSizeChange(screenSize);
          }
          
        }), [screenSize]);
  return screenSize;
}

export {
  make ,
}
/* react Not a pure module */
