// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M25.6198 13.7899C25.1139 13.2893 24.2901 13.2893 23.7849 13.7899L23.2231 14.3472C22.3336 15.2321 20.2503 17.6945 20.2503 15.8264V6.57565C20.2503 5.86888 19.6651 5.2882 18.9538 5.2882C18.2425 5.2882 17.6573 5.86888 17.6573 6.57565V15.8309C17.6573 16.1866 17.3673 16.472 17.0109 16.472C16.6507 16.472 16.3645 16.1866 16.3645 15.8309V5.28745C16.3645 4.58067 15.7793 4 15.068 4C14.3567 4 13.7715 4.58067 13.7715 5.28745V15.8309C13.7715 16.1866 13.4815 16.472 13.1251 16.472C12.765 16.472 12.4788 16.1866 12.4788 15.8309V6.5749C12.4788 5.86812 11.8936 5.28745 11.1823 5.28745C10.471 5.28745 9.88575 5.86812 9.88575 6.5749V15.8309C9.88575 16.1866 9.59579 16.472 9.23939 16.472C8.8792 16.472 8.59302 16.1866 8.59302 15.8309V9.15055C8.59302 8.44377 8.00782 7.8631 7.29651 7.8631C6.5852 7.8631 6 8.44377 6 9.15055V18.4066C6 20.9815 7.29651 27.4142 13.776 27.4142C20.2601 27.4142 21.9832 19.2357 25.246 15.991L25.6251 15.612C26.1257 15.1113 26.1257 14.292 25.6206 13.7906L25.6198 13.7899Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
