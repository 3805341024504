// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Grid from "../../../atom/Atom_Grid.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as Binding_Date from "../../../../binding/Binding_Date.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Template_ProgramContent from "./Template_ProgramContent.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

function formatTimecode(timecode, dateWithFormat) {
  var timecodeFormat = timecode < Binding_Date.hourInSecond ? "m:ss" : "h:mm:ss";
  var now = new Date(0);
  now.setHours(0);
  now.setSeconds(timecode);
  return dateWithFormat(now, timecodeFormat);
}

var gridRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.gridTemplateColumns([
            CssJs.maxContent,
            CssJs.auto
          ]),
      CssJs.alignItems("baseline"),
      CssJs.justifyContent("flexStart")
    ]);

var gridColumnGap = Particle_Screen_Ds.make(undefined, undefined, undefined, "S", undefined, "XS");

var gridRowGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var titleBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("S"), undefined, Particle_Spacer_Ds.makeBottom("XS"));

function Template_ProgramContent_Chapters(props) {
  var onChapterClick = props.onChapterClick;
  var parentPage = props.parentPage;
  var baseUrl = props.baseUrl;
  var match = Context.I18n.use();
  var dateWithFormat = match.formaters.dateWithFormat;
  var match$1 = match.labels.program;
  var chapter_title = match$1.chapter_title;
  var match$2 = Context.Config.use();
  var getChapterUrl = match$2.api.getChapterUrl;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(Particle_Screen_Constants.lTopBlockSpace),
              children: [
                JsxRuntime.jsx(Atom_Row.make, {
                      blockSpace: Caml_option.some(titleBlockSpace),
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(parentPage !== undefined && parentPage.domain === "concert" ? match$1.chapter_title_concert : chapter_title),
                            style: Atom_Text_Ds.Slider.make("Title")
                          })
                    }),
                JsxRuntime.jsx(Atom_Grid.make, {
                      children: props.chapters.map(function (chapter) {
                            var chapterUrl = getChapterUrl(Type_String.Href.toString(baseUrl), chapter.timecode);
                            if (chapterUrl !== undefined) {
                              return JsxRuntime.jsxs(React.Fragment, {
                                          children: [
                                            JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                  href: chapterUrl,
                                                  label: formatTimecode(chapter.timecode, dateWithFormat),
                                                  onClick: (function ($$event) {
                                                      $$event.preventDefault();
                                                      onChapterClick(Type_String.Href.toString(baseUrl), chapter.timecode);
                                                    }),
                                                  style: Atom_Cta_Ds.chapter
                                                }),
                                            JsxRuntime.jsx(Template_ProgramContent.Value.make, {
                                                  value: chapter.title
                                                })
                                          ]
                                        }, chapterUrl);
                            } else {
                              return null;
                            }
                          }),
                      gapColumn: Caml_option.some(gridColumnGap),
                      gapRow: Caml_option.some(gridRowGap),
                      rules: Caml_option.some(gridRules)
                    })
              ]
            });
}

var make = Template_ProgramContent_Chapters;

export {
  formatTimecode ,
  gridRules ,
  gridColumnGap ,
  gridRowGap ,
  titleBlockSpace ,
  make ,
}
/* gridRules Not a pure module */
