// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

var endpoint = {
  NAME: "Static",
  VAL: "send-magic-link"
};

var options = {
  tokenKind: "Sso"
};

function makeBody(args) {
  return args_encode(args);
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: "EmailSent"
        };
}

var SendConfig = {
  args_encode: args_encode,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  endpoint: endpoint,
  domain: "Sso",
  options: options,
  method: "Post",
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Send = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode,
      errorResponse_decode: errorResponse_decode,
      domain: "Sso",
      mapError: mapError,
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var send = Send.call;

export {
  SendConfig ,
  Send ,
  send ,
}
/* Send Not a pure module */
