// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M26.4746 6H5.52545C4.78952 6.00081 4.19314 6.5972 4.19232 7.33313V21.4262C4.19314 22.1621 4.78952 22.7585 5.52545 22.7593H13.25L13.0214 23.902H11.4293C11.2189 23.902 11.0484 24.0725 11.0484 24.2829V25.4256C11.0484 25.6359 11.2189 25.8065 11.4293 25.8065H20.5707C20.7811 25.8065 20.9516 25.6359 20.9516 25.4256V24.2829C20.9516 24.0725 20.7811 23.902 20.5707 23.902H18.9786L18.7501 22.7593H26.4746C27.2105 22.7585 27.8069 22.1621 27.8077 21.4262V7.33313C27.8069 6.5972 27.2105 6.00081 26.4746 6ZM20.1899 24.6638V25.0447H11.8102V24.6638H20.1899ZM14.027 22.7593L13.7985 23.902H18.2016L17.9731 22.7593H14.027ZM27.0459 21.4261C27.0456 21.7416 26.79 21.9972 26.4745 21.9975H5.52541C5.20998 21.9972 4.95433 21.7416 4.95407 21.4261V20.4739H27.0459V21.4261ZM4.95407 19.7123H27.0459V7.33327C27.0456 7.01784 26.79 6.76219 26.4745 6.76193H5.52541C5.20998 6.76219 4.95433 7.01784 4.95407 7.33327V19.7123Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M16.3809 20.8549H15.6191C15.4088 20.8549 15.2382 21.0254 15.2382 21.2358C15.2382 21.4461 15.4088 21.6166 15.6191 21.6166H16.3809C16.5913 21.6166 16.7618 21.4461 16.7618 21.2358C16.7618 21.0254 16.5913 20.8549 16.3809 20.8549Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
