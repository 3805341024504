// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Atom_Icon_Ds from "../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function Molecule_Breadcrumb$Link(props) {
  var __weight = props.weight;
  var category = props.category;
  var label = props.label;
  var weight = __weight !== undefined ? __weight : "Regular";
  var textStyle;
  if (category !== undefined && category.TAG !== "VideoType") {
    var category$1 = category._0;
    textStyle = category$1 === "Concert" ? Atom_Text_Ds.Breadcrumb.concert(weight) : (
        category$1 === "MyAdvantage" || category$1 === "Default" ? Atom_Text_Ds.Breadcrumb.$$default(weight) : (
            category$1 === "Actualite" ? Atom_Text_Ds.Breadcrumb.actualite(weight) : (
                category$1 === "Cinema" ? Atom_Text_Ds.Breadcrumb.cinema(weight) : (
                    category$1 === "Serie" ? Atom_Text_Ds.Breadcrumb.serie(weight) : (
                        category$1 === "Voyage" ? Atom_Text_Ds.Breadcrumb.voyage(weight) : (
                            category$1 === "Culture" ? Atom_Text_Ds.Breadcrumb.culture(weight) : (
                                category$1 === "Histoire" ? Atom_Text_Ds.Breadcrumb.histoire(weight) : Atom_Text_Ds.Breadcrumb.science(weight)
                              )
                          )
                      )
                  )
              )
          )
      );
  } else {
    textStyle = Atom_Text_Ds.Breadcrumb.$$default(weight);
  }
  return JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
              children: JsxRuntime.jsx(Atom_Text.make, {
                    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
                    children: Caml_option.some(label),
                    style: textStyle
                  }),
              href: props.href,
              style: Atom_Cta_Ds.breadcrumb
            }, label);
}

var Link = {
  make: Molecule_Breadcrumb$Link
};

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

function Molecule_Breadcrumb$Separator(props) {
  var icon = Core__Option.getOr(Core__Option.flatMap(props.category, (function (category) {
              if (category.TAG === "VideoType") {
                return ;
              } else {
                return Caml_option.some(Atom_Icon_Ds.updateColor(Atom_Icon_Ds.Arrow.right, category._0));
              }
            })), Atom_Icon_Ds.Arrow.right);
  return JsxRuntime.jsx(Atom_Icon.make, {
              icon: icon,
              size: iconSize
            });
}

var Separator = {
  iconSize: iconSize,
  make: Molecule_Breadcrumb$Separator
};

var defaultBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone());

function make(param) {
  var category = param.category;
  var description = param.description;
  var links = param.links;
  var mainTitleTag = Core__Option.getOr(param.mainTitleTag, "div");
  var blockSpace = Core__Option.getOr(param.blockSpace, defaultBlockSpace);
  var match;
  var exit = 0;
  var len = links.length;
  if (len !== 1 && len !== 0) {
    match = [
      JsxRuntime.jsx(Molecule_Breadcrumb$Separator, {
            category: category
          }),
      Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexStart")
    ];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match = [
      null,
      Particle_Screen_Ds.make(undefined, undefined, "center", "flexStart", undefined, "center")
    ];
  }
  var separator = match[0];
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              blockSpace: Caml_option.some(blockSpace),
              children: [
                JsxRuntime.jsx(Atom_Row.make, {
                      children: links.map(function (param, index) {
                            var isLast = index === (links.length - 1 | 0);
                            var separator$1 = isLast ? (
                                description !== undefined && description !== "" ? JsxRuntime.jsx(Atom_Cell.make, {
                                        display: Caml_option.some(Atom_Text_Ds.Breadcrumb.desc.display),
                                        children: separator
                                      }) : null
                              ) : separator;
                            return JsxRuntime.jsxs(Atom_Row.make, {
                                        alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "center", "baseline", undefined, "center")),
                                        children: [
                                          JsxRuntime.jsx(Molecule_Breadcrumb$Link, {
                                                href: param.href,
                                                label: param.label,
                                                category: category,
                                                weight: param.weight
                                              }),
                                          separator$1
                                        ],
                                        identifier: Caml_option.some(Identifiers.Breadcrumb.title),
                                        innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"))
                                      }, index.toString());
                          }),
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
                      kind: {
                        TAG: "Text",
                        _0: mainTitleTag
                      }
                    }),
                description !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                        autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                        children: Caml_option.some(description),
                        identifier: Caml_option.some(Identifiers.Breadcrumb.description),
                        kind: "p",
                        style: Atom_Text_Ds.Breadcrumb.desc
                      }) : null
              ],
              identifier: Caml_option.some(Identifiers.Breadcrumb.item),
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
              justifyContent: Caml_option.some(match[1]),
              maxWidth: param.maxWidth,
              rules: param.rules
            });
}

export {
  Link ,
  Separator ,
  defaultBlockSpace ,
  make ,
}
/* iconSize Not a pure module */
