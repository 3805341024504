// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M4 25.8176V8.36477L5.63506 4H28V19.2741L21.4535 25.8165H16.5442L13.274 29.0909H9.9987V25.8176H4ZM25.8181 6.17985H7.81677V21.9986H12.7282V25.2678L15.9983 21.9986H21.9991L25.8181 18.1806V6.17985Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M13.8181 10.5454H16V17.0909H13.8181V10.5454Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M20.3636 10.5454H22.5455V17.0909H20.3636V10.5454Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
