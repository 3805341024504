// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M7.43148 18.7201C4.32203 15.6055 4.32203 10.5461 7.43148 7.43668C8.9862 5.88196 11.0297 5.10719 13.0732 5.10719C15.1167 5.10719 17.1602 5.88196 18.7149 7.43668C20.2228 8.9446 21.0496 10.9465 21.0496 13.0784C21.0496 15.2103 20.2176 17.2174 18.7149 18.7201C15.6003 21.8296 10.5409 21.8296 7.43148 18.7201ZM28.2928 28.22C28.6834 27.8295 28.6834 27.1963 28.2928 26.8058L21.5445 20.0586C21.1821 19.6962 21.1571 19.1213 21.4435 18.6963C22.5544 17.0479 23.1503 15.1067 23.1503 13.0732C23.1503 10.3797 22.1051 7.84746 20.202 5.94435C16.271 2.01855 9.87536 2.01855 5.94435 5.94435C2.01855 9.87536 2.01855 16.271 5.94435 20.202C7.90985 22.1675 10.4941 23.1503 13.0732 23.1503C15.0409 23.1503 17.0046 22.5814 18.6976 21.4426C19.1227 21.1567 19.6973 21.1818 20.0595 21.544L26.8071 28.2916C27.1976 28.6821 27.8308 28.6821 28.2213 28.2916L28.2928 28.22Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
