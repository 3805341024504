// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Organism_Footer from "../organism/footer/Organism_Footer.res.mjs";
import * as Organism_Header from "../organism/header/Organism_Header.res.mjs";
import * as Organism_Error500 from "../organism/oops/Organism_Error500.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Organism_Header.make, {}),
                JsxRuntime.jsx(Organism_Error500.make, {
                      title: param.title,
                      subTitle: param.subTitle,
                      cta: param.ctaLabel,
                      ctaHref: param.ctaHref,
                      imgAlt: param.imgAlt
                    }),
                JsxRuntime.jsx(Organism_Footer.make, {})
              ]
            });
}

function make$1(param) {
  return JsxRuntime.jsx(Organism_Error500.Frontend.make, {
              code: param.code
            });
}

var Frontend = {
  make: make$1
};

export {
  make ,
  Frontend ,
}
/* Atom_Column Not a pure module */
