// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as Type_SocialMedia from "../../../../type/Type_SocialMedia.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";

var rowGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, [CssJs.marginLeft({
            NAME: "px",
            VAL: 0
          })], undefined, [CssJs.marginLeft({
            NAME: "px",
            VAL: -15
          })]);

var rowBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("S"));

function Organism_Concert_SocialLinks(props) {
  var socialMediaList = Type_SocialMedia.fromHeader(props.socialNetworks);
  return JsxRuntime.jsx(Atom_Row.make, {
              blockSpace: Caml_option.some(rowBlockSpace),
              children: socialMediaList.map(function (param) {
                    var label = param.label;
                    return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                href: Type_String.Href.toString(param.href),
                                identifier: param.identifier,
                                screenReadersLabel: label,
                                style: Atom_Cta_Ds.Header.makeSocial(param.icon),
                                target: "_blank"
                              }, label);
                  }),
              gap: Caml_option.some(rowGap),
              rules: Caml_option.some(rules)
            });
}

var make = Organism_Concert_SocialLinks;

export {
  rowGap ,
  rules ,
  rowBlockSpace ,
  make ,
}
/* rowGap Not a pure module */
