// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function getOrElse($$default, v) {
  return Core__Option.getOr((v == null) ? undefined : Caml_option.some(v), $$default);
}

export {
  getOrElse ,
}
/* No side effect */
