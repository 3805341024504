// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Zone_Layout from "../../zone/Zone_Layout.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Layout_Constants from "../layout/Layout_Constants.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_Breadcrumb from "../molecule/Molecule_Breadcrumb.res.mjs";

function make(param) {
  var match = Context.I18n.use();
  var formaters = match.formaters;
  var match$1 = Context.Config.use();
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, "Genre", param.domain, match$1.serverTime, param.page);
  var links = [{
      href: page.url,
      label: page.title,
      weight: "Bold"
    }];
  var description = Core__Option.map(Type_String.NotEmpty.make(page.description), (function (description) {
          return Type_String.NotEmpty.toString(description);
        }));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Molecule_Breadcrumb.make, {
                      maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
                      links: links,
                      description: description,
                      rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Layout_Constants.margin])),
                      blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "XL"), undefined, undefined, Particle_Spacer_Ds.makeAll("S"))),
                      category: page.page,
                      mainTitleTag: "h1"
                    }),
                JsxRuntime.jsx(Zone_Layout.make, {
                      page: page,
                      pageKind: "Genre",
                      numberZone: param.numberZone
                    })
              ]
            });
}

export {
  make ,
}
/* Context Not a pure module */
