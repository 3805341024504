// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Loader from "../../atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var loaderBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XL"));

var loaderMinHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "vh",
      VAL: 100
    });

function Molecule_Guide_Loader(props) {
  return JsxRuntime.jsx(Atom_Cell.make, {
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
              blockSpace: Caml_option.some(loaderBlockSpace),
              minHeight: Caml_option.some(loaderMinHeight),
              children: JsxRuntime.jsx(Atom_Loader.make, {
                    variant: "guideTv"
                  })
            });
}

var make = Molecule_Guide_Loader;

export {
  loaderBlockSpace ,
  loaderMinHeight ,
  make ,
}
/* loaderBlockSpace Not a pure module */
