// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Teaser_Title from "./Organism_Teaser_Title.res.mjs";
import * as Organism_Teaser_Subtitle from "./Organism_Teaser_Subtitle.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_Teaser_Animated_Title from "./Organism_Teaser_Animated_Title.res.mjs";

var landscapeGenreRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      Particle_zIndex.Particle_zIndex_Css.toCss({
            TAG: "Behind",
            _0: "SliderArrow"
          }),
      CssJs.width(CssJs.pct(95)),
      CssJs.height(CssJs.pct(100)),
      CssJs.transform(CssJs.translate({
                NAME: "percent",
                VAL: -50
              }, {
                NAME: "percent",
                VAL: -50
              }))
    ]);

function Organism_Teaser_Legend(props) {
  var teaserTemplate = props.teaserTemplate;
  var titleStyle = props.titleStyle;
  var title = props.title;
  var subtitleStyle = props.subtitleStyle;
  var subtitle = props.subtitle;
  var genreStyle = props.genreStyle;
  var genre = props.genre;
  var getSubtitleDsStyle = function (__x) {
    return Type_Teaser.getSubtitleDsStyle(titleStyle, subtitleStyle, __x);
  };
  var genre$1;
  if (genre !== undefined && genreStyle !== undefined) {
    var url = genre.url;
    var label = genre.label;
    genre$1 = url !== undefined ? JsxRuntime.jsx(Atom_Cta.Link.make, {
            href: Type_String.Program.Genre.Url.toString(url),
            label: Type_String.Program.Genre.Label.toString(label),
            style: genreStyle
          }) : JsxRuntime.jsx(Atom_Text.make, {
            children: Caml_option.some(Type_String.Program.Genre.Label.toString(label)),
            style: genreStyle.textStyle
          });
  } else {
    genre$1 = null;
  }
  var content;
  var exit = 0;
  if (title !== undefined) {
    switch (title.TAG) {
      case "Displayed" :
          var title$1 = title._0;
          content = teaserTemplate === "LandscapeGenre" ? Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(Organism_Teaser_Animated_Title.make, {
                              title: title$1,
                              style: titleStyle,
                              hoverBehavior: props.hoverBehavior
                            }),
                        genre$1
                      ]
                    })) : (
              subtitle !== undefined ? (
                  subtitle.TAG === "Displayed" ? Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(Organism_Teaser_Title.make, {
                                      title: title$1,
                                      style: titleStyle,
                                      identifier: Caml_option.some(Identifiers.Teaser.title)
                                    }),
                                JsxRuntime.jsx(Organism_Teaser_Subtitle.make, {
                                      identifier: Caml_option.some(Identifiers.Teaser.subtitle),
                                      subtitle: subtitle.label,
                                      style: subtitleStyle
                                    }),
                                genre$1
                              ]
                            })) : undefined
                ) : Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx(Organism_Teaser_Title.make, {
                                  title: title$1,
                                  style: titleStyle,
                                  identifier: Caml_option.some(Identifiers.Teaser.title)
                                }),
                            genre$1
                          ]
                        }))
            );
          break;
      case "AccessibilityOnly" :
          content = subtitle !== undefined && subtitle.TAG === "Displayed" ? Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(Organism_Teaser_Title.make, {
                              title: title._0,
                              identifier: Caml_option.some(Identifiers.Teaser.title)
                            }),
                        JsxRuntime.jsx(Organism_Teaser_Subtitle.make, {
                              identifier: Caml_option.some(Identifiers.Teaser.subtitle),
                              subtitle: subtitle.label,
                              style: getSubtitleDsStyle(subtitle.style),
                              kind: "h3"
                            }),
                        genre$1
                      ]
                    })) : undefined;
          break;
      case "HoverOnly" :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    content = subtitle !== undefined && subtitle.TAG === "Displayed" ? Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Organism_Teaser_Subtitle.make, {
                        identifier: Caml_option.some(Identifiers.Teaser.subtitle),
                        subtitle: subtitle.label,
                        style: getSubtitleDsStyle(subtitle.style),
                        kind: "h3"
                      }),
                  genre$1
                ]
              })) : undefined;
  }
  var match;
  match = teaserTemplate === "LandscapeGenre" ? [
      Caml_option.some(landscapeGenreRules),
      Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                TAG: "Absolute",
                _0: {
                  top: CssJs.pct(50),
                  left: CssJs.pct(50)
                }
              }))
    ] : [
      undefined,
      undefined
    ];
  if (content !== undefined) {
    return JsxRuntime.jsx(Atom_Column.make, {
                blockSpace: Caml_option.some(props.containerBlockspace),
                children: Caml_option.valFromOption(content),
                justifyContent: props.justifyContent,
                position: match[1],
                rules: match[0],
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
              });
  } else {
    return null;
  }
}

var make = Organism_Teaser_Legend;

export {
  landscapeGenreRules ,
  make ,
}
/* landscapeGenreRules Not a pure module */
