'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as React from "react";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Belt_MutableQueue from "@rescript/std/lib/es6/belt_MutableQueue.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Belt_MutableMapString from "@rescript/std/lib/es6/belt_MutableMapString.js";

function Make(B) {
  var toasts = Belt_MutableQueue.make();
  var displayedToast = {
    contents: undefined
  };
  var timeoutId = {
    contents: undefined
  };
  var subscribers = Belt_MutableMapString.make();
  var status = {
    contents: "NotInitialized"
  };
  var $$clearTimeout$1 = function () {
    Core__Option.forEach(timeoutId.contents, (function (__x) {
            clearTimeout(__x);
          }));
  };
  var remove = function () {
    $$clearTimeout$1();
    displayedToast.contents = undefined;
    notify();
  };
  var notify = function () {
    var match = displayedToast.contents;
    if (match !== undefined) {
      return ;
    }
    var firstToast = Belt_MutableQueue.pop(toasts);
    if (firstToast === undefined) {
      return Belt_MutableMapString.forEach(subscribers, (function (param, callback) {
                    callback(undefined);
                  }));
    }
    var delay = firstToast.delay;
    displayedToast.contents = firstToast;
    Belt_MutableMapString.forEach(subscribers, (function (param, callback) {
            callback(firstToast);
          }));
    if (delay !== undefined) {
      $$clearTimeout$1();
      timeoutId.contents = Caml_option.some(setTimeout((function () {
                  remove();
                }), delay));
      return ;
    }
    
  };
  var subscribe = function (callback) {
    var id = Uuid.v4();
    Belt_MutableMapString.set(subscribers, id, callback);
    return function () {
      Belt_MutableMapString.remove(subscribers, id);
    };
  };
  var t_add = function (delay, value) {
    var match = status.contents;
    if (match === "NotInitialized") {
      console.warn("Toaster not initialized");
    }
    var id = Uuid.v4();
    var toast_createdAt = new Date();
    var toast = {
      id: id,
      delay: delay,
      createdAt: toast_createdAt,
      props: value
    };
    Belt_MutableQueue.add(toasts, toast);
    notify();
    return id;
  };
  var t = {
    add: t_add,
    remove: remove
  };
  var use = function () {
    React.useEffect((function () {
            notify();
          }), []);
    return t;
  };
  var Toaster$Make$Container = function (props) {
    var match = status.contents;
    if (match === "NotInitialized") {
      status.contents = "Idle";
    }
    var match$1 = React.useState(function () {
          
        });
    var setToast = match$1[1];
    var toast = match$1[0];
    React.useEffect((function () {
            return subscribe(function (toast) {
                        setToast(function (param) {
                              return toast;
                            });
                      });
          }), undefined);
    if (toast !== undefined) {
      return JsxRuntime.jsx(B.Toast.make, {
                  value: toast.props,
                  delay: toast.delay,
                  remove: remove
                }, toast.id);
    } else {
      return null;
    }
  };
  var Container = {
    make: Toaster$Make$Container
  };
  return {
          Hook: {
            use: use
          },
          Container: Container
        };
}

export {
  Make ,
}
/* uuid Not a pure module */
