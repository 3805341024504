// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Util_Css from "../../../util/Util_Css.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Logo from "../../atom/Atom_Logo.res.mjs";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Particle_Css from "../../particle/Particle_Css.res.mjs";
import * as Particle_Color from "../../particle/Particle_Color.res.mjs";
import * as Particle_Spacer from "../../particle/Particle_Spacer.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Layout_Guide_Title from "./Layout_Guide_Title.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Teaser_Guide from "../../organism/guide/Organism_Teaser_Guide.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var columnSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "Zero");

var identifier = Identifiers.Layout_Guide.column;

var selector = Util_Css.createIdentifierSelector([Identifiers.toString(identifier)]);

function selectItems(__x) {
  return __x.querySelectorAll(selector);
}

function getSize(kind) {
  var match = Type_Teaser.Kind.view(kind);
  if (match === "Live" || match === "Direct" || match === "PrimeTime") {
    return "Big";
  } else {
    return "Standard";
  }
}

function getStyle(pageKind, teaserKind) {
  var size = getSize(teaserKind);
  if (typeof pageKind !== "object") {
    if (pageKind === "Concert") {
      return {
              TAG: "Concert",
              _0: size
            };
    } else {
      return {
              TAG: "Tv",
              _0: size
            };
    }
  } else if (pageKind.TAG === "Guide" && pageKind._0 === "Concert") {
    return {
            TAG: "Concert",
            _0: size
          };
  } else {
    return {
            TAG: "Tv",
            _0: size
          };
  }
}

var width = Particle_Screen_Ds.make(undefined, undefined, undefined, CssJs.pct(33.333), undefined, CssJs.pct(100));

var widthAndSpace = Particle_Screen_Ds.zipWith((function (width, columnSpace) {
        var space = CssJs.px(Particle_Spacer.spacerToInt(columnSpace));
        return Css_Js_Core.Calc.$neg(width, space);
      }), width, columnSpace);

var columnRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_Css.scrollSnapAlign("Start")]);

var emptyColumnrules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.overflow("hidden"),
      CssJs.maxHeight({
            NAME: "vh",
            VAL: 70
          })
    ]);

var imageRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 85
          }),
      CssJs.width({
            NAME: "px",
            VAL: 140
          })
    ], [
      CssJs.height({
            NAME: "px",
            VAL: 116
          }),
      CssJs.width({
            NAME: "px",
            VAL: 206
          })
    ], undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 63
          }),
      CssJs.width({
            NAME: "px",
            VAL: 112
          })
    ]);

var dateRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 25
          }),
      CssJs.width({
            NAME: "px",
            VAL: 75
          })
    ], undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 15
          }),
      CssJs.width({
            NAME: "px",
            VAL: 49
          })
    ]);

var timeRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 15
          }),
      CssJs.width({
            NAME: "px",
            VAL: 40
          })
    ], undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 15
          }),
      CssJs.width({
            NAME: "px",
            VAL: 32
          })
    ]);

var titleRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 25
          }),
      CssJs.width({
            NAME: "px",
            VAL: 155
          })
    ], undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 15
          }),
      CssJs.width({
            NAME: "px",
            VAL: 120
          })
    ]);

var descRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 15
          }),
      CssJs.width({
            NAME: "px",
            VAL: 70
          })
    ], undefined, undefined, [
      CssJs.height({
            NAME: "px",
            VAL: 15
          }),
      CssJs.width({
            NAME: "px",
            VAL: 50
          })
    ]);

var rules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.unsafe("gap", "10px")], undefined, undefined, [CssJs.unsafe("gap", "5px")]);

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeBottom("XL"), undefined, undefined, Particle_Spacer_Ds.makeBottom("M"));

var rowGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

function Layout_Guide_Column$EmptyColumn$TeaserPlaceholder(props) {
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Row.make, {
                        alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                        blockSpace: Caml_option.some(blockSpace),
                        children: [
                          JsxRuntime.jsxs(Atom_Column.make, {
                                children: [
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                        children: "",
                                        rules: Caml_option.some(dateRules)
                                      }),
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                        children: "",
                                        rules: Caml_option.some(dateRules)
                                      }),
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfEnd),
                                        background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                        children: "",
                                        rules: Caml_option.some(timeRules)
                                      })
                                ],
                                rules: Caml_option.some(rules)
                              }),
                          JsxRuntime.jsx(Atom_Row.make, {
                                alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                children: JsxRuntime.jsx(Atom_Logo.make, {
                                      logo: "ArtePlaceHolder"
                                    }),
                                justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                                rules: Caml_option.some(imageRules)
                              }),
                          JsxRuntime.jsxs(Atom_Column.make, {
                                children: [
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                        children: "",
                                        rules: Caml_option.some(titleRules)
                                      }),
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                        children: "",
                                        rules: Caml_option.some(descRules)
                                      }),
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        background: Caml_option.some(Particle_Background_Ds.emptyGuideTeaser),
                                        children: "",
                                        rules: Caml_option.some(descRules)
                                      })
                                ],
                                rules: Caml_option.some(rules)
                              })
                        ],
                        gap: Caml_option.some(rowGap),
                        wrap: "OnOneLine"
                      }))
            });
}

var TeaserPlaceholder = {
  background: Particle_Background_Ds.emptyGuideTeaser,
  imageRules: imageRules,
  dateRules: dateRules,
  timeRules: timeRules,
  titleRules: titleRules,
  descRules: descRules,
  rules: rules,
  blockSpace: blockSpace,
  rowGap: rowGap,
  make: Layout_Guide_Column$EmptyColumn$TeaserPlaceholder
};

var alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var opacityLayerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.width({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.background({
            NAME: "linearGradient",
            VAL: [
              CssJs.deg(0),
              [
                [
                  CssJs.pct(0),
                  Particle_Color.Css.color("Background")
                ],
                [
                  CssJs.pct(100),
                  Particle_Color.Css.color("Transparent")
                ]
              ]
            ]
          })
    ]);

var position = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: "zero",
        bottom: "zero"
      }
    });

function Layout_Guide_Column$EmptyColumn(props) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(alignItems),
              children: [
                JsxRuntime.jsx(Layout_Guide_Column$EmptyColumn$TeaserPlaceholder, {}),
                JsxRuntime.jsx(Layout_Guide_Column$EmptyColumn$TeaserPlaceholder, {}),
                JsxRuntime.jsx(Layout_Guide_Column$EmptyColumn$TeaserPlaceholder, {}),
                JsxRuntime.jsx(Layout_Guide_Column$EmptyColumn$TeaserPlaceholder, {}),
                JsxRuntime.jsx(Layout_Guide_Column$EmptyColumn$TeaserPlaceholder, {}),
                JsxRuntime.jsx(Atom_Cell.make, {
                      children: "",
                      position: Caml_option.some(position),
                      rules: Caml_option.some(opacityLayerRules)
                    })
              ],
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              rules: Caml_option.some(emptyColumnrules)
            });
}

var EmptyColumn = {
  TeaserPlaceholder: TeaserPlaceholder,
  alignItems: alignItems,
  opacityLayerRules: opacityLayerRules,
  position: position,
  make: Layout_Guide_Column$EmptyColumn
};

var display = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block");

function Layout_Guide_Column(props) {
  var pageKind = props.pageKind;
  var teasers = props.teasers;
  var getStyle$1 = React.useCallback((function (teaserKind) {
          return getStyle(pageKind, teaserKind);
        }), [pageKind]);
  var match = teasers !== undefined ? [
      Util_Array.NonEmpty.toArray(Util_Array.NonEmpty.map(Caml_option.valFromOption(teasers), (function (teaser) {
                  var style = getStyle$1(teaser.kind);
                  return JsxRuntime.jsx(Organism_Teaser_Guide.make, {
                              kind: "ItemList",
                              teaser: teaser,
                              style: style
                            }, Type_String.Teaser.Key.toString(teaser.key));
                }))),
      "List",
      undefined
    ] : [
      JsxRuntime.jsx(Layout_Guide_Column$EmptyColumn, {}),
      undefined,
      Caml_option.some(emptyColumnrules)
    ];
  return JsxRuntime.jsxs(Atom_Column.make, {
              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceNeeded),
              children: [
                JsxRuntime.jsx(Layout_Guide_Title.make, {
                      label: props.title,
                      titleRef: props.titleRef
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      children: match[0],
                      display: Caml_option.some(display),
                      kind: match[1],
                      rules: match[2]
                    })
              ],
              identifier: Caml_option.some(identifier),
              kind: "Section",
              rules: Caml_option.some(columnRules),
              width: Caml_option.some(widthAndSpace)
            });
}

var make = Layout_Guide_Column;

export {
  columnSpace ,
  identifier ,
  selector ,
  selectItems ,
  getSize ,
  getStyle ,
  width ,
  widthAndSpace ,
  columnRules ,
  emptyColumnrules ,
  EmptyColumn ,
  display ,
  make ,
}
/* columnSpace Not a pure module */
