// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Atom_Icon_Css from "./Atom_Icon_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Atom_Icon(props) {
  var ariaLabel = props.ariaLabel;
  var title = props.title;
  var icon = props.icon;
  var match = Atom_Icon_Ds.view(icon);
  var match$1 = Atom_Icon_Css.toCss(icon, props.size);
  var svgClassName = (function (__x) {
        return CssJs.merge(__x);
      })(match$1.svg.map(function (rule) {
            var __x = [rule];
            return CssJs.style(__x);
          }));
  var ariaHidden = props.ariaHidden !== undefined ? undefined : true;
  var ariaLabel$1 = ariaLabel !== undefined ? ariaLabel : undefined;
  return JsxRuntime.jsxs("svg", {
              children: [
                title !== undefined ? JsxRuntime.jsx("title", {
                        children: title
                      }) : null,
                JsxRuntime.jsx(match.component.make, {
                      color: match.color
                    })
              ],
              "aria-hidden": ariaHidden,
              "aria-label": ariaLabel$1,
              className: svgClassName,
              role: props.role,
              focusable: "false",
              viewBox: match.viewBox,
              xmlns: "http://www.w3.org/2000/svg"
            });
}

var make = Atom_Icon;

export {
  make ,
}
/* CssJs Not a pure module */
