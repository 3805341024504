// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M26.2727 3.00024L28.9985 5.72778L18.7272 15.999L29.0022 26.274L26.2762 29.0001L16.0012 18.725L5.72753 28.9987L3 26.2729L13.2739 15.999L3.00378 5.72885L5.73104 3.0016L16.0012 13.2717L26.2727 3.00024Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
