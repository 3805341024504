// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function make(primaryTitle, param) {
  var titles = [
    "h1",
    "h2",
    "h3"
  ];
  var dsHighlightTitleTag = primaryTitle === "h1" ? "h1" : "h2";
  var indexTitleTag = Core__Option.flatMap(dsHighlightTitleTag, (function (tag) {
          return Util_Array.findIndex(titles, (function (extra) {
                        return Caml_obj.equal(tag, extra);
                      }));
        }));
  var titleTag = Core__Option.flatMap(indexTitleTag, (function (extra) {
          return titles[extra];
        }));
  var subtitleTag = Core__Option.flatMap(indexTitleTag, (function (index) {
          return titles[index + 1 | 0];
        }));
  return [
          Core__Option.getOr(titleTag, "h2"),
          Core__Option.getOr(subtitleTag, "h3")
        ];
}

export {
  make ,
}
/* No side effect */
