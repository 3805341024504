// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M21.7903 3H10.4677C9.6575 3.0009 9.0009 3.6575 9 4.46774V27.5323C9.0009 28.3425 9.6575 28.9991 10.4677 29H21.7903C22.6006 28.9991 23.2572 28.3425 23.2581 27.5323V4.46774C23.2572 3.6575 22.6006 3.0009 21.7903 3ZM17.6875 3.83882L17.4778 4.67753H14.7777L14.568 3.83882H17.6875ZM21.7902 28.1614C22.1375 28.1611 22.419 27.8797 22.4193 27.5324V4.46786C22.419 4.12057 22.1375 3.83911 21.7902 3.83882H18.5518L18.2121 5.19858C18.1654 5.38525 17.9977 5.51621 17.8053 5.51624H14.4505C14.258 5.51652 14.0901 5.38576 14.0432 5.19911L13.703 3.83882H10.4677C10.1204 3.83911 9.83893 4.12057 9.83864 4.46786V27.5324C9.83893 27.8797 10.1204 28.1611 10.4677 28.1614H21.7902Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M17.387 26.9033H14.8709C14.6393 26.9033 14.4515 27.0911 14.4515 27.3227C14.4515 27.5543 14.6393 27.742 14.8709 27.742H17.387C17.6186 27.742 17.8064 27.5543 17.8064 27.3227C17.8064 27.0911 17.6186 26.9033 17.387 26.9033Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
