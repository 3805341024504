// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Atom_Switch_Ds from "./Atom_Switch_Ds.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.res.mjs";
import * as Particle_Css_font from "../particle/Particle_Css_font.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Background_Css from "../particle/Particle_Background_Css.res.mjs";

function makeToggleFocus(outline) {
  return [
          CssJs.outline(CssJs.px(1), CssJs.solid, Particle_Color.Css.color(outline)),
          CssJs.boxShadow(Css_Js_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(8), undefined, undefined, Particle_Color.Css.color(outline)))
        ];
}

var toggleDefaultBeforeAfterRules = [
  CssJs.transition(100, undefined, Css.cubicBezier(0.4, 0, 0.2, 1), "all"),
  CssJs.display("flex"),
  CssJs.justifyContent("center"),
  CssJs.alignItems("center")
];

function knobSizeToRule(knobSize) {
  return Particle_Screen_Ds.map(knobSize, (function (value) {
                return [
                        CssJs.height(value),
                        CssJs.width(value)
                      ];
              }));
}

function toBackGroundRules(backgroundSize) {
  return Particle_Screen_Ds.zipWith((function (width, height) {
                return [
                        CssJs.width(width),
                        CssJs.height(height)
                      ];
              }), backgroundSize.width, backgroundSize.height);
}

function getKnobSize(size) {
  if (size === "XS") {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(18));
  } else {
    return Particle_Screen_Ds.make(undefined, undefined, CssJs.px(31), undefined, undefined, CssJs.px(25));
  }
}

function getBackgroundSize(size) {
  if (size === "XS") {
    return {
            width: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(30)),
            height: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(12))
          };
  } else {
    return {
            width: Particle_Screen_Ds.make(undefined, undefined, CssJs.px(53), undefined, undefined, CssJs.px(40)),
            height: Particle_Screen_Ds.make(undefined, undefined, CssJs.px(21), undefined, undefined, CssJs.px(15))
          };
  }
}

function getTransformRules(size, transform) {
  var knobSize = getKnobSize(size);
  var backgroundSize = getBackgroundSize(size);
  return Particle_Screen_Ds.zipWith((function (value, bgWidth) {
                var tmp;
                tmp = transform === "Left" ? CssJs.translate3d(CssJs.px(0), CssJs.pct(-50), CssJs.px(0)) : CssJs.translate3d(Css_Legacy_Core.Calc.$neg(bgWidth, value), CssJs.pct(-50), CssJs.px(0));
                return [CssJs.transform(tmp)];
              }), knobSize, backgroundSize.width);
}

function makeForeground(param) {
  var size = param.size;
  var bgRules = Particle_Background_Css.toCss(param.circle);
  var rules = [
    CssJs.color(Particle_Color.Css.color(param.color)),
    CssJs.borderRadius(CssJs.pct(50))
  ];
  var rules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
          rules,
          toggleDefaultBeforeAfterRules,
          bgRules
        ].flat());
  var sizeRules = knobSizeToRule(getKnobSize(size));
  var transformRules = getTransformRules(size, param.transform);
  return Particle_Screen_Ds.$$Array.concatMany([
              sizeRules,
              transformRules,
              rules$1
            ]);
}

function makeParent(param) {
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
              CssJs.cursor("pointer"),
              CssJs.alignItems("center"),
              CssJs.selector("input + span", [
                    CssJs.position("relative"),
                    Particle_Css_font.size("XXS")
                  ]),
              CssJs.selector("input:focus + span", makeToggleFocus(param.outline)),
              CssJs.selector("input", [
                    CssJs.position("absolute"),
                    CssJs.opacity(0),
                    CssJs.pointerEvents("none"),
                    CssJs.disabled([CssJs.selector("& + span", [
                                CssJs.boxShadow("none"),
                                CssJs.cursor("notAllowed"),
                                CssJs.filter([{
                                        NAME: "grayscale",
                                        VAL: 100
                                      }])
                              ])])
                  ])
            ]);
}

function makeBackground(param) {
  var bgRules = Particle_Background_Css.toCss(param.background);
  var rules = [CssJs.borderRadius(Particle_Css_font.pxToRem(15))];
  var sizeRules = toBackGroundRules(getBackgroundSize(param.size));
  var rules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
          rules,
          toggleDefaultBeforeAfterRules,
          bgRules
        ].flat());
  return Particle_Screen_Ds.$$Array.concatMany([
              sizeRules,
              rules$1
            ]);
}

function toCss(dsT, state) {
  var view = Atom_Switch_Ds.view(state, dsT);
  return {
          background: makeBackground(view),
          foreground: makeForeground(view),
          parent: makeParent(view),
          icon: view.icon
        };
}

export {
  makeToggleFocus ,
  toggleDefaultBeforeAfterRules ,
  knobSizeToRule ,
  toBackGroundRules ,
  getKnobSize ,
  getBackgroundSize ,
  getTransformRules ,
  makeForeground ,
  makeParent ,
  makeBackground ,
  toCss ,
}
/* toggleDefaultBeforeAfterRules Not a pure module */
