// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";

function userUrlWithLocale(baseUrl, locale) {
  return baseUrl + "?lang=" + locale;
}

function locale_encode(v) {
  switch (v) {
    case "Fr" :
        return "fr";
    case "De" :
        return "de";
    case "Pl" :
        return "pl";
    case "It" :
        return "it";
    case "En" :
        return "en";
    case "Es" :
        return "es";
    
  }
}

function locale_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("fr" === v) {
          return {
                  TAG: "Ok",
                  _0: "Fr"
                };
        } else if ("de" === v) {
          return {
                  TAG: "Ok",
                  _0: "De"
                };
        } else if ("pl" === v) {
          return {
                  TAG: "Ok",
                  _0: "Pl"
                };
        } else if ("it" === v) {
          return {
                  TAG: "Ok",
                  _0: "It"
                };
        } else if ("en" === v) {
          return {
                  TAG: "Ok",
                  _0: "En"
                };
        } else if ("es" === v) {
          return {
                  TAG: "Ok",
                  _0: "Es"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function localeFromLanguage(x) {
  if (x === "en") {
    return "En";
  } else if (x === "es") {
    return "Es";
  } else if (x === "fr") {
    return "Fr";
  } else if (x === "it") {
    return "It";
  } else if (x === "pl") {
    return "Pl";
  } else {
    return "De";
  }
}

function localeToString(x) {
  switch (x) {
    case "Fr" :
        return "fr";
    case "De" :
        return "de";
    case "Pl" :
        return "pl";
    case "It" :
        return "it";
    case "En" :
        return "en";
    case "Es" :
        return "es";
    
  }
}

function stringToLanguage(x) {
  switch (x) {
    case "de" :
        return "de";
    case "en" :
        return "en";
    case "es" :
        return "es";
    case "it" :
        return "it";
    case "pl" :
        return "pl";
    default:
      return "fr";
  }
}

function isEurope(locale) {
  switch (locale) {
    case "Fr" :
    case "De" :
        return false;
    default:
      return true;
  }
}

export {
  userUrlWithLocale ,
  locale_encode ,
  locale_decode ,
  localeFromLanguage ,
  localeToString ,
  stringToLanguage ,
  isEurope ,
}
/* No side effect */
