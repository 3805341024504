// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "@rescript/std/lib/es6/caml.js";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as Belt_Map from "@rescript/std/lib/es6/belt_Map.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

var cmp = Caml.string_compare;

var Cmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function getStatus(feature, t) {
  return Core__Option.getOr(Belt_Map.get(t, feature), "Off");
}

function make(t) {
  if (t === undefined) {
    return Belt_Map.make(Cmp);
  }
  var themeSwitch_1 = t.themeSwitch ? "On" : "Off";
  var themeSwitch = [
    "ThemeSwitch",
    themeSwitch_1
  ];
  var magicLinkConnection_1 = t.magicLinkConnection ? "On" : "Off";
  var magicLinkConnection = [
    "MagicLinkConnection",
    magicLinkConnection_1
  ];
  var improveComWithUser_1 = t.improveComWithUser ? "On" : "Off";
  var improveComWithUser = [
    "ImproveComWithUser",
    improveComWithUser_1
  ];
  var alertButton_1 = t.alertButton ? "On" : "Off";
  var alertButton = [
    "AlertButton",
    alertButton_1
  ];
  var comingSoonEuropeEntry_1 = t.comingSoonEuropeEntry ? "On" : "Off";
  var comingSoonEuropeEntry = [
    "ComingSoonEuropeEntry",
    comingSoonEuropeEntry_1
  ];
  return Belt_Map.fromArray([
              themeSwitch,
              magicLinkConnection,
              improveComWithUser,
              alertButton,
              comingSoonEuropeEntry
            ], Cmp);
}

export {
  getStatus ,
  make ,
}
/* Cmp Not a pure module */
