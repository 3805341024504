// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M16 4.4716C22.357 4.4716 27.5271 9.643 27.5284 15.9987C27.5284 22.3557 22.357 27.5271 16 27.5271C9.6443 27.5271 4.4729 22.357 4.4729 15.9987C4.4729 9.6443 9.6443 4.4716 16 4.4716ZM16 29C23.1682 29 29 23.1682 29 15.9987C29 8.8318 23.1682 3 16 3C8.8318 3 3 8.8318 3 15.9987C3 23.1682 8.8318 29 16 29ZM19.6231 18.0943C18.3348 18.0943 17.4196 17.0543 17.4196 15.8063V15.7855C17.4196 14.5388 18.314 13.5196 19.6036 13.5196C20.8932 13.5196 21.8071 14.5596 21.8071 15.8063V15.8271C21.8071 17.0738 20.9127 18.093 19.6231 18.0943ZM19.6036 19.5698C21.8474 19.5698 23.4802 17.8759 23.4802 15.8063V15.7855C23.4802 13.7172 21.8695 12.0428 19.6231 12.0428C17.378 12.0428 15.7465 13.738 15.7465 15.8063V15.8271C15.7465 17.8954 17.3598 19.5698 19.6036 19.5698ZM7.9569 12.1689H9.7236L11.6255 17.2922L13.5274 12.1689H15.2525L12.3106 19.497H10.8988L7.9569 12.1689Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
