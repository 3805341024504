// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

function Atom_Cell(props) {
  var __blockSpace = props.blockSpace;
  var blockSpace = __blockSpace !== undefined ? Caml_option.valFromOption(__blockSpace) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone());
  return JsxRuntime.jsx(Atom_Box.make, {
              accessibility: props.accessibility,
              alignItems: props.alignItems,
              alignSelf: props.alignSelf,
              animation: props.animation,
              area: props.area,
              autoSize: props.autoSize,
              background: props.background,
              border: props.border,
              children: Caml_option.some(props.children),
              dataAnchor: props.dataAnchor,
              deviceRules: props.deviceRules,
              direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row")),
              display: props.display,
              displayOrder: props.displayOrder,
              height: props.height,
              id: props.id,
              identifier: props.identifier,
              justifyContent: props.justifyContent,
              kind: props.kind,
              maxHeight: props.maxHeight,
              maxWidth: props.maxWidth,
              minHeight: props.minHeight,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              onMouseEnter: props.onMouseEnter,
              onMouseLeave: props.onMouseLeave,
              position: props.position,
              rules: props.rules,
              spacer: Caml_option.some(blockSpace),
              width: props.width,
              ref_: props.ref_,
              cbRef: props.cbRef
            });
}

Atom_Cell.displayName = "Cell";

var make = Atom_Cell;

export {
  make ,
}
/*  Not a pure module */
