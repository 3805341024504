// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../../CssJs.res.mjs";
import * as Util_Css from "../../../util/Util_Css.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Atom_Cta_Css from "../../atom/Atom_Cta_Css.res.mjs";
import * as Particle_Color from "../../particle/Particle_Color.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as Particle_Border_Ds from "../../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Border_Css from "../../particle/Particle_Border_Css.res.mjs";
import * as Particle_Background_Css from "../../particle/Particle_Background_Css.res.mjs";

var teaserImageIdentifier = Util_Css.createIdentifierSelector([Identifiers.toString(Identifiers.Teaser.img)]);

var teaserItemIdentifier = Util_Css.createIdentifierSelector([Identifiers.toString(Identifiers.Teaser.item)]);

var teaserItemLinkIdentifier = Util_Css.createIdentifierSelector([Identifiers.toString(Identifiers.Teaser.itemLink)]);

function teaserImageSelector(rules) {
  return CssJs.selector(teaserImageIdentifier, rules);
}

function teaserItemsSelector(rules) {
  return CssJs.selector(teaserItemIdentifier, rules);
}

function teaserItemLinkSelector(rules) {
  return CssJs.selector(teaserItemLinkIdentifier, rules);
}

var Selectors = {
  teaserImageIdentifier: teaserImageIdentifier,
  teaserItemIdentifier: teaserItemIdentifier,
  teaserItemLinkIdentifier: teaserItemLinkIdentifier,
  teaserImageSelector: teaserImageSelector,
  teaserItemsSelector: teaserItemsSelector,
  teaserItemLinkSelector: teaserItemLinkSelector
};

var transitionRules = [
  CssJs.transition(350, undefined, Css.cubicBezier(0.67, 0.29, 0.21, 1.03), "all"),
  CssJs.backfaceVisibility(CssJs.hidden)
];

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Behind",
      _0: "SliderArrow"
    });

var imgWrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.overflow(CssJs.hidden),
      CssJs.transformOrigin(CssJs.pct(50), CssJs.pct(0))
    ]);

function imgDisableRules(background) {
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.after([
                      CssJs.unsafe("content", ""),
                      CssJs.position(CssJs.absolute),
                      CssJs.display(CssJs.block),
                      CssJs.right(CssJs.px(0)),
                      CssJs.left(CssJs.px(0)),
                      CssJs.top(CssJs.px(0)),
                      CssJs.bottom(CssJs.px(0)),
                      zIndexRule,
                      CssJs.width(CssJs.pct(100))
                    ].concat(background !== undefined ? Particle_Background_Css.toCss(Caml_option.valFromOption(background)) : []))]);
}

var panelFocusRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.display("flex"),
      zIndexRule,
      CssJs.pointerEvents("none"),
      CssJs.height(CssJs.px(0)),
      CssJs.overflow("hidden"),
      CssJs.focusWithin([CssJs.height(CssJs.pct(100))])
    ], undefined, undefined, [CssJs.display("none")]);

function wrapperRules(backgroundOpt, borderOpt, param) {
  var background = backgroundOpt !== undefined ? Caml_option.valFromOption(backgroundOpt) : undefined;
  var border = borderOpt !== undefined ? Caml_option.valFromOption(borderOpt) : undefined;
  return Particle_Screen_Ds.make(undefined, undefined, [
                [CssJs.backgroundColor(Particle_Color.Css.color("Transparent"))],
                Particle_Border_Css.toCss(Particle_Border_Ds.none)
              ].flat(), undefined, undefined, [
                [CssJs.flex3(0, 0, Css.auto)],
                background !== undefined ? Particle_Background_Css.toCss(Caml_option.valFromOption(background)) : [],
                border !== undefined ? Particle_Border_Css.toCss(border) : []
              ].flat());
}

var mobileHoverButtonWrapperRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.display("none")], undefined, undefined, [CssJs.display("flex")]);

function wrapperGenreHoverRules(hoverBehavior) {
  switch (hoverBehavior) {
    case "Disabled" :
        var rules = [CssJs.before([CssJs.display("none")])];
        return [CssJs.selector(teaserItemLinkIdentifier, rules)];
    case "Normal" :
    case "Forced" :
        break;
    
  }
  var rules$1 = [
    CssJs.opacity(0.7),
    CssJs.transform({
          NAME: "scale",
          VAL: [
            1.0,
            1.0
          ]
        }),
    CssJs.transitions([
          Css_Js_Core.Transition.shorthand(1500, undefined, Atom_Cta_Css.transitionInvertedTimingIn, "transform"),
          Css_Js_Core.Transition.shorthand(1500, undefined, Atom_Cta_Css.transitionTimingIn, "opacity")
        ])
  ];
  return [CssJs.selector(teaserImageIdentifier, rules$1)];
}

function wrapperGenreRules(hoverBehavior) {
  var rules = [
    CssJs.opacity(0),
    CssJs.transform({
          NAME: "scale",
          VAL: [
            2,
            2
          ]
        }),
    CssJs.transitions([
          Css_Js_Core.Transition.shorthand(750, 750, Atom_Cta_Css.transitionInvertedTimingIn, "transform"),
          Css_Js_Core.Transition.shorthand(750, undefined, Atom_Cta_Css.transitionTimingIn, "opacity")
        ])
  ];
  var rules$1 = [CssJs.before([CssJs.transition(750, undefined, Atom_Cta_Css.transitionTimingIn, "border")])];
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
              CssJs.flex3(0, 0, Css.auto),
              CssJs.selector(teaserImageIdentifier, rules),
              CssJs.selector(teaserItemLinkIdentifier, rules$1),
              CssJs.before([
                    CssJs.unsafe("content", ""),
                    CssJs.position(CssJs.absolute),
                    CssJs.left(CssJs.zero),
                    CssJs.right(CssJs.zero),
                    CssJs.bottom(CssJs.zero),
                    CssJs.top(CssJs.zero),
                    CssJs.backgroundImage({
                          NAME: "linearGradient",
                          VAL: [
                            CssJs.deg(0),
                            [
                              [
                                CssJs.pct(0),
                                CssJs.rgb(0, 0, 0)
                              ],
                              [
                                CssJs.pct(100),
                                CssJs.rgb(51, 51, 51)
                              ]
                            ]
                          ]
                        }),
                    CssJs.boxShadow(Css_Js_Core.Shadow.box(Css.px(0), Css.px(7), Css.px(8), Css.px(4), undefined, CssJs.rgba(0, 0, 0, {
                                  NAME: "num",
                                  VAL: 0.2
                                })))
                  ]),
              CssJs.hover(wrapperGenreHoverRules(hoverBehavior)),
              CssJs.focusWithin(wrapperGenreHoverRules(hoverBehavior))
            ]);
}

var wrapperDisabledRules = Particle_Screen_Ds.$$Array.concat(wrapperRules(undefined, undefined, undefined), Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.pointerEvents("none")]));

function getHoverWrapperMinHeight(pictureKind) {
  if (typeof pictureKind !== "object") {
    return 432;
  }
  if (pictureKind.TAG !== "TeaserHover") {
    return 432;
  }
  switch (pictureKind._0) {
    case "Portrait" :
        return 516;
    case "Standard" :
    case "Big" :
        return 432;
    
  }
}

function getPanelHoverWrapperRules(minHeightValues, pictureKind) {
  var scaleCoef;
  if (typeof pictureKind !== "object") {
    scaleCoef = 1 / 1.3;
  } else if (pictureKind.TAG === "TeaserHover") {
    switch (pictureKind._0) {
      case "Portrait" :
      case "Standard" :
          scaleCoef = 1 / 1.3;
          break;
      case "Big" :
          scaleCoef = 1 / 1.26;
          break;
      
    }
  } else {
    scaleCoef = 1 / 1.3;
  }
  var minHeightRules;
  var exit = 0;
  if (minHeightValues !== undefined && !(typeof pictureKind !== "object" || pictureKind.TAG !== "TeaserHover")) {
    switch (pictureKind._0) {
      case "Portrait" :
          minHeightRules = Particle_Screen_Ds.map(Caml_option.valFromOption(minHeightValues), (function (value) {
                  if (value !== 0) {
                    return [CssJs.minHeight(CssJs.pxFloat(value))];
                  } else {
                    return [CssJs.empty([])];
                  }
                }));
          break;
      case "Standard" :
      case "Big" :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    minHeightRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  }
  return Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                  zIndexRule,
                  CssJs.opacity(0),
                  CssJs.transform(CssJs.scale(scaleCoef, scaleCoef))
                ]), minHeightRules);
}

function getPanelHoverWrapperActiveRules(transition, mode) {
  var tmp;
  switch (mode.TAG) {
    case "Dialog" :
    case "MouseHover" :
        tmp = transition;
        break;
    case "MobileHover" :
        tmp = [CssJs.transform(CssJs.translate(CssJs.pct(-50), CssJs.pct(-50)))];
        break;
    
  }
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                [
                  CssJs.opacity(1),
                  CssJs.transform(CssJs.scale(1, 1))
                ],
                tmp
              ].flat());
}

var durationIn = 350;

var transitionImageDuration = 1500;

var transitionDuration = 750;

export {
  Selectors ,
  durationIn ,
  transitionRules ,
  zIndexRule ,
  imgWrapperRules ,
  imgDisableRules ,
  panelFocusRules ,
  wrapperRules ,
  mobileHoverButtonWrapperRules ,
  transitionImageDuration ,
  transitionDuration ,
  wrapperGenreHoverRules ,
  wrapperGenreRules ,
  wrapperDisabledRules ,
  getHoverWrapperMinHeight ,
  getPanelHoverWrapperRules ,
  getPanelHoverWrapperActiveRules ,
}
/* teaserImageIdentifier Not a pure module */
