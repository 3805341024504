// Generated by ReScript, PLEASE EDIT WITH CARE


function itemTemplateToImageStyle(itemTemplate) {
  switch (itemTemplate) {
    case "Banner" :
        return "Banner";
    case "Newsletter" :
        return "EventZone";
    case "ContentCollection" :
    case "PageHeader" :
        return "ThumbnailLandscape";
    case "Landscape" :
    case "LandscapeGenre" :
    case "Partner" :
    case "PlayNext" :
        return "StandardTeaser";
    case "Portrait" :
        return "PortraitTeaser";
    case "Poster" :
        return "PosterTeaser";
    case "EbuBox" :
    case "Label" :
    case "ProgramContent" :
        return ;
    case "Square" :
        return "Square";
    default:
      return "BigTeaser";
  }
}

export {
  itemTemplateToImageStyle ,
}
/* No side effect */
