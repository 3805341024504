// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";

function getShirtSize(shirtSize) {
  if (shirtSize === "M") {
    return 32;
  } else if (shirtSize === "S") {
    return 24;
  } else if (shirtSize === "XL") {
    return 46;
  } else if (shirtSize === "XS") {
    return 20;
  } else if (shirtSize === "XXL") {
    return 55;
  } else if (shirtSize === "XXS") {
    return 12;
  } else {
    return 40;
  }
}

function getShirtSpacing(shirtSize) {
  if (shirtSize === "M") {
    return 6;
  } else if (shirtSize === "S") {
    return 4.5;
  } else if (shirtSize === "XL") {
    return 8.63;
  } else if (shirtSize === "XS") {
    return 3.75;
  } else if (shirtSize === "XXL") {
    return 10.31;
  } else if (shirtSize === "XXS") {
    return 2.25;
  } else {
    return 7.5;
  }
}

function getSize(size) {
  if (typeof size === "object") {
    if (size.NAME === "Height") {
      return getShirtSize(size.VAL);
    } else {
      return size.VAL[0];
    }
  } else {
    return getShirtSize(size);
  }
}

function toCss(dsStyle, dsSize) {
  var match = Atom_Icon_Ds.view(dsStyle);
  var hover = match.hover;
  var color = match.color;
  var shape = Particle_Screen_Ds.map(dsSize, (function (s) {
          if (typeof s !== "object") {
            return {
                    TAG: "Square",
                    _0: getSize(s)
                  };
          }
          var variant = s.NAME;
          if (variant !== "Custom") {
            if (variant === "Height") {
              return {
                      TAG: "Rectangle",
                      _0: {
                        TAG: "Height",
                        _0: getSize(s)
                      }
                    };
            } else {
              return {
                      TAG: "Square",
                      _0: getSize(s)
                    };
            }
          }
          var match = s.VAL;
          return {
                  TAG: "Rectangle",
                  _0: {
                    TAG: "Full",
                    _0: match[0],
                    _1: match[1]
                  }
                };
        }));
  var heightRules = Particle_Screen_Css.toRules(undefined, undefined, Particle_Css.shape, shape);
  var fillRules = Util_Array.pure(color !== undefined ? Css_Js_Core.SVG.fill(Particle_Color.Css.color(color)) : Css_Js_Core.SVG.fill(CssJs.currentColor));
  var hoverRules = hover !== undefined ? CssJs.hover([Css_Js_Core.SVG.fill(Particle_Color.Css.color(hover))]) : CssJs.empty([]);
  var defaultRules = [
    hoverRules,
    CssJs.width(CssJs.auto),
    CssJs.opacity(match.opacity),
    CssJs.transform(CssJs.rotate(CssJs.deg(match.angle)))
  ];
  var rules = defaultRules.concat(heightRules).concat(fillRules);
  return {
          svg: rules
        };
}

export {
  getShirtSize ,
  getShirtSpacing ,
  getSize ,
  toCss ,
}
/* CssJs Not a pure module */
