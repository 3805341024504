// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Css from "./Particle_Css.res.mjs";

function toRules(device) {
  return [Particle_Css.getDeviceMediaQuery(device)];
}

export {
  toRules ,
}
/* Particle_Css Not a pure module */
