// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as React from "react";
import * as $$Storage from "../../Storage.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Header from "../../type/Type_Header.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Animation_Ds from "../particle/Particle_Animation_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as UseGetFocusableElement from "../../hook/UseGetFocusableElement.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var success_justifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "start");

var success_containerAnimation = Particle_Animation_Ds.Message.container;

var success_contentAnimation = Particle_Animation_Ds.Message.content;

var success_contentStyle = Atom_Text_Ds.makeMessage("Desc");

var success_containerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "M"));

var success_closeIconColor = "White";

var success = {
  background: Particle_Background_Ds.success,
  justifyContent: success_justifyContent,
  containerAnimation: success_containerAnimation,
  contentAnimation: success_contentAnimation,
  contentStyle: success_contentStyle,
  containerBlockSpace: success_containerBlockSpace,
  closeIconColor: success_closeIconColor,
  clsHeightBehavior: "NotPreservedHeight"
};

var newrecord = Caml_obj.obj_dup(success);

newrecord.background = Particle_Background_Ds.info;

var newrecord$1 = Caml_obj.obj_dup(success);

newrecord$1.background = Particle_Background_Ds.danger;

function subHeader(icon) {
  return {
          background: Particle_Background_Ds.subHeader,
          justifyContent: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center"),
          containerAnimation: Particle_Animation_Ds.SubHeader.content,
          contentAnimation: Particle_Animation_Ds.SubHeader.content,
          contentStyle: Atom_Text_Ds.makeMessage("SubHeader"),
          icon: icon !== undefined ? ({
                icon: Caml_option.valFromOption(icon),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
              }) : undefined,
          containerBlockSpace: Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXXS", "XL"), undefined, undefined, Particle_Spacer_Ds.makeAll("S")),
          closeIconColor: "Secondary",
          clsHeightBehavior: "PreservedHeight",
          zIndex: {
            TAG: "Behind",
            _0: "Header"
          }
        };
}

function subHeaderError(icon) {
  var newrecord = Caml_obj.obj_dup(subHeader(icon));
  newrecord.contentStyle = Atom_Text_Ds.makeMessage("SubHeaderError");
  newrecord.background = Particle_Background_Ds.danger;
  return newrecord;
}

function alert(icon) {
  return {
          background: Particle_Background_Ds.subHeader,
          justifyContent: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "start"),
          containerAnimation: Particle_Animation_Ds.SubHeader.content,
          contentAnimation: Particle_Animation_Ds.SubHeader.content,
          contentStyle: Atom_Text_Ds.makeMessage("SubHeader"),
          icon: icon !== undefined ? ({
                icon: Caml_option.valFromOption(icon),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
              }) : undefined,
          containerBlockSpace: Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXXS", "XL"), undefined, undefined, Particle_Spacer_Ds.makeAll("S")),
          closeIconColor: "Secondary",
          clsHeightBehavior: "PreservedHeight",
          zIndex: {
            TAG: "Behind",
            _0: "Header"
          }
        };
}

function view(param) {
  return {
          background: param.background,
          justifyContent: param.justifyContent,
          containerAnimation: param.containerAnimation,
          contentAnimation: param.contentAnimation,
          contentStyle: param.contentStyle,
          icon: param.icon,
          containerBlockSpace: param.containerBlockSpace,
          closeIconColor: param.closeIconColor,
          clsHeightBehavior: param.clsHeightBehavior,
          zIndex: param.zIndex
        };
}

var Molecule_Message_Ds = {
  success: success,
  info: newrecord,
  danger: newrecord$1,
  subHeader: subHeader,
  subHeaderError: subHeaderError,
  alert: alert,
  view: view
};

function dsVariant(variant, icon) {
  if (variant === "subHeader" || variant === "user") {
    return subHeader(icon);
  } else if (variant === "subHeaderError") {
    return subHeaderError(icon);
  } else if (variant === "danger") {
    return newrecord$1;
  } else if (variant === "alert") {
    return alert(icon);
  } else if (variant === "success") {
    return success;
  } else {
    return newrecord;
  }
}

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Above",
      _0: "Teaser"
    });

function toCss(view, visibility) {
  var zIndexRule$1 = Core__Option.getOr(Core__Option.map(view.zIndex, Particle_zIndex.Particle_zIndex_Css.toCss), zIndexRule);
  if (view.clsHeightBehavior === "PreservedHeight") {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                CssJs.visibility(visibility),
                CssJs.maxWidth({
                      NAME: "rem",
                      VAL: 100
                    }),
                zIndexRule$1
              ]);
  } else {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  }
}

var Molecule_Message_Css = {
  zIndexRule: zIndexRule,
  toCss: toCss
};

function storageMessage_encode(v) {
  return Spice.arrayToJson(Spice.stringToJson, v);
}

function storageMessage_decode(v) {
  return Spice.arrayFromJson(Spice.stringFromJson, v);
}

var defaultValue = [];

function toString(storageMessage) {
  return JSON.stringify(storageMessage_encode(storageMessage));
}

function fromString(str) {
  return storageMessage_decode(JSON.parse(str));
}

var Model_version = "v3";

var Model = {
  key: "message",
  version: Model_version,
  defaultValue: defaultValue,
  fromString: fromString,
  toString: toString
};

var $$Storage$1 = $$Storage.Make(Model, $$Storage.LocalStorage);

function Molecule_Message$Content(props) {
  var textStyle = props.textStyle;
  var linkLabel = props.linkLabel;
  var linkUrl = props.linkUrl;
  var text = props.text;
  if (linkUrl !== undefined && linkLabel !== undefined) {
    return JsxRuntime.jsxs(Atom_Row.make, {
                children: [
                  JsxRuntime.jsx(Atom_Cell.make, {
                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("S"))),
                        children: JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(text),
                              style: textStyle
                            })
                      }),
                  JsxRuntime.jsx(Atom_Cta.Link.make, {
                        href: linkUrl,
                        label: linkLabel,
                        onClick: props.onClick,
                        style: props.linkStyle
                      })
                ]
              });
  }
  return JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(text),
              style: textStyle
            });
}

var Content = {
  make: Molecule_Message$Content
};

var ctaAlignSelf = Particle_Screen_Ds.make(undefined, undefined, undefined, "center", undefined, "start");

var iconAlignSelf = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var alignItemsContent = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var iconDisplay = Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none");

var iconBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("XXS"));

function make(param) {
  var handleClick = param.handleClick;
  var delay = param.delay;
  var variant = param.variant;
  var view$1 = view(dsVariant(variant, param.icon));
  var linkStyle = variant === "subHeader" ? Atom_Cta_Ds.Message.linkSubHeader : Atom_Cta_Ds.Message.linkDefault;
  var linkPosition = Core__Option.getOr(param.linkPosition, "End");
  var icon = view$1.icon;
  var rules = toCss(view$1, param.visibility);
  var position;
  position = view$1.clsHeightBehavior === "PreservedHeight" ? Particle_Screen_Ds.make(undefined, undefined, {
          TAG: "Absolute",
          _0: {
            top: CssJs.px(Type_Header.Constants.height.m)
          }
        }, undefined, undefined, {
          TAG: "Absolute",
          _0: {
            top: CssJs.px(Type_Header.Constants.height.default)
          }
        }) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
          TAG: "Absolute",
          _0: {
            top: CssJs.px(0),
            right: CssJs.px(0),
            left: CssJs.px(0)
          }
        });
  var icon$1 = icon !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
          alignSelf: Caml_option.some(iconAlignSelf),
          display: Caml_option.some(iconDisplay),
          blockSpace: Caml_option.some(iconBlockSpace),
          children: JsxRuntime.jsx(Atom_Icon.make, {
                icon: icon.icon,
                size: icon.size
              })
        }) : null;
  var progress = delay !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
          width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
          height: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(2))),
          children: JsxRuntime.jsx(Atom_Cell.make, {
                height: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.px(2))),
                background: Caml_option.some(Particle_Background_Ds.orange),
                children: null,
                position: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                          TAG: "Absolute",
                          _0: {
                            bottom: {
                              NAME: "px",
                              VAL: 0
                            },
                            right: {
                              NAME: "px",
                              VAL: 0
                            },
                            left: {
                              NAME: "px",
                              VAL: 0
                            }
                          }
                        })),
                animation: Caml_option.some(Particle_Animation_Ds.Message.delay(delay))
              })
        }) : null;
  return JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: Caml_option.some(view$1.containerBlockSpace),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              background: Caml_option.some(view$1.background),
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    children: [
                      JsxRuntime.jsxs(Atom_Row.make, {
                            children: [
                              JsxRuntime.jsxs(Atom_Row.make, {
                                    alignItems: Caml_option.some(alignItemsContent),
                                    animation: Caml_option.some(view$1.contentAnimation),
                                    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                                    children: [
                                      icon$1,
                                      JsxRuntime.jsx(Molecule_Message$Content, {
                                            text: param.text,
                                            linkUrl: param.linkUrl,
                                            linkLabel: param.linkLabel,
                                            textStyle: view$1.contentStyle,
                                            linkStyle: linkStyle,
                                            linkPosition: linkPosition,
                                            onClick: handleClick
                                          })
                                    ],
                                    gap: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")),
                                    justifyContent: Caml_option.some(view$1.justifyContent),
                                    wrap: "OnOneLine"
                                  }),
                              JsxRuntime.jsx(Atom_Cell.make, {
                                    alignSelf: Caml_option.some(ctaAlignSelf),
                                    children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                                          accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, param.closeLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                                          onClick: handleClick,
                                          style: Atom_Cta_Ds.Message.makeClose(view$1.closeIconColor)
                                        })
                                  })
                            ],
                            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                          }),
                      progress
                    ],
                    width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                  }),
              position: Caml_option.some(position),
              rules: Caml_option.some(rules),
              animation: Caml_option.some(view$1.containerAnimation)
            });
}

var Internal = {
  make: make
};

function make$1(param) {
  var icon = param.icon;
  var variant = param.variant;
  var onClose = Core__Option.getOr(param.onClose, (function () {
          
        }));
  var storage = Core__Option.getOr(param.storage, "NotStored");
  var linkPosition = Core__Option.getOr(param.linkPosition, "End");
  var id = Core__Option.getOr(param.id, "");
  var view$1 = view(dsVariant(variant, icon));
  var match = UseToggle.make(undefined, undefined, (function () {
          onClose();
        }), undefined, undefined, undefined);
  var state = match.state;
  var toggle = match.toggle;
  var toggleRaw = match.toggleRaw;
  var visibility;
  visibility = state === "On" ? "visible" : "collapse";
  var match$1 = React.useState(function () {
        return [];
      });
  var setStoredMessages = match$1[1];
  var storedMessages = match$1[0];
  React.useEffect((function () {
          var match = $$Storage$1.get();
          if (storage === "Stored" && match.TAG === "Ok") {
            var data = match._0;
            setStoredMessages(function (param) {
                  return data;
                });
            var match$1 = data.find(function (val) {
                  return val === id;
                });
            if (match$1 !== undefined) {
              
            } else {
              toggleRaw();
            }
          } else {
            toggleRaw();
          }
        }), []);
  var match$2 = UseGetFocusableElement.make();
  var htmlElement = match$2[0];
  var handleClick = function (ev) {
    if (variant === "subHeader" && storage === "Stored") {
      var newStore = storedMessages.concat([id]);
      setStoredMessages(function (param) {
            return newStore;
          });
      $$Storage$1.save(newStore);
    }
    Core__Option.forEach(htmlElement, (function (prim) {
            prim.focus();
          }));
    onClose();
    toggle(ev);
  };
  if (view$1.clsHeightBehavior !== "PreservedHeight" && state !== "On") {
    return null;
  }
  return JsxRuntime.jsx(make, {
              variant: variant,
              text: param.text,
              closeLabel: param.closeLabel,
              linkLabel: param.linkLabel,
              linkUrl: param.linkUrl,
              linkPosition: linkPosition,
              icon: icon,
              handleClick: handleClick,
              visibility: visibility
            });
}

function make$2(param) {
  var clickOnCloseButton = param.clickOnCloseButton;
  var linkPosition = Core__Option.getOr(param.linkPosition, "End");
  var handleClick = function (param) {
    if (clickOnCloseButton !== undefined) {
      return clickOnCloseButton();
    }
    
  };
  return JsxRuntime.jsx(make, {
              variant: param.variant,
              text: param.text,
              closeLabel: param.closeLabel,
              linkLabel: param.linkLabel,
              linkUrl: param.linkUrl,
              linkPosition: linkPosition,
              icon: param.icon,
              delay: param.delay,
              handleClick: handleClick,
              visibility: "visible"
            });
}

var Toast = {
  make: make$2
};

export {
  Molecule_Message_Ds ,
  dsVariant ,
  Molecule_Message_Css ,
  storageMessage_encode ,
  storageMessage_decode ,
  Model ,
  $$Storage$1 as $$Storage,
  Content ,
  ctaAlignSelf ,
  iconAlignSelf ,
  alignItemsContent ,
  iconDisplay ,
  iconBlockSpace ,
  Internal ,
  make$1 as make,
  Toast ,
}
/* success Not a pure module */
