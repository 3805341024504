// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Emac_Teaser from "../emac/Emac_Teaser.res.mjs";
import * as Type_String from "./Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function makeNextDiffusionLabel(labels, availability, nextBroadcast) {
  var labelByAvailability;
  if (availability !== undefined) {
    var match = availability.type;
    labelByAvailability = match === "LIVESTREAM_TV" ? labels.program.next_broadcast : labels.program.live_on;
  } else {
    labelByAvailability = labels.program.next_broadcast;
  }
  if (nextBroadcast !== undefined && nextBroadcast.length !== 0) {
    return labels.program.next_broadcast;
  } else {
    return labelByAvailability;
  }
}

function getLabelFromMeta(labels, meta, nextBroadcast) {
  if (typeof meta !== "object") {
    return "";
  }
  switch (meta.TAG) {
    case "AudioVersions" :
        return labels.program.accessible;
    case "Availability" :
        return labels.program.available;
    case "Duration" :
        return labels.program.duration;
    case "Genre" :
        return labels.program.genre;
    case "NextDiffusion" :
        return makeNextDiffusionLabel(labels, meta._1, nextBroadcast);
    case "AllDiffusions" :
        return labels.program.next_broadcast;
    case "AvailabilityFormatted" :
    case "SeasonCount" :
        return "";
    case "FirstBroadcast" :
        return labels.program.showOf;
    
  }
}

function getValue(meta) {
  if (typeof meta !== "object") {
    return {
            TAG: "String",
            _0: ""
          };
  }
  switch (meta.TAG) {
    case "AudioVersions" :
        return {
                TAG: "AudioVersions",
                _0: meta._0
              };
    case "Duration" :
        return {
                TAG: "String",
                _0: Type_String.Teaser.DurationLabel.toString(meta._0)
              };
    case "Genre" :
        return {
                TAG: "Genre",
                _0: meta._0
              };
    case "NextDiffusion" :
        return {
                TAG: "String",
                _0: Type_String.Teaser.BroadcastDate.toString(meta._0)
              };
    case "AllDiffusions" :
        return {
                TAG: "MultiString",
                _0: meta._0.map(Type_String.Teaser.BroadcastDate.toString)
              };
    default:
      return {
              TAG: "String",
              _0: meta._0
            };
  }
}

function metaToMetaItem(labels, meta, nextBroadcast) {
  return {
          label: getLabelFromMeta(labels, meta, nextBroadcast),
          value: getValue(meta),
          raw: meta
        };
}

function nextDiffusionMeta(nextBroadcast, availability) {
  return Core__Option.map(Core__Option.flatMap(nextBroadcast, (function (__x) {
                    return Util_Array.getFirstElement(__x);
                  })), (function (value) {
                return {
                        TAG: "NextDiffusion",
                        _0: value,
                        _1: availability
                      };
              }));
}

function audioVersionsMeta(__x) {
  return Core__Option.map(__x, (function (value) {
                return {
                        TAG: "AudioVersions",
                        _0: value
                      };
              }));
}

function durationMeta(__x) {
  return Core__Option.map(__x, (function (value) {
                return {
                        TAG: "Duration",
                        _0: value
                      };
              }));
}

function genreMeta(__x) {
  return Core__Option.map(__x, (function (value) {
                return {
                        TAG: "Genre",
                        _0: value
                      };
              }));
}

function availableMeta(label) {
  return Core__Option.map(label, (function (value) {
                return {
                        TAG: "Availability",
                        _0: value
                      };
              }));
}

function availableFormattedMeta(label, date) {
  return Core__Option.map(label, (function (value) {
                return {
                        TAG: "AvailabilityFormatted",
                        _0: value,
                        _1: date
                      };
              }));
}

function allDiffusionsMeta(broadcasts) {
  return Core__Option.flatMap(broadcasts, (function (broadcasts) {
                return Core__Option.map(Util_Array.NonEmpty.makeFromArray(broadcasts), (function (value) {
                              return {
                                      TAG: "AllDiffusions",
                                      _0: Util_Array.NonEmpty.toArray(value)
                                    };
                            }));
              }));
}

function seasonCountMeta(value, seasonCountFormater) {
  return Core__Option.flatMap(value, (function (value) {
                if (value !== 0) {
                  return {
                          TAG: "SeasonCount",
                          _0: seasonCountFormater(value)
                        };
                }
                
              }));
}

function firstBroadcastMeta(label, dateFormater) {
  return Core__Option.map(label, (function (label) {
                return {
                        TAG: "FirstBroadcast",
                        _0: dateFormater(new Date(Type_String.Program.FirstBroadcastDate.toString(label)))
                      };
              }));
}

function makeProgramMetas(pageFromOpt, betweenDatesFormattedFormater, dateFormater, pageKind, labels, teaser) {
  var pageFrom = pageFromOpt !== undefined ? pageFromOpt : "Emac";
  if (teaser === undefined) {
    return [];
  }
  var availability = Core__Option.flatMap(teaser.availability, (function (param) {
          var label = param.label;
          if (param.type === "LIVESTREAM_TV") {
            return ;
          }
          var start = new Date(param.start);
          var end = Core__Option.map(param.end, (function (prim) {
                  return new Date(prim);
                }));
          if (end !== undefined && betweenDatesFormattedFormater !== undefined) {
            return availableFormattedMeta(betweenDatesFormattedFormater({
                            start: start,
                            end: Caml_option.valFromOption(end)
                          }), end);
          } else {
            return availableMeta(label);
          }
        }));
  var tmp;
  tmp = typeof pageKind !== "object" && pageKind === "Direct" ? undefined : availability;
  var tmp$1;
  if (pageFrom === "Emac") {
    var availability$1 = teaser.availability;
    tmp$1 = Core__Option.map(Core__Option.flatMap(teaser.broadcastDates, (function (__x) {
                return Util_Array.getFirstElement(__x);
              })), (function (value) {
            return {
                    TAG: "NextDiffusion",
                    _0: value,
                    _1: availability$1
                  };
          }));
  } else {
    tmp$1 = allDiffusionsMeta(teaser.broadcastDates);
  }
  var tmp$2;
  tmp$2 = pageFrom === "Emac" ? firstBroadcastMeta(teaser.program.firstBroadcastDate, dateFormater) : undefined;
  return Util_Array.catOption([
                durationMeta(teaser.durationLabel),
                tmp,
                genreMeta(teaser.program.genre),
                tmp$1,
                tmp$2,
                audioVersionsMeta(teaser.audioVersions)
              ]).map(function (__x) {
              return metaToMetaItem(labels, __x, teaser.broadcastDates);
            });
}

function makePageMetas(betweenDatesFormattedFormater, seasonCountFormater, metadatas) {
  var availability = Core__Option.flatMap(Caml_option.nullable_to_opt(metadatas.publish), (function (param) {
          var start = Core__Option.map(Caml_option.nullable_to_opt(param.online), (function (prim) {
                  return new Date(prim);
                }));
          var end = Core__Option.map(Caml_option.nullable_to_opt(param.offline), (function (prim) {
                  return new Date(prim);
                }));
          if (start === undefined) {
            return ;
          }
          if (end === undefined) {
            return ;
          }
          var end$1 = Caml_option.valFromOption(end);
          var start$1 = Caml_option.valFromOption(start);
          return Core__Option.map(betweenDatesFormattedFormater, (function (betweenDatesFormater) {
                        return betweenDatesFormater({
                                    start: start$1,
                                    end: end$1
                                  });
                      }));
        }));
  var end = Core__Option.flatMap(Caml_option.nullable_to_opt(metadatas.publish), (function (param) {
          return Core__Option.map(Caml_option.nullable_to_opt(param.offline), (function (prim) {
                        return new Date(prim);
                      }));
        }));
  return Util_Array.catOption([
                seasonCountMeta(Caml_option.nullable_to_opt(metadatas.totalSeasonNumber), seasonCountFormater),
                availableFormattedMeta(availability, end),
                audioVersionsMeta(Emac_Teaser.getAudioVersions(metadatas.displayAudioVersions))
              ]).map(function (__x) {
              return getValue(__x);
            });
}

export {
  makeNextDiffusionLabel ,
  getLabelFromMeta ,
  getValue ,
  metaToMetaItem ,
  nextDiffusionMeta ,
  audioVersionsMeta ,
  durationMeta ,
  genreMeta ,
  availableMeta ,
  availableFormattedMeta ,
  allDiffusionsMeta ,
  seasonCountMeta ,
  firstBroadcastMeta ,
  makeProgramMetas ,
  makePageMetas ,
}
/* Emac_Teaser Not a pure module */
