// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../../../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var color = Core__Option.getOr(param.color, "Text");
  return JsxRuntime.jsx("path", {
              d: "M26.2591 7.04746L26.2591 7.0475L26.2658 7.0552C27.3913 8.33678 28.025 10.0579 28.025 11.8999C28.025 13.7396 27.3928 15.4586 26.2683 16.7455L16.0757 28.0993C16.0756 28.0995 16.0754 28.0996 16.0753 28.0998C16.0326 28.1469 15.9751 28.1678 15.9341 28.1678C15.8902 28.1678 15.837 28.1493 15.7903 28.0984C15.7899 28.098 15.7896 28.0976 15.7893 28.0973L5.55395 16.7049C4.44951 15.4245 3.83218 13.7249 3.83218 11.8999C3.83218 10.059 4.46512 8.33785 5.59077 7.05078L5.74388 6.8777L5.74388 6.8777L5.74625 6.87501C6.89376 5.56649 8.39904 4.86667 9.98135 4.86667C11.5714 4.86667 13.0756 5.56274 14.2215 6.87387C14.2216 6.8739 14.2216 6.87393 14.2216 6.87396L15.3013 8.10967L15.9246 8.82304L16.5517 8.11299L17.6388 6.88219L17.6388 6.8822L17.6417 6.87884C18.7834 5.5721 20.2866 4.87291 21.8758 4.87291C23.4591 4.87291 24.967 5.56719 26.1122 6.87593L26.2591 7.04746Z",
              fill: "none",
              stroke: Particle_Color.toString(Particle_Color.Css.color(color)),
              strokeWidth: "1.66437"
            });
}

export {
  make ,
}
/* Particle_Color Not a pure module */
