// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = (function (baseUrl) {
    return {
    
            Concert: {
                title: 'ARTE Concert',
                url: baseUrl + '/en/arte-concert/',
                navigation: {
                    links: [
                        {
                            label: 'Browse by genre',
                            href: baseUrl + '/en/arte-concert/most-recent/',
                            id: 'concert-genres',
                            kind: 'other',
                        },
                    ],
                },
                 socialNetworks:{
                    facebook: null,
                    twitter: null,
                    instagram: null,
                    youtube: null,
                    tiktok: null,
                },
                       concertSelectGenre:{
                    title:{label:"Search genres", href:baseUrl +"/en/arte-concert/most-recent/"},
                    items:[
                        {
                            label:"Pop & Rock",
                            href:  baseUrl+"/en/p/pop-rock/" 
                        },
                        {
                            label:"Electronic",
                            href:baseUrl +"/en/p/electronic/"
                        },
                        {
                            label:"Metal",
                            href:baseUrl +"/en/p/metal/"
                        },
                        {
                            label:"Hip-hop",
                            href:baseUrl +"/en/p/hip-hop/"
                        },
                        {
                            label:"Jazz",
                            href:baseUrl +"/en/p/jazz/"
                        },
                        {
                            label:"Classical",
                            href:baseUrl +"/en/p/classical/"
                        },
                        {
                            label:"Opera",
                            href:baseUrl +"/en/p/opera/"
                        },
                        {
                            label:"Baroque",
                            href:baseUrl +"/en/p/baroque/"
                        },
                        {
                            label:"Performing arts",
                            href:baseUrl +"/en/p/performing-arts/"
                        },
                        {
                            label:"World",
                            href:baseUrl +"/en/p/world/"
                        },
                       
                    ]
               
            },
              guide:null,
            },
            DesktopNavigation: {
                links: [
                    {
                        label: 'Search',
                        href: baseUrl + '/en/search/',
                        id: 'search',
                    },
                    {
                        label: 'About',
                        href: 'https://www.arte.tv/sites/en/corporate/what-we-do/',
                        id: 'about',
                    },
                ],
            },
            MenuNavigation: {
                mobileLinks: [
                    {
                        label: 'Home',
                        href: baseUrl + '/en/',
                    },
                     {
                        label: 'Coming soon',
                        href: baseUrl + '/en/p/coming-soon-on-artetv/',
                        id: 'upcoming',
                    },
                    
                    {
                        label: 'Search',
                        href: baseUrl + '/en/search/',
                        id: 'search',
                    },
                    {
                        label: 'About',
                        href: 'https://www.arte.tv/sites/en/corporate/what-we-do/',
                        id: 'about',
                    },
                ],
                categories: [
                    {
                        label: 'Politics and society',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/politics-and-society/',
                        code: 'ACT',
                    },
                    {
                        label: 'Cinema',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/cinema/',
                        code: 'CIN',
                    },
                    {
                        label: 'Series',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/series/',
                        code: 'SER',
                    },
                    {
                        label: 'Culture',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/culture/',
                        code: 'CPO',
                    },
                    {
                        label: 'ARTE Concert',
                        kind: 'internal',
                        href: baseUrl + '/en/arte-concert/',
                        code: 'ARS',
                    },
                    {
                        label: 'Sciences',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/sciences/',
                        code: 'SCI',
                    },
                    {
                        label: 'Discovery',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/discovery/',
                        code: 'DEC',
                    },
                    {
                        label: 'History',
                        kind: 'internal',
                        href: baseUrl + '/en/videos/history/',
                        code: 'HIS',
                    },
                ],
                otherLinks: [
                    {
                        label: 'Magazine Shows',
                        href: baseUrl + '/en/videos/magazine-shows/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'All programmes',
                        href: baseUrl + '/en/videos/most-viewed/',
                        code: null,
                        kind: 'internal',
                    },
                    {
                        label: 'Video Games',
                        href: 'https://www.arte.tv/digitalproductions/en/categories/video-games/',
                        code: null,
                        kind: 'external',
                    },
                    {
                        label: 'Digital Productions',
                        href: 'https://www.arte.tv/digitalproductions/en/',
                        code: null,
                        kind: 'external',
                    },
                ],
            },
            LogoNavigation: {
                label: 'in English',
                title: 'Arte in English',
                href: baseUrl + '/en/',
                links: [
                    {
                        label: 'ARTE Concert',
                        id: 'arte_concert',
                        href: baseUrl + '/en/arte-concert/',
                    },
                      {
                        label: 'Coming soon',
                        href: baseUrl + '/en/p/coming-soon-on-artetv/',
                        id: 'upcoming',
                    },
                    {
                        label: 'About',
                        href: 'https://www.arte.tv/sites/en/corporate/what-we-do/',
                        id: 'about',
                    },
                ],
            },
    };
});

export {
  $$default as default,
}
/* No side effect */
