// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1711 8.83456 29 16 29C23.1711 29 29 23.1711 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.83456 3 16ZM4.47144 15.9997C4.47144 9.64516 9.64544 4.47116 16 4.47116C22.3546 4.47116 27.5286 9.64516 27.5286 15.9997C27.5286 22.3592 22.3546 27.5283 16 27.5283C9.64057 27.5283 4.47144 22.3592 4.47144 15.9997Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M5.75113 17.6953L6.20369 17.1542C6.51569 17.4142 6.84882 17.5751 7.24369 17.5751C7.55569 17.5751 7.74825 17.45 7.74825 17.2477V17.2371C7.74825 17.0445 7.62882 16.9405 7.04625 16.7951C6.34425 16.6131 5.89169 16.4205 5.89169 15.7291V15.7185C5.89169 15.084 6.40113 14.668 7.11369 14.668C7.62313 14.668 8.05457 14.8288 8.40882 15.11L8.00825 15.6868C7.70113 15.474 7.39482 15.344 7.10313 15.344C6.80657 15.344 6.65625 15.4788 6.65625 15.6454V15.656C6.65625 15.8851 6.80738 15.9574 7.41025 16.1134C8.11713 16.2954 8.51769 16.5505 8.51769 17.1583V17.1688C8.51769 17.8603 7.98713 18.2503 7.23882 18.2503C6.70338 18.2503 6.16794 18.0683 5.75194 17.6937L5.75113 17.6953Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M9.916 15.4232H8.85487V14.7163H11.7457V15.4232H10.6846V18.2052H9.91519V15.4232H9.916Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M12.334 14.7155H14.9649V15.3964H13.0977V16.1032H14.7406V16.7841H13.0977V17.517H14.9909V18.1979H12.334V14.7139V14.7155Z"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M17.2691 14.7153H15.6726H15.6677V18.2042H16.4371V17.0862H17.04L17.794 18.2042H18.6935L17.8403 16.9562C18.288 16.7896 18.5895 16.4362 18.5895 15.8748V15.8642C18.5895 15.5368 18.4806 15.2613 18.288 15.0688C18.054 14.8405 17.7111 14.7153 17.2691 14.7153ZM17.8046 15.9176C17.8046 16.2142 17.5917 16.4116 17.2172 16.4116H16.4372V15.4082H17.2017C17.5755 15.4082 17.8046 15.5796 17.8046 15.907V15.9176Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M19.276 14.7155H21.9068V15.3964H20.0397V16.1032H21.6826V16.7841H20.0397V17.517H21.9328V18.1979H19.276V14.7139V14.7155Z"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M22.4586 16.4574V16.468C22.4586 17.4608 23.2329 18.262 24.3094 18.262C25.386 18.262 26.166 17.4503 26.166 16.4574V16.4468C26.166 15.454 25.3917 14.6528 24.3151 14.6528C23.2386 14.6528 22.4586 15.4645 22.4586 16.4574ZM25.3649 16.4573V16.4678C25.3649 17.0658 24.9334 17.5549 24.32 17.5549C23.7017 17.5549 23.2646 17.0561 23.2646 16.4581V16.4475C23.2646 15.8495 23.6911 15.3604 24.3094 15.3604C24.9278 15.3604 25.3649 15.8593 25.3649 16.4573Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
