// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M3 16C3 23.1711 8.83456 29 16 29C23.1711 29 29 23.1711 29 16C29 8.83456 23.1654 3 16 3C8.83456 3 3 8.83456 3 16ZM4.47144 16C4.47144 9.64544 9.64544 4.47144 16 4.47144C22.3546 4.47144 27.5286 9.64544 27.5286 16C27.5286 22.3594 22.3546 27.5286 16 27.5286C9.64056 27.5286 4.47144 22.3594 4.47144 16Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M9.45856 16.546V12.386H11.06V16.5046C11.06 17.69 11.6531 18.3034 12.6306 18.3034C13.608 18.3034 14.2011 17.7103 14.2011 16.5566V12.386H15.8026V16.494C15.8026 18.6991 14.5651 19.7806 12.6094 19.7806C10.6537 19.7806 9.45856 18.6886 9.45856 16.546Z"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M19.1306 13.8631H16.9157V12.386H22.9477V13.8631H20.7328V19.666H19.1314L19.1306 13.8631Z"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
