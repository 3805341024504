// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Util_Dom from "../../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as UseOnBlur from "../../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../../hook/UseToggle.res.mjs";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Icon_Ds from "../../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Icon_Css from "../../atom/Atom_Icon_Css.res.mjs";
import * as Atom_Text_Css from "../../atom/Atom_Text_Css.res.mjs";
import * as Type_Dropdown from "../../../type/Type_Dropdown.res.mjs";
import * as Particle_Spacer from "../../particle/Particle_Spacer.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as UseClickOutside from "../../../hook/UseClickOutside.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Border_Css from "../../particle/Particle_Border_Css.res.mjs";
import * as Particle_Spacer_Css from "../../particle/Particle_Spacer_Css.res.mjs";
import * as Organism_Dropdown_Ds from "./Organism_Dropdown_Ds.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function createTitleItem(itemLabel, titleItems) {
  return Core__Option.map(titleItems, (function (titleItems) {
                return titleItems + " " + Type_String.Dropdown.Item.toString(itemLabel);
              }));
}

function createTitle(currentItemLabel, title) {
  return Core__Option.map(title, (function (title) {
                return title + " " + currentItemLabel;
              }));
}

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "Header"
    });

var listRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      zIndexRule,
      CssJs.unsafe("width", "max-content")
    ]);

var containerPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Static");

var listPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: CssJs.pct(100),
        left: "zero"
      }
    });

function Organism_Dropdown$Organism_Dropdown_List(props) {
  var titleItems = props.titleItems;
  var value = props.value;
  var items = props.items;
  var onChange = props.onChange;
  var accessibility = props.accessibility;
  var match = Organism_Dropdown_Ds.List.makeComponentView(props.variant);
  var itemStyle = match.itemStyle;
  var match$1 = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var close = match$1.close;
  var state = match$1.state;
  var ref = React.useRef(null);
  var refButton = React.useRef(null);
  UseClickOutside.make(ref, close);
  UseOnBlur.make(ref, close);
  var defaultCurrentItem = value !== undefined ? Core__Option.getOr(Util_Array.NonEmpty.find(items, (function (item) {
                return item.value === Type_String.Dropdown.Item.make(value);
              })), Type_Dropdown.Item.$$default) : Util_Array.NonEmpty.head(items);
  var match$2 = React.useState(function () {
        return defaultCurrentItem;
      });
  var setCurrentItem = match$2[1];
  var currentItem = match$2[0];
  var defaultItem = React.useRef(defaultCurrentItem);
  if (defaultCurrentItem !== defaultItem.current) {
    setCurrentItem(function (param) {
          return defaultCurrentItem;
        });
    defaultItem.current = defaultCurrentItem;
  }
  var defaultAccessibility;
  defaultAccessibility = state === "On" ? Particle_Accessibility.make(undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined) : Particle_Accessibility.make(undefined, undefined, undefined, undefined, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var accessibility$1;
  if (accessibility !== undefined) {
    var newrecord = Caml_obj.obj_dup(accessibility);
    newrecord.ariaExpanded = defaultAccessibility.ariaExpanded;
    accessibility$1 = newrecord;
  } else {
    accessibility$1 = defaultAccessibility;
  }
  var tmp;
  tmp = state === "On" ? CssJs.flexBox : CssJs.none;
  var display = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, tmp);
  var list = JsxRuntime.jsx(Atom_Column.make, {
        background: Caml_option.some(props.background),
        children: Util_Array.NonEmpty.toArray(items).map(function (item) {
              var label = item.label;
              var title = createTitleItem(item.label, titleItems);
              return JsxRuntime.jsx(Atom_Cell.make, {
                          kind: "ItemList",
                          children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                href: Type_String.Dropdown.Item.toString(item.value),
                                label: Type_String.Dropdown.Item.toString(label),
                                onClick: (function (__x) {
                                    if (onChange !== undefined) {
                                      onChange(item);
                                    }
                                    setCurrentItem(function (param) {
                                          return item;
                                        });
                                    close();
                                    var ref = Util_Dom.getDomRef(refButton);
                                    if (ref !== undefined) {
                                      Core__Option.map(Core__Option.flatMap(Caml_option.valFromOption(ref), Webapi__Dom__Element.asHtmlElement), (function (prim) {
                                              prim.focus();
                                            }));
                                      return ;
                                    }
                                    
                                  }),
                                style: itemStyle,
                                title: title
                              })
                        }, Type_String.Dropdown.Item.toString(label));
            }),
        display: Caml_option.some(display),
        identifier: Caml_option.some(Identifiers.Dropdown.element),
        kind: "List",
        position: Caml_option.some(listPosition),
        rules: Caml_option.some(listRules)
      });
  var title = createTitle(Type_String.Dropdown.Item.toString(currentItem.label), props.title);
  return JsxRuntime.jsxs(Atom_Box.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              border: match.border,
              children: [
                JsxRuntime.jsx(Atom_Cta.Button.make, {
                      accessibility: accessibility$1,
                      ctaRef: props.ref_,
                      label: Type_String.Dropdown.Item.toString(currentItem.label),
                      labelKind: props.buttonKind,
                      onClick: match$1.toggle,
                      style: match.itemCurrentStyle,
                      title: title,
                      rules: Caml_option.some(props.rules)
                    }),
                list
              ],
              position: Caml_option.some(containerPosition),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              ref_: ref
            });
}

function toString(autoComplete) {
  if (autoComplete === "birthdayMonth") {
    return "bday-month";
  } else if (autoComplete === "countryName") {
    return "country-name";
  } else if (autoComplete === "off") {
    return "off";
  } else if (autoComplete === "birthdayDay") {
    return "bday-day";
  } else if (autoComplete === "honorificPrefix") {
    return "honorific-prefix";
  } else {
    return "bday-year";
  }
}

var defaultRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.unsafe("appearance", "none"),
      CssJs.cursor(CssJs.pointer)
    ]);

function toCss(param) {
  var borderFocused = param.borderFocused;
  var border = param.border;
  var borderRules = border !== undefined ? Particle_Screen_Ds.map(Caml_option.valFromOption(border), (function (border) {
            return Particle_Border_Css.toCss(border);
          })) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.borderStyle("none")]);
  var borderFocusedRules = borderFocused !== undefined ? Particle_Screen_Ds.map(Caml_option.valFromOption(borderFocused), (function (border) {
            return [CssJs.focus(Particle_Border_Css.toCss(border))];
          })) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.borderStyle("none")]);
  return Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Atom_Text_Css.toCss(Atom_Text_Ds.view(param.textStyle)), defaultRules), borderRules), borderFocusedRules);
}

function Organism_Dropdown$Organism_Dropdown_Select(props) {
  var items = props.items;
  var onBlur = props.onBlur;
  var view = Organism_Dropdown_Ds.Select.makeComponentView(props.variant);
  var rules = Particle_Screen_Ds.$$Array.concat(toCss(view), props.rules);
  var itemRules = Atom_Text_Css.toCss(Atom_Text_Ds.view(view.itemStyle));
  var handleOnChange = Core__Option.map(props.onChange, (function (onChange) {
          return function ($$event) {
            var value = $$event.target.value;
            var currenItem = Core__Option.getOr(Util_Array.NonEmpty.find(items, (function (item) {
                        return item.value === value;
                      })), Type_Dropdown.Item.$$default);
            onChange(currenItem);
          };
        }));
  var handleBlur = function (e) {
    if (onBlur !== undefined) {
      return onBlur(e);
    }
    
  };
  var autoComplete = Core__Option.map(props.autoComplete, toString);
  return JsxRuntime.jsx(Atom_Box.make, {
              accessibility: props.accessibility,
              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
              background: Caml_option.some(props.background),
              children: Caml_option.some(Util_Array.NonEmpty.toArray(items).map(function (param) {
                        var value = param.value;
                        return JsxRuntime.jsx(Atom_Box.make, {
                                    children: Caml_option.some(Type_String.Dropdown.Item.toString(param.label)),
                                    kind: {
                                      TAG: "Option",
                                      _0: Type_String.Dropdown.Item.toString(value)
                                    },
                                    rules: Caml_option.some(itemRules)
                                  }, Type_String.Dropdown.Item.toString(value));
                      })),
              id: props.id,
              kind: {
                TAG: "Select",
                _0: {
                  name: props.name,
                  value: props.value,
                  onChange: handleOnChange,
                  title: props.title,
                  autoComplete: autoComplete
                }
              },
              onBlur: handleBlur,
              rules: Caml_option.some(rules),
              ref_: props.ref_
            });
}

var arrowSizeDsScreen = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var arrowSizePx = CssJs.px(Atom_Icon_Css.getSize("S"));

function contentSpacerPx(contentSpacer) {
  return CssJs.px(Particle_Spacer.spacerToInt(contentSpacer));
}

var contentSpacerDoublePx = CssJs.px(Particle_Spacer.spacerToInt("L"));

var cssPaddingRight = Css_Js_Core.Calc.$plus(arrowSizePx, contentSpacerDoublePx);

function contentRules(contentSpacerOpt, param) {
  var contentSpacer = contentSpacerOpt !== undefined ? contentSpacerOpt : "S";
  return Particle_Screen_Ds.map(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make(contentSpacer, contentSpacer, contentSpacer, "L")), (function (screen) {
                return Particle_Spacer_Css.toCss(screen).concat([
                            CssJs.paddingRight(cssPaddingRight),
                            CssJs.maxWidth(Css_Js_Core.Calc.$neg({
                                      NAME: "vw",
                                      VAL: 100
                                    }, cssPaddingRight))
                          ]);
              }));
}

var arrowRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.margin(CssJs.auto),
      CssJs.cursor("pointer"),
      CssJs.pointerEvents("none"),
      CssJs.width(arrowSizePx),
      CssJs.height(arrowSizePx)
    ]);

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeRight("Zero"), undefined, undefined, Particle_Spacer_Ds.makeRight("S"));

function Organism_Dropdown$Label(props) {
  var style = props.style;
  var label = props.label;
  if (label !== undefined && style !== undefined) {
    return JsxRuntime.jsx(Atom_Text.make, {
                blockSpace: Caml_option.some(blockSpace),
                children: Caml_option.some(label),
                kind: "p",
                style: style
              });
  } else {
    return null;
  }
}

function Organism_Dropdown$Container(props) {
  var arrowPosition = function (contentSpacerOpt, param) {
    var contentSpacer = contentSpacerOpt !== undefined ? contentSpacerOpt : "S";
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                TAG: "Absolute",
                _0: {
                  top: CssJs.zero,
                  bottom: CssJs.zero,
                  right: contentSpacerPx(contentSpacer)
                }
              });
  };
  return JsxRuntime.jsxs(Atom_Box.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsBaseline),
              autoSize: props.autoSize,
              children: [
                JsxRuntime.jsx(Organism_Dropdown$Label, {
                      label: props.label,
                      style: props.labelStyle
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      children: [
                        props.children,
                        JsxRuntime.jsx(Atom_Box.make, {
                              children: Caml_option.some(JsxRuntime.jsx(Atom_Icon.make, {
                                        icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.Arrow.down, "Text"),
                                        size: arrowSizeDsScreen
                                      })),
                              position: Caml_option.some(arrowPosition(props.contentSpacer, undefined)),
                              rules: Caml_option.some(arrowRules)
                            })
                      ],
                      height: props.height,
                      position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                      width: props.width
                    })
              ],
              direction: props.direction,
              identifier: Caml_option.some(Identifiers.Dropdown.container),
              wrap: "MultiLine",
              innerSpace: props.innerSpace
            });
}

function make(param) {
  var autoSize = param.autoSize;
  var accessibility = param.accessibility;
  var onChange = param.onChange;
  var value = param.value;
  var label = param.label;
  var items = param.items;
  var kind = param.kind;
  if (kind.TAG === "Select") {
    var match = kind._0;
    var variant = match.variant;
    var match$1 = Organism_Dropdown_Ds.Select.makeContainerView(variant);
    var contentSpacer = match$1.contentSpacer;
    var autoSize$1 = autoSize !== undefined ? Caml_option.some(Caml_option.valFromOption(autoSize)) : match$1.autoSize;
    var rules = contentRules(contentSpacer, undefined);
    return JsxRuntime.jsx(Organism_Dropdown$Container, {
                children: JsxRuntime.jsx(Organism_Dropdown$Organism_Dropdown_Select, {
                      accessibility: accessibility,
                      background: match$1.background,
                      name: match.name,
                      onChange: onChange,
                      onBlur: param.onBlur,
                      title: match.title,
                      autoComplete: match.autoComplete,
                      items: items,
                      value: value,
                      rules: rules,
                      variant: variant,
                      id: param.id,
                      ref_: param.ref_
                    }),
                label: label,
                labelStyle: match$1.labelStyle,
                autoSize: autoSize$1,
                height: match$1.height,
                innerSpace: match$1.innerSpace,
                iconColor: param.iconColor,
                contentSpacer: contentSpacer,
                direction: match$1.direction,
                width: match$1.width
              });
  }
  var match$2 = Core__Option.getOr(kind._0, {
        title: undefined,
        titleItems: undefined,
        buttonKind: undefined,
        variant: "list"
      });
  var variant$1 = match$2.variant;
  var match$3 = Organism_Dropdown_Ds.List.makeContainerView(variant$1);
  var contentSpacer$1 = match$3.contentSpacer;
  return JsxRuntime.jsx(Organism_Dropdown$Container, {
              children: JsxRuntime.jsx(Organism_Dropdown$Organism_Dropdown_List, {
                    accessibility: accessibility,
                    background: match$3.background,
                    onChange: onChange,
                    items: items,
                    value: value,
                    title: match$2.title,
                    titleItems: match$2.titleItems,
                    buttonKind: match$2.buttonKind,
                    rules: contentRules(contentSpacer$1, undefined),
                    variant: variant$1
                  }),
              label: label,
              labelStyle: match$3.labelStyle,
              autoSize: match$3.autoSize,
              height: match$3.height,
              innerSpace: match$3.innerSpace,
              contentSpacer: contentSpacer$1,
              direction: match$3.direction
            });
}

var Organism_Dropdown_Select = {
  AutoComplete: {}
};

export {
  Organism_Dropdown_Select ,
  make ,
}
/* zIndexRule Not a pure module */
