// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "m19.47 10.67 1.89 1.89-3.46 3.46 3.46 3.46-1.89 1.89-3.46-3.46-3.46 3.46-1.89-1.89 3.46-3.46-3.46-3.46 1.89-1.89 3.46 3.46 3.46-3.46Z"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
