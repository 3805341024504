// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M24.3227 7.51237C22.0423 5.24912 19.0098 4 15.7876 4C12.5654 4 9.52751 5.48865 7.24709 7.75189C5.03356 9.94283 3.78534 12.5459 3.71303 16.1695H0L4.85007 21.9559L9.70557 16.1695H5.99887C6.0766 13.7725 7.08621 11.1242 8.85866 9.35714C10.7098 7.52322 13.1682 6.39159 15.7822 6.39159C18.3952 6.39159 20.8555 7.34516 22.7003 9.17908C24.545 11.013 25.5655 13.4272 25.5655 16.0249C25.5655 18.6225 24.545 21.0476 22.7003 22.8869C20.8492 24.7217 18.3961 25.7304 15.7822 25.7304C15.2417 25.7304 14.8512 25.6798 14.3053 25.5912L14.11 25.5298V27.8373L14.2104 27.8762C14.7789 27.9611 15.2805 28 15.8554 28C19.0767 28 22.0712 26.75 24.3516 24.4867C26.632 22.2181 27.8695 19.2073 27.8695 16.0023C27.8802 12.7909 26.6022 9.77562 24.3227 7.51237Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M13.0117 11.4036V21.0865L21.6471 16.2419L13.0117 11.4036Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
