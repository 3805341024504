// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Zone_Type from "../../zone/Zone_Type.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Emac_Teaser from "../../emac/Emac_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Organism_Footer from "../organism/footer/Organism_Footer.res.mjs";
import * as Organism_Header from "../organism/header/Organism_Header.res.mjs";
import * as Organism_Error404 from "../organism/oops/Organism_Error404.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

function make(param) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Organism_Header.make, {}),
                JsxRuntime.jsx(Organism_Error404.make, {
                      title: param.title,
                      subTitle: param.subTitle,
                      cta: param.ctaLabel,
                      ctaHref: param.ctaHref
                    }),
                JsxRuntime.jsx(Organism_Footer.make, {})
              ]
            });
}

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("None", "None", "M", "M"), undefined, undefined, Particle_Spacer_Ds.make("None", "None", "S", "S"));

function make$1(param) {
  var match = Context.I18n.use();
  var formaters = match.formaters;
  var nextBroadcastDateFormater = formaters.nextBroadcastDate;
  var betweenDatesFormater = formaters.betweenDates;
  var dateFormater = formaters.availabilityDate;
  var match$1 = Context.Config.use();
  var timeReference = match$1.serverTime;
  var toDsTeaser = function (titleBehavior, teaser) {
    return Emac_Teaser.toDsTeaser(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, undefined, titleBehavior, undefined, undefined, undefined, teaser);
  };
  var sliderZone = Core__Option.flatMap(param.sliderZone, (function (__x) {
          return Zone_Type.toDsZone(toDsTeaser, undefined, undefined, "", "", "Search", undefined, __x);
        }));
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(blockSpace),
              children: JsxRuntime.jsx(Organism_Error404.Haiku.make, {
                    backgoundImageSrc: param.backgoundImageSrc,
                    contentHtml: param.contentHtml,
                    programTitle: param.programTitle,
                    sliderZone: sliderZone
                  })
            });
}

var Haiku = {
  blockSpace: blockSpace,
  make: make$1
};

export {
  make ,
  Haiku ,
}
/* blockSpace Not a pure module */
