// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              d: "M16.25 4.5L19.6224 12.6083L28.376 13.31L21.7067 19.023L23.7443 27.565L16.25 22.9875L8.75574 27.565L10.7933 19.023L4.12403 13.31L12.8776 12.6083L16.25 4.5Z",
              stroke: "white",
              strokeLinejoin: "round",
              strokeWidth: "1.8"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
