// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as User_Type from "../User_Type.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Api_Config from "./User_Api_Config.res.mjs";

function tokenPayload_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "grant_type",
                    false,
                    Spice.stringToJson(v.grant_type)
                  ],
                  [
                    "username",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Type_String.User.Email.t_encode, extra);
                        })(v.email)
                  ],
                  [
                    "password",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.password)
                  ],
                  [
                    "anonymous_token",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.anonymous_token)
                  ],
                  [
                    "code",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.code)
                  ],
                  [
                    "redirect_uri",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.redirectUri)
                  ]
                ]));
}

function errorResponse_encode(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError = User_Api_Config.$$Error.mapError;

var endpoint = {
  NAME: "Static",
  VAL: "token"
};

var options = {
  tokenKind: "Sso"
};

function makeBody(args) {
  return tokenPayload_encode({
              grant_type: "password",
              email: args.email,
              password: args.password,
              anonymous_token: Core__Option.map(args.anonymousToken, User_Type.Token.toString)
            });
}

function ssoResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var access_token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "access_token"), null));
  if (access_token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              access_token: access_token._0
            }
          };
  }
  var e = access_token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".access_token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function decodeApiResponse(t) {
  var err = ssoResponse_decode(t);
  if (err.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: User_Type.Token.make(err._0.access_token)
          };
  } else {
    return {
            TAG: "Error",
            _0: err._0
          };
  }
}

var WithPasswordConfig = {
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  mapError: mapError,
  domain: "Sso",
  endpoint: endpoint,
  method: "Post",
  options: options,
  makeBody: makeBody,
  ssoResponse_decode: ssoResponse_decode,
  decodeApiResponse: decodeApiResponse
};

var WithPassword = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode,
      errorResponse_decode: errorResponse_decode,
      domain: "Sso",
      mapError: mapError,
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

function errorResponse_encode$1(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$1(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$1 = User_Api_Config.$$Error.mapError;

var endpoint$1 = {
  NAME: "Static",
  VAL: "token"
};

function makeBody$1(param) {
  return tokenPayload_encode({
              grant_type: "anonymous"
            });
}

function ssoResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var access_token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "access_token"), null));
  if (access_token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              access_token: access_token._0
            }
          };
  }
  var e = access_token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".access_token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function decodeApiResponse$1(t) {
  var err = ssoResponse_decode$1(t);
  if (err.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: User_Type.Token.make(err._0.access_token)
          };
  } else {
    return {
            TAG: "Error",
            _0: err._0
          };
  }
}

var options$1 = {
  tokenKind: "Sso"
};

var AnonymousConfig = {
  errorResponse_encode: errorResponse_encode$1,
  errorResponse_decode: errorResponse_decode$1,
  mapError: mapError$1,
  domain: "Sso",
  endpoint: endpoint$1,
  method: "Post",
  makeBody: makeBody$1,
  ssoResponse_decode: ssoResponse_decode$1,
  decodeApiResponse: decodeApiResponse$1,
  options: options$1
};

var Anonymous = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$1,
      errorResponse_decode: errorResponse_decode$1,
      domain: "Sso",
      mapError: mapError$1,
      method: "Post",
      endpoint: endpoint$1,
      makeBody: makeBody$1,
      decodeApiResponse: decodeApiResponse$1,
      options: options$1
    });

function errorResponse_encode$2(v) {
  return User_Api_Config.$$Error.error_t_encode(v);
}

function errorResponse_decode$2(v) {
  return User_Api_Config.$$Error.error_t_decode(v);
}

var mapError$2 = User_Api_Config.$$Error.mapError;

var endpoint$2 = {
  NAME: "Static",
  VAL: "token"
};

var options$2 = {};

function makeBody$2(args) {
  return tokenPayload_encode({
              grant_type: "authorization_code",
              code: args.code,
              redirectUri: args.redirectUri
            });
}

function ssoResponse_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var access_token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "access_token"), null));
  if (access_token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              access_token: access_token._0
            }
          };
  }
  var e = access_token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".access_token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function decodeApiResponse$2(t) {
  var err = ssoResponse_decode$2(t);
  if (err.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: User_Type.Token.make(err._0.access_token)
          };
  } else {
    return {
            TAG: "Error",
            _0: err._0
          };
  }
}

var WithCodeConfig = {
  errorResponse_encode: errorResponse_encode$2,
  errorResponse_decode: errorResponse_decode$2,
  mapError: mapError$2,
  domain: "Sso",
  endpoint: endpoint$2,
  method: "Post",
  options: options$2,
  makeBody: makeBody$2,
  ssoResponse_decode: ssoResponse_decode$2,
  decodeApiResponse: decodeApiResponse$2
};

var WithCode = User_Api_Config.MakeMutation({
      errorResponse_encode: errorResponse_encode$2,
      errorResponse_decode: errorResponse_decode$2,
      domain: "Sso",
      mapError: mapError$2,
      method: "Post",
      endpoint: endpoint$2,
      makeBody: makeBody$2,
      decodeApiResponse: decodeApiResponse$2,
      options: options$2
    });

var getTokenWithPassword = WithPassword.call;

var getAnonymousToken = Anonymous.call;

var getTokenWithAuthorizationCode = WithCode.call;

export {
  tokenPayload_encode ,
  WithPasswordConfig ,
  WithPassword ,
  getTokenWithPassword ,
  AnonymousConfig ,
  Anonymous ,
  getAnonymousToken ,
  WithCodeConfig ,
  WithCode ,
  getTokenWithAuthorizationCode ,
}
/* WithPassword Not a pure module */
