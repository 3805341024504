// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Css_font from "../design-system/particle/Particle_Css_font.res.mjs";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";

var heightStyle = Particle_Screen_Ds.make(undefined, undefined, Particle_Css_font.pxToRem(56), undefined, undefined, Particle_Css_font.pxToRem(40));

var Constants_height = {
  m: 56,
  default: 40
};

var Constants = {
  height: Constants_height,
  heightStyle: heightStyle,
  verticalPadding: 5
};

export {
  Constants ,
}
/* heightStyle Not a pure module */
