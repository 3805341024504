import { env } from '@/env';
import { addHours, isFuture } from '@artegeie/date';
import { useTokenStatus } from '@artegeie/design-system/replay';
import { signin } from '@replay/oidc-client';
import { eventStore } from '@replay/tracking/eventStore';
import { Locale } from '@replay/types/Locale';
import { ServerSideTracking } from '@replay/types/Stats';
import { useCallback } from 'react';
import { create, type StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { subheader } from './Navbar';
import { useYoti } from './useYoti';
import { userStore } from '@replay/tracking/userStore';

type AuthRedirect = {
    returnPage: string;
    shouldVerifyAge: boolean;
    expirationDate: Date;
};
interface AuthData {
    legacy__userEmail: string | undefined;
    legacy__savedRedirect: AuthRedirect | null;
    subheaders: subheader[];
    legacy__setUserEmail: (email: string) => void;
    addSubheader: (subheader: subheader) => void;
    removeSubheader: (subheader: subheader) => void;
    clearSubheaders: () => void;
    legacy__saveRedirect: (redirect: Omit<AuthRedirect, 'expirationDate'>) => void;
    legacy__clearRedirect: () => void;
    legacy__getRedirect: () => AuthRedirect | null;
}

const createAuthData: StateCreator<AuthData> = (set, get) => ({
    legacy__savedRedirect: null,
    subheaders: [],
    legacy__userEmail: undefined,
    legacy__setUserEmail: (email) => {
        set({
            legacy__userEmail: email,
        });
    },
    addSubheader: (sub) => {
        set((state) => {
            // do not add the same subheader twice
            if (state.subheaders.find((s) => s === sub)) {
                return state;
            }
            return { ...state, subheaders: [...state.subheaders, sub] };
        });
    },
    removeSubheader: (sub) => {
        set((state) => {
            return { ...state, subheaders: state.subheaders.filter((s) => s !== sub) };
        });
    },
    clearSubheaders: () => {
        set({
            subheaders: [],
        });
    },
    legacy__saveRedirect: ({ returnPage, shouldVerifyAge }) => {
        set({
            legacy__savedRedirect: {
                shouldVerifyAge,
                returnPage,
                expirationDate: addHours(new Date(), 1),
            },
        });
    },
    legacy__clearRedirect: () => {
        set({
            legacy__savedRedirect: null,
        });
    },
    legacy__getRedirect: () => {
        const savedRedirect = get().legacy__savedRedirect;
        if (!savedRedirect) {
            return null;
        }
        const { returnPage, expirationDate } = savedRedirect;
        if (returnPage && expirationDate && isFuture(expirationDate)) {
            return savedRedirect;
        } else {
            return null;
        }
    },
});

export const useAuthStore = create<AuthData>()(
    devtools(
        persist(
            (...a) => ({
                ...createAuthData(...a),
            }),
            { name: 't_auth_replay' },
        ),
    ),
);

export const legacy__validateEmailQuery = (email: string | string[] | undefined): string | undefined => {
    if (typeof email === 'string') {
        return email;
    }
    if (Array.isArray(email)) {
        return email[0];
    }
    return undefined;
};

export const isValidUrl = (maybeURL?: string | URL | null): maybeURL is URL => {
    if (!maybeURL) return false;
    try {
        return Boolean(new URL(maybeURL));
    } catch {
        return false;
    }
};

export const validateReturnPageQuery = (returnPage: string | string[] | undefined | null): string | undefined => {
    if (!returnPage) return undefined;
    const urlToValidate = Array.isArray(returnPage) ? returnPage[0] : returnPage;
    const decoded = decodeURIComponent(urlToValidate);
    return isValidUrl(decoded) ? decoded : undefined;
};

export const validateShouldVerifyAgeQuery = (shouldVerifyAge: string | string[] | undefined): boolean => {
    const value = Array.isArray(shouldVerifyAge) ? shouldVerifyAge[0] : shouldVerifyAge;
    return value === 'true';
};

export type FromQuery = 'age-verification';
export const validateFromQuery = (from: string | string[] | undefined): FromQuery | undefined => {
    const value = Array.isArray(from) ? from[0] : from;
    if (value === 'age-verification') {
        return value;
    }
};

const getRedirectParams = (returnPage: string, shouldVerifyAge: boolean) => {
    const searchParams = new URLSearchParams();
    searchParams.set('shouldVerifyAge', String(shouldVerifyAge));
    searchParams.set('returnPage', returnPage);
    return searchParams;
};

export const useLegacyRedirect = (locale: Locale) => {
    const { legacy__getRedirect, legacy__clearRedirect } = useAuthStore.getState();
    const savedRedirect = legacy__getRedirect();
    const redirect = useCallback(() => {
        let returnPage = `/${locale}/`;
        if (savedRedirect) {
            returnPage = savedRedirect.returnPage;
            legacy__clearRedirect();
        }
        window.location.assign(returnPage);
    }, [savedRedirect, legacy__clearRedirect, locale]);
    return redirect;
};

export const getBaseURL = (locale: Locale) => {
    return `${env.NEXT_PUBLIC_REPLAY_BASE_URL}${locale}`;
};

const getCurrentUrl = () => {
    if (typeof window === 'undefined') return '';
    return window.location.href;
};

export const getLandingUrl = (locale: Locale) => {
    return new URL(`${getBaseURL(locale)}/profile/auth/landing/`);
};
export const getCallbackUrl = (locale: Locale) => {
    return new URL(`${getBaseURL(locale)}/profile/auth/callback/`);
};

const useEventStore = eventStore(env.NODE_ENV);
export const useStartAuth = (locale: Locale, stats?: ServerSideTracking) => {
    const { sendAgeVerificationEvent } = useEventStore();
    const { getEncodedData } = userStore();
    const loginStatus = useTokenStatus();
    const redirectToYoti = useYoti(locale);
    const getLoginUrl = useCallback(
        (returnPage?: string, shouldVerifyAge?: boolean) => {
            const currentUrl = returnPage || getCurrentUrl();
            const landingUrl = getLandingUrl(locale);
            const callbackUrl = getCallbackUrl(locale);
            // The callback page will redirect to the final page
            callbackUrl.search = getRedirectParams(currentUrl, shouldVerifyAge || false).toString();
            // The landing page will redirect to the callback page
            landingUrl.search = getRedirectParams(callbackUrl.toString(), shouldVerifyAge || false).toString();
            return landingUrl.href;
        },
        [locale],
    );
    const startAuth = useCallback(
        ({ shouldVerifyAge, returnPage }: { shouldVerifyAge: boolean; returnPage?: string }) => {
            if (loginStatus !== 'Connected') {
                if (env.NEXT_PUBLIC_FEATURE_FLAGS_OIDC_AUTH) {
                    signin({
                        extraQueryParams: {
                            shouldVerifyAge: shouldVerifyAge ? 'true' : 'false',
                        },
                        encodedTrackingData: getEncodedData(),
                        returnPage: returnPage || getCurrentUrl(),
                        locale,
                    });
                } else {
                    const authUrl = getLoginUrl(returnPage || window.location.href, shouldVerifyAge);
                    window.location.assign(authUrl);
                }
                return;
            }
            if (!shouldVerifyAge) return;
            if (stats) {
                sendAgeVerificationEvent(stats, 'VERIFY_AGE_BUTTON');
            }
            redirectToYoti(returnPage);
        },
        [loginStatus, stats, redirectToYoti, getEncodedData, locale, getLoginUrl, sendAgeVerificationEvent],
    );
    return {
        startAuth,
        loginUrl: getLoginUrl(),
    };
};
