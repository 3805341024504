// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M6.75729 4.45447C6.42403 4.25447 6 4.49453 6 4.8832V27.1221C6 27.5108 6.42403 27.7508 6.75729 27.5508L25.2856 16.4314C25.6092 16.2372 25.6092 15.7681 25.2856 15.5739L6.75729 4.45447Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
