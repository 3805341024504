// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Nullable from "@rescript/core/src/Core__Nullable.res.mjs";

function alt(fa1, fa2) {
  if (fa1 !== undefined) {
    return fa1;
  } else {
    return fa2;
  }
}

function flatten(opt) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  }
  
}

function getWithDefaultLazy(opt, getDefault) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  } else {
    return getDefault();
  }
}

function mapWithDefaultLazy(opt, getDefault, f) {
  if (opt !== undefined) {
    return f(Caml_option.valFromOption(opt));
  } else {
    return getDefault();
  }
}

var fromNullable = Core__Nullable.fromOption;

export {
  fromNullable ,
  alt ,
  flatten ,
  getWithDefaultLazy ,
  mapWithDefaultLazy ,
}
/* No side effect */
