// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";

var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.gridTemplateColumns([
            {
              NAME: "fr",
              VAL: 1
            },
            {
              NAME: "fr",
              VAL: 1
            },
            {
              NAME: "fr",
              VAL: 1
            }
          ]),
      CssJs.justifyItems("center"),
      CssJs.alignItems("center"),
      CssJs.gridGap({
            NAME: "px",
            VAL: 25
          }),
      CssJs.marginBottom("zero")
    ]);

var columnBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));

var wrapperDisplay = Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "grid");

var wrapperJustifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

function Organism_PartnersSection(props) {
  var partnersLink = props.partners.map(function (param) {
        var label = param.label;
        return JsxRuntime.jsx(Atom_Cell.make, {
                    kind: "ItemList",
                    children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                          href: param.href,
                          screenReadersLabel: label,
                          style: Atom_Cta_Ds.Footer.makePartner(param.kind)
                        })
                  }, label);
      });
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(columnBlockSpace),
              children: JsxRuntime.jsx(Atom_Box.make, {
                    children: Caml_option.some(partnersLink),
                    display: Caml_option.some(wrapperDisplay),
                    justifyContent: Caml_option.some(wrapperJustifyContent),
                    kind: "List",
                    rules: Caml_option.some(rules),
                    wrap: "MultiLine"
                  })
            });
}

var make = Organism_PartnersSection;

export {
  rules ,
  columnBlockSpace ,
  wrapperDisplay ,
  wrapperJustifyContent ,
  make ,
}
/* rules Not a pure module */
