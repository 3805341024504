// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Logo from "../../atom/Atom_Logo.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";

function Organism_European(props) {
  var text = props.text;
  if (text !== undefined) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Atom_Column.make, {
                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"))),
                        children: [
                          JsxRuntime.jsx(Atom_Text.make, {
                                children: Caml_option.some(text),
                                kind: "span",
                                style: Atom_Text_Ds.Footer.european
                              }),
                          JsxRuntime.jsx(Atom_Logo.make, {
                                logo: "EuropeanFlag"
                              })
                        ],
                        innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")),
                        rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.alignItems(CssJs.center)]))
                      }),
                  JsxRuntime.jsx(Atom_Divider.make, {
                        style: Atom_Divider.footer
                      })
                ]
              });
  } else {
    return null;
  }
}

var make = Organism_European;

export {
  make ,
}
/* CssJs Not a pure module */
