// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_List_Ds from "../atom/Atom_List_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

function makeTextStyles(entryOpt, colorOpt, param) {
  var entry = entryOpt !== undefined ? Caml_option.valFromOption(entryOpt) : undefined;
  var color = colorOpt !== undefined ? colorOpt : "Text";
  var default_h1 = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_h2 = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_h3 = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_p = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_a = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_strong = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_em = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_li = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_th = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var default_td = {
    text: Atom_Text_Ds.DangerousHtml.makeDefault(color),
    spacer: undefined
  };
  var $$default = {
    h1: default_h1,
    h2: default_h2,
    h3: default_h3,
    p: default_p,
    a: default_a,
    strong: default_strong,
    em: default_em,
    li: default_li,
    th: default_th,
    td: default_td
  };
  if (entry === undefined) {
    return $$default;
  }
  switch (entry) {
    case "AndroidReleases" :
        return {
                h1: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("h1", color),
                  spacer: Particle_Spacer_Ds.Inside.makeBottom("S")
                },
                h2: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("h2", color),
                  spacer: Particle_Spacer_Ds.Inside.makeBottom("XS")
                },
                h3: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("h3", color),
                  spacer: Particle_Spacer_Ds.Inside.makeBottom("XXS")
                },
                p: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("p", color),
                  spacer: Particle_Spacer_Ds.Inside.makeBottom("S")
                },
                a: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("a", color),
                  spacer: undefined
                },
                strong: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("strong", color),
                  spacer: undefined
                },
                em: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("em", color),
                  spacer: undefined
                },
                li: {
                  text: Atom_Text_Ds.DangerousHtml.makeAndroidReleases("li", color),
                  spacer: undefined
                },
                th: default_th,
                td: default_td
              };
    case "Papa" :
        return {
                h1: {
                  text: Atom_Text_Ds.DangerousHtml.makePapa("h1", color),
                  spacer: undefined
                },
                h2: {
                  text: Atom_Text_Ds.DangerousHtml.makePapa("h2", color),
                  spacer: undefined
                },
                h3: {
                  text: Atom_Text_Ds.DangerousHtml.makePapa("h3", color),
                  spacer: undefined
                },
                p: {
                  text: Atom_Text_Ds.DangerousHtml.makePapa("p", color),
                  spacer: undefined
                },
                a: default_a,
                strong: default_strong,
                em: default_em,
                li: {
                  text: Atom_Text_Ds.DangerousHtml.makePapa("li", color),
                  spacer: undefined
                },
                th: default_th,
                td: default_td
              };
    case "Presse" :
        return {
                h1: default_h1,
                h2: default_h2,
                h3: default_h3,
                p: default_p,
                a: default_a,
                strong: {
                  text: Atom_Text_Ds.DangerousHtml.makeTable("strong", "Black"),
                  spacer: undefined
                },
                em: default_em,
                li: default_li,
                th: {
                  text: Atom_Text_Ds.DangerousHtml.makeTable("th", "White"),
                  spacer: Particle_Spacer_Ds.makeAll("S")
                },
                td: {
                  text: Atom_Text_Ds.DangerousHtml.makeTable("td", "Black"),
                  spacer: Particle_Spacer_Ds.makeAll("S")
                }
              };
    case "UserCheckbox" :
        return {
                h1: default_h1,
                h2: default_h2,
                h3: default_h3,
                p: {
                  text: Atom_Text_Ds.User.checkbox,
                  spacer: undefined
                },
                a: {
                  text: Atom_Text_Ds.User.checkboxLink,
                  spacer: undefined
                },
                strong: default_strong,
                em: default_em,
                li: default_li,
                th: default_th,
                td: default_td
              };
    case "UserDescription" :
        return {
                h1: default_h1,
                h2: default_h2,
                h3: default_h3,
                p: {
                  text: Atom_Text_Ds.User.text("MyAccount"),
                  spacer: undefined
                },
                a: {
                  text: Atom_Text_Ds.User.link,
                  spacer: undefined
                },
                strong: default_strong,
                em: {
                  text: Atom_Text_Ds.User.text("MyAccount"),
                  spacer: undefined
                },
                li: default_li,
                th: default_th,
                td: default_td
              };
    case "UserAuth" :
        return {
                h1: default_h1,
                h2: default_h2,
                h3: default_h3,
                p: default_p,
                a: {
                  text: Atom_Text_Ds.User.link,
                  spacer: undefined
                },
                strong: default_strong,
                em: default_em,
                li: {
                  text: Atom_Text_Ds.User.text("Auth"),
                  spacer: undefined
                },
                th: default_th,
                td: default_td
              };
    
  }
}

var default_text = makeTextStyles(undefined, undefined, undefined);

var default_ul = {
  list: Atom_List_Ds.defaultUl,
  spacer: undefined
};

var default_ol = {
  list: Atom_List_Ds.defaultOl,
  spacer: undefined
};

var $$default = {
  text: default_text,
  ul: default_ul,
  ol: default_ol
};

function androidReleases(color) {
  return {
          text: makeTextStyles("AndroidReleases", color, undefined),
          ul: {
            list: Atom_List_Ds.androidReleasesUl(color),
            spacer: Particle_Spacer_Ds.Inside.makeBottom("S")
          },
          ol: {
            list: Atom_List_Ds.androidReleasesOl(color),
            spacer: Particle_Spacer_Ds.Inside.makeBottom("S")
          },
          img: {
            image: {
              TAG: "DangerousHtml",
              _0: "AndroidReleases"
            },
            spacer: Particle_Spacer_Ds.Inside.makeVertical("M"),
            maxWidth: Caml_option.some(Particle_Screen_Ds.make(undefined, {
                      NAME: "percent",
                      VAL: 80
                    }, {
                      NAME: "percent",
                      VAL: 60
                    }, {
                      NAME: "percent",
                      VAL: 50
                    }, undefined, {
                      NAME: "percent",
                      VAL: 100
                    }))
          }
        };
}

function papa(color) {
  return {
          text: makeTextStyles("Papa", color, undefined),
          ul: {
            list: Atom_List_Ds.papaUl,
            spacer: undefined
          },
          ol: {
            list: Atom_List_Ds.defaultOl,
            spacer: undefined
          }
        };
}

function presse(color) {
  var newrecord = Caml_obj.obj_dup($$default);
  newrecord.table = {
    thBackground: "Black",
    tdBackgroundOdd: {
      NAME: "LightGreyWithOpacity",
      VAL: 10
    }
  };
  newrecord.ul = {
    list: Atom_List_Ds.presseUl,
    spacer: undefined
  };
  newrecord.text = makeTextStyles("Presse", color, undefined);
  return newrecord;
}

var newrecord = Caml_obj.obj_dup($$default);

newrecord.table = undefined;

newrecord.img = undefined;

newrecord.text = makeTextStyles("UserCheckbox", "Text", undefined);

var newrecord$1 = Caml_obj.obj_dup($$default);

newrecord$1.iframe = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.maxWidth(CssJs.pct(100))]));

newrecord$1.table = undefined;

newrecord$1.img = undefined;

newrecord$1.text = makeTextStyles("UserDescription", "Text", undefined);

var newrecord$2 = Caml_obj.obj_dup($$default);

newrecord$2.text = makeTextStyles("UserAuth", "Text", undefined);

var newrecord$3 = Caml_obj.obj_dup($$default);

var init = default_text;

newrecord$3.text = {
  h1: init.h1,
  h2: init.h2,
  h3: init.h3,
  p: {
    text: Atom_Text_Ds.Cookie.text,
    spacer: undefined
  },
  a: {
    text: Atom_Text_Ds.Cookie.linkModal,
    spacer: undefined
  },
  strong: init.strong,
  em: init.em,
  li: init.li,
  th: init.th,
  td: init.td
};

function view(param) {
  var text = param.text;
  return {
          h1: text.h1,
          h2: text.h2,
          h3: text.h3,
          p: text.p,
          a: text.a,
          strong: text.strong,
          em: text.em,
          li: text.li,
          ul: param.ul,
          ol: param.ol,
          img: param.img,
          th: text.th,
          td: text.td,
          table: param.table,
          iframe: param.iframe
        };
}

var userCheckbox = newrecord;

var userDescription = newrecord$1;

var userAuth = newrecord$2;

var cookieModal = newrecord$3;

export {
  makeTextStyles ,
  $$default as default,
  androidReleases ,
  papa ,
  presse ,
  userCheckbox ,
  userDescription ,
  userAuth ,
  cookieModal ,
  view ,
}
/* default Not a pure module */
