'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Core__List from "@rescript/core/src/Core__List.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "./Particle_Css.res.mjs";
import * as Particle_Screen_Ds from "./Particle_Screen_Ds.res.mjs";

function transform(transformer, screenSize) {
  var variant = screenSize.NAME;
  if (variant === "M") {
    return {
            NAME: "M",
            VAL: transformer(screenSize.VAL)
          };
  } else if (variant === "S") {
    return {
            NAME: "S",
            VAL: transformer(screenSize.VAL)
          };
  } else if (variant === "XL") {
    return {
            NAME: "XL",
            VAL: transformer(screenSize.VAL)
          };
  } else if (variant === "XS") {
    return {
            NAME: "XS",
            VAL: transformer(screenSize.VAL)
          };
  } else {
    return {
            NAME: "L",
            VAL: transformer(screenSize.VAL)
          };
  }
}

function mediaQueryTransformer(mediaQueryKind, screenResolution, screenResolution2) {
  if (mediaQueryKind === "MinWidth" || screenResolution2 === undefined) {
    return Particle_Css.minWidthMediaQuery(screenResolution);
  } else {
    return Particle_Css.minMaxWidthMediaQuery(screenResolution, screenResolution2);
  }
}

function getMediaQuery(mediaQueryKind, screenResolution, screenResolution2) {
  return CssJs.media(mediaQueryTransformer(mediaQueryKind, screenResolution, screenResolution2), screenResolution.VAL);
}

function identity(a) {
  return a;
}

function toRules(selectorOpt, mediaQueryKindOpt, toCssRules, responsive) {
  var selector = selectorOpt !== undefined ? selectorOpt : identity;
  var mediaQueryKind = mediaQueryKindOpt !== undefined ? mediaQueryKindOpt : "MinWidth";
  var prop = Particle_Screen_Ds.view(responsive);
  var tmp;
  if (prop.TAG === "AllSize") {
    tmp = toCssRules(prop._0);
  } else {
    var responsiveSizes = prop._1;
    var transformer = function (extra) {
      return transform(toCssRules, extra);
    };
    var responsiveRules = responsiveSizes.map(function (prop, index) {
          var width2;
          width2 = mediaQueryKind === "MinWidth" ? undefined : Core__List.get(Core__List.fromArray(responsiveSizes), index + 1 | 0);
          var screenResolution = transform(toCssRules, prop);
          var screenResolution2 = Core__Option.map(width2, transformer);
          return getMediaQuery(mediaQueryKind, screenResolution, screenResolution2);
        });
    var baseRules = toCssRules(prop._0);
    tmp = baseRules.concat(responsiveRules);
  }
  return selector(tmp);
}

function toCss2(toCssRules, responsive) {
  var rules = toRules(identity, undefined, toCssRules, responsive);
  return CssJs.style(rules);
}

var hasMatches = (function(mediaQueries) {
          return mediaQueries.matches;
        });

function getResolutionFromScreensize($$window) {
  var innerWidth = $$window.innerWidth;
  var match = hasMatches($$window.matchMedia(Particle_Css.minWidthMediaQuery({
                NAME: "L",
                VAL: undefined
              })));
  var match$1 = hasMatches($$window.matchMedia(Particle_Css.minWidthMediaQuery({
                NAME: "M",
                VAL: undefined
              })));
  var match$2 = hasMatches($$window.matchMedia(Particle_Css.minWidthMediaQuery({
                NAME: "S",
                VAL: undefined
              })));
  if (match) {
    return {
            NAME: "L",
            VAL: innerWidth
          };
  } else if (match$1) {
    return {
            NAME: "M",
            VAL: innerWidth
          };
  } else if (match$2) {
    return {
            NAME: "S",
            VAL: innerWidth
          };
  } else {
    return {
            NAME: "XS",
            VAL: innerWidth
          };
  }
}

export {
  transform ,
  mediaQueryTransformer ,
  getMediaQuery ,
  identity ,
  toRules ,
  toCss2 ,
  hasMatches ,
  getResolutionFromScreensize ,
}
/* CssJs Not a pure module */
