// Generated by ReScript, PLEASE EDIT WITH CARE


function keepNdecimal(num, n) {
  var pow = Math.pow(10.0, n);
  var multiplied = num >= 0.0 ? Math.floor(pow * num) : Math.ceil(pow * num);
  return multiplied / pow;
}

export {
  keepNdecimal ,
}
/* No side effect */
