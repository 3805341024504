// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Screen_Ds from "./Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "./Particle_Spacer_Ds.res.mjs";
import * as Particle_Spacer_Constants from "./Particle_Spacer_Constants.res.mjs";

var $$default = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.desktop, undefined, undefined, Particle_Spacer_Constants.Zone.mobile);

var Zone = {
  $$default: $$default
};

var empty = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.allZero, undefined, undefined, Particle_Spacer_Constants.allZero);

var oneHundredPctWidth = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100));

var oneHundredPctMaxWidth = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100));

var oneHundredPctHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100));

var oneHundredPctMaxHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(100));

var alignItemsCenter = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var alignItemsFlexStart = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexStart");

var alignItemsFlexEnd = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexEnd");

var alignItemsBaseline = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "baseline");

var alignItemsStretch = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "stretch");

var alignSelfStart = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexStart");

var alignSelfCenter = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var alignSelfEnd = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexEnd");

var justifyContentCenter = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var justifyContentSpaceBetween = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "spaceBetween");

var justifyContentSpaceAround = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "spaceAround");

var justifyContentFlexStart = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.flexStart);

var justifyContentFlexEnd = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.flexEnd);

var mInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var sInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var xsInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var xxsInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var xxsGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var mGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var xxsVerticalBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("XXS"));

var xxsBottomBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("XXS"));

var xxxsBottomBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("XXXS"));

var xsBottomBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("XS"));

var mBottomBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("M"));

var lBottomBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

var lTopBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"));

var emptyRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);

var flexDirectionRow = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.row);

var flexDirectionColumn = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.column);

var autoSizeAllPlaceNeeded = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "AllPlaceNeeded");

var autoSizeAllPlaceAvailable = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "AllPlaceAvailable");

var autoSizeNoResizing = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "NoResizing");

var displayInlineFlex = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex");

var displayInline = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inline");

var positionRelative = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Relative");

var positionAbsolute = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: undefined
    });

export {
  Zone ,
  empty ,
  oneHundredPctWidth ,
  oneHundredPctMaxWidth ,
  oneHundredPctHeight ,
  oneHundredPctMaxHeight ,
  alignItemsCenter ,
  alignItemsFlexStart ,
  alignItemsFlexEnd ,
  alignItemsBaseline ,
  alignItemsStretch ,
  alignSelfStart ,
  alignSelfCenter ,
  alignSelfEnd ,
  justifyContentCenter ,
  justifyContentSpaceBetween ,
  justifyContentSpaceAround ,
  justifyContentFlexStart ,
  justifyContentFlexEnd ,
  mInnerSpace ,
  sInnerSpace ,
  xsInnerSpace ,
  xxsInnerSpace ,
  xxsGap ,
  mGap ,
  xxsVerticalBlockSpace ,
  xxsBottomBlockSpace ,
  xxxsBottomBlockSpace ,
  xsBottomBlockSpace ,
  mBottomBlockSpace ,
  lBottomBlockSpace ,
  lTopBlockSpace ,
  emptyRules ,
  flexDirectionRow ,
  flexDirectionColumn ,
  autoSizeAllPlaceNeeded ,
  autoSizeAllPlaceAvailable ,
  autoSizeNoResizing ,
  displayInlineFlex ,
  displayInline ,
  positionRelative ,
  positionAbsolute ,
}
/* default Not a pure module */
