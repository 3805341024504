// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_String from "./Type_String.res.mjs";

var default_value = Type_String.Dropdown.Item.empty;

var default_label = Type_String.Dropdown.Item.empty;

var $$default = {
  value: default_value,
  label: default_label
};

var Item = {
  $$default: $$default
};

export {
  Item ,
}
/* No side effect */
