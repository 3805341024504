// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function append(arr, value) {
  return arr.concat([value]);
}

function prepend(arr, value) {
  return [value].concat(arr);
}

function reject(arr, f) {
  return arr.filter(function (x) {
              return !f(x);
            });
}

function getLastElement(arr) {
  return arr[arr.length - 1 | 0];
}

function pure(a) {
  return [a];
}

function getFirstElement(arr) {
  return arr[0];
}

function findIndex(xs, pred) {
  var result = Js_array.findIndex(pred, xs);
  if (result === -1) {
    return ;
  } else {
    return result;
  }
}

function tail(xs) {
  var l = xs.length;
  if (l === 0) {
    return ;
  }
  if (l === 1) {
    return [];
  }
  var ys = xs.slice(1);
  if (ys.length > 0) {
    return ys;
  }
  
}

function tailOrEmpty(xs) {
  var ys = tail(xs);
  if (ys !== undefined) {
    return ys;
  } else {
    return [];
  }
}

function init(xs) {
  var l = xs.length;
  if (l === 0) {
    return ;
  } else {
    return Belt_Array.slice(xs, 0, l - 1 | 0);
  }
}

function initOrEmpty(xs) {
  var arr = init(xs);
  if (arr !== undefined) {
    return arr;
  } else {
    return [];
  }
}

function splitAt(xs, i) {
  if (i < 0 || i > xs.length) {
    return ;
  } else {
    return [
            Belt_Array.slice(xs, 0, i),
            xs.slice(i)
          ];
  }
}

function catOption(arr) {
  return Core__Array.filterMap(arr, (function (a) {
                return a;
              }));
}

function chunk(xs, size) {
  var resultArr = [];
  var i = 0;
  if (xs.length > size && size > 0) {
    while(i < xs.length) {
      Js_array.push(Js_array.slice(i, i + size | 0, xs), resultArr);
      i = i + size | 0;
    };
  } else {
    Js_array.push(xs, resultArr);
  }
  return resultArr;
}

function make(tail, hd) {
  return [
          hd,
          Core__Option.getOr(tail, [])
        ];
}

function makeFromArray(arr) {
  return Core__Option.flatMap(arr[0], (function (v) {
                return [
                        v,
                        arr.slice(1)
                      ];
              }));
}

function toArray(param) {
  return [param[0]].concat(param[1]);
}

function map(param, mapper) {
  return [
          mapper(param[0]),
          param[1].map(mapper)
        ];
}

function mapWithIndex(param, mapper) {
  return [
          mapper(param[0], 0),
          param[1].map(function (v, i) {
                return mapper(v, i + 1 | 0);
              })
        ];
}

function forEach(param, mapper) {
  mapper(param[0]);
  param[1].forEach(mapper);
}

function every(param, pred) {
  if (pred(param[0])) {
    return Belt_Array.every(param[1], pred);
  } else {
    return false;
  }
}

function some(param, pred) {
  if (pred(param[0])) {
    return true;
  } else {
    return param[1].some(pred);
  }
}

function keep(t, pred) {
  return makeFromArray(toArray(t).filter(pred));
}

function length(param) {
  return 1 + param[1].length | 0;
}

function toString(t) {
  return toArray(t).toString();
}

function find(t, pred) {
  return toArray(t).find(pred);
}

function head(param) {
  return param[0];
}

function joinWith(t, str) {
  return toArray(t).join(str);
}

function toReactElement(t) {
  return toArray(t);
}

function add(param, a) {
  return [
          param[0],
          param[1].concat([a])
        ];
}

function reduce(t, acc, f) {
  return Core__Array.reduce(toArray(t), acc, f);
}

function extractHead(param) {
  return [
          param[0],
          makeFromArray(param[1])
        ];
}

function catOption$1(t) {
  return makeFromArray(Core__Array.filterMap(toArray(t), (function (a) {
                    return a;
                  })));
}

var NonEmpty = {
  makeFromArray: makeFromArray,
  make: make,
  toArray: toArray,
  map: map,
  mapWithIndex: mapWithIndex,
  forEach: forEach,
  every: every,
  some: some,
  keep: keep,
  length: length,
  toString: toString,
  find: find,
  head: head,
  joinWith: joinWith,
  toReactElement: toReactElement,
  add: add,
  reduce: reduce,
  extractHead: extractHead,
  catOption: catOption$1
};

export {
  append ,
  prepend ,
  reject ,
  getLastElement ,
  pure ,
  getFirstElement ,
  findIndex ,
  tail ,
  tailOrEmpty ,
  init ,
  initOrEmpty ,
  splitAt ,
  catOption ,
  chunk ,
  NonEmpty ,
}
/* No side effect */
