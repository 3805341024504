// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Type_UserAgent from "../type/Type_UserAgent.res.mjs";

function make() {
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  React.useEffect((function () {
          setState(function (param) {
                return {
                        userAgent: Type_UserAgent.userAgent,
                        platform: Type_UserAgent.platform
                      };
              });
        }), []);
  return match[0];
}

export {
  make ,
}
/* react Not a pure module */
