// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_Common from "../../type/Type_Common.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Box_Type from "./Atom_Box_Type.res.mjs";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Animation_Css from "../particle/Particle_Animation_Css.res.mjs";

function downloadToString(x) {
  if (typeof x === "object") {
    return x.VAL;
  } else {
    return "";
  }
}

var textSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("h1"),
      S$RescriptSchema.literal("h2"),
      S$RescriptSchema.literal("h3"),
      S$RescriptSchema.literal("h4"),
      S$RescriptSchema.literal("h5"),
      S$RescriptSchema.literal("h6"),
      S$RescriptSchema.literal("p"),
      S$RescriptSchema.literal("span"),
      S$RescriptSchema.literal("div"),
      S$RescriptSchema.literal("figcaption"),
      S$RescriptSchema.literal("details"),
      S$RescriptSchema.literal("summary"),
      S$RescriptSchema.literal("label"),
      S$RescriptSchema.literal("legend")
    ]);

function getClassByKind(kind) {
  if (typeof kind !== "object") {
    if (kind === "List") {
      return [
              CssJs.listStyleType("none"),
              CssJs.margin(CssJs.px(0))
            ];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function getTagAndProps(id, ref, className, accessibility, onFocus, onBlur, onMouseEnter, onMouseLeave, dataAnchor, identifier, kind) {
  var makePropsWithoutClassName = function (className) {
    return function (none, none$1, none$2, none$3, none$4, none$5, none$6, none$7, none$8, none$9, none$10, none$11, none$12, none$13, none$14, extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6) {
      var match = Core__Option.mapOr(extra$6, Particle_Accessibility.defaultView, Particle_Accessibility.view);
      var rel = Core__Option.map(extra, Type_Common.Rel.toString);
      return {
              ref: ref,
              "aria-current": match.ariaCurrent,
              "aria-disabled": match.ariaDisabled,
              "aria-hidden": match.ariaHidden,
              "aria-label": match.ariaLabel,
              "aria-roledescription": match.ariaRoledescription,
              "aria-expanded": match.ariaExpanded,
              "aria-haspopup": match.ariaHasPopup,
              "aria-modal": match.ariaModal,
              "aria-pressed": match.ariaPressed,
              "aria-live": match.ariaLive,
              "aria-controls": match.ariaControls,
              "aria-describedby": match.ariaDescribedby,
              "aria-labelledby": match.ariaLabelledby,
              defaultValue: none$6,
              className: className,
              "data-testid": Core__Option.map(identifier, Identifiers.toString),
              id: id,
              lang: match.lang,
              role: match.role,
              tabIndex: match.tabIndex,
              title: extra$3,
              alt: none,
              autoComplete: none$1,
              disabled: none$4,
              download: none$7,
              href: none$8,
              htmlFor: none$9,
              loading: none$10,
              name: none$11,
              rel: rel,
              src: extra$1,
              target: Core__Option.map(extra$2, (function (t) {
                      return t;
                    })),
              type: extra$4,
              value: extra$5,
              onFocus: onFocus,
              onBlur: onBlur,
              onChange: none$12,
              onSubmit: none$14,
              onClick: none$13,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave,
              dangerouslySetInnerHTML: Core__Option.map(none$3, (function (content) {
                      return {
                              __html: content
                            };
                    })),
              box: none$2,
              "data-anchor": none$5
            };
    };
  };
  var makeProps = makePropsWithoutClassName(className);
  if (kind !== undefined) {
    if (typeof kind !== "object") {
      switch (kind) {
        case "Blockquote" :
            return [
                    "blockquote",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Details" :
            return [
                    "details",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Fieldset" :
            return [
                    "fieldset",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Figure" :
            return [
                    "figure",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Footer" :
            return [
                    "footer",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Header" :
            var accessibility$1 = Core__Option.mapOr(accessibility, Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "banner", undefined), (function (__x) {
                    return Particle_Accessibility.set(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "banner", undefined, __x);
                  }));
            return [
                    "header",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility$1)
                  ];
        case "ItemList" :
            return [
                    "li",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "List" :
            return [
                    "ul",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Main" :
            return [
                    "main",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "main", undefined))
                  ];
        case "Section" :
            return [
                    "section",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Span" :
            return [
                    "span",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        
      }
    } else {
      switch (kind.TAG) {
        case "Button" :
            return [
                    "button",
                    makeProps(undefined, undefined, undefined, undefined, undefined, dataAnchor, undefined, undefined, undefined, undefined, undefined, undefined, undefined, kind._1, undefined, undefined, undefined, undefined, kind._2, kind._0, undefined, accessibility)
                  ];
        case "ButtonDisabled" :
            return [
                    "button",
                    makeProps(undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, kind._0, undefined, accessibility)
                  ];
        case "DangerouslySetInnerHTML" :
            return [
                    "div",
                    makeProps(undefined, undefined, undefined, kind._0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Dialog" :
            return [
                    "div",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Particle_Accessibility.makeDialog(kind._0))
                  ];
        case "DownloadLink" :
            var match = kind._0;
            var download = downloadToString(match.download);
            return [
                    "a",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, download, match.href, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Type_Common.Rel.make("noreferrer")), undefined, undefined, undefined, undefined, undefined, undefined)
                  ];
        case "EbuBox" :
            var match$1 = kind._0;
            return [
                    "div",
                    makePropsWithoutClassName(match$1.className)(undefined, undefined, match$1.ebuBox, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Form" :
            return [
                    "form",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, kind._0, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Label" :
            return [
                    "label",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, kind._0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        case "Link" :
            var match$2 = kind._0;
            var rel = match$2.rel;
            var onClick = match$2.onClick;
            var title = match$2.title;
            var target = match$2.target;
            var href = match$2.href;
            if (target === "_self") {
              return [
                      "a",
                      makeProps(undefined, undefined, undefined, undefined, undefined, dataAnchor, undefined, undefined, href, undefined, undefined, undefined, undefined, onClick, undefined, rel, undefined, target, title, undefined, undefined, accessibility)
                    ];
            }
            if (target === "_blank") {
              var rel$1 = Core__Option.getOr(Core__Option.map(rel, (function (rel) {
                          return Type_Common.Rel.add(rel, "noopener");
                        })), Type_Common.Rel.make("noopener"));
              return [
                      "a",
                      makeProps(undefined, undefined, undefined, undefined, undefined, dataAnchor, undefined, undefined, href, undefined, undefined, undefined, undefined, onClick, undefined, Caml_option.some(rel$1), undefined, target, title, undefined, undefined, accessibility)
                    ];
            }
            break;
        case "Nav" :
            var ariaLabel = kind._0;
            var accessibility$2 = Core__Option.mapOr(accessibility, Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, ariaLabel, undefined, undefined, undefined, undefined, undefined, "navigation", undefined), (function (__x) {
                    return Particle_Accessibility.set(undefined, undefined, undefined, undefined, undefined, undefined, undefined, ariaLabel, undefined, undefined, undefined, undefined, undefined, undefined, "navigation", undefined, __x);
                  }));
            return [
                    "nav",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility$2)
                  ];
        case "Option" :
            return [
                    "option",
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, kind._0, accessibility)
                  ];
        case "Select" :
            var match$3 = kind._0;
            return [
                    "select",
                    makeProps(undefined, match$3.autoComplete, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, match$3.name, match$3.onChange, undefined, undefined, undefined, undefined, undefined, match$3.title, undefined, match$3.value, accessibility)
                  ];
        case "Text" :
            return [
                    kind._0,
                    makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
                  ];
        
      }
    }
  }
  return [
          "div",
          makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, accessibility)
        ];
}

function getGapRules(display, gap) {
  var zipWithIteratee = function (display, gap) {
    if (display === "grid") {
      return Atom_Box_Type.getGridGapRules(gap);
    } else {
      return Atom_Box_Type.getFlexGapRules(gap);
    }
  };
  return Core__Option.getOr(Core__Option.map(gap, (function (gap) {
                    return Particle_Screen_Css.toRules(undefined, undefined, (function (array) {
                                  return array;
                                }), Particle_Screen_Ds.zipWith(zipWithIteratee, display, gap));
                  })), []);
}

function getInnerSpaceRules(direction, innerSpace) {
  var zipWithIteratee = function (direction, innerSpace) {
    if (direction === "unset" || direction === "rowReverse" || direction === "row" || direction === "inherit_" || direction === "initial") {
      return Particle_Spacer_Ds.Outside.make("None", "Zero", "None", innerSpace);
    } else {
      return Particle_Spacer_Ds.Outside.make("None", innerSpace, "None", "Zero");
    }
  };
  var innerSpaceScreen = innerSpace !== undefined && direction !== undefined ? Particle_Screen_Ds.zipWith(zipWithIteratee, Caml_option.valFromOption(direction), Caml_option.valFromOption(innerSpace)) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone());
  var particleScreenToCssJsRulesTransformer = function (spacer) {
    var rules = Particle_Spacer_Css.toCss(spacer);
    return [CssJs.children([CssJs.selector(":not(:last-child)", rules)])];
  };
  return Particle_Screen_Css.toRules(undefined, undefined, particleScreenToCssJsRulesTransformer, innerSpaceScreen);
}

function makeChildClass(childRules) {
  return Core__Option.map(Core__Option.map(childRules, (function (rules) {
                    return [CssJs.selector("> *", rules)];
                  })), CssJs.style);
}

function make(param) {
  var position = param.position;
  var mediaQueryKind = param.mediaQueryKind;
  var kind = param.kind;
  var direction = param.direction;
  var kindRules = Core__Option.map(kind, getClassByKind);
  var display = Core__Option.getOr(param.display, Atom_Box_Type.defaultDisplay);
  var children = Core__Option.getOr(param.children, null);
  var baseRules = Atom_Box_Type.getBaseRules(param.alignItems, param.alignSelf, undefined, param.area, param.autoSize, param.background, param.border, param.deviceRules, direction, Caml_option.some(display), param.displayOrder, param.height, param.justifyContent, kindRules, param.maxHeight, param.maxWidth, param.minHeight, param.spacer, param.width, param.wrap);
  var innerSpaceRules = getInnerSpaceRules(direction, param.innerSpace);
  var gapRules = getGapRules(display, param.gap);
  var posRules = Util_Array.catOption([Core__Option.map(position, Atom_Box_Type.getPositionRules)]);
  var boxCoordonateRules = Util_Array.catOption([Core__Option.map(position, Atom_Box_Type.getBoxCoordonateRules)]);
  var baseClass = Cn.make(baseRules.concat([innerSpaceRules], [gapRules], posRules, boxCoordonateRules).map(CssJs.style));
  var childClass = makeChildClass(param.childRules);
  var animationClass = Core__Option.map(Core__Option.map(param.animation, Particle_Animation_Css.toCss), CssJs.style);
  var className = Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.map(param.rules, (function (__x) {
                      return Particle_Screen_Css.toRules(undefined, mediaQueryKind, (function (r) {
                                    return r;
                                  }), __x);
                    })), (function (__x) {
                  return Core__Array.make(1, __x);
                })), (function (__x) {
              return __x.map(CssJs.style);
            })), CssJs.merge);
  var allClassNames = Util_Array.catOption([
        baseClass,
        childClass,
        animationClass,
        className
      ]);
  var match = Core__Option.map(param.ref_, (function (prim) {
          return prim;
        }));
  var match$1 = Core__Option.map(param.cbRef, (function (prim) {
          return prim;
        }));
  var ensuredRef;
  if (match !== undefined) {
    var ref = Caml_option.valFromOption(match);
    if (match$1 !== undefined) {
      console.warn("Warning: both ref_ and cbRef are provided, cbRef will be ignored");
      ensuredRef = Caml_option.some(ref);
    } else {
      ensuredRef = Caml_option.some(ref);
    }
  } else {
    ensuredRef = match$1 !== undefined ? Caml_option.some(Caml_option.valFromOption(match$1)) : undefined;
  }
  var match$2 = getTagAndProps(param.id, ensuredRef, CssJs.merge(allClassNames), param.accessibility, param.onFocus, param.onBlur, param.onMouseEnter, param.onMouseLeave, param.dataAnchor, param.identifier, kind);
  return React.createElement(match$2[0], match$2[1], children);
}

export {
  textSchema ,
  getClassByKind ,
  getTagAndProps ,
  getInnerSpaceRules ,
  make ,
}
/* textSchema Not a pure module */
