// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Type_GuideTv from "../../../../type/Type_GuideTv.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Layout_Guide_Column from "../Layout_Guide_Column.res.mjs";
import * as Particle_Accessibility from "../../../particle/Particle_Accessibility.res.mjs";

function getTeasersByDay(teasers, locale, convertDateToTimezone) {
  var dayPeriod;
  switch (locale) {
    case "De" :
        dayPeriod = Type_GuideTv.deDayPeriod;
        break;
    default:
      dayPeriod = Type_GuideTv.frDayPeriod;
  }
  var morningTeasers = [];
  var daytimeTeasers = [];
  var eveningTeasers = [];
  Type_GuideTv.sortTeaserByUpcomingDate(teasers).forEach(function (teaser) {
        var match = teaser.availability;
        if (match === undefined) {
          return ;
        }
        var period = Type_GuideTv.compareDayPeriod(new Date(convertDateToTimezone(match.upcomingDate)), dayPeriod);
        switch (period) {
          case "Morning" :
              morningTeasers.push(teaser);
              return ;
          case "DayTime" :
              daytimeTeasers.push(teaser);
              return ;
          case "Evening" :
              eveningTeasers.push(teaser);
              return ;
          
        }
      });
  return {
          morning: morningTeasers,
          dayTime: daytimeTeasers,
          evening: eveningTeasers
        };
}

function Layout_Guide_Tv_Columns(props) {
  var pageKind = props.pageKind;
  var match = Context.I18n.use();
  var match$1 = match.labels.guide.tv;
  var teasersDay = getTeasersByDay(props.teasers, match.locale, props.convertDateToTimezone);
  return JsxRuntime.jsxs(Atom_Row.make, {
              accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1),
              children: [
                JsxRuntime.jsx(Layout_Guide_Column.make, {
                      title: match$1.morning,
                      teasers: Util_Array.NonEmpty.makeFromArray(teasersDay.morning),
                      pageKind: pageKind
                    }),
                JsxRuntime.jsx(Layout_Guide_Column.make, {
                      title: match$1.daytime,
                      teasers: Util_Array.NonEmpty.makeFromArray(teasersDay.dayTime),
                      pageKind: pageKind
                    }),
                JsxRuntime.jsx(Layout_Guide_Column.make, {
                      title: match$1.evening,
                      teasers: Util_Array.NonEmpty.makeFromArray(teasersDay.evening),
                      pageKind: pageKind
                    })
              ],
              ref_: props.layoutGuideColumnsRef
            });
}

var make = Layout_Guide_Tv_Columns;

export {
  getTeasersByDay ,
  make ,
}
/* Context Not a pure module */
