// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

var program_blockSpace = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("M")));

var program_innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var program_titleStyle = Atom_Text_Ds.Partners.program;

var program = {
  blockSpace: program_blockSpace,
  innerSpace: program_innerSpace,
  titleStyle: program_titleStyle
};

var rc_innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var rc_titleStyle = Atom_Text_Ds.Partners.rc;

var rc = {
  blockSpace: undefined,
  innerSpace: rc_innerSpace,
  titleStyle: rc_titleStyle
};

function view(param) {
  return {
          blockSpace: param.blockSpace,
          innerSpace: param.innerSpace,
          titleStyle: param.titleStyle
        };
}

var Molecule_Partners_Ds = {
  program: program,
  rc: rc,
  view: view
};

function make(param) {
  var partners = param.partners;
  var match = Context.I18n.use();
  var match$1 = match.labels.program;
  var match$2 = view(param.variant);
  if (partners !== undefined && partners.length !== 0) {
    return JsxRuntime.jsxs(Atom_Column.make, {
                blockSpace: match$2.blockSpace,
                children: [
                  JsxRuntime.jsx(Atom_Text.make, {
                        children: Caml_option.some(match$1.in_partnership_with),
                        style: match$2.titleStyle
                      }),
                  JsxRuntime.jsx(Atom_Row.make, {
                        children: partners.map(function (teaser) {
                              var imageSrc = teaser.imageSrc;
                              if (imageSrc !== undefined) {
                                return JsxRuntime.jsx(Atom_Cell.make, {
                                            kind: "ItemList",
                                            children: JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                                                  children: JsxRuntime.jsx(Atom_Image.make, {
                                                        alt: teaser.title,
                                                        src: imageSrc,
                                                        style: "Partner"
                                                      }),
                                                  href: teaser.url,
                                                  style: Atom_Cta_Ds.partner,
                                                  target: "_blank"
                                                })
                                          });
                              } else {
                                return null;
                              }
                            }),
                        kind: "List"
                      })
                ],
                innerSpace: Caml_option.some(match$2.innerSpace)
              });
  } else {
    return null;
  }
}

export {
  Molecule_Partners_Ds ,
  make ,
}
/* program Not a pure module */
