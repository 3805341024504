// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Atom_Label from "../../../atom/Atom_Label.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";

function Template_ProgramContent_FirstBroadcast(props) {
  var value = props.value;
  var programKind = props.programKind;
  var match = Context.I18n.use();
  var labels = match.labels;
  var formaters = match.formaters;
  var ffImproveComWithUser = Context.Config.getFeatureFlippingStatus("ImproveComWithUser");
  var match$1 = labels.common;
  if (programKind === undefined) {
    return null;
  }
  if (programKind === "MAGAZINE") {
    if (ffImproveComWithUser === "On") {
      return null;
    }
    if (value === undefined) {
      return null;
    }
    var match$2 = labels.program;
    var dateLabel = formaters.date(new Date(Type_String.Program.FirstBroadcastDate.toString(value)));
    return JsxRuntime.jsx(Atom_Cell.make, {
                blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"), undefined, Particle_Spacer_Ds.makeTop("M"))),
                children: JsxRuntime.jsx(Atom_Label.make, {
                      text: match$2.showOf + " " + dateLabel,
                      style: Caml_option.some(Atom_Label.firstBroadcastDate)
                    })
              });
  }
  if (programKind !== "PRESSE") {
    return null;
  }
  if (value === undefined) {
    return null;
  }
  var match$3 = labels.program;
  var dateLabel$1 = formaters.dateWithFormat(new Date(Type_String.Program.FirstBroadcastDate.toString(value)), match$3.premiere_date_format);
  return JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"), undefined, Particle_Spacer_Ds.makeTop("M"))),
              children: JsxRuntime.jsx(Atom_Label.make, {
                    text: match$1.firstBroadcast + " " + dateLabel$1,
                    style: Caml_option.some(Atom_Label.presseFirstBroadcastDate(ffImproveComWithUser))
                  })
            });
}

var make = Template_ProgramContent_FirstBroadcast;

export {
  make ,
}
/* Context Not a pure module */
