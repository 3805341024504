// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Css_font from "../particle/Particle_Css_font.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";

function getTruncateLineRules(nbLine) {
  if (nbLine !== undefined) {
    return [
            CssJs.unsafe("WebkitBoxOrient", "vertical"),
            CssJs.overflow(CssJs.hidden),
            CssJs.unsafe("WebkitLineClamp", String(nbLine))
          ];
  } else {
    return [];
  }
}

function toCss(param) {
  var whiteSpace = param.whiteSpace;
  var alignment = param.alignment;
  var hover = param.hover;
  var weight = param.weight;
  var border = param.border;
  var sizeRules = Particle_Screen_Ds.map(param.size, (function (s) {
          return Util_Array.pure(Particle_Css_font.size(s));
        }));
  var truncateLineRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Core__Option.mapOr(param.truncateLine, [], (function (extra) {
              return Particle_Screen_Css.toRules(undefined, undefined, getTruncateLineRules, extra);
            })));
  var tmp;
  if (whiteSpace !== undefined) {
    switch (whiteSpace) {
      case "NoWrap" :
          tmp = CssJs.whiteSpace("nowrap");
          break;
      case "PreWrap" :
          tmp = CssJs.whiteSpace("preWrap");
          break;
      case "PreLine" :
          tmp = CssJs.whiteSpace("preLine");
          break;
      
    }
  } else {
    tmp = CssJs.whiteSpace("normal");
  }
  var wrapRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [tmp]);
  var weightRules = weight !== undefined ? Particle_Screen_Ds.map(Caml_option.valFromOption(weight), (function (w) {
            return [Particle_Css_font.weight(w)];
          })) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Array.catOption([
            Core__Option.map(param.color, (function (color) {
                    return CssJs.color(Particle_Color.Css.color(color));
                  })),
            Caml_option.some(Particle_Css_font.family(param.family)),
            Caml_option.some(Particle_Css_font.line_height(param.lineHeight)),
            Core__Option.map(param.decoration, Particle_Css.text_decoration),
            Core__Option.map(param.style, Particle_Css.text_style),
            Caml_option.some(CssJs.hover(Particle_Css.text_hover(hover))),
            Caml_option.some(CssJs.focus(Particle_Css.text_hover(hover))),
            Caml_option.some(CssJs.marginBottom(CssJs.px(0)))
          ]));
  var alignmentRules = alignment !== undefined ? Particle_Screen_Ds.map(Caml_option.valFromOption(alignment), (function (align) {
            return Util_Array.pure(Particle_Css.text_align(align));
          })) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  var borderRules = border !== undefined ? Particle_Screen_Ds.map(Caml_option.valFromOption(border), Particle_Border_Css.toCss) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  return Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(rules, weightRules), sizeRules), alignmentRules), truncateLineRules), wrapRules), borderRules);
}

export {
  getTruncateLineRules ,
  toCss ,
}
/* CssJs Not a pure module */
