// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Organism_HeaderLabels_De from "../design-system/organism/header/label/Organism_HeaderLabels_De.res.mjs";
import * as Organism_HeaderLabels_En from "../design-system/organism/header/label/Organism_HeaderLabels_En.res.mjs";
import * as Organism_HeaderLabels_Es from "../design-system/organism/header/label/Organism_HeaderLabels_Es.res.mjs";
import * as Organism_HeaderLabels_Fr from "../design-system/organism/header/label/Organism_HeaderLabels_Fr.res.mjs";
import * as Organism_HeaderLabels_It from "../design-system/organism/header/label/Organism_HeaderLabels_It.res.mjs";
import * as Organism_HeaderLabels_Pl from "../design-system/organism/header/label/Organism_HeaderLabels_Pl.res.mjs";

function toDsNavigation(menuItems, param) {
  var match = Context.Config.use();
  var baseUrl = match.baseUrl;
  var match$1 = Context.I18n.use();
  var locale = match$1.locale;
  var data = React.useMemo((function () {
          switch (locale) {
            case "Fr" :
                return Organism_HeaderLabels_Fr.default(baseUrl);
            case "De" :
                return Organism_HeaderLabels_De.default(baseUrl);
            case "Pl" :
                return Organism_HeaderLabels_Pl.default(baseUrl);
            case "It" :
                return Organism_HeaderLabels_It.default(baseUrl);
            case "En" :
                return Organism_HeaderLabels_En.default(baseUrl);
            case "Es" :
                return Organism_HeaderLabels_Es.default(baseUrl);
            
          }
        }), [
        baseUrl,
        locale
      ]);
  if (menuItems === undefined) {
    return data;
  }
  var init = data.MenuNavigation;
  return {
          DesktopNavigation: data.DesktopNavigation,
          MenuNavigation: {
            mobileLinks: init.mobileLinks,
            categories: menuItems.main,
            otherLinks: menuItems.secondary
          },
          LogoNavigation: data.LogoNavigation,
          Concert: data.Concert
        };
}

export {
  toDsNavigation ,
}
/* react Not a pure module */
