// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Particle_Border_Ds from "../../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function make(variant) {
  if (variant === "guidePresse") {
    return {
            background: Particle_Background_Ds.background,
            border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Presse.textField)),
            borderFocused: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Presse.textField)),
            labelStyle: Atom_Text_Ds.Presse.dropdown,
            textStyle: Atom_Text_Ds.Presse.dropdown,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else if (variant === "select") {
    return {
            autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
            background: Particle_Background_Ds.background,
            textStyle: Atom_Text_Ds.Dropdown.current,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else if (variant === "myProfileNewDesign") {
    return {
            background: Particle_Background_Ds.background,
            border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.userInactive)),
            borderFocused: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.userActive)),
            contentSpacer: "XS",
            innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")),
            labelStyle: Atom_Text_Ds.User.label,
            textStyle: Atom_Text_Ds.Dropdown.current,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "column")),
            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
          };
  } else if (variant === "settings") {
    return {
            background: Particle_Background_Ds.background,
            border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Settings.dropdown)),
            borderFocused: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Settings.dropdownItemAccessibility)),
            height: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                      NAME: "px",
                      VAL: 60
                    })),
            labelStyle: Atom_Text_Ds.Dropdown.label,
            textStyle: Atom_Text_Ds.Dropdown.current,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else if (variant === "myProfile") {
    return {
            autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
            background: Particle_Background_Ds.background,
            border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.userInactive)),
            borderFocused: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Textfield.userActive)),
            contentSpacer: "XS",
            textStyle: Atom_Text_Ds.Dropdown.current,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "column")),
            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
          };
  } else if (variant === "concertGenre") {
    return {
            background: Particle_Background_Ds.background,
            labelStyle: Atom_Text_Ds.Dropdown.greyLabel,
            textStyle: Atom_Text_Ds.Dropdown.current,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else if (variant === "presse") {
    return {
            autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
            background: Particle_Background_Ds.background,
            border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Presse.textField)),
            borderFocused: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Presse.textField)),
            labelStyle: Atom_Text_Ds.Presse.dropdown,
            textStyle: Atom_Text_Ds.Presse.dropdown,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else if (variant === "filterPresse") {
    return {
            background: Particle_Background_Ds.background,
            labelStyle: Atom_Text_Ds.Dropdown.label,
            textStyle: Atom_Text_Ds.Presse.dropdown,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else {
    return {
            autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
            background: Particle_Background_Ds.dropdownMenu,
            textStyle: Atom_Text_Ds.User.dropdown,
            itemStyle: Atom_Text_Ds.Dropdown.selectItem,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "column")),
            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
          };
  }
}

function makeContainerView(variant) {
  var match = make(variant);
  return {
          autoSize: match.autoSize,
          background: match.background,
          contentSpacer: match.contentSpacer,
          height: match.height,
          innerSpace: match.innerSpace,
          labelStyle: match.labelStyle,
          direction: match.direction,
          width: match.width
        };
}

function makeComponentView(variant) {
  var match = make(variant);
  return {
          border: match.border,
          borderFocused: match.borderFocused,
          textStyle: match.textStyle,
          itemStyle: match.itemStyle
        };
}

var Select = {
  makeContainerView: makeContainerView,
  makeComponentView: makeComponentView
};

function make$1(variant) {
  if (variant === "settings") {
    return {
            autoSize: undefined,
            background: Particle_Background_Ds.background,
            border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Settings.dropdown)),
            height: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                      NAME: "px",
                      VAL: 60
                    })),
            innerSpace: undefined,
            itemCurrentStyle: Atom_Cta_Ds.Setting.dropdown,
            itemStyle: Atom_Cta_Ds.dropdownItem,
            labelStyle: Atom_Text_Ds.Dropdown.label,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  } else {
    return {
            autoSize: undefined,
            background: Particle_Background_Ds.background,
            border: undefined,
            height: undefined,
            innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "None")),
            itemCurrentStyle: Atom_Cta_Ds.dropdown,
            itemStyle: Atom_Cta_Ds.dropdownItem,
            labelStyle: Atom_Text_Ds.Dropdown.label,
            direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row"))
          };
  }
}

function makeContainerView$1(variant) {
  var match = make$1(variant);
  return {
          autoSize: match.autoSize,
          background: match.background,
          contentSpacer: undefined,
          height: match.height,
          innerSpace: match.innerSpace,
          labelStyle: match.labelStyle,
          direction: match.direction
        };
}

function makeComponentView$1(variant) {
  var match = make$1(variant);
  return {
          border: match.border,
          itemCurrentStyle: match.itemCurrentStyle,
          itemStyle: match.itemStyle
        };
}

var List = {
  makeContainerView: makeContainerView$1,
  makeComponentView: makeComponentView$1
};

export {
  Select ,
  List ,
}
/* Atom_Cta_Ds Not a pure module */
