// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M21.1642 2.47241V3.51513H10.8358V2.47241H8.56791V3.51513H3.5V8.59698V10.8649V28.8534H28.5V3.51513H23.4321V2.47241H21.1642ZM23.4321 6.82322V5.78303H26.2321V8.59699H5.7679V5.78303H8.56792V6.82322H10.8358V5.78303H21.1642V6.82322H23.4321ZM5.7679 26.5856H26.2309V10.865H5.7679V26.5856ZM21.0743 15.8365H24.3887V12.5233H21.0743V15.8365ZM16.5866 15.8365H19.8998V12.5233H16.5866V15.8365ZM12.1002 15.8365H15.4133V12.5233H12.1002V15.8365ZM21.0743 20.2709H24.3875V16.9578H21.0743V20.2709ZM16.5866 20.2709H19.8998V16.9578H16.5866V20.2709ZM12.1002 20.2709H15.4133V16.9578H12.1002V20.2709ZM7.61263 20.2709H10.9258V16.9578H7.61263V20.2709ZM16.5866 24.7052H19.8998V21.392H16.5866V24.7052ZM12.1002 24.7052H15.4133V21.392H12.1002V24.7052ZM7.61263 24.7052H10.9258V21.392H7.61263V24.7052Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
