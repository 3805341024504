import { useEffect, useState } from 'react';

export const useCheckLocalStorage = () => {
    // test if localStorage throw an error, if it's the case we overide the localStorage with a fake one
    useEffect(() => {
        try {
            global.localStorage.getItem('localStorage');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error && error.name && error.name === 'NS_ERROR_STORAGE_CONSTRAINT') {
                Storage.prototype.setItem = () => {
                    return;
                };
                Storage.prototype.getItem = () => {
                    return null;
                };
                Storage.prototype.removeItem = () => {
                    return;
                };
                Storage.prototype.clear = () => {
                    return;
                };
            }
            console.error(error);
        }
    }, []);
};

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
    const [storedValue, setStoredValue] = useState(() => initialValue);

    useEffect(() => {
        try {
            const item = global.window.localStorage.getItem(key);
            const value = item ? JSON.parse(item) : initialValue;
            setStoredValue(value)
        } catch (error) {
            // If user is in private mode or has storage restriction
            // localStorage can throw. JSON.parse and JSON.stringify
            console.log(error); // eslint-disable-line no-console
        }
    }, [initialValue, key])

    const setValue = (value: T) => {
        try {
            setStoredValue(value);
            global.window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            // If user is in private mode or has storage restriction
            // localStorage can throw. Also JSON.stringify can throw.
            console.log(error); // eslint-disable-line no-console
        }
    };

    return [storedValue, setValue];
};
