// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      d: "M18.6703 5.27637C20.7082 7.01878 22 9.60852 22 12.5C22 17.7467 17.7467 22 12.5 22C7.25329 22 3 17.7467 3 12.5C3 9.60852 4.29176 7.01878 6.32967 5.27637",
                      fill: "none",
                      stroke: "white",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "1.85581"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M12.5 11.4443V2.99989",
                      fill: "none",
                      stroke: "white",
                      strokeLinecap: "round",
                      strokeWidth: "1.85581"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
