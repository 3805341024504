// Generated by ReScript, PLEASE EDIT WITH CARE


function inputTypeToString(inputType) {
  if (typeof inputType === "object") {
    return "text";
  } else if (inputType === "password") {
    return "password";
  } else if (inputType === "hidden") {
    return "hidden";
  } else if (inputType === "text") {
    return "text";
  } else if (inputType === "search") {
    return "search";
  } else if (inputType === "email") {
    return "email";
  } else if (inputType === "datetime-local") {
    return "datetime-local";
  } else if (inputType === "tel") {
    return "tel";
  } else if (inputType === "url") {
    return "url";
  } else if (inputType === "number") {
    return "number";
  } else {
    return "date";
  }
}

export {
  inputTypeToString ,
}
/* No side effect */
