// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Particle_Color from "./Particle_Color.res.mjs";
import * as Particle_zIndex from "./Particle_zIndex.res.mjs";
import * as Particle_Screen_Ds from "./Particle_Screen_Ds.res.mjs";

var commonClass = [
  CssJs.unsafe("content", ""),
  CssJs.position(CssJs.absolute),
  CssJs.bottom(CssJs.px(0)),
  CssJs.left(CssJs.px(0)),
  CssJs.right(CssJs.px(0))
];

function makeZindexRule(variant) {
  if (variant !== undefined) {
    return Particle_zIndex.Particle_zIndex_Css.toCss({
                TAG: "Exact",
                _0: variant
              });
  } else {
    return Particle_zIndex.Particle_zIndex_Css.toCss({
                TAG: "Exact",
                _0: "Gradient"
              });
  }
}

function pictureGradientLeftRules(variant) {
  return [CssJs.before([
                  CssJs.width(CssJs.pct(30)),
                  CssJs.top(CssJs.px(0)),
                  CssJs.background({
                        NAME: "linearGradient",
                        VAL: [
                          CssJs.deg(90),
                          [
                            [
                              CssJs.pct(10),
                              Particle_Color.Css.color("Background")
                            ],
                            [
                              CssJs.pct(100),
                              Particle_Color.Css.color("Transparent")
                            ]
                          ]
                        ]
                      }),
                  makeZindexRule(variant)
                ].concat(commonClass))];
}

function toCss(view) {
  if (typeof view !== "object") {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.after([
                      CssJs.width(Css_Js_Core.Calc.$plus(CssJs.pct(100), CssJs.pxFloat(0.25))),
                      CssJs.background({
                            NAME: "linearGradient",
                            VAL: [
                              CssJs.deg(90),
                              [
                                [
                                  CssJs.pct(0),
                                  Particle_Color.Css.color("Background")
                                ],
                                [
                                  CssJs.pct(100),
                                  Particle_Color.Css.color("Transparent")
                                ]
                              ]
                            ]
                          }),
                      CssJs.unsafe("content", ""),
                      CssJs.position(CssJs.absolute),
                      CssJs.top(CssJs.px(0)),
                      CssJs.bottom(CssJs.px(0)),
                      CssJs.left(CssJs.px(0))
                    ])]);
  }
  var zIndex = view._0;
  return Particle_Screen_Ds.make(undefined, undefined, pictureGradientLeftRules(zIndex), undefined, undefined, [CssJs.after([
                      CssJs.height(Css_Js_Core.Calc.$plus(CssJs.pct(30), CssJs.pxFloat(0.25))),
                      CssJs.background({
                            NAME: "linearGradient",
                            VAL: [
                              CssJs.deg(0),
                              [
                                [
                                  CssJs.pct(2),
                                  Particle_Color.Css.color("Background")
                                ],
                                [
                                  CssJs.pct(100),
                                  Particle_Color.Css.color("Transparent")
                                ]
                              ]
                            ]
                          }),
                      makeZindexRule(zIndex)
                    ].concat(commonClass))]);
}

export {
  commonClass ,
  makeZindexRule ,
  pictureGradientLeftRules ,
  toCss ,
}
/* commonClass Not a pure module */
