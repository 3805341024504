// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";

function Organism_LegacySection(props) {
  return JsxRuntime.jsx(Atom_Cell.make, {
              justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"))),
              kind: {
                TAG: "Text",
                _0: "p"
              },
              children: JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(props.label),
                    kind: "span",
                    style: Atom_Text_Ds.Footer.make("Desc")
                  }),
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.textAlign(CssJs.center)]))
            });
}

var make = Organism_LegacySection;

export {
  make ,
}
/* CssJs Not a pure module */
