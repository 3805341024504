import { type ConfigProviderApi, type SubscribeToNewsletterResult } from '@artegeie/design-system/type';
import { fetchJson } from '@replay/fetch';
import { type Translate } from '@replay/i18n/types/translations';
import { isError } from '@replay/types/Result';
import { env } from '@/env';

const newsletterRegisterUrl = env.NEXT_PUBLIC_SSO_NEWSLETTER_SUBSCRIBE_URL as string;

type ApiResponse = {
    msg: 'OK' | 'OK - second';
};

export const subscribeToNewsletter: (translate: Translate) => ConfigProviderApi['subscribeToNewsletter'] =
    (translate) =>
    async (email, locale, source = 'replay_footer') => {
        const success = {
            tag: 'Ok',
            value: translate('replay_internal.newsletter.success'),
        } as SubscribeToNewsletterResult;
        const error = {
            tag: 'Error',
            value: translate('replay_internal.newsletter.error'),
        } as SubscribeToNewsletterResult;
        const duplicate = {
            tag: 'Error',
            value: translate('replay_internal.newsletter.duplicate'),
        } as SubscribeToNewsletterResult;
        const requestInit = {
            headers: {
                client: 'web',
                authorization: 'jfUxE7JZHFa9GRn5BuPDYMpLmqNc284h',
                'content-type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                email: email,
                language: locale,
                source: source,
            }),
        };
        const response = await fetchJson<ApiResponse>(newsletterRegisterUrl, {
            withoutXCorrelationHeader: true,
            requestInit,
        });
        if (isError(response)) {
            return error;
        }
        if (response.value.msg === 'OK - second') {
            return duplicate;
        }
        return success;
    };
