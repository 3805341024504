// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M22.7337 7.71435C23.6238 7.71435 24.3453 8.4359 24.3453 9.32604C24.3453 10.2161 23.6238 10.9377 22.7337 10.9377C21.8435 10.9377 21.122 10.2161 21.122 9.32604C21.122 8.4359 21.8435 7.71435 22.7337 7.71435ZM15.9974 20.1046C13.729 20.1046 11.8902 18.2658 11.8902 15.9974C11.8902 13.7291 13.729 11.8903 15.9974 11.8903C18.2657 11.8903 20.1045 13.7291 20.1045 15.9974C20.1045 18.2658 18.2657 20.1046 15.9974 20.1046ZM15.9974 9.27513C12.2862 9.27882 9.27837 12.2861 9.27405 15.9974C9.27405 19.7037 12.2894 22.7207 15.9974 22.7207C19.7053 22.7207 22.7207 19.7042 22.7207 15.9974C22.7164 12.2861 19.7086 9.27882 15.9974 9.27513ZM26.3797 21.2484C26.3797 24.081 24.0836 26.3773 21.2509 26.3776H10.7465C7.91381 26.3788 5.6165 24.0836 5.61509 21.2509V10.7464C5.6139 7.9139 7.90923 5.61675 10.7418 5.61561H10.7465H21.2535C24.0859 5.61706 26.3809 7.91411 26.3797 10.7464V21.2536V21.2484ZM21.2509 3H10.7464C6.47396 3 3 6.47396 3 10.7464V21.2536C3 25.5214 6.47396 28.9948 10.7464 28.9948H21.2535C25.5234 28.9948 29 25.5219 29 21.2515V10.7464C28.9947 6.47396 25.5208 3 21.2509 3Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
