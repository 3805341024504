// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Avatar from "../../../type/Type_Avatar.res.mjs";
import * as Layout_Account from "../../layout/Layout_Account.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Avatar_List from "../../organism/Organism_Avatar_List.res.mjs";

function make(param) {
  var avatarList = Type_Avatar.getAvatarList(param.avatarSsoList);
  return JsxRuntime.jsx(Layout_Account.WithAvatar.make, {
              children: JsxRuntime.jsx(Organism_Avatar_List.make, {
                    avatarList: avatarList
                  })
            });
}

export {
  make ,
}
/* Type_Avatar Not a pure module */
