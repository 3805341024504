// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Util_Nullable from "../../util/Util_Nullable.res.mjs";
import * as Layout_Program from "../layout/Layout_Program.res.mjs";
import * as UseClickTracking from "../../hook/UseClickTracking.res.mjs";
import * as Type_Page_Program from "../../type/Type_Page_Program.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function defaultOnPlayNextChange(param) {
  
}

function ensureOnPlayNextChange(onPlayNextChange) {
  return Util_Nullable.getOrElse(defaultOnPlayNextChange, onPlayNextChange);
}

function defaultOnChapterClick(param, param$1) {
  
}

function ensureOnChapterClick(onChapterClick) {
  return Util_Nullable.getOrElse(defaultOnChapterClick, onChapterClick);
}

function ensureIsPlayNextActivated(bool) {
  if (!(bool == null) && bool) {
    return "On";
  } else {
    return "Off";
  }
}

var Autoplay = {
  defaultOnPlayNextChange: defaultOnPlayNextChange,
  ensureOnPlayNextChange: ensureOnPlayNextChange,
  defaultOnChapterClick: defaultOnChapterClick,
  ensureOnChapterClick: ensureOnChapterClick,
  ensureIsPlayNextActivated: ensureIsPlayNextActivated
};

function make(param) {
  var onTrailerButtonClick = param.onTrailerButtonClick;
  var match = Context.I18n.use();
  var formaters = match.formaters;
  var match$1 = Context.Config.use();
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, "Program", param.domain, match$1.serverTime, param.page);
  var match$2;
  match$2 = param.videoKind === "Program" ? [
      "WATCH_TRAILER",
      Identifiers.Cta.trailerButton
    ] : [
      "WATCH_CONTENT",
      Identifiers.Cta.fullProgramButton
    ];
  var eventName = match$2[0];
  var clickTracking = UseClickTracking.use();
  var onTrailerButtonClick$1 = !(onTrailerButtonClick == null) ? (function () {
        clickTracking({
              eventGroup: "program_actions",
              eventName: eventName
            });
        onTrailerButtonClick();
      }) : undefined;
  var ffImproveComWithUser = Context.Config.getFeatureFlippingStatus("ImproveComWithUser");
  var tmp;
  tmp = ffImproveComWithUser === "On" ? formaters.betweenDatesFormatted : undefined;
  var layout = Type_Page_Program.makeFromPage(match.labels, page, undefined, onTrailerButtonClick$1, param.trailerButtonLabel, Caml_option.some(match$2[1]), formaters.date, tmp, "Program", undefined);
  return JsxRuntime.jsx(Layout_Program.make, {
              isPlayNextActivated: ensureIsPlayNextActivated(param.isPlayNextActivated),
              onPlayNextChange: Util_Nullable.getOrElse(defaultOnPlayNextChange, param.onPlayNextChange),
              onChapterClick: Util_Nullable.getOrElse(defaultOnChapterClick, param.onChapterClick),
              playedProgramId: param.playedProgramId,
              layout: layout,
              player: param.player,
              geoblockingMode: param.geoblockingMode
            });
}

export {
  Autoplay ,
  make ,
}
/* Context Not a pure module */
