// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../CssJs.res.mjs";

var rules = [CssJs.selector(".video-embed,.video-thumbnail", [CssJs.important(CssJs.position("unset"))])];

var Player = {
  rules: rules
};

export {
  Player ,
}
/* rules Not a pure module */
