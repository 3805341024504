'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as User_Provider from "../user/User_Provider.res.mjs";
import * as User_Api__Sso__TvCode from "../user/User_Api/User_Api__Sso__TvCode.res.mjs";

function make() {
  var controller = new AbortController();
  var match = Context.I18n.use();
  var locale = match.locale;
  var match$1 = React.useState(function () {
        return "Loading";
      });
  var setCode = match$1[1];
  var status = User_Provider.Hook.useStatus();
  var match$2 = match.labels.mona.editEmail;
  var userErrorGenericLabel = match$2.errorGeneric;
  React.useEffect((function () {
          setCode(function (param) {
                return "Loading";
              });
          User_Api__Sso__TvCode.get({
                  signal: controller.signal,
                  locale: locale
                }).then(function (resp) {
                if (resp.TAG !== "Ok") {
                  return setCode(function (param) {
                              return {
                                      NAME: "Error",
                                      VAL: userErrorGenericLabel
                                    };
                            });
                }
                var code = resp._0.code;
                setCode(function (param) {
                      return {
                              NAME: "Success",
                              VAL: code
                            };
                    });
              });
        }), [
        status,
        locale
      ]);
  return match$1[0];
}

export {
  make ,
}
/* react Not a pure module */
