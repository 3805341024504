import { FeedbackButton as FeedbackButtonDs } from '@artegeie/design-system/replay';
import { type Locale } from '@replay/i18n/types/locale';
import { useRouter } from 'next/router';
import { type ReactElement } from 'react';
import { env } from '@/env';
import { useAuthStore } from './useRedirect';

export const FeedbackButton = ({ locale }: { locale: Locale }): ReactElement | null => {
    const { asPath } = useRouter();
    const { legacy__saveRedirect } = useAuthStore();
    if (asPath.includes('feedback') || !env.NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM) return null;
    return (
        <FeedbackButtonDs
            href={`/${locale}/feedback`}
            onClick={() => {
                legacy__saveRedirect({
                    shouldVerifyAge: false,
                    returnPage: asPath,
                });
            }}
        />
    );
};
