// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Meta from "./Type_Meta.res.mjs";
import * as Type_Page from "./Type_Page.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Atom_Cta_Ds from "../design-system/atom/Atom_Cta_Ds.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Identifiers from "../selector/Identifiers.res.mjs";
import * as Presse_Page from "../presse/Presse_Page.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function label(zone) {
  return zone.label;
}

function playNext(zone) {
  return zone.playNext;
}

function partner(zone) {
  return zone.partner;
}

function programContent(zone) {
  return zone.programContent;
}

function meta(zone) {
  return zone.meta;
}

function sliders(zone) {
  return zone.sliders;
}

function download(zone) {
  return zone.download;
}

function $$event(zone) {
  return zone.event;
}

function ctas(zone) {
  return zone.ctas;
}

function makeFromPage(labels, page, program, onTrailerButtonClick, trailerButtonLabel, trailerButtonIdentifierOpt, dateFormater, betweenDatesFormattedFormater, pageKind, param) {
  var trailerButtonIdentifier = trailerButtonIdentifierOpt !== undefined ? Caml_option.valFromOption(trailerButtonIdentifierOpt) : Identifiers.Cta.trailerButton;
  var layout = Core__Array.reduce(page.zones, {
        label: undefined,
        playNext: undefined,
        partner: undefined,
        programContent: undefined,
        meta: undefined,
        sliders: undefined,
        download: undefined,
        event: undefined,
        ctas: undefined
      }, (function (layout, zone) {
          var template;
          switch (zone.TAG) {
            case "Main" :
                template = zone._0.template;
                break;
            case "Program" :
                template = zone._0.template;
                break;
            case "Guide" :
                template = zone._0;
                break;
            
          }
          if (typeof template !== "object") {
            return layout;
          }
          var variant = template.NAME;
          if (variant === "Label") {
            if (layout.label !== undefined) {
              return layout;
            } else {
              return {
                      label: template,
                      playNext: layout.playNext,
                      partner: layout.partner,
                      programContent: layout.programContent,
                      meta: layout.meta,
                      sliders: layout.sliders,
                      download: layout.download,
                      event: layout.event,
                      ctas: layout.ctas
                    };
            }
          }
          if (variant === "PlayNextSlider" || variant === "PlayNextTeaser") {
            if (layout.playNext !== undefined) {
              return layout;
            } else {
              return {
                      label: layout.label,
                      playNext: template,
                      partner: layout.partner,
                      programContent: layout.programContent,
                      meta: layout.meta,
                      sliders: layout.sliders,
                      download: layout.download,
                      event: layout.event,
                      ctas: layout.ctas
                    };
            }
          }
          if (variant === "ProgramContent") {
            if (layout.programContent !== undefined) {
              return layout;
            } else {
              return {
                      label: layout.label,
                      playNext: layout.playNext,
                      partner: layout.partner,
                      programContent: template,
                      meta: typeof template === "object" && template.NAME === "ProgramContent" ? Type_Meta.makeProgramMetas(undefined, betweenDatesFormattedFormater, dateFormater, pageKind, labels, template.VAL[0]) : undefined,
                      sliders: layout.sliders,
                      download: layout.download,
                      event: layout.event,
                      ctas: layout.ctas
                    };
            }
          }
          if (variant === "EventZone") {
            if (layout.event !== undefined) {
              return layout;
            } else {
              return {
                      label: layout.label,
                      playNext: layout.playNext,
                      partner: layout.partner,
                      programContent: layout.programContent,
                      meta: layout.meta,
                      sliders: layout.sliders,
                      download: layout.download,
                      event: template,
                      ctas: layout.ctas
                    };
            }
          }
          if (variant !== "Slider") {
            if (variant === "Partner" && template.VAL.data.length !== 0 && layout.partner === undefined) {
              return {
                      label: layout.label,
                      playNext: layout.playNext,
                      partner: template,
                      programContent: layout.programContent,
                      meta: layout.meta,
                      sliders: layout.sliders,
                      download: layout.download,
                      event: layout.event,
                      ctas: layout.ctas
                    };
            } else {
              return layout;
            }
          }
          if (template.VAL.data.length === 0) {
            return layout;
          }
          var sliders = layout.sliders;
          return {
                  label: layout.label,
                  playNext: layout.playNext,
                  partner: layout.partner,
                  programContent: layout.programContent,
                  meta: layout.meta,
                  sliders: sliders !== undefined ? Caml_option.some(Util_Array.NonEmpty.add(Caml_option.valFromOption(sliders), template)) : Caml_option.some(Util_Array.NonEmpty.make(undefined, template)),
                  download: layout.download,
                  event: layout.event,
                  ctas: layout.ctas
                };
        }));
  var program$1 = program !== undefined ? program : Core__Option.map(layout.programContent, (function (programContent) {
            return programContent.VAL[0];
          }));
  var layout$1;
  var exit = 0;
  if (program$1 !== undefined) {
    var match = program$1.program.kind;
    if (match === "TRAILER") {
      layout$1 = layout;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    layout$1 = onTrailerButtonClick !== undefined && trailerButtonLabel !== undefined ? ({
          label: layout.label,
          playNext: layout.playNext,
          partner: layout.partner,
          programContent: layout.programContent,
          meta: layout.meta,
          sliders: layout.sliders,
          download: layout.download,
          event: layout.event,
          ctas: Util_Array.NonEmpty.makeFromArray([{
                  label: trailerButtonLabel,
                  kind: {
                    TAG: "Button",
                    _0: onTrailerButtonClick,
                    _1: Atom_Cta_Ds.Trailer.enabledProgram
                  },
                  identifier: trailerButtonIdentifier
                }])
        }) : layout;
  }
  if (program$1 !== undefined) {
    return {
            label: layout$1.label,
            playNext: layout$1.playNext,
            partner: layout$1.partner,
            programContent: {
              NAME: "ProgramContent",
              VAL: [
                program$1,
                page.parent,
                "Auto"
              ]
            },
            meta: layout$1.meta,
            sliders: layout$1.sliders,
            download: layout$1.download,
            event: layout$1.event,
            ctas: layout$1.ctas
          };
  } else {
    return layout$1;
  }
}

function makeFromPresse(labels, pressePage, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference) {
  var makePlayNext = function (recommendations) {
    return Belt_Option.flatMap((recommendations == null) ? undefined : Caml_option.some(recommendations), (function (recommendations) {
                  var match = recommendations.teasers;
                  if (match.length !== 0) {
                    return {
                            NAME: "PlayNextSlider",
                            VAL: Presse_Page.recommandationsToDsZone(recommendations, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, labels.common.firstBroadcast, "Program")
                          };
                  }
                  
                }));
  };
  var teaser = Presse_Page.toDsTeaser(pressePage.zones.program, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, labels.common.firstBroadcast, "Program");
  var programContent = {
    NAME: "ProgramContent",
    VAL: [
      teaser,
      undefined,
      "AlwaysExpanded"
    ]
  };
  var programMetas = Type_Meta.makeProgramMetas("Presse", undefined, nextBroadcastDateFormater, "Program", labels, teaser);
  var makePresseMetas = function (program) {
    return Presse_Page.makePresseMetas(labels.common.press, program);
  };
  var metas = programMetas.concat(makePresseMetas(pressePage.zones.program));
  return {
          label: undefined,
          playNext: makePlayNext(pressePage.zones.recommendations),
          partner: undefined,
          programContent: programContent,
          meta: metas,
          sliders: undefined,
          download: Core__Option.map(pressePage.zones.pictures, Type_Page.Presse.makeSliderDownload),
          event: undefined,
          ctas: Presse_Page.programToCtas(pressePage.zones.program)
        };
}

export {
  label ,
  playNext ,
  partner ,
  programContent ,
  meta ,
  sliders ,
  download ,
  $$event ,
  ctas ,
  makeFromPage ,
  makeFromPresse ,
}
/* Type_Meta Not a pure module */
