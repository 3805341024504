// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Type_String from "./Type_String.res.mjs";
import * as Binding_Date from "../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function watching_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pct",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.floatToJson, extra);
                        })(v.pct)
                  ],
                  [
                    "time",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.intToJson, extra);
                        })(v.time)
                  ]
                ]));
}

function watching_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pct = (function (extra) {
        return Spice.optionFromJson(Spice.floatFromJson, extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "pct"), null));
  if (pct.TAG === "Ok") {
    var time = (function (extra) {
          return Spice.optionFromJson(Spice.intFromJson, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "time"), null));
    if (time.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pct: pct._0,
                time: time._0
              }
            };
    }
    var e = time._0;
    return {
            TAG: "Error",
            _0: {
              path: ".time" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pct._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pct" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  if (typeof v !== "object") {
    if (v === "NotWatched") {
      return ["NotWatched"];
    } else {
      return ["Watched"];
    }
  } else {
    return [
            "Watching",
            watching_encode(v._0)
          ];
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "NotWatched" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "NotWatched"
                  };
          }
      case "Watched" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Watched"
                  };
          }
      case "Watching" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = watching_decode(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Watching",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function persistenceState_encode(v) {
  if (v.TAG === "Remote") {
    return [
            "Remote",
            Binding_Date.t_encode(v._0)
          ];
  } else {
    return [
            "Local",
            Binding_Date.t_encode(v._0)
          ];
  }
}

function persistenceState_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Local" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Binding_Date.t_decode(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Local",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Remote" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = Binding_Date.t_decode(Belt_Array.getExn(v, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Remote",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function userProgression_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeProgression",
                    false,
                    t_encode(v.timeProgression)
                  ],
                  [
                    "persistence",
                    false,
                    persistenceState_encode(v.persistence)
                  ]
                ]));
}

function userProgression_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeProgression = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeProgression"), null));
  if (timeProgression.TAG === "Ok") {
    var persistence = persistenceState_decode(Belt_Option.getWithDefault(Js_dict.get(v, "persistence"), null));
    if (persistence.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                timeProgression: timeProgression._0,
                persistence: persistence._0
              }
            };
    }
    var e = persistence._0;
    return {
            TAG: "Error",
            _0: {
              path: ".persistence" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = timeProgression._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeProgression" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function clubProgression_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "programId",
                    false,
                    Spice.stringToJson(v.programId)
                  ],
                  [
                    "timecode",
                    false,
                    Spice.intToJson(v.timecode)
                  ],
                  [
                    "progress",
                    false,
                    Spice.floatToJson(v.progress)
                  ],
                  [
                    "updatedAt",
                    false,
                    Spice.stringToJson(v.updatedAt)
                  ]
                ]));
}

function clubProgression_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var programId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "programId"), null));
  if (programId.TAG === "Ok") {
    var timecode = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "timecode"), null));
    if (timecode.TAG === "Ok") {
      var progress = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "progress"), null));
      if (progress.TAG === "Ok") {
        var updatedAt = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    programId: programId._0,
                    timecode: timecode._0,
                    progress: progress._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = progress._0;
      return {
              TAG: "Error",
              _0: {
                path: ".progress" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = timecode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".timecode" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = programId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".programId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function makeFromPct(time, f) {
  if (f <= 0) {
    return "NotWatched";
  } else if (f > 0 && f < 1) {
    return {
            TAG: "Watching",
            _0: {
              pct: f * 100,
              time: time
            }
          };
  } else if (f >= 1) {
    return "Watched";
  } else {
    return "NotWatched";
  }
}

function makeFromTimeAndDuration(timeInSec, durationInSec) {
  if (timeInSec === durationInSec) {
    return "Watched";
  } else if (timeInSec <= 0) {
    return "NotWatched";
  } else if (timeInSec > durationInSec || durationInSec <= 0) {
    return ;
  } else {
    return {
            TAG: "Watching",
            _0: {
              pct: timeInSec / durationInSec * 100,
              time: timeInSec
            }
          };
  }
}

function getPct(t) {
  if (typeof t !== "object") {
    if (t === "NotWatched") {
      return ;
    } else {
      return 100;
    }
  } else {
    return t._0.pct;
  }
}

function getTime(t) {
  if (typeof t !== "object") {
    return ;
  } else {
    return t._0.time;
  }
}

function getUpdatedAt(state) {
  return state._0;
}

function toUserProgression(progression) {
  return {
          timeProgression: makeFromPct(progression.timecode, progression.progress),
          persistence: {
            TAG: "Remote",
            _0: new Date(progression.updatedAt)
          }
        };
}

function toClubProgression(id, progression) {
  var match = progression.timeProgression;
  if (typeof match !== "object") {
    if (match === "NotWatched") {
      return {
              programId: Type_String.Teaser.DsId.toString(id),
              timecode: 0,
              progress: 0,
              updatedAt: progression.persistence._0.toString()
            };
    } else {
      return {
              programId: Type_String.Teaser.DsId.toString(id),
              timecode: 1,
              progress: 100,
              updatedAt: progression.persistence._0.toString()
            };
    }
  }
  var match$1 = match._0;
  return {
          programId: Type_String.Teaser.DsId.toString(id),
          timecode: Core__Option.getOr(match$1.time, 0),
          progress: Core__Option.getOr(match$1.pct, 0),
          updatedAt: progression.persistence._0.toString()
        };
}

var noProgression = "NotWatched";

export {
  watching_encode ,
  watching_decode ,
  t_encode ,
  t_decode ,
  persistenceState_encode ,
  persistenceState_decode ,
  userProgression_encode ,
  userProgression_decode ,
  clubProgression_encode ,
  clubProgression_decode ,
  makeFromPct ,
  noProgression ,
  makeFromTimeAndDuration ,
  getPct ,
  getTime ,
  getUpdatedAt ,
  toUserProgression ,
  toClubProgression ,
}
/* Binding_Date Not a pure module */
