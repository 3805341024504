// Generated by ReScript, PLEASE EDIT WITH CARE


var Var = {
  prefix: "--ads-"
};

export {
  Var ,
}
/* No side effect */
