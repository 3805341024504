// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Molecule_BurgerMenuIcon_Ds from "./Molecule_BurgerMenuIcon_Ds.res.mjs";
import * as Molecule_BurgerMenuIcon_Css from "./Molecule_BurgerMenuIcon_Css.res.mjs";
import * as Organism_HeaderAccessibility from "../organism/header/Organism_HeaderAccessibility.res.mjs";

function Molecule_BurgerMenuIcon(props) {
  var state = props.state;
  var view = Molecule_BurgerMenuIcon_Ds.view(props.style);
  var match = Molecule_BurgerMenuIcon_Css.toCss(view);
  var tmp;
  tmp = state === "On" ? match.transform : [];
  var transformClass = (function (__x) {
        return CssJs.style(__x);
      })(tmp);
  var className = CssJs.merge([
        CssJs.style(match.line),
        transformClass
      ]);
  var background;
  background = state === "On" ? view.background : Particle_Background_Ds.transparent;
  var accessibility = Organism_HeaderAccessibility.accessibility(state);
  return JsxRuntime.jsxs(Atom_Column.make, {
              accessibility: accessibility,
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "stretch")),
              background: Caml_option.some(background),
              blockSpace: Caml_option.some(view.blockSpace),
              children: [
                JsxRuntime.jsx("span", {
                      className: className
                    }),
                JsxRuntime.jsx("span", {
                      className: className
                    }),
                JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                      children: "Menu"
                    })
              ],
              display: props.display,
              height: Caml_option.some(view.height),
              kind: {
                TAG: "Button",
                _0: "button",
                _1: props.toggle,
                _2: undefined
              },
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, match.wrapper)),
              width: Caml_option.some(view.width)
            });
}

var make = Molecule_BurgerMenuIcon;

export {
  make ,
}
/* CssJs Not a pure module */
