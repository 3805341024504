// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Slider_Teaser from "../../organism/slider/teaser/Organism_Slider_Teaser.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function Template_PlayNext$SimplePlayNext(props) {
  return JsxRuntime.jsx(Atom_Box.make, {
              background: Caml_option.some(Particle_Background_Ds.playNext),
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Row.make, {
                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("M"), undefined, Particle_Spacer_Ds.makeVertical("S"))),
                        children: [
                          JsxRuntime.jsx(Atom_Text.make, {
                                children: Caml_option.some(props.label),
                                style: Atom_Text_Ds.PlayNextSimple.label
                              }),
                          JsxRuntime.jsx(Atom_Cta.Link.make, {
                                href: props.href,
                                label: props.title,
                                style: Atom_Cta_Ds.playNextSimple
                              })
                        ]
                      })),
              direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "row", undefined, "column")),
              identifier: Caml_option.some(Identifiers.Zone_Layout.playNext),
              justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "spaceBetween", undefined, "normal")),
              spacer: Caml_option.some(props.blockSpace),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              ref_: props.ref_
            });
}

var SimplePlayNext = {
  make: Template_PlayNext$SimplePlayNext
};

function Template_PlayNext(props) {
  var ref_ = props.ref_;
  var onPlayNextChange = props.onPlayNextChange;
  var zone = props.zone;
  var blockSpace = props.blockSpace;
  var switchHandler = function (value) {
    onPlayNextChange(value);
  };
  if (zone.NAME === "PlayNextSlider") {
    return JsxRuntime.jsx(Organism_Slider_Teaser.make, {
                blockSpace: blockSpace,
                zone: zone.VAL,
                firstTeaserToDisplay: props.playedProgramId,
                onPlayNextChange: switchHandler,
                isPlayNextActivated: props.isPlayNextActivated,
                parentRef: ref_,
                identifier: Caml_option.some(Identifiers.Zone_Layout.playNext)
              });
  }
  var match = zone.VAL;
  var teaser = match[0];
  var title = teaser.title;
  var href = teaser.href;
  var zoneTitle = match[1];
  var match$1 = Type_Teaser.Helper.extractSubtitleString(teaser.subtitle);
  if (zoneTitle === undefined) {
    return null;
  }
  if (zoneTitle.TAG !== "Displayed") {
    return null;
  }
  if (title === undefined) {
    return null;
  }
  var zoneTitle$1 = zoneTitle._0;
  switch (title.TAG) {
    case "Displayed" :
        var title$1 = title._0;
        if (match$1 !== undefined) {
          var title$2 = Type_String.Teaser.Title.toString(title$1) + " - " + match$1;
          return JsxRuntime.jsx(Template_PlayNext$SimplePlayNext, {
                      blockSpace: blockSpace,
                      title: title$2,
                      label: zoneTitle$1 + " ",
                      href: Type_String.Href.toString(href),
                      ref_: ref_
                    });
        }
        var title$3 = Type_String.Teaser.Title.toString(title$1);
        return JsxRuntime.jsx(Template_PlayNext$SimplePlayNext, {
                    blockSpace: blockSpace,
                    title: title$3,
                    label: zoneTitle$1 + " ",
                    href: Type_String.Href.toString(href),
                    ref_: ref_
                  });
    case "AccessibilityOnly" :
    case "HoverOnly" :
        return null;
    
  }
}

var make = Template_PlayNext;

export {
  SimplePlayNext ,
  make ,
}
/* Atom_Box Not a pure module */
