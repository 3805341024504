// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M17.2622 10.9509C17.2622 11.647 16.6956 12.2136 15.9995 12.2136C15.3045 12.2136 14.7379 11.647 14.7379 10.9509C14.7379 10.2548 15.3045 9.68933 15.9995 9.68933C16.6956 9.68933 17.2622 10.2548 17.2622 10.9509Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M14.7379 22.1812H17.2622V13.4757H14.7379V22.1812Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16ZM16 27.3592C22.2735 27.3592 27.3592 22.2735 27.3592 16C27.3592 9.72647 22.2735 4.64078 16 4.64078C9.72647 4.64078 4.64078 9.72647 4.64078 16C4.64078 22.2735 9.72647 27.3592 16 27.3592Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
