// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as User_Provider from "../../../user/User_Provider.res.mjs";
import * as Layout_Account from "../../layout/Layout_Account.res.mjs";
import * as User_Api__Sso__Me from "../../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function confirmOnClick(locale, onDeleteAccount) {
  var controller = new AbortController();
  var signal = controller.signal;
  User_Api__Sso__Me.$$delete({
          signal: signal,
          locale: locale
        }).then(function (response) {
        if (response.TAG === "Ok") {
          User_Provider.disconnect(onDeleteAccount, locale);
          return ;
        }
        console.log(response._0);
      });
}

function Page_MyAccount_NewDesign_DeleteAccount(props) {
  var onDeleteAccount = props.onDeleteAccount;
  var match = Context.I18n.use();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.deleteAccount;
  var match$2 = labels.common;
  return JsxRuntime.jsxs(Layout_Account.WithAvatar.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match$1.title),
                      kind: "h1",
                      style: Atom_Text_Ds.User.title("MyAccount")
                    }),
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match$1.description),
                      style: Atom_Text_Ds.User.text("MyAccount")
                    }),
                JsxRuntime.jsx(Atom_Row.make, {
                      children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                            label: match$2.confirm,
                            onClick: (function (param) {
                                confirmOnClick(locale, onDeleteAccount);
                              }),
                            variant: "base"
                          })
                    })
              ]
            });
}

var make = Page_MyAccount_NewDesign_DeleteAccount;

export {
  confirmOnClick ,
  make ,
}
/* Context Not a pure module */
