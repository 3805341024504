// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M18.4492 19.666H20.0508V13.8628H22.266V12.386H16.234V13.8628H18.4492V19.666ZM12.8436 19.77C14.414 19.77 15.5164 18.9588 15.5164 17.5132V17.4924C15.5164 16.2236 14.6844 15.6932 13.2076 15.3084C11.9492 14.986 11.6372 14.83 11.6372 14.3516V14.3308C11.6372 13.9772 11.9596 13.6964 12.5732 13.6964C13.1868 13.6964 13.8212 13.9668 14.466 14.414L15.298 13.2076C14.5596 12.6148 13.6548 12.282 12.594 12.282C11.1068 12.282 10.046 13.1556 10.046 14.4764V14.4972C10.046 15.9428 10.9924 16.3484 12.4588 16.7228C13.6756 17.0348 13.9252 17.2428 13.9252 17.6484V17.6692C13.9252 18.0956 13.53 18.3556 12.8748 18.3556C12.0428 18.3556 11.3564 18.0124 10.7012 17.4716L9.7548 18.6052C10.6284 19.3852 11.7412 19.77 12.8436 19.77ZM16 4.4716C9.6456 4.4716 4.4716 9.6456 4.4716 16C4.4716 22.3596 9.6404 27.5284 16 27.5284C22.3544 27.5284 27.5284 22.3596 27.5284 16C27.5284 9.6456 22.3544 4.4716 16 4.4716ZM16 29C8.8344 29 3 23.1708 3 16C3 8.8344 8.8344 3 16 3C23.1656 3 29 8.8344 29 16C29 23.1708 23.1708 29 16 29Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
