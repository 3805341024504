// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../design-system/atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../design-system/atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../../../type/Type_Avatar.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Molecule_Avatar from "../../../design-system/molecule/Molecule_Avatar.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as Layout_Constants from "../../../design-system/layout/Layout_Constants.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../design-system/particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../../../design-system/particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

var parentWidth = CssJs.pct(100);

var parentMaxWidth = CssJs.px(843);

var formContainerMobileMinHeight = CssJs.px(250);

var formContainerMobileWidth = {
  NAME: "percent",
  VAL: 100
};

var formContainerDesktopMinHeight = CssJs.px(330);

var formContainerDesktopMaxWidth = CssJs.px(543);

var wrapperRulesNewDesign = Particle_Screen_Ds.make(undefined, undefined, [CssJs.minHeight(CssJs.vh(100))], undefined, undefined, [CssJs.backgroundSize("cover")]);

var wrapperBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XL", "L"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("Zero", "Zero"));

var cardBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeTop("XL"), undefined, undefined, Particle_Spacer_Ds.makeTop("Zero"));

var loginInnerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XL", "L"));

var parentLoginRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.maxWidth(parentMaxWidth)], undefined, undefined, [CssJs.width(parentWidth)]);

var loginContainerRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.minHeight(formContainerDesktopMinHeight),
      CssJs.maxWidth(formContainerDesktopMaxWidth)
    ], undefined, undefined, [
      CssJs.minHeight(formContainerMobileMinHeight),
      CssJs.width(formContainerMobileWidth)
    ]);

var wrapperWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(100), undefined, undefined, "auto");

var wrapperWidthNewDesign = Particle_Screen_Ds.make(undefined, undefined, CssJs.px(400), undefined, undefined, "auto");

var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.alignItems(CssJs.center)]);

var Form_Css = {
  wrapperInnerSpace: Particle_Screen_Constants.mInnerSpace,
  wrapperWidth: wrapperWidth,
  wrapperWidthNewDesign: wrapperWidthNewDesign,
  wrapperRules: wrapperRules,
  formWrapperInnerSpace: Particle_Screen_Constants.sInnerSpace,
  formBottomGap: Particle_Screen_Constants.mGap
};

function Layout_Auth(props) {
  var __avatarDisplay = props.avatarDisplay;
  var footerLabel = props.footerLabel;
  var title = props.title;
  var avatarDisplay = __avatarDisplay !== undefined ? __avatarDisplay : "Hide";
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var userAvatar = Core__Option.getOr(Core__Option.flatMap(userInfos, User_Type.Info.avatar), Type_Avatar.defaultAvatar);
  var footerLabel$1 = footerLabel !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(footerLabel),
          kind: "span",
          style: Atom_Text_Ds.User.footer
        }) : null;
  var tmp;
  tmp = avatarDisplay === "Show" ? JsxRuntime.jsx(Molecule_Avatar.make, {
          avatar: userAvatar,
          style: "Auth"
        }) : null;
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(wrapperBlockSpace),
              children: JsxRuntime.jsx(Atom_Cell.make, {
                    justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                    blockSpace: Caml_option.some(cardBlockSpace),
                    width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                    children: JsxRuntime.jsx(Atom_Column.make, {
                          alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                          background: Caml_option.some(Particle_Background_Ds.profileBackground),
                          children: JsxRuntime.jsxs(Atom_Column.make, {
                                alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                background: Caml_option.some(Particle_Background_Ds.profileBackground),
                                blockSpace: Caml_option.some(loginInnerBlockSpace),
                                children: [
                                  tmp,
                                  title !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
                                          kind: {
                                            TAG: "Text",
                                            _0: "h1"
                                          },
                                          children: JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
                                                style: Atom_Text_Ds.User.title("Auth"),
                                                dangerouslySetInnerHTML: title
                                              })
                                        }) : null,
                                  props.children,
                                  JsxRuntime.jsxs(Atom_Row.make, {
                                        alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfCenter),
                                        children: [
                                          footerLabel$1,
                                          JsxRuntime.jsx(Atom_Cta.User.Link.make, {
                                                label: props.footerLinkLabel,
                                                href: props.footerLinkUrl,
                                                variant: "footer"
                                              })
                                        ],
                                        gap: Caml_option.some(Particle_Screen_Constants.xxsGap)
                                      })
                                ],
                                innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
                                justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                                rules: Caml_option.some(loginContainerRules)
                              }),
                          rules: Caml_option.some(parentLoginRules)
                        })
                  }),
              kind: "Main",
              maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
              rules: Caml_option.some(wrapperRulesNewDesign),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var wrapperMaxWidth = Layout_Constants.maxWidthMainContentScreen;

var wrapperWidth$1 = Particle_Screen_Constants.oneHundredPctWidth;

var wrapperJustifyContent = Particle_Screen_Constants.justifyContentCenter;

var titleAlignItems = Particle_Screen_Constants.alignItemsCenter;

var containerJustifyContent = Particle_Screen_Constants.justifyContentCenter;

var footerGap = Particle_Screen_Constants.xxsGap;

var make = Layout_Auth;

export {
  parentWidth ,
  parentMaxWidth ,
  formContainerMobileMinHeight ,
  formContainerMobileWidth ,
  formContainerDesktopMinHeight ,
  formContainerDesktopMaxWidth ,
  wrapperRulesNewDesign ,
  wrapperBlockSpace ,
  cardBlockSpace ,
  wrapperMaxWidth ,
  wrapperWidth$1 as wrapperWidth,
  wrapperJustifyContent ,
  titleAlignItems ,
  containerJustifyContent ,
  loginInnerBlockSpace ,
  parentLoginRules ,
  loginContainerRules ,
  footerGap ,
  Form_Css ,
  make ,
}
/* parentWidth Not a pure module */
