// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M21.256 20.0805C21.26 20.1084 21.2652 20.1363 21.2719 20.1641L22.8225 26.6647L17.1193 23.1812C16.8284 23.0035 16.4626 23.0035 16.1718 23.1812L10.4686 26.6647L12.0192 20.1641C12.0983 19.8326 11.9852 19.4847 11.7264 19.263L6.65098 14.9154L13.3125 14.3813C13.6523 14.3541 13.9482 14.1391 14.0791 13.8244L16.6455 7.65391L19.1111 13.5818V10.4064C19.1111 10.079 19.2164 9.77614 19.3947 9.5305L17.4847 4.93825C17.3437 4.59928 17.0126 4.37842 16.6455 4.37842C16.2784 4.37842 15.9473 4.59928 15.8064 4.93825L12.6139 12.6138L4.32754 13.2781C3.9616 13.3074 3.64924 13.554 3.53579 13.9032C3.42235 14.2523 3.5301 14.6355 3.80891 14.8743L10.1223 20.2823L8.19342 28.3684C8.10824 28.7255 8.24626 29.0988 8.54327 29.3146C8.84027 29.5304 9.23793 29.5463 9.55123 29.3549L16.6455 25.0218L23.7398 29.3549C24.0531 29.5463 24.4508 29.5304 24.7478 29.3146C25.0448 29.0988 25.1828 28.7255 25.0976 28.3684L23.1688 20.2823L23.4045 20.0805H21.256Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M23.8569 8.02917C23.8569 6.6695 24.9375 5.56029 26.2687 5.56029C27.5969 5.56029 28.6804 6.66652 28.6804 8.02917V9.55582H23.8569V8.02917ZM21.882 18.2536H30.5825C31.2058 18.2536 31.7126 17.7348 31.7126 17.0967V10.7128C31.7126 10.0747 31.2058 9.55586 30.5825 9.55586H30.5533V8.02921C30.5533 5.61101 28.6309 3.64307 26.2686 3.64307C23.9064 3.64307 21.984 5.61101 21.984 8.02921V9.55586H21.8849C21.2616 9.55586 20.7548 10.0747 20.7548 10.7128V17.0967C20.7518 17.7348 21.2587 18.2536 21.882 18.2536Z",
                      fillRule: "evenodd"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
