// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Emac_Zone from "../emac/Emac_Zone.res.mjs";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Emac_Teaser from "../emac/Emac_Teaser.res.mjs";
import * as Type_Action from "../type/Type_Action.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Binding_Constant from "../binding/Binding_Constant.res.mjs";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Constants from "../design-system/particle/Particle_Spacer_Constants.res.mjs";

function canBeDisplayed(zone) {
  if (typeof zone !== "object") {
    return true;
  }
  var variant = zone.NAME;
  if (variant === "Slider") {
    return zone.VAL.data.length !== 0;
  } else {
    return true;
  }
}

function getFirstTeaser(toDsTeaser, data) {
  return Core__Option.map(data[0], toDsTeaser);
}

function makeActionFromLink(emacCodeLink) {
  var code = emacCodeLink.split("_");
  var match = code[0];
  var match$1 = code[1];
  if (match === undefined) {
    return "Anchor";
  }
  if (match !== "ACTION") {
    return "Anchor";
  }
  if (match$1 === undefined) {
    return "Anchor";
  }
  switch (match$1) {
    case "purge-favorites" :
        return {
                TAG: "Action",
                _0: "PurgeFavorites"
              };
    case "purge-history" :
        return {
                TAG: "Action",
                _0: "PurgeHistory"
              };
    default:
      return "Anchor";
  }
}

function makeLink(emacLink) {
  return Core__Option.map(emacLink, (function (param) {
                return {
                        url: Caml_option.nullable_to_opt(param.url),
                        title: Caml_option.nullable_to_opt(param.title),
                        page: Core__Option.map(Caml_option.nullable_to_opt(param.page), makeActionFromLink)
                      };
              }));
}

function codeEmacZoneToCode(code) {
  var match = Core__Option.map(Core__Option.map((code == null) ? undefined : Caml_option.some(code), (function (__x) {
              return __x.split("_");
            })), (function (__x) {
          return Belt_Array.slice(__x, 0, 2);
        }));
  var tmp;
  if (match !== undefined && match.length === 2) {
    var split1 = match[0];
    var split2 = match[1];
    tmp = split1 + "_" + split2;
  } else {
    tmp = "";
  }
  return Binding_Constant.Zone.DataKeys.decode(tmp);
}

function make(toDsTeaser, zone) {
  var template = Curry._1(Binding_Constant.Zone.Template.tFromJs, zone.displayOptions.template);
  var match = zone.displayOptions.showItemTitle;
  var itemTitle = match ? (
      template === "horizontal-landscapeBigWithSubtitle" ? "SubtitleAsTitle" : "Normal"
    ) : (
      template === "horizontal-landscape" ? "NeverDisplayed" : "HoverOnly"
    );
  var match$1 = zone.title;
  var match$2 = zone.displayOptions.showZoneTitle;
  var match$3 = zone.displayOptions.theme;
  var tmp;
  var exit = 0;
  if (template === "tableview-playnext") {
    tmp = {
      NAME: "PlayNext",
      VAL: "WithSwitch"
    };
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match$3 == null) {
      tmp = "Classic";
    } else {
      switch (match$3) {
        case "afp" :
            tmp = "Afp";
            break;
        case "info" :
            tmp = "Info";
            break;
        case "white" :
            tmp = "White";
            break;
        default:
          tmp = "Classic";
      }
    }
  }
  return {
          id: zone.id,
          link: makeLink(Caml_option.nullable_to_opt(zone.link)),
          title: !(match$1 == null) ? (
              match$2 ? ({
                    TAG: "Displayed",
                    _0: match$1
                  }) : ({
                    TAG: "Hidden",
                    _0: match$1
                  })
            ) : undefined,
          itemTitle: itemTitle,
          description: Caml_option.nullable_to_opt(zone.description),
          itemTemplate: Core__Option.mapOr(template, "Landscape", Binding_Constant.Zone.Template.toItemTemplate),
          theme: tmp,
          data: zone.content.data.map(function (extra) {
                return Curry._2(toDsTeaser, itemTitle, extra);
              }),
          nextPage: Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Caml_option.nullable_to_opt(zone.content.pagination), (function (param) {
                          return Caml_option.nullable_to_opt(param.links);
                        })), (function (param) {
                      return Caml_option.nullable_to_opt(param.next);
                    })), (function (nextPage) {
                  return {
                          TAG: "Emac",
                          _0: nextPage
                        };
                })),
          lastPage: Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Caml_option.nullable_to_opt(zone.content.pagination), (function (param) {
                          return Caml_option.nullable_to_opt(param.links);
                        })), (function (param) {
                      return Caml_option.nullable_to_opt(param.last);
                    })), (function (lastPage) {
                  return lastPage;
                })),
          totalPagesCount: Core__Option.flatMap(Caml_option.nullable_to_opt(zone.content.pagination), (function (param) {
                  return Caml_option.nullable_to_opt(param.pages);
                })),
          slug: Caml_option.nullable_to_opt(zone.slug),
          groupZoneName: Caml_option.nullable_to_opt(zone.groupedZonesName),
          genreStickerBehavior: Core__Option.getOr(Caml_option.nullable_to_opt(zone.displayTeaserGenre), false) ? "DisplayGenreSticker" : "HideGenreSticker"
        };
}

function getEventZone(getFirstTeaser, zone, textPosition) {
  var action = Type_Action.actionFromZoneCode(zone.code);
  return Core__Option.map(getFirstTeaser(zone.content.data), (function (teaser) {
                var match = teaser.program.clip;
                var match$1 = teaser.program.trailer;
                var match$2 = teaser.program.id;
                if (match !== undefined) {
                  return {
                          NAME: "EventZone",
                          VAL: {
                            teaser: teaser,
                            textPosition: textPosition,
                            video: {
                              TAG: "Clip",
                              _0: match
                            },
                            action: action
                          }
                        };
                }
                if (match$1 !== undefined && match$2 !== undefined) {
                  return {
                          NAME: "EventZone",
                          VAL: {
                            teaser: teaser,
                            textPosition: textPosition,
                            video: {
                              TAG: "Trailer",
                              _0: match$2
                            },
                            action: action
                          }
                        };
                }
                return {
                        NAME: "EventZone",
                        VAL: {
                          teaser: teaser,
                          textPosition: textPosition,
                          action: action
                        }
                      };
              }));
}

function toDsZone(toDsTeaser, category, pageParent, pageDescription, pageUrl, pageKind, pageMetasOpt, zone) {
  var pageMetas = pageMetasOpt !== undefined ? pageMetasOpt : [];
  var getFirstTeaser$1 = function (extra) {
    return getFirstTeaser((function (extra) {
                  return Curry._2(toDsTeaser, "Normal", extra);
                }), extra);
  };
  return Core__Option.flatMap(Curry._1(Binding_Constant.Zone.Template.tFromJs, zone.displayOptions.template), (function (template) {
                var match = zone.content.data;
                var match$1 = zone.authenticatedContent;
                if (template === "ebu-box") {
                  return {
                          NAME: "EbuBox",
                          VAL: [
                            Core__Option.getOr(Caml_option.nullable_to_opt(zone.title), ""),
                            zone.id
                          ]
                        };
                }
                if (template === "horizontalSelectedHighlighted-landscape") {
                  return ;
                }
                if (match.length === 0 && (match$1 == null)) {
                  return ;
                }
                if (template === "single-programContent") {
                  return Core__Option.map(getFirstTeaser$1(zone.content.data), (function (teaser) {
                                return {
                                        NAME: "ProgramContent",
                                        VAL: [
                                          teaser,
                                          pageParent,
                                          "Auto"
                                        ]
                                      };
                              }));
                }
                if (template === "single-pageHeader" || template === "single-collectionContent") {
                  return Core__Option.map(getFirstTeaser$1(zone.content.data), (function (teaser) {
                                var tmp;
                                tmp = typeof pageKind !== "object" || pageKind.TAG !== "Rc" ? "OnButtonClick" : pageKind._0;
                                return {
                                        NAME: "ContentCollection",
                                        VAL: {
                                          teaser: teaser,
                                          description: pageDescription,
                                          pageParent: pageParent,
                                          favoriteButtonAvailability: "Available",
                                          primaryTitle: "h1",
                                          trailerBehavior: tmp,
                                          clip: teaser.program.clip,
                                          metas: pageMetas,
                                          pageKind: pageKind
                                        }
                                      };
                              }));
                }
                if (template === "slider-landscape" || template === "slider-square" || template === "slider-banner") {
                  var match$2 = zone.content.data;
                  if (match$2.length !== 1) {
                    var zone$1 = make(toDsTeaser, zone);
                    return {
                            NAME: "TopTeaserWithCarousel",
                            VAL: [
                              zone$1,
                              pageKind,
                              category
                            ]
                          };
                  }
                  var teaser = match$2[0];
                  var dsTeaser = toDsTeaser("Normal", teaser);
                  var match$3 = dsTeaser.program.trailer;
                  var match$4 = dsTeaser.program.id;
                  var match$5 = dsTeaser.program.clip;
                  if (match$5 !== undefined) {
                    return {
                            NAME: "TopTeaserWithClip",
                            VAL: [
                              dsTeaser,
                              pageKind,
                              match$5
                            ]
                          };
                  } else if (match$3 !== undefined && match$4 !== undefined) {
                    return {
                            NAME: "TopTeaserWithTrailer",
                            VAL: [
                              dsTeaser,
                              pageKind,
                              match$4
                            ]
                          };
                  } else {
                    return {
                            NAME: "TopTeaser",
                            VAL: [
                              dsTeaser,
                              pageKind
                            ]
                          };
                  }
                }
                if (template === "horizontal-square" || template === "horizontal-landscapeBigWithSubtitle" || template === "horizontal-landscape-genre" || template === "horizontal-landscape" || template === "horizontalFirstHighlighted-square" || template === "horizontal-portrait" || template === "horizontal-landscapeBig" || template === "horizontal-poster") {
                  return {
                          NAME: "Slider",
                          VAL: make(toDsTeaser, zone)
                        };
                }
                if (template === "vertical-landscape" || template === "verticalFirstHighlighted-landscape") {
                  var zone$2 = make(toDsTeaser, zone);
                  return {
                          NAME: "PaginatedGrid",
                          VAL: {
                            zone: zone$2,
                            pageUrl: pageUrl
                          }
                        };
                }
                if (template === "single-partner") {
                  return {
                          NAME: "Partner",
                          VAL: make(toDsTeaser, zone)
                        };
                }
                if (template === "vertical-label") {
                  var dsZone = make(toDsTeaser, zone);
                  var match$6 = dsZone.data;
                  if (match$6.length !== 0) {
                    return {
                            NAME: "Label",
                            VAL: dsZone
                          };
                  } else {
                    return ;
                  }
                }
                if (template === "event-textOnRightSide") {
                  return getEventZone(getFirstTeaser$1, zone, "TextOnRightSide");
                }
                if (template !== "tableview-playnext") {
                  if (template === "single-newsletter") {
                    return Core__Option.map(getFirstTeaser$1(zone.content.data), (function (teaser) {
                                  return {
                                          NAME: "Newsletter",
                                          VAL: teaser
                                        };
                                }));
                  } else if (template === "tableview-guide") {
                    return {
                            NAME: "Guide",
                            VAL: make(toDsTeaser, zone)
                          };
                  } else if (template === "single-banner") {
                    return Core__Option.map(getFirstTeaser$1(zone.content.data), (function (teaser) {
                                  return {
                                          NAME: "Banner",
                                          VAL: teaser
                                        };
                                }));
                  } else {
                    return getEventZone(getFirstTeaser$1, zone, "TextOnLeftSide");
                  }
                }
                var zone$3 = make(toDsTeaser, zone);
                var match$7 = zone$3.data;
                var len = match$7.length;
                if (len !== 1) {
                  if (len !== 0) {
                    return {
                            NAME: "PlayNextSlider",
                            VAL: zone$3
                          };
                  } else {
                    return ;
                  }
                }
                var firstData = match$7[0];
                return {
                        NAME: "PlayNextTeaser",
                        VAL: [
                          firstData,
                          zone$3.title
                        ]
                      };
              }));
}

var withBackgroundSpacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.Slider.withBackgroundDesktop, undefined, undefined, Particle_Spacer_Constants.Zone.Slider.withBackgroundMobile);

var afterTopTeaserSpacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.Slider.afterTopTeaserDesktop, undefined, undefined, Particle_Spacer_Constants.Zone.Slider.afterTopTeaserMobile);

var topContentSpacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.allZero, undefined, undefined, Particle_Spacer_Constants.allZero);

var firstContent = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.firstDesktop, undefined, undefined, Particle_Spacer_Constants.Zone.firstMobile);

var defaultSpacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.desktop, undefined, undefined, Particle_Spacer_Constants.Zone.mobile);

var eventZoneSpacer = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.desktop, undefined, undefined, Particle_Spacer_Constants.Zone.$$Event.mobile);

var firstEventZone = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.$$Event.firstDesktop, undefined, undefined, Particle_Spacer_Constants.Zone.$$Event.firstMobile);

function getSpacers(previousZone, zone) {
  var exit = 0;
  if (typeof zone === "object" && zone.NAME === "Slider") {
    var match = zone.VAL.theme;
    if (match === "White") {
      return withBackgroundSpacer;
    }
    if (match === "Info" || match === "Afp") {
      return withBackgroundSpacer;
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (previousZone !== undefined) {
      var exit$1 = 0;
      if (typeof previousZone === "object") {
        var variant = previousZone.NAME;
        if (variant === "TopTeaser" || variant === "TopTeaserWithTrailer" || variant === "TopTeaserWithCarousel") {
          return afterTopTeaserSpacer;
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (typeof zone === "object" && zone.NAME === "EventZone") {
          return eventZoneSpacer;
        } else {
          return defaultSpacer;
        }
      }
      
    } else {
      if (typeof zone !== "object") {
        return firstContent;
      }
      var variant$1 = zone.NAME;
      if (variant$1 === "TopTeaser" || variant$1 === "ContentCollection" || variant$1 === "TopTeaserWithClip" || variant$1 === "TopTeaserWithTrailer" || variant$1 === "TopTeaserWithCarousel") {
        return topContentSpacer;
      } else if (variant$1 === "EventZone") {
        return firstEventZone;
      } else {
        return firstContent;
      }
    }
  }
  
}

function getMethod(authenticatedContent) {
  var contentId = Core__Option.flatMap(Core__Option.flatMap((authenticatedContent == null) ? undefined : Caml_option.some(authenticatedContent), (function (param) {
              return Caml_option.nullable_to_opt(param.contentId);
            })), Emac_Zone.contentIdFromJs);
  if (contentId === undefined) {
    return "Static";
  }
  if (!(contentId === "sso-favorites" || contentId === "sso-history" || contentId === "sso-personalzone")) {
    return {
            TAG: "Restricted",
            _0: "Connected"
          };
  }
  var connectedZoneEmptyDisplay = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap((authenticatedContent == null) ? undefined : Caml_option.some(authenticatedContent), (function (param) {
                  return Caml_option.nullable_to_opt(param.displayOptions.theme);
                })), (function (theme) {
              if (theme === "showEmptyZone") {
                return "Show";
              }
              
            })), "Hide");
  return {
          TAG: "Connected",
          _0: contentId,
          _1: connectedZoneEmptyDisplay
        };
}

function createMain(zoneId, key, template, blockSpace, method) {
  return {
          TAG: "Main",
          _0: {
            id: zoneId,
            template: template,
            key: key,
            blockSpace: blockSpace,
            method: method
          }
        };
}

function getNextEpisodeUrl(data) {
  return Core__Option.map(data.find(function (teaser) {
                  return Core__Option.isSome(teaser.callToAction);
                }), (function (teaser) {
                return teaser.href;
              }));
}

function addNextEpisodeUrlToContentCollection(contentCollection, nextTemplate) {
  if (typeof nextTemplate !== "object") {
    return contentCollection;
  }
  var variant = nextTemplate.NAME;
  if (variant === "Slider") {
    if (Core__Option.isSome(contentCollection.nextEpisodeUrl)) {
      return contentCollection;
    }
    var newrecord = Caml_obj.obj_dup(contentCollection);
    newrecord.nextEpisodeUrl = getNextEpisodeUrl(nextTemplate.VAL.data);
    return newrecord;
  }
  if (variant !== "PaginatedGrid") {
    return contentCollection;
  }
  var newrecord$1 = Caml_obj.obj_dup(contentCollection);
  newrecord$1.nextEpisodeUrl = getNextEpisodeUrl(nextTemplate.VAL.zone.data);
  return newrecord$1;
}

function updateContentCollectionResult(result, previousTemplate) {
  return result.map(function (t) {
              switch (t.TAG) {
                case "Main" :
                    var layout = t._0;
                    var match = layout.template;
                    if (typeof match === "object" && match.NAME === "ContentCollection") {
                      return {
                              TAG: "Main",
                              _0: {
                                id: layout.id,
                                template: Core__Option.getOr(previousTemplate, {
                                      NAME: "ContentCollection",
                                      VAL: match.VAL
                                    }),
                                key: layout.key,
                                blockSpace: layout.blockSpace,
                                method: layout.method
                              }
                            };
                    } else {
                      return t;
                    }
                case "Program" :
                case "Guide" :
                    return t;
                
              }
            });
}

function createDsTeaser(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, domain, zoneId, genreStickerBehavior, itemTitle, teaser) {
  return Emac_Teaser.toDsTeaser(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, zoneId, itemTitle, genreStickerBehavior, domain, undefined, teaser);
}

function handlePaginatedGrid(paginatedGridTemplate, groupZoneName, result, previousTemplate, currentZone, zoneId, key) {
  var relatedZone = result.find(function (zone) {
        switch (zone.TAG) {
          case "Main" :
              var match = zone._0.template;
              if (typeof match !== "object") {
                return false;
              }
              if (match.NAME !== "PaginatedGrid") {
                return false;
              }
              var match$1 = match.VAL.relatedZones;
              if (match$1 !== undefined) {
                return match$1.name === groupZoneName;
              } else {
                return false;
              }
          case "Program" :
          case "Guide" :
              return false;
          
        }
      });
  if (relatedZone !== undefined) {
    switch (relatedZone.TAG) {
      case "Main" :
          var match = relatedZone._0.template;
          if (typeof match === "object" && match.NAME === "PaginatedGrid") {
            var match$1 = match.VAL.relatedZones;
            if (match$1 !== undefined) {
              match$1.zones.push(paginatedGridTemplate.zone);
              return ;
            }
            
          }
          break;
      case "Program" :
      case "Guide" :
          break;
      
    }
  }
  var blockSpace = getSpacers(previousTemplate, {
        NAME: "PaginatedGrid",
        VAL: paginatedGridTemplate
      });
  var method = getMethod(currentZone.authenticatedContent);
  var newrecord = Caml_obj.obj_dup(paginatedGridTemplate);
  var template_1 = (newrecord.relatedZones = {
      name: groupZoneName,
      zones: []
    }, newrecord);
  var template = {
    NAME: "PaginatedGrid",
    VAL: template_1
  };
  return createMain(zoneId, key, template, blockSpace, method);
}

function handleTemplate(template, result, previousTemplate, currentZone, zoneId, key) {
  if (typeof template === "object" && template.NAME === "PaginatedGrid") {
    var paginatedGridTemplate = template.VAL;
    var groupZoneName = paginatedGridTemplate.zone.groupZoneName;
    if (groupZoneName !== undefined) {
      return handlePaginatedGrid(paginatedGridTemplate, groupZoneName, result, previousTemplate, currentZone, zoneId, key);
    }
    
  }
  var blockSpace = getSpacers(previousTemplate, template);
  var method = getMethod(currentZone.authenticatedContent);
  return createMain(zoneId, key, template, blockSpace, method);
}

function processNewT(newT, result, previousTemplate) {
  if (newT === undefined) {
    return {
            previousTemplate: previousTemplate,
            result: result
          };
  }
  switch (newT.TAG) {
    case "Main" :
        var template = newT._0.template;
        var previousTemplate$1 = previousTemplate !== undefined && typeof previousTemplate === "object" && previousTemplate.NAME === "ContentCollection" ? ({
              NAME: "ContentCollection",
              VAL: addNextEpisodeUrlToContentCollection(previousTemplate.VAL, template)
            }) : template;
        result.push(newT);
        return {
                previousTemplate: previousTemplate$1,
                result: updateContentCollectionResult(result, previousTemplate$1)
              };
    case "Program" :
    case "Guide" :
        result.push(newT);
        return {
                previousTemplate: previousTemplate,
                result: result
              };
    
  }
}

function transformZones(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, category, pageParent, pageDescription, pageUrl, pageKind, pageMetas, domain, zones) {
  return Core__Array.reduce(zones, {
              previousTemplate: undefined,
              result: []
            }, (function (param, currentZone) {
                var result = param.result;
                var previousTemplate = param.previousTemplate;
                var zoneId = currentZone.id;
                var key = currentZone.id;
                var genreStickerBehavior = Core__Option.getOr(Caml_option.nullable_to_opt(currentZone.displayTeaserGenre), false) ? "DisplayGenreSticker" : "HideGenreSticker";
                var toDsTeaser = function (titleBehaviour, teaser) {
                  return createDsTeaser(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, domain, zoneId, genreStickerBehavior, titleBehaviour, teaser);
                };
                var newT = Core__Option.flatMap(toDsZone(toDsTeaser, category, pageParent, pageDescription, pageUrl, pageKind, pageMetas, currentZone), (function (template) {
                        var variant = template.NAME;
                        if (variant === "Partner" || variant === "PlayNextSlider" || variant === "ProgramContent" || variant === "PlayNextTeaser" || variant === "Label") {
                          return {
                                  TAG: "Program",
                                  _0: {
                                    template: template
                                  }
                                };
                        } else if (variant === "Guide") {
                          return {
                                  TAG: "Guide",
                                  _0: template
                                };
                        } else {
                          return handleTemplate(template, result, previousTemplate, currentZone, zoneId, key);
                        }
                      }));
                return processNewT(newT, result, previousTemplate);
              })).result;
}

function makeFromClub(minutesLabel, initialZone, kind, page, nextPage) {
  var newrecord = Caml_obj.obj_dup(initialZone);
  newrecord.nextPage = Core__Option.map(page, (function (nextPage) {
          return {
                  TAG: "Club",
                  _0: kind,
                  _1: nextPage
                };
        }));
  newrecord.data = nextPage.map(function (__x) {
        return Type_Club.Teaser.toDsTeaser(minutesLabel, undefined, __x);
      });
  return newrecord;
}

function makeFromNextZone(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, initialZone, nextPage) {
  var newrecord = Caml_obj.obj_dup(initialZone);
  newrecord.nextPage = Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Caml_option.nullable_to_opt(nextPage.pagination), (function (param) {
                  return Caml_option.nullable_to_opt(param.links);
                })), (function (param) {
              return Caml_option.nullable_to_opt(param.next);
            })), (function (nextPage) {
          return {
                  TAG: "Emac",
                  _0: nextPage
                };
        }));
  newrecord.data = Core__Option.getOr(Core__Option.map(Caml_option.nullable_to_opt(nextPage.data), (function (__x) {
              return __x.map(function (__x) {
                          return Emac_Teaser.toDsTeaser(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, Type_String.Zone.Id.make(initialZone.id), initialZone.itemTitle, initialZone.genreStickerBehavior, undefined, undefined, __x);
                        });
            })), []);
  return newrecord;
}

export {
  canBeDisplayed ,
  getFirstTeaser ,
  makeActionFromLink ,
  makeLink ,
  codeEmacZoneToCode ,
  make ,
  getEventZone ,
  toDsZone ,
  withBackgroundSpacer ,
  afterTopTeaserSpacer ,
  topContentSpacer ,
  firstContent ,
  defaultSpacer ,
  eventZoneSpacer ,
  firstEventZone ,
  getSpacers ,
  getMethod ,
  createMain ,
  getNextEpisodeUrl ,
  addNextEpisodeUrlToContentCollection ,
  updateContentCollectionResult ,
  createDsTeaser ,
  handlePaginatedGrid ,
  handleTemplate ,
  processNewT ,
  transformZones ,
  makeFromClub ,
  makeFromNextZone ,
}
/* withBackgroundSpacer Not a pure module */
