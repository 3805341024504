// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Organism_Teaser_Title(props) {
  var style = props.style;
  var match = Core__Option.map(props.title, (function (title) {
          return Type_String.Teaser.Title.toString(title);
        }));
  if (match !== undefined && match !== "") {
    if (style !== undefined) {
      return JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(match),
                  id: props.id,
                  identifier: props.identifier,
                  kind: "h3",
                  style: style
                });
    } else {
      return JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                  children: match
                });
    }
  } else {
    return null;
  }
}

var make = Organism_Teaser_Title;

export {
  make ,
}
/* Atom_Text Not a pure module */
