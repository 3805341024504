// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

function make(param) {
  var kind = param.kind;
  var url = param.url;
  var links = param.socialNetworks.map(function (s) {
        var match;
        switch (s) {
          case "Facebook" :
              match = [
                "Facebook",
                "https://www.facebook.com/sharer/sharer.php?u=" + url,
                Atom_Cta_Ds.Sharebar.make("Facebook", kind)
              ];
              break;
          case "X" :
              match = [
                "X",
                "https://twitter.com/intent/tweet?url=" + url,
                Atom_Cta_Ds.Sharebar.make("X", kind)
              ];
              break;
          case "Whatsapp" :
              match = [
                "Whatsapp",
                "whatsapp://send?text=" + url,
                Atom_Cta_Ds.Sharebar.make("Whatsapp", kind)
              ];
              break;
          
        }
        var label = match[0];
        return JsxRuntime.jsx(Atom_Cta.Link.make, {
                    href: match[1],
                    label: label,
                    style: match[2]
                  }, label);
      });
  return JsxRuntime.jsx(Atom_Row.make, {
              children: links,
              gap: param.gap,
              innerSpace: param.innerSpace,
              justifyContent: Caml_option.some(param.justifyContent)
            });
}

var Internal = {
  make: make
};

var alignSelf = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexStart");

var width = Particle_Screen_Ds.make(undefined, undefined, undefined, CssJs.px(400), undefined, CssJs.px(200));

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, "S", undefined, "XS");

var Css = {
  alignSelf: alignSelf,
  width: width,
  justifyContent: justifyContent,
  innerSpace: innerSpace
};

var socialNetworks = [
  "Facebook",
  "X",
  "Whatsapp"
];

function make$1(param) {
  return JsxRuntime.jsx(Atom_Cell.make, {
              justifyContent: Caml_option.some(justifyContent),
              alignSelf: Caml_option.some(alignSelf),
              width: Caml_option.some(width),
              children: JsxRuntime.jsx(make, {
                    url: param.url,
                    kind: "Player",
                    socialNetworks: socialNetworks,
                    innerSpace: Caml_option.some(innerSpace),
                    justifyContent: justifyContent
                  })
            });
}

var Player = {
  Css: Css,
  socialNetworks: socialNetworks,
  make: make$1
};

var justifyContent$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var Css$1 = {
  justifyContent: justifyContent$1,
  gap: gap
};

var socialNetworks$1 = [
  "Facebook",
  "X",
  "Whatsapp"
];

function make$2(param) {
  return JsxRuntime.jsx(make, {
              url: param.url,
              kind: "EventZone",
              socialNetworks: socialNetworks$1,
              justifyContent: justifyContent$1,
              gap: Caml_option.some(gap)
            });
}

var EventZone = {
  Css: Css$1,
  socialNetworks: socialNetworks$1,
  make: make$2
};

export {
  Internal ,
  Player ,
  EventZone ,
}
/* alignSelf Not a pure module */
