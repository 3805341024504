// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M3 29H29V3H3V29ZM5.59997 26.4001H26.4V5.60011H5.59997V26.4001ZM14.5232 10.8181V14.5647H10.7558V17.4351H14.5232V21.1817H17.4781V17.4351H21.2442V14.5647H17.4781V10.8181H14.5232Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
