import { type Translate } from '@replay/i18n/types/translations';

type TranslateNextEpisodeParams = {
    progress: number;
    season: number;
    episode: number;
    totalEpisodes: number;
};

export const translateNextEpisode =
    (translate: Translate) =>
        (params: TranslateNextEpisodeParams): string => {
            const { progress, season, episode, totalEpisodes } = params;
            const labelAction =
                progress > 1 && progress < 95
                    ? translate('replay_internal.formaters.collection.continue_watching')
                    : translate('teasers.watch');
            const labelSeason =
                season > 0
                    ? ' : ' + translate('replay_internal.formaters.collection.season_number', { seasonNumber: season })
                    : '';

            return `${labelAction}${labelSeason} (${episode}/${totalEpisodes})`;
        };

export const translateAddToFavorite =
    (translate: Translate) =>
        (title: string): string =>
            translate('replay_internal.formaters.teasers.favorite.add', { title });

export const translateAddToReminder =
    (translate: Translate) =>
        (title: string): string =>
            translate('replay_internal.formaters.teasers.reminder.add', { title });

export const translateRemoveFromFavorite =
    (translate: Translate) =>
        (title: string): string =>
            translate('replay_internal.formaters.teasers.favorite.remove', { title });

export const translateRemoveFromReminder =
    (translate: Translate) =>
        (title: string): string =>
            translate('replay_internal.formaters.teasers.reminder.remove', { title });

export const translateProgress =
    (translate: Translate) =>
        (pct: number): string =>
            translate('replay_internal.formaters.teasers.progress.label', { pct: Math.round(pct) });

export const translateWatchTrailer = (translate: Translate) => (programTitle: string) =>
    translate('replay_internal.formaters.accessibility.watchTrailer', { programTitle });

export const translateInfoSelectedDay =
    (translate: Translate) =>
        (selectionDate: string, daysBefore: string, daysAfter: string): string =>
            translate('replay_internal.formaters.guide_tv.daySlider_infoSelectedDay', {
                selectionDate,
                daysBefore,
                daysAfter,
            });

export const translateSeasonCount =
    (translate: Translate) =>
        (seasonCount: number): string =>
            translate('replay_internal.formaters.collection.season_count', { count: seasonCount });

export const formValidationStringMax =
    (translate: Translate) =>
        (numberOfCharacters: number): string =>
            translate('replay_internal.formaters.formValidation.stringMax', { numberOfCharacters });

export const formValidationStringMin =
    (translate: Translate) =>
        (numberOfCharacters: number): string =>
            translate('replay_internal.formaters.formValidation.stringMin', { numberOfCharacters });

export const forgottenPasswordSuccess =
    (translate: Translate) =>
        (email: string): string =>
            translate('replay_internal.formaters.myArte.login.forgottenPasswordSuccess', { email });

export const registerConfirmSuccess =
    (translate: Translate) =>
        (email: string): string =>
            translate('replay_internal.formaters.myArte.register.registerConfirmSuccess', { email });

export const magicLink =
    (translate: Translate) =>
        (email: string): string =>
            translate('replay_internal.formaters.myArte.login.magicLink', { email });

export const acceptTosLabel =
    (translate: Translate) =>
        (acceptTosCguLabel: string, acceptTosCguLink: string, acceptTosDataLabel: string, acceptTosDataLink: string) =>
            translate('replay_internal.formaters.myArte.register.acceptTosLabel', {
                cgu: `<a target="_blank" href="${acceptTosCguLink}">${acceptTosCguLabel}</a>`,
                data: `<a target="_blank" href="${acceptTosDataLink}">${acceptTosDataLabel}</a>`,
            });
export const cookieLink = (translate: Translate) => (cookieLink: string, cookieUrl: string) =>
    translate('replay_internal.formaters.cookie.link', {
        cookie: `<a target="_blank" href="${cookieLink}">${cookieUrl}</a>`,
    });
export const checkFaqLabel = (translate: Translate) => (checkFaqLabel: string, checkFaqLink: string) =>
    translate('replay_internal.formaters.feedback.checkFaq', {
        faq: `<a target="_blank" href="${checkFaqLink}">${checkFaqLabel}</a>`,
    });

export const acceptDataLabel = (translate: Translate) => (acceptDataLabel: string, acceptDataLink: string) =>
    translate('replay_internal.formaters.feedback.acceptData', {
        data: `<a target="_blank" href="${acceptDataLink}">${acceptDataLabel}</a>`,
    });
export const avatarSelected = (translate: Translate) => (avatar: string) =>
    translate('replay_internal.formaters.avatar.selected', { avatar });
export const avatarSelectable = (translate: Translate) => (avatar: string) =>
    translate('replay_internal.formaters.avatar.selectable', { avatar });