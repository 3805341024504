// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Row from "./Atom_Row.res.mjs";
import * as Atom_Text from "./Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Animation_Ds from "../particle/Particle_Animation_Ds.res.mjs";
import * as Particle_Animation_Css from "../particle/Particle_Animation_Css.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Accessibility_Constant from "../particle/Particle_Accessibility_Constant.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XXS", "XS"));

var base_textStyle = Atom_Text_Ds.Sticker.base;

var base = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.teaserSticker,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: base_textStyle,
  border: undefined
};

function direct(dotColor) {
  return {
          accessibility: undefined,
          backgroundStyle: Particle_Background_Ds.teaserSticker,
          blockSpace: blockSpace,
          withDot: dotColor,
          textStyle: Atom_Text_Ds.Sticker.direct,
          border: undefined
        };
}

function live(dotColor) {
  return {
          accessibility: undefined,
          backgroundStyle: Particle_Background_Ds.teaserSticker,
          blockSpace: blockSpace,
          withDot: dotColor,
          textStyle: Atom_Text_Ds.Sticker.live,
          border: undefined
        };
}

var highlight_textStyle = Atom_Text_Ds.Sticker.highlight;

var highlight = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.orange,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: highlight_textStyle,
  border: undefined
};

var time_accessibility = Particle_Accessibility_Constant.ariaHidden;

var time_textStyle = Atom_Text_Ds.Sticker.time;

var time = {
  accessibility: time_accessibility,
  backgroundStyle: Particle_Background_Ds.timeSticker,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: time_textStyle,
  border: undefined
};

var disabledTime_textStyle = Atom_Text_Ds.Sticker.time;

var disabledTime = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.timeSticker,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: disabledTime_textStyle,
  border: undefined
};

var firstBroadcastDate_blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

var firstBroadcastDate_textStyle = Atom_Text_Ds.Sticker.firstBroadcastDate;

var firstBroadcastDate = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.tags,
  blockSpace: firstBroadcastDate_blockSpace,
  withDot: undefined,
  textStyle: firstBroadcastDate_textStyle,
  border: undefined
};

function presseFirstBroadcastDate(improveComWithUser) {
  var tmp;
  tmp = improveComWithUser === "On" ? "XS" : "S";
  return {
          accessibility: undefined,
          backgroundStyle: Particle_Background_Ds.background,
          blockSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll(tmp)),
          withDot: undefined,
          textStyle: Atom_Text_Ds.Presse.firstBroadcastDate(improveComWithUser),
          border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeRegular))
        };
}

var presseHightlight_blockSpace = (function (__x) {
      return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, __x);
    })(Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

var presseHightlight_textStyle = Atom_Text_Ds.Presse.$$default;

var presseHightlight_border = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.default));

var presseHightlight = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.transparent,
  blockSpace: presseHightlight_blockSpace,
  withDot: undefined,
  textStyle: presseHightlight_textStyle,
  border: presseHightlight_border
};

var base_textStyle$1 = Atom_Text_Ds.Sticker.TeaserGuide.base;

var base$1 = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.teaserSticker,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: base_textStyle$1,
  border: undefined
};

function direct$1(dotColor) {
  return {
          accessibility: undefined,
          backgroundStyle: Particle_Background_Ds.teaserSticker,
          blockSpace: blockSpace,
          withDot: dotColor,
          textStyle: Atom_Text_Ds.Sticker.TeaserGuide.direct,
          border: undefined
        };
}

function live$1(dotColor) {
  return {
          accessibility: undefined,
          backgroundStyle: Particle_Background_Ds.teaserSticker,
          blockSpace: blockSpace,
          withDot: dotColor,
          textStyle: Atom_Text_Ds.Sticker.live,
          border: undefined
        };
}

var time_accessibility$1 = Particle_Accessibility_Constant.ariaHidden;

var time_textStyle$1 = Atom_Text_Ds.Sticker.TeaserGuide.time;

var time$1 = {
  accessibility: time_accessibility$1,
  backgroundStyle: Particle_Background_Ds.timeSticker,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: time_textStyle$1,
  border: undefined
};

var disabledTime_textStyle$1 = Atom_Text_Ds.Sticker.TeaserGuide.time;

var disabledTime$1 = {
  accessibility: undefined,
  backgroundStyle: Particle_Background_Ds.timeSticker,
  blockSpace: blockSpace,
  withDot: undefined,
  textStyle: disabledTime_textStyle$1,
  border: undefined
};

var TeaserGuide = {
  base: base$1,
  direct: direct$1,
  live: live$1,
  time: time$1,
  disabledTime: disabledTime$1
};

function view(param) {
  return {
          accessibility: param.accessibility,
          backgroundStyle: param.backgroundStyle,
          blockSpace: param.blockSpace,
          withDot: param.withDot,
          textStyle: param.textStyle,
          border: param.border
        };
}

var Atom_Label_Ds = {
  base: base,
  direct: direct,
  live: live,
  time: time,
  disabledTime: disabledTime,
  firstBroadcastDate: firstBroadcastDate,
  presseHightlight: presseHightlight,
  highlight: highlight,
  presseFirstBroadcastDate: presseFirstBroadcastDate,
  TeaserGuide: TeaserGuide,
  view: view
};

function makeDot(color) {
  var beforRules = [
      CssJs.backgroundColor(Particle_Color.Css.color(color)),
      CssJs.unsafe("content", ""),
      CssJs.marginRight(Particle_Css.getSpacerSize("XXS")),
      CssJs.alignSelf("center"),
      CssJs.borderRadius(CssJs.pct(100)),
      CssJs.width(CssJs.px(5)),
      CssJs.height(CssJs.px(5))
    ].concat(Particle_Animation_Css.toCss(Particle_Animation_Ds.Sticker.liveDot));
  return [CssJs.before(beforRules)];
}

function toCss(param) {
  var withDot = param.withDot;
  if (withDot !== undefined) {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, makeDot(withDot));
  } else {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  }
}

var Atom_Label_Css = {
  toCss: toCss
};

var defaultInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var defaultRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);

function Atom_Label(props) {
  var __rules = props.rules;
  var __innerSpace = props.innerSpace;
  var __style = props.style;
  var style = __style !== undefined ? Caml_option.valFromOption(__style) : base;
  var innerSpace = __innerSpace !== undefined ? Caml_option.valFromOption(__innerSpace) : defaultInnerSpace;
  var rules = __rules !== undefined ? Caml_option.valFromOption(__rules) : defaultRules;
  var view$1 = view(style);
  var rulesLabel = toCss(view$1);
  var rules$1 = Particle_Screen_Ds.$$Array.concat(rules, rulesLabel);
  return JsxRuntime.jsx(Atom_Row.make, {
              accessibility: view$1.accessibility,
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              alignSelf: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "selfStart")),
              background: Caml_option.some(view$1.backgroundStyle),
              blockSpace: Caml_option.some(view$1.blockSpace),
              border: view$1.border,
              children: JsxRuntime.jsx(Atom_Text.make, {
                    children: Caml_option.some(props.text),
                    kind: "span",
                    style: view$1.textStyle
                  }),
              id: props.id,
              identifier: props.identifier,
              innerSpace: Caml_option.some(innerSpace),
              rules: Caml_option.some(rules$1)
            });
}

var make = Atom_Label;

export {
  Atom_Label_Ds ,
  Atom_Label_Css ,
  base ,
  direct ,
  live ,
  time ,
  disabledTime ,
  firstBroadcastDate ,
  presseHightlight ,
  highlight ,
  presseFirstBroadcastDate ,
  TeaserGuide ,
  view ,
  defaultInnerSpace ,
  defaultRules ,
  make ,
}
/* blockSpace Not a pure module */
