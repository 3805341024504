'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util_Array from "./Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";

function getDomRef(ref$p) {
  return Caml_option.nullable_to_opt(ref$p.current);
}

var defaultRect = {
  left: 0,
  height: 0,
  right: 0,
  top: 0,
  width: 0
};

function getElementRect(element) {
  var domRect = element.getBoundingClientRect();
  return {
          left: domRect.left,
          height: domRect.height,
          right: domRect.right,
          top: domRect.top,
          width: domRect.width
        };
}

function getElementSize(container) {
  return Core__Option.getOr(Core__Option.map(container, getElementRect), defaultRect);
}

function isVisible(containerSize, direction, param) {
  var right = param.right;
  var left = param.left;
  var match = left > 0;
  var match$1 = right > 0;
  var match$2 = right <= containerSize.right;
  var match$3 = left <= containerSize.right;
  if (match) {
    if (match$1) {
      if (match$2) {
        if (match$3) {
          return true;
        } else {
          return false;
        }
      } else if (match$3) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else if (match$1 && match$2) {
    return true;
  } else {
    return false;
  }
}

function check(element, containerSize, direction) {
  return Core__Option.getOr(Core__Option.map(Core__Option.map(element, getElementRect), (function (extra) {
                    return isVisible(containerSize, direction, extra);
                  })), false);
}

var Visibility = {
  isVisible: isVisible,
  check: check
};

function scrollBy(element, scrollBy$1) {
  Core__Option.map(Core__Option.flatMap(element, Webapi__Dom__Element.asHtmlElement), (function (element) {
          element.scrollBy({
                behavior: "smooth",
                left: scrollBy$1,
                top: 0
              });
        }));
}

function isScrolledMax(offset, element) {
  var scrollLeft = Core__Option.getOr(Core__Option.map(element, (function (prim) {
              return prim.scrollLeft;
            })), 0);
  var scrollWidth = Core__Option.getOr(Core__Option.map(element, (function (prim) {
              return prim.scrollWidth;
            })), 0);
  var sliderWidth = Core__Option.getOr(Core__Option.map(element, (function (prim) {
              return prim.clientWidth;
            })), 0);
  var maxScrollLeftValue = scrollWidth - sliderWidth | 0;
  var isScrolledRightMax = (scrollLeft | 0) >= (maxScrollLeftValue - offset | 0);
  return [
          scrollLeft > offset,
          isScrolledRightMax
        ];
}

var $$window = (typeof window !== 'undefined' ? window : undefined);

var userAgent = (typeof navigator !== 'undefined' ? navigator.userAgent : undefined);

var localStorage = (() => {
    try {
      return window.localStorage.getItem("localStorage")
    } catch (e) {
      return undefined
    }
  });

var platform = (typeof navigator !== 'undefined' && typeof navigator.userAgentData !== 'undefined' ? navigator.userAgentData.platform : undefined);

var $$document = (typeof document !== 'undefined' ? document : undefined);

var body = Core__Option.flatMap($$document, (function ($$document) {
        return Caml_option.nullable_to_opt($$document.querySelector("body"));
      }));

var $$MediaQueryList = {};

var SafeWebApi = {
  $$window: $$window,
  userAgent: userAgent,
  localStorage: localStorage,
  platform: platform,
  $$document: $$document,
  body: body,
  $$MediaQueryList: $$MediaQueryList
};

var React = {};

var _map = {"Escape":"Escape","Tab":"Tab","ShiftTab":"ShiftTab"};

var _revMap = {"Escape":"Escape","Tab":"Tab","ShiftTab":"ShiftTab"};

function keyboardKeyToJs(param) {
  return _map[param];
}

function keyboardKeyFromJs(param) {
  return _revMap[param];
}

function getKey($$event) {
  var key = _revMap[$$event.key];
  var isShiftKeyPressed = $$event.shiftKey;
  if (key !== undefined && key === "Tab" && isShiftKeyPressed) {
    return "ShiftTab";
  } else {
    return key;
  }
}

function isKeyPressed($$event, keyboardKey) {
  return getKey($$event) === keyboardKey;
}

var $$KeyboardEvent = {
  keyboardKeyToJs: keyboardKeyToJs,
  keyboardKeyFromJs: keyboardKeyFromJs,
  getKey: getKey,
  isKeyPressed: isKeyPressed
};

function getAllFocusableElements(element) {
  return element.querySelectorAll("a[href]:not([disabled]):not([tabindex='-1']), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), select:not([disabled])");
}

function getLastElementFocusable(ref) {
  return Core__Option.flatMap(Core__Option.flatMap(Core__Option.map(Core__Option.map(Caml_option.nullable_to_opt(ref.current), getAllFocusableElements), (function (prim) {
                        return Array.prototype.slice.call(prim);
                      })), (function (__x) {
                    return Util_Array.getLastElement(__x);
                  })), Webapi__Dom__Element.ofNode);
}

function getFirstElementFocusableWithElement(element) {
  return Core__Option.flatMap(Util_Array.getFirstElement(Array.prototype.slice.call(element.querySelectorAll("a[href]:not([disabled]):not([tabindex='-1']), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), select:not([disabled])"))), Webapi__Dom__Element.ofNode);
}

function getFirstElementFocusable(ref) {
  return Core__Option.flatMap(Caml_option.nullable_to_opt(ref.current), getFirstElementFocusableWithElement);
}

export {
  getDomRef ,
  defaultRect ,
  getElementRect ,
  getElementSize ,
  Visibility ,
  scrollBy ,
  isScrolledMax ,
  SafeWebApi ,
  React ,
  $$KeyboardEvent ,
  getAllFocusableElements ,
  getLastElementFocusable ,
  getFirstElementFocusableWithElement ,
  getFirstElementFocusable ,
}
/* window Not a pure module */
