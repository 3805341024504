// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Atom_Image_Ds from "../atom/Atom_Image_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Teaser_Title from "../organism/teaser/Organism_Teaser_Title.res.mjs";
import * as Organism_Teaser_Subtitle from "../organism/teaser/Organism_Teaser_Subtitle.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var identifier = Identifiers.Download.item;

var downloadItemIdentifier = Util_Css.createIdentifierSelector([Identifiers.toString(identifier)]);

var titleStyle = Atom_Text_Ds.Teaser.toParent(Atom_Text_Ds.Teaser.make("DownloadTitle"));

var subtitleStyle = Atom_Text_Ds.Teaser.toParent(Atom_Text_Ds.Teaser.make("Subtitle"));

var pictureRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Css.preserveAspectRatioRules("R16_9"));

var pictureAutoSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "NoResizing");

var image = Atom_Image_Ds.view(Atom_Image_Ds.make("StandardTeaser"));

var pictureInitialWidth = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "initial");

var containerInitialWidth = Particle_Screen_Ds.map(image.display, (function (x) {
        if (typeof x !== "object" || x.TAG !== "Fixed") {
          return CssJs.px(0);
        } else {
          return CssJs.px(x._0.width);
        }
      }));

var positionAbsolute = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: {
          NAME: "px",
          VAL: 8
        },
        left: {
          NAME: "px",
          VAL: 8
        }
      }
    }, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: {
          NAME: "px",
          VAL: 4
        },
        left: {
          NAME: "px",
          VAL: 4
        }
      }
    });

var imageBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.Outside.makeBottom("XS"));

function make(param) {
  var match = Context.I18n.use();
  var match$1 = match.labels.common;
  var image = JsxRuntime.jsx(Atom_Image.make, {
        ariaHidden: true,
        alt: "",
        identifier: Caml_option.some(Identifiers.Teaser.img),
        src: param.imageSrc,
        style: "StandardTeaser"
      });
  var picture = JsxRuntime.jsxs(Atom_Cell.make, {
        autoSize: Caml_option.some(pictureAutoSize),
        blockSpace: Caml_option.some(imageBlockSpace),
        width: Caml_option.some(pictureInitialWidth),
        children: [
          image,
          JsxRuntime.jsx(Atom_Cta.Link.make, {
                dataAnchor: true,
                href: param.href,
                label: match$1.download,
                position: Caml_option.some(positionAbsolute),
                style: Atom_Cta_Ds.makeDownloadLinkWithText
              })
        ],
        position: Caml_option.some(Particle_Screen_Constants.positionRelative),
        rules: Caml_option.some(pictureRules)
      });
  return JsxRuntime.jsx(Atom_Box.make, {
              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeNoResizing),
              children: Caml_option.some(JsxRuntime.jsx(Atom_Column.make, {
                        alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                        children: JsxRuntime.jsxs(Atom_Column.make, {
                              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                              children: [
                                picture,
                                JsxRuntime.jsx(Organism_Teaser_Title.make, {
                                      title: param.title,
                                      style: titleStyle,
                                      identifier: Caml_option.some(Identifiers.Teaser.title)
                                    }),
                                JsxRuntime.jsx(Organism_Teaser_Subtitle.make, {
                                      identifier: Caml_option.some(Identifiers.Teaser.subtitle),
                                      subtitle: param.subtitle,
                                      style: subtitleStyle
                                    })
                              ],
                              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexStart)
                            }),
                        innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
                        justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
                      })),
              id: param.id,
              identifier: Caml_option.some(identifier),
              width: Caml_option.some(containerInitialWidth)
            });
}

var imageKind = "StandardTeaser";

export {
  identifier ,
  downloadItemIdentifier ,
  titleStyle ,
  subtitleStyle ,
  imageKind ,
  pictureRules ,
  pictureAutoSize ,
  image ,
  pictureInitialWidth ,
  containerInitialWidth ,
  positionAbsolute ,
  imageBlockSpace ,
  make ,
}
/* downloadItemIdentifier Not a pure module */
