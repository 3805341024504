// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Header from "../../type/Type_Header.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

var logo = [
  "Afp",
  {
    NAME: "ArteVerticalLogo",
    VAL: "Slider"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "HighlightTitle"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "Footer"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "MenuNavigation"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "Header"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "ColoredBox"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "Modal"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "ErrorPage"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "Haiku"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "AndroidReleases"
  },
  {
    NAME: "ArteVerticalLogo",
    VAL: "Shorts"
  },
  "ArteHorizontalLogo",
  "ArtePlaceHolder",
  "ConcertLogo"
];

function makeStyle(colorOpt, shape, viewBox, hover) {
  var color = colorOpt !== undefined ? colorOpt : "White";
  return {
          color: color,
          shape: shape,
          hover: hover,
          viewBox: viewBox,
          wrapper: undefined
        };
}

function makeArteVerticalLogoStyle(colorOpt, hover, height) {
  var color = colorOpt !== undefined ? colorOpt : "Arte";
  var shape = Particle_Screen_Ds.map(height, (function (height) {
          return {
                  TAG: "Rectangle",
                  _0: {
                    TAG: "Full",
                    _0: height / 4 | 0,
                    _1: height
                  }
                };
        }));
  return makeStyle(color, shape, "0 0 8 32", hover);
}

function makeLogo(logo) {
  var exec = function (localLogo) {
    if (typeof localLogo === "object") {
      var variant = localLogo.NAME;
      if (variant === "WithWrapper") {
        var match = localLogo.VAL;
        var init = exec(match[0]);
        return {
                color: init.color,
                shape: init.shape,
                hover: init.hover,
                viewBox: init.viewBox,
                wrapper: match[1]
              };
      }
      if (variant === "ArteVerticalLogo") {
        var match$1 = localLogo.VAL;
        if (match$1 === "Shorts") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 50));
        }
        if (match$1 === "ColoredBox") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 40));
        }
        if (match$1 === "AndroidReleases") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 150));
        }
        if (match$1 === "MenuNavigation") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, 150, undefined, undefined, undefined, 80));
        }
        if (match$1 === "ErrorPage") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, 150, undefined, 200, undefined, 100));
        }
        if (match$1 === "Modal") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 80));
        }
        if (match$1 === "Slider") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 30));
        }
        if (match$1 === "Footer") {
          return makeArteVerticalLogoStyle("Arte", undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 200));
        }
        if (match$1 === "Header") {
          return makeArteVerticalLogoStyle(undefined, "White", Particle_Screen_Ds.make(undefined, undefined, Type_Header.Constants.height.m - Type_Header.Constants.verticalPadding | 0, undefined, undefined, Type_Header.Constants.height.default - Type_Header.Constants.verticalPadding | 0));
        }
        if (match$1 === "Haiku") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, 150, undefined, 100));
        }
        if (match$1 === "HighlightTitle") {
          return makeArteVerticalLogoStyle(undefined, undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, 150, undefined, 120));
        }
        
      }
      
    } else {
      if (localLogo === "ConcertLogo") {
        return makeStyle(undefined, Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 101,
                          _1: 25
                        }
                      }, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 130,
                          _1: 38
                        }
                      }, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 81,
                          _1: 20
                        }
                      }), "30 0 475 140", undefined);
      }
      if (localLogo === "ArteHorizontalLogo") {
        return makeStyle("Arte", Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 100,
                          _1: 25
                        }
                      }, undefined, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 73,
                          _1: 25
                        }
                      }), "0 0 131 32", "White");
      }
      if (localLogo === "Rtve" || localLogo === "Rtbf" || localLogo === "Zdf" || localLogo === "Yle" || localLogo === "Rts" || localLogo === "Rte" || localLogo === "Rai" || localLogo === "Orf" || localLogo === "Ltv" || localLogo === "Lrt" || localLogo === "Ffl" || localLogo === "Ard" || localLogo === "Ceskatelevize") {
        var makePartnerLogoStyle = function (width, viewBox) {
          return makeStyle("LightGrey", Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                          TAG: "Rectangle",
                          _0: {
                            TAG: "Full",
                            _0: width,
                            _1: 42
                          }
                        }), viewBox, undefined);
        };
        if (localLogo === "Ard") {
          return makePartnerLogoStyle(67, "0 -5 67 42");
        } else if (localLogo === "Ffl") {
          return makePartnerLogoStyle(108, "0 0 997.38 212.3");
        } else if (localLogo === "Lrt") {
          return makePartnerLogoStyle(46, "0 0 46 22");
        } else if (localLogo === "Ltv") {
          return makePartnerLogoStyle(91, "0 0 91 27");
        } else if (localLogo === "Orf") {
          return makePartnerLogoStyle(56, "0 -10 56 42");
        } else if (localLogo === "Rai") {
          return makePartnerLogoStyle(34, "0 -4 34 42");
        } else if (localLogo === "Rte") {
          return makePartnerLogoStyle(36, "0 -10 36 42");
        } else if (localLogo === "Rts") {
          return makePartnerLogoStyle(72, "0 -9 72 42");
        } else if (localLogo === "Yle") {
          return makePartnerLogoStyle(35, "0 -3.5 35 42");
        } else if (localLogo === "Zdf") {
          return makePartnerLogoStyle(52, "0 -6 52 42");
        } else if (localLogo === "Rtbf") {
          return makePartnerLogoStyle(63, "0 -12 63 42");
        } else if (localLogo === "Rtve") {
          return makePartnerLogoStyle(57, "0 0 57 30");
        } else {
          return makePartnerLogoStyle(61, "0 -3 61 42");
        }
      }
      if (localLogo === "ArtePlaceHolder") {
        return makeStyle("EmptyTeaserLogo", Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 70,
                          _1: 25
                        }
                      }, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 100,
                          _1: 25
                        }
                      }, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 50,
                          _1: 25
                        }
                      }), "0 0 131 32", undefined);
      }
      if (localLogo === "EuropeanFlag") {
        return makeStyle("White", Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 166,
                          _1: 56
                        }
                      }), "0 0 180 61", undefined);
      }
      if (localLogo === "Afp") {
        return makeStyle(undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        TAG: "Rectangle",
                        _0: {
                          TAG: "Full",
                          _0: 109,
                          _1: 35
                        }
                      }), "0 0 109 35", undefined);
      }
      
    }
    return makeStyle(undefined, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                    TAG: "Square",
                    _0: 32
                  }), "0 0 32 32", undefined);
  };
  return exec(logo);
}

function view(param) {
  return {
          angle: 0,
          color: param.color,
          shape: param.shape,
          hover: Core__Option.map(param.hover, (function (a) {
                  return a;
                })),
          viewBox: param.viewBox,
          wrapper: param.wrapper
        };
}

export {
  logo ,
  makeStyle ,
  makeArteVerticalLogoStyle ,
  makeLogo ,
  view ,
}
/* Type_Header Not a pure module */
