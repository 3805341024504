// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M4 20.805V6H26.31V14.2H24.3221V8.0221H6.0221V18.8404H12.22V20.805H4ZM7.705 8.86988L10.72 11.8799L12.095 10.5049L12.44 14.4549L8.49 14.1049L9.865 12.7299L6.855 9.71988L7.705 8.86988Z",
                      fillRule: "evenodd"
                    }),
                JsxRuntime.jsx("path", {
                      clipRule: "evenodd",
                      d: "M13.525 25.21H29V15.49H13.525V25.21Z",
                      fillRule: "evenodd",
                      stroke: "white",
                      strokeWidth: "1.06383"
                    })
              ]
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
