// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Icon_Ds from "../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

function make(param) {
  var advices = param.advices;
  var advices$1 = advices !== undefined ? JsxRuntime.jsx(Atom_Column.make, {
          blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("S"))),
          children: advices.map(function (param, i) {
                var href = param.href;
                var label = param.label;
                return JsxRuntime.jsx(Atom_Cell.make, {
                            kind: "ItemList",
                            children: (href == null) ? JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(label),
                                    style: Atom_Text_Ds.listItem
                                  }) : JsxRuntime.jsx(Atom_Cta.Link.make, {
                                    href: href,
                                    label: label,
                                    style: Atom_Cta_Ds.searchNoResultsAdvice
                                  })
                          }, i.toString());
              }),
          kind: "List",
          rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.listStyleType("disc")]))
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("M"))),
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      style: Atom_Text_Ds.Search.makeNoResults(Caml_option.some(Atom_Icon_Ds.rocket))
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(param.message)
                            }),
                        advices$1
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S"))
                    })
              ],
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL"))
            });
}

export {
  make ,
}
/* CssJs Not a pure module */
