// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Util_Dom from "../../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Textfield from "../../atom/Atom_Textfield.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Border_Ds from "../../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var textFieldRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.alignSelf("center"),
      CssJs.height({
            NAME: "percent",
            VAL: 100
          })
    ]);

function Organism_SearchNavigation(props) {
  var __numberResultsLabel = props.numberResultsLabel;
  var toggle = props.toggle;
  var state = props.state;
  var placeholder = props.placeholder;
  var onChange = props.onChange;
  var searchNavigation = props.searchNavigation;
  var numberResultsLabel = __numberResultsLabel !== undefined ? __numberResultsLabel : "";
  var ref = React.useRef(null);
  var match = React.useState(function () {
        return "First";
      });
  var setDisplayStatus = match[1];
  var displayStatus = match[0];
  React.useEffect((function () {
          var match = Util_Dom.getDomRef(ref);
          if (displayStatus !== "First") {
            if (match !== undefined) {
              Core__Option.forEach(Webapi__Dom__Element.asHtmlElement(Caml_option.valFromOption(match)), (function (prim) {
                      prim.focus();
                    }));
            }
            
          }
          
        }), [state]);
  var handleOpen = function (evt) {
    setDisplayStatus(function (param) {
          return "Others";
        });
    toggle(evt);
  };
  var rules;
  rules = state === "On" ? Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.flexGrow(2)])) : undefined;
  var tmp;
  tmp = onChange !== undefined ? (
      state === "On" ? JsxRuntime.jsxs(Atom_Cell.make, {
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Cta.activeMedium)),
              children: [
                JsxRuntime.jsx(Atom_Textfield.make, {
                      title: placeholder,
                      value: Core__Option.getOr(props.initialQuery, ""),
                      placeholder: placeholder,
                      onChange: onChange,
                      required: true,
                      displayOrder: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 0)),
                      rules: Caml_option.some(textFieldRules),
                      style: "HeaderSearch",
                      autoFocus: true,
                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                      focusOutline: Particle_Border_Ds.none
                    }),
                JsxRuntime.jsx(Atom_Cta.Button.make, {
                      onClick: toggle,
                      screenReadersLabel: searchNavigation.closeLabel,
                      style: Atom_Cta_Ds.Header.close
                    })
              ],
              rules: rules
            }) : JsxRuntime.jsx(Atom_Cta.Button.make, {
              ctaRef: ref,
              onClick: handleOpen,
              screenReadersLabel: searchNavigation.displayLabel,
              style: Atom_Cta_Ds.Header.search
            })
    ) : JsxRuntime.jsx(Atom_Cta.Link.make, {
          accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "button", undefined),
          ctaRef: ref,
          href: searchNavigation.href,
          style: Atom_Cta_Ds.Header.search
        });
  return JsxRuntime.jsxs(Atom_Cell.make, {
              accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "search", undefined),
              height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
              children: [
                tmp,
                JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                      children: numberResultsLabel,
                      accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "polite", undefined, undefined, undefined, undefined, undefined)
                    })
              ],
              rules: rules
            });
}

var make = Organism_SearchNavigation;

export {
  textFieldRules ,
  make ,
}
/* textFieldRules Not a pure module */
