'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";

function make() {
  var match = React.useState(function () {
        return "Unlocked";
      });
  var setScrollStatus = match[1];
  var enableScrollLock = function (param) {
    Core__Option.map(Core__Option.flatMap(Util_Dom.SafeWebApi.body, Webapi__Dom__Element.asHtmlElement), (function (body) {
            body.style.setProperty("overflow", "hidden", "");
          }));
    setScrollStatus(function (param) {
          return "Locked";
        });
  };
  var disableScrollLock = function (param) {
    Core__Option.map(Core__Option.flatMap(Util_Dom.SafeWebApi.body, Webapi__Dom__Element.asHtmlElement), (function (body) {
            return body.style.removeProperty("overflow");
          }));
    setScrollStatus(function (param) {
          return "Unlocked";
        });
  };
  return {
          scrollStatus: match[0],
          enableScrollLock: enableScrollLock,
          disableScrollLock: disableScrollLock
        };
}

export {
  make ,
}
/* react Not a pure module */
