'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as React from "react";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Caml_splice_call from "@rescript/std/lib/es6/caml_splice_call.js";
import * as Belt_MutableQueue from "@rescript/std/lib/es6/belt_MutableQueue.js";
import * as Belt_MutableMapString from "@rescript/std/lib/es6/belt_MutableMapString.js";

function asyncMode(initialNextPage, query) {
  return {
          initialNextPage: initialNextPage,
          query: query
        };
}

function makeConfig(async, elementsPerChunk, initializeWhen, param) {
  return {
          async: async,
          elementsPerChunk: elementsPerChunk,
          initializeWhen: initializeWhen
        };
}

function getInitialChunk(numberElementPerChunk, elements) {
  var chunks = Util_Array.splitAt(elements, numberElementPerChunk);
  if (chunks !== undefined) {
    return [
            chunks[0],
            chunks[1]
          ];
  } else {
    return [
            elements,
            []
          ];
  }
}

function updateOtherChunks(elements, numberElementsPerChunk) {
  var newOtherChunks = Util_Array.chunk(elements, numberElementsPerChunk);
  var len = newOtherChunks.length;
  if (len !== 1) {
    if (len !== 0) {
      return newOtherChunks;
    } else {
      return ;
    }
  }
  var match = newOtherChunks[0];
  if (match.length !== 0) {
    return newOtherChunks;
  }
  
}

function make(config, data) {
  var dataListeners = Belt_MutableMapString.make();
  var configRef = {
    contents: config
  };
  var match = getInitialChunk(configRef.contents.elementsPerChunk, data);
  var initialData = match[0];
  var otherChunks = updateOtherChunks(match[1], configRef.contents.elementsPerChunk);
  var queryNextPage = {
    contents: Core__Option.mapOr(configRef.contents.async, (function (param) {
            return Promise.resolve("NotReady");
          }), (function (c) {
            return c.query;
          }))
  };
  var otherChunks$1 = Belt_MutableQueue.make();
  if (otherChunks !== undefined) {
    otherChunks.forEach(function (c) {
          Belt_MutableQueue.add(otherChunks$1, c);
        });
  }
  var state = {
    data: initialData,
    status: "NotInitialized",
    nextPage: Core__Option.flatMap(configRef.contents.async, (function (c) {
            return c.initialNextPage;
          }))
  };
  var notifyDataChange = function () {
    Belt_MutableMapString.forEach(dataListeners, (function (param, callback) {
            callback({
                  TAG: "DataUpdated",
                  _0: state.data,
                  _1: state.status
                });
          }));
  };
  var notifyStatusChange = function () {
    Belt_MutableMapString.forEach(dataListeners, (function (param, callback) {
            callback({
                  TAG: "StatusUpdated",
                  _0: state.status
                });
          }));
  };
  var subscribe = function (callback) {
    var key = Uuid.v4();
    Belt_MutableMapString.set(dataListeners, key, callback);
    return key;
  };
  var unSubscribe = function (key) {
    Belt_MutableMapString.remove(dataListeners, key);
  };
  var updateStatus = function () {
    var match = state.status;
    var match$1 = state.nextPage;
    var match$2 = Belt_MutableQueue.isEmpty(otherChunks$1);
    var tmp;
    var exit = 0;
    if (match$1 !== undefined || !match$2) {
      exit = 1;
    } else {
      tmp = "Completed";
    }
    if (exit === 1) {
      switch (match) {
        case "NotInitialized" :
            tmp = "Initialized";
            break;
        case "Completed" :
            tmp = "Completed";
            break;
        default:
          tmp = "Idle";
      }
    }
    state.status = tmp;
  };
  var more = async function () {
    var match = state.status;
    switch (match) {
      case "Initialized" :
      case "Idle" :
          break;
      default:
        return ;
    }
    state.status = "Loading";
    notifyStatusChange();
    var match$1 = Belt_MutableQueue.pop(otherChunks$1);
    var match$2 = state.nextPage;
    if (match$1 !== undefined) {
      Caml_splice_call.spliceObjApply(state.data, "push", [match$1]);
    } else if (match$2 !== undefined) {
      state.status = "FetchingData";
      notifyStatusChange();
      var match$3 = await queryNextPage.contents(Caml_option.valFromOption(match$2));
      if (typeof match$3 !== "object") {
        if (match$3 !== "NotReady") {
          state.nextPage = undefined;
        }
        
      } else {
        var match$4 = match$3._0;
        state.nextPage = match$4.nextPage;
        var newOtherChunks = updateOtherChunks(match$4.data, configRef.contents.elementsPerChunk);
        if (newOtherChunks !== undefined) {
          newOtherChunks.forEach(function (c) {
                Belt_MutableQueue.add(otherChunks$1, c);
              });
          var newChunkToDisplay = Belt_MutableQueue.pop(otherChunks$1);
          if (newChunkToDisplay !== undefined) {
            Caml_splice_call.spliceObjApply(state.data, "push", [newChunkToDisplay]);
          }
          
        }
        
      }
    }
    updateStatus();
    return notifyDataChange();
  };
  var initializeClientSide = async function () {
    var numberElementsRequired = configRef.contents.initializeWhen;
    var predicate;
    if (numberElementsRequired.TAG === "NumberElement") {
      var numberElementsRequired$1 = numberElementsRequired._0;
      predicate = (function (arr) {
          var elementsLength = arr.length;
          return elementsLength >= numberElementsRequired$1;
        });
    } else {
      predicate = numberElementsRequired._0;
    }
    var exec = async function () {
      if (predicate(state.data)) {
        return ;
      }
      var chunks = Belt_MutableQueue.pop(otherChunks$1);
      if (chunks !== undefined) {
        Caml_splice_call.spliceObjApply(state.data, "push", [chunks]);
        return await exec();
      }
      var nextPage = state.nextPage;
      if (nextPage === undefined) {
        return ;
      }
      var match = await queryNextPage.contents(Caml_option.valFromOption(nextPage));
      if (typeof match !== "object") {
        if (match === "NotReady") {
          return await exec();
        } else {
          return ;
        }
      }
      var match$1 = match._0;
      state.nextPage = match$1.nextPage;
      var newOtherChunks = updateOtherChunks(match$1.data, configRef.contents.elementsPerChunk);
      if (newOtherChunks !== undefined) {
        newOtherChunks.forEach(function (c) {
              Belt_MutableQueue.add(otherChunks$1, c);
            });
        return await exec();
      }
      
    };
    await exec();
    updateStatus();
    return notifyDataChange();
  };
  var reset = function (config, data) {
    configRef.contents = config;
    var match = getInitialChunk(configRef.contents.elementsPerChunk, data);
    var otherChunks$2 = updateOtherChunks(match[1], configRef.contents.elementsPerChunk);
    queryNextPage.contents = Core__Option.mapOr(configRef.contents.async, (function (param) {
            return Promise.resolve("NotReady");
          }), (function (c) {
            return c.query;
          }));
    Belt_MutableQueue.clear(otherChunks$1);
    if (otherChunks$2 !== undefined) {
      otherChunks$2.forEach(function (c) {
            Belt_MutableQueue.add(otherChunks$1, c);
          });
    }
    state.data = match[0];
    state.status = "NotInitialized";
    state.nextPage = Core__Option.flatMap(configRef.contents.async, (function (c) {
            return c.initialNextPage;
          }));
    initializeClientSide();
  };
  return {
          subscribe: subscribe,
          unSubscribe: unSubscribe,
          more: (async function () {
              return await more();
            }),
          initializeClientSide: initializeClientSide,
          initialData: initialData,
          reset: reset
        };
}

var Internal = {
  getInitialChunk: getInitialChunk,
  updateOtherChunks: updateOtherChunks,
  make: make
};

function reducer(state, action) {
  if (action.TAG === "Update") {
    return {
            status: action._1,
            data: action._0.slice()
          };
  } else {
    return {
            status: action._0,
            data: state.data
          };
  }
}

function use(config, data) {
  var match = React.useMemo((function () {
          return make(config, data);
        }), []);
  var initializeClientSide = match.initializeClientSide;
  var more = match.more;
  var unSubscribe = match.unSubscribe;
  var subscribe = match.subscribe;
  var more$1 = React.useCallback((function () {
          more();
        }), [more]);
  var match$1 = React.useReducer(reducer, {
        status: "Loading",
        data: match.initialData
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  React.useEffect((function () {
          var subscribeId = subscribe(function ($$event) {
                if ($$event.TAG === "DataUpdated") {
                  return dispatch({
                              TAG: "Update",
                              _0: $$event._0,
                              _1: $$event._1
                            });
                } else {
                  return dispatch({
                              TAG: "UpdateStatus",
                              _0: $$event._0
                            });
                }
              });
          return (function () {
                    unSubscribe(subscribeId);
                  });
        }), [
        subscribe,
        unSubscribe
      ]);
  React.useEffect((function () {
          initializeClientSide();
        }), [initializeClientSide]);
  return {
          loadMore: more$1,
          status: state.status,
          data: state.data,
          reset: match.reset
        };
}

export {
  asyncMode ,
  makeConfig ,
  Internal ,
  reducer ,
  use ,
}
/* uuid Not a pure module */
