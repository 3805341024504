'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../design-system/atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../design-system/atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as Atom_Image from "../../../design-system/atom/Atom_Image.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Type_Action from "../../../type/Type_Action.res.mjs";
import * as Atom_Divider from "../../../design-system/atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../design-system/particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../../../design-system/particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

function make(param) {
  var redirect = param.redirect;
  var match = Context.I18n.use();
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var match$2 = labels.mona.newsletter;
  var action = Type_Action.makeAction("SubscribeToNewsletter");
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: match$2.title,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              avatarDisplay: "Show",
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    children: [
                      JsxRuntime.jsxs(Atom_Row.make, {
                            alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                            background: Caml_option.some(Particle_Background_Ds.eventZone),
                            blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"))),
                            children: [
                              JsxRuntime.jsx(Atom_Cell.make, {
                                    children: JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                                          alt: "newsletter",
                                          style: {
                                            TAG: "FromCdn",
                                            _0: {
                                              TAG: "Newsletter",
                                              _0: match.locale
                                            }
                                          }
                                        }),
                                    rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.height(CssJs.px(74))]))
                                  }),
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(match$2.description),
                                    style: Atom_Text_Ds.User.text("MyAccount")
                                  })
                            ],
                            innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")),
                            width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                            wrap: "OnOneLine"
                          }),
                      JsxRuntime.jsxs(Atom_Column.make, {
                            children: [
                              JsxRuntime.jsx(Atom_Cta.Action.Toggle.make, {
                                    action: action._0,
                                    label: match$2.cta,
                                    variant: "Auth"
                                  }),
                              JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                    label: match$2.secondaryCta,
                                    onClick: (function (param) {
                                        redirect();
                                      }),
                                    variant: "link"
                                  })
                            ],
                            innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M"))
                          }),
                      JsxRuntime.jsx(Atom_Divider.make, {
                            style: Atom_Divider.user
                          })
                    ],
                    innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
                    width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
                  })
            });
}

export {
  make ,
}
/* CssJs Not a pure module */
