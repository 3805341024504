// Generated by ReScript, PLEASE EDIT WITH CARE


function default_confirmation(value1, value2) {
  return value1 + " and " + value2 + " don't match";
}

function default_email(value) {
  return value + " is not a valid email";
}

function default_stringNonEmpty(param) {
  return "String must not be empty";
}

function default_eitherStringNonEmpty(param, param$1) {
  return "One of the fields must not be empty";
}

function default_stringRegExp(_value, pattern) {
  return "This value must match the following: /" + pattern + "/";
}

function default_stringMin(_value, min) {
  return "This value must be at least " + String(min) + " characters";
}

function default_stringMax(_value, max) {
  return "This value must be at most " + String(max) + " characters";
}

var $$default = {
  confirmation: default_confirmation,
  email: default_email,
  stringNonEmpty: default_stringNonEmpty,
  eitherStringNonEmpty: default_eitherStringNonEmpty,
  stringRegExp: default_stringRegExp,
  stringMin: default_stringMin,
  stringMax: default_stringMax,
  date: "Invalid date",
  required: "This field is required",
  password: "The password must contain at least 8 characters including lowercase, uppercase, number and special character."
};

export {
  $$default as default,
}
/* No side effect */
