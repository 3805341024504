// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Particle_Color_Ds from "./Particle_Color_Ds.res.mjs";

function toCss(colorStyle) {
  var color = Particle_Color_Ds.view(colorStyle);
  return CssJs.hsla(CssJs.deg(color.h), CssJs.pct(color.s), CssJs.pct(color.l), {
              NAME: "num",
              VAL: color.a
            });
}

export {
  toCss ,
}
/* CssJs Not a pure module */
