import { type Autoplay as ArteVpAutoPlay, type Mode } from '@artegeie/arte-vp';
import { type Locale } from './Locale';
import { type VideoKind } from '@artegeie/design-system/type';

export type FromPage = 'direct' | 'other';
export type Autoplay = ArteVpAutoPlay;

// this enum  map the Players' autoplay props
export enum AutoPlayStatus {
    //Depending on your browser autoplay settings, it will at least start whitout sound
    START_MUTED = 'true',
    IF_AVAILABLE = 'if_available',
    DEACTIVATED = 'false',
}

export const autoplayToBool = (autoplay: Autoplay) => {
    if (autoplay === AutoPlayStatus.START_MUTED || autoplay === AutoPlayStatus.IF_AVAILABLE) {
        return true;
    }

    return false;
};

export const autoplayFromBool = (b: boolean) => (b ? AutoPlayStatus.IF_AVAILABLE : AutoPlayStatus.DEACTIVATED);

export const getAutoplayFromVideoKind = (videoKind: VideoKind) =>
    videoKind === 'clip' || videoKind === 'preview' ? AutoPlayStatus.START_MUTED : AutoPlayStatus.IF_AVAILABLE;

export const getAutoplayFromDomain = (domain: FromPage, directAutoplay?: boolean) => {
    if (domain !== 'direct') return AutoPlayStatus.IF_AVAILABLE;
    if (directAutoplay) return AutoPlayStatus.START_MUTED;
    return AutoPlayStatus.DEACTIVATED;
};

export const getHigherAutoplay = (autoplay1: Autoplay, autoplay2: Autoplay) => {
    if (autoplay1 === AutoPlayStatus.START_MUTED || autoplay2 === AutoPlayStatus.START_MUTED) {
        return AutoPlayStatus.START_MUTED;
    }
    if (autoplay1 === AutoPlayStatus.IF_AVAILABLE || autoplay2 === AutoPlayStatus.IF_AVAILABLE) {
        return AutoPlayStatus.IF_AVAILABLE;
    }
    return AutoPlayStatus.DEACTIVATED;
};

export const localeToLang = (locale: Locale): string => {
    return `${locale.toLowerCase()}_${locale.toUpperCase()}`;
};

const dict: Record<VideoKind, Mode> = {
    clip: 'trailer',
    program: 'default',
    trailer: 'trailer',
    preview: 'light',
};
export const modeFromVideoKind = (videoKind: VideoKind): Mode => dict[videoKind];
