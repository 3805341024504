import { matchResult, Result } from './Result';
import { Locale } from './Locale';

const getTypeKeyFromUnknown = (obj: unknown): string | undefined => {
    const type = !!obj && typeof obj === 'object' && (obj as unknown as Record<string, unknown>)['type'];
    if (typeof type === 'string') {
        return type;
    }
};

export const isJsonError = (json: unknown): json is JsonError => {
    return getTypeKeyFromUnknown(json) === 'Json';
};
export const isTimoutError = (json: unknown): json is TimeoutError => {
    return getTypeKeyFromUnknown(json) === 'Timeout';
};
export const isHttpError = (error: unknown): error is HttpError => {
    return getTypeKeyFromUnknown(error) === 'Http';
};
export const isUnknownError = (error: unknown): error is UnknownError => {
    return getTypeKeyFromUnknown(error) === 'Unknown';
};

export const isAbortedByConsumerError = (error: unknown): error is AbortedByConsumerError => {
    return getTypeKeyFromUnknown(error) === 'AbortedByConsumer';
};

export type HttpErrors =
    | 'BadRequest'
    | 'NotFound'
    | 'InternalServerError'
    | 'ServiceUnavailable'
    | 'Forbidden'
    | 'Unauthorized'
    | 'TooManyRequests'
    | 'Unknown'
    | 'MethodNotAllowed';

export type StatusCode = 400 | 401 | 403 | 404 | 405 | 429 | 500 | 503;

const httpErrors: Record<StatusCode, HttpErrors> = {
    400: 'BadRequest',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'NotFound',
    405: 'MethodNotAllowed',
    429: 'TooManyRequests',
    500: 'InternalServerError',
    503: 'ServiceUnavailable',
};

const isStatusCode = (statusCode: number): statusCode is StatusCode => {
    return statusCode in httpErrors;
};

export const fromStatusCode = (errorCode: number): HttpErrors => {
    if (isStatusCode(errorCode)) {
        return httpErrors[errorCode];
    }
    return 'Unknown';
};

type BaseError = {
    url: string;
    stack: string | null;
    'x-correlation-id': string | undefined;
};

export type HttpError = BaseError & {
    type: 'Http';
    statusCode: number;
    codeDefinition: HttpErrors;
};

export type JsonError = BaseError & {
    type: 'Json';
    value: string;
};

export type TimeoutError = BaseError & {
    type: 'Timeout';
    numberRetry: number;
};

export type AbortedByConsumerError = BaseError & {
    type: 'AbortedByConsumer';
};

export type UnknownError = BaseError & {
    type: 'Unknown';
    message: string;
};

export type FetchError = HttpError | JsonError | TimeoutError | AbortedByConsumerError | UnknownError;

declare global {
    interface GlobalError {
        Unknown: {
            type: 'Unknown';
            err: {
                type: 'Unknown';
                stack: string | null;
            };
        };
        ConcertPage: {
            type: 'ConcertPage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        GuidePage: {
            type: 'GuidePage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        ProgramPage: {
            type: 'ProgramPage';
            err:
                | {
                      type: 'NoProgramFound';
                      stack: string | null;
                  }
                | {
                      type: 'NoData';
                      stack: string | null;
                  };
        };
        VideoPage: {
            type: 'VideoPage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        HomePage: {
            type: 'HomePage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        EventPage: {
            type: 'EventPage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        MyVideosPage: {
            type: 'MyVideosPage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        MyVideosFavoritePage: {
            type: 'MyVideosFavoritePage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        MyVideosLastViewedPage: {
            type: 'MyVideosLastViewedPage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };
        MyVideosResumePage: {
            type: 'MyVideosResumePage';
            err: {
                type: 'NoData';
                locale: Locale;
            };
        };

        ApiPlayer: {
            type: 'ApiPlayer';
            err: {
                type: 'ApiPlayerError';
                code: string;
                title: string;
                message: string;
            };
        };
        Fetch: {
            type: 'Fetch';
            err: FetchError;
        };
    }
}

export type ReplayError = {
    [ErrorType in keyof GlobalError]: GlobalError[ErrorType];
}[keyof GlobalError];

export const unknownError = (): GlobalError['Unknown'] => {
    return {
        type: 'Unknown',
        err: {
            type: 'Unknown',
            stack: new global.Error().stack || null,
        },
    };
};

const getHttpStatusCodeFromError = (error: ReplayError) => {
    if (isHttpError(error.err)) {
        return error.err.statusCode;
    }
    return 500;
};

export const getHttpStatusCode = (result: Result<unknown, ReplayError>) => {
    return matchResult(result, () => 200, getHttpStatusCodeFromError);
};

const errorTypeToCode = (error: ReplayError): string => {
    switch (error?.type) {
        case 'Unknown':
            return '0';
        case 'Fetch':
            return '1';
        case 'ApiPlayer':
            return '2';
        case 'ConcertPage':
            return '3';
        case 'GuidePage':
            return '4';
        case 'ProgramPage':
            return '5';
        case 'VideoPage':
            return '6';
        case 'HomePage':
            return '7';
        case 'EventPage':
            return '8';
        case 'MyVideosPage':
            return '9';
        case 'MyVideosFavoritePage':
            return '10';
        case 'MyVideosLastViewedPage':
            return '11';
        case 'MyVideosResumePage':
            return '12';
        default:
            return 'X';
    }
};

const errorKindToCode = (error: ReplayError): string => {
    switch (error?.err?.type) {
        case 'NoData':
            return '0';
        case 'NoProgramFound':
            return '1';
        case 'ApiPlayerError':
            return '2';
        case 'Http':
            return '3';
        case 'Json':
            return '4';
        case 'Timeout':
            return '5';
        case 'AbortedByConsumer':
            return '6';
        case 'Unknown':
            return '7';
        default:
            return 'X';
    }
};

export const errorToCode = (error: ReplayError): string => {
    return errorKindToCode(error) + errorTypeToCode(error);
};
