// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Screen_Ds from "./Particle_Screen_Ds.res.mjs";
import * as Particle_ImageCdn_Ds from "./Particle_ImageCdn_Ds.res.mjs";

function slider(theme) {
  if (typeof theme === "object") {
    return {
            color: "Grey",
            opacity: undefined,
            image: undefined
          };
  } else if (theme === "Afp") {
    return {
            color: "Afp",
            opacity: undefined,
            image: undefined
          };
  } else if (theme === "User" || theme === "Classic" || theme === "Search") {
    return {
            color: "Transparent",
            opacity: undefined,
            image: undefined
          };
  } else {
    return {
            color: "White",
            opacity: undefined,
            image: undefined
          };
  }
}

function activeBullet(color) {
  var color$1 = color === "Default" ? "Arte" : color;
  return {
          color: color$1,
          opacity: undefined,
          image: undefined
        };
}

function background500(cdnUrl) {
  return {
          color: "Transparent",
          opacity: undefined,
          image: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                    cdnUrl: cdnUrl,
                    path: "Background500",
                    kind: {
                      TAG: "Normal",
                      _0: "Auto"
                    }
                  }))
        };
}

var Oops_cta404 = {
  color: "DarkGrey",
  opacity: undefined,
  image: undefined
};

var Oops = {
  background500: background500,
  cta404: Oops_cta404
};

var AndroidReleases_hero = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var AndroidReleases_heroTutorial = {
  color: "Background",
  opacity: undefined,
  image: undefined
};

var AndroidReleases_releaseNote = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var AndroidReleases_ctaTutorial = {
  color: "Arte",
  opacity: undefined,
  image: undefined
};

var AndroidReleases = {
  hero: AndroidReleases_hero,
  heroTutorial: AndroidReleases_heroTutorial,
  releaseNote: AndroidReleases_releaseNote,
  ctaTutorial: AndroidReleases_ctaTutorial
};

function newsletterZone(cdnUrl) {
  return {
          color: "Grey",
          opacity: undefined,
          image: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Caml_option.some({
                        cdnUrl: cdnUrl,
                        path: "NewsletterZone",
                        kind: {
                          TAG: "Normal",
                          _0: "Cover"
                        }
                      }), undefined, undefined, undefined))
        };
}

var Newsletter = {
  newsletterZone: newsletterZone
};

var Corporate = {
  menu: {
    color: "CorporateMenu",
    opacity: undefined,
    image: undefined
  }
};

var Presse_menu = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var Presse_disabledButton = {
  color: "Divider",
  opacity: undefined,
  image: undefined
};

var Presse_button = {
  color: "Arte",
  opacity: undefined,
  image: undefined
};

var Presse_secondaryButton = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var Presse_tooltip = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var Presse = {
  menu: Presse_menu,
  disabledButton: Presse_disabledButton,
  button: Presse_button,
  secondaryButton: Presse_secondaryButton,
  tooltip: Presse_tooltip
};

function makeImage(backgroundImage) {
  if (backgroundImage === undefined) {
    return ;
  }
  var cdnUrl = Particle_ImageCdn_Ds.make(backgroundImage.path, backgroundImage.cdnUrl);
  return {
          cdnUrl: cdnUrl,
          position: {
            x: 0,
            y: 0
          },
          size: backgroundImage.kind._0,
          repeat: "noRepeat",
          width: undefined,
          height: undefined,
          hoverOpacity: undefined
        };
}

function view(param) {
  return {
          color: param.color,
          opacity: param.opacity,
          image: Core__Option.map(param.image, (function (__x) {
                  return Particle_Screen_Ds.map(__x, makeImage);
                }))
        };
}

var borderStoryBackground = {
  color: "LightGrey",
  opacity: undefined,
  image: undefined
};

var audioDescription = {
  color: "AudioDescription",
  opacity: undefined,
  image: undefined
};

var avatarSticker = {
  color: "AvatarStickerBackground",
  opacity: undefined,
  image: undefined
};

var background = {
  color: "Background",
  opacity: undefined,
  image: undefined
};

var profileBackground = {
  color: "Black",
  opacity: undefined,
  image: undefined
};

var badge = {
  color: "Arte",
  opacity: undefined,
  image: undefined
};

var buttonSolid = {
  color: "Primary",
  opacity: undefined,
  image: undefined
};

var danger = {
  color: "Error",
  opacity: undefined,
  image: undefined
};

var disabledButtonUser = {
  color: "Divider",
  opacity: undefined,
  image: undefined
};

var disabledTeaser = {
  color: "Grey",
  opacity: 0.7,
  image: undefined
};

var dropdownMenu = {
  color: "DarkGrey",
  opacity: undefined,
  image: undefined
};

var emptyGuideTeaser = {
  color: "EmptyTeaserBackground",
  opacity: undefined,
  image: undefined
};

var eventZone = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var eventZoneOverlay = {
  color: "Transparent",
  opacity: 1,
  image: undefined
};

var footer = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var geoblocking = {
  color: "Geoblocking",
  opacity: undefined,
  image: undefined
};

var header = {
  color: "Header",
  opacity: undefined,
  image: undefined
};

var headerUser = {
  color: "AccountHeader",
  opacity: undefined,
  image: undefined
};

var imagePlaceholder = {
  color: "Placeholder",
  opacity: undefined,
  image: undefined
};

var info = {
  color: "Arte",
  opacity: undefined,
  image: undefined
};

var lastChance = {
  color: "LastChance",
  opacity: undefined,
  image: undefined
};

var magazines = {
  color: "Emissions",
  opacity: undefined,
  image: undefined
};

var modalOverlay = {
  color: "Geoblocking",
  opacity: undefined,
  image: undefined
};

var playerOverlay = {
  color: "Black",
  opacity: undefined,
  image: undefined
};

var mostRecent = {
  color: "MostRecent",
  opacity: undefined,
  image: undefined
};

var mostViewed = {
  color: "Trending",
  opacity: undefined,
  image: undefined
};

var orange = {
  color: "Arte",
  opacity: undefined,
  image: undefined
};

var playerPlaceholder = {
  color: "Black",
  opacity: undefined,
  image: undefined
};

var playerPlayIcon = {
  color: "Black",
  opacity: 0.7,
  image: undefined
};

var playNext = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var primary = {
  color: "Primary",
  opacity: undefined,
  image: undefined
};

var secondary = {
  color: "Secondary",
  opacity: undefined,
  image: undefined
};

var selectGenreButtonSelected = {
  color: "Primary",
  opacity: undefined,
  image: undefined
};

var selectConcertGenre = {
  color: "DarkGrey",
  opacity: 1,
  image: undefined
};

var success = {
  color: "Success",
  opacity: undefined,
  image: undefined
};

var switchActive = {
  color: "ArteLight",
  opacity: undefined,
  image: undefined
};

var switchCircleActive = {
  color: "Arte",
  opacity: undefined,
  image: undefined
};

var switchCircleInactive = {
  color: "SwitchInactiveCircle",
  opacity: undefined,
  image: undefined
};

var switchCircleInactiveProgram = {
  color: "White",
  opacity: undefined,
  image: undefined
};

var switchInactive = {
  color: "SwitchInactiveBackground",
  opacity: undefined,
  image: undefined
};

var switchInactiveProgram = {
  color: "DividerStatic",
  opacity: undefined,
  image: undefined
};

var switchCircleTheme = {
  color: "Primary",
  opacity: undefined,
  image: undefined
};

var tags = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var teaserClassic = {
  color: "TeaserClassicBackground",
  opacity: undefined,
  image: undefined
};

var teaserCollection = {
  color: "Background",
  opacity: undefined,
  image: undefined
};

var teaserInfo = {
  color: "TeaserInfoBackground",
  opacity: undefined,
  image: undefined
};

var teaserPlayNext = {
  color: "Grey",
  opacity: undefined,
  image: undefined
};

var teaserHoverNewOverlay = {
  color: "TeaserHover",
  opacity: undefined,
  image: undefined
};

var teaserSticker = {
  color: "White",
  opacity: undefined,
  image: undefined
};

var timeSticker = {
  color: "Black",
  opacity: undefined,
  image: undefined
};

var tooltip = {
  color: "Black",
  opacity: undefined,
  image: undefined
};

var transparent = {
  color: "Transparent",
  opacity: undefined,
  image: undefined
};

var userNavigationItemListCurrentUser = {
  color: "DarkGrey",
  opacity: undefined,
  image: undefined
};

var subHeader = {
  color: "SubHeaderBackground",
  opacity: undefined,
  image: undefined
};

var userPanel = {
  color: "DarkGrey",
  opacity: undefined,
  image: undefined
};

var white = {
  color: "White",
  opacity: undefined,
  image: undefined
};

var black = {
  color: "Black",
  opacity: undefined,
  image: undefined
};

var toggleCorporateButton = {
  color: "White",
  opacity: undefined,
  image: undefined
};

export {
  borderStoryBackground ,
  activeBullet ,
  audioDescription ,
  avatarSticker ,
  background ,
  profileBackground ,
  badge ,
  buttonSolid ,
  danger ,
  disabledButtonUser ,
  disabledTeaser ,
  dropdownMenu ,
  emptyGuideTeaser ,
  eventZone ,
  eventZoneOverlay ,
  footer ,
  geoblocking ,
  header ,
  headerUser ,
  imagePlaceholder ,
  info ,
  lastChance ,
  magazines ,
  modalOverlay ,
  playerOverlay ,
  mostRecent ,
  mostViewed ,
  orange ,
  playerPlaceholder ,
  playerPlayIcon ,
  playNext ,
  primary ,
  secondary ,
  selectGenreButtonSelected ,
  selectConcertGenre ,
  slider ,
  success ,
  switchActive ,
  switchCircleActive ,
  switchCircleInactive ,
  switchCircleInactiveProgram ,
  switchInactive ,
  switchInactiveProgram ,
  switchCircleTheme ,
  tags ,
  teaserClassic ,
  teaserCollection ,
  teaserInfo ,
  teaserPlayNext ,
  teaserHoverNewOverlay ,
  teaserSticker ,
  timeSticker ,
  tooltip ,
  transparent ,
  userNavigationItemListCurrentUser ,
  subHeader ,
  userPanel ,
  white ,
  black ,
  Oops ,
  AndroidReleases ,
  Newsletter ,
  toggleCorporateButton ,
  Corporate ,
  Presse ,
  view ,
}
/* No side effect */
