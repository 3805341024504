// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Js_json from "@rescript/std/lib/es6/js_json.js";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as MyNullable from "../MyNullable.res.mjs";
import * as Type_Image from "../type/Type_Image.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Type_Avatar from "../type/Type_Avatar.res.mjs";
import * as Type_Common from "../type/Type_Common.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Type_Teaser from "../type/Type_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Pattern from "../type/Type_Pattern.res.mjs";
import * as Type_Sticker from "../type/Type_Sticker.res.mjs";
import * as Type_Progression from "../type/Type_Progression.res.mjs";

function lastviewed_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "is",
                    false,
                    MyNullable.t_encode(Spice.boolToJson)(v.is)
                  ],
                  [
                    "timecode",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.timecode)
                  ],
                  [
                    "progress",
                    false,
                    MyNullable.t_encode(Spice.floatToJson)(v.progress)
                  ]
                ]));
}

function lastviewed_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var is = MyNullable.t_decode(Spice.boolFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "is"), null));
  if (is.TAG === "Ok") {
    var timecode = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "timecode"), null));
    if (timecode.TAG === "Ok") {
      var progress = MyNullable.t_decode(Spice.floatFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "progress"), null));
      if (progress.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  is: is._0,
                  timecode: timecode._0,
                  progress: progress._0
                }
              };
      }
      var e = progress._0;
      return {
              TAG: "Error",
              _0: {
                path: ".progress" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = timecode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".timecode" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = is._0;
  return {
          TAG: "Error",
          _0: {
            path: ".is" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function type_encode(v) {
  return ["teaser"];
}

function type_decode(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString" && match._0 === "teaser") {
    if (tagged.length !== 1) {
      return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
    } else {
      return {
              TAG: "Ok",
              _0: "teaser"
            };
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

function alternateResolution_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "width",
                    false,
                    Spice.intToJson(v.width)
                  ],
                  [
                    "height",
                    false,
                    Spice.intToJson(v.height)
                  ]
                ]));
}

function alternateResolution_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var width = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
    if (width.TAG === "Ok") {
      var height = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
      if (height.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  url: url._0,
                  width: width._0,
                  height: height._0
                }
              };
      }
      var e = height._0;
      return {
              TAG: "Error",
              _0: {
                path: ".height" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = width._0;
    return {
            TAG: "Error",
            _0: {
              path: ".width" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function image_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "width",
                    false,
                    Spice.intToJson(v.width)
                  ],
                  [
                    "height",
                    false,
                    Spice.intToJson(v.height)
                  ],
                  [
                    "alternateResolutions",
                    false,
                    (function (extra) {
                          return Spice.arrayToJson(alternateResolution_encode, extra);
                        })(v.alternateResolutions)
                  ]
                ]));
}

function image_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var width = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "width"), null));
    if (width.TAG === "Ok") {
      var height = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "height"), null));
      if (height.TAG === "Ok") {
        var alternateResolutions = (function (extra) {
              return Spice.arrayFromJson(alternateResolution_decode, extra);
            })(Belt_Option.getWithDefault(Js_dict.get(v, "alternateResolutions"), null));
        if (alternateResolutions.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    url: url._0,
                    width: width._0,
                    height: height._0,
                    alternateResolutions: alternateResolutions._0
                  }
                };
        }
        var e = alternateResolutions._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".alternateResolutions" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = height._0;
      return {
              TAG: "Error",
              _0: {
                path: ".height" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = width._0;
    return {
            TAG: "Error",
            _0: {
              path: ".width" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function favorite_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "is",
                    false,
                    MyNullable.t_encode(Spice.boolToJson)(v.is)
                  ]]));
}

function favorite_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var is = MyNullable.t_decode(Spice.boolFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "is"), null));
  if (is.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              is: is._0
            }
          };
  }
  var e = is._0;
  return {
          TAG: "Error",
          _0: {
            path: ".is" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.id)
                  ],
                  [
                    "kind",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.kind)
                  ],
                  [
                    "programId",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.programId)
                  ],
                  [
                    "url",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.url)
                  ],
                  [
                    "title",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.title)
                  ],
                  [
                    "subtitle",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.subtitle)
                  ],
                  [
                    "images",
                    false,
                    MyNullable.t_encode(function (extra) {
                            return Spice.arrayToJson(image_encode, extra);
                          })(v.images)
                  ],
                  [
                    "markings",
                    false,
                    MyNullable.t_encode(Type_Common.stickers_encode)(v.markings)
                  ],
                  [
                    "duration",
                    false,
                    MyNullable.t_encode(Type_Common.duration_in_min_from_js_encode)(v.duration)
                  ],
                  [
                    "durationLabel",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.durationLabel)
                  ],
                  [
                    "description",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.description)
                  ],
                  [
                    "isSubscribed",
                    false,
                    MyNullable.t_encode(Spice.boolToJson)(v.isSubscribed)
                  ],
                  [
                    "lastviewed",
                    false,
                    MyNullable.t_encode(lastviewed_encode)(v.lastviewed)
                  ],
                  [
                    "favorite",
                    false,
                    MyNullable.t_encode(favorite_encode)(v.favorite)
                  ],
                  [
                    "trackingPixel",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.trackingPixel)
                  ],
                  [
                    "location",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.location)
                  ],
                  [
                    "startDate",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.endDate)
                  ],
                  [
                    "participationEndDate",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.participationEndDate)
                  ],
                  [
                    "disclamerLink",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.disclamerLink)
                  ],
                  [
                    "specialTxt",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.specialTxt)
                  ],
                  [
                    "eventShareUrl",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.eventShareUrl)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var kind = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "kind"), null));
    if (kind.TAG === "Ok") {
      var programId = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "programId"), null));
      if (programId.TAG === "Ok") {
        var url = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
        if (url.TAG === "Ok") {
          var title = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
          if (title.TAG === "Ok") {
            var subtitle = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "subtitle"), null));
            if (subtitle.TAG === "Ok") {
              var images = MyNullable.t_decode(function (extra) {
                      return Spice.arrayFromJson(image_decode, extra);
                    })(Belt_Option.getWithDefault(Js_dict.get(v, "images"), null));
              if (images.TAG === "Ok") {
                var markings = MyNullable.t_decode(Type_Common.stickers_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "markings"), null));
                if (markings.TAG === "Ok") {
                  var duration = MyNullable.t_decode(Type_Common.duration_in_min_from_js_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "duration"), null));
                  if (duration.TAG === "Ok") {
                    var durationLabel = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "durationLabel"), null));
                    if (durationLabel.TAG === "Ok") {
                      var description = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
                      if (description.TAG === "Ok") {
                        var isSubscribed = MyNullable.t_decode(Spice.boolFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "isSubscribed"), null));
                        if (isSubscribed.TAG === "Ok") {
                          var lastviewed = MyNullable.t_decode(lastviewed_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "lastviewed"), null));
                          if (lastviewed.TAG === "Ok") {
                            var favorite = MyNullable.t_decode(favorite_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "favorite"), null));
                            if (favorite.TAG === "Ok") {
                              var trackingPixel = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "trackingPixel"), null));
                              if (trackingPixel.TAG === "Ok") {
                                var $$location = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
                                if ($$location.TAG === "Ok") {
                                  var startDate = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
                                  if (startDate.TAG === "Ok") {
                                    var endDate = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
                                    if (endDate.TAG === "Ok") {
                                      var participationEndDate = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "participationEndDate"), null));
                                      if (participationEndDate.TAG === "Ok") {
                                        var disclamerLink = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "disclamerLink"), null));
                                        if (disclamerLink.TAG === "Ok") {
                                          var specialTxt = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "specialTxt"), null));
                                          if (specialTxt.TAG === "Ok") {
                                            var eventShareUrl = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "eventShareUrl"), null));
                                            if (eventShareUrl.TAG === "Ok") {
                                              return {
                                                      TAG: "Ok",
                                                      _0: {
                                                        id: id._0,
                                                        kind: kind._0,
                                                        programId: programId._0,
                                                        url: url._0,
                                                        title: title._0,
                                                        subtitle: subtitle._0,
                                                        images: images._0,
                                                        markings: markings._0,
                                                        duration: duration._0,
                                                        durationLabel: durationLabel._0,
                                                        description: description._0,
                                                        isSubscribed: isSubscribed._0,
                                                        lastviewed: lastviewed._0,
                                                        favorite: favorite._0,
                                                        trackingPixel: trackingPixel._0,
                                                        location: $$location._0,
                                                        startDate: startDate._0,
                                                        endDate: endDate._0,
                                                        participationEndDate: participationEndDate._0,
                                                        disclamerLink: disclamerLink._0,
                                                        specialTxt: specialTxt._0,
                                                        eventShareUrl: eventShareUrl._0
                                                      }
                                                    };
                                            }
                                            var e = eventShareUrl._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".eventShareUrl" + e.path,
                                                      message: e.message,
                                                      value: e.value
                                                    }
                                                  };
                                          }
                                          var e$1 = specialTxt._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".specialTxt" + e$1.path,
                                                    message: e$1.message,
                                                    value: e$1.value
                                                  }
                                                };
                                        }
                                        var e$2 = disclamerLink._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".disclamerLink" + e$2.path,
                                                  message: e$2.message,
                                                  value: e$2.value
                                                }
                                              };
                                      }
                                      var e$3 = participationEndDate._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".participationEndDate" + e$3.path,
                                                message: e$3.message,
                                                value: e$3.value
                                              }
                                            };
                                    }
                                    var e$4 = endDate._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".endDate" + e$4.path,
                                              message: e$4.message,
                                              value: e$4.value
                                            }
                                          };
                                  }
                                  var e$5 = startDate._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".startDate" + e$5.path,
                                            message: e$5.message,
                                            value: e$5.value
                                          }
                                        };
                                }
                                var e$6 = $$location._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".location" + e$6.path,
                                          message: e$6.message,
                                          value: e$6.value
                                        }
                                      };
                              }
                              var e$7 = trackingPixel._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".trackingPixel" + e$7.path,
                                        message: e$7.message,
                                        value: e$7.value
                                      }
                                    };
                            }
                            var e$8 = favorite._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".favorite" + e$8.path,
                                      message: e$8.message,
                                      value: e$8.value
                                    }
                                  };
                          }
                          var e$9 = lastviewed._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".lastviewed" + e$9.path,
                                    message: e$9.message,
                                    value: e$9.value
                                  }
                                };
                        }
                        var e$10 = isSubscribed._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".isSubscribed" + e$10.path,
                                  message: e$10.message,
                                  value: e$10.value
                                }
                              };
                      }
                      var e$11 = description._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".description" + e$11.path,
                                message: e$11.message,
                                value: e$11.value
                              }
                            };
                    }
                    var e$12 = durationLabel._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".durationLabel" + e$12.path,
                              message: e$12.message,
                              value: e$12.value
                            }
                          };
                  }
                  var e$13 = duration._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".duration" + e$13.path,
                            message: e$13.message,
                            value: e$13.value
                          }
                        };
                }
                var e$14 = markings._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".markings" + e$14.path,
                          message: e$14.message,
                          value: e$14.value
                        }
                      };
              }
              var e$15 = images._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".images" + e$15.path,
                        message: e$15.message,
                        value: e$15.value
                      }
                    };
            }
            var e$16 = subtitle._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".subtitle" + e$16.path,
                      message: e$16.message,
                      value: e$16.value
                    }
                  };
          }
          var e$17 = title._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".title" + e$17.path,
                    message: e$17.message,
                    value: e$17.value
                  }
                };
        }
        var e$18 = url._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".url" + e$18.path,
                  message: e$18.message,
                  value: e$18.value
                }
              };
      }
      var e$19 = programId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".programId" + e$19.path,
                message: e$19.message,
                value: e$19.value
              }
            };
    }
    var e$20 = kind._0;
    return {
            TAG: "Error",
            _0: {
              path: ".kind" + e$20.path,
              message: e$20.message,
              value: e$20.value
            }
          };
  }
  var e$21 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$21.path,
            message: e$21.message,
            value: e$21.value
          }
        };
}

function toResolution(images) {
  var transformToResolution = function (images) {
    return Type_Image.Src.makeResolution(Core__Array.reduce(images, [], (function (acc, param) {
                      var innerResolutions = param.alternateResolutions.map(function (param) {
                            return Type_Image.Resolution.make(param.url, param.width, param.height);
                          });
                      var currentResolution = Type_Image.Resolution.make(param.url, param.width, param.height);
                      return [
                                [currentResolution],
                                innerResolutions,
                                acc
                              ].flat();
                    })));
  };
  return Core__Option.getOr(Core__Option.map((images == null) ? undefined : Caml_option.some(images), transformToResolution), Type_Image.Src.empty);
}

function toDsTeaser(minutesLabel, $staropt$star, param) {
  var id = param.id;
  var hoverBehavior = $staropt$star !== undefined ? $staropt$star : "Normal";
  var durationLabelTeaser = Type_String.Teaser.DurationLabel.makeFromNullable(param.durationLabel);
  var duration = Core__Option.map(Caml_option.nullable_to_opt(param.duration), Type_Common.jsMinDurationToDuration);
  var stickers = Core__Option.getOr(Core__Option.map(Caml_option.nullable_to_opt(param.markings), (function (__x) {
              return Type_Sticker.makeFromCommonStickers(undefined, __x);
            })), Type_Sticker.empty);
  var teaserKind = Type_Teaser.Kind.fromStickers(stickers);
  var stickers$1 = Type_Teaser.Helper.addClubDurationSticker((function (extra) {
          return Type_Teaser.Helper.getDurationLabel(minutesLabel, extra);
        }), duration, teaserKind, stickers);
  var programId = Type_String.Program.Id.makeFromNullable(param.programId);
  var teaserId = Type_String.Teaser.Id.makeFromNullable(id);
  var dsId = Type_Teaser.Helper.makeDsId(programId, teaserId);
  var key = Type_Teaser.Helper.makeKey(undefined, dsId);
  var kind = Core__Option.flatMap(Caml_option.nullable_to_opt(param.kind), Type_Common.programKindFromJs);
  var baseUrlRegex = Type_Pattern.make("ArteBaseUrl");
  var url = Core__Option.getOr(Caml_option.nullable_to_opt(param.url), "").replace(baseUrlRegex, "");
  var tmp;
  if (kind !== undefined) {
    var match = Core__Option.flatMap(Caml_option.nullable_to_opt(param.favorite), (function (param) {
            return Caml_option.nullable_to_opt(param.is);
          }));
    tmp = match !== undefined && match ? ({
          TAG: "Available",
          _0: "Favorite"
        }) : ({
          TAG: "Available",
          _0: "NotFavorite"
        });
  } else {
    tmp = "NotAvailable";
  }
  return {
          audioVersions: undefined,
          availability: undefined,
          broadcastDates: undefined,
          callToAction: undefined,
          parentPage: undefined,
          credits: undefined,
          description: Type_String.Teaser.Description.makeFromNullable(param.description),
          dsId: dsId,
          duration: duration,
          durationLabel: durationLabelTeaser,
          favoriteStatus: tmp,
          href: Type_String.Href.make(url),
          geoblocking: undefined,
          id: Type_String.Teaser.Id.makeFromNullableOrEmpty(id),
          imageSrc: toResolution(param.images),
          key: key,
          kind: teaserKind,
          partners: undefined,
          program: {
            clip: undefined,
            description: undefined,
            firstBroadcastDate: undefined,
            genre: undefined,
            id: programId,
            kind: kind,
            shortDescription: undefined,
            trailer: undefined,
            chapters: undefined
          },
          progression: Core__Option.getOr(kind === "SHOW" ? Core__Option.flatMap(Caml_option.nullable_to_opt(param.lastviewed), (function (param) {
                        var timecode = param.timecode;
                        return Core__Option.map(Caml_option.nullable_to_opt(param.progress), (function (extra) {
                                      return Type_Progression.makeFromPct((timecode == null) ? undefined : Caml_option.some(timecode), extra);
                                    }));
                      })) : undefined, Type_Progression.noProgression),
          shopUrl: undefined,
          stickers: stickers$1,
          subtitle: Core__Option.map(Caml_option.nullable_to_opt(param.subtitle), (function (subtitle) {
                  return {
                          TAG: "Displayed",
                          label: Type_String.Teaser.Subtitle.make(subtitle)
                        };
                })),
          title: Core__Option.map(Caml_option.nullable_to_opt(param.title), (function (title) {
                  return {
                          TAG: "Displayed",
                          _0: Type_String.Teaser.Title.make(title)
                        };
                })),
          trackingPixel: Type_String.TrackingPixel.makeFromNullable(param.trackingPixel),
          hoverBehavior: hoverBehavior,
          ageRestriction: undefined
        };
}

function emptyConnectedZoneTeaser(title) {
  return {
          audioVersions: undefined,
          availability: undefined,
          broadcastDates: undefined,
          callToAction: undefined,
          parentPage: undefined,
          credits: undefined,
          description: undefined,
          dsId: Type_Teaser.Helper.makeDsId(undefined, undefined),
          duration: undefined,
          durationLabel: undefined,
          favoriteStatus: "NotAvailable",
          href: Type_String.Href.empty,
          geoblocking: undefined,
          id: Type_String.Teaser.Id.makeFromNullableOrEmpty(null),
          imageSrc: Type_Image.Src.empty,
          key: Type_Teaser.Helper.makeKey(undefined, undefined),
          kind: Type_Teaser.Kind.standard,
          partners: undefined,
          program: {
            clip: undefined,
            description: undefined,
            firstBroadcastDate: undefined,
            genre: undefined,
            id: undefined,
            kind: "EXTERNAL",
            shortDescription: undefined,
            trailer: undefined,
            chapters: undefined
          },
          progression: Type_Progression.noProgression,
          shopUrl: undefined,
          stickers: Type_Sticker.empty,
          subtitle: undefined,
          title: Core__Option.map(title, (function (title) {
                  return {
                          TAG: "Displayed",
                          _0: Type_String.Teaser.Title.make(title)
                        };
                })),
          trackingPixel: undefined,
          hoverBehavior: "Disabled",
          ageRestriction: undefined
        };
}

var Teaser = {
  type_encode: type_encode,
  type_decode: type_decode,
  alternateResolution_encode: alternateResolution_encode,
  alternateResolution_decode: alternateResolution_decode,
  image_encode: image_encode,
  image_decode: image_decode,
  favorite_encode: favorite_encode,
  favorite_decode: favorite_decode,
  t_encode: t_encode,
  t_decode: t_decode,
  toResolution: toResolution,
  toDsTeaser: toDsTeaser,
  emptyConnectedZoneTeaser: emptyConnectedZoneTeaser
};

function meta_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "totalCount",
                    false,
                    Spice.intToJson(v.totalCount)
                  ],
                  [
                    "page",
                    false,
                    Spice.intToJson(v.page)
                  ],
                  [
                    "pages",
                    false,
                    Spice.intToJson(v.pages)
                  ],
                  [
                    "saveInterval",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.saveInterval)
                  ]
                ]));
}

function meta_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var totalCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "totalCount"), null));
  if (totalCount.TAG === "Ok") {
    var page = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
    if (page.TAG === "Ok") {
      var pages = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pages"), null));
      if (pages.TAG === "Ok") {
        var saveInterval = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "saveInterval"), null));
        if (saveInterval.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    totalCount: totalCount._0,
                    page: page._0,
                    pages: pages._0,
                    saveInterval: saveInterval._0
                  }
                };
        }
        var e = saveInterval._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".saveInterval" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = pages._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pages" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = page._0;
    return {
            TAG: "Error",
            _0: {
              path: ".page" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = totalCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".totalCount" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function t_encode$1(encoder_a) {
  return function (v) {
    return Js_dict.fromArray(Spice.filterOptional([
                    [
                      "meta",
                      false,
                      meta_encode(v.meta)
                    ],
                    [
                      "data",
                      false,
                      encoder_a(v.data)
                    ]
                  ]));
  };
}

function t_decode$1(decoder_a) {
  return function (v) {
    if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
      return Spice.error(undefined, "Not an object", v);
    }
    if (!(typeof v === "object" && !Array.isArray(v))) {
      return Spice.error(undefined, "Not an object", v);
    }
    var meta = meta_decode(Belt_Option.getWithDefault(Js_dict.get(v, "meta"), null));
    if (meta.TAG === "Ok") {
      var data = decoder_a(Belt_Option.getWithDefault(Js_dict.get(v, "data"), null));
      if (data.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  meta: meta._0,
                  data: data._0
                }
              };
      }
      var e = data._0;
      return {
              TAG: "Error",
              _0: {
                path: ".data" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = meta._0;
    return {
            TAG: "Error",
            _0: {
              path: ".meta" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  };
}

function getData(metaData) {
  return metaData.data;
}

function getNextPage(t) {
  if (t.meta.page < t.meta.pages) {
    return t.meta.page + 1 | 0;
  }
  
}

var $$Response = {
  meta_encode: meta_encode,
  meta_decode: meta_decode,
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  getData: getData,
  getNextPage: getNextPage
};

function teasersResponse_encode(v) {
  return t_encode$1(function (extra) {
                return Spice.arrayToJson(t_encode, extra);
              })(v);
}

function teasersResponse_decode(v) {
  return t_decode$1(function (extra) {
                return Spice.arrayFromJson(t_decode, extra);
              })(v);
}

function magazines_encode(v) {
  return Spice.arrayToJson(Type_String.Teaser.Id.t_encode, v);
}

function magazines_decode(v) {
  return Spice.arrayFromJson(Type_String.Teaser.Id.t_decode, v);
}

function events_encode(v) {
  return Spice.arrayToJson(Spice.stringToJson, v);
}

function events_decode(v) {
  return Spice.arrayFromJson(Spice.stringFromJson, v);
}

function custom_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "magazines",
                    false,
                    MyNullable.t_encode(magazines_encode)(v.magazines)
                  ],
                  [
                    "eventsParticipation",
                    false,
                    MyNullable.t_encode(events_encode)(v.eventsParticipation)
                  ]
                ]));
}

function custom_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var magazines = MyNullable.t_decode(magazines_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "magazines"), null));
  if (magazines.TAG === "Ok") {
    var eventsParticipation = MyNullable.t_decode(events_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "eventsParticipation"), null));
    if (eventsParticipation.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                magazines: magazines._0,
                eventsParticipation: eventsParticipation._0
              }
            };
    }
    var e = eventsParticipation._0;
    return {
            TAG: "Error",
            _0: {
              path: ".eventsParticipation" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = magazines._0;
  return {
          TAG: "Error",
          _0: {
            path: ".magazines" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function acceptCom_encode(v) {
  return Spice.boolToJson(v);
}

function acceptCom_decode(v) {
  return Spice.boolFromJson(v);
}

function email_encode(v) {
  return Spice.stringToJson(v);
}

function email_decode(v) {
  return Spice.stringFromJson(v);
}

function gender_encode(v) {
  switch (v) {
    case "Male" :
        return "m";
    case "Female" :
        return "f";
    case "Unknown" :
        return "u";
    
  }
}

function gender_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("m" === v) {
          return {
                  TAG: "Ok",
                  _0: "Male"
                };
        } else if ("f" === v) {
          return {
                  TAG: "Ok",
                  _0: "Female"
                };
        } else if ("u" === v) {
          return {
                  TAG: "Ok",
                  _0: "Unknown"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function user_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uid",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.uid)
                  ],
                  [
                    "custom",
                    false,
                    MyNullable.t_encode(custom_encode)(v.custom)
                  ],
                  [
                    "acceptCom",
                    false,
                    MyNullable.t_encode(acceptCom_encode)(v.acceptCom)
                  ],
                  [
                    "email",
                    false,
                    MyNullable.t_encode(email_encode)(v.email)
                  ],
                  [
                    "gender",
                    false,
                    MyNullable.t_encode(gender_encode)(v.gender)
                  ],
                  [
                    "firstName",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.lastName)
                  ],
                  [
                    "street",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.street)
                  ],
                  [
                    "city",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.city)
                  ],
                  [
                    "zipCode",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.zipCode)
                  ],
                  [
                    "country",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.country)
                  ],
                  [
                    "birthdate",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.birthdate)
                  ],
                  [
                    "over16",
                    false,
                    MyNullable.t_encode(Spice.boolToJson)(v.over16)
                  ],
                  [
                    "over18",
                    false,
                    MyNullable.t_encode(Spice.boolToJson)(v.over18)
                  ],
                  [
                    "nlarte",
                    false,
                    MyNullable.t_encode(Spice.boolToJson)(v.nlarte)
                  ],
                  [
                    "ageVerificationToken",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.ageVerificationToken)
                  ],
                  [
                    "avatar",
                    false,
                    MyNullable.t_encode(Type_Avatar.avatarSSO_encode)(v.avatar)
                  ]
                ]));
}

function user_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uid = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "uid"), null));
  if (uid.TAG === "Ok") {
    var custom = MyNullable.t_decode(custom_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "custom"), null));
    if (custom.TAG === "Ok") {
      var acceptCom = MyNullable.t_decode(acceptCom_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "acceptCom"), null));
      if (acceptCom.TAG === "Ok") {
        var email = MyNullable.t_decode(email_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
        if (email.TAG === "Ok") {
          var gender = MyNullable.t_decode(gender_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "gender"), null));
          if (gender.TAG === "Ok") {
            var firstName = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
            if (firstName.TAG === "Ok") {
              var lastName = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
              if (lastName.TAG === "Ok") {
                var street = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "street"), null));
                if (street.TAG === "Ok") {
                  var city = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
                  if (city.TAG === "Ok") {
                    var zipCode = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "zipCode"), null));
                    if (zipCode.TAG === "Ok") {
                      var country = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
                      if (country.TAG === "Ok") {
                        var birthdate = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "birthdate"), null));
                        if (birthdate.TAG === "Ok") {
                          var over16 = MyNullable.t_decode(Spice.boolFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "over16"), null));
                          if (over16.TAG === "Ok") {
                            var over18 = MyNullable.t_decode(Spice.boolFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "over18"), null));
                            if (over18.TAG === "Ok") {
                              var nlarte = MyNullable.t_decode(Spice.boolFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "nlarte"), null));
                              if (nlarte.TAG === "Ok") {
                                var ageVerificationToken = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "ageVerificationToken"), null));
                                if (ageVerificationToken.TAG === "Ok") {
                                  var avatar = MyNullable.t_decode(Type_Avatar.avatarSSO_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "avatar"), null));
                                  if (avatar.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              uid: uid._0,
                                              custom: custom._0,
                                              acceptCom: acceptCom._0,
                                              email: email._0,
                                              gender: gender._0,
                                              firstName: firstName._0,
                                              lastName: lastName._0,
                                              street: street._0,
                                              city: city._0,
                                              zipCode: zipCode._0,
                                              country: country._0,
                                              birthdate: birthdate._0,
                                              over16: over16._0,
                                              over18: over18._0,
                                              nlarte: nlarte._0,
                                              ageVerificationToken: ageVerificationToken._0,
                                              avatar: avatar._0
                                            }
                                          };
                                  }
                                  var e = avatar._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".avatar" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = ageVerificationToken._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".ageVerificationToken" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = nlarte._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".nlarte" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = over18._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".over18" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = over16._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".over16" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = birthdate._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".birthdate" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = country._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".country" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = zipCode._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".zipCode" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = city._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".city" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = street._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".street" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = lastName._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".lastName" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = firstName._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".firstName" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = gender._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".gender" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = email._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".email" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = acceptCom._0;
      return {
              TAG: "Error",
              _0: {
                path: ".acceptCom" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = custom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".custom" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = uid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uid" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

function t_encode$2(v) {
  return t_encode$1(function (extra) {
                return Spice.arrayToJson(user_encode, extra);
              })(v);
}

function t_decode$2(v) {
  return t_decode$1(function (extra) {
                return Spice.arrayFromJson(user_decode, extra);
              })(v);
}

var defaultValue_uid = null;

var defaultValue_custom = null;

var defaultValue_acceptCom = null;

var defaultValue_email = null;

var defaultValue_gender = null;

var defaultValue_firstName = null;

var defaultValue_lastName = null;

var defaultValue_street = null;

var defaultValue_city = null;

var defaultValue_zipCode = null;

var defaultValue_country = null;

var defaultValue_birthdate = null;

var defaultValue_over16 = null;

var defaultValue_over18 = null;

var defaultValue_nlarte = null;

var defaultValue_ageVerificationToken = null;

var defaultValue_avatar = null;

var defaultValue = {
  uid: defaultValue_uid,
  custom: defaultValue_custom,
  acceptCom: defaultValue_acceptCom,
  email: defaultValue_email,
  gender: defaultValue_gender,
  firstName: defaultValue_firstName,
  lastName: defaultValue_lastName,
  street: defaultValue_street,
  city: defaultValue_city,
  zipCode: defaultValue_zipCode,
  country: defaultValue_country,
  birthdate: defaultValue_birthdate,
  over16: defaultValue_over16,
  over18: defaultValue_over18,
  nlarte: defaultValue_nlarte,
  ageVerificationToken: defaultValue_ageVerificationToken,
  avatar: defaultValue_avatar
};

function getAcceptCom(user) {
  return Core__Option.getOr(Caml_option.nullable_to_opt(user.acceptCom), false);
}

function getEmail(user) {
  return Caml_option.nullable_to_opt(user.email);
}

function isRegisteredToNewsletter(user) {
  return Caml_option.nullable_to_opt(user.nlarte);
}

function getAgeVerificationStatus(user) {
  var match = user.over16;
  var match$1 = user.over18;
  var match$2 = user.ageVerificationToken;
  if (match == null) {
    return "NotVerified";
  } else if (match) {
    if (match$1 == null) {
      return "NotVerified";
    } else if (match$1) {
      if (match$2 == null) {
        return "NotVerified";
      } else {
        return "Verified18";
      }
    } else if (match$2 == null) {
      return "NotVerified";
    } else {
      return "Verified16";
    }
  } else if (!(match$1 == null) && !(match$1 || (match$2 == null))) {
    return "Under16";
  } else {
    return "NotVerified";
  }
}

function getAgeVerificationToken(user) {
  return Caml_option.nullable_to_opt(user.ageVerificationToken);
}

function getUid(user) {
  return Caml_option.nullable_to_opt(user.uid);
}

function getAvatar(user) {
  return Caml_option.nullable_to_opt(user.avatar);
}

function getFromT(t) {
  return Core__Option.getOr(Util_Array.getFirstElement(t.data), defaultValue);
}

var User = {
  magazines_encode: magazines_encode,
  magazines_decode: magazines_decode,
  events_encode: events_encode,
  events_decode: events_decode,
  custom_encode: custom_encode,
  custom_decode: custom_decode,
  acceptCom_encode: acceptCom_encode,
  acceptCom_decode: acceptCom_decode,
  email_encode: email_encode,
  email_decode: email_decode,
  gender_encode: gender_encode,
  gender_decode: gender_decode,
  user_encode: user_encode,
  user_decode: user_decode,
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  defaultValue: defaultValue,
  getAcceptCom: getAcceptCom,
  getEmail: getEmail,
  isRegisteredToNewsletter: isRegisteredToNewsletter,
  getAgeVerificationStatus: getAgeVerificationStatus,
  getAgeVerificationToken: getAgeVerificationToken,
  getUid: getUid,
  getAvatar: getAvatar,
  getFromT: getFromT
};

function favorites_encode(v) {
  return t_encode$1(function (extra) {
                return Spice.arrayToJson(t_encode, extra);
              })(v);
}

function favorites_decode(v) {
  return t_decode$1(function (extra) {
                return Spice.arrayFromJson(t_decode, extra);
              })(v);
}

function getFirstTeaser(favorites) {
  return favorites.data[0];
}

var Favorite = {
  favorites_encode: favorites_encode,
  favorites_decode: favorites_decode,
  get: getData,
  getFirstTeaser: getFirstTeaser
};

function videosInProgress_encode(v) {
  return t_encode$1(function (extra) {
                return Spice.arrayToJson(t_encode, extra);
              })(v);
}

function videosInProgress_decode(v) {
  return t_decode$1(function (extra) {
                return Spice.arrayFromJson(t_decode, extra);
              })(v);
}

function getFirstTeaser$1(teaser) {
  return teaser.data[0];
}

var VideoInProgress = {
  videosInProgress_encode: videosInProgress_encode,
  videosInProgress_decode: videosInProgress_decode,
  get: getData,
  getFirstTeaser: getFirstTeaser$1
};

export {
  lastviewed_encode ,
  lastviewed_decode ,
  Teaser ,
  $$Response ,
  teasersResponse_encode ,
  teasersResponse_decode ,
  User ,
  Favorite ,
  VideoInProgress ,
}
/* Type_Image Not a pure module */
