// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as UseOnBlur from "../../../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../../../hook/UseToggle.res.mjs";
import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Header from "../../../../type/Type_Header.res.mjs";
import * as Atom_Divider from "../../../atom/Atom_Divider.res.mjs";
import * as UseClickOutside from "../../../../hook/UseClickOutside.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

var innerSpaceListColumn = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function Organism_Concert_SelectGenre$ListItem(props) {
  var items = props.items;
  if (items !== undefined) {
    return JsxRuntime.jsx(Atom_Column.make, {
                children: items.map(function (item) {
                      return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                  href: item.href,
                                  label: item.label,
                                  style: Atom_Cta_Ds.Header.genreItem
                                }, item.label);
                    }),
                innerSpace: Caml_option.some(innerSpaceListColumn)
              });
  } else {
    return null;
  }
}

var ListItem = {
  innerSpaceListColumn: innerSpaceListColumn,
  make: Organism_Concert_SelectGenre$ListItem
};

var rowBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("S", "M", "L", "XL"));

var dividerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("M"));

function getChunkSize(selectGenre) {
  return selectGenre.items.length / 2 | 0;
}

var containerPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: {
          NAME: "px",
          VAL: Type_Header.Constants.height.m
        }
      }
    });

var containerBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("M"));

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("L"));

function Organism_Concert_SelectGenre(props) {
  var selectGenre = props.selectGenre;
  var match = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var close = match.close;
  var state = match.state;
  var containerRef = React.useRef(null);
  UseClickOutside.make(containerRef, close);
  UseOnBlur.make(containerRef, close);
  var chunkedSelectGenre = Util_Array.chunk(selectGenre.items, getChunkSize(selectGenre));
  var dropContent;
  dropContent = state === "On" ? JsxRuntime.jsx(Atom_Column.make, {
          background: Caml_option.some(Particle_Background_Ds.selectConcertGenre),
          blockSpace: Caml_option.some(containerBlockspace),
          children: JsxRuntime.jsxs(Atom_Row.make, {
                blockSpace: Caml_option.some(rowBlockSpace),
                children: [
                  JsxRuntime.jsx(Organism_Concert_SelectGenre$ListItem, {
                        items: chunkedSelectGenre[0]
                      }),
                  JsxRuntime.jsx(Atom_Cell.make, {
                        blockSpace: Caml_option.some(dividerBlockSpace),
                        children: JsxRuntime.jsx(Atom_Divider.make, {
                              style: Atom_Divider.headerSocialLink
                            })
                      }),
                  JsxRuntime.jsx(Organism_Concert_SelectGenre$ListItem, {
                        items: chunkedSelectGenre[1]
                      })
                ],
                wrap: "OnOneLine"
              }),
          position: Caml_option.some(containerPosition)
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
              blockSpace: Caml_option.some(blockSpace),
              children: [
                JsxRuntime.jsx(Atom_Cta.Button.make, {
                      label: selectGenre.title.label,
                      onClick: match.toggle,
                      style: Atom_Cta_Ds.Header.makeConcertGenre(state)
                    }),
                dropContent
              ],
              ref_: containerRef
            });
}

var make = Organism_Concert_SelectGenre;

export {
  ListItem ,
  rowBlockSpace ,
  dividerBlockSpace ,
  getChunkSize ,
  containerPosition ,
  containerBlockspace ,
  blockSpace ,
  make ,
}
/* innerSpaceListColumn Not a pure module */
