// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Atom_Logo_Ds from "./Atom_Logo_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Spacer from "../particle/Particle_Spacer.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";

function toCss(dsStyle) {
  var match = Atom_Logo_Ds.view(dsStyle);
  var hover = match.hover;
  var heightRules = Particle_Screen_Css.toRules(undefined, undefined, Particle_Css.shape, match.shape);
  var hoverRules = hover !== undefined ? CssJs.hover([Css_Js_Core.SVG.fill(Particle_Color.Css.color(hover))]) : CssJs.empty([]);
  var wrapperRules = Core__Option.map(match.wrapper, (function (wrapper) {
          if (wrapper === "TopTeaser") {
            return Particle_Screen_Ds.make(undefined, undefined, [CssJs.display("flex")], undefined, undefined, [
                        CssJs.position("absolute"),
                        CssJs.transform({
                              NAME: "translateX",
                              VAL: CssJs.pct(-100)
                            }),
                        CssJs.display("none"),
                        CssJs.paddingRight(Particle_Spacer.spacerToVarName("XS"))
                      ]);
          } else {
            return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.after([
                              CssJs.unsafe("content", ""),
                              CssJs.position(CssJs.absolute),
                              CssJs.left(CssJs.zero),
                              CssJs.right(CssJs.zero),
                              CssJs.bottom(CssJs.zero),
                              CssJs.top(CssJs.zero),
                              CssJs.unsafe("background", "linear-gradient(to top left,transparent 43%, black 44%, black 54%,transparent 55%)")
                            ])]);
          }
        }));
  var rules = [
    hoverRules,
    CssJs.width(CssJs.auto),
    Css_Js_Core.SVG.fill(Particle_Color.Css.color(match.color)),
    CssJs.transform(CssJs.rotate(CssJs.deg(match.angle)))
  ];
  return {
          svg: rules.concat(heightRules),
          wrapper: wrapperRules
        };
}

export {
  toCss ,
}
/* CssJs Not a pure module */
