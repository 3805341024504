// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  return JsxRuntime.jsx("path", {
              clipRule: "evenodd",
              d: "M-0.000368 20.4433L20.2195 0.00392533L25.2747 5.11373L13.481 17.0354H46.0073V24.2618H13.8871L25.2747 35.7725L20.2195 40.8823L-0.000368 20.4433Z",
              fillRule: "evenodd"
            });
}

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
