// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as SetFocus from "../../hook/SetFocus.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Textfield from "../atom/Atom_Textfield.res.mjs";
import * as Layout_Constants from "./Layout_Constants.res.mjs";
import * as Organism_Dropdown from "../organism/dropdown/Organism_Dropdown.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("L", "M", "XL", "XL"), undefined, undefined, Particle_Spacer_Ds.make("S", "S", "M", "M"));

function Layout_Search$SearchTitle(props) {
  var searchValue = props.searchValue;
  var match = Context.I18n.use();
  var resultCount = Core__Option.getOr(Caml_option.nullable_to_opt(props.resultCount), 0);
  var match$1 = match.labels.search;
  var renderTitle;
  var exit = 0;
  if (props.withSearchBar || resultCount !== 0) {
    exit = 1;
  } else {
    renderTitle = null;
  }
  if (exit === 1) {
    renderTitle = searchValue === "" ? JsxRuntime.jsx(Atom_Text.make, {
            children: Caml_option.some(match$1.searching),
            kind: "h1",
            style: Atom_Text_Ds.Search.title
          }) : JsxRuntime.jsx(Atom_Text.make, {
            children: Caml_option.some(match$1.search_results_for + " " + searchValue),
            kind: "h1",
            style: Atom_Text_Ds.Search.title
          });
  }
  return JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: Caml_option.some(blockSpace),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
              children: renderTitle,
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.margin("auto")]))
            });
}

var SearchTitle = {
  blockSpace: blockSpace,
  make: Layout_Search$SearchTitle
};

var blockSpace$1 = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XL"), undefined, undefined, Particle_Spacer_Ds.makeHorizontal("M"));

var blockSpaceDropdown = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Constants.ListPage.dropdown);

function Layout_Search$SearchFilter(props) {
  var __withSearchBar = props.withSearchBar;
  var searchValue = props.searchValue;
  var withSearchBar = __withSearchBar !== undefined ? __withSearchBar : true;
  var match = Context.I18n.use();
  var labels = match.labels;
  var internalInputRef = React.useRef(null);
  var inputRef = Core__Option.getOr(props.inputRef, internalInputRef);
  React.useEffect((function () {
          SetFocus.ByRef.make(inputRef);
        }), []);
  var match$1 = labels.search;
  var searchPlaceholderLabel = Core__Option.getOr(props.placeholder, match$1.placeholder);
  var match$2 = labels.common;
  var match$3 = labels.accessibility;
  var searchBar = withSearchBar ? JsxRuntime.jsx(Atom_Textfield.make, {
          type_: "search",
          value: searchValue,
          placeholder: searchPlaceholderLabel,
          onChange: props.onChange,
          style: "PageSearch",
          inputRef: inputRef
        }) : null;
  var dropdownItems = Util_Array.NonEmpty.makeFromArray(props.dropdownItems);
  var dropdown = dropdownItems !== undefined ? JsxRuntime.jsx(Atom_Row.make, {
          blockSpace: Caml_option.some(blockSpaceDropdown),
          children: JsxRuntime.jsx(Organism_Dropdown.make, {
                kind: {
                  TAG: "List",
                  _0: {
                    title: match$3.showVideosSortingOptions,
                    titleItems: match$3.sortVideoBy,
                    buttonKind: "h3",
                    variant: "list"
                  }
                },
                items: Caml_option.valFromOption(dropdownItems),
                label: match$2.filter_by,
                value: props.dropdownValue
              })
        }) : null;
  var renderDropdown = searchValue === "" ? null : dropdown;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(blockSpace$1),
              children: [
                searchBar,
                renderDropdown
              ],
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var SearchFilter = {
  blockSpace: blockSpace$1,
  blockSpaceDropdown: blockSpaceDropdown,
  make: Layout_Search$SearchFilter
};

export {
  SearchTitle ,
  SearchFilter ,
}
/* blockSpace Not a pure module */
